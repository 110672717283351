/**
 * userIdIsValid
 * @description Checks to see if the user ID is a valid value type for the route
 */

export function userIdIsValid (id) {
  if (id === 'user' || id === 'users') return false;
  if (typeof id === 'string') return true;
  if (typeof id === 'number') return true;
  return false;
}

/**
 * createFullName
 */
export function createFullName (givenName, familyName) {
  let fullName = '';

  if (givenName) {
    fullName = `${fullName} ${givenName}`;
  }

  if (familyName) {
    fullName = `${fullName} ${familyName}`;
  }

  return fullName;
}
