import { useSubReseller } from 'hooks';

/**
 * useSubResellerUsers
 * @description Returns the users that are within a Sub-Reseller's organization
 * @note This does NOT return users in the Sub-Reseller's child organizations
 */

export default function useSubResellerUsers () {
  const { organization = {} } = useSubReseller();
  const { users = [] } = organization;

  return {
    users
  };
}
