const defaultGeometricConstraintLevels = (toggleAll = false) => ({
  minimalGeometricConstraints: toggleAll,
  advancedGeometricConstraints: toggleAll,
  allGeometricConstraints: toggleAll
});

/**
 * type enumerators for repeat cycle type
 */
export const RepeatCycleTypes = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  BIWEEKLY: 'biweekly',
  MONTHLY: 'monthly',
  OTHER: 'other',
  properties: {
    daily: {
      label: 'Daily',
      ...defaultGeometricConstraintLevels(),
      value: 1
    },
    weekly: {
      label: 'Weekly',
      ...defaultGeometricConstraintLevels(),
      minimalGeometricConstraints: true,
      value: 7
    },
    biweekly: {
      label: 'Bi-weekly',
      ...defaultGeometricConstraintLevels(),
      minimalGeometricConstraints: true,
      advancedGeometricConstraints: true,
      value: 14
    },
    monthly: {
      label: 'Monthly',
      ...defaultGeometricConstraintLevels(),
      minimalGeometricConstraints: true,
      advancedGeometricConstraints: true,
      value: 30
    },
    other: {
      label: 'Other',
      ...defaultGeometricConstraintLevels(true)
    }
  }
};
