// shared data/tasks
export * from 'commonLib/src/data/tasks';

// console specific tasks data can be exported here

const TaskCollectItemLabels = /** @type {const} */ ({
  collectId: 'Collect ID',
  collection: 'Collection',
  collectDate: 'Collect Date',
  imagingMode: 'Imaging Mode',
  taskingTier: 'Tasking Tier'
});

export {
  TaskCollectItemLabels
};
