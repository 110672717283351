import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useRepeatRequest, useRepeatRequests } from 'hooks';

import { logError } from 'lib/logger';
import {
  fetchRepeatRequestByRepeatRequestId,
  triggerNotice
} from 'state/actions';
import { REPEAT_REQUEST_ID } from 'data/property-names';

export default function useFetchRepeatRequest ({
  repeatRequestId,
  forceNew = false
}) {
  const dispatch = useDispatch();

  const { actions = {} } = useRepeatRequests();
  const repeatRequest = useRepeatRequest(repeatRequestId) || {};
  const { properties = {} } = repeatRequest;
  const repeatRequestIdProperty = properties[REPEAT_REQUEST_ID];

  useEffect(() => {
    if (!repeatRequestId || (repeatRequestIdProperty && !forceNew)) return;
    dispatch(fetchRepeatRequestByRepeatRequestId(repeatRequestId)).catch(
      (error) => {
        logError(error.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      }
    );
  }, [dispatch, repeatRequestId, repeatRequestIdProperty, forceNew]);

  return {
    ...actions.fetchRepeatRequestByRepeatRequestId,
    repeatRequest
  };
}
