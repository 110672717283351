import React from 'react';

import {
  useOrgOrders,
  useOrgFetchOrders,
  useOrgFetchUsers,
  useUser
} from 'hooks';
import { routePathByName } from 'lib/routes';

import TemplateOrders from 'templates/TemplateOrders';

import { ORG_MANAGER_ROLE } from 'data/roles';
import { ROUTE_ORG_ORDERS_DETAILS } from 'data/route-names';

const organizationPath = routePathByName('organization');

const OrganizationOrdersPage = () => {
  let orgId, isLoadingUser;

  const { user = {} } = useUser();
  const { orders } = useOrgOrders();

  const { organization = {} } = user;

  if (organization.id) {
    orgId = organization.id;
    isLoadingUser = false;
  } else {
    isLoadingUser = true;
  }

  // setting forceNew makes sure we always get updated orders when we visit the page

  const { isLoading: isLoadingOrgOrders } = useOrgFetchOrders({
    orgId,
    forceNew: true
  });

  const { isLoading: isLoadingOrgUsers } = useOrgFetchUsers({
    orgId
  });

  const ordersState = {
    isLoading: isLoadingUser || isLoadingOrgOrders || isLoadingOrgUsers
  };

  const breadcrumbs = [
    {
      label: 'Organization',
      to: organizationPath
    }
  ];

  const columns = [
    'orderId',
    'contractLabel',
    'user',
    'orderDate',
    'status',
    'actions'
  ];

  return (
    <TemplateOrders
      state={ordersState}
      orders={orders}
      tableColumns={columns}
      detailsRouteName={ROUTE_ORG_ORDERS_DETAILS}
      access={ORG_MANAGER_ROLE}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default OrganizationOrdersPage;
