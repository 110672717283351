import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import {
  useFetchRepeatRequests,
  useLocation,
  useRepeatRequests,
  useUser,
  useFetchCollectionTypes
} from 'hooks';
import { routePathByName } from 'lib/routes';
import {
  taskFilterParamsToObject,
  REPEAT_REQUESTS_TABLE_COLUMNS,
  prependDateTimeString,
  getFilterOptions,
  parseTableColumnsByFlag
} from 'lib/tasks';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';
import { updateRepeatRequestsColumns } from 'state/actions';
import { USER_ROLE } from 'data/roles';

import TemplateTasks from 'templates/TemplateTasks';

// Standalone Repeat Requests "page", behind tab on /tasks page
const RepeatRequestsPage = () => {
  const dispatch = useDispatch();
  const { queryParams } = useLocation();
  const {
    user: {
      organizationId,
      organization: {
        permittedCollectionTiers: { repeatRequest: permittedTiers = [] }
      }
    }
  } = useUser();

  const { repeatRequests = [], totalPages = 1, columns = [] } = useRepeatRequests();

  const { page = 1, limit = DEFAULT_PAGING_LIMIT, filters = {}, sort, order } = queryParams || {};

  // Convert filters & sort in query params to format tasks table can use
  // memoize the obj to avoid unnecessary re-renders
  const filtersParamObject = useMemo(() => {
    return taskFilterParamsToObject(filters);
  }, [filters]);

  const sortObject = useMemo(() => {
    return { sort, order };
  }, [sort, order]);

  // use forceNew to make sure we always have a fresh list
  const { isLoading = false, dispatchRepeatRequestsCsvByUserId } = useFetchRepeatRequests({
    forceNew: true,
    page,
    limit,
    filters: filtersParamObject,
    sortObject
  });

  const { isLoading: isLoadingCollectionTypes, collectionTypes } = useFetchCollectionTypes();

  const noTasksActions = [
    {
      to: routePathByName('search'),
      label: 'Go to Search'
    }
  ];

  const flags = useFlags();

  // Reset to default tasks table columns order & active state
  const resetColumns = () => {
    dispatch(updateRepeatRequestsColumns(parseTableColumnsByFlag({ columns: REPEAT_REQUESTS_TABLE_COLUMNS, flags, organizationId })));
  };

  useEffect(() => {
    if (columns && flags.collectionTypeTaskingFormEnabled) {
      dispatch(updateRepeatRequestsColumns(parseTableColumnsByFlag({ columns, flags, organizationId })));
    }
  }, [flags]);

  return (
    <TemplateTasks
      id="tabpanel_1"
      isLoading={isLoading || isLoadingCollectionTypes}
      tasks={repeatRequests}
      collectionTypes={collectionTypes}
      filters={filtersParamObject}
      filterOptions={getFilterOptions({ permittedTiers, isRepeat: true, flags, organizationId, collectionTypes })}
      paging={{ currentPage: Number(page), totalPages, limit: Number(limit) }}
      accessType={USER_ROLE}
      emptyActions={noTasksActions}
      tableColumns={columns}
      resetColumns={resetColumns}
      handleColumnsChange={(cols) => dispatch(updateRepeatRequestsColumns(cols))}
      activeTab="repeat"
      exportHandler={dispatchRepeatRequestsCsvByUserId}
      exportFileName={prependDateTimeString('capella-user-repeat-requests-download.csv')}
    />
  );
};

export default RepeatRequestsPage;
