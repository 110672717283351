import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';

import { triggerNotice, putToken } from 'state/actions';
import { useLocation, useUser } from 'hooks';
import { routePathByName, redirectTo } from 'lib/routes';
import { logError } from 'lib/logger';

import User from 'models/user';

import Layout from 'components/Layout';
import Container from 'components/Container';
import FormChangePassword from 'components/FormChangePassword';

const UserNewPasswordPage = () => {
  const dispatch = useDispatch();

  const { user: userData } = useUser();
  const user = new User(userData); // TODO: useUser should return a User

  const { queryParams = {} } = useLocation() || {};
  let { challenge } = queryParams;

  try {
    challenge = JSON.parse(challenge);
  } catch (e) {
    logError('Failed to parse challenge', {
      details: e
    });
    challenge = false;
  }

  useEffect(() => {
    if (!challenge) {
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try again'
        })
      );
      // Redirect to logout to clear state in case something funky
      // is going on
      redirectTo(routePathByName('userLogout'));
    }
  }, [dispatch, challenge]);

  async function handleOnSubmit (fields) {
    const data = {
      ...challenge,
      challengeResponses: {
        password: fields.password && fields.password.value
      }
    };

    try {
      await dispatch(putToken(data));
    } catch (e) {
      logError(e.message, fields);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try again'
        })
      );
      return;
    }

    dispatch(
      triggerNotice({
        type: 'success',
        weight: 'bold',
        align: 'center',
        text: 'Password successfully set! Please login with your new password'
      })
    );

    redirectTo(routePathByName('userLogout'));
  }

  return (
    <Layout navigation={user.isLoggedIn()}>
      <Helmet bodyAttributes={{ class: 'page-user page-user-new-password' }}>
        <title>Set a New Password</title>
      </Helmet>
      <Container className="content" type="centered">
        <h1>Set a New Password</h1>

        <FormChangePassword onSubmit={handleOnSubmit} />
      </Container>
    </Layout>
  );
};

export default UserNewPasswordPage;
