import { routePathByName } from 'lib/routes';

// Static mapping of role values into friendlier labels

const roleMap = {
  admin: 'Admin',
  'analytics-developer': 'GCM Developer',
  'analytics-operator': 'GCM Operator',
  'analytics-user': 'GCM User',
  'organization-manager': 'Organization Manager',
  'product-qa-user': 'Product QA User',
  reseller: 'Reseller',
  'sub-reseller': 'Sub Reseller',
  user: 'User',
  'archive-viewer': 'Archive Viewer',
  'archive-buyer': 'Archive Buyer',
  tasker: 'Tasker',
  salesforce: 'Salesforce'
};

/**
 * usersToTableData
 */

export function usersToTableData (users = [], { editPathName }) {
  if (!Array.isArray(users) || users.length === 0) return [];

  return users.map((user = {}, index) => {
    const { organization = {} } = user;

    // We have to add an additional || {} here as destructuring doesn't
    // take into consideration falsy values, meaning we would be trying to
    // grab "name" of null if there is no org

    const { name: orgName } = organization || {};

    const roles = user.roles
      ? user.roles.map((role) => roleMap[role] || role)
      : user.roles;

    return {
      lastName: user.familyName || '',
      firstName: user.givenName || '',
      email: user.email || '',
      organization: orgName || '',
      role: roles || [],
      status: `${user.disabled ? 'Disabled' : 'Active'}`,
      actions: [
        {
          to: routePathByName(editPathName, {
            wildcard: [user.id]
          }),
          label: 'Edit',
          icon: 'FaPen',
          buttonType: ['text', 'icon-before']
        }
      ]
    };
  });
}
