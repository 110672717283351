import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormRow, FormInput, InputButton } from 'fogg/ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { getAvailableCountries, fieldByName } from '../lib/fields';
import {
  ANALYTICS_ACCESS_ROLE,
  ANALYTICS_INITIALIZED_ROLE
} from '../data/roles';
import {
  DEFAULT_INGEST_WINDOW,
  DEFAULT_BACKFILL,
  DEFAULT_ANALYTIC_WINDOW,
  DEFAULT_SPACING
} from '../data/organization';

import FormAnalyticsPanel from './FormAnalyticsPanel';
import ReactTooltip from 'react-tooltip';
import { S3BucketConfigTable } from 'commonLib';

const countries = getAvailableCountries();

const SELECT_SINGLE_VALUE_TYPES = ['country'];

const FormAdminEditOrganization = ({
  organization = {},
  licenses = {},
  disabled = false,
  onSubmit
}) => {
  const {
    name,
    address,
    type = {},
    salesforceAccountId,
    s3PushEnabled,
    s3BucketConfigs,
    roles = [],
    amplitudeChangeDetectionEnabled = false,
    vesselClassificationEnabled = false,
    availableLicenses
  } = organization;
  const { id: addressId, street, city, state, postalCode, country } = address;
  // Handle Organization Type dropdown default value & state, only for reseller users
  const orgTypes = [
    { value: 'standard', label: 'Standard' },
    { value: 'reseller', label: 'Reseller' },
    { value: 'sub-reseller', label: 'Sub-Reseller' }
  ];
  const isAnalyticsAlreadyEnabled = !!roles.includes(ANALYTICS_ACCESS_ROLE); // Org already has CHMO role
  const isAnalyticsInitialized = !!roles.includes(ANALYTICS_INITIALIZED_ROLE); // Org previously initialized CHMO/Analytics
  const [isAnalyticsChecked, setIsAnalyticsChecked] = useState(null);
  const [
    isAmplitudeChangeDetectionEnabled,
    setIsAmplitudeChangeDetectionEnabled
  ] = useState(amplitudeChangeDetectionEnabled);

  const [
    isVesselClassificationEnabled,
    setIsVesselClassificationEnabled
  ] = useState(vesselClassificationEnabled);

  const [isContinuousAnalyticsChecked, setIsContinuousAnalyticsChecked] =
    useState(false);
  const [useS3, setUseS3] = useState(s3PushEnabled);

  const defaultActionValues = {
    ingestWindow: DEFAULT_INGEST_WINDOW,
    backfill: DEFAULT_BACKFILL,
    analyticWindow: DEFAULT_ANALYTIC_WINDOW,
    spacing: DEFAULT_SPACING
  };

  const [analyticsOptions, setAnalyticsOptions] = useState(defaultActionValues);

  const flags = useFlags();

  async function handleSubmit (e, fields = {}) {
    // Normalize array based select fields to their selected value
    SELECT_SINGLE_VALUE_TYPES.forEach((type) => {
      const value = fields[type] && fields[type].value;
      if (Array.isArray(value) && value.length > 0) {
        fields[type].value = value[0];
      }
    });
    // Activate CHMO/Analytics by passing CHMO to roles array
    let rolesArray = roles;
    if (isAnalyticsChecked) {
      // Start from scratch to prevent dupes, but preserve other roles
      rolesArray.filter(
        (role) => role !== ANALYTICS_ACCESS_ROLE || ANALYTICS_INITIALIZED_ROLE
      );
      rolesArray.push(ANALYTICS_ACCESS_ROLE);
      // Pass initialized role on 1st activations
      if (!isAnalyticsInitialized) {
        rolesArray.push(ANALYTICS_INITIALIZED_ROLE);
      }
    } else if (isAnalyticsAlreadyEnabled && isAnalyticsChecked === false) {
      // If already enabled, and user un-checks, remove CHMO Access role
      rolesArray = roles.filter((role) => role !== ANALYTICS_ACCESS_ROLE);
    }

    fields.roles = { value: rolesArray, isValid: true };

    // Update org's ACD settings based on checkbox state
    fields.amplitudeChangeDetectionEnabled = {
      value: isAmplitudeChangeDetectionEnabled,
      isValid: true
    };

    // Update org's VC settings based on checkbox state
    fields.vesselClassificationEnabled = {
      value: isVesselClassificationEnabled,
      isValid: true
    };

    fields.s3PushEnabled = useS3
      ? { value: true, isValid: true }
      : { value: false, isValid: true };

    // Normalize defaultValue inputs for license that are objects
    fields.availableLicenses.value = fields.availableLicenses.value.map(license => typeof license === 'object' ? license.value : license);

    if (typeof onSubmit === 'function') {
      // Ensure correct options are passed to /action based on what user has checked
      let currentOptions = null;
      if (isAnalyticsChecked) currentOptions = analyticsOptions;
      if (
        (!isContinuousAnalyticsChecked && !isAnalyticsChecked) ||
        isAnalyticsInitialized
      ) {
        currentOptions = null; // Pass nothing if neither is checked
      } else if (!isContinuousAnalyticsChecked && isAnalyticsChecked) {
        // ingestWindow only if NO continuous analytics
        currentOptions = { ingestWindow: analyticsOptions.ingestWindow };
      }
      onSubmit(e, fields, currentOptions);
    }
  }

  // Click Handler for Re-Initialize and Disable Analytics Checkboxes
  function handleAnalyticsCheckbox ({ currentTarget = {} }) {
    const { checked } = currentTarget;
    setIsAnalyticsChecked(checked);
  }

  // Handle Enable Analytics form value changes separately than rest of form
  function handleUpdateAnalyticsOptions (name, value) {
    setAnalyticsOptions((options) => ({ ...options, [name]: value }));
  }

  const { analyticWindow, spacing } = analyticsOptions;
  const analyticWindowField = fieldByName('analyticWindow');

  const validationRules = {
    analyticWindow: {
      ...analyticWindowField,
      required: false,
      isValid: (value) => {
        return analyticWindow >= spacing;
      }
    }
  };

  // Click handler for enabling / disabling Amplitude Change Detection (ACD)
  function handleAmplitudeChangeDetectionCheckbox ({
    currentTarget: { checked } = {}
  }) {
    setIsAmplitudeChangeDetectionEnabled(checked);
  }

  // Click handler for enabling / disabling Vessel Classification (VC)
  function handleVesselClassificationCheckbox ({
    currentTarget: { checked } = {}
  }) {
    setIsVesselClassificationEnabled(checked);
  }

  function handleS3Checkbox ({
    currentTarget: { checked } = {}
  }) {
    setUseS3(checked);
  }

  const licenseDefaultValues = useMemo(
    () =>
      availableLicenses.map((licenseId) =>
        licenses.all.find((license) => license.value === licenseId)
      ),
    [availableLicenses, licenses]
  );
  const licenseOptions = useMemo(() => {
    const filteredOptionGroups = [];
    licenses.groupedOptions.forEach((optionGroup) => {
      const filteredOptions = optionGroup.options.filter(
        (option) => option.isShareable
      );
      if (filteredOptions.length) {
        filteredOptionGroups.push({
          ...optionGroup,
          options: filteredOptions
        });
      }
    });

    return filteredOptionGroups;
  }, [licenses.groupedOptions]);

  return (
    <Form
      className="form-admin-edit-organization"
      rules={validationRules}
      onSubmit={handleSubmit}
    >
      <FormRow>
        <p className="caption">All fields are required.</p>
      </FormRow>

      <FormInput
        id="addressId"
        type="hidden"
        required={true}
        defaultValue={addressId}
        disabled={disabled}
      />

      <FormRow>
        <FormInput
          id="organizationName"
          label="Organization Name"
          required={true}
          defaultValue={name}
          disabled={disabled}
          autoComplete="organization org"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="type"
          label="Organization Type"
          type="select"
          options={orgTypes}
          defaultValue={type}
          required={true}
          disabled={disabled}
          autoComplete="organization type"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="country"
          label="Country"
          type="select"
          options={countries}
          defaultValue={country}
          required={true}
          disabled={disabled}
          autoComplete="organization country"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="street"
          label="Street Address"
          defaultValue={street}
          required={true}
          disabled={disabled}
          autoComplete="organization street-address"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="city"
          label="City"
          defaultValue={city}
          required={true}
          disabled={disabled}
          autoComplete="organization address-level2"
        />
        <FormInput
          id="state"
          label="State / Province"
          defaultValue={state}
          required={true}
          disabled={disabled}
          autoComplete="organization address-level1"
        />
        <FormInput
          id="postalCode"
          label="Postal Code"
          defaultValue={postalCode}
          required={true}
          disabled={disabled}
          autoComplete="organization postal-code"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="salesforceAccountId"
          label="Salesforce Account ID"
          defaultValue={salesforceAccountId}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <h4 className="form-subheader">Analytics:</h4>
      </FormRow>

      <FormRow>
        {isAnalyticsAlreadyEnabled ? (
          <InputButton
            id="disableAnalytics"
            label="GCM Module"
            type="checkbox"
            isChecked={isAnalyticsAlreadyEnabled}
            disabled={disabled}
            onChange={handleAnalyticsCheckbox}
          />
        ) : !isAnalyticsInitialized ? (
          <FormAnalyticsPanel
            isAnalyticsEnabled={isAnalyticsChecked || isAnalyticsAlreadyEnabled}
            isContinuousEnabled={isContinuousAnalyticsChecked}
            handleAnalyticsCheckbox={(e, { isChecked }) =>
              setIsAnalyticsChecked(isChecked)
            }
            handleContinuousCheckbox={(e, { isChecked }) =>
              setIsContinuousAnalyticsChecked(isChecked)
            }
            analyticsOptions={analyticsOptions}
            handleUpdateAnalyticsOptions={handleUpdateAnalyticsOptions}
            disabled={disabled}
          />
        ) : (
          <InputButton
            id="reenableAnalytics"
            label="GCM Module"
            type="checkbox"
            isChecked={!isAnalyticsInitialized}
            disabled={disabled}
            onChange={handleAnalyticsCheckbox}
            className="reenable-analytics"
          />
        )}
      </FormRow>
      <FormRow>
        <InputButton
          id="enableAmplitudeChangeDetection"
          label="Amplitude Change Detection (ACD)"
          type="checkbox"
          isChecked={isAmplitudeChangeDetectionEnabled}
          disabled={disabled}
          onChange={handleAmplitudeChangeDetectionCheckbox}
          controlChecked={true}
        />
      </FormRow>
      {flags.vesselClassificationEnabled &&
        <FormRow>
          <InputButton
            id="enableVesselClassification"
            label="Vessel Classification (VC)"
            type="checkbox"
            isChecked={isVesselClassificationEnabled}
            disabled={disabled}
            onChange={handleVesselClassificationCheckbox}
            controlChecked={true}
          />
        </FormRow>}

      <FormRow>
        <h4 className="form-subheader">Available Licenses:</h4>
      </FormRow>
      <FormRow>
        <FormInput
          key={licenseDefaultValues}
          id="availableLicenses"
          type="multiselect"
          disabled={disabled}
          options={licenseOptions}
          defaultValue={licenseDefaultValues}
        />
      </FormRow>
      <FormRow>
        <h4 className="form-subheader">Push to S3:</h4>
      </FormRow>
      <ReactTooltip
        id="pushToS3Tip"
        place="top"
        effect="solid"
        type="dark"
        multiline={true}
        className="customTip"
      >
        <div>
          Enable this feature and enter AWS details to enable the Capella
          platform to deliver imagery directly to your S3 bucket.
        </div>
      </ReactTooltip>
      <FormRow>
        <>
          <InputButton
            id="pushToS3"
            label="Push to Amazon S3"
            type="checkbox"
            isChecked={useS3}
            disabled={disabled}
            onChange={handleS3Checkbox}
            className="pushToS3Wrapper"
          />
          <span className="general-tool-tip" data-tip data-for="pushToS3Tip">
            ?
          </span>
        </>
      </FormRow>
      {useS3 && (
        <S3BucketConfigTable
          s3BucketConfigs={s3BucketConfigs}
          orgId={organization.id}
          isAdmin={true}
        />
      )}

      <FormRow className="form-row-actions">
        <Button full={true} disabled={disabled}>
          Save Changes
        </Button>
      </FormRow>
    </Form>
  );
};

FormAdminEditOrganization.propTypes = {
  organization: PropTypes.object,
  licenses: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool
};

export default FormAdminEditOrganization;
