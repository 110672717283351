import {
  exportClassData,
  updateClassData,
  destroyClassData
} from '../lib/util';
import { formatDateTime } from 'lib/datetime';

import User from '../models/user';
import Contract from '../models/contract';

const defaultOrganization = {
  address: {
    city: null,
    state: null,
    street: null,
    zip: null
  },
  childOrganizations: [],
  contract: new Contract(),
  contractType: null,
  createdAt: null,
  id: null,
  name: null,
  resellerId: null,
  salesforceAccountId: null,
  subResellerId: null,
  type: null,
  updatedAt: null,
  users: [],
  roles: [],
  s3PushEnabled: null,
  s3BucketConfigs: [],
  availableLicenses: []
};

class Organization {
  constructor (data = {}) {
    Object.assign(this, defaultOrganization);
    this.update(data);
  }

  data () {
    return exportClassData(this);
  }

  update (data) {
    const { contract = {}, users = [], childOrganizations = [] } = data;

    const { type: contractType = null } = contract || {};

    if (contract) {
      this.contract.update(contract);
    }

    return updateClassData(this, {
      ...data,
      contractType,
      childOrganizations: childOrganizations.map((org) => {
        return org instanceof Organization ? org : new Organization(org);
      }),
      users: users.map((user) => {
        return user instanceof User ? user : new User(user);
      })
    });
  }

  destroy () {
    return destroyClassData(this);
  }

  get usersCount () {
    return this.users.length;
  }

  get createdAtFormatted () {
    return formatDateTime(this.createdAt);
  }

  get updatedAtFormatted () {
    return formatDateTime(this.updatedAt);
  }
}

export default Organization;
