import React from 'react';

import { useUser, useResellerFetchOrganization } from 'hooks';
import { routePathByName } from 'lib/routes';
import {
  ROUTE_RESELLER,
  ROUTE_RESELLER_ORG_CREATE,
  ROUTE_RESELLER_ORG_DETAILS
} from 'data/route-names';

import { TemplateOrganizations } from 'commonLib';

const breadcrumbs = [
  {
    label: 'Reseller',
    to: routePathByName(ROUTE_RESELLER)
  }
];

const ResellerOrganizationsPage = () => {
  let isLoadingUser;

  const { user = {} } = useUser();

  if (!user.organizationId) {
    isLoadingUser = true;
  }

  // Determine if we're in a loading state between the various requests

  const { isLoading: isLoadingOrganization, organization = {} } =
    useResellerFetchOrganization({
      id: user.organizationId
    });

  const isLoading = isLoadingUser || isLoadingOrganization;

  const organizationsState = {
    isLoading
  };

  const templateOptions = {
    detailsRouteName: ROUTE_RESELLER_ORG_DETAILS,
    createRouteName: ROUTE_RESELLER_ORG_CREATE
  };

  return (
    <TemplateOrganizations
      organizations={organization.childOrganizations}
      breadcrumbs={breadcrumbs}
      state={organizationsState}
      options={templateOptions}
    />
  );
};

export default ResellerOrganizationsPage;
