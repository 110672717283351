import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  FormRow,
  FormInput,
  InputButton,
  InputRangeExtended,
  ToggleButtonGroup
} from 'fogg/ui';
import ReactDatetime from 'react-datetime';
import InputRange from 'react-input-range';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { TaskRequestTypes } from 'data/task-request-types';
import { TaskCollectionTiers } from 'data/task-collection-tiers';
import { TaskCollectModes } from 'data/task-collect-modes';
import {
  taskDefaults,
  sliderValuesAndRange,
  getLookAngleRange,
  getLookAngleDefaults,
  getGRRRange,
  getGRRDefaults,
  isPointGeometryType,
  getAvailableAnalyticsCollectModes
} from 'lib/tasks';
import { chompFloat } from 'lib/util';
import { fieldByName } from 'lib/fields';
import { useTaskContext, useUser } from 'hooks';

import Tooltip from './Tooltip';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';
import { OptionsPanel } from 'commonLib';

const FormCreateTaskStandard = ({
  primaryTaskFields,
  footerChildren,
  onSubmit,
  onCancel,
  children,
  geoJson = {},
  enableCollectTimeSelect = false
}) => {
  const isPointTask = isPointGeometryType({ geoJson });
  const { taskState, updateTaskState } = useTaskContext();
  // Verify which analytics the user's org has enabled
  const {
    user: {
      organization = {}
    } = {}
  } = useUser();
  const { permittedCollectionTiers = {} } = organization;
  const { taskingRequest = [] } = permittedCollectionTiers;

  // Rely on user org's permitted task tiers
  const taskingTierOptions = taskDefaults.AVAILABLE_COLLECTION_TIERS.filter(
    ({ value }) => taskingRequest.includes(value)
  );

  // Show the Analytics options enabled both, globally, and per-organization
  const flags = useFlags();
  const analyticsOptions = AnalyticProducts.Types
    .filter(o => !o.repeatOnly)
    .filter(o => o.orgEnabledFlagId === undefined || organization[o.orgEnabledFlagId])
    .filter(o => o.globalEnabledFlagId === undefined || flags[o.globalEnabledFlagId]);

  // Task form defaults, which are different for Area (non-Point) tasks
  const {
    collectConstraints: {
      azrMax = isPointTask ? taskDefaults.DEFAULT_AZR_MAX : taskDefaults.area.DEFAULT_AREA_TASK_AZR_MAX,
      azrMin = isPointTask ? taskDefaults.DEFAULT_AZR_MIN : taskDefaults.area.DEFAULT_AREA_TASK_AZR_MIN,
      grrMax = isPointTask ? taskDefaults.DEFAULT_GRR_MAX : taskDefaults.area.DEFAULT_AREA_TASK_GRR_MAX,
      grrMin = isPointTask ? taskDefaults.DEFAULT_GRR_MIN : taskDefaults.area.DEFAULT_AREA_TASK_GRR_MIN,
      imageLength = isPointTask ? taskDefaults.DEFAULT_IMAGE_LENGTH : taskDefaults.area.DEFAULT_AREA_TASK_IMAGE_LENGTH,
      imageWidth = isPointTask ? taskDefaults.DEFAULT_IMAGE_WIDTH : taskDefaults.area.DEFAULT_AREA_TASK_IMAGE_WIDTH,
      numLooks = isPointTask ? taskDefaults.DEFAULT_NUM_LOOKS_SPOTLIGHT : null,
      offNadirMin = taskDefaults.DEFAULT_OFF_NADIR_MIN,
      offNadirMax = taskDefaults.DEFAULT_OFF_NADIR_MAX,
      polarization,
      targetHeight = null,
      localTime = taskDefaults.DEFAULT_LOCAL_TIME
    } = {},
    collectMode = isPointTask ? taskDefaults.DEFAULT_COLLECT_MODE : TaskCollectModes.STRIPMAP,
    customCloseDate = false,
    ascDsc = '',
    lookDirection = '',
    orbitalPlanes,
    preApproval = false,
    productCategory = taskDefaults.DEFAULT_PRODUCT_CATEGORY,
    archiveHoldback = null,
    taskingTier = taskDefaults.DEFAULT_TASKING_TIER,
    windowCloseInt,
    windowOpenInt,
    analytics,
    isRetask = false,
    fromArchive = false,
    customAttribute1,
    customAttribute2
  } = taskState;

  const collectionTier = taskState.collectionTier ||
        taskDefaults.DEFAULT_COLLECTION_TIER;

  const isNotSpotlightMode =
    collectMode !== TaskCollectModes.SLIDING_SPOTLIGHT && collectMode !== TaskCollectModes.SPOTLIGHT;
  const numLooksHidden = collectMode === TaskCollectModes.STRIPMAP;
  const imageLengthDisabled = collectMode === TaskCollectModes.SPOTLIGHT;
  const imageWidthDisabled = collectMode === TaskCollectModes.SPOTLIGHT;
  const showCategoryOptions = productCategory === 'custom';
  const showLookAngleRange = showCategoryOptions && (!isRetask || fromArchive);
  const showTargetHeight = targetHeight !== null;
  const isAreaStripmap = !isPointTask && collectMode === TaskCollectModes.STRIPMAP;

  const returnOrbitalPlanesSelection = (selections = []) => {
    if (selections?.length) {
      const array = taskDefaults.AVAILABLE_ORBITAL_PLANES.filter((option) => {
        const { value } = option;
        return selections.includes(value);
      });
      return array;
    } else return [];
  };

  const activeAnalytic = analytics && analytics.length ? analytics[0] : undefined;
  // Reset any ACD-specific defaults that are invalid on Standard Task
  if (activeAnalytic === 'ACD') {
    updateTaskState({
      analytics: undefined,
      repetitionProperties: {
        lookAngleTolerance: undefined,
        maintainSceneFraming: false,
        repeatCycle: undefined
      },
      productCategory: taskDefaults.DEFAULT_PRODUCT_CATEGORY
    });
  }

  /**
   * input ref and useEffect keeps the style of the floating number element for a slider updated depending on it's position
   */
  const inputRef = useRef();
  useEffect(() => {
    if (inputRef && inputRef.current) {
      const inputBox = inputRef.current;
      const rangeValue = inputBox.querySelector('#rangeValue');
      localStorage.setItem('rangeFloatStyle', rangeValue.style.left);
      rangeValue.style.left = localStorage.getItem('rangeFloatStyle');
      const timer = setTimeout(() => {
        rangeValue.style.left = localStorage.getItem('rangeFloatStyle');
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [targetHeight]);

  /**
   * Event handlers
   */
  function handleOnWindowOpenChange ({ target = {} } = {}) {
    const { value } = target;
    const open = value && new Date(parseInt(value));
    const close = new Date(open);
    close.setUTCDate(close.getUTCDate() + parseInt(taskingTier));
    let closed = new Date(close).valueOf();
    let isCustomClose = false;

    // if close date was customized, and close date is valid, preserve the close date
    if (customCloseDate && windowCloseInt > open.valueOf()) {
      closed = windowCloseInt;
      isCustomClose = true;
    }

    updateTaskState({
      windowOpenInt: parseInt(value, 10),
      windowCloseInt: closed,
      shouldRefreshAccessTimes: true,
      customCloseDate: isCustomClose
    });
  }

  function handleOnWindowCloseChange ({ target = {} } = {}) {
    const { value } = target;

    if (value) {
      updateTaskState({
        shouldRefreshAccessTimes: true,
        windowCloseInt: parseInt(value, 10),
        customCloseDate: true
      });
    } else {
      updateTaskState({
        customCloseDate: false
      });
    }
  }

  function handleOnTaskingTierChange (target) {
    const open = windowOpenInt && new Date(windowOpenInt);
    const days = (TaskCollectionTiers.properties[target.value] || {}).days;
    const close = new Date(open);
    close.setUTCDate(close.getUTCDate() + parseInt(days));
    const closed = new Date(close).valueOf();

    updateTaskState({
      shouldRefreshAccessTimes: true,
      taskingTier: parseInt(days, 10),
      collectionTier: target.value,
      windowCloseInt: !customCloseDate ? closed : windowCloseInt
    });
  }

  function handleCollectMode (selection) {
    const { value: collectModeValue } = selection;
    const lookAngleDefaults = getLookAngleDefaults(collectModeValue, productCategory);
    const grrDefaults = getGRRDefaults(collectModeValue);

    // init default task state values when collect mode changes
    // these values are for 'spotlight' collectMode
    let newTaskStateValue = {
      imageWidthDisabled: true,
      imageLengthDisabled: true,
      numLooksHidden: false,
      collectConstraints: {
        offNadirMin: lookAngleDefaults.minValue,
        offNadirMax: lookAngleDefaults.maxValue,
        grrMin: grrDefaults.minValue,
        grrMax: grrDefaults.maxValue,
        azrMin: taskDefaults.DEFAULT_AZR_MIN,
        azrMax: taskDefaults.DEFAULT_AZR_MAX,
        imageWidth: taskDefaults.DEFAULT_IMAGE_WIDTH,
        imageLength: taskDefaults.DEFAULT_IMAGE_LENGTH,
        numLooks: taskDefaults.DEFAULT_NUM_LOOKS_SPOTLIGHT
      }
    };

    if (collectModeValue === TaskCollectModes.SLIDING_SPOTLIGHT) {
      newTaskStateValue = {
        imageWidthDisabled: false,
        imageLengthDisabled: false,
        numLooksHidden: false,
        collectConstraints: {
          offNadirMin: lookAngleDefaults.minValue,
          offNadirMax: lookAngleDefaults.maxValue,
          grrMin: grrDefaults.minValue,
          grrMax: grrDefaults.maxValue,
          azrMin: 1.0,
          azrMax: 1.0,
          imageWidth: null,
          imageLength: null,
          numLooks: taskDefaults.DEFAULT_NUM_LOOKS
        }
      };
    }

    if (collectModeValue === TaskCollectModes.STRIPMAP) {
      newTaskStateValue = {
        imageWidthDisabled: false,
        imageLengthDisabled: false,
        numLooksHidden: true,
        collectConstraints: {
          offNadirMin: lookAngleDefaults.minValue,
          offNadirMax: lookAngleDefaults.maxValue,
          grrMin: grrDefaults.minValue,
          grrMax: grrDefaults.maxValue,
          azrMin: 1.2,
          azrMax: 1.2,
          // Area Stripmaps have 8k imageWidth default
          imageWidth: isPointTask
            ? taskDefaults.DEFAULT_IMAGE_WIDTH
            : taskDefaults.area.DEFAULT_AREA_TASK_IMAGE_WIDTH,
          // Area Stripmaps have 100k imageLength default
          imageLength: isPointTask
            ? 20000
            : taskDefaults.area.DEFAULT_AREA_TASK_IMAGE_LENGTH,
          numLooks: null
        }
      };
    }

    // set the state for task and collectConstraints
    updateTaskState({
      collectMode: collectModeValue,
      shouldRefreshAccessTimes: true,
      ...newTaskStateValue
    });
  }

  function handleMinMaxSliderChange (value, stateCallback) {
    const { min, max } = value;
    const updatedValue = {
      min: typeof min === 'number' ? chompFloat(min, 2) : min,
      max: typeof max === 'number' ? chompFloat(max, 2) : max
    };

    // this callback can be used to save the state right in the UI component
    if (typeof stateCallback === 'function') {
      stateCallback(updatedValue);
    }
  }

  /**
   * standard task submit handler
   */
  function handleSubmit (e, fields) {
    let payload = {
      ...fields,
      mode: { value: 'void' },
      windowDuration: { value: (windowCloseInt - windowOpenInt) / 1000 },
      taskRequestType: { value: TaskRequestTypes.STANDARD }
    };

    // set the date values as an ISO string so we can send that to the API
    payload.windowClose.value = new Date(windowCloseInt).toISOString();
    payload.windowOpen.value = new Date(windowOpenInt).toISOString();
    payload.collectMode.value = collectMode;
    payload.collectionTier.value = collectionTier;
    payload.ascDsc.value = ascDsc;
    payload.lookDirection.value = lookDirection;
    payload.polarization.value = polarization;
    payload.productCategory.value = productCategory;
    payload.archiveHoldback.value = archiveHoldback;
    payload.preApproval.value = preApproval;
    if (fields.orbitalPlanes) {
      payload.orbitalPlanes.value = orbitalPlanes;
    }
    if (targetHeight !== null) {
      payload.targetHeight = { value: parseInt(targetHeight, 10) };
    }
    // Always pass stripmap defaults for Area Stripmaps
    if (isAreaStripmap) {
      payload.imageWidth = { value: imageWidth };
      payload.imageLength = { value: imageLength };
      payload.grrMin = { value: grrMin };
      payload.grrMax = { value: grrMax };
      payload.azrMin = { value: azrMin };
      payload.azrMax = { value: azrMax };
      delete payload.numLooks;
    }

    // look angle
    payload.offNadirMin = { value: offNadirMin };
    payload.offNadirMax = { value: offNadirMax };
    payload.localTime = { value: localTime };
    payload.analytics = { value: analytics };

    if (showCategoryOptions) {
      payload = {
        ...payload,
        imageWidth: { value: imageWidth },
        imageLength: { value: imageLength },
        grrMin: { value: grrMin },
        grrMax: { value: grrMax },
        azrMin: { value: azrMin },
        azrMax: { value: azrMax },
        numLooks: { value: numLooks }
      };
    }

    if (typeof onSubmit === 'function') {
      onSubmit(e, payload);
    }
  }

  /**
   * validation rules for handleSubmit
   */
  const validationRules = {
    windowOpen: {
      ...fieldByName('windowOpen'),
      required: true,
      isValid: (date) => {
        return date > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    windowClose: {
      ...fieldByName('windowClose'),
      isValid: () => {
        if (windowCloseInt < windowOpenInt) return false;
        return windowCloseInt > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    customAttribute1: {
      ...fieldByName('customAttribute1'),
      isValid: () => {
        return customAttribute1?.length > 1;
      }
    },
    customAttribute2: {
      ...fieldByName('customAttribute2'),
      isValid: () => {
        return customAttribute2?.length > 1;
      }
    }
  };

  // render
  return (
    <Form
      className="create-task-form"
      onSubmit={handleSubmit}
      rules={validationRules}
    >
      {primaryTaskFields}

      <FormRow>
        <FormInput
          id="analytics"
          type="select"
          label="Analytics"
          clearable={true}
          disabled={isRetask}
          defaultValue={activeAnalytic}
          className="analytics-select"
          options={analyticsOptions}
          onChange={(selection) => {
            const { value: analyticsValue = undefined } = selection || {};
            updateTaskState({
              analytics: analyticsValue ? [analyticsValue] : undefined
            });
          }}
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="windowOpen"
          label="Window Open (UTC)"
          type="datetime"
          value={windowOpenInt}
          closeOnSelectDate={true}
          allowPastDate={false}
          utc={true}
          validationMessage="Please select a window start time after the current time"
          onChange={handleOnWindowOpenChange}
        />
        <FormInput
          id="collectionTier"
          type="select"
          label="Tasking Tier"
          clearable={false}
          options={taskingTierOptions}
          defaultValue={collectionTier}
          required={true}
          onChange={handleOnTaskingTierChange}
        />
      </FormRow>

      {enableCollectTimeSelect && (
        <FormRow className="form-row-block form-row-toggle-group">
          <label>Collection Time</label>
          <ToggleButtonGroup
            id="localTime"
            className={`${isRetask ? 'disabled disabled-visually' : ''}`}
            activeValue={localTime}
            options={taskDefaults.AVAILABLE_COLLECT_TIMES.options}
            onChange={(value) => updateTaskState({
              collectConstraints: {
                localTime: value
              },
              shouldRefreshAccessTimes: true
            })}
          />
        </FormRow>
      )}

      <FormRow>
        <FormInput
          id="collectMode"
          type="select"
          label="Imaging Mode"
          clearable={false}
          disabled={isRetask}
          options={
            !activeAnalytic
              ? taskDefaults.AVAILABLE_COLLECT_MODES
              : getAvailableAnalyticsCollectModes(activeAnalytic)
          }
          defaultValue={collectMode}
          onChange={handleCollectMode}
        />
      </FormRow>

      {children}

      <OptionsPanel label="Optional Parameters" id="optional-parameters" defaultIsOpen={isRetask}>
        <FormRow className="optional-parameters-window-close">
          <FormInput
            id="windowClose"
            label="Window Close (UTC)"
            type="datetime"
            value={windowCloseInt}
            disableFrom={{
              from: ReactDatetime.moment.utc(new Date(windowOpenInt))
            }}
            closeOnSelectDate={true}
            showClear={true}
            allowPastDate={false}
            utc={true}
            validationMessage="Please select a window close time after the tasking tier period"
            onChange={handleOnWindowCloseChange}
          />
        </FormRow>
        <FormRow>
          <InputButton
            id="preApproval"
            label="Pre-Approval"
            type="checkbox"
            value="true"
            isChecked={preApproval}
            disabled={isRetask}
            onChange={({ currentTarget = {} }) => {
              const { checked } = currentTarget;
              updateTaskState({
                preApproval: checked
              });
            }}
          />
        </FormRow>
        <FormRow className="horizontal-button-list">
          <div className="search-dropdowns" data-testid="orbitalPlanes">
            <FormInput
              id="orbitalPlanes"
              name="orbitalPlanes"
              type="multiselect"
              label="Orbital Plane"
              clearable={false}
              disabled={isRetask}
              options={taskDefaults.AVAILABLE_ORBITAL_PLANES}
              value={returnOrbitalPlanesSelection(orbitalPlanes)}
              onChange={(selections) => {
                let selectionsArray;
                if (selections?.length && selections.length > 0) {
                  selectionsArray = selections.map(({ value }) => value);
                }

                updateTaskState({
                  shouldRefreshAccessTimes: true,
                  windowCloseInt: windowCloseInt,
                  orbitalPlanes: selectionsArray
                });
              }}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="search-dropdowns" data-testid="orbitalState">
            <FormInput
              id="ascDsc"
              type="select"
              label="Orbit State"
              clearable={true}
              disabled={isRetask}
              options={taskDefaults.AVAILABLE_ASC_DSC}
              defaultValue={ascDsc}
              onChange={(selection) => {
                const { value: ascDscValue = '' } = selection || {};

                updateTaskState({
                  shouldRefreshAccessTimes: true,
                  windowCloseInt: windowCloseInt,
                  ascDsc: ascDscValue
                });
              }}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="search-dropdowns" data-testid="observationDirection">
            <FormInput
              id="lookDirection"
              type="select"
              label="Observation Direction"
              clearable={true}
              disabled={isRetask}
              options={taskDefaults.AVAILABLE_LOOK_DIRECTIONS}
              defaultValue={lookDirection}
              onChange={(selection) => {
                const { value: lookDirectionValue = undefined } =
                  selection || {};

                updateTaskState({
                  shouldRefreshAccessTimes: true,
                  windowCloseInt: windowCloseInt,
                  lookDirection: lookDirectionValue
                });
              }}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="search-dropdowns" data-testid="polarization">
            <FormInput
              id="polarization"
              type="select"
              label="Polarization"
              clearable={true}
              disabled={isRetask}
              options={taskDefaults.AVAILABLE_POLARIZATION}
              defaultValue={polarization}
              onChange={(selection) =>
                updateTaskState({
                  collectConstraints: {
                    polarization: selection?.value || undefined
                  }
                })
              }
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="search-dropdowns" data-testid="targetHeight">
            <FormInput
              id="showTargetHeight"
              type="select"
              label="Target Height"
              clearable={false}
              disabled={isRetask}
              options={taskDefaults.SHOW_TARGET_HEIGHT}
              onChange={({ value }) =>
                updateTaskState({
                  collectConstraints: {
                    targetHeight: value === 'custom' ? targetHeight || 0 : null
                  }
                })
              }
              defaultValue={targetHeight ? 'custom' : 'default'}
            />
          </div>
        </FormRow>
        {showTargetHeight && (
          <FormRow>
            <div className="search-filters-range-slider" ref={inputRef}>
              <InputRangeExtended
                className={`${isRetask ? 'disabled disabled-visually' : ''}`}
                minValue={-1000}
                maxValue={9000}
                value={targetHeight || 0}
                onChangeComplete={(value) =>
                  updateTaskState({
                    collectConstraints: {
                      targetHeight: parseInt(value, 10)
                    }
                  })
                }
                step={1}
                metric={'m'}
                onChangeDelay={0.3}
              />
            </div>
          </FormRow>
        )}
        <FormRow className="form-row-block">
          <label className="form-label standalone-label" htmlFor="archiveHoldback">
            Archive Catalog Holdback
            <Tooltip
              className="customer-internal-id-tip"
              id="archiveHoldbackTip"
              multiline={true}
              tooltipText="Archive catalog holdback is an option for customers to delay the time in which a tasked image is included in the publicly available imagery archive. For a value of 'None', imagery will immediately flow into the public archive. For any value other than 'None', an uplift charge will be included in the final task price and the imagery will only appear in the public archive after the holdback period. If no value is provided, it will automatically be set to the default value defined by the contract selected during the next step."
            />
          </label>
          <div className="search-dropdowns" data-testid="archiveHoldback">
            <FormInput
              type="select"
              id="archiveHoldback"
              className="archive-holdback-select"
              clearable={true}
              options={taskDefaults.ARCHIVE_HOLDBACK_VALUES}
              defaultValue={archiveHoldback}
              onChange={({ value }) => {
                updateTaskState({
                  archiveHoldback: value
                });
              }}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="search-dropdowns" data-testid="productCategory">
            <FormInput
              id="productCategory"
              type="select"
              label="Product Category"
              clearable={false}
              disabled={isRetask}
              options={taskDefaults.AVAILABLE_PRODUCT_CATEGORIES}
              defaultValue={productCategory}
              onChange={(selection) => {
                const { value: productCategoryValue } = selection;
                const lookAngleDefaults = getLookAngleDefaults(collectMode, productCategoryValue);
                const updatedConstraints = {
                  offNadirMin: lookAngleDefaults.minValue,
                  offNadirMax: lookAngleDefaults.maxValue
                };

                if (isAreaStripmap) {
                  // Specific imageWidth & Length for Stripmap Area Tasks
                  updatedConstraints.imageWidth = taskDefaults.area.DEFAULT_AREA_TASK_IMAGE_WIDTH;
                  updatedConstraints.imageLength = taskDefaults.area.DEFAULT_AREA_TASK_IMAGE_LENGTH;
                }

                updateTaskState({
                  shouldRefreshAccessTimes: true,
                  windowCloseInt: windowCloseInt,
                  productCategory: selection?.value,
                  collectConstraints: {
                    ...updatedConstraints
                  }
                });
              }}
            />
          </div>
        </FormRow>

        {showCategoryOptions && (
          <div className="custom-fields">
            {showLookAngleRange && (
              <FormRow>
                <div
                  className='search-filters-range-slider'
                  data-testid="look-angle"
                >
                  <label className="form-label-slider">Look Angle</label>
                  <InputRange
                    {...sliderValuesAndRange({
                      defaultValueMin: offNadirMin,
                      defaultValueMax: offNadirMax,
                      defaultRangeMin: getLookAngleRange(collectMode, productCategory).minValue,
                      defaultRangeMax: getLookAngleRange(collectMode, productCategory).maxValue,
                      valueSideEffects: {
                        min: offNadirMin,
                        max: offNadirMax
                      },
                      rangeSideEffects: () => {
                        return getLookAngleRange(collectMode, productCategory);
                      }
                    })}
                    disabled={isRetask && !fromArchive}
                    onChange={(value) =>
                      handleMinMaxSliderChange(value, (updatedValue) => {
                        updateTaskState({
                          shouldRefreshAccessTimes: true,
                          windowCloseInt: windowCloseInt,
                          collectConstraints: {
                            offNadirMin: updatedValue.min,
                            offNadirMax: updatedValue.max
                          }
                        });
                      })
                    }
                  />
                </div>
              </FormRow>
            )}

            <FormRow>
              <div
                className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                data-testid="ground-range"
              >
                <label className="form-label-slider">Ground Range Resolution</label>
                <InputRange
                  {...sliderValuesAndRange({
                    defaultValueMin: grrMin,
                    defaultValueMax: grrMax,
                    defaultRangeMin: getGRRRange(collectMode).minValue,
                    defaultRangeMax: getGRRRange(collectMode).maxValue,
                    valueSideEffects: {
                      min: grrMin,
                      max: grrMax
                    },
                    rangeSideEffects: () => {
                      return getGRRRange(collectMode);
                    }
                  })}
                  step={0.05}
                  formatLabel={(value) => `${value}m`}
                  disabled={isRetask}
                  onChange={(value) =>
                    handleMinMaxSliderChange(value, (updatedValue) => {
                      updateTaskState({
                        collectConstraints: {
                          grrMin: updatedValue.min,
                          grrMax: updatedValue.max
                        }
                      });
                    })
                  }
                />
              </div>
            </FormRow>
            <FormRow>
              <div
                className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                data-testid="azimuth-resolution"
              >
                <label className="form-label-slider">Azimuth Resolution</label>
                <InputRange
                  {...sliderValuesAndRange({
                    defaultValueMin: taskDefaults.DEFAULT_AZR_MIN,
                    defaultValueMax: taskDefaults.DEFAULT_AZR_MAX,
                    defaultRangeMin: taskDefaults.DEFAULT_AZR_MIN_RANGE,
                    defaultRangeMax: taskDefaults.DEFAULT_AZR_MAX_RANGE,
                    valueSideEffects: {
                      min: azrMin,
                      max: azrMax
                    },
                    rangeSideEffects: ({ minValue, maxValue }) => {
                      if (collectMode === TaskCollectModes.STRIPMAP) {
                        return {
                          minValue: 1.2,
                          maxValue: 11.5
                        };
                      }

                      if (collectMode === TaskCollectModes.SLIDING_SPOTLIGHT) {
                        return {
                          minValue: 0.7,
                          maxValue: 5.0
                        };
                      }

                      return {
                        minValue,
                        maxValue
                      };
                    }
                  })}
                  step={0.01}
                  formatLabel={(value) => `${value}m`}
                  disabled={isRetask}
                  onChange={(value) =>
                    handleMinMaxSliderChange(value, (updatedValue) => {
                      updateTaskState({
                        collectConstraints: {
                          azrMin: updatedValue.min,
                          azrMax: updatedValue.max
                        }
                      });
                    })
                  }
                />
              </div>
            </FormRow>

            {isNotSpotlightMode && (
              <FormRow>
                <div
                  className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                  data-testid="image-width"
                >
                  <label className="form-label-slider">Image Width</label>
                  <InputRange
                    minValue={5000}
                    maxValue={10000}
                    value={imageWidth}
                    step={1000}
                    formatLabel={(value) => `${value / 1000}km`}
                    disabled={imageWidthDisabled || isRetask}
                    onChange={(value) =>
                      updateTaskState({
                        collectConstraints: {
                          imageWidth: value
                        }
                      })
                    }
                  />
                </div>
              </FormRow>
            )}

            {isNotSpotlightMode && (
              <FormRow>
                <div
                  className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                  data-testid="image-length"
                >
                  <label className="form-label-slider">Image Length</label>
                  <InputRange
                    {...sliderValuesAndRange({
                      defaultRangeMin: 0,
                      defaultRangeMax: 10000,
                      valueSideEffects: imageLength,
                      rangeSideEffects: ({ minValue, maxValue }) => {
                        if (collectMode === TaskCollectModes.STRIPMAP) {
                          return {
                            minValue: 20000,
                            maxValue: 200000
                          };
                        }

                        if (collectMode === TaskCollectModes.SLIDING_SPOTLIGHT) {
                          return {
                            minValue: 5000,
                            maxValue: 50000
                          };
                        }

                        return {
                          minValue,
                          maxValue
                        };
                      }
                    })}
                    step={1000}
                    formatLabel={(value) => `${value / 1000}km`}
                    disabled={imageLengthDisabled || isRetask}
                    onChange={(value) =>
                      updateTaskState({
                        collectConstraints: {
                          imageLength: value
                        }
                      })
                    }
                  />
                </div>
              </FormRow>
            )}

            {!numLooksHidden && (
              <FormRow>
                <div
                  className="search-filters-range-slider"
                  data-testid="number-looks"
                >
                  <label className="form-label-slider">Number of Looks</label>
                  <InputRange
                    {...sliderValuesAndRange({
                      defaultRangeMin: 1,
                      defaultRangeMax: 9,
                      valueSideEffects: numLooks,
                      rangeSideEffects: ({ minValue, maxValue }) => {
                        if (collectMode === TaskCollectModes.STRIPMAP) {
                          return {
                            minValue: 1,
                            maxValue: 5
                          };
                        }

                        if (collectMode === TaskCollectModes.SLIDING_SPOTLIGHT) {
                          return {
                            minValue: 1,
                            maxValue: 5
                          };
                        }

                        return {
                          minValue,
                          maxValue
                        };
                      }
                    })}
                    value={numLooks}
                    step={1}
                    formatLabel={(value) => `${value}`}
                    disabled={isRetask}
                    onChange={(value) =>
                      updateTaskState({
                        collectConstraints: {
                          numLooks: value
                        }
                      })
                    }
                  />
                </div>
              </FormRow>
            )}
          </div>
        )}

        <FormRow className="form-row-block">
          <label className="form-label standalone-label" htmlFor="customAttribute1">
            Custom Attribute 1
            <Tooltip
              className="customer-internal-id-tip"
              id="customAttribute1Tip"
              multiline={true}
              tooltipText='Attribute to help customers track a Capella task with their internal systems'
            />
          </label>
          <FormInput
            id="customAttribute1"
            defaultValue={customAttribute1}
            onChange={({ target: { value = '' } }) =>
              updateTaskState({
                customAttribute1: value
              })
            }
            validationMessage="2 character minimum for ID"
          />
        </FormRow>
        <FormRow className="form-row-block">
          <label className="form-label standalone-label" htmlFor="customAttribute2">
            Custom Attribute 2
            <Tooltip
              className="customer-po-id-tip"
              id="customAttribute2Tip"
              multiline={true}
              tooltipText='Attribute to help customers track a Capella task with their internal systems'
            />
          </label>
          <FormInput
            id="customAttribute2"
            defaultValue={customAttribute2}
            onChange={({ target: { value = '' } }) =>
              updateTaskState({
                customAttribute2: value
              })
            }
            validationMessage="2 character minimum for ID"
          />
        </FormRow>

      </OptionsPanel>

      {footerChildren}

      <FormRow className="form-row-actions">
        <Button>Create Task</Button>

        <Button type="text" onClick={onCancel}>
          Cancel Task Request
        </Button>
      </FormRow>
    </Form>
  );
};

FormCreateTaskStandard.propTypes = {
  primaryTaskFields: PropTypes.node,
  footerChildren: PropTypes.node,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  geoJson: PropTypes.object,
  enableCollectTimeSelect: PropTypes.bool
};

export default FormCreateTaskStandard;
