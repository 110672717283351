import { ACCESS_ROLES, ORG_MANAGER_ROLE } from '../../data/roles';
import { ORGANIZATION_SCOPE } from '../../data/scopes';
import { constructRequestActionManager } from '../../lib/actions';
import { routePathByName } from 'lib/routes';
import { setPropertyIfExists } from '../../lib/util';
import {
  UPDATE_ORG_TASK,
  UPDATE_ORG_TASKS,
  UPDATE_ORG_REPEAT_REQUEST,
  UPDATE_ORG_REPEAT_REQUESTS,
  UPDATE_ORG_REPEAT_REQUESTS_PAGED,
  UPDATE_ORG_TASKS_PAGED,
  UPDATE_ORG_TASKS_COLUMNS,
  UPDATE_ORG_REPEAT_REQUESTS_COLUMNS
} from '../../data/action-types';
import {
  ROUTE_API_TASKS_SEARCH,
  ROUTE_API_REPEAT_REQUESTS_SEARCH,
  ROUTE_API_EXPORT_TASKS_CSV,
  ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV
} from '../../data/route-names';
import { DEFAULT_PAGING_LIMIT } from '../../data/tasks';
import { mapTaskTableField, taskFiltersToAPI } from '../../lib/tasks';
import {DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS, UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS} from "../../data";

/**
 * setOrgActionState
 * @description Updates the org store's status
 */

export function setOrgActionState (status) {
  return {
    type: 'UPDATE_ORG_ACTION_STATE',
    data: status
  };
}

const createDispatchRequestAction =
  constructRequestActionManager(setOrgActionState);

/**
 * fetchOrganization
 * @description Fetches the active organization via the token
 */

export const fetchOrganization = () => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrganization',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: routePathByName('apiOrganization'),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateOrganization(data));

    return data;
  };
};

/**
 * putUpdateOrganization
 * @description Updates an organization given the data object
 * @param {object} data
 */

export const putUpdateOrganization = (data) => {
  return async (dispatch, getState) => {
    const organizationData = {
      name: data.organizationName,
      address: {
        id: data.addressId,
        street: data.street,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country
      }
    };

    if (data.s3PushEnabled !== null) {
      organizationData.s3PushEnabled = data.s3PushEnabled;
    }

    const dispatchAction = createDispatchRequestAction({
      name: 'putUpdateOrganization',
      scope: 'admin',
      request: {
        url: routePathByName('apiOrganization'),
        method: 'put',
        data: organizationData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(updateOrganization(requestData));

    return requestData;
  };
};

/**
 * fetchAllOrgOrders
 * @description Fetches all orders for an organization
 */

export const fetchAllOrgOrders = (orgId) => {
  return async (dispatch, getState) => {
    const url = routePathByName('apiOrders', {
      params: {
        organizationId: orgId
      }
    });
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchAllOrgOrders',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: url,
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateOrgOrders(data));

    return data;
  };
};

/**
 * fetchAllOrgUsers
 * @description Fetches all user profiles for an organization
 */

export const fetchAllOrgUsers = (orgId) => {
  return async (dispatch, getState) => {
    const url = routePathByName('apiOrgUsers', {
      wildcard: [orgId]
    });
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchAllOrgUsers',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: url,
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateOrgUsers(data));

    return data;
  };
};

/**
 * fetchOrgUserById
 * @description Fetches an user profile by their ID using the organization manager scope
 * @param {string} userId
 */

export const fetchOrgUserById = (userId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrgUserById',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: `${routePathByName('apiUsers')}/${userId}`,
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateOrgUsers(data));

    return data;
  };
};

/**
 * fetchTaskById
 * @description Fetches a task by ID
 * @param {string} taskId ID of the organization
 */
export const fetchTaskById = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTaskById',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: routePathByName('apiTasksTask', {
          wildcard: [taskId]
        }),
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const { data } = request || {};

    dispatch(updateOrgTask(data));

    return data;
  };
};

/**
 * fetchTasksByOrgId
 * @description Fetches the tasks for a given organization
 * @param {string} orgId ID of the organization
 */
export const fetchTasksByOrgId = (orgId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksByOrgId',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: `${routePathByName('apiTask')}?organizationId=${orgId}`,
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const { data } = request || {};

    dispatch(updateOrgTasks(data));

    return data;
  };
};

/**
 * @description Filter based task search for Org Managers
 * @param {string} orgId ID of the organization to search
 * @param {object} paging pagination page & limit
 * @param {object} [filters={}] e.g. { filterName: ['value'] }
 * @param {object} [sortObj = {}] e.g. { sort: 'status', order: 'asc' }
 * @param {AbortController} [controller = AbortController] AbortController signal to cancel stale requests
 */

export const searchTasksByOrgId = (
  orgId,
  { page = 1, limit = DEFAULT_PAGING_LIMIT },
  filters = {},
  sortObj = {},
  controller
) => {
  // Convert UI filters format & naming to API format
  const filterPayload = taskFiltersToAPI(filters, 'single') || {};
  const { sort, order } = sortObj;

  filterPayload.organizationIds = [orgId];

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'searchTasksByOrgId',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASKS_SEARCH, {
          params: {
            page,
            limit,
            sort: mapTaskTableField(sort),
            order: order
          }
        }),
        data: {
          filter: filterPayload
        },
        method: 'post',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request || {};

    const { results, currentPage, totalPages } = data;

    dispatch(updateOrgTasksPaged(data));

    return { results, currentPage, totalPages };
  };
};

/**
 * Fetch action for a csv representation of a organization's tasking request data
 *
 * @param {string} orgId
 * @param {object} [searchFilters={}] an object of search filters in key value shape per filter, e.g. { status: '', collectMods: [], ... }
 * @param {{ sort: string, order: 'asc'|'desc' }} [sortObj={}] sort and order object to pass as parameters to the download endpoint
 */
export const fetchTasksCsvByOrgId = (orgId, searchFilters = {}, sortObj = {}) => {
  return async (dispatch, getState) => {
    const { sort, order } = sortObj;
    // Convert UI filters format & naming to API format
    const filterPayload = taskFiltersToAPI(searchFilters, 'single') || {};
    filterPayload.organizationIds = [orgId];

    const dispatchAction = createDispatchRequestAction({
      name: fetchTasksCsvByOrgId.name,
      scope: ORGANIZATION_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_EXPORT_TASKS_CSV, {
          params: { sort: mapTaskTableField(sort), order }
        }),
        data: {
          filter: filterPayload
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });
    const request = await dispatchAction(dispatch, getState);
    const { data } = request;
    return data;
  };
};

/**
 * @description Filter based repeat-request search for Org Managers
 * @param {string} orgId ID of the organization to search
 * @param {object} paging pagination page & limit
 * @param {object} [filters={}] e.g. { filterName: ['value'] }
 * @param {object} [sortObj = {}] e.g. { sort: 'status', order: 'asc' }
 * @param {AbortController} [controller = new AbortController()] AbortController signal to cancel stale requests
 */

export const searchRepeatRequestsByOrgId = (
  orgId,
  { page = 1, limit = DEFAULT_PAGING_LIMIT },
  filters = {},
  sortObj = {},
  controller
) => {
  // Convert UI filters format & naming to API format
  const filterPayload = taskFiltersToAPI(filters, 'repeat') || {};
  filterPayload.organizationIds = [orgId];

  const { sort, order } = sortObj;

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'searchRepeatRequestsByOrgId',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_REPEAT_REQUESTS_SEARCH, {
          params: {
            page,
            limit,
            sort: mapTaskTableField(sort, 'repeat'),
            order: order
          }
        }),
        data: {
          filter: filterPayload
        },
        method: 'post',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request || {};

    const { results, currentPage, totalPages } = data;

    dispatch(updateOrgRepeatRequestsPaged(data));

    return { results, currentPage, totalPages };
  };
};

/**
 * Fetches the repeat requests by organizationId
 * @param {string} organizationId ID of the user's organization
 */
export const fetchRepeatRequestsByOrganizationId = (organizationId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchRepeatRequestsByOrganizationId',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: routePathByName('apiRepeatRequest', {
          params: {
            organizationId,
            skip: 0,
            limit: 1000
          }
        }),
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateOrgRepeatRequests(data));

    return data;
  };
};

/**
 * Fetch action for a csv representation of a organization's repeat request data
 *
 * @param {string} orgId
 * @param {object} [searchFilters={}] an object of search filters in key value shape per filter, e.g. { status: '', collectMods: [], ... }
 * @param {{ sort: string, order: 'asc'|'desc' }} [sortObj={}] sort and order object to pass as parameters to the download endpoint
 */
export const fetchRepeatRequestsCsvByOrgId = (orgId, searchFilters = {}, sortObj = {}) => {
  return async (dispatch, getState) => {
    const { sort, order } = sortObj;
    // Convert UI filters format & naming to API format
    const filterPayload = taskFiltersToAPI(searchFilters, 'repeat', orgId) || {};
    const dispatchAction = createDispatchRequestAction({
      name: fetchRepeatRequestsCsvByOrgId.name,
      scope: ORGANIZATION_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV, {
          params: { sort: mapTaskTableField(sort), order }
        }),
        data: {
          filter: filterPayload
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });
    const request = await dispatchAction(dispatch, getState);
    const { data } = request;
    return data;
  };
};

/**
 * putUpdateOrgUser
 * @description Updates an organization user's profile with the given data
 * @param {string} userId The user's ID
 * @param {object} data Updated user data
 */

export const putUpdateOrgUser = (userId, data) => {
  return async (dispatch, getState) => {
    // we only want to pass params that are meant to be updated

    const userData = {
      ...setPropertyIfExists('familyName', data.familyName),
      ...setPropertyIfExists('givenName', data.givenName),
      ...setPropertyIfExists('organizationId', data.organizationId),
      ...setPropertyIfExists('disabled', data.disabled),
      ...setPropertyIfExists('forceDisabled', data.forceDisabled),
      ...setPropertyIfExists('forceTaskerRoleRemoval', data.forceTaskerRoleRemoval)
    };

    ACCESS_ROLES.filter((role) => {
      return role.manageAccess.includes(ORG_MANAGER_ROLE) && role.updateDisabled !== true;
    }).forEach((role) => {
      if (typeof data[role.apiKey] === 'boolean') {
        userData[role.apiKey] = data[role.apiKey];
      }
    });

    const dispatchAction = createDispatchRequestAction({
      name: 'putUpdateOrgUser',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: `${routePathByName('apiUsers')}/${userId}`,
        method: 'put',
        data: userData
      }
    });

    let response;
    try {
      response = await dispatchAction(dispatch, getState);
    } catch (err) {
      // Expected errors, catch and return message
      if (err.message === DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS || err.message === UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS) {
        return {
          updated: false,
          message: err.message
        };
      }
      // Unexpected error, throw it up to the parent
      throw err;
    }

    const { data: requestData } = response || {};

    const updatedUserData = {
      id: userId,
      ...requestData
    };

    dispatch(updateOrgUsers(updatedUserData));

    return updatedUserData;
  };
};

/**
 * reissuePasswordAsOrgManager
 * @param {string} userId
 */

export const reissuePasswordAsOrgManager = (userId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'reissuePassword',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: routePathByName('apiUsersResendPassword', {
          wildcard: [userId]
        }),
        method: 'post'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    return requestData;
  };
};

/**
 * testOrgBucketConfig
 * @description test that bucket config is setup correctly by Org ID
 * @param {string} orgId
 * @param {array} payload
 */

export const testOrgBucketConfig = (orgId, payload) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'testOrgBucketConfig',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: routePathByName('apiOrgS3PushTest', {
          wildcard: [orgId]
        }),
        method: 'post',
        data: payload
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(getOrgS3Push(data));

    return data;
  };
};

/**
 * fetchOrgS3BucketById
 * @description Fetches an Org's S3 Bucket Data by their Org ID
 * @param {string} orgId
 */

export const fetchOrgS3BucketById = (orgId) => {
  return async (dispatch, getState) => {
    const url = routePathByName('apiOrgS3BucketPostGet', {
      wildcard: [orgId]
    });
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrgS3BucketById',
      scope: ORGANIZATION_SCOPE,
      request: {
        url: url,
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(fetchOrgS3Bucket(data));

    return data;
  };
};

/******************
 * STATE UPDATERS *
 ******************/

/**
 * updateOrganization
 * @description Set the organization profile
 */

export const updateOrganization = (data) => {
  return {
    type: 'UPDATE_ORGANIZATION',
    data
  };
};

/**
 * updateOrgUsers
 * @description Set the org users
 */

export const updateOrgUsers = (data) => {
  return {
    type: 'UPDATE_ORG_USERS',
    data
  };
};

/**
 * updateOrgTask
 * @description Updates a single org task
 */

export const updateOrgTask = (data) => {
  return {
    type: UPDATE_ORG_TASK,
    data
  };
};

/**
 * updateOrgRepeatRequest
 * @description Update a single org repeat request
 */

export const updateOrgRepeatRequest = (data) => {
  return {
    type: UPDATE_ORG_REPEAT_REQUEST,
    data
  };
};

/**
 * updateOrgTasks
 * @description Set the org tasks
 */

export const updateOrgTasks = (data) => {
  return {
    type: UPDATE_ORG_TASKS,
    data
  };
};

/**
 * updateOrgTasksPaged
 * @description Set the org task results (but paged)
 */

export const updateOrgTasksPaged = (data) => {
  return {
    type: UPDATE_ORG_TASKS_PAGED,
    data
  };
};

/**
 * updateOrgTasksColumns
 * @description Set the current active org task table columns & column order
 */
export const updateOrgTasksColumns = (data) => {
  return {
    type: UPDATE_ORG_TASKS_COLUMNS,
    data
  };
};

/**
 * updateOrgRepeatRequestsColumns
 * @description Set the current active org repeat table columns & column order
 */
export const updateOrgRepeatRequestsColumns = (data) => {
  return {
    type: UPDATE_ORG_REPEAT_REQUESTS_COLUMNS,
    data
  };
};

/**
 * updateOrgRepeatRequests
 * @description Set the org repeat requests
 */

export const updateOrgRepeatRequests = (data) => {
  return {
    type: UPDATE_ORG_REPEAT_REQUESTS,
    data
  };
};

/**
 * updateOrgRepeatRequestsPaged
 * @description Set the org repeat requests in paged format
 */

export const updateOrgRepeatRequestsPaged = (data) => {
  return {
    type: UPDATE_ORG_REPEAT_REQUESTS_PAGED,
    data
  };
};

/**
 * updateOrgOrders
 * @description Set the org orders
 */

export const updateOrgOrders = (data) => {
  return {
    type: 'UPDATE_ORG_ORDERS',
    data
  };
};

/**
 * addOrgUser
 * @description Add/create a new user
 */

export const addOrgUser = (data) => {
  return {
    type: 'ADD_ORG_USER',
    data
  };
};

/**
 * getOrgS3Push
 * @description Set the org's S3 Push Data
 */

export const getOrgS3Push = (data) => {
  return {
    type: 'GET_ORG_S3_PUSH',
    data
  };
};

/**
 * fetchOrgS3Bucket
 * @description Set the org's S3 Bucket Data
 */

export const fetchOrgS3Bucket = (data) => {
  return {
    type: 'FETCH_ORG_S3_BUCKET',
    data
  };
};
