import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useCart, useLocation, useFetchContracts, useOrder, useCreateNewEntry } from 'hooks';
import { postReviewOrder, updateCartShowContracts } from 'state/actions';
import { isEmptyObject } from 'lib/util';
import Order from 'models/order';

/**
 * Shared Cart stuff, mainly to verify whether a new order in the Cart step
 * needs to select a contract. Also return the latest lineItems and Order
 */
export default function useVerifyCart () {
  const dispatch = useDispatch();
  const location = useLocation();
  const { items } = useCart();
  const { isLoading } = useFetchContracts();

  const [lineItems, setLineItems] = useState([]);
  const { newEntry: newOrder } = useCreateNewEntry({
    entryHook: useOrder,
    entryPathBase: 'cart',
    entryType: 'order',
    location
  });

  const ordersVerified = useRef(false);
  useEffect(() => {
    let orderVal;
    if (isEmptyObject(newOrder) || !newOrder.id) {
      orderVal = new Order({
        orderId: 'NEW_ORDER'
      });
      orderVal.addItems(items);
    } else {
      orderVal = newOrder;
      // Keeping item consistency between cart and order
      orderVal.items = items;
    }
    // Pre-flight /review to see if contract selection is needed
    if (orderVal?.items?.length && !isLoading && !ordersVerified.current) {
      ordersVerified.current = true;
      dispatch(postReviewOrder(orderVal, true)).then(({ orderDetails = {} }) => {
        const { lineItems } = orderDetails;
        setLineItems(lineItems);
        // No error, so no need for user to select Contract
        dispatch(updateCartShowContracts(false));
      }).catch(({ response = {} }) => {
        const { data = {}, status } = response;
        const { error = {} } = data;
        const { code } = error;
        if (status === 400 && code === 'CONTRACT_ID_REQUIRED') {
          // If we get this error back, user needs to select a contract in cart
          dispatch(updateCartShowContracts(true));
        }
      });
    }
  }, [items]);

  function resetOrdersVerified () {
    ordersVerified.current = false;
  }

  return {
    lineItems,
    resetOrdersVerified, // calling this re-triggers /review
    order: newOrder
  };
}
