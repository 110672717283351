import React from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import { ChildToggle } from 'fogg/ui';

const DEFAULT_LABEL = 'More Options';

const OptionsPanel = ({
  children,
  label = DEFAULT_LABEL,
  id,
  defaultIsOpen = false
}) => {
  return (
    <ChildToggle
      className="options-panel"
      label={label}
      id={id}
      icon={<FaChevronDown />}
      isChecked={defaultIsOpen}
    >
      {children}
    </ChildToggle>
  );
};

OptionsPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  label: PropTypes.string,
  id: PropTypes.string,
  defaultIsOpen: PropTypes.bool
};

export default OptionsPanel;
