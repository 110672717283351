import React from 'react';

import {
  useContracts,
  useFetchContracts,
  useOrgFetchOrganization,
  useUser
} from 'hooks';
import { routePathByName } from 'lib/routes';

import { TemplateOrganizationDetails } from 'commonLib';

import { ORG_MANAGER_ROLE } from 'data/roles';

const OrganizationDetailsPage = () => {
  const { user = {} } = useUser();
  const { organization: userOrganization = {} } = user;

  const { organization = {}, isLoading } = useOrgFetchOrganization();
  const {
    isLoading: isContractsLoading,
    ledgerBillingEnabled = false
  } = useFetchContracts();
  const { contracts } = useContracts({ forceNew: true });

  const { parentOrganization = {} } = userOrganization;

  if (parentOrganization.name) {
    organization.resellerOrg = parentOrganization;
  }

  const breadcrumbs = [
    {
      label: 'Organization',
      to: routePathByName('organization')
    }
  ];

  const organizationState = {
    isLoading: isLoading || isContractsLoading
  };

  const organizationDetails = organization || userOrganization;

  // FUTURE maybe we should make the a&e /organizations call smarter to return ledger contracts for a ledger enabled org
  // FOR NOW we easily call the ledger endpoint and mutate the organization object to include ledgerContracts, not ideal, but no big deal
  // JAM-4275
  if (ledgerBillingEnabled && !organizationState.isLoading) {
    organizationDetails.ledgerContracts = contracts;
  }

  return (
    <TemplateOrganizationDetails
      organization={organization || userOrganization}
      accessType={ORG_MANAGER_ROLE}
      breadcrumbs={breadcrumbs}
      state={organizationState}
    />
  );
};

export default OrganizationDetailsPage;
