import moment from 'moment';
import { routePathByName } from 'lib/routes';
import { DAY_NIGHT_TIME_RANGES } from 'data/search';

/**
 * returns a more useful properties array of an item for the UI
 * @param {object} [props]
 * @param {object} [props.item=null] - item, instance of models/item
 *
 * @returns {object} output
 * @returns {Array} output.itemProperties - The more useful properties array
 */
export default function useItemProperties ({
  item = null
} = {}) {
  const output = {
    itemProperties: []
  };

  const {
    id: stacId = null,
    feature: {
      links = null
    } = {}
  } = item || {};

  const itemProperties = item?.propertiesArray(true, ['processing:software']);

  if (!itemProperties) return output;

  itemProperties.forEach((item, index) => {
    if (item.property === 'locale:time') {
      // Add synthetic "Collection Time" property here. Will not get looped over since forEach doesn't operate on elements added to array during execution
      const format = 'hh:mm:ss';
      const { day: { gte, lt } } = DAY_NIGHT_TIME_RANGES;
      const timePropertyIsDay = moment(item.value, format).isBetween(moment(gte, format), moment(lt, format), undefined, '[)');

      itemProperties.push({
        property: 'Collection Time',
        value: timePropertyIsDay ? 'day' : 'night',
        key: 'synthetic:collect_time'
      });
    }
  });

  if (links?.length) {
    const stacIds = [];

    links.forEach(({ rel = '', id: linkStacId = '' }) => {
      if (rel === 'derived_from') {
        stacIds.push(linkStacId);
      }
    });

    stacIds.forEach((derivedStacId, derivedIteration) => {
      const derivedFromStacIdsWithOrig = stacIds.concat([stacId]);
      itemProperties.push({
        key: `link:derived_from_${derivedIteration}`,
        type: 'url',
        property: `Derived From ${derivedIteration + 1}`,
        value: [
          derivedStacId,
          routePathByName('search', {
            params: {
              q: derivedFromStacIdsWithOrig.join(','),
              zoom: 'auto'
            }
          })
        ]
      });
    });
  }

  return {
    itemProperties
  };
}
