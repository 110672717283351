import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Badge } from 'fogg/ui';
import { geoJSON } from 'leaflet';
import { BiMapPin, BiPlusMedical } from 'react-icons/bi';
import { FiZoomIn } from 'react-icons/fi';
import { FaExpand } from 'react-icons/fa';
import { TbZoomQuestion } from 'react-icons/tb';
import { Button, Link } from '@trussworks/react-uswds';

import {
  NavigatorLayout,
  NavigatorBody,
  PageHeader,
  LoadingSpinner
} from 'commonLib';
import { useLocation, useAoiMapLayers } from 'hooks';
import MapPreview from 'components/MapPreview';
import { routePathByName, navigateTo } from 'lib/routes';
import { logError } from 'lib/logger';
import {
  ROUTE_AOI_CREATE,
  ROUTE_AOI_DETAILS,
  ROUTE_AOIS
} from 'commonLib/src/data/route-names';
import { DEFAULT_MAP_CENTER } from '../data/aois';

/**
 * AOIs list & map view template
 * @param {object} props
 * @param {array} props.aois=[]
 * @param {object} [props.aoiFeatures={}] aois converted to FeatureCollection for MapPreview
 * @param {Function} [props.handleSearch] Handler for the searching of aois
 * @param {Function} [props.clearSearch] Handler for clearing out the search query
 * @param {boolean} [props.isLoading=false]
 * @param {React.MutableRefObject<string|undefined>} searchRef
 * @param {function} handleAddLayerError
 */
function TemplateAois ({
  aois = [],
  aoiFeatures = undefined,
  handleSearch,
  clearSearch,
  isLoading = false,
  searchRef,
  handleAddLayerError
}) {
  const mapRef = useRef();
  const featureRef = useRef();
  const {
    queryParams = {},
    updateQuerySearchParams
  } = useLocation();

  const { addLayerToFeatureGroup } = useAoiMapLayers({ featureRef });

  const { search = '' } = queryParams || {};

  const handleMapEffect = ({ leafletElement: map } = {}) => {
    mapRef.current = map;
  };

  function zoomIntoAOI (geoJson) {
    const aoiMarker = geoJSON(geoJson);
    mapRef.current.fitBounds(aoiMarker.getBounds().pad(0.5));
  }

  // Reset map view to fit all map markers in view
  function resetMapZoom () {
    if (!mapRef.current || !aoiFeatures) return;
    mapRef.current.fitBounds(geoJSON(aoiFeatures).getBounds());
  }

  function newSearchHandler (e) {
    e.preventDefault();

    let newSearch = search;

    if (searchRef && searchRef.current) {
      newSearch = searchRef.current.value;
    }

    if (newSearch) {
      updateQuerySearchParams({ search: newSearch }, { replace: false });
    }

    // parent search handler
    if (typeof handleSearch === 'function') {
      handleSearch(newSearch);
    }
  }

  function clearSearchHandler (e) {
    e.preventDefault();

    // parent clear search handler
    if (typeof clearSearch === 'function') {
      clearSearch();
    }
    searchRef.current.value = '';
    navigateTo(routePathByName(ROUTE_AOIS));
  }

  // Adds fetched AOIs to the map
  useEffect(() => {
    const { leafletElement } = featureRef?.current || {};
    if (!leafletElement) {
      return;
    }
    let layersAdded = false;
    const caughtErrors = [];
    if (aois?.length && !isLoading) {
      leafletElement.clearLayers();
      aois.forEach((aoi) => {
        // In case there is an issue with a single AOI, we will try/catch it, log it, and continue with the loop through other AOIs
        try {
          addLayerToFeatureGroup(aoi);
          layersAdded = true;
        } catch (err) {
          logError('Error adding AOI to map layers.', {
            aoiDetails: aoi
          });
        }
      });

      // fit the map to the newly added feature group layers
      if (layersAdded) {
        mapRef.current.fitBounds(leafletElement.getBounds());
      } else {
        // if we didn't add any layers that means we tried to add layers but failed, resulting in caught errors above
        handleAddLayerError(
          new Error('Error adding AOIs to the map.', {
            cause: { caughtErrors }
          })
        );
      }
    }
  }, [aois, isLoading]);

  return (
    <NavigatorLayout access="user" className="layout-navigator split-map-view">
      <Helmet>
        <title>Areas of Interest (AOIs)</title>
      </Helmet>

      <NavigatorBody
        className={`aois-list-body layout-navigator-body ${
          isLoading ? 'is-loading' : ''
        }`}
      >
        <div className="aois-list-body-header">
          <PageHeader
            title="Areas of Interest"
            icon={<BiMapPin className="icon-bi" />}
          />
          <Button
            type="button"
            base={true}
            className="create-aoi-button icon-before"
            onClick={() => navigateTo(routePathByName(ROUTE_AOI_CREATE))}
            data-testid="create-aoi-button"
          >
            <BiPlusMedical size=".9em" /> Create New AOI
          </Button>
        </div>

        <div className="usa-search usa-search--base block search-aois">
          <div data-testid="searchField">
            <label
              data-testid="label"
              className="usa-sr-only"
              htmlFor="search-field"
            >
              Search
            </label>
            <input
              data-testid="textInput"
              className="usa-input"
              id="search-field"
              name="search"
              type="search"
              placeholder="Search AOI Name or ID"
              ref={searchRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  newSearchHandler(e);
                }
              }}
            />
          </div>
          <div className="usa-search--small">
            <button type="submit" className="usa-button" data-testid="button" onClick={newSearchHandler}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                className="usa-icon usa-icon--size-3 usa-search__submit-icon"
                focusable="false"
                role="img"
                name="Search"
              >
                <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
              </svg>
            </button>
          </div>
        </div>

        {searchRef?.current?.value ? (
          <div className="search-aois text-right tuck-under">
            <Button
              type="reset"
              unstyled={true}
              className="reset-search-button"
              onClick={clearSearchHandler}
            >
              Reset Search
            </Button>
          </div>
        ) : null}

        <div className="aois-list-header">
          <label>Name & Description</label>
          <label>Notifications</label>
          <label>Zoom</label>
        </div>

        <div
          className="aois"
          data-testid={isLoading ? 'aois-loading' : 'aois-loaded'}
        >
          {isLoading ? (
            <LoadingSpinner position="center" size="large" color="black" />
          ) : aois?.length ? (
            <ul className="aois-list">
              {aois.map((aoi = {}) => {
                const { id, properties = {} } = aoi;
                const { name, description, active } = properties;
                const badgeClass = `badge-solid ${
                  active ? 'badge-completed' : ''
                }`;

                return (
                  <li key={id} className="aois-list-item">
                    <span className="aoi-description-body">
                      <h5>
                        <Link
                          href={routePathByName(ROUTE_AOI_DETAILS, {
                            wildcard: [id]
                          })}
                          data-testid={id}
                        >
                          {name}
                        </Link>
                      </h5>
                      {description && (
                        <span className="aoi-description">{description}</span>
                      )}
                    </span>
                    <Badge
                      type={badgeClass}
                      label={active ? 'ACTIVE' : 'INACTIVE'}
                      aria-label="Notifications Status"
                    />
                    <Button
                      className="zoom-aoi-button"
                      onClick={() => zoomIntoAOI(aoi.geometry)}
                    >
                      <FiZoomIn />
                    </Button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="empty-aois-list">
              <TbZoomQuestion className="alert-icon" />
              <h4>No AOIs Found</h4>
              <Button
                type="button"
                base={true}
                className="create-aoi-button icon-before"
                onClick={() => navigateTo(routePathByName(ROUTE_AOI_CREATE))}
                data-testid="create-aoi-button"
              >
                <BiPlusMedical size=".9em" /> Create New AOI
              </Button>
            </div>
          )}
        </div>
      </NavigatorBody>

      <section className="aois-map">
        <MapPreview
          center={DEFAULT_MAP_CENTER}
          emptyMap={!aoiFeatures}
          featureRef={featureRef}
          fitGeoJson={false}
          useMapEffect={handleMapEffect}
          displayAccessRequests={false}
          displayAOIDetails={false}
          zoom={1}
        >
          {aois?.length > 0 ? (
            <Button
              type="button"
              base={true}
              className="icon-before reset-view-button"
              onClick={resetMapZoom}
              data-testid="reset-map-view"
            >
              <FaExpand /> Reset View
            </Button>
          ) : null}
        </MapPreview>
      </section>
    </NavigatorLayout>
  );
}

TemplateAois.propTypes = {
  aois: PropTypes.array,
  aoiFeatures: PropTypes.object,
  handleSearch: PropTypes.func,
  clearSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  searchRef: PropTypes.any,
  handleAddLayerError: PropTypes.func
};

export default TemplateAois;
