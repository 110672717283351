import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { VscSettings } from 'react-icons/vsc';
import { DatetimeRange, Badge } from 'fogg/ui';
import {
  Button,
  Search,
  TextInput,
  ModalToggleButton,
  Modal,
  ModalFooter
} from '@trussworks/react-uswds';
import { dateRangeFilterToObject } from 'lib/datetime';
import { RepeatCycleTypes } from 'data/repeat-cycle-types';
import DropdownMenu from '../components/DropdownMenu';
import TableColumnChooser from '../components/TableColumnChooser';

/**
 * An expandable filters panel to control data displayed in the tasks table
 * and potentially other tables in the future (e.g. orders)
 * @param {object} props
 * @param {object} props.filters={} Active filters e.g. { status: ['completed'] }
 * @param {object} [props.filterOptions={}] All potential filters e.g. TASKS_TABLE_FILTER_OPTIONS
 * @param {function} props.handleFilterSelect handler for selecting filters and ultimately driving /tasks/search
 * @param {function} props.handleSearch handler for search bar, which is text-based filter
 * @param {function} props.resetFilters handler to clear/reset all active filters & search bar
 * @param {string} [props.activeDatepicker=null] name/id of active datepicker
 * @param {function} [props.setActiveDatepicker] set name/id of active datepicker (or reset to null)
 * @param {function} [props.resetColumns] handler to reset column chooser to defaults
 * @param {React.Component} [props.exportComponent]
 */
const FiltersPanel = ({
  filters = {},
  filterOptions = {},
  handleFilterSelect,
  handleSearch,
  resetFilters,
  activeDatepicker,
  setActiveDatepicker,
  columns = [],
  handleColumns,
  resetColumns,
  exportComponent
}) => {
  const otherInputRef = useRef(null);
  const filtersModalRef = useRef(null);

  const [dateValidation, setDateValidation] = useState(null);
  const [isColumnsOpen, setIsColumnsOpen] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);

  // Track and handle "Other" value entries separately
  const { repeatCycle = [] } = filters;
  const activeOtherValue = repeatCycle.length && repeatCycle.find(
    val => !Object.keys(RepeatCycleTypes).includes(val.toUpperCase()));

  /* Build array of Filters options */
  const filterValues = Object.values(filterOptions);
  const filtersList = Object.keys(filterOptions).map((opt, i) => {
    return ({
      ...filterValues[i],
      id: opt
    });
  });

  // Convert date selections to /tasks/search filter format
  function handleDateChange (date, filter) {
    setDateValidation(null);
    const activeFilter = filters[filter] ? filters[filter][0] : undefined;
    if (date) {
      const { start, end } = dateRangeFilterToObject(activeFilter) || {};
      // Only trigger filter change if Save changes active date range
      if (date.start !== start || date.end !== end) {
        // Also make sure start & end dates selected...
        if (date.start && date.end) {
          handleFilterSelect('customDate', filter, date);
        } else setDateValidation(filter);
      } else setActiveDatepicker(null);
    } else {
      // Reset custom date filter
      handleFilterSelect('customDate', filter, null);
    }
  }

  // Pre-populate datepicker with the active date selection
  const getDefaultDate = (id) => {
    if (filters[id] && filters[id][0].includes('/')) {
      const datesArray = filters[id][0].split('/');
      return {
        start: Number(datesArray[0]), end: Number(datesArray[1])
      };
    }
  };

  // If "CLEAR" is selected, reset custom date selection & close
  function handleClear (id) {
    handleDateChange(null, id);
    setActiveDatepicker(null);
  }

  // Handle "Other" Repeat Cycle Save differently
  function handleOtherSave () {
    const inputVal = otherInputRef.current?.value;
    if (inputVal) {
      handleFilterSelect('multi-other', 'repeatCycle', inputVal);
      setShowOtherInput(false);
    }
  }

  const filtersCount = filters
    ? Object.values(filters)
      .flat()
      .filter((n) => n).length
    : 0;

  let filterButtonClass = 'icon-before badge-after';
  if (filtersCount) filterButtonClass += ' active';

  return (
    <>
      <div className="filters-control data-table-filters" role="filters-bar">
        <ModalToggleButton
          modalRef={filtersModalRef}
          type="button"
          base={true}
          className={filterButtonClass}
          data-testid='filters-button'
          opener>
          <VscSettings /> Filters
          <Badge
            type="badge-solid"
            label={filtersCount.toString()}
          />
        </ModalToggleButton>
        <Search
          placeholder="Search Task Name or ID"
          className="usa-search--base"
          size="small"
          onSubmit={handleSearch}
          defaultValue={filters.search}
          key={filters.search}
        />
        {filtersCount
          ? <Button type="button" onClick={resetFilters} unstyled>Reset Filters</Button>
          : null
        }
        <div className="button-group-right">
          <DropdownMenu
            id="column-chooser-button"
            isOpen={isColumnsOpen}
            trigger={
              <Button
                type="button"
                onClick={() => setIsColumnsOpen(!isColumnsOpen)}
                id="column-chooser-button"
                base={true}
              >
                  Columns
              </Button>
            }
            menu={
              <TableColumnChooser
                columns={columns}
                handleColumns={handleColumns}
                resetColumns={resetColumns}
              />
            }
            triggerCallback={setIsColumnsOpen}
          />
          {exportComponent}
        </div>
      </div>

      <Modal
        ref={filtersModalRef}
        isLarge
        className="filters-modal"
        aria-labelledby="Task table filters"
        id="filters-modal">
        <section className='filters-body' role="filters">
          {filtersList?.length && filtersList.map(({ id, label, type, options }) => {
            return (
              <div key={id} className="filters-body-row">
                <label>{label}</label>
                <div className="filters-button-group">
                  {(type.includes('multi')) && options?.length && (
                    <>
                      <Button
                        type="button"
                        className={!filters[id] ? 'active' : ''}
                        onClick={() => handleFilterSelect(type, id, null)}
                        base={true}
                      >
                        Any
                      </Button>
                      {options.map(({ value, label }, i) => (
                        <React.Fragment key={`${value}}-${i}`}>
                          <Button
                            type="button"
                            key={`${value}}-${i}`}
                            id={`${value}}-${i}`}
                            base={true}
                            onClick={value !== 'other'
                              ? () => handleFilterSelect(type, id, value)
                              : () => setShowOtherInput(!showOtherInput)}
                            className={value !== 'other'
                              ? filters[id]?.includes(value) ? 'active' : ''
                              : activeOtherValue ? 'active' : ''
                            }
                          >
                            {label}
                          </Button>
                          {(label === 'Other' && activeOtherValue)
                            ? (
                              <label className="active-other-label" htmlFor={`${value}}-${i}`}>
                                {activeOtherValue} Day(s)
                              </label>
                            )
                            : null}
                        </React.Fragment>
                      ))}
                      {(showOtherInput && type === 'multi-other') &&
                      (
                        <div className="other-filter-input">
                          <TextInput
                            inputRef={otherInputRef}
                            id="repeat-cycle-placeholder"
                            name="repeat-cycle-placeholder"
                            type="number"
                            defaultValue={activeOtherValue}
                            placeholder="# of Days"
                          />
                          <Button type="text" className="primary" onClick={handleOtherSave}>Save</Button>
                          <Button type="text" onClick={() => setShowOtherInput(false)} unstyled>Cancel</Button>
                        </div>
                      )}
                    </>
                  )}
                  {type === 'date' && options && (
                    <>
                      <Button
                        key={`any-${id}`}
                        type="button"
                        className={!filters[id] ? 'active' : ''}
                        onClick={() => handleFilterSelect(type, id, null)}
                        base={true}
                      >
                        Any
                      </Button>
                      {options.map(({ value, label }, i) => {
                        const isCustomDate = value === 'custom' && filters[id] && filters[id][0]?.includes('/');
                        let dateArray;
                        if (isCustomDate) {
                          // Split string into 2 timestamps to display when active
                          dateArray = filters[id][0].split('/');
                        }
                        return (
                          <React.Fragment key={`${value}}-${i}`}>
                            <Button
                              type="button"
                              onClick={value === 'custom'
                                ? () => setActiveDatepicker(id)
                                : () => handleFilterSelect(type, id, value)}
                              id={`${value}}-${i}`}
                              className={
                                filters[id]?.includes(value.toString()) || isCustomDate
                                  ? 'active'
                                  : ''
                              }
                              base={true}
                            >
                              {label}
                            </Button>
                            {dateArray?.length && (
                              <label className="active-date-label" htmlFor={`${value}}-${i}`}>
                                {moment
                                  .utc(moment(Number(dateArray[0])))
                                  .format('MM/DD/YYYY')
                                  .valueOf() +
                                  ' - ' +
                              moment
                                .utc(moment(Number(dateArray[1])))
                                .format('MM/DD/YYYY')
                                .valueOf()}

                              </label>
                            )}
                          </React.Fragment>
                        );
                      })}
                      {(activeDatepicker && activeDatepicker === id) && (
                        <div className={`datepicker ${dateValidation === id ? 'is-error' : ''}`}>
                          <DatetimeRange
                            key={`datepicker-${id}`}
                            defaultDate={getDefaultDate(id)}
                            onClear={() => handleClear(id)}
                            onCancel={() => setActiveDatepicker(null)}
                            onChange={(date) => handleDateChange(date, id)}
                            utc={true}
                          />
                          <label className="error">Select a Start & End Date!</label>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })
          }
        </section>
        <ModalFooter className="filters-footer">
          <ModalToggleButton className="primary" type="button" modalRef={filtersModalRef} closer>
            Apply & Close
          </ModalToggleButton>
          <Button type="button" unstyled onClick={resetFilters}>
            Reset Filters
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

FiltersPanel.propTypes = {
  filters: PropTypes.object.isRequired,
  filterOptions: PropTypes.object,
  columns: PropTypes.array.isRequired,
  handleColumns: PropTypes.func.isRequired,
  handleFilterSelect: PropTypes.func,
  handleSearch: PropTypes.func,
  activeDatepicker: PropTypes.string,
  setActiveDatepicker: PropTypes.func,
  resetFilters: PropTypes.func,
  resetColumns: PropTypes.func,
  exportComponent: PropTypes.node
};

export default FiltersPanel;
