import { v1 as uuidv1 } from 'uuid';
import clone from 'clone';
import deepmerge from 'deepmerge';

import User from '../../models/user';

let applicationUser;
const uuid = uuidv1();

const defaultState = {
  user: {},
  pageSession: {
    id: uuid
  },
  actions: {}
};

const user = (state = defaultState, { data, type } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);

  if (!(applicationUser instanceof User)) {
    applicationUser = new User(workingState.user);
  }

  // Make sure we keep our user in sync with our state

  applicationUser.update(workingState.user);

  switch (type) {
    case 'UPDATE_USER_ACTION_STATE':
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    case 'UPDATE_USER':
      applicationUser.update(workingData);
      break;
    case 'SET_TOKEN':
      applicationUser.update(workingData);
      break;
    case 'SET_REFRESH_TOKEN':
      applicationUser.update(workingData);
      break;
    case 'SET_REFRESH_TOKEN_EXPIRATION':
      applicationUser.update(workingData);
      break;
    case 'RESET_PASSWORD':
      applicationUser.update(workingData);
      break;
    case 'USER_DESTROY_SESSION':
      applicationUser.destroy();
      break;
    default:
      applicationUser.update({});
  }

  return {
    ...workingState,
    user: {
      ...applicationUser.data()
    }
  };
};

export default user;
