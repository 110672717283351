export const CONTRACT_TYPE_INDEFINITE = 'indefinite';
export const CONTRACT_TYPE_STANDARD = 'standard';
export const CONTRACT_TYPE_RESELLER = 'reseller';
export const CONTRACT_TYPE_TRIAL = 'trial';
export const CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION = 'archive-subscription';

export const CONTRACT_FORM_DEFAULTS_BASE = {
  isInvoiceable: {
    checked: true
  }
};

const CONTRACT_FORM_DEFAULTS_TRIAL = {
  isInvoiceable: {
    checked: false
  }
};

export const CONTRACT_TYPES = [
  {
    label: 'Indefinite',
    name: CONTRACT_TYPE_INDEFINITE,
    defaults: CONTRACT_FORM_DEFAULTS_BASE
  },
  {
    label: 'Standard',
    name: CONTRACT_TYPE_STANDARD,
    defaults: CONTRACT_FORM_DEFAULTS_BASE
  },
  {
    label: 'Reseller',
    name: CONTRACT_TYPE_RESELLER,
    defaults: CONTRACT_FORM_DEFAULTS_BASE
  },
  {
    label: 'Archive Image Subscription',
    name: CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION,
    defaults: CONTRACT_FORM_DEFAULTS_BASE
  },
  {
    label: 'Trial',
    name: CONTRACT_TYPE_TRIAL,
    defaults: CONTRACT_FORM_DEFAULTS_TRIAL
  }
];

export const CONTRACT_PERIOD_STATES = [
  {
    label: 'Active',
    name: 'active'
  },
  {
    label: 'Upcoming',
    name: 'upcoming'
  },
  {
    label: 'Passed',
    name: 'passed'
  }
];

export const CONTRACT_RESELLER_TIER_SOLE = 'sole';
export const CONTRACT_RESELLER_TIER_PLATINUM = 'platinum';
export const CONTRACT_RESELLER_TIER_GOLD = 'gold';
export const CONTRACT_RESELLER_TIER_SILVER = 'silver';
export const CONTRACT_RESELLER_TIER_BRONZE = 'bronze';

export const CONTRACT_RESELLER_TIERS = [
  {
    label: 'Sole',
    name: CONTRACT_RESELLER_TIER_SOLE
  },
  {
    label: 'Platinum',
    name: CONTRACT_RESELLER_TIER_PLATINUM
  },
  {
    label: 'Gold',
    name: CONTRACT_RESELLER_TIER_GOLD
  },
  {
    label: 'Silver',
    name: CONTRACT_RESELLER_TIER_SILVER
  },
  {
    label: 'Bronze',
    name: CONTRACT_RESELLER_TIER_BRONZE
  }
];

export const CONTRACT_EXCLUSIVITY_UPLIFT_NONE = 'none';
export const CONTRACT_EXCLUSIVITY_UPLIFT_30_DAYS = '30_day';
export const CONTRACT_EXCLUSIVITY_UPLIFT_1_YEAR = '1_year';
export const CONTRACT_EXCLUSIVITY_PERMANENT = 'permanent';

export const CONTRACT_EXCLUSIVITY_UPLIFTS = [
  {
    label: 'None',
    name: CONTRACT_EXCLUSIVITY_UPLIFT_NONE
  },
  {
    label: '30-Day Catalog Holdback: 10%',
    name: CONTRACT_EXCLUSIVITY_UPLIFT_30_DAYS
  },
  {
    label: '1-Year Catalog Holdback: 25%',
    name: CONTRACT_EXCLUSIVITY_UPLIFT_1_YEAR
  },
  {
    label: 'Permanent Catalog Holdback: 100%',
    name: CONTRACT_EXCLUSIVITY_PERMANENT
  }
];
