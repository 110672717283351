import { routePathByName } from '../lib/routes';
import { ITEM_PROPERTIES } from '../data/items';
import { AnalyticProducts } from '../data/analytic-product-types';

/**
 * itemPropertyById
 */

export function itemPropertyById (propertyId) {
  return ITEM_PROPERTIES.find(({ id } = {}) => propertyId === id);
}

/**
 * collectionsToTableData
 */

export function collectionsToTableData (
  collections = [],
  { editPathName } = {}
) {
  if (!Array.isArray(collections) || collections.length === 0) return [];

  return collections.map((collection = {}, index) => {
    const { title, id } = collection;

    return {
      title,
      id,
      actions: [
        {
          to: routePathByName(editPathName, {
            wildcard: [collection.id]
          }),
          label: 'View Details',
          icon: 'FaChevronRight',
          buttonType: ['text', 'icon-after']
        }
      ]
    };
  });
}

/**
 * Return order items grouped by their collect ID
 * @param {array} items = []
 */
export function groupItemsByCollect (items) {
  if (!items || !items.length) return;

  const groupedItems = items.reduce((group, item) => {
    const { collectId, properties = {} } = item;
    // Check 2 different places for collect ID
    const id = collectId || properties['capella:collect_id'];
    group[id] = group[id] ?? [];
    item.collectId = id;
    group[id].push(item);

    // Move VS, VC, and ACD items to end
    if (AnalyticProducts.isAnalytic(group[id][0].productType)) {
      group[id].push(group[id].shift());
    }
    return group;
  }, {});
  return groupedItems;
}
