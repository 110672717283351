import React from 'react';

import ThankYouLayout from 'components/ThankYouLayout';

import { useSiteMetadata } from 'hooks';

const UserRegisterThankYouPage = () => {
  const { url, name } = useSiteMetadata();

  const actionRoutes = [
    {
      label: name,
      to: url
    }
  ];

  return (
    <ThankYouLayout
      basePage="user-register"
      actionRoutes={actionRoutes}
      layout={{
        navigation: false
      }}
    >
      <p>We&apos;ll let you know as soon as your account is ready.</p>
    </ThankYouLayout>
  );
};

export default UserRegisterThankYouPage;
