import StreetsThumbnail from '../assets/images/mapbox-streets-thumb.png';
import SatelliteThumbnail from '../assets/images/mapbox-satellite-thumb.png';

const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN;
const MAPBOX_STYLE_ID = process.env.MAPBOX_STYLE_ID;

export const mapServices = [
  {
    name: 'street',
    format: 'png',
    projections: 'epsg3857',
    attribution:
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    nativeZoom: 18,
    minZoom: 2,
    thumb: StreetsThumbnail,
    tileSize: 256,
    tileEndpoint: `https://api.mapbox.com/styles/v1/${MAPBOX_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`
  },
  {
    name: 'satellite',
    format: 'png',
    projections: 'epsg3857',
    attribution:
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 22,
    nativeZoom: 22,
    minZoom: 2,
    thumb: SatelliteThumbnail,
    tileSize: 256,
    tileEndpoint: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`
  }
];

export const defaultLayers = {
  base: [
    {
      name: 'Street',
      serviceName: 'street',
      type: 'service',
      defaultIsActive: true
    },
    {
      name: 'Satellite',
      serviceName: 'satellite',
      type: 'service',
      defaultIsActive: false
    }
  ],
  overlay: []
};
