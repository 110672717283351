import {
  UPDATE_REPEAT_REQUEST_CHILD_TASKS,
  UPDATE_REPEAT_REQUEST_CHILD_TASKS_ACTION_STATE
} from 'data/action-types';
import { REPEAT_REQUEST_CHILD_TASKS_SCOPE } from 'data/scopes';
import { routePathByName } from 'lib/routes';
import { constructRequestActionManager } from 'commonLib/src/lib/actions';
import Task from 'models/task';

/**
 * Updates the repeat requests store's status
 */
export function setRepeatRequestChildTasksActionState (status) {
  return {
    type: UPDATE_REPEAT_REQUEST_CHILD_TASKS_ACTION_STATE,
    data: status
  };
}

const createDispatchRequestAction = constructRequestActionManager(
  setRepeatRequestChildTasksActionState
);

/**
 * Fetch tasks by repeat request id
 */
export const fetchChildTasksByRepeatRequestId = (repeatRequestId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchChildTasksByRepeatRequestId',
      scope: REPEAT_REQUEST_CHILD_TASKS_SCOPE,
      request: {
        url: `${routePathByName('apiRepeatRequest')}/${repeatRequestId}/tasks`,
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = [] } = request || {};

    // map the returned task data to instances of Task
    const mappedTasks =
      data?.map?.((responseTask = {}) =>
        new Task().ingestTaskBody(responseTask)
      ) || data;
    dispatch(updateRepeatRequestTasks(mappedTasks));

    return mappedTasks;
  };
};

/**
 * Sets the childTasks in the state of a repeatRequest
 */
export const updateRepeatRequestTasks = (data) => {
  return {
    type: UPDATE_REPEAT_REQUEST_CHILD_TASKS,
    data
  };
};
