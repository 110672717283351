import { useMappedState } from 'redux-react-hook';

export default function useAdmin () {
  const mapState = ({ admin }) => admin;

  const admin = useMappedState(mapState);

  return {
    ...admin
  };
}
