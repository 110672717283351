import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ children, title, icon, subtitle }) => {
  return (
    <div className="page-header">
      <div className="page-header-content">
        <h1>
          {icon}
          {title || <>&nbsp;</>}
        </h1>
        {subtitle && <span className="page-header-subtitle">{subtitle}</span>}
      </div>
      {children && <div className="page-header-extras">{children}</div>}
    </div>
  );
};

PageHeader.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.node
};

export default PageHeader;
