import React from 'react';
import PropTypes from 'prop-types';
import { FaUpload } from 'react-icons/fa';

const FileUpload = ({ acceptedTypes = [], onFileRead }) => {
  /**
   * handleOnFileChange
   */

  function handleOnFileChange (e) {
    const file = e.target.files[0];
    const fileReader = new FileReader();

    fileReader.onloadend = handleOnFileRead;
    fileReader.readAsText(file);
  }

  /**
   * handleOnFileRead
   */

  function handleOnFileRead (event = {}) {
    const { currentTarget } = event;

    if (typeof onFileRead === 'function') {
      onFileRead({
        content: currentTarget.result,
        event
      });
    }
  }

  return (
    <div className="file-upload">
      <div className="file-upload-dropzone">
        <label htmlFor="file-upload-file">
          <FaUpload />
          <input
            id="file-upload-file"
            type="file"
            accept=".json, .geojson"
            multiple={false}
            onChange={handleOnFileChange}
          />
          <p>
            <span className="file-upload-link">Click to browse</span> or drag
            and drop to upload
          </p>
          {Array.isArray(acceptedTypes) && (
            <p className="file-upload-types">
              <strong>Accepted File Types</strong>: {acceptedTypes.join(' ')}
            </p>
          )}
        </label>
      </div>
    </div>
  );
};

FileUpload.propTypes = {
  acceptedTypes: PropTypes.array,
  onFileRead: PropTypes.func
};

export default FileUpload;
