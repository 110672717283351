import React from 'react';
import PropTypes from 'prop-types';
import { WonderLink } from 'fogg/ui';

import ClassName from '../models/classname';

const ProfileSidebarSection = ({
  children,
  className,
  header,
  actions = []
}) => {
  const sidebarClass = new ClassName('profile-sidebar');
  const componentClass = new ClassName(sidebarClass.childString('section'));

  componentClass.addIf(className, className);

  const hasActions = Array.isArray(actions) && actions.length > 0;

  return (
    <div className={componentClass.string}>
      <div className={sidebarClass.childString('header')}>
        {header && <h3>{header}</h3>}
        {hasActions && (
          <ul className={sidebarClass.childString('actions')}>
            {actions.map((action) => {
              const { label, onClick, icon, to } = action;
              return (
                <li key={label}>
                  <WonderLink to={to} onClick={onClick}>
                    {icon}
                    {label}
                  </WonderLink>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {children}
    </div>
  );
};

ProfileSidebarSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  header: PropTypes.string,
  actions: PropTypes.array
};

export default ProfileSidebarSection;
