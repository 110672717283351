import React from 'react';

import ThankYouLayout from 'components/ThankYouLayout';

import { routePathByName } from 'lib/routes';

const UserPremiumThankYouPage = () => {
  const actionRoutes = [
    {
      label: 'Map Search',
      to: routePathByName('search')
    },
    {
      label: 'Account',
      to: routePathByName('accountProfile')
    }
  ];

  return (
    <ThankYouLayout basePage="user-premium" actionRoutes={actionRoutes}>
      <p>Someone from our team will be in touch as soon as we can.</p>
    </ThankYouLayout>
  );
};

export default UserPremiumThankYouPage;
