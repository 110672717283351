import React from 'react';
import { Helmet } from 'react-helmet';
import { FaShoppingCart } from 'react-icons/fa';

import { useVerifyCart } from 'hooks';
import Layout from 'components/Layout';
import Container from 'components/Container';
import PageHeader from 'components/PageHeader';
import TemplateCart from 'templates/TemplateCart';

/**
 * Standalone Cart Page
 */
const CartViewPage = () => {
  const cartObject = useVerifyCart();

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-cart' }}>
        <title>Cart</title>
      </Helmet>
      <Container className="content">
        <PageHeader
          title="Cart"
          icon={<FaShoppingCart className="icon-fa" />}
        />
        <TemplateCart cartObject={cartObject} />
      </Container>
    </Layout>
  );
};

export default CartViewPage;
