import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FaDatabase } from 'react-icons/fa';
import { DataTable, TableSearchInput, WonderLink } from 'fogg/ui';
import { useTableData } from 'fogg/hooks';
import { datetime } from 'fogg/lib';

import {
  NavigatorLayout,
  NavigatorBody,
  PageHeader,
  Breadcrumbs,
  Container
} from 'commonLib';
import { fillEmptyOrders } from 'lib/data';
import { ordersToTableData, DEFAULT_ORDERS_TABLE_COLUMNS } from 'lib/orders';
import { routePathByName } from 'lib/routes';
import { ROUTE_ORDERS_DETAILS } from 'data/route-names';

import { USER_ROLE } from 'data/roles';

const { formatDateTime } = datetime;

const LOADING_ORDERS_COUNT = 5;

const ORDERS_COLUMNS = [
  {
    Header: 'Order ID',
    columnId: 'orderId',
    widthRatio: 3
  },
  {
    Header: 'User',
    columnId: 'userName',
    widthRatio: 3
  },
  {
    Header: 'Organization',
    columnId: 'organizationName',
    widthRatio: 3
  },
  {
    Header: 'Contract',
    columnId: 'contractLabel',
    widthRatio: 3
  },
  {
    Header: 'Date (UTC)',
    columnId: 'orderDate',
    align: 'center',
    widthRatio: 2,
    cellTransformer: (value) => formatDateTime(value),
    filterTransformer: (value) => formatDateTime(value),
    defaultSorted: 'desc'
  },
  {
    Header: 'Status',
    columnId: 'status',
    align: 'center',
    widthRatio: 1.5
  },
  {
    columnId: 'actions',
    Header: false,
    widthRatio: 0.5,
    align: 'right',
    type: 'action',
    canSort: false,
    canFilter: false
  }
];

/**
 * replaces the first plain text order id column with a better link action column
 * @param  {string} [detailsPath=ROUTE_ORDERS_DETAILS] - route name for order details
 */
function getOrderColumnsWithOrderIdAction (detailsPath = ROUTE_ORDERS_DETAILS) {
  const [orderIdCol, ...restOfOrdersCols] = ORDERS_COLUMNS;

  const betterOrdersCols = [{
    ...orderIdCol,
    cellTransformer: (id) => {
      if (id === 'Loading') return id;

      const orderDetailsPath = routePathByName(detailsPath, {
        wildcard: [id]
      });

      return (
        <WonderLink className="data-table-details-link" to={orderDetailsPath}>{id}</WonderLink>
      );
    }
  }, ...restOfOrdersCols];

  return betterOrdersCols;
}

const TemplateOrders = ({
  accessType = USER_ROLE,
  breadcrumbs = [],
  detailsRouteName = ROUTE_ORDERS_DETAILS,
  emptyActions = [],
  orders,
  state = {},
  tableColumns = DEFAULT_ORDERS_TABLE_COLUMNS
}) => {
  let activeOrders = orders;
  const { isLoading = false } = state;

  if (isLoading) {
    activeOrders = fillEmptyOrders(LOADING_ORDERS_COUNT);
  }

  // Determine the columns we'll use to display the orders table. We want to only
  // include the columns we permit, so we give the TemplateOrders component the
  // ability to override the default included onces

  const betterOrdersCols = getOrderColumnsWithOrderIdAction(detailsRouteName);

  const columnDefinitions = betterOrdersCols.filter(({ columnId } = {}) => {
    return tableColumns.includes(columnId);
  });

  // Transform our active orders into tabular data that we'll use to pass
  // in to our component

  const orderData = ordersToTableData(activeOrders, {
    routeName: detailsRouteName,
    columns: tableColumns
  });

  const { columns, data, sort, filter, clearFilters, filters } = useTableData({
    columns: columnDefinitions,
    data: orderData
  });

  const searchFilters =
    filters.find(({ filterType } = {}) => filterType === 'search') || {};
  const { filterValue } = searchFilters;

  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;

  /**
   * handleOnSort
   */

  function handleOnSort (cell) {
    sort(cell);
  }

  /**
   * handleOnFilter
   */

  function handleOnFilter (filterData) {
    filter(filterData);
  }

  /**
   * handleOnInputChange
   */

  function handleOnInputChange ({ currentTarget = {} } = {}) {
    const { value } = currentTarget;

    const filterData = {
      value
    };

    handleOnFilter(filterData);
  }

  return (
    <NavigatorLayout access={accessType} className="layout-navigator full">
      <Helmet bodyAttributes={{ class: 'template-orders' }}>
        <title>Orders</title>
      </Helmet>
      <Container type="full">
        <NavigatorBody className="layout-navigator-body">
          <div className="data-table-page-header">
            <div>
              {hasBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}

              <PageHeader
                title="Orders"
                icon={<FaDatabase className="icon-fa" />}
              />
            </div>
            <div className="data-table-search-input">
              <TableSearchInput
                value={filterValue}
                onChange={handleOnInputChange}
                onClear={clearFilters}
              />
            </div>
          </div>

          <div className="data-table-wrapper">
            <DataTable
              label="Orders"
              onSort={handleOnSort}
              columns={columns}
              data={data}
              isLoading={isLoading}
              emptyActions={emptyActions}
              fixHeightToContent={false}
            />
          </div>
        </NavigatorBody>
      </Container>
    </NavigatorLayout>
  );
};

TemplateOrders.propTypes = {
  state: PropTypes.object,
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableColumns: PropTypes.array,
  detailsRouteName: PropTypes.string,
  accessType: PropTypes.string,
  breadcrumbs: PropTypes.array,
  emptyActions: PropTypes.array
};

export default TemplateOrders;
