import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'fogg/ui';

/**
 * Similar to ItemCard item and CostBreakdownItem, but simplified
 * for the Cart AddOn items specifically
 * @param {object} props
 * @param {string} props.title=''
 * @param {string} [props.badge='']
 * @param {object} [props.actionButtons]
 */
const CostBreakdownAddOn = ({
  badge = '',
  title = '',
  actionButtons,
  cost
}) => {
  return (
    <div className={`item-card-wrapper cost-breakdown-addon is-child ${badge}`}>
      <div className="item-card">
        <div className="item-overview">
          <div className="item-overview-content">
            <div className="item-overview-id">
              {badge && (<Badge label={badge} type="info" />)}
              <strong>{title}</strong>
            </div>
          </div>
        </div>
      </div>
      {actionButtons && (
        <div className="item-card-actions">
          {actionButtons}
        </div>
      )}
      {cost && (
        <div className="item-card-cost">
          {cost}
        </div>
      )}
    </div>
  );
};

CostBreakdownAddOn.propTypes = {
  title: PropTypes.string.isRequired,
  badge: PropTypes.string,
  actionButtons: PropTypes.node,
  cost: PropTypes.string
};

export default CostBreakdownAddOn;
