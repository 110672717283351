import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { Button, Form, FormInput, FormRow } from 'fogg/ui';

import { fieldByName } from 'lib/fields';
import { logError } from 'lib/logger';

import { postPasswords } from 'state/actions';

import LinkList from 'components/LinkList';

const FormResetPassword = ({ email, links, onSuccess, onFailure }) => {
  const dispatch = useDispatch();

  const validationRules = {
    email: {
      ...fieldByName('email'),
      required: true
    }
  };

  /**
   * handleResetSubmit
   */

  function handleResetSubmit (e, fields = {}) {
    const { email = {} } = fields;
    dispatch(postPasswords(email.value))
      .then((data) => {
        if (typeof onSuccess === 'function') {
          onSuccess(data, {
            fields
          });
        }
      })
      .catch((error) => {
        logError(error.message, fields);
        if (typeof onSuccess === 'function') {
          onFailure(error, {
            fields
          });
        }
      });
  }

  return (
    <Form onSubmit={handleResetSubmit} rules={validationRules}>
      <FormRow>
        {/* If we have an email set, force the user ot use it, such as if they're logged in */}
        <FormInput
          id="email"
          label="Email"
          type="email"
          required={true}
          defaultValue={email}
          disabled={!!email}
        />
      </FormRow>

      <FormRow>
        <Button>Send Password Reset</Button>
      </FormRow>

      {Array.isArray(links) && links.length > 0 && (
        <FormRow className="login-links">
          <LinkList className="text-center" links={links} />
        </FormRow>
      )}
    </Form>
  );
};

FormResetPassword.propTypes = {
  email: PropTypes.string,
  links: PropTypes.array,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func
};

export default FormResetPassword;
