import React from 'react';
import PropTypes from 'prop-types';

const IconPolygon = ({ className }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M23 6.5L10.5 0.5L0 11L7.5 24L22 21L23 6.5Z" />
    </svg>
  );
};

IconPolygon.propTypes = {
  className: PropTypes.string
};

export default IconPolygon;
