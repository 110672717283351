import { useMappedState } from 'redux-react-hook';

import Order from 'models/order';

export default function useOrgOrders () {
  const mapState = ({ org }) => org;
  const { orders = [], users = [] } = useMappedState(mapState);

  return {
    orders: Order.mapUsersToOrders(orders, users)
  };
}
