import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAdmin } from '../hooks';
import { logError } from '../lib/logger';
import {
  triggerNotice,
  fetchAllUsersPaged,
  updateAdminUsers
} from '../state/actions';

export default function useAdminFetchUsers ({ forceNew = false } = {}) {
  const { users = [], actions = {} } = useAdmin();
  const { fetchAllUsersPaged: fetchAllUsersAction = {} } = actions;
  const { firstLoad } = fetchAllUsersAction;
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstLoad && !forceNew) return;

    // Fetch users until we reach end of paged results
    async function fetchUsers () {
      let totalUsers;
      try {
        const request = await dispatch(fetchAllUsersPaged({ page: 1 }));
        const { results = [], currentPage = 1, totalPages = 1 } = request || {};
        totalUsers = results;

        // 1 page of results...
        if (currentPage === totalPages) {
          dispatch(updateAdminUsers(totalUsers));
          return;
        } else {
          // More than 1 page, so loop through them
          for (let i = 2; i <= totalPages; i++) {
            const { results } = await dispatch(
              fetchAllUsersPaged({ page: i })
            );
            totalUsers.push(...results);
            // Update users store at the final page
            if (i === totalPages) {
              dispatch(updateAdminUsers(totalUsers));
            }
          }
        }
      } catch (e) {
        logError(e.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      }
    }

    fetchUsers();
  }, [dispatch, forceNew, firstLoad]);

  return {
    ...actions.fetchAllUsersPaged,
    users
  };
}
