import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormRow, FormInput, Notice } from 'fogg/ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { TaskRequestTypes } from 'data/task-request-types';
import { isPointGeometryType } from 'lib/tasks';

import { useUser, useTaskContext } from 'hooks';

import FormCreateTaskModular from './FormCreateTaskModular';
import FormCreateTaskOosm from './FormCreateTaskOosm';
import FormCreateTaskRepeat from './FormCreateTaskRepeat';
import FormCreateTaskStandard from './FormCreateTaskStandard';

/**
 * shows the customizable form for creating a new task
 * @param {function} onSubmit - handles submitting task parameters for review
 * @param {function} onCancel - handles cancelling the task
 * @param {object} [task={}] - the task handed down from the dispatch
 * @param {object} [geoJson={}] - map data object for said task
 */
const FormCreateTask = ({ onSubmit, onCancel, geoJson = {} }) => {
  /**
   * user and organization destructured values from user hook
   */
  const {
    user: {
      organizationId,
      id: userId,
      apiEnvironmentRole,
      isSandbox = apiEnvironmentRole === 'sandbox'
    } = {}
  } = useUser();

  // consumer of TaskCreateFormProvider
  const { taskState: storedTask, updateTaskState } = useTaskContext();

  // task props that can change from the parent level and cause side effects
  const {
    name: storedTaskName = '',
    description: storedTaskDescription = '',
    taskRequestType = TaskRequestTypes.STANDARD,
    isStandardTask = taskRequestType === TaskRequestTypes.STANDARD,
    isOosmTask = taskRequestType === TaskRequestTypes.OOSM,
    isRepeatTask = taskRequestType === TaskRequestTypes.REPEAT
  } = storedTask;

  const [taskName, setTaskName] = useState(storedTaskName);
  const [taskDescription, setTaskDescription] = useState(storedTaskDescription);

  const isPoint = isPointGeometryType({ geoJson });

  /**
   * event handlers
   */

  /**
   * handleSubmit
   */
  function handleSubmit (e, payload) {
    if (typeof onSubmit === 'function') {
      onSubmit(e, payload, collectionTypesEnabled);
    }
  }

  /**
   * handleCancel
   */

  function handleCancel (e, fields) {
    e.preventDefault();

    if (typeof onCancel === 'function') {
      onCancel(e, fields);
    }
  }

  // Keeping Task Context updated when name or description change state
  useEffect(() => {
    if (taskName !== storedTaskName) {
      updateTaskState({ name: taskName });
    }
    if (taskDescription !== storedTaskDescription) {
      updateTaskState({ description: taskDescription });
    }
  }, [taskName, taskDescription]);

  const flags = useFlags();
  const collectionTypesEnabled = flags.collectionTypeTaskingFormEnabled?.organizations?.includes(organizationId) || false;

  // TODO use onBlur for text field changes (need to update FOGG)
  const primaryTaskFields = (
    <>
      <FormInput id="userId" type="hidden" required={true} value={userId} />

      <FormInput
        id="orgId"
        type="hidden"
        required={true}
        value={organizationId}
      />

      <FormInput
        id="organizationId"
        type="hidden"
        required={true}
        value={organizationId}
      />

      <FormRow>
        <FormInput
          id="name"
          label="Target Name"
          defaultValue={taskName}
          onChange={({ target: { value = '' } }) =>
            setTaskName(value)
          }
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="description"
          label="Target Description"
          defaultValue={taskDescription}
          onChange={({ target: { value = '' } }) =>
            setTaskDescription(value)
          }
        />
      </FormRow>
    </>
  );

  // TODO - Remove this along with FormCreateTaskStandard and FormCreateTaskRepeat
  const footerNotice = isSandbox && (
    <Notice type="warning" weight="bold">
      Your account is currently configured for simulated tasking only.
    </Notice>
  );

  return (
    <>
      {(isStandardTask || isRepeatTask) && collectionTypesEnabled ? (
        <FormCreateTaskModular
          task={storedTask}
          geoJson={geoJson}
          isSandbox={isSandbox}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isRepeatTask={isRepeatTask}
          primaryTaskFields={primaryTaskFields}
        />
      ) : (
        <>
          {/* Standard task */}
          {isStandardTask && (
            <FormCreateTaskStandard
              primaryTaskFields={primaryTaskFields}
              task={storedTask}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              footerChildren={footerNotice}
              geoJson={geoJson}
              enableCollectTimeSelect={isPoint}
            />
          )}

          {/* Repeat Task */}
          {isRepeatTask && (
            <FormCreateTaskRepeat
              primaryTaskFields={primaryTaskFields}
              task={storedTask}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              footerChildren={footerNotice}
              geoJson={geoJson}
              enableCollectTimeSelect={isPoint}
            />
          )}
        </>
      )}

      {/* OOSM task specific form fields */}
      {isOosmTask && (
        <FormCreateTaskOosm
          primaryTaskFields={primaryTaskFields}
          task={storedTask}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          footerChildren={footerNotice}
        />
      )}
    </>
  );
};

FormCreateTask.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  geoJson: PropTypes.object
};

export default FormCreateTask;
