import React from 'react';
import PropTypes from 'prop-types';

import ClassName from '../models/classname';

const ProfileSidebar = ({ children, className }) => {
  const componentClass = new ClassName('profile-sidebar-section');

  componentClass.addIf(className, className);

  return <div className="profile-sidebar">{children}</div>;
};

ProfileSidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

export default ProfileSidebar;
