import React from 'react';
import PropTypes from 'prop-types';
import { StatusIndicator } from 'fogg/ui';

import { formatDateTime } from 'lib/datetime';
import { TASK_STATUS_PROCESS_CHAIN, TASK_ERROR_LIST } from 'lib/tasks';

const DEFAULT_HEADERS = ['Window Open (UTC)', 'Window Close (UTC)'];

const TaskStatus = ({
  headers = DEFAULT_HEADERS,
  task = {},
  windowOnly = false
}) => {
  const { status, statusMessage, windowOpenInt, windowCloseInt } = task;

  return (
    <div className="task-status">
      <div className="task-status-window">
        <h4>{headers[0]}</h4>
        <p className="task-status-date">{formatDateTime(windowOpenInt)}</p>
      </div>

      {!windowOnly && (
        <div className="task-status-indicator">
          <StatusIndicator
            activeId={status}
            statusList={TASK_STATUS_PROCESS_CHAIN}
            errorList={TASK_ERROR_LIST}
          />
          <p className="task-status-indicator-message">{statusMessage}</p>
        </div>
      )}

      <div className="task-status-window">
        <h4>{headers[1]}</h4>
        <p className="task-status-date">{formatDateTime(windowCloseInt)}</p>
      </div>
    </div>
  );
};

TaskStatus.propTypes = {
  headers: PropTypes.array,
  task: PropTypes.object,
  windowOnly: PropTypes.bool
};

export default TaskStatus;
