import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBriefcase } from 'react-icons/fa';

import ContractPeriod from '../../../../models/contract-period';

import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from '../../../../data/contracts';

import {
  routePathByName,
  redirectTo,
  logError,
  updateOrganizationContractPeriod,
  triggerSuccess,
  triggerError,
  useAdminFetchOrganization,
  Breadcrumbs,
  PageHeader,
  Container,
  FormContractPeriodEdit
} from 'commonLib';

import Layout from 'components/Layout';

const PAGE_TITLE = 'Edit Contract Period';

const AdminOrganizationsContractPeriodEditPage = ({
  organizationId,
  contractPeriodId
}) => {
  const dispatch = useDispatch();

  const { organization = {}, isLoading } = useAdminFetchOrganization({
    id: organizationId
  });

  const contract =
    organization?.contracts?.find((orgContract) =>
      orgContract?.contractPeriods?.find((cp) => cp.id === contractPeriodId)
    ) || {};

  let contractPeriod = contract?.contractPeriods?.find(
    (cp) => cp.id === contractPeriodId
  );
  contractPeriod = new ContractPeriod(contractPeriod);

  const isArchiveSub = contract?.type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;

  const contractDetailsPath = routePathByName(
    'adminOrganizationContractDetails',
    {
      wildcard: [organizationId, contract.id]
    }
  );

  function handleOnSubmit ({ data }) {
    dispatch(
      updateOrganizationContractPeriod(organizationId, contractPeriodId, data)
    )
      .then(() => {
        redirectTo(contractDetailsPath);
        dispatch(
          triggerSuccess(
            `Successfully updated contract period ${contractPeriodId}!`
          )
        );
      })
      .catch((error) => {
        logError(error.message, data);
        dispatch(triggerError());
      });
  }

  // Admins can "cancel an edit" by navigating back to the org details page
  function handleOnCancel () {
    redirectTo(contractDetailsPath);
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: 'page-admin page-admin-contract page-admin-contract-edit'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Admin',
              to: routePathByName('admin')
            },
            {
              label: 'Organizations',
              to: routePathByName('adminOrganizations')
            },
            {
              label: 'Organization',
              to: routePathByName('adminOrganizationsDetails', {
                wildcard: organizationId
              })
            },
            {
              label: 'Contract',
              to: contractDetailsPath
            }
          ]}
        />
        <PageHeader title={PAGE_TITLE} icon={<FaBriefcase />} />
        <FormContractPeriodEdit
          contract={contract}
          contractPeriod={contractPeriod}
          isArchiveSub={isArchiveSub}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
          disabled={isLoading}
        />
      </Container>
    </Layout>
  );
};

AdminOrganizationsContractPeriodEditPage.propTypes = {
  organizationId: PropTypes.string,
  contractPeriodId: PropTypes.string
};

export default AdminOrganizationsContractPeriodEditPage;
