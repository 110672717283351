import clone from 'clone';
import deepmerge from 'deepmerge';

import { updateSingleEntityByKey } from '../../lib/state';

const defaultState = {
  users: [],
  organizations: [],
  actions: {},
  s3buckets: [],
  organizationLedgerContracts: {},
  publicCollections: [],
  licenses: {}
};

const admin = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);

  switch (type) {
    case 'UPDATE_ADMIN_USERS': {
      const users = [...workingState.users];

      let updatedState;
      const updatedUserIndex = users.findIndex(
        (user) => user.id === workingData.id
      );

      //  if we found an index, it's not an array and we are updating a single user
      if (updatedUserIndex !== -1) {
        users[updatedUserIndex] = {
          ...users[updatedUserIndex],
          ...workingData
        };
        updatedState = users;
      } else if (!Array.isArray(workingData)) {
        updatedState = [workingData];
      } else {
        updatedState = [...workingData];
      }

      return {
        ...workingState,
        users: updatedState
      };
    }
    case 'ADD_ADMIN_USER': {
      return {
        ...workingState,
        users: [...(state.users || []), workingData]
      };
    }

    case 'DELETE_ADMIN_USER': {
      const users = [...workingState.users];
      return {
        ...workingState,
        users: users.filter((user) => user.id !== workingData)
      };
    }

    case 'UPDATE_ADMIN_ORGANIZATION': {
      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: workingData,
        previousEntities: workingState.organizations
      });
      return {
        ...workingState,
        organizations: workingState.organizations
      };
    }

    case 'UPDATE_ADMIN_ORGANIZATIONS': {
      if (!Array.isArray(workingData)) {
        throw new Error('Invalid data passed to UPDATE_ADMIN_ORGANIZATIONS');
      }
      const orgs = [...workingState.organizations];

      let updatedState;
      const updatedOrgIndex = orgs.findIndex(
        (org) => org.id === workingData.id
      );

      //  if we found an index, it's not an array and we are updating a single org
      if (updatedOrgIndex !== -1) {
        orgs[updatedOrgIndex] = {
          ...orgs[updatedOrgIndex],
          ...workingData
        };
        updatedState = orgs;
      } else {
        updatedState = [...workingData];
      }

      return {
        ...workingState,
        organizations: updatedState
      };
    }

    case 'ADD_ADMIN_ORGANIZATION': {
      return {
        ...workingState,
        organizations: [...(state.organizations || []), workingData]
      };
    }

    case 'UPDATE_ADMIN_ORGANIZATION_LEDGER_CONTRACTS': {
      return {
        ...workingState,
        organizationLedgerContracts: workingData
      };
    }

    case 'UPDATE_ADMIN_ORGANIZATION_S3_BUCKET': {
      workingState.s3buckets = updateSingleEntityByKey({
        key: 'id',
        entity: workingData,
        previousEntities: workingState.s3buckets
      });
      return {
        ...workingState,
        s3buckets: workingState.s3buckets
      };
    }

    case 'ADD_ADMIN_ORGANIZATION_S3_BUCKET': {
      return {
        ...workingState,
        s3buckets: [...(state.s3buckets || []), workingData]
      };
    }

    case 'ADD_ADMIN_ORGANIZATION_CONTRACT': {
      const { organizationId, contract } = workingData;
      const organization = workingState.organizations.find(
        (org) => org.id === organizationId
      );

      organization.contracts.push(contract);

      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: organization,
        previousEntities: workingState.organizations
      });

      return {
        ...workingState,
        organizations: [...workingState.organizations]
      };
    }

    case 'UPDATE_ADMIN_ORGANIZATION_CONTRACT': {
      const { organizationId, contract } = workingData;
      const organization = workingState.organizations.find(
        (org) => org.id === organizationId
      );

      organization.contracts = updateSingleEntityByKey({
        key: 'id',
        entity: contract,
        previousEntities: organization.contracts
      });
      if (organization.contract.id === contract.id) {
        organization.contract = contract;
      }

      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: organization,
        previousEntities: workingState.organizations
      });

      return {
        ...workingState,
        organizations: [...workingState.organizations]
      };
    }

    case 'UPDATE_ADMIN_ORGANIZATION_CONTRACT_PERIOD': {
      const { organizationId, contractPeriod } = workingData;
      const organization = workingState.organizations.find(
        (org) => org.id === organizationId
      );
      const contract = organization.contracts.find(
        (orgContract) => orgContract.id === contractPeriod.contractId
      );

      contract.contractPeriods = updateSingleEntityByKey({
        key: 'id',
        entity: contractPeriod,
        previousEntities: contract.contractPeriods
      });
      organization.contracts = updateSingleEntityByKey({
        key: 'id',
        entity: contract,
        previousEntities: organization.contracts
      });
      if (organization.contract.id === contract.id) {
        organization.contract = contract;
      }

      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: organization,
        previousEntities: workingState.organizations
      });

      return {
        ...workingState,
        organizations: [...workingState.organizations]
      };
    }

    case 'ADD_ADMIN_ORGANIZATION_CONTRACT_PERIOD': {
      const { organizationId, contractPeriod } = workingData;
      let organization = workingState.organizations.find(
        (org) => org.id === organizationId
      );

      if (!organization) {
        organization = {
          id: organizationId,
          contract: {
            contractPeriods: []
          }
        };
      }
      const contract = organization.contracts.find(
        (orgContract) => orgContract.id === contractPeriod.contractId
      );

      contract.contractPeriods.push(contractPeriod);
      organization.contracts = updateSingleEntityByKey({
        key: 'id',
        entity: contract,
        previousEntities: organization.contracts
      });
      if (organization.contract.id === contract.id) {
        organization.contract = contract;
      }

      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: organization,
        previousEntities: workingState.organizations
      });

      return {
        ...workingState,
        organizations: [...workingState.organizations]
      };
    }

    case 'UPDATE_ADMIN_ORGANIZATION_COLLECTION_PERMISSIONS': {
      const { organizationId, collections } = workingData;

      const organization = workingState.organizations.find(
        (org) => org.id === organizationId
      ) || {
        id: organizationId
      };

      organization.collections = collections;

      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: organization,
        previousEntities: workingState.organizations
      });

      return {
        ...workingState,
        organizations: [...workingState.organizations]
      };
    }

    case 'ADD_ADMIN_ORGANIZATION_COLLECTION_PERMISSIONS': {
      const { organizationId, collections } = workingData;

      const organization = workingState.organizations.find(
        (org) => org.id === organizationId
      ) || {
        id: organizationId
      };

      organization.collections = Array.from(
        new Set([...collections, ...(organization.collections || [])])
      );

      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: organization,
        previousEntities: workingState.organizations
      });

      return {
        ...workingState,
        organizations: [...workingState.organizations]
      };
    }

    case 'REMOVE_ADMIN_ORGANIZATION_COLLECTION_PERMISSIONS': {
      const { organizationId, collections } = workingData;

      const organization = workingState.organizations.find(
        (org) => org.id === organizationId
      );

      organization.collections = organization.collections.filter(
        (collection) => !collections.includes(collection)
      );

      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: organization,
        previousEntities: workingState.organizations
      });

      return {
        ...workingState,
        organizations: [...workingState.organizations]
      };
    }

    case 'ADD_ADMIN_CONTRACT_DATASHARE_ORGANIZATION': {
      const { ownerId, contractId, shareData } = workingData;

      let organization = workingState.organizations.find(
        (org) => org.id === ownerId
      );

      if (!organization) {
        organization = {
          id: ownerId,
          contract: {
            dataSharedWith: []
          }
        };
      }
      const contract = organization.contracts.find(
        (orgContract) => orgContract.id === contractId
      );

      contract.dataSharedWith.push(shareData);
      organization.contracts = updateSingleEntityByKey({
        key: 'id',
        entity: contract,
        previousEntities: organization.contracts
      });
      if (organization.contract.id === contract.id) {
        organization.contract = contract;
      }

      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: organization,
        previousEntities: workingState.organizations
      });

      return {
        ...workingState,
        organizations: [...workingState.organizations]
      };
    }

    case 'REMOVE_ADMIN_CONTRACT_DATASHARE_ORGANIZATION': {
      const { ownerId, contractId, orgId } = workingData;

      const organization = workingState.organizations.find(
        (org) => org.id === ownerId
      );
      const contract = organization.contracts.find(
        (orgContract) => orgContract.id === contractId
      );

      contract.dataSharedWith = contract.dataSharedWith.filter(
        (share) => share.orgId !== orgId
      );
      organization.contracts = updateSingleEntityByKey({
        key: 'id',
        entity: contract,
        previousEntities: organization.contracts
      });
      if (organization.contract.id === contract.id) {
        organization.contract = contract;
      }

      workingState.organizations = updateSingleEntityByKey({
        key: 'id',
        entity: organization,
        previousEntities: workingState.organizations
      });

      return {
        ...workingState,
        organizations: [...workingState.organizations]
      };
    }

    case 'FETCH_ADMIN_PUBLIC_COLLECTIONS':
      return {
        ...workingState,
        publicCollections: [...workingData.publicCollections]
      };

    case 'UPDATE_ADMIN_ACTION_STATE':
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };

    case 'UPDATE_LICENSES': {
      return {
        ...workingState,
        licenses: deepmerge(workingState.licenses, workingData)
      };
    }

    default:
      return workingState;
  }
};

export default admin;
