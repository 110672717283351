import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBriefcase } from 'react-icons/fa';

import {
  routePathByName,
  redirectTo,
  logError,
  addOrganizationContractPeriod,
  useAdminFetchOrganization,
  triggerSuccess,
  triggerError,
  Breadcrumbs,
  PageHeader,
  Container,
  FormContractPeriodAdd
} from 'commonLib';

import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from '../../../../data/contracts';

import Layout from 'components/Layout';

const PAGE_TITLE = 'Add Contract Period';

const AdminOrganizationsContractPeriodAddPage = ({
  organizationId,
  contractId
}) => {
  const dispatch = useDispatch();

  const { organization = {}, isLoading } = useAdminFetchOrganization({
    id: organizationId
  });

  const contract = organization?.contracts?.find(contract => contract.id === contractId);
  const isArchiveSub = contract?.type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;

  const contractDetailsPath = routePathByName(
    'adminOrganizationContractDetails',
    {
      wildcard: [organizationId, contractId]
    }
  );

  function handleOnSubmit ({ data }) {
    dispatch(addOrganizationContractPeriod(organizationId, contractId, data))
      .then(() => {
        redirectTo(contractDetailsPath);
        dispatch(triggerSuccess('Successfully added a new contract period!'));
      })
      .catch((error) => {
        logError(error.message, data);
        dispatch(triggerError());
      });
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: 'page-admin page-admin-contract page-admin-contract-edit'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Admin',
              to: routePathByName('admin')
            },
            {
              label: 'Organizations',
              to: routePathByName('adminOrganizations')
            },
            {
              label: 'Organization',
              to: routePathByName('adminOrganizationsDetails', {
                wildcard: organizationId
              })
            },
            {
              label: 'Contract',
              to: contractDetailsPath
            }
          ]}
        />
        <PageHeader title={PAGE_TITLE} icon={<FaBriefcase />} />
        <FormContractPeriodAdd
          disabled={isLoading}
          isArchiveSub={isArchiveSub}
          onSubmit={handleOnSubmit}
        />
      </Container>
    </Layout>
  );
};

AdminOrganizationsContractPeriodAddPage.propTypes = {
  organizationId: PropTypes.string,
  contractId: PropTypes.string
};

export default AdminOrganizationsContractPeriodAddPage;
