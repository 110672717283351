import React from 'react';
import PropTypes from 'prop-types';
import { ROUTE_TASKS } from 'commonLib/src/data/route-names';
import { useFetchTask, useTask } from 'hooks';
import { routePathByName } from 'lib/routes';
import { ROUTE_TASKS_DETAILS } from 'data/route-names';
import TemplateEditTask from 'templates/TemplateEditTask';

const TaskEditPage = ({ taskId }) => {
  const task = useTask(taskId) || {
    id: taskId
  };

  const { name } = task || {};

  const { isLoading = false } = useFetchTask(taskId, true);

  const breadcrumbs = [
    {
      label: 'Tasks',
      to: routePathByName(ROUTE_TASKS)
    },
    {
      label: name,
      to: routePathByName(ROUTE_TASKS_DETAILS, {
        wildcard: [taskId]
      })
    }
  ];

  return (
    <TemplateEditTask
      task={task}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    />
  );
};

TaskEditPage.propTypes = {
  taskId: PropTypes.string
};

export default TaskEditPage;
