import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { MdPlaylistAdd, MdRepeat } from 'react-icons/md';
import { NavBar, FormRow, InputButton, Badge, WonderLink } from 'fogg/ui';
import {
  useTask,
  useCreateNewEntry,
  useLocation,
  usePageReqs,
  useAreaOfInterest,
  useUser,
  useTaskContext,
  useRepeatRequest,
  useTaskCreate
} from 'hooks';
import { storeNewTask, updateTask } from 'state/actions';
import { routePathByName } from 'lib/routes';
import { mapCreateTaskFieldsToTask, isPointGeometryType, taskDefaults, THIRTY_DAYS_MS } from 'lib/tasks';
import { isEmptyObject } from 'lib/util';

import Layout from 'components/Layout';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import FormCreateTask from 'components/FormCreateTask';
import MapPreview from 'components/MapPreview';
import AccessRequests from 'components/AccessRequests';
import { NEW_TASK } from 'data/action-types';
import { TaskRequestTypes } from 'data/task-request-types';
import { ROUTE_TASKS_DETAILS, ROUTE_REPEAT_REQUEST_DETAILS } from 'data/route-names';

const TasksCreatePage = ({ location }) => {
  const dispatch = useDispatch();
  const { prevPath = '', queryParams = {} } = useLocation(location);
  const [isCanceled, setIsCanceled] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const mapRef = useRef();

  /**
   * organization destructured values from user hook
   */
  const {
    user: {
      organization: {
        icebreakerApiEnabled = false
      } = {}
    } = {}
  } = useUser();

  const { taskState, changeTaskRequestType, updateTaskState } =
    useTaskContext();

  const {
    geometry,
    taskRequestType = TaskRequestTypes.STANDARD,
    isRepeatTask = taskRequestType === TaskRequestTypes.REPEAT,
    isOosmTask = taskRequestType === TaskRequestTypes.OOSM,
    ascDsc = '',
    collectConstraints: {
      imageLength = taskDefaults.DEFAULT_IMAGE_LENGTH,
      imageWidth = taskDefaults.DEFAULT_IMAGE_WIDTH,
      offNadirMax = taskDefaults.DEFAULT_OFF_NADIR_MAX,
      offNadirMin = taskDefaults.DEFAULT_OFF_NADIR_MIN,
      localTime = taskDefaults.DEFAULT_LOCAL_TIME
    } = {},
    lookDirection = '',
    orbitalPlanes,
    taskingTier = taskDefaults.DEFAULT_TASKING_TIER,
    windowCloseInt,
    windowOpenInt,
    collectMode,
    productCategory,
    shouldRefreshAccessTimes = false,
    repetitionProperties: {
      repeatStart,
      repeatEnd
    } = {},
    isRetask = false,
    fromArchive = false,
    origTaskingId = ''
  } = taskState;

  const [taskType, setTaskType] = useState(taskRequestType);

  const newEntryHookOptions = isRepeatTask
    ? {
      entryHook: useRepeatRequest,
      entryPathBase: 'repeatRequests',
      entryType: 'repeatRequest'
    }
    : {
      entryHook: useTask,
      entryPathBase: 'tasks',
      entryType: 'task'
    };
  const { newEntry = {} } = useCreateNewEntry({
    ...newEntryHookOptions,
    entryStatus: isCanceled ? 'canceled' : 'active',
    location
  });

  const { position: mapPosition, geoJson: mapGeoJson } = useAreaOfInterest({
    geoJson: queryParams.geoJson,
    position: queryParams.mapPosition,
    geometry
  });

  const { path: reviewTaskPath } = useTaskCreate({
    geoJson: mapGeoJson,
    path: 'review'
  });

  const windowOpen = repeatStart || windowOpenInt;
  const windowClose = taskRequestType === 'repeat'
    ? repeatEnd || new Date(new Date(repeatStart).getTime() + THIRTY_DAYS_MS).getTime()
    : windowCloseInt;

  const taskDetailsPath = !isRetask ? '' : isRepeatTask ? routePathByName(ROUTE_REPEAT_REQUEST_DETAILS, {
    wildcard: [origTaskingId]
  }) : routePathByName(ROUTE_TASKS_DETAILS, {
    wildcard: [origTaskingId]
  });

  /**
   * local state for controlling accessRequests component rendering
   */
  const [accessRequestsState, setAccessRequestsState] = useState({
    taskRequestType,
    geoJson: mapGeoJson,
    windowOpen,
    windowClose,
    localTime,
    tier: taskDefaults.DEFAULT_TASKING_TIER,
    accessRequestOffNadirMin: offNadirMin,
    accessRequestOffNadirMax: offNadirMax,
    accessRequestAscending: ascDsc,
    accessRequestLookDirection: lookDirection,
    accessRequestOrbitalPlanes: orbitalPlanes,
    isPoint: isPointGeometryType({ geoJson: mapGeoJson }),
    imageLength,
    imageWidth,
    imagingMode: collectMode,
    productCategory,
    refMap: mapRef
  });

  // Watch for Task State changes and pass them to AccessRequests
  useEffect(() => {
    // Shared task state
    setTaskType(taskRequestType);
    setAccessRequestsState({
      ...accessRequestsState,
      localTime,
      taskRequestType,
      windowOpen: windowOpen,
      windowClose: windowClose,
      tier: taskingTier,
      accessRequestOffNadirMin: offNadirMin,
      accessRequestOffNadirMax: offNadirMax,
      accessRequestAscending: ascDsc,
      accessRequestLookDirection: lookDirection,
      accessRequestOrbitalPlanes: orbitalPlanes || [],
      isPoint: isPointGeometryType({ geoJson: mapGeoJson }),
      imageLength,
      imageWidth,
      imagingMode: collectMode,
      productCategory
    });
  }, [taskState]);

  /**
   * handleOnAccessRequestRefresh
   */

  async function handleOnAccessRequestRefresh () {
    const taskStart = windowOpenInt;
    const taskEnd = windowCloseInt;
    const difference = taskEnd - taskStart;
    const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

    function refreshValues () {
      updateTaskState({
        shouldRefreshAccessTimes: false,
        taskingTier: daysDifference
      });
    }

    await refreshValues();

    setAccessRequestsState((prevState) => ({
      ...prevState,
      localTime,
      geoJson: mapGeoJson,
      windowOpen: taskStart,
      windowClose: taskEnd,
      tier: taskingTier,
      accessRequestOffNadirMin: offNadirMin,
      accessRequestOffNadirMax: offNadirMax,
      accessRequestAscending: ascDsc,
      accessRequestLookDirection: lookDirection,
      accessRequestOrbitalPlanes: orbitalPlanes,
      isPoint: isPointGeometryType({ geoJson: mapGeoJson }),
      imageWidth,
      imageLength,
      imagingMode: collectMode,
      productCategory
    }));
    // Keep track of Refresh Access Times clicks, passing to
    // AccessRequests & useAccessRequests to generate a new unique key
    setRefreshCounter(refreshCounter + 1);
  }

  useEffect(() => {
    if (isCanceled === true) {
      navigate(routePathByName('search'));
    }
  }, [isCanceled]);

  const { reqsMet } = usePageReqs({
    condition: mapPosition || mapGeoJson || !isCanceled,
    error: {
      text: 'Please select an Area of Interest to create a new task.',
      to: routePathByName('search'),
      message: 'No Area of Interest selected.'
    }
  });

  /**
   * changes task request type and updates the new state
   * @param {string} [requestType="standard"] - tasking request type, defaults to 'standard'
   * @param {object} [eventTargetChecked=false] - optional event object passed via click event, should contain { currentTarget.checked } as prop
   */
  function handleTaskRequestTypeChange (
    requestType = TaskRequestTypes.STANDARD,
    { currentTarget: { checked: eventTargetChecked = false } = {} } = {}
  ) {
    let updatedRequestType = TaskRequestTypes.STANDARD;

    // handle oosm check toggle, checked = 'oosm', unchecked = 'standard'
    if (requestType === TaskRequestTypes.OOSM && eventTargetChecked) {
      updatedRequestType = TaskRequestTypes.OOSM;
    }

    if (requestType === TaskRequestTypes.REPEAT) {
      updatedRequestType = TaskRequestTypes.REPEAT;
    }
    // if the taskType state is already set to the active request type changed value (other then oosm toggle), do nothing
    if (
      taskRequestType === requestType &&
      taskRequestType !== TaskRequestTypes.OOSM
    ) {
      return;
    }

    changeTaskRequestType(updatedRequestType);
  }

  /**
   * handleSubmit
   * @description
   */
  function handleSubmit (e, fields, collectionTypesEnabled) {
    const taskFields = {
      ...fields,
      id: {
        value: NEW_TASK
      },
      geometryCoordinates: {
        value: [mapPosition.lng, mapPosition.lat]
      },
      geometryType: {
        value: 'Point'
      }
    };

    // This current assumes that we're only able to use one bounding box when
    // creating an area of interest on the search map

    if (mapGeoJson) {
      taskFields.geometryCoordinates.value =
        mapGeoJson.features[0].geometry.coordinates;
      taskFields.geometryType.value = mapGeoJson.features[0].geometry.type;
    }
    const task = mapCreateTaskFieldsToTask(taskFields, collectionTypesEnabled);

    if (!isEmptyObject(newEntry)) {
      dispatch(updateTask(task));
    } else {
      dispatch(storeNewTask(task));
    }

    updateTaskState(task);

    navigate(reviewTaskPath);
  }

  function toggleIsCanceled () {
    setIsCanceled(true);
    return setIsCanceled;
  }

  /**
   * handleCancel
   * @description handle canceling the task request
   */

  function handleCancel () {
    toggleIsCanceled();
  }

  if (!reqsMet) return null;

  const isPoint = isPointGeometryType({ geoJson: mapGeoJson });
  // Remove auto-fit for points
  const mapFitGeoJson = !isPoint;
  const mapZoom = isPoint ? 13 : 5;

  const handleMapEffect = ({ leafletElement: map } = {}) => {
    mapRef.current = map;
  };

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-tasks page-tasks-create' }}>
        <title>{`${!isRetask ? 'Create New Task' : 'Retask'}`}</title>
      </Helmet>

      <div className="tasks-create">
        <Container className="content tasks-create-form trim-top trim-left trim-right">
          <PageHeader
            title={`${!isRetask ? 'Create New Task' : 'Retask'}`}
            icon={!isRetask ? <MdPlaylistAdd className="icon-md" /> : <MdRepeat className="icon-sm" />}
            className="page-header flat-bottom pad-left-onehalf pad-bottom-one"
          />

          {isPoint && !isRetask && (
            <NavBar
              primary={[
                {
                  label:
                    TaskRequestTypes.properties[TaskRequestTypes.STANDARD]
                      .label,
                  to: '#singletask',
                  icon: TaskRequestTypes.properties[TaskRequestTypes.STANDARD]
                    .label,
                  onClick: () =>
                    handleTaskRequestTypeChange(TaskRequestTypes.STANDARD),
                  value: TaskRequestTypes.STANDARD,
                  className: `standard-task-${taskRequestType}`
                },
                {
                  label:
                    TaskRequestTypes.properties[TaskRequestTypes.REPEAT].label,
                  to: '#repeattask',
                  icon: TaskRequestTypes.properties[TaskRequestTypes.REPEAT]
                    .label,
                  onClick: () =>
                    handleTaskRequestTypeChange(TaskRequestTypes.REPEAT),
                  value: TaskRequestTypes.REPEAT,
                  className: `repeat-task-${taskRequestType}`
                }
              ]}
              secondary={[]}
            />
          )}

          {icebreakerApiEnabled && !isRepeatTask && !isRetask && (
            <FormRow className="outer-form-row">
              <InputButton
                id="enableOOSM"
                label={TaskRequestTypes.properties[TaskRequestTypes.OOSM].label}
                type="checkbox"
                isChecked={isOosmTask}
                onChange={(e) =>
                  handleTaskRequestTypeChange(TaskRequestTypes.OOSM, e)
                }
              />
            </FormRow>
          )}

          {isRetask && (
            <FormRow className="outer-form-row">
              <div className="tasks-retask-info">
                <Badge
                  label={TaskRequestTypes.properties[taskRequestType]
                    .label}
                  type={TaskRequestTypes.properties[taskRequestType]
                    .label === 'Single Task' ? 'default' : 'info'}
                />
                (<WonderLink className="data-table-details-link" to={fromArchive ? prevPath : taskDetailsPath}>{origTaskingId}</WonderLink>)
              </div>
            </FormRow>
          )}

          <FormCreateTask
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            geoJson={mapGeoJson}
          />
        </Container>

        <Container className="tasks-create-preview">
          {mapPosition && (
            <>
              <MapPreview
                zoom={mapZoom}
                center={mapPosition}
                geoJson={mapGeoJson}
                fitGeoJson={mapFitGeoJson}
                useMapEffect={handleMapEffect}
                displayAccessRequests={true}
              >
                <AccessRequests
                  {...accessRequestsState}
                  onAccessRequestsRefresh={handleOnAccessRequestRefresh}
                  shouldRefreshAccessTimes={shouldRefreshAccessTimes}
                  key={taskType}
                  refreshCounter={refreshCounter}
                />
              </MapPreview>
            </>
          )}
        </Container>
      </div>
    </Layout>
  );
};

TasksCreatePage.propTypes = {
  location: PropTypes.object
};

export default TasksCreatePage;
