import React from 'react';
import { Helmet } from 'react-helmet';
import { FaUser } from 'react-icons/fa';
import {
  DataTable,
  TableSearchInput,
  TableSearchFilters,
  TableActions
} from 'fogg/ui';
import { useTableData } from 'fogg/hooks';

import { useOrg, useOrgFetchUsers, useUser } from 'hooks';
import { sortByKey } from 'lib/util';
import { fillEmptyUsers } from 'lib/data';
import { routePathByName } from 'lib/routes';
import { usersToTableData } from 'commonLib/src/lib/users';

import {
  NavigatorLayout,
  NavigatorSidebar,
  NavigatorBody,
  Breadcrumbs,
  PageHeader,
  Container
} from 'commonLib';

const DEFAULT_COLUMNS = [
  {
    Header: 'Last Name',
    columnId: 'lastName',
    widthRatio: 2
  },
  {
    Header: 'First Name',
    columnId: 'firstName',
    widthRatio: 2
  },
  {
    Header: 'Email',
    columnId: 'email',
    widthRatio: 2
  },
  {
    Header: 'Organization',
    columnId: 'organization',
    widthRatio: 2,
    align: 'center'
  },
  {
    columnId: 'role',
    includeColumn: false
  },
  {
    columnId: 'status',
    includeColumn: false
  },
  {
    columnId: 'actions',
    Header: false,
    align: 'right',
    type: 'action',
    canSort: false,
    canFilter: false,
    widthRatio: 1
  }
];

const FILTER_MENU_OPTIONS = [
  {
    columnId: 'role',
    type: 'checkbox',
    Header: 'Role'
  },
  {
    columnId: 'status',
    type: 'radio',
    Header: 'Status'
  }
];

const OrganizationUsersPage = () => {
  let orgId, isLoadingUser;

  const { user = {} } = useUser();
  const { users } = useOrg();

  const sortedUsers = sortByKey(users, 'familyName');
  let activeUsers = sortedUsers;

  const { organization = {} } = user;
  const { id: organizationId } = organization;

  if (organizationId) {
    orgId = organizationId;
  } else {
    isLoadingUser = true;
  }

  const { isLoading: isLoadingOrgUsers } = useOrgFetchUsers({
    orgId
  });

  const isLoading = isLoadingUser || isLoadingOrgUsers;

  if (isLoading) {
    activeUsers = fillEmptyUsers();
  }

  const usersData = usersToTableData(activeUsers, {
    editPathName: 'organizationUsersDetails'
  });

  const { columns, data, sort, filter, clearFilters, filters } = useTableData({
    columns: DEFAULT_COLUMNS,
    data: usersData
  });

  function handleOnSort (cell) {
    sort(cell);
  }

  function handleOnFilter (filterData, cell) {
    filter(filterData, cell);
  }

  const searchFilters =
    filters.find(({ filterType } = {}) => filterType === 'search') || {};
  const { filterValue } = searchFilters;

  /**
   * handleOnSearchChange
   */

  function handleOnSearchChange ({ currentTarget = {} } = {}) {
    const { value } = currentTarget;

    const filterData = {
      value
    };

    handleOnFilter(filterData);
  }

  /**
   * handleOnFiltersChange
   */

  function handleOnFiltersChange ({ columnId, selectedOptions } = {}) {
    const checkedOptions = selectedOptions.filter(
      ({ isChecked } = {}) => !!isChecked
    );
    const values = checkedOptions.map(({ value } = {}) => value);

    const filterData = {
      value: values
    };

    const cell = {
      filterType: 'checklist',
      columnId
    };

    handleOnFilter(filterData, cell);
  }

  return (
    <NavigatorLayout className="layout-navigator fixed-footer">
      <Helmet bodyAttributes={{ class: 'page-admin page-admin-users' }}>
        <title>Organization Users</title>
      </Helmet>

      <Container type="full">
        <NavigatorSidebar>
          <Breadcrumbs
            items={[
              {
                label: 'Organization',
                to: routePathByName('organization')
              }
            ]}
          />

          <PageHeader title="Users" icon={<FaUser className="icon-fa" />} />

          <TableSearchInput
            value={filterValue}
            onChange={handleOnSearchChange}
            onClear={clearFilters}
          />

          <TableSearchFilters
            isLoading={isLoading}
            options={FILTER_MENU_OPTIONS}
            defaultTableData={usersData}
            onChange={handleOnFiltersChange}
          />
        </NavigatorSidebar>

        <NavigatorBody>
          <TableActions
            actions={[
              {
                to: routePathByName('organizationUsersCreate'),
                label: 'Create a New User',
                buttonType: ['text', 'icon-before'],
                icon: 'FaPlusCircle'
              }
            ]}
          />

          <div className="data-table-wrapper with-side-layout">
            <DataTable
              label="Users"
              onSort={handleOnSort}
              columns={columns}
              data={data}
              isLoading={isLoading}
            />
          </div>
        </NavigatorBody>
      </Container>
    </NavigatorLayout>
  );
};

export default OrganizationUsersPage;
