import { useMappedState } from 'redux-react-hook';
import Task from 'models/task';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';

export default function useOrgTasks ({
  includeSpawned = true,
  includeContractDetails = false,
  contractRouteName = null
} = {}) {
  const mapState = ({ org, user }) => ({ org, user });

  const {
    org: {
      tasks = [],
      users = [],
      currentPage = 1,
      totalPages = 1,
      limit = DEFAULT_PAGING_LIMIT,
      columns = []
    },
    user: {
      user: { organization: { contracts = [] } = {} }
    }
  } = useMappedState(mapState);

  // Only return valid tasks with a tasking request ID
  let filteredTasks = tasks.filter(
    (task) => task?.properties?.taskingrequestId
  );

  // Filter out tasks spawned from a repeat request if desired
  if (!includeSpawned) {
    filteredTasks = filteredTasks.filter(
      (task) => !task?.properties?.repeatrequestId
    );
  }

  // map in contract details if they are included and enabled
  if (includeContractDetails) {
    filteredTasks = Task.mapContractsToTasks(filteredTasks, contracts);
  }

  return {
    includeContractDetails,
    tasks: Task.mapUsersToTasks(filteredTasks, users),
    currentPage,
    totalPages,
    columns,
    limit
  };
}
