import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormRow, FormInput, InputButton } from 'fogg/ui';

import { getAvailableCountries } from 'lib/fields';

import { FormReadOnlyField, S3BucketConfigTable } from 'commonLib';
import ReactTooltip from 'react-tooltip';

const countries = getAvailableCountries();

const SELECT_SINGLE_VALUE_TYPES = ['country'];

const FormOrganizationEditOrganization = ({
  organization = {},
  disabled = false,
  onSubmit
}) => {
  const { name, address = {}, s3PushEnabled, s3BucketConfigs } = organization;
  const { id: addressId, street, city, state, postalCode, country } = address;
  const [useS3, setUseS3] = useState(s3PushEnabled);

  function handleSubmit (e, fields = {}) {
    // Normalize array based select fields to their selected value
    SELECT_SINGLE_VALUE_TYPES.forEach((type) => {
      const value = fields[type] && fields[type].value;
      if (Array.isArray(value) && value.length > 0) {
        fields[type].value = value[0];
      }
    });

    if (useS3) {
      fields.s3PushEnabled = { value: true, isValid: true };
    } else {
      fields.s3PushEnabled = { value: false, isValid: true };
    }

    if (typeof onSubmit === 'function') {
      onSubmit(e, fields);
    }
  }

  function handleS3Checkbox ({ currentTarget = {} }) {
    const { checked } = currentTarget;
    setUseS3(checked);
  }

  return (
    <Form className="form-admin-edit-organization" rules={{}} onSubmit={handleSubmit}>
      <FormRow>
        <p className="caption">All fields are required.</p>
      </FormRow>

      <FormInput
        id="addressId"
        type="hidden"
        required={true}
        defaultValue={addressId}
        disabled={disabled}
      />

      <FormRow>
        <FormReadOnlyField label="Organization Name" value={name} />
      </FormRow>

      <FormRow>
        <FormInput
          id="country"
          label="Country"
          type="select"
          options={countries}
          defaultValue={country}
          required={true}
          disabled={disabled}
          autoComplete="organization country"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="street"
          label="Street Address"
          defaultValue={street}
          required={true}
          disabled={disabled}
          autoComplete="organization street-address"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="city"
          label="City"
          defaultValue={city}
          required={true}
          disabled={disabled}
          autoComplete="organization address-level2"
        />
        <FormInput
          id="state"
          label="State / Province"
          defaultValue={state}
          required={true}
          disabled={disabled}
          autoComplete="organization address-level1"
        />
        <FormInput
          id="postalCode"
          label="Postal Code"
          defaultValue={postalCode}
          required={true}
          disabled={disabled}
          autoComplete="organization postal-code"
        />
      </FormRow>

      <ReactTooltip
        id="pushToS3Tip"
        place="top"
        effect="solid"
        type="dark"
        multiline={true}
        className="customTip"
      >
        <div>
          Enable this feature and enter AWS details to enable the Capella
          platform to deliver imagery directly to your S3 bucket.
        </div>
      </ReactTooltip>

      <FormRow>
        <>
          <InputButton
            id="pushToS3"
            label="Push to Amazon S3"
            type="checkbox"
            isChecked={useS3}
            disabled={disabled}
            onChange={handleS3Checkbox}
            className="pushToS3Wrapper"
          />
          <span className="general-tool-tip" data-tip data-for="pushToS3Tip">
            ?
          </span>
        </>
      </FormRow>
      {useS3 && (
        <S3BucketConfigTable
          s3BucketConfigs={s3BucketConfigs}
          orgId={organization.id}
        />
      )}

      <FormRow className="form-row-actions">
        <Button full={true} disabled={disabled}>
          Save Changes
        </Button>
      </FormRow>
    </Form>
  );
};

FormOrganizationEditOrganization.propTypes = {
  organization: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool
};

export default FormOrganizationEditOrganization;
