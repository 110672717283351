import { getByKey } from 'lib/util';

/**
 * updateSingleEntityByKey
 * @description Used to find an object by key in an array and update it's data
 */

export function updateSingleEntityByKey ({
  key = 'id',
  entity = {},
  previousEntities = []
}) {
  const errorBase = `Failed to update entity with key ${key}`;

  if (!Array.isArray(previousEntities)) {
    throw new Error(`${errorBase}: Invalid previous entities`);
  }

  if (typeof key === 'undefined') {
    throw new Error(`${errorBase}: Invalid key`);
  }

  const entityKey = getByKey(entity, key);

  if (typeof entityKey === 'undefined') {
    throw new Error(`${errorBase}: Entity does not contain key ${key}`);
  }

  const index = previousEntities.findIndex(
    (prevEntity) => getByKey(prevEntity, key) === entityKey
  );

  if (index >= 0) {
    previousEntities[index] = {
      ...previousEntities[index],
      ...entity
    };
  } else {
    previousEntities.push(entity);
  }

  return [...previousEntities];
}
