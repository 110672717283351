import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FaFileAlt } from 'react-icons/fa';

import {
  Breadcrumbs,
  Container,
  NavigatorBody,
  NavigatorLayout,
  PageHeader
} from 'commonLib';
import ContractsItemList from 'commonLib/src/components/ContractsItemList';
import { routePathByName } from '../lib/routes';
import { ROUTE_ORG_CONTRACT_DETAILS, ROUTE_ORG } from '../data/route-names';
import { USER_ROLE } from '../data/roles';

const organizationPath = routePathByName(ROUTE_ORG);

const DEFAULT_BREADCRUMBS = [
  {
    label: 'Organization',
    to: organizationPath
  }
];

const TemplateContracts = ({
  accessType = USER_ROLE,
  breadcrumbs = DEFAULT_BREADCRUMBS,
  contracts,
  detailsRouteName = ROUTE_ORG_CONTRACT_DETAILS,
  state = {}
}) => {
  const { isLoading = false } = state;

  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;

  return (
    <NavigatorLayout access={accessType} className="layout-navigator full">
      <Helmet bodyAttributes={{ class: 'template-contracts' }}>
        <title>Contracts</title>
      </Helmet>
      <Container type="full">
        <NavigatorBody className="layout-navigator-body">
          <div className="data-table-page-header">
            <div>
              {hasBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}

              <PageHeader
                title="Contracts"
                icon={<FaFileAlt className="icon-fa" />}
              />
            </div>
          </div>

          <ContractsItemList
            contracts={contracts}
            isLoading={isLoading}
            detailsRouteName={detailsRouteName}
          />
        </NavigatorBody>
      </Container>
    </NavigatorLayout>
  );
};

TemplateContracts.propTypes = {
  accessType: PropTypes.string,
  breadcrumbs: PropTypes.array,
  contracts: PropTypes.arrayOf(PropTypes.object).isRequired,
  detailsRouteName: PropTypes.string,
  state: PropTypes.object
};

export default TemplateContracts;
