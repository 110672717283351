import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';

// Is there a better name for this?

const NavigatorLayout = ({
  className = 'layout-navigator',
  children,
  ...props }) => {
  return (
    <Layout className={className} {...props}>
      {children}
    </Layout>
  );
};

NavigatorLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default NavigatorLayout;
