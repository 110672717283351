import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useSubResellerTask, useSubReseller } from 'hooks';

import { logError } from 'lib/logger';
import { errorMessageByCode } from 'lib/errors';
import {
  triggerNotice,
  fetchSubResellerTasksCollectsById
} from 'state/actions';

import {
  TASK_STATUS_ACCEPTED,
  TASK_STATUS_ACTIVE,
  TASK_STATUS_COMPLETE
} from 'data/tasks';

const VALID_TASK_STATUSES = [
  TASK_STATUS_ACCEPTED,
  TASK_STATUS_ACTIVE,
  TASK_STATUS_COMPLETE
];

export default function useSubResellerFetchTaskCollects ({ taskId } = {}) {
  const dispatch = useDispatch();
  const { actions } = useSubReseller();
  const task = useSubResellerTask(taskId);

  const shouldFetchCollects = VALID_TASK_STATUSES.includes(task.status);

  /**
   * fetchUsers
   */

  function fetchCollects (id) {
    dispatch(fetchSubResellerTasksCollectsById(id)).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchCollects = useCallback(() => {
    fetchCollects(taskId);
  }, [taskId]); // eslint-disable-line

  useEffect(() => {
    if (!shouldFetchCollects) return;
    memoizedFetchCollects();
  }, [memoizedFetchCollects, shouldFetchCollects]);

  return {
    fetchSubResellerTasksCollectsById: memoizedFetchCollects,
    ...actions.fetchSubResellerTasksCollectsById
  };
}
