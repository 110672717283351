import React from 'react';
import { Helmet } from 'react-helmet';
import { routePathByName } from 'lib/routes';
import { orgmanRouteLinks, mapToLinkListItem } from 'commonLib';
import * as routeNames from 'commonLib/src/data/route-names';
import { IconByName } from 'commonLib/components';
import Layout from 'components/Layout';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import LinkBlockList from 'components/LinkBlockList';

const OrganizationIndexPage = () => {
  const links = orgmanRouteLinks().map((link) =>
    mapToLinkListItem(
      link,
      (routeName) => routePathByName(routeNames[routeName]),
      IconByName
    )
  );

  return (
    <Layout>
      <Helmet
        bodyAttributes={{ class: 'page-organization page-organization-index' }}
      >
        <title>Organization</title>
      </Helmet>
      <Container className="content">
        <PageHeader title="Organization" icon={<IconByName name="FaUsers" />} />
        <LinkBlockList links={links} />
      </Container>
    </Layout>
  );
};

export default OrganizationIndexPage;
