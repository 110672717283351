import React from 'react';
import PropTypes from 'prop-types';
import { FaCheck, FaExclamationTriangle } from 'react-icons/fa';

/**
 * List of values used in AOI Upload Modal to display valid/invalid upload
 * @param {array} list array of objects to display in list format
 * @param {object} [properties={}] - additional optional properties to display beneath list
 */
const StatusList = ({ list, properties }) => {
  return (
    <div className="status-list-container">
      {list && list.length && (
        <ul className="status-list" data-testid="status-list-items">
          {list.map(({ label, value, valid, details }, index) => {
            return (
              <li key={index} className="status-list-item">
                <div className="status-list-icon">
                  {valid ? (
                    <span className="list-icon text-color-green">
                      <FaCheck />
                    </span>
                  ) : (
                    <span className="list-icon text-color-red">
                      <FaExclamationTriangle />
                    </span>
                  )}
                </div>
                <div className="status-list-text">
                  <p>
                    {label && <span>{label}: </span>}
                    <strong>{value}</strong>
                  </p>
                  <div
                    className={`status-list-details ${valid ? '' : 'invalid'}`}
                  >
                    {details}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {properties && (
        <ul className="status-list" data-testid="status-list-properties">
          {properties.length > 0 && (
            <li className="status-list-item status-list-item-properties" data-testid="status-list-properties">
              <div className="properties-list">
                <h4>Properties: </h4>
                {properties.map(({ label, value }, i) => {
                  return (
                    <div className="properties-list-item" key={i}>
                      <div className="list-item-label">{label}:</div>
                      <div className="list-item-value">
                        <strong>{value}</strong>
                      </div>
                    </div>
                  );
                })}
              </div>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

StatusList.propTypes = {
  list: PropTypes.array,
  properties: PropTypes.array
};

export default StatusList;
