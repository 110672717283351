import { useMappedState } from 'redux-react-hook';

export default function useOrg () {
  const mapState = ({ org }) => org;

  const org = useMappedState(mapState);

  return {
    ...org
  };
}
