import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormRow, FormInput } from 'fogg/ui';
import {
  useUser,
  useResellerFetchOrganization,
  useAdminOrganizations,
  useAdminFetchOrganizations
} from '../hooks';
import { getAvailableCountries } from '../lib/fields';
import { ADMIN_ROLE, RESELLER_ROLE, SUB_RESELLER_ROLE } from '../data/roles';

const countries = getAvailableCountries();

const SELECT_SINGLE_VALUE_TYPES = ['country', 'type', 'subResellerId'];

const FormCreateOrganization = ({ disabled = false, onSubmit, formAccess }) => {
  const [selectedOrgType, setSelectedOrgType] = useState('standard');
  const [selectedReseller, setSelectedReseller] = useState();

  const { isLoading: isLoadingOrganizations } = useAdminFetchOrganizations();
  const organizations = useAdminOrganizations();

  // Get all reseller orgs to populate the associated reseller org input
  const resellerOrgs = organizations.filter((org) => {
    return org.type === 'reseller';
  });

  const resellerOrgList = [];
  if (resellerOrgs.length) {
    resellerOrgs.map((org) => {
      resellerOrgList.push({ value: org.id, label: org.name, type: org.type });
    });
  }

  let subresellerOrgs;
  const subresellerOrgList = [];

  // Whenever the selected reseller org changes, get the appropriate subResellers
  useEffect(() => {
    if (!selectedReseller) return;
    // Get all reseller orgs to populate the associated reseller org input
    subresellerOrgs = organizations.filter((org) => {
      return (
        org.type === 'sub-reseller' && org.resellerId === selectedReseller.value
      );
    });

    if (subresellerOrgs.length) {
      subresellerOrgs.map((org) => {
        subresellerOrgList.push({
          value: org.id,
          label: org.name,
          type: org.type
        });
      });
    }
  }, [selectedReseller]);

  // Need to get user type to see if reseller or admin, etc.
  let isLoadingUser;
  let accessType = 'admin';

  const { user = {} } = useUser();

  if (!user.organizationId) {
    isLoadingUser = true;
  }

  const { isLoading: isLoadingOrganization, organization = {} } =
    useResellerFetchOrganization({
      id: user.organizationId
    });

  const isLoading =
    isLoadingUser || isLoadingOrganization || isLoadingOrganizations;

  const { childOrganizations = [] } = organization;

  if (user && user.roles.length) {
    if (user.roles.includes(ADMIN_ROLE)) {
      accessType = 'admin';
    }
    if (
      user.roles.includes(RESELLER_ROLE) &&
      !user.roles.includes(ADMIN_ROLE)
    ) {
      accessType = 'reseller';
    }
    if (
      user.roles.includes(SUB_RESELLER_ROLE) &&
      !user.roles.includes(RESELLER_ROLE) &&
      !user.roles.includes(ADMIN_ROLE)
    ) {
      accessType = 'sub-reseller';
    }
  }

  // Create array of child sub-reseller orgs to populate dropdown
  const childSubresellerOrgList = [];
  if (childOrganizations.length) {
    childOrganizations.map((org) => {
      if (org.type === 'sub-reseller') {
        childSubresellerOrgList.push({
          value: org.id,
          label: org.name,
          type: org.type
        });
      }
    });
  }

  function handleSubmit (e, fields = {}) {
    // Normalize array based select fields to their selected value
    SELECT_SINGLE_VALUE_TYPES.forEach((type) => {
      const value = fields[type] && fields[type].value;
      if (Array.isArray(value) && value.length > 0) {
        fields[type].value = value[0];
      }
    });

    // Clear out resellerid if type is reseller
    if (selectedOrgType === 'reseller') {
      fields.resellerId.value = undefined;
    }

    // Clear subResellerId if type is Org Type is 'sub-reseller'
    if (selectedOrgType === 'sub-reseller') {
      if (typeof fields.subResellerId !== 'undefined') {
        fields.subResellerId.value = undefined;
      }
    }
    if (typeof onSubmit === 'function') {
      onSubmit(e, fields);
    }
  }

  function onChangeOrgType (target) {
    if (target) {
      const { value } = target;
      if (value === 'reseller') {
        setSelectedReseller(null);
      }
      setSelectedOrgType(value);
    }
  }

  function onChangeAssociatedOrg (target) {
    if (target) {
      setSelectedReseller(target);
    }
  }

  // Handle Organization Type dropdown default value & state, only for reseller users
  const orgTypes = [
    { value: 'standard', label: 'Standard' },
    { value: 'sub-reseller', label: 'Sub-Reseller' }
  ];

  // If user is an admin they can also select Reseller
  if (accessType === 'admin') {
    orgTypes.push({ value: 'reseller', label: 'Reseller' });
  }

  // For resellers creating org for sub-reseller, need to select which
  return (
    <Form className={`form-${accessType}-create-user`} onSubmit={handleSubmit}>
      {accessType === 'sub-reseller' && (
        <FormRow>
          <p className="caption">All fields are required.</p>
        </FormRow>
      )}

      <FormRow>
        <FormInput
          id="organizationName"
          label="Organization Name *"
          required={true}
          disabled={disabled}
          autoComplete="organization org"
        />
      </FormRow>

      {formAccess !== SUB_RESELLER_ROLE && (
        <>
          {(accessType === RESELLER_ROLE || accessType === ADMIN_ROLE) && (
            <FormRow>
              <FormInput
                id="type"
                label="Organization Type *"
                type="select"
                options={orgTypes}
                defaultValue={orgTypes[0].value}
                required={true}
                disabled={disabled}
                autoComplete="Organization Type"
                onChange={onChangeOrgType}
              />
            </FormRow>
          )}

          {accessType === ADMIN_ROLE && (
            <>
              <FormRow>
                <FormInput
                  id="resellerId"
                  label="Associated Reseller Org"
                  type="select"
                  options={resellerOrgList}
                  required={false}
                  disabled={disabled || selectedOrgType === 'reseller'}
                  autoComplete="Associated Sub-Reseller Org"
                  onChange={onChangeAssociatedOrg}
                  value={selectedReseller}
                />
              </FormRow>

              <FormRow>
                <FormInput
                  id="subResellerId"
                  label="Associated Sub-Reseller Org"
                  type="select"
                  options={subresellerOrgList}
                  required={false}
                  disabled={
                    disabled ||
                    selectedOrgType !== 'standard' ||
                    selectedReseller === ''
                  }
                  autoComplete="Associated Sub-Reseller Org"
                />
              </FormRow>
            </>
          )}

          {!!childSubresellerOrgList.length && (
            <FormRow>
              <FormInput
                id="subResellerId"
                label="Sub-Reseller Organization"
                type="select"
                options={childSubresellerOrgList}
                required={false}
                disabled={disabled || selectedOrgType === 'sub-reseller'}
                autoComplete="Sub-Reseller Organization"
              />
            </FormRow>
          )}
        </>
      )}

      <FormRow>
        <FormInput
          id="country"
          label="Country *"
          type="select"
          options={countries}
          required={true}
          disabled={disabled}
          autoComplete="organization country"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="street"
          label="Street Address *"
          required={true}
          disabled={disabled}
          autoComplete="organization street-address"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="city"
          label="City *"
          required={true}
          disabled={disabled}
          autoComplete="organization address-level2"
        />
        <FormInput
          id="state"
          label="State / Province *"
          required={true}
          disabled={disabled}
          autoComplete="organization address-level1"
        />
        <FormInput
          id="postalCode"
          label="Postal Code *"
          required={true}
          disabled={disabled}
          autoComplete="organization postal-code"
        />
      </FormRow>

      <FormRow className="form-row-actions">
        <Button full={true} disabled={isLoading}>
          Add New Organization
        </Button>
      </FormRow>
    </Form>
  );
};

FormCreateOrganization.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  formAccess: PropTypes.string
};

export default FormCreateOrganization;
