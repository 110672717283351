import fields from '../data/fields';
import { CONTRACT_TYPES } from '../data/contracts';
import { countries } from 'countries-list';

/**
 * fieldByName
 * @description Returns the matching source field by name
 */

export function fieldByName (name) {
  return fields.find((field) => field.name === name);
}

/**
 * getAvailableCountries
 * @description Returns an array of country objects
 */

export function getAvailableCountries () {
  const availableCountries = [];

  for (const key in countries) {
    if (!Object.prototype.hasOwnProperty.call(countries, key)) continue;
    availableCountries.push({
      value: key,
      label: countries[key].name
    });
  }

  // Sort countries alphabetically (by full country name, not country code)
  availableCountries.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  return availableCountries;
}

/**
 * getContractTypes
 * @description Returns an array of contract objects
 */

export function getContractTypes () {
  return CONTRACT_TYPES.map(({ name, label }) => {
    return {
      value: name,
      label
    };
  });
}

/**
 * contractLabelByName
 * @description Given the contract name, returns the label from the contract type list
 */

export function contractLabelByName (name) {
  const match =
    CONTRACT_TYPES.find((contractType) => contractType.name === name) || {};
  const { label } = match || {};
  return label;
}

/**
 * countryCodeByName
 * @description Given the full country name, returns the country code from the country list
 */

export function countryCodeByName (name) {
  const countries = getAvailableCountries();
  const match = countries.find((country) => country.name === name) || {};
  const { code } = match;
  return code;
}
