import { device, location } from 'fogg/lib';

import { getRouteState } from 'lib/routes';

const { isDomAvailable } = device;
const { objectToQueryString } = location;

/**
 * clearQuerySearchParams
 * @description Remove all serach related query params from URL
 * @example clearQuerySearchParams({properties: 'hey'}, ['properties', 'q'])
 */

export function clearQuerySearchParams (activeParams, paramsToClear) {
  if (!isDomAvailable()) return;

  const urlParams = { ...activeParams };

  paramsToClear.forEach((param) => {
    if (urlParams[param]) {
      delete urlParams[param];
    }
  });

  updateQuerySearchParams(urlParams);
}

export function updateQuerySearchParams (params = {}) {
  if (!isDomAvailable()) return;

  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'undefined') {
      params[key] = '';
    }
  });

  if (window.history) {
    const state = {
      ...getRouteState()
    };
    window.history.pushState(state, '', `?${objectToQueryString(params)}`);
  }
}
