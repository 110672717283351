import { useMappedState } from 'redux-react-hook';

import RepeatRequest from 'models/repeatRequest';

export default function useSubResellerRepeatRequests ({
  includeContractDetails = false
}) {
  const mapState = ({ subreseller, user }) => ({ subreseller, user });

  const {
    subreseller: { repeatRequests = [], totalPages, repeatColumns },
    user: {
      user: { organization: { contracts = [] } = {} }
    }
  } = useMappedState(mapState);

  let filteredRepeatRequests = repeatRequests;

  if (includeContractDetails) {
    filteredRepeatRequests = RepeatRequest.mapContractsToTasks(
      filteredRepeatRequests,
      contracts
    );
  }

  return {
    repeatRequests: filteredRepeatRequests.map((repeatRequest) => {
      const instance = new RepeatRequest();
      instance.ingestTaskBody(repeatRequest);
      return instance;
    }),
    totalPages,
    repeatColumns
  };
}
