import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import ErrorLayout from 'components/ErrorLayout';

const NotFoundPage = ({ caughtError }) => {
  return (
    <ErrorLayout caughtError={caughtError}>
      <Helmet bodyAttributes={{ class: 'page-error page-error-404' }}>
        <title>404: Not Found</title>
      </Helmet>
      <h1>Oops, this page doesn&apos;t exist!</h1>
      <p className="text-center">
        This doesn&apos;t seem to be the right page. If you think this is in
        error, please contact the Capella team!
      </p>
    </ErrorLayout>
  );
};

NotFoundPage.propTypes = {
  caughtError: PropTypes.object
};

export default NotFoundPage;
