import React, { useRef, useState, useEffect } from 'react';
import { geoJson as LeafletGeoJson } from 'leaflet';
import { Helmet } from 'react-helmet';
import { BiMapPin } from 'react-icons/bi';
import { useDispatch } from 'redux-react-hook';
import {
  NavigatorLayout,
  NavigatorBody,
  PageHeader,
  Breadcrumbs
} from 'commonLib';

import { useEditableHandler, useAoi, useAoiMapLayers } from 'hooks';
import { triggerNotice, postAOI, handleAoiDraw } from 'state/actions';
import { routePathByName, redirectTo } from 'lib/routes';
import { ROUTE_AOIS } from 'data/route-names';
import { logError } from 'lib/logger';
import { isLayerGreaterThanMaxSize } from 'lib/aoi';
import FormCreateEditAoi from 'components/FormCreateEditAoi';
import MapPreview from 'components/MapPreview';
import ModalAOIUpload from 'components/ModalAOIUpload';
import MapPreviewDrawPanel from 'components/MapPreviewDrawPanel';

import { triggerLeafletDraw } from 'commonLib/src/lib/map';
import { DEFAULT_MAP_CENTER, DEFAULT_AOI_VALUES } from '../../data/aois';

export default function CreateAOIPage () {
  const dispatch = useDispatch();
  const mapRef = useRef();
  const featureRef = useRef();

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [isLargeAOISize, setIsLargeAOISize] = useState(false);
  const [geoJsonUpload, setGeoJsonUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [aoiFormValues, setAoiFormValues] = useState(DEFAULT_AOI_VALUES);
  const { aoiFeatureState, updateAoiFeatureState } = useAoi();
  const { enableEditForLayer } = useAoiMapLayers();

  const breadcrumbs = [
    {
      label: 'AOIs',
      to: routePathByName('aois')
    }
  ];

  function updateAoiFormValuesProperties (newProperties) {
    setAoiFormValues((prevValues) => ({
      ...prevValues,
      properties: {
        ...prevValues.properties,
        ...newProperties
      }
    }));
  }

  async function handleSubmit (payload) {
    const data = {
      ...payload,
      geometry: aoiFeatureState.geometry
    };

    // handle injecting radius property into the payload
    if (aoiFeatureState.properties.radius) {
      data.properties.radius = aoiFeatureState.properties.radius;
    }
    // handle injecting subType property into the payload
    if (aoiFeatureState.properties.subType) {
      data.properties.subType = aoiFeatureState.properties.subType;
    }

    try {
      setIsLoading(true);
      await dispatch(postAOI(data));
      dispatch(
        triggerNotice({
          type: 'success',
          weight: 'bold',
          align: 'center',
          text: 'New AOI Successfully Created!'
        })
      );
      setIsLoading(false);
      redirectTo(routePathByName(ROUTE_AOIS));
    } catch (e) {
      setIsLoading(false);
      logError(e.message, data);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'We had a problem creating your AOI! Please try again'
        })
      );
    }
  }

  // Clears any previously uploaded layers after drawing new shape
  // NOTE: leafletElement.clearLayers() prevents drawing new shape for some reason
  function clearMapLayers () {
    if (!featureRef.current) return;

    const { leafletElement } = featureRef.current;
    const drawnItems = leafletElement._layers;

    if (Object.keys(drawnItems).length > 1) {
      Object.keys(drawnItems).forEach((layerid, index) => {
        if (index > 0) return;
        const layer = drawnItems[layerid];
        featureRef.current.leafletElement.removeLayer(layer);
      });
    }
  }

  /**
   * Draw (or Edit Draw) event handler, after validating shape area
   * the leafletElement is converted to GeoJSON
   *
   * @param {L.Polyline} layer
   * @param {L.LayerGroup} leafletElement
   * @returns {Promise<void>}
   */
  async function drawnOrEditedHandler (layer, leafletElement) {
    if (!leafletElement) return;
    clearMapLayers();
    const feature = await dispatch(
      handleAoiDraw(leafletElement, layer)
    );
    updateAoiFeatureState(feature);
    setIsLargeAOISize(isLayerGreaterThanMaxSize(layer));
    const center = leafletElement.getBounds()?.getCenter();
    if (center) {
      mapRef.current.panTo(center);
    }
  }

  // updateAoiFeatureState after layer edit
  const { handleEditing } = useEditableHandler({
    editableHandler: drawnOrEditedHandler
  });

  /**
   * leaflet draw created event handler, with editing enabled
   *
   * @param {L.Polyline} layer
   * @param {L.LayerGroup} leafletElement
   */
  function handleOnDraw (layer, leafletElement) {
    drawnOrEditedHandler(layer, leafletElement);

    // enable edit mode
    enableEditForLayer(layer, handleEditing);
  }

  // Watch for file uploads, add to map
  useEffect(() => {
    if (!featureRef.current || !geoJsonUpload) return;
    if (geoJsonUpload) {
      const { leafletElement } = featureRef.current;
      leafletElement.clearLayers();

      const feature = LeafletGeoJson(geoJsonUpload);
      updateAoiFeatureState(geoJsonUpload);

      // if the uploaded geoJson has a name or description, add it to the form fields' state
      if (geoJsonUpload.properties) {
        const { name: defaultName, description: defaultDescription } =
          DEFAULT_AOI_VALUES.properties;
        const {
          name: uploadName = defaultName,
          description: uploadDescription = defaultDescription
        } = geoJsonUpload.properties;
        updateAoiFormValuesProperties({
          name: uploadName,
          description: uploadDescription
        });
      }

      leafletElement.addLayer(feature);
      mapRef.current.fitBounds(feature.getBounds().pad(1.0));
      setIsLargeAOISize(isLayerGreaterThanMaxSize(feature.getLayers()[0]));

      // enable edit mode

      const [addedLayer] = feature.getLayers();
      enableEditForLayer(addedLayer, handleEditing);
    }
  }, [geoJsonUpload]);

  const handleMapEffect = ({ leafletElement: map } = {}) => {
    mapRef.current = map;
  };

  /**
   * Handler for the custom MapPreview draw buttons, also resets any previously uploaded GeoJSON
   * We currently disable draw if user uploads a GeoJSON bc Edit doesn't play nice
   * @param {string} shape - Leaflet shape types e.g. 'rectangle', 'polygon', 'circle', 'marker'
   * @param {object} e - click event object
   */
  async function handleDrawClick (shape, e) {
    await setGeoJsonUpload(null);
    triggerLeafletDraw(shape, e);
  }

  function handleUpload (geoJson) {
    setGeoJsonUpload(geoJson);
  }
  return (
    <NavigatorLayout access="user" className="layout-navigator split-map-view">
      <Helmet bodyAttributes={{ class: 'create-aoi' }}>
        <title>Create New AOI</title>
      </Helmet>
      <ModalAOIUpload
        isOpen={openUploadModal}
        handleClosed={() => setOpenUploadModal(!openUploadModal)}
        onUploadComplete={handleUpload}
      />
      <NavigatorBody className="create-aoi-body layout-navigator-body">
        <section className="create-aoi-container">
          <Breadcrumbs items={breadcrumbs} />

          <PageHeader
            title="Create New AOI"
            icon={<BiMapPin className="icon-bi" />}
          />
          <FormCreateEditAoi
            onSubmit={handleSubmit}
            aoi={{
              ...aoiFormValues,
              geometry: aoiFeatureState?.geometry,
              isLargeAOISize
            }}
            isEdit={false}
            isLoading={isLoading}
          />
        </section>
      </NavigatorBody>
      <section className="aois-map">
        <MapPreview
          center={DEFAULT_MAP_CENTER}
          emptyMap={true}
          useMapEffect={handleMapEffect}
          fitGeoJson={false}
          displayAccessRequests={false}
          displayAOIDetails={false}
          disableDraw={false}
          disableEdit={true} // Default edit mode is disabled in favor of automatic edit mode
          onDrawCreated={handleOnDraw}
          featureRef={featureRef}
          drawControlOptions={{
            circle: true,
            circlemarker: false,
            marker: false,
            polygon: true,
            polyline: false,
            rectangle: true
          }}
          zoom={3}
        >
          <MapPreviewDrawPanel
            triggerUploadModal={() => setOpenUploadModal(!openUploadModal)}
            handleDraw={handleDrawClick}
          />
        </MapPreview>
      </section>
    </NavigatorLayout>
  );
}
