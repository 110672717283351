import { useReseller, useResellerUsers } from 'hooks';

/**
 * useResellerAllUsers
 * @description Returns all users belonging to a Reseller
 * @note This includes all users from child organizations
 */

export default function useResellerAllUsers () {
  const { users: resellerUsers = [] } = useResellerUsers();

  const { organization = {} } = useReseller();
  const { childOrganizations = [] } = organization;

  const childUsers = childOrganizations.flatMap(({ users }) => users);

  return {
    users: [...resellerUsers, ...childUsers]
  };
}
