import { useLens } from 'fogg/hooks';

import { useLocation } from 'hooks';
import { addParamsToUrl, isEmptyObject } from 'lib/util';
import { routePathByName } from 'lib/routes';

const errorBase = 'Failed to create task link';

export default function useTaskCreate (settings = {}) {
  const params = {};
  let { geoJson, path = 'create' } = settings;
  let addTaskLink;

  const { queryParams: locationQueryParams = {} } = useLocation();
  const { geoJson: locationGeoJson } = locationQueryParams;

  const { geoSearch = {}, map = {} } = useLens();

  const { queryParams: searchQueryParams = {} } = geoSearch;
  const { geoJson: searchGeoJson } = searchQueryParams;

  const { mapConfig = {} } = map;
  const { center } = mapConfig;

  // If we're not preconfiguring a geoJson object as a settings parameter, we want to
  // attempt to use the active search state's value

  if (!geoJson || isEmptyObject(geoJson)) {
    geoJson = searchGeoJson;
  }

  // If we can't generate our geoJson param from an active search, attempt to grab
  // from the URL query params

  if (!geoJson || isEmptyObject(geoJson)) {
    geoJson = locationGeoJson;
  }

  // Once we have our geoJson, if it's a string value, we want to try to make it into
  // an object to normalize how we're passing the data around the app

  if (geoJson && typeof geoJson === 'string') {
    try {
      geoJson = JSON.parse(geoJson);
    } catch (e) {
      throw new Error(`${errorBase}: Could not parse geoJson`);
    }
  }

  // Once we have our known configurations, attempt to add the geoJson and center
  // to our params if they're available

  if (geoJson && !isEmptyObject(geoJson)) {
    params.geoJson = geoJson;
  }

  if (center && !isEmptyObject(center)) {
    params.mapPosition = center;
  }

  // If we dont have any active params, dont add so we can pass back an undefined path
  // to allow us to control behavior when it's not available

  if (params && !isEmptyObject(params)) {
    if (path === 'create') {
      addTaskLink = addParamsToUrl(`${routePathByName('tasksCreate')}`, {
        mapPosition: center,
        geoJson: geoJson && JSON.stringify(geoJson)
      });
    } else {
      addTaskLink = addParamsToUrl(`${routePathByName('tasksReview')}`, {
        mapPosition: center,
        geoJson: geoJson && JSON.stringify(geoJson)
      });
    }
  }

  return {
    path: addTaskLink
  };
}
