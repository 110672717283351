import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAdmin, useUser, useAdminLicenses } from '../hooks';
import { ADMIN_ROLE } from '../data/roles';
import { logError } from '../lib/logger';
import { triggerNotice, fetchLicenses } from '../state/actions';

export default function useAdminFetchLicenses ({ forceNew = false } = {}) {
  const dispatch = useDispatch();
  // Verify user has admin priviledges
  const { user } = useUser();
  let accessType = '';
  if (user && user.roles && user.roles.includes(ADMIN_ROLE)) {
    accessType = ADMIN_ROLE;
  }

  const licenses = useAdminLicenses() || [];
  const { actions = {} } = useAdmin();
  const { fetchLicenses: fetchLicensesAction = {} } = actions;
  const { firstLoad } = fetchLicensesAction;

  useEffect(() => {
    if (firstLoad && !forceNew) return;
    if (accessType === ADMIN_ROLE) {
      dispatch(fetchLicenses()).catch((error) => {
        logError(error.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      });
    }
  }, [dispatch, forceNew, firstLoad, accessType]);

  return {
    ...actions.fetchLicenses,
    licenses
  };
}
