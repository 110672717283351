import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAdmin, useItems } from '../hooks';
import { logError } from '../lib/logger';
import { errorMessageByCode } from '../lib/errors';
import {
  triggerNotice,
  fetchAllCollections,
  fetchPublicCollections
} from '../state/actions';

/**
 * @typedef {import('../models/collection')} Collection
 *
 * @typedef {Object} UseFetchCollectionsActions
 * @property {boolean} isLoading - Indicates whether the fetchAllCollections action is currently in a loading state.
 * @property {boolean} isError - Indicates whether an error has occurred in the fetchAllCollections action.
 * @property {boolean} isRetrying - Indicates whether the fetchAllCollections action is in a retrying state.
 * @property {boolean} firstLoad - Indicates whether it is the first load of the fetchAllCollections action.
 * @property {Collection[]} collections - array of objects in the shape of the Collection model
 * @property {Object} fetchPublicCollectionsActions - Actions specific to the fetchPublicCollectionsActions operation
 * @property {boolean} fetchPublicCollectionsActions.isLoading - Indicates whether the fetchPublicCollectionsActions action is currently in a loading state.
 * @property {boolean} fetchPublicCollectionsActions.isError - Indicates whether an error has occurred in the fetchPublicCollectionsActions action.
 * @property {boolean} fetchPublicCollectionsActions.isRetrying - Indicates whether the fetchPublicCollectionsActions action is in a retrying state.
 * @property {boolean} fetchPublicCollectionsActions.firstLoad - Indicates whether it is the first load of the fetchPublicCollectionsActions action.
 */

/**
 * Retrieves the collections using 2 endpoints to deduce which collections are public or restricted
 *
 * @returns {UseFetchCollectionsActions}
 */
export default function useFetchCollections () {
  const dispatch = useDispatch();

  const { actions = {}, collections } = useItems();
  const { actions: adminActions = {}, publicCollections } = useAdmin();
  const [enhancedCollections, setEnhancedCollections] = useState([]);

  /**
   * fetches all collections from catalog
   */
  function _fetchAllCollections () {
    dispatch(fetchAllCollections()).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  /**
   * fetches public-only collections from a&e
   */
  function _fetchPublicCollections () {
    dispatch(fetchPublicCollections()).catch((e) => {
      logError(`${e.message}`);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchAllCollections = useCallback(() => {
    _fetchAllCollections();
  }, []);

  const memoizedFetchPublicCollections = useCallback(() => {
    _fetchPublicCollections();
  }, []);

  useEffect(() => {
    memoizedFetchAllCollections();
    memoizedFetchPublicCollections();
  }, [memoizedFetchAllCollections, memoizedFetchPublicCollections]);

  // Combines collections with publicCollections so the UI can make informed decisions
  useEffect(() => {
    if (collections.length && publicCollections.length) {
      const extendedCollections = collections.map((collection) => {
        collection.isPublic = !!publicCollections.find(({ id: publicId }) => publicId === collection.id);
        return collection;
      });
      setEnhancedCollections(extendedCollections);
    } else {
      setEnhancedCollections(collections);
    }
  }, [publicCollections]);

  return {
    fetchAllCollections: memoizedFetchAllCollections,
    ...actions.fetchAllCollections,
    collections: enhancedCollections,
    fetchPublicCollectionsActions: adminActions.fetchPublicCollections
  };
}
