import BaseTask from './baseTask';
import { geometryToGeoJson } from 'lib/map';
import TaskCollect from 'models/task-collect';
import TaskTile from 'models/task-tile';

class Task extends BaseTask {
  static mapUsersToTasks (users, tasks) {
    return BaseTask.mapUsersToTasks(users, tasks, Task);
  }

  constructor (data = {}) {
    super(data);
  }

  updateInstanceProperties () {
    super.updateInstanceProperties();
    this.repetitionProperties = this._data.properties.repetitionProperties;
    this.costBreakdown = this._data.properties.costBreakdown;
  }

  geoJson () {
    return this.geometry && geometryToGeoJson(this.geometry);
  }

  get windowDuration () {
    return durationFromWindow(this.windowOpenInt, this.windowCloseInt);
  }

  get collects () {
    const collects = this._data.properties && this._data.properties.collects;
    return collects ? collects.map((collect) => new TaskCollect(collect)) : [];
  }

  get tiles () {
    const tiles = this._data.properties && this._data.properties.tiles;
    return tiles ? tiles.map((collect) => new TaskTile(collect)) : [];
  }

  get collectsWithTiles () {
    const collects = this.collects;
    const tiles = this.tiles;

    return collects.map((collect) => {
      const { tileId: collectTileId } = collect;

      const tile = tiles.find(
        ({ properties }) => properties.tileId === collectTileId
      );

      return {
        collect,
        tile
      };
    });
  }

  get statusAndId () {
    return {
      id: this.id,
      status: this.status
    };
  }

  get conflictingTasks () {
    const { conflictingTasks = [] } = this._data.properties || {};
    return conflictingTasks;
  }
}

export default Task;

/**
 * durationFromWindow
 */

function durationFromWindow (open, close) {
  let windowOpen = open;
  let windowClose = close;

  if (!(windowOpen instanceof Date)) {
    windowOpen = new Date(windowOpen);
  }

  if (!(windowClose instanceof Date)) {
    windowClose = new Date(windowClose);
  }

  // The API wants seconds instead of miliseconds

  return (windowClose - windowOpen) / 1000;
}
