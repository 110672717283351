import React from 'react';
import PropTypes from 'prop-types';
import { device } from 'fogg/lib';

import {
  useTasks,
  useFetchTask,
  useTask,
  useFetchTaskCollects,
  useFetchTaskTiles
} from 'hooks';
import {
  ROUTE_RESELLER,
  ROUTE_RESELLER_TASKS,
  ROUTE_TASKS
} from 'data/route-names';
import { navigateTo, routePathByName } from 'lib/routes';
import { taskIdIsValid } from 'lib/data';

import TemplateTask from 'templates/TemplateTask';

const { isDomAvailable } = device;

const tasksPath = routePathByName(ROUTE_TASKS);

const ResellerTaskDetailsPage = ({ taskId }) => {
  const { actions: taskActions = {} } = useTasks();
  const {
    updateResellerTaskStatusById: updateTaskStatusByIdAction = {},
    fetchTaskByTaskId: fetchTaskActions = {}
  } = taskActions;
  const { isLoading: isUpdateTaskStatusByIdLoading } =
    updateTaskStatusByIdAction;
  const { isLoading: isFetchTaskLoading } = fetchTaskActions;

  const task = useTask(taskId) || {
    id: taskId
  };

  const {
    isLoading,
    fetchConflictingTasksActions: { isLoading: isConflictingTasksLoading = false } = {},
    conflictingTasks = []
  } = useFetchTask(taskId, true);
  const { isLoading: taskCollectsIsLoading } = useFetchTaskCollects({ taskId });
  const { isLoading: taskTilesIsLoading } = useFetchTaskTiles({ taskId });

  // If we don't have a valid ID, navigate back to our tasks list page

  if (!taskIdIsValid(taskId)) {
    if (isDomAvailable()) navigateTo(tasksPath);
    return null;
  }

  const breadcrumbs = [
    {
      label: 'Reseller',
      to: routePathByName(ROUTE_RESELLER)
    },
    {
      label: 'Tasks',
      to: routePathByName(ROUTE_RESELLER_TASKS)
    }
  ];

  const taskState = {
    isLoading:
      isLoading ||
      isFetchTaskLoading ||
      isUpdateTaskStatusByIdLoading ||
      taskCollectsIsLoading ||
      taskTilesIsLoading,
    isConflictingTasksLoading
  };

  return (
    <TemplateTask
      task={task}
      conflictingTasks={conflictingTasks}
      breadcrumbs={breadcrumbs}
      state={taskState}
    />
  );
};

ResellerTaskDetailsPage.propTypes = {
  taskId: PropTypes.string
};

export default ResellerTaskDetailsPage;
