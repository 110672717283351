import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import { routePathByName } from 'lib/routes';
import {
  ROUTE_AOIS,
  ROUTE_AOI_DETAILS,
  ROUTE_AOI_CREATE
} from 'commonLib/src/data/route-names';

import AOIsPage from 'pages-client/aois/aois';
import AOIDetailsPage from 'pages-client/aois/aoi';
import CreateAOIPage from 'pages-client/aois/create';

const AOIsIndex = () => (
  <>
    <Router basepath="/">
      <AOIsPage path={routePathByName(ROUTE_AOIS)} />
      <AOIDetailsPage
        path={routePathByName(ROUTE_AOI_DETAILS, {
          wildcard: [':aoiId']
        })}
      />
      <CreateAOIPage path={routePathByName(ROUTE_AOI_CREATE)} />
    </Router>
  </>
);

AOIsIndex.propTypes = {
  location: PropTypes.object
};

export default AOIsIndex;
