import Request from 'models/request';
import { location } from 'fogg/lib';

const API_ENDPOINT = 'https://api.mapbox.com';
const MAPBOX_GEOCODE_TOKEN = process.env.MAPBOX_GEOCODE_TOKEN;

const { addParamsToUrl } = location;

/**
 * Request
 * @description Creates an Request class to manage and fetch request data
 */

const API_ERROR_BASE = 'Failed to create MapboxRequest';
const API_ERROR_MISSING_ENDPOINT = 'Missing API_ENDPOINT';

export default class MapboxRequest extends Request {
  constructor (baseUrl, options = {}) {
    // Temporary distinguisher between absolute and relative paths.
    // In place while we're using test endpoints
    let requestURL;

    if (baseUrl.indexOf('http://') === 0 || baseUrl.indexOf('https://') === 0) {
      requestURL = baseUrl;
    } else {
      if (typeof API_ENDPOINT === 'undefined') {
        throw new Error(`${API_ERROR_BASE}: ${API_ERROR_MISSING_ENDPOINT}`);
      }
      requestURL = `${API_ENDPOINT}${baseUrl}`;
    }

    requestURL = addParamsToUrl(requestURL, {
      access_token: MAPBOX_GEOCODE_TOKEN,
      ...options.params
    });

    super(requestURL);
  }
}
