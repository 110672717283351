import { useMappedState } from 'redux-react-hook';
import RepeatRequest from 'models/repeatRequest';

export default function useRepeatRequests ({
  includeContractDetails = true
} = {}) {
  const mapState = ({ repeatRequests, user }) => ({ repeatRequests, user });

  const {
    repeatRequests: repeatRequestsState,
    user: {
      user: { organization: { contracts = [] } = {} }
    }
  } = useMappedState(mapState);

  const { repeatRequests } = repeatRequestsState;
  let filteredRepeatRequests = repeatRequests;

  // map in contract details if they are included and enabled
  if (includeContractDetails) {
    filteredRepeatRequests = RepeatRequest.mapContractsToTasks(
      filteredRepeatRequests,
      contracts
    );
  }

  return {
    ...repeatRequestsState,
    includeContractDetails,
    repeatRequests: [
      ...filteredRepeatRequests.map((repeatRequest) =>
        new RepeatRequest().ingestTaskBody(repeatRequest)
      )
    ]
  };
}
