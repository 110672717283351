import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FaPlusCircle } from 'react-icons/fa';
import { WonderLink } from 'fogg/ui';

import { routePathByName } from '../lib/routes';
import { fillEmptyOrganization } from '../lib/data';

import Layout from 'components/Layout';
import Breadcrumbs from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import OrganizationProfile from '../components/OrganizationProfile';
import OrganizationSidebar from '../components/OrganizationSidebar';
import ContractsItemList from '../components/ContractsItemList';

import { ADMIN_ROLE } from '../data/roles';
import { ROUTE_ORG_CONTRACT_DETAILS } from '../data/route-names';

const organizationPath = routePathByName('organization');

const DEFAULT_BREADCRUMBS = [
  {
    label: 'Organization',
    to: organizationPath
  }
];

const TemplateOrganizationDetails = ({
  organization = {},
  collections,
  licenses,
  accessType,
  breadcrumbs = DEFAULT_BREADCRUMBS,
  state = {},
  onUpdateCollections,
  detailView = false,
  onUpdateHiddenFields,
  onUpdateVerification,
  contractDetailsRouteName = ROUTE_ORG_CONTRACT_DETAILS,
  contractDetailsRouteOptions = []
}) => {
  const { id, name, contracts = [] } = organization;

  const accessFull = accessType === ADMIN_ROLE;

  let activeOrganization = organization;

  const shouldHidePricing = organization.hideOrderPricing && !accessFull;

  const { isLoading = false } = state;

  if (isLoading) {
    activeOrganization = fillEmptyOrganization();
  }

  const helmetSettings = {
    bodyAttributes: {
      class: 'template-organization template-organization-details'
    }
  };

  return (
    <Layout>
      <Helmet {...helmetSettings}>
        <title>{name}</title>
      </Helmet>
      <Container className={`content ${isLoading ? 'content-is-loading' : ''}`}>
        <Breadcrumbs items={breadcrumbs} />
        <PageHeader title={name} subtitle={`ID: ${id}`} />

        <div className="profile">
          <div className="profile-content">
            <div className="profile-section profile-section-rows">
              <OrganizationProfile
                organization={activeOrganization}
                licenses={licenses}
              />
            </div>
          </div>
          <OrganizationSidebar
            accessType={accessType}
            isLoading={isLoading}
            organization={activeOrganization}
            collections={collections}
            onUpdateCollections={onUpdateCollections}
            detailView={detailView}
            onUpdateHiddenFields={onUpdateHiddenFields}
            onUpdateVerification={onUpdateVerification}
          />
        </div>

        <div className="profile">
          <div className="profile-content">
            <div className="profile-content-header">
              <h2>Contracts</h2>
              <ul className="profile-content-header-actions">
                {accessFull && (
                  <li>
                    <WonderLink
                      to={routePathByName('adminOrganizationsContractAdd', {
                        wildcard: id
                      })}
                    >
                      <FaPlusCircle /> Create a New Contract
                    </WonderLink>
                  </li>
                )}
              </ul>
            </div>
            <ContractsItemList
              contracts={contracts}
              isLoading={isLoading}
              shouldHidePricing={shouldHidePricing}
              detailsRouteName={contractDetailsRouteName}
              detailsRouteOptions={contractDetailsRouteOptions}
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

TemplateOrganizationDetails.propTypes = {
  accessType: PropTypes.string,
  breadcrumbs: PropTypes.array,
  collections: PropTypes.array,
  contractDetailsRouteName: PropTypes.string,
  contractDetailsRouteOptions: PropTypes.array,
  detailView: PropTypes.bool,
  ledgerBillingContracts: PropTypes.array,
  onUpdateCollections: PropTypes.func,
  onUpdateHiddenFields: PropTypes.func,
  onUpdateVerification: PropTypes.func,
  organization: PropTypes.object,
  state: PropTypes.object,
  licenses: PropTypes.object
};

export default TemplateOrganizationDetails;
