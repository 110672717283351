import { useMappedState } from 'redux-react-hook';
import Task from 'models/task';

export default function useTasks ({
  includeSpawned = true,
  includeContractDetails = false
} = {}) {
  const mapState = ({ tasks, user }) => ({ tasks, user });

  const {
    tasks: tasksState,
    user: {
      user: { organization: { contracts = [] } = {} }
    }
  } = useMappedState(mapState);
  const {
    tasks = [],
    searchTasks = [],
    currentPage = 1,
    totalPages = 1,
    limit = 10
  } = tasksState;

  // Only return valid tasks with a tasking request ID
  let filteredTasks = tasks.filter(
    (task) => task?.properties?.taskingrequestId
  );

  // Filter out tasks spawned from a repeat request if desired
  if (!includeSpawned) {
    filteredTasks = filteredTasks.filter(
      (task) => !task?.properties?.repeatrequestId
    );
  }

  // map in contract details if they are included and enabled
  if (includeContractDetails) {
    filteredTasks = Task.mapContractsToTasks(filteredTasks, contracts);
  }

  return {
    ...tasksState,
    includeContractDetails,
    tasks: [...filteredTasks.map((task) => new Task().ingestTaskBody(task))],
    currentPage,
    totalPages,
    limit,
    searchTasks: [...searchTasks.map((task) => new Task().ingestTaskBody(task))]
  };
}
