import { fetchItemById, searchCatalog } from 'state/actions';

/**
 * Adds selected items to cart, then asynchrounsly fetch metadata from catalog
 * to display full cart item info (ie. thumbnail, collect date, imaging mode, freshness)
 * @param {array} [items=[]] Array of items to add to cart
 */

export const addAndConfigCartItems = (items = []) => {
  if (!items || !items.length) return;
  return async (dispatch, getState) => {
    // 1. Add selected item(s) directly to cart for fast/smooth UX
    items.forEach(({ collectionId, itemId = '' }) => {
      if (itemId && collectionId) {
        dispatch(addCartItem({
          id: itemId,
          collection: collectionId || undefined
        }));
      } else if (itemId && !collectionId) {
        // If no collection ID is found, it's likely auto-added GEO from analytic
        fetchDerivedItem(itemId);
      }
    });
    // 2. Fetch all metadata and assets and update item(s)
    items.forEach(({ collectionId, itemId = '' }) => {
      // Triggering a fetch without a collection ID returns a completely different
      // type of result. This comes from auto-added GEOs and can break the UI
      if (collectionId) {
        dispatch(fetchItemById({
          collectionId,
          itemId
        })).then(item => {
          dispatch(updateCartItem(item));
        });
      }
    });

    // Asynchronously search catalog for a given STAC ID and add to cart
    async function fetchDerivedItem (stacId) {
      if (!stacId) return;
      const { features = [] } = await dispatch(searchCatalog({
        ids: [stacId],
        limit: 1,
        page: 1
      }, false));

      if (features.length && features[0].feature) {
        const derivedProduct = features[0].feature;
        // Add derived item to cart
        dispatch(addCartItem(derivedProduct));
      }
    }
  };
};

/**
 * addItem
 * @description Add an item to the cart
 */

export const addCartItem = (item) => {
  return {
    type: 'ADD_TO_CART',
    data: item
  };
};

/**
 * addAdditionalCartItem
 * @description Add an additional cart item via the "Add Product" add on button in cart
 */

export const addAdditionalCartItem = (item) => {
  return {
    type: 'ADD_ADDITIONAL_CART_ITEM',
    data: item
  };
};

/**
 * removeAdditionalCartItem
 * @description Removes a specific additional cart item
 */

export const removeAdditionalCartItem = (item) => {
  return {
    type: 'REMOVE_ADDITIONAL_CART_ITEM',
    data: item
  };
};

/**
 * updateItem
 * @description Updates an existing item in the cart
 */

export const updateCartItem = (item) => {
  return {
    type: 'UPDATE_CART',
    data: item
  };
};

/**
 * updateCartShowContracts
 * @description Updates boolean for whether to show contract selector
 * in the cart
 */
export const updateCartShowContracts = (shouldShow) => {
  return {
    type: 'UPDATE_CART_SHOW_CONTRACTS',
    data: shouldShow
  };
};

/**
 * removeItem
 * @description Remove an item from the cart
 */

export const removeCartItem = (item) => {
  return {
    type: 'REMOVE_FROM_CART',
    data: item
  };
};

/**
 * destroyCart
 * @description Destroy cart
 */

export const destroyCart = () => {
  return {
    type: 'DESTROY_CART'
  };
};

/**
 * setContract
 * @param  {string} contractId
 * @param  {string} [contractLabel='']
 */
export const setContract = ({
  contractId,
  contractLabel = ''
}) => {
  return {
    type: 'SET_CONTRACT',
    data: {
      contractId,
      contractLabel
    }
  };
};
