import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import {
  navigateTo,
  routePathByName,
  canRedirectToAfterAuth,
  isPublicPath,
  isUnauthPath
} from 'lib/routes';
import { useUser, useLocation } from 'hooks';
import { useTokenCookie } from 'commonLib/src/hooks';
import { fetchUser } from 'state/actions';

const REDIRECT_AFTER_LOGIN_PATHS = [
  routePathByName('userLogin'),
  routePathByName('userAuthorize')
];

export default function useFetchUser ({ next, token, prevPath } = {}) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user = {} } = useUser();
  const { token: jwt, refresh } = useTokenCookie();
  const sessionToken = token || jwt;
  /**
   * redirectAfterAuth
   */

  function redirectAfterAuth () {
    if (next && canRedirectToAfterAuth(next)) {
      navigateTo(next);
      return;
    } else if (prevPath !== pathname && canRedirectToAfterAuth(prevPath)) {
      navigateTo(prevPath);
      return;
    }
    navigateTo(routePathByName('search'));
  }

  useEffect(() => {
    async function fetchUserEffect () {
      // If we don't have a token, we can't fetch the user. This also means
      // that they should be either on a login page or a publicaly available
      // route, so check that, and if not, kick to login

      if (typeof refresh !== 'string') {
        if (!isPublicPath(pathname) && !isUnauthPath(pathname) && !pathname === routePathByName('userLogin')) {
          navigateTo(routePathByName('userLogout'));
        }
        return;
      }

      try {
        await dispatch(fetchUser());
      } catch (e) {
        const { message } = e || {};
        // eslint-disable-next-line no-console
        console.error(message);
      }

      // If we have a user ID already but are still stuck on the login
      // page, kick the user to the right place

      if (next || REDIRECT_AFTER_LOGIN_PATHS.includes(pathname)) {
        redirectAfterAuth();
      }
    }

    fetchUserEffect();
  }, [sessionToken, refresh]); // eslint-disable-line

  return user;
}
