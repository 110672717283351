import React, { useEffect, useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import PropTypes from 'prop-types';
import { Button, Panel } from 'fogg/ui';
import { useLens } from 'fogg/hooks';
import { MdPlaylistAdd, MdAddShoppingCart, MdCheck } from 'react-icons/md';

import { useSearch, useTaskCreate, useCart, useVerifyCart, useUser } from 'hooks';
import { addAndConfigCartItems } from 'state/actions';
import { returnDerivedCartItems } from 'lib/search';
import { orgIsArchiveSubOnly } from 'commonLib';
import SearchResults from 'components/SearchResults';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { userHasTaskingAccess } from 'commonLib/src/lib/role-util';

/**
 * @param {object} props
 * @param {object} [props.layers={}]
 * @param {function} [props.toggleLayer]
 */
const SidebarSearch = ({ checkedFeatures, setCheckedFeatures, layers, toggleLayer }) => {
  const dispatch = useDispatch();
  const [isLoadingAddToCart, setIsLoadingAddToCart] = useState(false);
  const [areItemsInCart, setAreItemsInCart] = useState();

  const { geoSearch = {} } = useLens();
  const { isActiveSearch } = geoSearch;

  const { actions = {} } = useSearch();
  const { resolveLensSearch = {} } = actions;
  const { isLoading: searchIsLoading } = resolveLensSearch;

  const { path: createTaskPath } = useTaskCreate();

  const { items: cartItems } = useCart();
  const { resetOrdersVerified } = useVerifyCart();
  const { overlay } = layers || {};

  const flags = useFlags();
  const { isLoading: userIsLoading, user: { organization = {}, roles = {} } = {} } = useUser();
  const orgIsArchSubOnly = orgIsArchiveSubOnly(organization);
  const isTaskingAllowed = userHasTaskingAccess(roles, flags);

  const isLoading = searchIsLoading || userIsLoading;

  const displayResults = isLoading || isActiveSearch;

  // Given we're on the search page, if we find that radiant_earth
  // is still active, toggle it off so that we're not leaking individual
  // cogs onto the top level search result list

  if (overlay) {
    overlay.forEach((layer) => {
      if (layer.id === 'radiant_earth' && layer.isActive) {
        toggleLayer('radiant_earth');
      }
    });
  }

  /**
   * handleAddToCart
   * function to look up item + add to cart
   */

  async function handleAddToCart () {
    // Ensure that all derived items are added to the cart along with the selected items
    const derivedCheckedFeatures = [];
    checkedFeatures.forEach(feature => derivedCheckedFeatures.push(...returnDerivedCartItems(feature)));
    // Only get the checked items that haven't already been added to the cart
    const itemsToAdd = derivedCheckedFeatures.filter((feature) => {
      const found = cartItems.find((i) => i.id === feature.id);
      return !found;
    });
    setIsLoadingAddToCart(true);
    setAreItemsInCart(true);
    await dispatch(addAndConfigCartItems(itemsToAdd));
    setIsLoadingAddToCart(false);
    resetOrdersVerified();
  }

  // Check if checked items are in cart

  useEffect(() => {
    if (checkedFeatures.length === 0) return;
    const itemsAreInCart = checkedFeatures.every((feature) =>
      cartItems.find((i) => i.id === feature.id)
    );
    setAreItemsInCart(itemsAreInCart);
    // Make sure "Add to Cart" button comes back when cart is empty
    if (!cartItems || !cartItems.length) {
      setAreItemsInCart(false);
    }
  }, [checkedFeatures, cartItems]);

  const hasCheckedFeatures = checkedFeatures && checkedFeatures.length > 0;

  return (
    <>

      {displayResults && (
        <>
          <SearchResults
            isLoading={isLoading}
            searchIsLoading={searchIsLoading}
            checkedFeatures={checkedFeatures}
            setCheckedFeatures={setCheckedFeatures}
          />

          {(hasCheckedFeatures || createTaskPath) && (
            <Panel className="panel-clean details details-actions">
              {hasCheckedFeatures && (
                <Button
                  className="details-actions-action-add-to-cart"
                  type="icon-before"
                  full={true}
                  disabled={
                    isLoading || isLoadingAddToCart || areItemsInCart
                  }
                  onClick={handleAddToCart}
                >
                  {areItemsInCart ? (
                    <>
                      <MdCheck /> Added to Cart
                    </>
                  ) : (
                    <>
                      <MdAddShoppingCart />{' '}
                      {isLoadingAddToCart
                        ? 'Adding To Cart...'
                        : 'Add To Cart'}
                    </>
                  )}
                </Button>
              )}
              {createTaskPath && !orgIsArchSubOnly && isTaskingAllowed && (
                <Button
                  className="details-actions-action-tasking-request"
                  to={createTaskPath}
                  full={true}
                  type="icon-before"
                >
                  <MdPlaylistAdd /> Tasking Request
                </Button>
              )}
            </Panel>
          )}
        </>
      )}
    </>
  );
};

SidebarSearch.propTypes = {
  results: PropTypes.array,
  mapPosition: PropTypes.array,
  geoJson: PropTypes.object,
  layers: PropTypes.shape({
    base: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        serviceName: PropTypes.string
      })
    ),
    overlay: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        serviceName: PropTypes.string
      })
    )
  }),
  toggleLayer: PropTypes.func,
  checkedFeatures: PropTypes.array,
  setCheckedFeatures: PropTypes.func
};

export default SidebarSearch;
