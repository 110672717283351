import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import { routePathByName } from 'lib/routes';
import {
  ROUTE_SUB_RESELLER,
  ROUTE_SUB_RESELLER_DETAILS,
  ROUTE_SUB_RESELLER_CONTRACT_DETAILS,
  ROUTE_SUB_RESELLER_ORDERS,
  ROUTE_SUB_RESELLER_ORDER_DETAILS,
  ROUTE_SUB_RESELLER_ORG_CREATE,
  ROUTE_SUB_RESELLER_ORG_DETAILS,
  ROUTE_SUB_RESELLER_ORG_DETAILS_EDIT,
  ROUTE_SUB_RESELLER_ORGS,
  ROUTE_SUB_RESELLER_TASK_DETAILS,
  ROUTE_SUB_RESELLER_TASKS,
  ROUTE_SUB_RESELLER_USERS,
  ROUTE_SUB_RESELLER_USER_DETAILS,
  ROUTE_SUB_RESELLER_ORG_CONTRACT_DETAILS,
  ROUTE_SUB_RESELLER_REPEAT_REQUESTS,
  ROUTE_SUB_RESELLER_REPEAT_REQUEST_DETAILS
} from 'data/route-names';

import SubResellerIndexPage from 'pages-client/subreseller/subreseller';
import SubResellerDetailsPage from 'pages-client/subreseller/details';
import SubResellerContractDetailsPage from 'pages-client/subreseller/contracts/contract';
import SubResellerUsersPage from 'pages-client/subreseller/users/users';
import SubResellerUsersDetails from 'pages-client/subreseller/users/user';
import SubResellerUsersCreatePage from 'pages-client/subreseller/users/create';
import SubResellerOrganizationsPage from 'pages-client/subreseller/organizations/organizations';
import SubResellerOrganizationsDetails from 'pages-client/subreseller/organizations/organization';
import SubResellerOrganizationsDetailsEdit from 'pages-client/subreseller/organizations/edit';
import SubResellerOrganizationsCreate from 'pages-client/subreseller/organizations/create';
import SubResellerOrganizationsContractEditPage from 'pages-client/subreseller/organizations/contract/edit';
import SubResellerOrganizationsContractPeriodAddPage from 'pages-client/subreseller/organizations/contract-period/add';
import SubResellerOrganizationsContractPeriodEditPage from 'pages-client/subreseller/organizations/contract-period/edit';
import SubResellerTasksPage from 'pages-client/subreseller/tasks/tasks';
import SubResellerRepeatRequestsPage from 'pages-client/subreseller/repeatRequests/repeatRequests';
import SubResellerTaskDetailsPage from 'pages-client/subreseller/tasks/task';
import SubResellerRepeatRequestPage from 'pages-client/subreseller/repeatRequests/repeatRequest';
import SubResellerOrders from 'pages-client/subreseller/orders/orders';
import SubResellerOrder from 'pages-client/subreseller/orders/order';
import SubresellerOrganizationContractDetails from 'pages-client/subreseller/organizations/contract';

const SubResellerIndex = () => {
  const organizationRouteOptions = {
    wildcard: [':organizationId', ':contractPeriodId']
  };

  const ledgerOrganizationRouteOptions = {
    wildcard: [':organizationId', ':contractId']
  };

  return (
    <>
      <Router basepath="/">
        <SubResellerIndexPage
          path={routePathByName(ROUTE_SUB_RESELLER)}
          default
        />
        <SubResellerUsersPage
          path={routePathByName(ROUTE_SUB_RESELLER_USERS)}
        />
        <SubResellerUsersDetails
          path={routePathByName(ROUTE_SUB_RESELLER_USER_DETAILS, {
            wildcard: ':userId'
          })}
        />
        <SubResellerOrganizationsPage
          path={routePathByName(ROUTE_SUB_RESELLER_ORGS)}
        />
        <SubResellerUsersCreatePage
          path={routePathByName('subresellerUsersCreate')}
        />
        <SubResellerOrganizationsDetails
          path={routePathByName(ROUTE_SUB_RESELLER_ORG_DETAILS, {
            wildcard: ':organizationId'
          })}
        />
        <SubResellerOrganizationsDetailsEdit
          path={routePathByName(ROUTE_SUB_RESELLER_ORG_DETAILS_EDIT, {
            wildcard: ':organizationId'
          })}
        />
        <SubResellerOrganizationsCreate
          path={routePathByName(ROUTE_SUB_RESELLER_ORG_CREATE)}
        />

        <SubResellerTasksPage
          path={routePathByName(ROUTE_SUB_RESELLER_TASKS)}
        />
        <SubResellerRepeatRequestsPage
          path={routePathByName(ROUTE_SUB_RESELLER_REPEAT_REQUESTS)}
        />
        <SubResellerRepeatRequestPage
          path={routePathByName(ROUTE_SUB_RESELLER_REPEAT_REQUEST_DETAILS, {
            wildcard: [':repeatRequestId']
          })}
        />

        <SubResellerTasksPage
          path={routePathByName(ROUTE_SUB_RESELLER_TASKS)}
        />
        <SubResellerTaskDetailsPage
          path={routePathByName(ROUTE_SUB_RESELLER_TASK_DETAILS, {
            wildcard: [':taskId']
          })}
        />
        <SubResellerOrganizationsContractEditPage
          path={routePathByName(
            'subresellerOrganizationsContractEdit',
            ledgerOrganizationRouteOptions
          )}
        />
        <SubResellerOrganizationsContractPeriodAddPage
          path={routePathByName(
            'subresellerOrganizationsContractPeriodAdd',
            ledgerOrganizationRouteOptions
          )}
        />
        <SubResellerOrganizationsContractPeriodEditPage
          path={routePathByName(
            'subresellerOrganizationsContractPeriodEdit',
            organizationRouteOptions
          )}
        />
        <SubResellerDetailsPage
          path={routePathByName(ROUTE_SUB_RESELLER_DETAILS, {
            wildcard: ':organizationId'
          })}
        />
        <SubResellerContractDetailsPage
          path={routePathByName(
            ROUTE_SUB_RESELLER_CONTRACT_DETAILS,
            ledgerOrganizationRouteOptions
          )}
        />
        <SubResellerOrders path={routePathByName(ROUTE_SUB_RESELLER_ORDERS)} />
        <SubResellerOrder
          path={routePathByName(ROUTE_SUB_RESELLER_ORDER_DETAILS, {
            wildcard: ':orderId'
          })}
        />
        <SubresellerOrganizationContractDetails
          path={routePathByName(
            ROUTE_SUB_RESELLER_ORG_CONTRACT_DETAILS,
            ledgerOrganizationRouteOptions
          )}
        />
      </Router>
    </>
  );
};

SubResellerIndex.propTypes = {
  location: PropTypes.object
};

export default SubResellerIndex;
