import React from 'react';
import LINK_PROPERTIES_CONFIG from './route-links.yml';

const ADMIN_KEY_PREFIX = 'admin';
const ORGMAN_KEY_PREFIX = 'orgman';
const RESELLER_KEY_PREFIX = 'reseller';
const SUBRESELLER_KEY_PREFIX = 'subreseller';

/**
 * @typedef {object} LinkProperties
 * @property {string} label
 * @property {[keyof import('./route-names')]} routeName
 * @property {[keyof import('react-icons/fa')]} iconName
 * @property {number} [sortOrder]
 * @property {boolean} [enabled]
 */

const routeLinks = {
  admin: [
    'adminCollectionsLink',
    'adminOrganizationsLink',
    'adminUsersLink'],
  orgman: [
    'orgmanContractsLinks',
    'orgmanDetailsLink',
    'orgmanOrdersLink',
    'orgmanTasksLink',
    'orgmanUsersLink'
  ],
  reseller: [
    'resellerDetailsLink',
    'resellerOrdersLink',
    'resellerOrganizationsLink',
    'resellerTasksLink',
    'resellerUsersLink'
  ],
  subreseller: [
    'subresellerDetailsLink',
    'subresellerOrdersLink',
    'subresellerOrganizationsLink',
    'subresellerTasksLink',
    'subresellerUsersLink'
  ],
  config: {
    ...LINK_PROPERTIES_CONFIG
  }
};

/**
 * array filter function, filters on `enabled` property and any other custom logic
 *
 * @param {object} param0
 * @param {boolean} [param0.enabled=true] enabled property, if not defined is always true
 * @param {boolean} [additionalParam=true] optionally provide an addition logical determination for filtering
 * @returns {boolean}
 */
function filterEnabled ({ enabled = true }, additionalParam = true) {
  return enabled === true && additionalParam === true;
}

/**
 * Array compare function to sort on arrayItem.sortOrder property.
 * If the sortOrder is not defined, it defaults to Infinity to order it after any that are defined
 */
function sortBySortOrderAsc (
  { sortOrder: aLinkSort = Infinity },
  { sortOrder: bLinkSort = Infinity }
) {
  if (aLinkSort > bLinkSort) {
    return 1;
  }
  if (aLinkSort < bLinkSort) {
    return -1;
  }
  return 0;
}

/**
 * @param {string} keyPrefix
 * @param {object} routeLinkData
 * @returns {LinkProperties[]}
 */
function filteredRouteLinksByKeyPrefix (keyPrefix, routeLinkData = routeLinks) {
  const keys = Object.entries(routeLinkData)
    .filter(([key]) => key === keyPrefix)
    .map(([_key, value]) => value)
    .flat();

  return Object.entries(routeLinkData.config)
    .filter(([key, value]) => filterEnabled(value, keys.includes(key)))
    .map(([_key, value]) => ({ ...value }))
    .sort(sortBySortOrderAsc);
}

/**
 * array map function to shape the output based on the LinkProperties
 *
 * @param {LinkProperties} link
 * @param {Function} routePathByNameFunc
 * @param {React.Component|HTMLElement} IconComponent
 * @returns {{ label: string; to: string; icon: React.Component|HTMLElement}}
 */
function mapToLinkListItem (link, routePathByNameFunc, IconComponent) {
  return {
    label: link.label,
    to: routePathByNameFunc(link.routeName),
    icon: (
      <IconComponent
        name={link.iconName}
        className={`icon-${link.label.toLowerCase()}`}
      />
    )
  };
}

function adminRouteLinks () {
  return filteredRouteLinksByKeyPrefix(ADMIN_KEY_PREFIX);
}

function orgmanRouteLinks () {
  return filteredRouteLinksByKeyPrefix(ORGMAN_KEY_PREFIX);
}

function resellerRouteLinks () {
  return filteredRouteLinksByKeyPrefix(RESELLER_KEY_PREFIX);
}

function subresellerRouteLinks () {
  return filteredRouteLinksByKeyPrefix(SUBRESELLER_KEY_PREFIX);
}

export {
  adminRouteLinks,
  filteredRouteLinksByKeyPrefix,
  mapToLinkListItem,
  orgmanRouteLinks,
  resellerRouteLinks,
  subresellerRouteLinks
};
