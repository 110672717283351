import { useSearchParam } from 'react-use';
import PropTypes from 'prop-types';
import { useLogOut } from 'hooks';
import { routePathByName } from 'lib/routes';
import { ROUTE_SEARCH } from 'commonLib/src/data/route-names';

const UserLogoutPage = ({ location = {} }) => {
  // navigate to this page after you log out
  let next = useSearchParam('next');
  const sessionExpired = useSearchParam('sessionExpired');

  const { state = {}, pathname } = location;
  const manualLogout = state?.manualLogout || false;

  // if we're in the middle of re-routing to another page
  // proceed with logging the user out, but don't interrupt
  // the page change with another redirect
  const isRerouting =
    typeof window !== 'undefined' && routePathByName('userLogout') !== pathname;

  // Set default redirect path to /search if not manual logout
  if (!next && !isRerouting && !manualLogout) {
    if (state?.prevPath) {
      next = state.prevPath;
    } else next = routePathByName(ROUTE_SEARCH);
  }

  useLogOut({
    next,
    redirect: !isRerouting,
    sessionExpired,
    manualLogout
  });
  return null;
};

UserLogoutPage.propTypes = {
  location: PropTypes.object
};

export default UserLogoutPage;
