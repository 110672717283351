import React from 'react';
import PropTypes from 'prop-types';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { WonderLink } from 'fogg/ui';

import { sortByKey } from 'lib/util';

/**
 * Map through assets for an individual order item and display them as download links
 * @param {object} props
 * @param {object} [props.assets={}]
 */
const AssetLinks = ({ assets }) => {
  const groupedAssets = groupAssetsByType(assets, 'title');
  const assetSets = [];

  for (const [key, value] of Object.entries(groupedAssets)) {
    assetSets.push({
      label: key,
      assets: value
    });
  }

  // hasAssets is > 1 since we should always have the STAC record "asset"
  const hasAssets = Array.isArray(assetSets) && assetSets.length > 1;

  return (
    <ul className="asset-links">
      {hasAssets && assetSets.map((set, setIndex) => {
        const { label, assets: setAssets } = set;
        const setAssetsSorted = sortByKey(setAssets, 'name');
        return (
          <li key={`AssetLinks-Set-${setIndex}`}>
            {Array.isArray(setAssetsSorted) && (
              <>
                <strong className="asset-links-set-title">{label}</strong>
                <ul>
                  {setAssetsSorted.map((asset, assetIndex) => {
                    return (
                      <li key={`AssetLinks-Set-Asset-${assetIndex}`}>
                        <WonderLink
                          to={asset.href}
                          download={asset.download ? asset.download : true}
                          target="_blank"
                        >
                          <FaCloudDownloadAlt />
                          {asset.name}
                        </WonderLink>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

AssetLinks.propTypes = {
  assets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  orderStatus: PropTypes.string
};

export default AssetLinks;

function groupAssetsByType (assets = {}, typeKey) {
  const assetSets = {};

  for (const [key, value] of Object.entries(assets)) {
    const setKey = value[typeKey];

    if (!assetSets[setKey]) {
      assetSets[setKey] = [];
    }
    let url = value.href;
    // Use hrefDownload url when available to ensure downloads
    // work as expected for .json file formats
    if (value.hrefDownload) {
      url = value.hrefDownload;
    }
    assetSets[setKey].push({
      name: key,
      href: url,
      download: value.download
    });
  }

  return assetSets;
}
