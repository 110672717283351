import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'fogg/ui';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';

/**
 * Wrapper used within ItemCard to group the card items
 * with the optional assets & cost
 * @param {object} props
 * @param {object} [props.item={}]
 * @param {object} [props.assets={}]
 */
const ItemOverview = ({ item = {}, assets }) => {
  const { title, id, cost, properties = {}, feature = {} } = item;

  const productType = properties['sar:product_type'] || feature.productType;

  let itemTitle = title || id;
  // We don't have a title/STAC ID to display yet for generated analytics, so add one...
  if (!itemTitle && AnalyticProducts.isAnalytic(productType)) {
    itemTitle = AnalyticProducts.getLabel(productType) || 'Analytic Item';
  }

  return (
    <div className="item-overview">
      <div className="item-overview-content">
        <div className="item-overview-id" title={id}>
          {productType && (<Badge label={productType} type="info" />)}
          <strong>{itemTitle}</strong>
        </div>
        <div className="item-overview-cost">{cost && cost}</div>
      </div>
      {assets && assets}
    </div>
  );
};

ItemOverview.propTypes = {
  item: PropTypes.object,
  assets: PropTypes.node
};

export default ItemOverview;
