import React from 'react';
import PropTypes from 'prop-types';
import { MapPreview as FoggMapPreview } from 'fogg/ui';

import { mapServices } from 'data/map-services';
import { useMapLayers } from 'hooks';
import BasemapControl from 'components/BasemapControl';

/**
 * Reusable wrapper for FoggMapPreview since we use it in multiple places
 * so we don't have to re-render it every time and persist some of the state (like basemap)
 * @param {object} props
 * @param {object} [props.children]
 * @param {number} [props.zoom=5]
 * @param {boolean} [props.displayAccessRequests=false]
 * @param {boolean} [props.displayAOIDetails=true]
 * @param {boolean} [props.showBasemap=true]
 * @param {object} [props.rest={}] Pass-through props for FoggMapPreview
 */

const MapPreview = ({
  children,
  zoom = 5,
  displayAccessRequests,
  displayAOIDetails = true,
  showBasemap = true,
  ...rest
}) => {
  const { layers } = useMapLayers();

  let previewContainerClass = 'map-preview-inner-container';
  if (displayAccessRequests) previewContainerClass += ' access-requests-included';
  if (!displayAOIDetails) previewContainerClass += ' hide-aoi-details';

  return (
    <div className="map-preview-container" data-testid="map-preview">
      <div className={previewContainerClass}>
        <FoggMapPreview
          zoom={zoom}
          projection="epsg3857"
          availableLayers={layers}
          availableServices={mapServices}
          showGeometryType={false}
          {...rest}
        />
        {showBasemap && (
          <BasemapControl layers={layers} className="mini" />
        )}
        {children}
      </div>
    </div>
  );
};

MapPreview.propTypes = {
  children: PropTypes.node,
  zoom: PropTypes.number,
  displayAccessRequests: PropTypes.bool,
  displayAOIDetails: PropTypes.bool,
  showBasemap: PropTypes.bool
};

export default MapPreview;
