import { exportClassData } from '../lib/util';
import { formatDateTime } from '../lib/datetime';
import { itemPropertyById } from '../lib/items';

class Item {
  constructor (data = {}) {
    this.feature = { ...data };
    this.id = this.feature.id || this.feature.granuleId;
    this.properties = this.feature.properties || {};
    this.assets = this.feature.assets || {};
    this.collection =
      this.propertyByKey('collection') ||
      this.feature.collection ||
      this.feature.collectionId;
    this.startTime = this.propertyByKey('start_datetime');
    this.endTime = this.propertyByKey('end_datetime');
    this.thumbnail = this.assets.thumbnail && this.feature.assets.thumbnail.href;
    this.preview = this.assets.preview && this.feature.assets.preview.href;
    this.cost = this.feature.cost;
    this.previouslyOwned = this.feature.previouslyOwned;
    this.bbox = this.feature.bbox;
    this.geometry = this.feature.geometry;
    this.collectId = this.feature.collectId || (this.feature?.properties && this.feature.properties['capella:collect_id']);
    this.freshnessOption = this.feature.freshnessOption || this.feature.properties?.freshnessOption;
    this.orderDetails = this.feature.orderDetails || {};
    this.productType = this.feature?.properties && this.feature.properties['sar:product_type']
      ? this.feature.properties['sar:product_type'] : undefined;
  }

  /**
   * data
   * @description Dump of all data in the class
   */

  data () {
    return exportClassData(this);
  }

  /**
   * assetByKey
   * @description Returns an asset given it's key
   */

  assetByKey (key, assets = this.assets) {
    return (assets && assets[key]) || {};
  }

  /**
   * propertyByKey
   * @description Returns a property object given it's key
   */

  propertyByKey (key, properties = this.properties) {
    return properties && properties[key];
  }

  /**
   * propertiesArray
   * @description Returns a property object given it's key
   * @param {bool} friendly - controls if the array uses friendly names for properties
   * @param excludeProperties - array of properties to not include/display
   */

  propertiesArray (friendly = true, excludeProperties = []) {
    // 1st remove any properties we don't want
    if (excludeProperties.length) {
      excludeProperties.forEach(val => delete this.properties[val]);
    }
    return Object.keys(this.properties).map((key) => {
      const itemProperty = itemPropertyById(key);
      const { label, type } = itemProperty || {};

      let name = friendly && label ? label : key;
      let value = this.properties[key];

      // Let's check if value is a valid instance of date too

      if (type === 'datetime' && value instanceof Date && !isNaN(value)) {
        name = `${name} (UTC)`;
        value = formatDateTime(value);
      }

      if (type === 'degrees') {
        value = `${value}°`;
      }

      if (type === 'gigahertz') {
        value = `${value} GHz`;
      }

      if (type === 'meters') {
        if (['Image Width', 'Image Length'].includes(name)) {
          value = Math.round(value);
        }
        value = `${value} m`;
      }

      if (type === 'uncertainty') {
        value = `± ${value}`;
      }

      // Fix names like "sliding_spotlight"
      if (key === 'sar:instrument_mode') {
        value = value.replace('_', ' ');
      }

      // If our value is null - we want to display something
      // a little more friendly

      if (`${value}` === 'null') {
        value = '-';
      }

      // If our value is none return -

      if (`${value}` === 'none') {
        value = '-';
      }

      // If we have an array, we want to stringify
      // the value so that we're showing it in friendlier way

      if (Array.isArray(value)) {
        value = value.toString();
      }

      // If our previous toString doesn't work, we fall back to an actual
      // JSON.stringify call to make sure we show the real value

      if (typeof value !== 'string') {
        value = JSON.stringify(this.properties[key]);
      }

      // If "Archive Holdback Date = 12/31/9999, display "Permanent"
      // Looking for '9999' instead of exact date to give some timezone buffer
      if (label === 'Archive Holdback Date' && value.includes('9999')) {
        value = 'Permanent';
      }

      return {
        property: name,
        value,
        key,
        type
      };
    });
  }
}

export default Item;
