import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import { routePathByName } from 'lib/routes';
import {
  ROUTE_RESELLER,
  ROUTE_RESELLER_DETAILS,
  ROUTE_RESELLER_CONTRACT_DETAILS,
  ROUTE_RESELLER_ORDERS,
  ROUTE_RESELLER_ORDER_DETAILS,
  ROUTE_RESELLER_ORG_CREATE,
  ROUTE_RESELLER_ORG_DETAILS,
  ROUTE_RESELLER_ORG_DETAILS_EDIT,
  ROUTE_RESELLER_ORGS,
  ROUTE_RESELLER_TASK_DETAILS,
  ROUTE_RESELLER_TASKS,
  ROUTE_RESELLER_USERS,
  ROUTE_RESELLER_USERS_DETAILS,
  ROUTE_RESELLER_ORG_CONTRACT_DETAILS,
  ROUTE_RESELLER_REPEAT_REQUEST_DETAILS
} from 'data/route-names';

import ResellerIndexPage from 'pages-client/reseller/reseller';
import ResellerUsersPage from 'pages-client/reseller/users/users';
import ResellerUsersDetails from 'pages-client/reseller/users/user';
import ResellerUsersCreatePage from 'pages-client/reseller/users/create';
import ResellerOrganizationsPage from 'pages-client/reseller/organizations/organizations';
import ResellerOrganizationsDetails from 'pages-client/reseller/organizations/organization';
import ResellerOrganizationsDetailsEdit from 'pages-client/reseller/organizations/edit';
import ResellerOrganizationsCreate from 'pages-client/reseller/organizations/create';
import ResellerTasksPage from 'pages-client/reseller/tasks/tasks';
import ResellerTaskDetailsPage from 'pages-client/reseller/tasks/task';
import ResellerRepeatRequestPage from 'pages-client/reseller/repeatRequests/repeatRequest';
import ResellerRepeatRequestsPage from 'pages-client/reseller/repeatRequests/repeatRequests';

import ResellerOrganizationsContractEditPage from 'pages-client/reseller/organizations/contract/edit';
import ResellerOrganizationsContractPeriodAddPage from 'pages-client/reseller/organizations/contract-period/add';
import ResellerOrganizationsContractPeriodEditPage from 'pages-client/reseller/organizations/contract-period/edit';
import ResellerDetailsPage from 'pages-client/reseller/details';
import ResellerContractDetailsPage from 'pages-client/reseller/contracts/contract';
import ResellerOrders from 'pages-client/reseller/orders/orders';
import ResellerOrder from 'pages-client/reseller/orders/order';
import ResellerOrganizationContractDetails from 'pages-client/reseller/organizations/contract';
import { ROUTE_RESELLER_REPEAT_REQUESTS } from '../../common-web-lib/src/data/route-names';

const ResellerIndex = () => {
  const organizationRouteOptions = {
    wildcard: [':organizationId', ':contractPeriodId']
  };

  const ledgerOrganizationRouteOptions = {
    wildcard: [':organizationId', ':contractId']
  };

  return (
    <>
      <Router basepath="/">
        <ResellerIndexPage path={routePathByName(ROUTE_RESELLER)} default />
        <ResellerUsersPage path={routePathByName(ROUTE_RESELLER_USERS)} />
        <ResellerUsersDetails
          path={routePathByName(ROUTE_RESELLER_USERS_DETAILS, {
            wildcard: ':userId'
          })}
        />
        <ResellerUsersCreatePage
          path={routePathByName('resellerUsersCreate')}
        />
        <ResellerOrganizationsPage
          path={routePathByName(ROUTE_RESELLER_ORGS)}
        />
        <ResellerOrganizationsDetails
          path={routePathByName(ROUTE_RESELLER_ORG_DETAILS, {
            wildcard: ':organizationId'
          })}
        />
        <ResellerOrganizationsDetailsEdit
          path={routePathByName(ROUTE_RESELLER_ORG_DETAILS_EDIT, {
            wildcard: ':organizationId'
          })}
        />
        <ResellerOrganizationsCreate
          path={routePathByName(ROUTE_RESELLER_ORG_CREATE)}
        />

        <ResellerTasksPage path={routePathByName(ROUTE_RESELLER_TASKS)} />
        <ResellerRepeatRequestsPage
          path={routePathByName(ROUTE_RESELLER_REPEAT_REQUESTS)}
        />
        <ResellerRepeatRequestPage
          path={routePathByName(ROUTE_RESELLER_REPEAT_REQUEST_DETAILS, {
            wildcard: [':repeatRequestId']
          })}
        />

        <ResellerTaskDetailsPage
          path={routePathByName(ROUTE_RESELLER_TASK_DETAILS, {
            wildcard: [':taskId']
          })}
        />
        <ResellerOrganizationsContractEditPage
          path={routePathByName(
            'resellerOrganizationsContractEdit',
            ledgerOrganizationRouteOptions
          )}
        />
        <ResellerOrganizationsContractPeriodAddPage
          path={routePathByName(
            'resellerOrganizationsContractPeriodAdd',
            ledgerOrganizationRouteOptions
          )}
        />
        <ResellerOrganizationsContractPeriodEditPage
          path={routePathByName(
            'resellerOrganizationsContractPeriodEdit',
            organizationRouteOptions
          )}
        />
        <ResellerDetailsPage
          path={routePathByName(ROUTE_RESELLER_DETAILS, {
            wildcard: ':organizationId'
          })}
        />
        <ResellerContractDetailsPage
          path={routePathByName(
            ROUTE_RESELLER_CONTRACT_DETAILS,
            ledgerOrganizationRouteOptions
          )}
        />
        <ResellerOrders path={routePathByName(ROUTE_RESELLER_ORDERS)} />
        <ResellerOrder
          path={routePathByName(ROUTE_RESELLER_ORDER_DETAILS, {
            wildcard: ':orderId'
          })}
        />
        <ResellerOrganizationContractDetails
          path={routePathByName(
            ROUTE_RESELLER_ORG_CONTRACT_DETAILS,
            ledgerOrganizationRouteOptions
          )}
        />
      </Router>
    </>
  );
};

ResellerIndex.propTypes = {
  location: PropTypes.object
};

export default ResellerIndex;
