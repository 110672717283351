
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Reusable dropdown component
 * @param {object} props
 * @param {boolean} props.isOpen = false
 * @param {Node} props.trigger element (ie. button) that will toggle the menu open/closed
 * @param {function|null} [props.triggerCallback] allows trigger to get called programmatically
 * @param {Node|Node[]} props.menu options to be exposed when dropdown is open
 * @param {string} [props.id] unique ID used to control aria-labelledby for accessibility
 */
const DropdownMenu = ({
  isOpen = false,
  trigger,
  triggerCallback,
  menu,
  id = 'dropdown_menu'
}) => {
  const focusRef = useRef();
  // Close dropdown when clicking outside of it
  const handleClickOutside = (event) => {
    if (!focusRef.current?.contains(event.target)) {
      triggerCallback(false);
    }
  };
  document.addEventListener('mousedown', handleClickOutside);

  return (
    <div
      className="dropdown-wrapper"
      aria-expanded={isOpen}
      id={id}
      ref={focusRef}
    >
      {trigger}
      {isOpen ? (
        menu
      ) : null}
    </div>
  );
};

DropdownMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  trigger: PropTypes.node,
  triggerCallback: PropTypes.func,
  menu: PropTypes.node,
  id: PropTypes.string
};

export default DropdownMenu;
