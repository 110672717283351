import React from 'react';
import PropTypes from 'prop-types';
import { Table, WonderLink } from 'fogg/ui';

import { formatDateTime } from 'lib/datetime';
import { routePathByName } from 'lib/routes';

const OrderConfigTable = ({
  order = {},
  hasMultipleContracts = false,
  orderRoute = 'ordersDetails'
}) => {
  const { orderDate, userId, parentOrderId, contractLabel } = order;

  const columns = [
    {
      columnId: 'property'
    },
    {
      columnId: 'value'
    }
  ];

  const data = [
    {
      property: 'Order Date (UTC)',
      value: (orderDate && formatDateTime(orderDate)) || ''
    },
    {
      property: 'User ID',
      value: userId || ''
    }
  ];

  if (parentOrderId && typeof parentOrderId === 'string') {
    data.push({
      property: 'Original Order',
      value: (
        <WonderLink
          to={routePathByName(orderRoute, {
            wildcard: [parentOrderId]
          })}
        >
          {parentOrderId}
        </WonderLink>
      )
    });
  }

  if (hasMultipleContracts) {
    data.push({
      property: 'Contract',
      value: contractLabel
    });
  }

  return (
    <Table
      displayHeader={false}
      fixHeightToContent={true}
      rowHeight={50}
      columns={columns}
      data={data}
    />
  );
};

OrderConfigTable.propTypes = {
  order: PropTypes.object.isRequired,
  orderRoute: PropTypes.string,
  hasMultipleContracts: PropTypes.bool
};

export default OrderConfigTable;
