import React from 'react';
import PropTypes from 'prop-types';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';
import { Table } from '@trussworks/react-uswds';
import { fillEmptyTasks } from 'lib/data';
import { useLocation } from 'hooks';

/**
 * An accessible Data Table component built using USWDS Table
 * @param {object} props
 * @param {array} [props.columns = []] e.g. TASKS_TABLE_COLUMNS pass via parent
 * @param {Node|Node[]} [props.tableBody=undefined] JSX containing the actual table data
 * @param {boolean} [props.isLoading = false]
 * @param {string} [props.tableId = '']
 * @param {boolean} [props.isSortable = true]
 */

const SortableTable = ({
  columns = [],
  tableBody = undefined,
  isLoading = false,
  tableId = '',
  isSortable = true,
  handleSortClick
}) => {
  const { queryParams = {} } = useLocation();
  const { sort: sortParam, order: orderParam } = queryParams;

  const columnCount = columns?.length || 5;
  const hasColumns = columns?.length && columns.length > 0;

  return (
    <div className="table-wrapper" id={tableId}>
      <Table bordered={false} fullWidth={true} scrollable={true} className="tasks-table">
        <thead>
          <tr>
            {!hasColumns && (
              <td className="text-center empty-tasks-table" colSpan={columnCount}>
                <FiAlertTriangle /> Select Columns to display tasks table.
              </td>
            )}
            {hasColumns ? columns.map((col = {}) => {
              const { columnId, label, sortableColumn = true } = col;

              return (
                <th scope="col" key={columnId} role="columnheader">
                  <span className={
                    (isSortable && sortableColumn) ? 'sortable' : ''}
                  >
                    {label}
                    {(isSortable && sortableColumn) && (
                      <span
                        className="sort-buttons"
                        data-testid={`sort-buttons-${columnId}`}
                      >
                        <button
                          className={sortParam === columnId && orderParam === 'asc'
                            ? 'active' : ''}
                          onClick={() => handleSortClick(columnId, 'asc')}
                        >
                          <FaSortUp />
                        </button>
                        <button
                          className={sortParam === columnId && orderParam === 'desc'
                            ? 'active' : ''}
                          onClick={() => handleSortClick(columnId, 'desc')}
                        >
                          <FaSortDown />
                        </button>
                      </span>
                    )}
                  </span>
                </th>
              );
            }) : undefined}
          </tr>
        </thead>
        <tbody data-testid={isLoading ? 'loading-table-body' : 'table-body'}>
          {isLoading
            ? (fillEmptyTasks(5, columnCount))
            : tableBody
          }
        </tbody>
      </Table>
    </div>
  );
};

SortableTable.propTypes = {
  columns: PropTypes.array,
  tableBody: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isLoading: PropTypes.bool,
  tableId: PropTypes.string,
  isSortable: PropTypes.bool,
  handleSortClick: PropTypes.func
};

export default SortableTable;
