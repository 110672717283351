import currency from 'currency.js';

export function formatNumberToCurrency (integer) {
  const value = currency(integer, {
    formatWithSymbol: true
  });
  return value.format();
}

export function formatCurrencyToNumber (string) {
  if (!string) return;
  const value = currency(string || 0);
  return value.value;
}

export function formatPercentageFloat (float) {
  const number = parseFloat(float) * 100;
  return `${number.toFixed(2)}%`;
}

/**
 * percentageToFloat
 * @param {*} percentage - Must be a properly formatted percentage - Ex: 10%, 10.10%
 */

export function percentageToFloat (percentage) {
  const invalidPercentageError = `Invalid percentage: ${percentage}`;
  const isString = typeof percentage === 'string';

  let value;

  if (!isString || !percentage.slice(-1) === '%') {
    throw new Error(invalidPercentageError);
  }

  value = percentage.substr(0, percentage.length - 1);
  value = parseFloat(value);
  value = value / 100;

  return value;
}
