import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { device } from 'fogg/lib';

import {
  userIdIsValid,
  logError,
  redirectTo,
  useAdminFetchUser,
  useAdmin,
  useAdminFetchOrganizations,
  putUpdateUser,
  reissuePassword,
  triggerNotice,
  triggerSuccess,
  TemplateUser,
  ADMIN_ROLE,
  DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS,
  UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS
} from 'commonLib';

import { routePathByName } from 'commonLib/src/lib';

const { isDomAvailable } = device;

const adminUsersPath = routePathByName('adminUsers');

const AdminUserPage = ({ userId }) => {
  const [ needsForceDisable, setNeedsForceDisable, ] = useState(false);
  const [ needsForceTaskerRoleRemoval, setNeedsForceTaskerRoleRemoval ] = useState(false);
  const [ userDataToResubmit, setUserDataToResubmit ] = useState({});

  const dispatch = useDispatch();

  const { actions = {} } = useAdmin();
  const {
    putUpdateUser: putUpdateUserAction = {},
    reissuePassword: reissuePasswordAction = {}
  } = actions;
  const { isLoading: putUpdateUserIsLoading } = putUpdateUserAction;
  const { isLoading: reissuePasswordIsLoading } = reissuePasswordAction;

  const { user = {}, isLoading: fetchUserIsLoading } = useAdminFetchUser({
    id: userId
  });
  const { noaaVerified, noaaWhitelisted } = user;

  const { organizations = [], isLoading: organizationsIsLoading } =
    useAdminFetchOrganizations();

  const isLoading =
    fetchUserIsLoading ||
    putUpdateUserIsLoading ||
    reissuePasswordIsLoading ||
    organizationsIsLoading;

  const verifications = [
    {
      id: 'noaaVerified',
      label: 'NOAA Verified',
      isVerified: !!noaaVerified,
      readOnly: true
    },
    {
      id: 'noaaWhitelisted',
      label: 'NOAA Whitelisted',
      isVerified: !!noaaWhitelisted,
      actionLabels: {
        add: 'Add to Whitelist',
        remove: 'Remove from Whitelist'
      }
    }
  ];

  if (!userIdIsValid(userId)) {
    // Navigate only works on the client, throws an error during compilation
    if (isDomAvailable()) {
      redirectTo(adminUsersPath);
    }
    return null;
  }

  const breadcrumbs = [
    {
      label: 'Admin',
      to: routePathByName('admin')
    },
    {
      label: 'Users',
      to: routePathByName('adminUsers')
    }
  ];

  /**
   * handleUpdateUser
   * @description Dispatches an update to a user based on the given user object
   */

  async function handleUpdateUser (userData, { showSuccess = true } = {}) {

    let showSuccessBanner = showSuccess;
    let response;
    try {
      response = await dispatch(putUpdateUser(userId, userData));
    } catch (e) {
      logError(e.message, userData);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
      return;
    }

    if (response.message === DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS) {
      setNeedsForceDisable(true);
      showSuccessBanner = false;
    } else if (response.message === UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS) {
      setNeedsForceTaskerRoleRemoval(true);
      setUserDataToResubmit(userData);
      showSuccessBanner = false;
    }

    if (showSuccessBanner) {
      dispatch(
        triggerNotice({
          type: 'success',
          align: 'center',
          text: 'Successfully updated user!'
        })
      );
    }

    return response;
  }

  /**
   * handleUpdateVerification
   * @description Triggers an update to toggle a user's verification settings
   */

  function handleUpdateVerification (e, { id }) {
    handleUpdateUser({
      [id]: !user[id]
    });
  }

  /**
   * handleReissuePassword
   * @description Dispatches a request to reissue a user's temporary password
   */

  async function handleReissuePassword () {
    try {
      await dispatch(reissuePassword(userId));
      dispatch(triggerSuccess('Successfully reissued password'));
    } catch (error) {
      logError(error.message, {
        id: userId
      });
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'User has already reset temporary password. Please have them use the Forgot Password feature on the UI to reset.'
        })
      );
    }
  }

  function handleCloseModal () {
    setNeedsForceTaskerRoleRemoval(false);
    setNeedsForceDisable(false);
    setUserDataToResubmit(undefined);
  }

  function handleUpdateConfirmation() {
    if (needsForceTaskerRoleRemoval) {
      handleForceTaskerRoleRemoval();
    } else if (needsForceDisable) {
      handleForceDisableUser();
    }
  }

  function handleForceDisableUser () {
    handleUpdateUser({
      disable: true,
      forceDisabled: true
    });
    handleCloseModal();
  }

  function handleForceTaskerRoleRemoval() {
    userDataToResubmit.forceTaskerRoleRemoval = true
    handleUpdateUser(userDataToResubmit);
    handleCloseModal();
  }

  return (
    <TemplateUser
      user={user}
      organizations={organizations}
      accessType={ADMIN_ROLE}
      breadcrumbs={breadcrumbs}
      verifications={verifications}
      isLoading={isLoading}
      onUpdateUser={handleUpdateUser}
      onUpdateVerification={handleUpdateVerification}
      onReissuePassword={handleReissuePassword}
      forceDisable={needsForceDisable}
      forceTaskerRoleRemoval={needsForceTaskerRoleRemoval}
      handleConfirmation={handleUpdateConfirmation}
      handleRejectConfirmation={handleCloseModal}
    />
  );
};

AdminUserPage.propTypes = {
  userId: PropTypes.string
};

export default AdminUserPage;
