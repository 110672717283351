import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { geoJson as GeoJson } from 'leaflet';

import { taskDefaults } from 'lib/tasks';
import { flattenObject } from 'lib/util';
import { useAccessRequests } from 'hooks';
import { ACCESS_SEQUENCE_STATUS_LOADING } from 'data/access-requests';
import { TaskCollectModes } from 'data/task-collect-modes';
import AccessRequestsBody from './AccessRequestsBody';
import AccessRequestsWrapper from './AccessRequestsWrapper';

const intervalPercent = 0.18;

const AccessRequests = ({
  geoJson = {},
  windowOpen,
  windowClose,
  imageWidth = taskDefaults.DEFAULT_IMAGE_WIDTH,
  imageLength = taskDefaults.DEFAULT_IMAGE_LENGTH,
  localTime,
  imagingMode,
  accessRequestOffNadirMin,
  accessRequestOffNadirMax,
  accessRequestAscending,
  accessRequestLookDirection,
  accessRequestOrbitalPlanes,
  isPoint,
  onAccessRequestsRefresh,
  shouldRefreshAccessTimes,
  taskRequestType,
  productCategory,
  refMap,
  refreshCounter,
  accesses: persistedAccesses
}) => {
  let imageWidthValue = imageWidth;
  let imageLengthValue = imageLength;
  // If user didn't specify imageWidth / imageLength, we need to
  // send the default values for /accessrequests footprints to work
  // Also make sure those defaults are set as long as no custom values are set
  if (!imageLength && !imageWidth) {
    imageWidthValue = 5000;
    if (imagingMode === TaskCollectModes.SPOTLIGHT) {
      imageLengthValue = 5000;
    } else if (imagingMode === TaskCollectModes.SLIDING_SPOTLIGHT) {
      imageLengthValue = 10000;
    } else if (imagingMode === TaskCollectModes.STRIPMAP) {
      imageLengthValue = 20000;
    }
  }

  const {
    accesses,
    message,
    status
  } = useAccessRequests({
    geoJson,
    windowOpen,
    windowClose,
    localTime,
    accessRequestOffNadirMin,
    accessRequestOffNadirMax,
    accessRequestAscending,
    accessRequestLookDirection,
    accessRequestOrbitalPlanes,
    imageWidth: imageWidthValue,
    imageLength: imageLengthValue,
    imagingMode,
    taskRequestType,
    refreshCounter
  });

  const isLoading = status === ACCESS_SEQUENCE_STATUS_LOADING;

  // Allow existing accesses to be passed in if already fetched
  const currentAccesses = persistedAccesses || accesses;

  // Map through the accesses to create a new position based on
  // the access index
  const accessIntervals = currentAccesses?.map((access, index) => {
    const position = intervalPercent * index;
    return {
      ...access,
      position
    };
  });

  const firstPassAccesses = currentAccesses.map((childNode) => {
    const localObject = {};
    for (const [key, value] of Object.entries(childNode)) {
      if (key === 'accessProperties') {
        flattenObject(value, localObject);
      }
      if (key !== 'accessProperties') {
        localObject[key] = value;
      }
    }
    return localObject;
  });

  /**
   * For now this just strips the trailing 'Z' from timestamps
   * @param {string} value Timestamp string
   * @returns {string} timestamp string with trailing 'Z' removed
   */
  function formatWindowTime (value) {
    if (!value || typeof value !== 'string') return;
    let returnValue = value;
    if (value.slice(-1).toUpperCase() === 'Z') {
      returnValue = value.slice(0, -1);
    }
    return returnValue;
  }

  const csvAccesses = firstPassAccesses.map((childNode) => {
    let localObject = {};
    for (const [key, value] of Object.entries(childNode)) {
      const newValue = Array.isArray(value) ? value.toString() : value;
      switch (key) {
        case 'center':
          localObject['Center WGS84'] = newValue;
          break;
        case 'centerEcef':
          localObject['Center ECEF'] = newValue;
          break;
        case 'spacecraftId':
          localObject['Spacecraft ID'] = newValue;
          break;
        case 'accessId':
          localObject['Access ID'] = newValue;
          break;
        case 'accessrequestId':
          localObject['Access Request ID'] = newValue;
          break;
        case 'windowOpen':
          localObject['Window Open (UTC)'] = newValue;
          break;
        case 'windowClose':
          localObject['Window Close (UTC)'] = newValue;
          break;
        case 'windowOpenLocal':
          localObject['Window Open (Local Target)'] = formatWindowTime(newValue);
          break;
        case 'windowCloseLocal':
          localObject['Window Close (Local Target)'] = formatWindowTime(newValue);
          break;
        case 'orbitalPlane':
          localObject['Orbital Plane'] = newValue;
          break;
        case 'ascdsc':
          localObject['Orbit State'] = newValue;
          break;
        case 'lookDirection':
          localObject['Look Direction'] = newValue;
          break;
        case 'localTime':
          localObject['Local Mean Time'] = newValue;
          break;
        case 'azimuthOpen':
          localObject['Az Open'] = newValue;
          break;
        case 'azimuthClose':
          localObject['Az Close'] = newValue;
          break;
        case 'elevationMin':
          localObject['Elev Min'] = newValue;
          break;
        case 'elevationMax':
          localObject['Elev Max'] = newValue;
          break;
        case 'offNadirMin':
          localObject['Off-Nadir Min'] = newValue;
          break;
        case 'offNadirMax':
          localObject['Off-Nadir Max'] = newValue;
          break;
        default:
      }
    }
    // Control order of fields for CSV, specifically "Orbital Plane" after "Window Close"
    const downloadOrder = {
      'Center WGS84': null,
      'Center ECEF': null,
      'Spacecraft ID': null,
      'Access ID': null,
      'Access Request ID': null,
      'Window Open (UTC)': null,
      'Window Close (UTC)': null,
      'Window Open (Local Target)': null,
      'Window Close (Local Target)': null,
      'Orbital Plane': null,
      'Orbit State': null
    };

    localObject = Object.assign(downloadOrder, localObject);
    return localObject;
  });

  function hoverAccess ({ id, geometry }, mouseAction) {
    if (!id || !geometry || !mouseAction) return;
    const poly = GeoJson(geometry, { name: 'access-polygon' });
    if (mouseAction === 'enter') {
      poly.addTo(refMap.current);
      refMap.current.fitBounds(poly.getBounds());
    } else {
      refMap.current.eachLayer(layer => {
        if (layer.options.name === 'access-polygon') {
          layer.remove();
        }
      });
    }
  }

  const enableTimeSelect = currentAccesses.length > 0 && currentAccesses.reduce(
    (accumulator, access) =>
      !accumulator ? accumulator : !!access.windowOpenLocal, // Should always be false if any item doesn't have a windowOpenLocal attribute. Unfortunately reduce doesn't have a great way to break early so this is probably the best way to go.
    true
  );

  const timeSelectOptions = [{ label: 'UTC', value: 'utc' }, { label: 'LOCAL', value: 'local' }];
  const [selectedTime, setSelectedTime] = useState('utc');
  const handleSelectedTimeChange = value => setSelectedTime(value);

  return (
    <AccessRequestsWrapper
      onAccessRequestsRefresh={isPoint && onAccessRequestsRefresh}
      shouldRefreshAccessTimes={shouldRefreshAccessTimes}
      message={message}
      isPoint={isPoint}
      csvAccesses={csvAccesses}
      enableTimeSelect={enableTimeSelect}
      selectedTime={selectedTime}
      onChange={handleSelectedTimeChange}
      timeSelectOptions={timeSelectOptions}
    >
      <AccessRequestsBody
        accesses={accessIntervals}
        message={message}
        isPoint={isPoint}
        isLoading={isLoading}
        hoverAccess={hoverAccess}
        csvAccesses={csvAccesses}
        displayUtc={selectedTime === 'utc'}
      />
    </AccessRequestsWrapper>
  );
};

AccessRequests.propTypes = {
  geoJson: PropTypes.object,
  windowOpen: PropTypes.number,
  windowClose: PropTypes.number,
  localTime: PropTypes.string,
  tier: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  accessRequestOffNadirMin: PropTypes.number,
  accessRequestOffNadirMax: PropTypes.number,
  accessRequestAscending: PropTypes.string,
  accessRequestLookDirection: PropTypes.string,
  accessRequestOrbitalPlanes: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  isPoint: PropTypes.bool,
  onAccessRequestsRefresh: PropTypes.func,
  shouldRefreshAccessTimes: PropTypes.bool,
  imageLength: PropTypes.number,
  imageWidth: PropTypes.number,
  imagingMode: PropTypes.string,
  refMap: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  taskRequestType: PropTypes.string,
  productCategory: PropTypes.string,
  refreshCounter: PropTypes.number,
  accesses: PropTypes.array
};

export default AccessRequests;
