import React from 'react';

const Logo = () => {
  return (
    <svg viewBox="0 0 158.77 218.98">
      <path
        className="st0"
        d="M158.33,39.39C158.33,16.22,135.16,0,135.16,0v1.65c0,0.43,0.14,0.85,0.41,1.19
        c2.41,3.1,14.65,19.5,14.65,33.08v21.24c0,1.07-0.87,1.94-1.94,1.94h-0.16c-0.81,0-1.54-0.51-1.82-1.27
        c-3.46-9.63-6.88-10.54-9.29-10.45c-1.04,0.04-1.86,0.9-1.86,1.94v10.58c0,0.89-0.59,1.66-1.45,1.87
        c-4.18,1.06-16.44,4.59-24.04,11.24c-5.58,4.89-15.06,15.06-20.86,31.28c-3.21,9-5.79,13.9-16.22,13.9H47.5
        c-0.26,0-0.52-0.06-0.76-0.16c-7.68-3.05-40.19,4.91-40.19,11.75c0,1.48,3.8,2.97,6.53,3.84c1.3,0.42,1.77,2.03,0.89,3.08
        c-2.47,2.9-5.89,8.03-1.77,18.22c0.09,0.23,0.14,0.48,0.14,0.73c0,10.82-0.11,11.24-9.38,15.38c-0.64,0.29-1.08,0.91-1.14,1.61
        c-3.02,34.7-1.43,38.93-1.11,44.54c0.06,1.02,0.91,1.81,1.93,1.81h6.99c1.48,0,2.38-1.57,1.69-2.88
        c-5.38-10.23-2.76-35.47,4.51-36.51c6.98-1,24.33,0,37.08-17.38c0.67,0.67,0.77,1.73,0.21,2.49c-8.36,11.39-17.22,14.89-20.74,15.78
        c-0.87,0.22-1.48,0.99-1.48,1.88v0.31c0,0.26,0.05,0.51,0.15,0.75l14.41,34.37c0.3,0.72,1.01,1.19,1.79,1.19h7.64
        c1.67,0,2.56-1.97,1.45-3.22c-6.66-7.46-16.49-24.44-12.17-31.8c0.33-0.57,0.95-0.89,1.61-0.92c10.72-0.48,19.36-8.88,24-18.55
        c0.33-0.68,1-1.12,1.75-1.12c3.97,0.03,16.14,0.18,16.14,1.15c2.32,3.48,2.32,9.27-1.16,18.54c-8.7,20.67-5.15,25-4.68,34.08
        c0.05,1.03,0.9,1.84,1.93,1.84h7.71c1.43,0,2.34-1.49,1.74-2.78c-8.22-17.65,1.44-28.08,7.53-33.05c0.67-0.54,3.15-2.41,3.15-2.41
        s0,3.12,0.01,3.89c0.06,9.3,0.54,23.06,4.15,33.1c0.27,0.76,1.01,1.25,1.81,1.25h10.04c1.53,0,2.44-1.67,1.63-2.97
        c-6.97-11.24-11.25-30.88-4.9-45.7c6.58-15.36,12.74-20.86,13.9-30.12c1.32-10.6-0.74-28.98-1.16-33.6
        c-1.16-12.74,3.48-30.12,5.79-30.12c1.75,0,0.85,17.26,0.31,25.65c-0.16,2.43,1.77,4.47,4.2,4.47h2.52c0.68,0,1.3-0.35,1.65-0.92
        c2.03-3.33,8.88-14.66,9.86-17.62c1.16-3.48,3.48-6.95,4.63-15.06C159.33,66.02,158.33,44.03,158.33,39.39z M145.65,75.46
        c-1.32-0.01-2.39-1.08-2.38-2.4c0-1.32,1.08-2.39,2.4-2.38c1.32,0.01,2.39,1.08,2.38,2.4C148.04,74.4,146.97,75.46,145.65,75.46z"
      />
    </svg>
  );
};

export default Logo;
