import { sortByDateKey } from 'lib/util';

class TaskCollect {
  constructor (data) {
    Object.assign(this, data);
  }

  get lastStatus () {
    const history = this.collectStatusHistory;

    if (!Array.isArray(history)) return undefined;

    const sorted = sortByDateKey(history, 'time');
    const length = sorted.length;

    return sorted[length - 1];
  }
}

export default TaskCollect;
