import { useEffect, useState } from 'react';

/**
 * hook for handling Leaflet editable events
 *
 * @param {object} [param0] optional config and event handlers
 * @param {function} [param0.editableHandler] handles an editable event
 * @param {object} [param0.delay=500] delay the editable event in milliseconds (default 500ms)
 * @returns {{ editedLayerEvent: L.LayerEvent; handleEditing: function; }}
 */
function useEditableHandler ({
  editableHandler,
  delay = 500
} = {}) {
  const [editedLayerEvent, setEditedLayerEvent] = useState({});

  /**
   * @param {{ layer: object, target: object, type: string }} layerEvent Fired as soon as any change is made to the feature geometry.
   */
  function handleEditing (layerEvent) {
    setEditedLayerEvent(layerEvent);
  }

  // subscribes to editedLayerEvent, fires handler after timeout delay
  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      const {
        layer,
        target: leafletElement
      } = editedLayerEvent;
      if (!leafletElement) {
        return;
      }
      if (typeof editableHandler === 'function') {
        await editableHandler(layer, leafletElement);
      }
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [editedLayerEvent]);

  return {
    editedLayerEvent,
    handleEditing
  };
}

export default useEditableHandler;
