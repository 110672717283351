import { useMappedState } from 'redux-react-hook';

import Collection from 'models/collection';

export default function useItems () {
  const mapState = ({ items }) => items;
  const items = useMappedState(mapState);

  const collections = items.collections.map(
    (collection) => new Collection(collection)
  );

  return {
    ...items,
    collections
  };
}
