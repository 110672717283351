import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Layout as FoggLayout } from 'fogg/ui';

import 'assets/stylesheets/application.scss';

import { useSiteMetadata } from 'hooks';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';

const AppLayout = ({
  children,
  className,
  notice,
  navigation = true,
  footer = true
}) => {
  const { name } = useSiteMetadata();

  return (
    <FoggLayout notice={notice}>
      <Helmet titleTemplate={`%s - ${name}`} />
      <div
        className={`wrapper ${className || ''}`}
        data-footer={footer}
        data-navigation={navigation}
      >
        {navigation && <Navigation />}

        <main>{children}</main>

        {footer && <Footer />}
      </div>
    </FoggLayout>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  notice: PropTypes.object,
  navigation: PropTypes.bool,
  footer: PropTypes.bool
};

export default AppLayout;
