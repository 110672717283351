import {
  ADD_TASK,
  CLEAR_NEW_TASK,
  UPDATE_TASK,
  UPDATE_TASKS,
  UPDATE_TASKS_PAGED,
  UPDATE_TASKS_ACTION_STATE,
  UPDATE_TASKS_LIMIT,
  UPDATE_TASKS_PAGE,
  UPDATE_TASKS_SORT,
  UPDATE_TASKS_FILTERS,
  UPDATE_TASKS_COLUMNS,
  UPDATE_SEARCH_TASKS,
  EXPORT_TASK_TABLE_CSV,
  UPDATE_CONFLICTED_TASK
} from 'data/action-types';
import { CONTENT_TYPE_JSON } from 'data/content-types';
import { TASKING_REQUEST_ID } from 'data/property-names';
import {
  ROUTE_API_TASK,
  ROUTE_API_ACCESS_REQUESTS,
  ROUTE_API_ACCESS_REQUEST_BY_ID,
  ROUTE_API_ACCESSES_BY_REQUEST_ID,
  ROUTE_API_ACCESS_REQUEST_TILES,
  ROUTE_API_TASK_FROM_COLLECT,
  ROUTE_API_TASKS_SEARCH,
  ROUTE_API_EXPORT_TASKS_CSV
} from 'data/route-names';
import { mapTaskTableField, taskFiltersToAPI } from 'lib/tasks';
import { TASKS_SCOPE } from 'data/scopes';
import { TaskRequestTypes } from 'data/task-request-types';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';
import { routePathByName } from 'lib/routes';
import { constructRequestActionManager } from 'commonLib/src/lib/actions';

/**
 * setTasksActionState
 * @description Updates the tasks store's status
 */

export function setTasksActionState (status) {
  return {
    type: UPDATE_TASKS_ACTION_STATE,
    data: status
  };
}

const createDispatchRequestAction =
  constructRequestActionManager(setTasksActionState);

/**
 * fetchTasksByUserId
 * @description Fetches the tasks for a given user
 * @param {string} userId ID of the user
 */

export const fetchTasksByUserId = (userId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksByUserId',
      scope: TASKS_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_API_TASK)}?customerId=${userId}`,
        method: 'fetch',
        headers: {
          'Content-Type': CONTENT_TYPE_JSON
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateTasks(data));

    return data;
  };
};

/**
 * fetchTasksByTaskIds
 * @param {Array} taskIds Array of task ID strings to search for
 * @param {boolean} [includeRepeating=false] Include tasks spawned from repeat requests
 * @returns {Array} Array of tasks
 */
export const fetchTasksByTaskIds = (taskIds, includeRepeating = false) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksByTaskIds',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASKS_SEARCH),
        method: 'post',
        headers: {
          'Content-Type': CONTENT_TYPE_JSON
        },
        data: {
          filter: {
            taskIds,
            includeRepeatingTasks: includeRepeating
          }
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: { results = [] } = {} } = request || {};

    dispatch(updateSearchTasks(results));

    return results;
  };
};

/**
 * searchTasksByUserId
 * @description Filter based task search
 * @param {string} userId ID of the user
 * @param {object} paging pagination page & limit
 * @param {object} [filters={}] e.g. { filterName: ['value'] }
 * @param {object} [sortObj = {}] e.g. { sort: 'status', order: 'asc' }
 * @param {AbortController} [controller = AbortController] AbortController signal to cancel stale requests
 */

export const searchTasksByUserId = (
  userId,
  { page = 1, limit = DEFAULT_PAGING_LIMIT },
  filters = {},
  sortObj = {},
  controller
) => {
  // Convert UI filters format & naming to API format
  const filterPayload = taskFiltersToAPI(filters, 'single', userId) || {};
  const { sort, order } = sortObj;

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'searchTasksByUserId',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASKS_SEARCH, {
          params: {
            page,
            limit,
            sort: mapTaskTableField(sort),
            order: order
          }
        }),
        data: {
          filter: filterPayload
        },
        method: 'post',
        signal: controller?.signal,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request || {};

    const { results, currentPage, totalPages } = data;

    dispatch(updateTasksPaged(data));

    return { results, currentPage, totalPages };
  };
};

/**
 * Fetch action for a csv representation of a user's tasking request data
 *
 * @param {string} userId
 * @param {object} [searchFilters={}] an object of search filters in key value shape per filter, e.g. { status: '', collectMods: [], ... }
 * @param {{ sort: string, order: 'asc'|'desc' }} [sortObj={}] sort and order object to pass as parameters to the download endpoint
 */
export const fetchTasksCsvByUserId = (userId, searchFilters = {}, sortObj = {}) => {
  return async (dispatch, getState) => {
    const { sort, order } = sortObj;
    // Convert UI filters format & naming to API format
    const filterPayload = taskFiltersToAPI(searchFilters, 'single', userId) || {};
    const dispatchAction = createDispatchRequestAction({
      name: fetchTasksCsvByUserId.name,
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_EXPORT_TASKS_CSV, {
          params: { sort: mapTaskTableField(sort), order }
        }),
        data: {
          filter: filterPayload
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });
    const request = await dispatchAction(dispatch, getState);
    const { data } = request;
    return data;
  };
};

export function exportTaskTableCsv () {
  return {
    type: EXPORT_TASK_TABLE_CSV
  };
}

/**
 * updateTasksPage
 * @description Updates current pagination page for /tasks/paged requests
 */

export function updateTasksPage (page) {
  return {
    type: UPDATE_TASKS_PAGE,
    data: page
  };
}

/**
 * updateTasksPagedLimit
 * @description Updates # of items per page for paginated /tasks/paged requests
 */

export function updateTasksPagedLimit (limit) {
  return {
    type: UPDATE_TASKS_LIMIT,
    data: limit
  };
}

/**
 * updateTasksSort
 * @description Updates the active sort column and order
 * @param {object} sort = {} e.g. { sort: 'windowOpen', order: 'asc' }
 */

export function updateTasksSort (sort) {
  return {
    type: UPDATE_TASKS_SORT,
    data: sort
  };
}

/**
 * fetchTaskByTaskId
 * @description Fetch a task by it's ID
 * @param {string} taskId ID of the task
 */

export const fetchTaskByTaskId = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTaskByTaskId',
      scope: TASKS_SCOPE,
      request: {
        url: `${routePathByName('apiTask')}/${taskId}`,
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateTasks([data]));

    return data;
  };
};

export const fetchTaskAndConflictedTaskByIDs = (taskId, conflictId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTaskAndConflictedTaskByIDs',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASKS_SEARCH),
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: {
          filter: {
            taskIds: [taskId, conflictId],
            includeRepeatingTasks: true
          }
        }
      }
    });
    const res = await dispatchAction(dispatch, getState);
    const { data: { results = [] } = {} } = res || {};
    const mainTask = results.find((task) => task.properties[TASKING_REQUEST_ID] === taskId);
    const subTask = results.find((task) => task.properties[TASKING_REQUEST_ID] === conflictId);

    await dispatch(updateTasks([mainTask]));
    await dispatch(updateConflictedTask(subTask));

    return results;
  };
};

const TASK_POST_PROPERTIES = [
  'taskingrequestName',
  'taskingrequestDescription',
  'orgId',
  'userId',
  'windowOpen',
  'windowClose',
  'collectionTier',
  'collectConstraints',
  'preApproval',
  'productCategory',
  'archiveHoldback',
  'processingConfig',
  'customAttribute1',
  'customAttribute2'
];

const TASK_POST_COLLECT_CONSTRAINTS = [
  'collectMode',
  'localTime',
  'orbitalPlanes',
  'ascDsc',
  'lookDirection',
  'targetHeight',
  'azrMin',
  'azrMax',
  'grrMin',
  'grrMax',
  'offNadirMin',
  'offNadirMax',
  'imageWidth',
  'imageLength',
  'numLooks',
  'polarization',
  'mode',
  'elevationMin',
  'elevationMax',
  'squint',
  'radarParametersBandwidth',
  'radarParametersCenterFrequency',
  'radarParametersPrf',
  'srrMin',
  'srrMax'
];

const REPEAT_REQUEST_POST_PROPERTIES = [
  'repeatrequestName',
  'repeatrequestDescription',
  'orgId',
  'userId',
  'collectionTier',
  'collectConstraints',
  'preApproval',
  'productCategory',
  'archiveHoldback',
  'processingConfig',
  'customAttribute1',
  'customAttribute2'
];

const REPEAT_REQUEST_POST_REPETITION_PROPERTIES = [
  'repeatStart',
  'repeatEnd',
  'repetitionInterval',
  'repetitionCount',
  'maintainSceneFraming',
  'lookAngleTolerance'
];

const CUSTOM_ONLY_COLLECT_CONSTRAINTS = [
  'grrMin',
  'grrMax',
  'azrMin',
  'azrMax',
  'imageWidth',
  'imageLength',
  'offNadirMin',
  'offNadirMax'
];

/**
 * postTaskRequest
 * @description Creates a new task given the provided data
 * @param {object} data The object data of the task
 */
export const postTaskRequest = (data = {}) => {
  return async (dispatch, getState) => {
    const { contractId, type, geometry, properties } = data;
    const { taskRequestType = TaskRequestTypes.STANDARD, productCategory = 'standard' } = properties;
    const isRepeatTask = taskRequestType === TaskRequestTypes.REPEAT;
    const isOosmTask = taskRequestType === TaskRequestTypes.OOSM;
    const isCustomTask = productCategory === 'custom';

    const taskData = {
      contractId,
      type,
      geometry,
      properties: {}
    };

    // Convert analytics selection > PCM processingConfig.productTypes
    if (properties.analytics && properties.analytics.length) {
      taskData.properties.processingConfig = {
        productTypes: properties.analytics
      };
    }

    // Limit the properties to only the ones we want to actively send
    const taskProperties = isRepeatTask
      ? REPEAT_REQUEST_POST_PROPERTIES
      : TASK_POST_PROPERTIES;
    taskProperties.forEach((property) => {
      if (!properties[property]) return;
      if (property === 'collectConstraints') {
        taskData.properties[property] = {};
      } else {
        taskData.properties[property] = properties[property];
      }
    });

    if (!taskData.properties.collectConstraints) {
      taskData.properties.collectConstraints = {};
    }

    if (properties.collectConstraints) {
      TASK_POST_COLLECT_CONSTRAINTS.forEach((property) => {
        if (!properties.collectConstraints[property]) return;

        if (!isCustomTask && CUSTOM_ONLY_COLLECT_CONSTRAINTS.includes(property)) return;

        taskData.properties.collectConstraints[property] = properties.collectConstraints[property];
      });
    }

    // repeat task specific properties
    if (isRepeatTask) {
      if (!taskData.properties.repetitionProperties) {
        taskData.properties.repetitionProperties = {};
      }

      if (properties.repetitionProperties) {
        REPEAT_REQUEST_POST_REPETITION_PROPERTIES.forEach((property) => {
          if (!properties.repetitionProperties[property]) return;
          taskData.properties.repetitionProperties[property] =
            properties.repetitionProperties[property];
        });
      }
    }

    let taskingAPI = 'apiTask';
    if (isOosmTask) {
      taskData.properties.preApproval = false;
      taskingAPI = 'apiTaskOOSM';
    } else if (isRepeatTask) {
      taskingAPI = 'apiRepeatRequest';
    }

    // Don't include "mode" in payload if value is 'void'
    if (taskData.properties.collectConstraints.mode === 'void') {
      taskData.properties.collectConstraints.mode = undefined;
    }

    const dispatchAction = createDispatchRequestAction({
      name: 'postTaskRequest',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(taskingAPI),
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: taskData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(addTask(responseData));

    return responseData;
  };
};

/**
 * updateTaskById
 * @description Updates a task's properties given the ID
 * @param {string} taskId ID of the task
 * @param {object} data Data to update on task
 */

export const updateTaskById = (taskId, dataObj = {}) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateTaskById',
      scope: TASKS_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_API_TASK)}/${taskId}`,
        method: 'patch',
        data: dataObj
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    return data;
  };
};

/**
 * updateTaskStatusById
 * @description Updates the status of a task given the ID
 * @param {string} taskId ID of the task
 * @param {object} data Data to update on task status
 */

export const updateTaskStatusById = (taskId, { status } = {}) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateTaskStatusById',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName('apiTasksStatus', {
          wildcard: [taskId]
        }),
        method: 'patch',
        data: {
          status
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(fetchTaskByTaskId(taskId));

    return data;
  };
};

/**
 * fetchTasksCollectsById
 * @description Gets a given task's collects
 * @param {string} taskId ID of the task
 */

export const fetchTasksCollectsById = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksCollectsById',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName('apiTaskCollects', {
          wildcard: [taskId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(
      updateTask({
        properties: {
          [TASKING_REQUEST_ID]: taskId,
          collects: data
        }
      })
    );

    return data;
  };
};

/**
 * fetchTaskByCollectId
 * @description Gets a given task's collects
 * @param {string} collect ID
 */

export const fetchTaskByCollectId = (collectId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTaskByCollectId',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASK_FROM_COLLECT, {
          wildcard: [collectId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};
    return data;
  };
};

/**
 * fetchTasksTilesById
 * @description Gets a given task's tiles
 * @param {string} taskId ID of the task
 */

export const fetchTasksTilesById = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksTilesById',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName('apiTaskTiles', {
          wildcard: [taskId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(
      updateTask({
        properties: {
          [TASKING_REQUEST_ID]: taskId,
          tiles: data
        }
      })
    );

    return data;
  };
};

// Mission Awareness & Access Requests
/**
 * createAccessRequest
 */

export const createAccessRequest = ({ geoJson } = {}) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'createAccessRequest',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_ACCESS_REQUESTS),
        method: 'post',
        data: geoJson
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};
    dispatch(
      updateTask({
        accessRequests: data
      })
    );
    return data;
  };
};

/**
 * getAccessRequestById
 * @param {object} props
 * @param {string} props.id Access Request ID
 * @param {AbortController} [props.controller = AbortController] AbortController signal to cancel stale requests
 */

export const getAccessRequestById = ({ id } = {}, controller) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'getAccessRequestById',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_ACCESS_REQUEST_BY_ID, {
          wildcard: [id]
        }),
        method: 'fetch',
        signal: controller?.signal
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};
    return data;
  };
};

/**
 * getAccessesByRequestId
 * @param {object} props
 * @param {string} props.id Access Request ID
 * @param {AbortController} [props.controller = AbortController] AbortController signal to cancel stale requests
 */

export const getAccessesByRequestId = ({ id } = {}, controller) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'getAccessByRequestId',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_ACCESSES_BY_REQUEST_ID, {
          wildcard: [id]
        }),
        method: 'fetch',
        signal: controller?.signal
      }
    });

    const request = await dispatchAction(dispatch, getState);
    return request;
  };
};

/**
 * fetchAccessTilesById
 * @description Gets a given an Access Request's tiles
 * @param {string} accessRequestId ID of the task
 * @param {AbortController} [controller = AbortController] AbortController signal to cancel stale requests
 */

export const fetchAccessTilesById = (accessRequestId, controller) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchAccessTilesById',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_ACCESS_REQUEST_TILES, {
          wildcard: [accessRequestId]
        }),
        method: 'fetch',
        signal: controller?.signal
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    return data;
  };
};

/******************
 * STATE UPDATERS *
 ******************/

/**
 * storeNewTask
 * @description
 */

export const storeNewTask = (data) => {
  return addTask(data);
};

/**
 * clearNewTask
 * @description
 */

export const clearNewTask = () => {
  return {
    type: CLEAR_NEW_TASK,
    data: {}
  };
};

/**
 * updateTasks
 * @description Set the task results
 */

export const updateTasks = (data) => {
  return {
    type: UPDATE_TASKS,
    data
  };
};

/**
 * updateTasksPaged
 * @description Set the task results in paged format
 */
export const updateTasksPaged = (data) => {
  return {
    type: UPDATE_TASKS_PAGED,
    data
  };
};

/**
 * updateTasksFilters
 * @description Set the current active tasks table filters
 */
export const updateTasksFilters = (data) => {
  return {
    type: UPDATE_TASKS_FILTERS,
    data
  };
};

/**
 * updateTasksColumns
 * @description Set the current active task table columns & column order
 */
export const updateTasksColumns = (data) => {
  return {
    type: UPDATE_TASKS_COLUMNS,
    data
  };
};

/**
 * updateSearchTasks
 * @description Set the task search results
 */
export const updateSearchTasks = (data) => {
  return {
    type: UPDATE_SEARCH_TASKS,
    data
  };
};

/**
 * updateTask
 * @description Update an individual task
 */

export const updateTask = (data) => {
  return {
    type: UPDATE_TASK,
    data
  };
};

/**
 * addTask
 * @description Add a task to the store
 */

export const addTask = (data) => {
  return {
    type: ADD_TASK,
    data
  };
};

/**
 * updateConflictedTask
 * @description Update conflicted task stats
 */
export const updateConflictedTask = (data) => {
  return {
    type: UPDATE_CONFLICTED_TASK,
    data
  };
};
