import { constructRequestActionManager } from 'commonLib/src/lib/actions';
import { CONTRACTS_SCOPE } from 'data/scopes';
import { routePathByName } from 'lib/routes';
import { ROUTE_API_CONTRACTS } from 'data/route-names';
import Contract from 'commonLib/src/models/salesforceContract';

/**
 * Update's the contracts store's status
 * @param  {string} status - status of the contracts store
 */
export function setContractsActionState (status) {
  return {
    type: 'UPDATE_CONTRACTS_ACTION_STATE',
    data: status
  };
}

const createDispatchRequestAction =
  constructRequestActionManager(setContractsActionState);

/**
 * @param {object} props
 * @param  {boolean} [props.includeManagedOrgs=false]
 */
export const fetchContracts = ({
  includeManagedOrgs = false
} = {}) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchContracts',
      scope: CONTRACTS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_CONTRACTS, {
          params: {
            includeManagedOrgs
          }
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request;

    const contracts = data.map((contract) => new Contract(contract).data());

    dispatch(updateContracts(contracts));

    return data;
  };
};

/******************
 * STATE UPDATERS *
 ******************/

/**
 * updateContracts
 * @param  {object} data - contracts data to keep in the contracts
 */
export const updateContracts = (data) => ({
  type: 'UPDATE_CONTRACTS',
  data
});
