import clone from 'clone';
import deepmerge from 'deepmerge';

import { TASKING_REQUEST_ID, DEFAULT_PAGING_LIMIT, updateSingleEntityByKey } from 'commonLib';
import { ORG_REPEAT_REQUESTS_TABLE_COLUMNS, ORG_TASKS_TABLE_COLUMNS } from '../../lib/tasks';
import {
  UPDATE_ORG_TASK,
  UPDATE_ORG_TASKS,
  UPDATE_ORG_TASKS_PAGED,
  UPDATE_ORG_TASKS_COLUMNS,
  UPDATE_ORG_REPEAT_REQUESTS,
  UPDATE_ORG_REPEAT_REQUESTS_PAGED,
  UPDATE_ORG_REPEAT_REQUESTS_COLUMNS
} from '../../data/action-types';

const defaultState = {
  users: [],
  organization: {},
  actions: {},
  orders: [],
  tasks: [],
  totalPages: 1,
  limit: DEFAULT_PAGING_LIMIT,
  repeatRequests: [],
  s3push: [],
  s3bucket: [],
  columns: ORG_TASKS_TABLE_COLUMNS,
  repeatColumns: ORG_REPEAT_REQUESTS_TABLE_COLUMNS
};

const org = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);

  switch (type) {
    case 'UPDATE_ORGANIZATION': {
      return {
        ...workingState,
        organization: workingData
      };
    }

    case UPDATE_ORG_TASK: {
      workingState.tasks = updateSingleEntityByKey({
        key: `properties.${TASKING_REQUEST_ID}`,
        entity: workingData,
        previousEntities: workingState.tasks
      });
      return {
        ...workingState,
        tasks: workingState.tasks
      };
    }

    case UPDATE_ORG_TASKS: {
      return {
        ...workingState,
        tasks: [...workingData]
      };
    }

    case UPDATE_ORG_TASKS_PAGED:
      if (!Array.isArray(workingData.results)) {
        throw new Error('Invalid data passed to UPDATE_TASKS_PAGED');
      }
      return {
        ...workingState,
        tasks: workingData.results,
        currentPage: workingData.currentPage,
        totalPages: workingData.totalPages
      };

    case UPDATE_ORG_TASKS_COLUMNS:
      return {
        ...workingState,
        columns: workingData
      };

    case UPDATE_ORG_REPEAT_REQUESTS: {
      return {
        ...workingState,
        repeatRequests: [...workingData]
      };
    }

    case UPDATE_ORG_REPEAT_REQUESTS_PAGED: {
      if (!Array.isArray(workingData.results)) {
        throw new Error('Invalid data passed to UPDATE_ORG_REPEAT_REQUESTS_PAGED');
      }
      return {
        ...workingState,
        repeatRequests: workingData.results,
        currentPage: workingData.currentPage,
        totalPages: workingData.totalPages
      };
    }

    case UPDATE_ORG_REPEAT_REQUESTS_COLUMNS: {
      return {
        ...workingState,
        repeatColumns: workingData
      };
    }

    case 'UPDATE_ORG_ORDERS': {
      return {
        ...workingState,
        orders: [...workingData]
      };
    }
    case 'UPDATE_ORG_USERS': {
      let users = [...workingState.users];

      const updatedUserIndex = users.findIndex(
        (user) => user.id === workingData.id
      );

      const updatedUser = users[updatedUserIndex];

      //  if we found an index, it's not an array and we are updating a single user
      if (updatedUserIndex !== -1) {
        users[updatedUserIndex] = {
          ...users[updatedUserIndex],
          ...workingData
        };
      } else if (!Array.isArray(workingData)) {
        users = [workingData];
      } else {
        users = [...workingData];
      }

      if (updatedUser && typeof updatedUser.organizationId !== 'string') {
        users.splice(updatedUserIndex, 1);
      }

      return {
        ...workingState,
        users
      };
    }

    case 'ADD_ORG_USER': {
      return {
        ...workingState,
        users: [...(state.users || []), workingData]
      };
    }

    case 'UPDATE_ORG_ACTION_STATE': {
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    }

    case 'GET_ORG_S3_PUSH': {
      return {
        ...workingState,
        s3push: [workingData]
      };
    }

    case 'FETCH_ORG_S3_BUCKET': {
      return {
        ...workingState,
        s3bucket: [...workingData]
      };
    }

    default:
      return workingState;
  }
};

export default org;
