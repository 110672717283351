import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';

import { triggerNotice, putPasswords } from 'state/actions';
import { useLocation, useUser } from 'hooks';
import { routePathByName, redirectTo } from 'lib/routes';
import { obfuscateEmail } from 'lib/util';
import { logError } from 'lib/logger';

import User from 'models/user';

import Layout from 'components/Layout';
import Container from 'components/Container';
import FormChangePassword from 'components/FormChangePassword';

const UserChangePasswordPage = () => {
  const dispatch = useDispatch();

  const { user: userData } = useUser();
  const user = new User(userData); // TODO: useUser should return a User

  const { queryParams } = useLocation() || {};
  const { confirmationCode, email } = queryParams || {};
  const paramsAreValid = email && confirmationCode;

  useEffect(() => {
    if (!paramsAreValid) {
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try resetting your password again'
        })
      );
      redirectTo(routePathByName('userLogin'));
    }
  }, [dispatch, email, confirmationCode, paramsAreValid]);

  if (!paramsAreValid) return null;

  const links = [];

  if (!user.isLoggedIn()) {
    links.push({
      label: 'Log In to Your Account',
      to: routePathByName('userLogin')
    });
  }

  function handleOnSubmit (fields) {
    const data = {
      confirmationCode,
      email,
      password: fields.password && fields.password.value,
      passwordConfirmation:
        fields.passwordConfirmation && fields.passwordConfirmation.value
    };

    try {
      dispatch(putPasswords(data));
    } catch (e) {
      logError(e.message, fields);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try again'
        })
      );
      return;
    }

    dispatch(
      triggerNotice({
        type: 'success',
        weight: 'bold',
        align: 'center',
        text: 'Password successfully reset! Please login with your new password'
      })
    );

    redirectTo(routePathByName('userLogout'));
  }

  return (
    <Layout navigation={user.isLoggedIn()}>
      <Helmet bodyAttributes={{ class: 'page-user page-user-change-password' }}>
        <title>Change Password</title>
      </Helmet>
      <Container className="content" type="centered">
        <h1>Change Password</h1>

        {!user.isLoggedIn() && (
          <p className="text-center">
            {/* copy taken from cognito page. using regex to obfuscate the email address */}
            {`Please enter new password for ${obfuscateEmail(email)}.`}
          </p>
        )}

        <FormChangePassword links={links} onSubmit={handleOnSubmit} />
      </Container>
    </Layout>
  );
};

export default UserChangePasswordPage;
