import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  FormRow,
  FormInput,
  InputButton,
  InputRangeExtended,
  ToggleButtonGroup
} from 'fogg/ui';
import ReactDatetime from 'react-datetime';
import InputRange from 'react-input-range';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { TaskRequestTypes } from 'data/task-request-types';
import { RepeatRequestEndTypes } from 'data/repeat-request-end-types';
import { RepeatCycleTypes } from 'data/repeat-cycle-types';
import { TaskCollectionTiers } from 'data/task-collection-tiers';
import {
  taskDefaults,
  sliderValuesAndRange,
  THIRTY_DAYS_MS,
  getAvailableAnalyticsCollectModes,
  getLookAngleRange,
  getLookAngleDefaults,
  getGRRRange,
  getGRRDefaults,
  getACDProductCategories
} from 'lib/tasks';
import { fieldByName } from 'lib/fields';
import { chompFloat } from 'lib/util';
import { useUser, useTaskContext } from 'hooks';
import { CAPELLA_SUPPORT_URL } from 'commonLib/src/data/route-names';

import Tooltip from './Tooltip';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';
import { OptionsPanel } from 'commonLib';

const FormCreateTaskRepeat = ({
  primaryTaskFields,
  children,
  footerChildren,
  onSubmit,
  onCancel,
  geoJson = {},
  enableCollectTimeSelect = false
}) => {
  const { taskState, updateTaskState } = useTaskContext();

  // Verify which analytics the user's org has enabled
  const {
    user: {
      organization = {}
    } = {}
  } = useUser();

  const {
    permittedCollectionTiers = {}
  } = organization;

  const { repeatRequest = [] } = permittedCollectionTiers;

  const {
    collectConstraints: {
      azrMax = taskDefaults.DEFAULT_AZR_MAX,
      azrMin = taskDefaults.DEFAULT_AZR_MIN,
      grrMax = taskDefaults.DEFAULT_GRR_MAX,
      grrMin = taskDefaults.DEFAULT_GRR_MIN,
      imageLength = taskDefaults.DEFAULT_IMAGE_LENGTH,
      imageWidth = taskDefaults.DEFAULT_IMAGE_WIDTH,
      numLooks = taskDefaults.DEFAULT_NUM_LOOKS_SPOTLIGHT,
      offNadirMin = taskDefaults.DEFAULT_OFF_NADIR_MIN,
      offNadirMax = taskDefaults.DEFAULT_OFF_NADIR_MAX,
      polarization,
      targetHeight = null,
      localTime = taskDefaults.DEFAULT_LOCAL_TIME
    } = {},
    repetitionProperties: {
      lookAngleTolerance,
      maintainSceneFraming = false,
      repeatCycle = taskDefaults.repeat.DEFAULT_REPEAT_CYCLE_VALUE,
      repetitionInterval = 2,
      repeatStart,
      repeatEnd,
      repetitionCount = null,
      repetitionEndType = taskDefaults.repeat
        .DEFAULT_REPEAT_TASK_END_TYPE_VALUE
    } = {},
    collectMode = taskDefaults.DEFAULT_COLLECT_MODE,
    customCloseDate = false,
    ascDsc = '',
    lookDirection = '',
    orbitalPlanes,
    preApproval = false,
    productCategory = taskDefaults.DEFAULT_PRODUCT_CATEGORY,
    archiveHoldback = null,
    analytics,
    isRetask = false,
    customAttribute1,
    customAttribute2
  } = taskState;

  const collectionTier = taskState.collectionTier ||
        taskDefaults.repeat.DEFAULT_REPEAT_TASK_COLLECTION_TIER;

  const showCustomSize =
    collectMode !== 'sliding_spotlight' && collectMode !== 'spotlight';
  const numLooksHidden = collectMode === 'stripmap';
  const imageLengthDisabled = collectMode === 'spotlight';
  const imageWidthDisabled = collectMode === 'spotLight';
  const showCategoryOptions = productCategory === 'custom';
  const showTargetHeight = targetHeight !== null;

  const returnOrbitalPlanesSelection = (selections = []) => {
    if (selections?.length) {
      const array = taskDefaults.AVAILABLE_ORBITAL_PLANES.filter((option) => {
        const { value } = option;
        return selections.includes(value);
      });
      return array;
    } else return [];
  };

  const activeAnalytic = analytics && analytics.length ? analytics[0] : undefined;
  const isACDSelected = activeAnalytic === 'ACD';
  const showLookAngleRange = showCategoryOptions && !isACDSelected && !isRetask;
  const showAzimuthResolution = showCategoryOptions && !isACDSelected;
  const showGroundRangeResolution = showCategoryOptions && !isACDSelected;

  // Rely on user org's permitted task tiers
  const taskingTierOptions = taskDefaults.repeat.AVAILABLE_REPEAT_TASK_COLLECTION_TIERS.filter(
    ({ value }) => repeatRequest.includes(value)
  );

  // Show the Analytics options enabled both, globally, and per-organization
  const flags = useFlags();
  const analyticsOptions = AnalyticProducts.Types
    .filter(o => o.orgEnabledFlagId === undefined || organization[o.orgEnabledFlagId])
    .filter(o => o.globalEnabledFlagId === undefined || flags[o.globalEnabledFlagId]);

  /**
   * gets repeatEnd date based on existing state properties: repetitionEndType, customCloseDate
   * @param {string} [endType=repetitionEndType] - provide a user selected end type or use local state by default
   * @param {number} [tierDays=taskingTier] - provide a user selected tier or use local state by default
   * @returns {number|null}
   */
  const getRepeatEndDate = ({
    endType = repetitionEndType
  } = {}) => {
    if (endType && endType === RepeatRequestEndTypes.DATE) {
      if (customCloseDate) {
        if (repeatEnd) {
          return repeatEnd;
        } else {
          // customCloseDate was set in standard task before switching to repeat
          // So set a default of 30 days from repeatStart
          const thirtyDays = new Date(repeatStart + THIRTY_DAYS_MS);
          return thirtyDays;
        }
      }

      // if end date was not explicitly set, we default to 1 month out from start date
      const startDate = new Date(parseInt(repeatStart));
      const endDate = new Date(startDate);
      endDate.setUTCMonth(endDate.getMonth() + 1);
      return new Date(endDate).valueOf();
    }

    return null;
  };

  /**
   * input ref and useEffect keeps the style of the floating number element for a slider updated depending on on it's position
   */
  const inputRef = useRef();
  useEffect(() => {
    if (inputRef && inputRef.current) {
      const inputBox = inputRef.current;
      const rangeValue = inputBox.querySelector('#rangeValue');
      localStorage.setItem('rangeFloatStyle', rangeValue.style.left);
      rangeValue.style.left = localStorage.getItem('rangeFloatStyle');
      const timer = setTimeout(() => {
        rangeValue.style.left = localStorage.getItem('rangeFloatStyle');
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [targetHeight]);

  /**
   * Event handlers
   */

  function handleOnWindowOpenChange ({ target = {} } = {}) {
    const { value } = target;

    updateTaskState({
      repetitionProperties: {
        repeatStart: parseInt(value, 10),
        repeatEnd: getRepeatEndDate()
      },
      shouldRefreshAccessTimes: true
    });
  }

  function handleOnWindowCloseChange ({ target = {} } = {}) {
    const { value } = target;

    if (value) {
      updateTaskState({
        repetitionProperties: {
          repeatEnd: parseInt(value, 10)
        },
        shouldRefreshAccessTimes: true,
        customCloseDate: true
      });
    } else {
      updateTaskState({
        customCloseDate: false
      });
    }
  }

  function handleOnTaskingTierChange (target) {
    const { days } = TaskCollectionTiers.properties[target.value];
    const updatedCollectionTier = target.value;

    updateTaskState({
      taskingTier: parseInt(days, 10),
      collectionTier: updatedCollectionTier,
      repetitionProperties: {
        repeatEnd: getRepeatEndDate()
      }
    });
  }

  function handleCollectMode (selection) {
    const { value: collectModeValue } = selection;
    const lookAngleDefaults = getLookAngleDefaults(collectModeValue, productCategory);
    const grrDefaults = getGRRDefaults(collectModeValue);

    // init default task state values when collect mode changes
    // these values are for 'spotlight' collectMode
    let newTaskStateValue = {
      imageWidthDisabled: true,
      imageLengthDisabled: true,
      numLooksHidden: false,
      collectConstraints: {
        offNadirMin: lookAngleDefaults.minValue,
        offNadirMax: lookAngleDefaults.maxValue,
        grrMin: grrDefaults.minValue,
        grrMax: grrDefaults.maxValue,
        azrMin: taskDefaults.DEFAULT_AZR_MIN,
        azrMax: taskDefaults.DEFAULT_AZR_MAX,
        imageWidth: taskDefaults.DEFAULT_IMAGE_WIDTH,
        imageLength: taskDefaults.DEFAULT_IMAGE_LENGTH,
        numLooks: taskDefaults.DEFAULT_NUM_LOOKS_SPOTLIGHT
      }
    };

    if (collectModeValue === 'sliding_spotlight') {
      newTaskStateValue = {
        imageWidthDisabled: false,
        imageLengthDisabled: false,
        numLooksHidden: false,
        collectConstraints: {
          offNadirMin: lookAngleDefaults.minValue,
          offNadirMax: lookAngleDefaults.maxValue,
          grrMin: grrDefaults.minValue,
          grrMax: grrDefaults.maxValue,
          azrMin: 1.0,
          azrMax: 1.0,
          imageWidth: null,
          imageLength: null,
          numLooks: taskDefaults.DEFAULT_NUM_LOOKS
        }
      };
    }

    if (collectModeValue === 'stripmap') {
      newTaskStateValue = {
        imageWidthDisabled: false,
        imageLengthDisabled: false,
        numLooksHidden: true,
        collectConstraints: {
          offNadirMin: lookAngleDefaults.minValue,
          offNadirMax: lookAngleDefaults.maxValue,
          grrMin: grrDefaults.minValue,
          grrMax: grrDefaults.maxValue,
          azrMin: 1.2,
          azrMax: 1.2,
          imageWidth: 5000,
          imageLength: 20000,
          numLooks: null
        }
      };
    }

    // set the state for task and collectConstraints
    updateTaskState({
      collectMode: collectModeValue,
      shouldRefreshAccessTimes: true,
      ...newTaskStateValue
    });
  }

  /**
   * this event handler causes a bunch of render side effects, so we need to keep the state clean when it changes
   */
  function handleRepeatCycleChange (selection) {
    const { value: repeatCycleValue } = selection;

    const advancedState = {
      preApproval: false,
      productCategory: taskDefaults.DEFAULT_PRODUCT_CATEGORY,
      repetitionProperties: {
        repeatCycle: repeatCycleValue
      },
      collectConstraints: {
        lookDirection: '',
        grrMax: taskDefaults.DEFAULT_GRR_MAX,
        grrMin: taskDefaults.DEFAULT_GRR_MIN,
        azrMax: taskDefaults.DEFAULT_AZR_MAX,
        azrMin: taskDefaults.DEFAULT_AZR_MIN,
        imageLength: taskDefaults.DEFAULT_IMAGE_LENGTH,
        imageWidth: taskDefaults.DEFAULT_IMAGE_WIDTH,
        numLooks: taskDefaults.DEFAULT_NUM_LOOKS_SPOTLIGHT
      }
    };

    const minimalState = {
      ...advancedState,
      ascDsc: '',
      repetitionProperties: {
        ...advancedState.repetitionProperties,
        maintainSceneFraming: false
      },
      collectConstraints: {
        ...advancedState.collectConstraints,
        offNadirMax: taskDefaults.DEFAULT_OFF_NADIR_MAX,
        offNadirMin: taskDefaults.DEFAULT_OFF_NADIR_MIN
      }
    };

    const defaultState = {
      ...minimalState,
      orbitalPlanes: undefined
    };

    if (!isRetask && repeatCycleValue === RepeatCycleTypes.DAILY) {
      updateTaskState(defaultState);
    } else if (!isRetask && repeatCycleValue === RepeatCycleTypes.WEEKLY) {
      updateTaskState(minimalState);
    } else if (
      !isRetask &&
      (repeatCycleValue === RepeatCycleTypes.BIWEEKLY ||
      repeatCycleValue === RepeatCycleTypes.MONTHLY)
    ) {
      updateTaskState(advancedState);
    } else {
      updateTaskState({
        repetitionProperties: {
          repeatCycle: repeatCycleValue
        }
      });
    }
  }

  function handleMinMaxSliderChange (value, stateCallback) {
    const { min, max } = value;
    const updatedValue = {
      min: typeof min === 'number' ? chompFloat(min, 2) : min,
      max: typeof max === 'number' ? chompFloat(max, 2) : max
    };

    // this callback can be used to save the state right in the UI component
    if (typeof stateCallback === 'function') {
      stateCallback(updatedValue);
    }
  }

  function handleAnalyticsChange (target) {
    const { value: analyticsValue = undefined } = target || {};

    let updatedTaskState = {
      analytics: analyticsValue ? [analyticsValue] : undefined
    };
    // ACD specific defaults
    if (analyticsValue === 'ACD') {
      updatedTaskState = {
        ...updatedTaskState,
        repetitionProperties: {
          maintainSceneFraming: true,
          repeatCycle: RepeatCycleTypes.BIWEEKLY
        },
        productCategory: taskDefaults.DEFAULT_PRODUCT_CATEGORY
      };
    }
    updateTaskState(updatedTaskState);
  }

  /**
   * standard task submit handler
   */
  function handleSubmit (e, fields) {
    let payload = {
      ...fields,
      mode: { value: 'void' },
      windowDuration: { value: (repeatEnd - repeatStart) / 1000 },
      taskRequestType: { value: TaskRequestTypes.REPEAT }
    };

    payload.collectMode.value = collectMode;
    payload.collectionTier.value = collectionTier;
    if (payload.ascDsc) {
      payload.ascDsc.value = ascDsc;
    }
    if (payload.polarization) {
      payload.polarization.value = polarization;
    }
    if (payload.lookDirection) {
      payload.lookDirection.value = lookDirection;
    }
    if (payload.productCategory) {
      payload.productCategory.value = productCategory;
    }
    if (payload.archiveHoldback) {
      payload.archiveHoldback.value = archiveHoldback;
    }
    if (payload.preApproval) {
      payload.preApproval.value = preApproval;
    }
    if (fields.orbitalPlanes) {
      payload.orbitalPlanes.value = orbitalPlanes;
    }
    if (targetHeight !== null) {
      payload.targetHeight = { value: parseInt(targetHeight, 10) };
    }

    // repeat task specific properties
    payload.repeatStart.value = new Date(repeatStart).toISOString();
    payload.repeatEnd =
      repetitionEndType === RepeatRequestEndTypes.DATE
        ? {
          value: new Date(repeatEnd).toISOString()
        }
        : undefined;
    payload.repetitionEndType = { value: repetitionEndType };
    payload.repetitionCount = { value: parseInt(repetitionCount, 10) };
    payload.maintainSceneFraming = { value: maintainSceneFraming };
    payload.lookAngleTolerance = { value: lookAngleTolerance };
    payload.repeatCycle = { value: repeatCycle };

    if (repeatCycle === RepeatCycleTypes.OTHER) {
      payload.repetitionInterval = { value: repetitionInterval };
    } else if (repeatCycle === RepeatCycleTypes.DAILY) {
      payload.repetitionInterval = { value: 1 };
    } else if (repeatCycle === RepeatCycleTypes.WEEKLY) {
      payload.repetitionInterval = { value: 7 };
    } else if (repeatCycle === RepeatCycleTypes.BIWEEKLY) {
      payload.repetitionInterval = { value: 14 };
    } else if (repeatCycle === RepeatCycleTypes.MONTHLY) {
      payload.repetitionInterval = { value: 30 };
    }

    // look angle
    payload.offNadirMin = { value: offNadirMin };
    payload.offNadirMax = { value: offNadirMax };
    payload.localTime = { value: localTime };
    payload.analytics = { value: analytics };

    // custom category options
    if (showCategoryOptions) {
      payload = {
        ...payload,
        imageWidth: { value: imageWidth },
        imageLength: { value: imageLength },
        grrMin: { value: grrMin },
        grrMax: { value: grrMax },
        azrMin: { value: azrMin },
        azrMax: { value: azrMax },
        numLooks: { value: numLooks }
      };
    }

    if (typeof onSubmit === 'function') {
      onSubmit(e, payload);
    }
  }

  /**
   * validation rules for handleSubmit
   */
  const validationRules = {
    repeatStart: {
      ...fieldByName('repeatStart'),
      required: true,
      isValid: (date) => {
        return date > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    repeatEnd: {
      ...fieldByName('repeatEnd'),
      isValid: (date) => {
        if (repeatEnd && repeatEnd < repeatStart) {
          return false;
        }
        return date > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    repetitionCount: {
      ...fieldByName('repetitionCount'),
      isValid: (count) => {
        if (repetitionEndType === RepeatRequestEndTypes.COUNT && (repetitionCount < 2 || repetitionCount > 60)) {
          return false;
        }
        return true;
      }
    },
    repetitionInterval: {
      ...fieldByName('repetitionInterval'),
      isValid: (count) => {
        if (repeatCycle === RepeatCycleTypes.OTHER && (repetitionInterval < 2 || repetitionInterval > 90)) {
          return false;
        }
        return true;
      }
    },
    customAttribute1: {
      ...fieldByName('customAttribute1'),
      isValid: () => {
        return customAttribute1?.length > 1;
      }
    },
    customAttribute2: {
      ...fieldByName('customAttribute2'),
      isValid: () => {
        return customAttribute2?.length > 1;
      }
    }
  };

  // repeat cycle types causes render side effects
  const {
    minimalGeometricConstraints = false,
    advancedGeometricConstraints = false,
    allGeometricConstraints = false
  } = RepeatCycleTypes.properties[repeatCycle];

  const { AVAILABLE_REPEAT_CYCLE_OPTIONS, ACD_REPEAT_CYCLE_OPTIONS } = taskDefaults.repeat;

  // render
  return (
    <Form
      className="create-task-form"
      onSubmit={handleSubmit}
      rules={validationRules}
    >
      {primaryTaskFields}
      <FormRow>
        <FormInput
          id="analytics"
          type="select"
          label="Analytics"
          clearable={true}
          disabled={isRetask}
          defaultValue={activeAnalytic}
          className="analytics-select"
          options={analyticsOptions}
          onChange={handleAnalyticsChange}
        />
      </FormRow>
      <FormRow>
        <FormInput
          id="repeatStart"
          label="Repeat Task Start (UTC)"
          type="datetime"
          value={repeatStart}
          closeOnSelectDate={true}
          allowPastDate={false}
          utc={true}
          validationMessage="Please select a window start time after the current time"
          onChange={handleOnWindowOpenChange}
        />
        <FormInput
          id="repetitionEndType"
          type="select"
          label="Repeat Task End"
          clearable={false}
          options={taskDefaults.repeat.AVAILABLE_REPEAT_TASK_END_OPTIONS}
          defaultValue={repetitionEndType}
          required={true}
          onChange={({ value }) => {
            updateTaskState({
              repetitionProperties: {
                repetitionEndType: value,
                repeatEnd: getRepeatEndDate({ endType: value }),
                repetitionCount:
                  value === RepeatRequestEndTypes.COUNT ? 2 : null
              },
              customCloseDate: false,
              shouldRefreshAccessTimes: true
            });
          }}
        />
      </FormRow>

      {repetitionEndType !== RepeatRequestEndTypes.INDEFINITE && (
        <FormRow className="form-row-indent">
          {repetitionEndType === RepeatRequestEndTypes.DATE && (
            <FormInput
              id="repeatEnd"
              label="Repeat Task End (UTC)"
              type="datetime"
              value={repeatEnd}
              disableFrom={{
                from: ReactDatetime.moment.utc(new Date(repeatStart))
              }}
              showClear={true}
              allowPastDate={false}
              utc={true}
              validationMessage="Please select a window close time after the tasking tier period"
              onChange={handleOnWindowCloseChange}
              className="no-max-w"
              closeOnSelectDate={true}
            />
          )}
          {repetitionEndType === RepeatRequestEndTypes.COUNT && (
            <FormInput
              id="repetitionCount"
              name="repetitionCount"
              type="number"
              label="Number of Collects (2 - 60)"
              defaultValue={repetitionCount}
              min={2}
              step={1}
              max={60}
              validationMessage="Please enter a minimum of 2 or maximum of 60 number of collects"
              onChange={({ target }) =>
                updateTaskState({
                  repetitionProperties: {
                    repetitionCount: parseInt(target?.value, 10) || -1
                  }
                })
              }
            />
          )}
        </FormRow>
      )}

      {enableCollectTimeSelect && (
        <FormRow className="form-row-block form-row-toggle-group">
          <label>Collection Time</label>
          <ToggleButtonGroup
            id="localTime"
            className={`${isRetask ? 'disabled disabled-visually' : ''}`}
            activeValue={localTime}
            options={taskDefaults.AVAILABLE_COLLECT_TIMES.options}
            disabled={isRetask}
            onChange={(value) => updateTaskState({
              collectConstraints: {
                localTime: value
              },
              shouldRefreshAccessTimes: true
            })}
          />
        </FormRow>
      )}

      <FormRow>
        <FormInput
          id="collectionTier"
          type="select"
          label="Tasking Tier"
          clickable={true}
          clearable={false}
          options={taskingTierOptions}
          defaultValue={collectionTier}
          required={true}
          onChange={handleOnTaskingTierChange}
        />
      </FormRow>
      <FormRow>
        <div className="search-dropdowns" data-testid="repeat-cycle">
          <FormInput
            id="repeatCycle"
            type="select"
            label="Repeat Cycle"
            clearable={false}
            options={isACDSelected ? ACD_REPEAT_CYCLE_OPTIONS : AVAILABLE_REPEAT_CYCLE_OPTIONS}
            value={AVAILABLE_REPEAT_CYCLE_OPTIONS.find(opt => opt.value === repeatCycle)}
            onChange={handleRepeatCycleChange}
          />
        </div>
      </FormRow>
      {repeatCycle === RepeatCycleTypes.OTHER && (
        <FormRow className="form-row-indent pad-bottom-one">
          <div className="search-dropdowns" data-testid="number-of-days">
            <FormInput
              id="repetitionInterval"
              name="repetitionInterval"
              type="number"
              label="Number of days (2 - 90)"
              defaultValue={repetitionInterval}
              min={2}
              step={1}
              max={90}
              onChange={({ target }) =>
                updateTaskState({
                  repetitionProperties: {
                    repetitionInterval: parseInt(target?.value, 10) || -1
                  }
                })
              }
              validationMessage="Please enter a minimum of 2 or maximum of 90 days"
            />
          </div>
        </FormRow>
      )}

      {children}

      <FormRow>
        <FormInput
          id="collectMode"
          type="select"
          label="Imaging Mode"
          clearable={false}
          disabled={isRetask}
          options={
            !activeAnalytic
              ? taskDefaults.AVAILABLE_COLLECT_MODES
              : getAvailableAnalyticsCollectModes(activeAnalytic)
          }
          defaultValue={collectMode}
          onChange={handleCollectMode}
        />
      </FormRow>

      <OptionsPanel label="Optional Parameters" id="optional-parameters" defaultIsOpen={isRetask}>
        <FormRow>
          <div className="search-dropdowns" data-testid="polarization">
            <FormInput
              id="polarization"
              type="select"
              label="Polarization"
              clearable={true}
              disabled={isRetask}
              options={taskDefaults.AVAILABLE_POLARIZATION}
              defaultValue={polarization}
              onChange={(selection) =>
                updateTaskState({
                  collectConstraints: {
                    polarization: selection?.value || undefined
                  }
                })
              }
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="search-dropdowns" data-testid="targetHeight">
            <FormInput
              id="showTargetHeight"
              type="select"
              label="Target Height"
              clearable={false}
              disabled={isRetask}
              options={taskDefaults.SHOW_TARGET_HEIGHT}
              onChange={({ value }) =>
                updateTaskState({
                  collectConstraints: {
                    targetHeight: value === 'custom' ? targetHeight || 0 : null
                  }
                })
              }
              defaultValue={targetHeight ? 'custom' : 'default'}
            />
          </div>
        </FormRow>
        {showTargetHeight && (
          <FormRow>
            <div className="search-filters-range-slider" ref={inputRef}>
              <InputRangeExtended
                className={`${isRetask ? 'disabled disabled-visually' : ''}`}
                minValue={-1000}
                maxValue={9000}
                value={targetHeight || 0}
                disabled={isRetask}
                onChangeComplete={(value) =>
                  updateTaskState({
                    collectConstraints: {
                      targetHeight: parseInt(value, 10)
                    }
                  })
                }
                step={1}
                metric={'m'}
                onChangeDelay={0.3}
              />
            </div>
          </FormRow>
        )}

        {allGeometricConstraints === true && repeatCycle !== RepeatCycleTypes.OTHER && (
          <FormRow>
            <InputButton
              id="preApproval"
              label="Pre-Approval"
              type="checkbox"
              value="true"
              isChecked={preApproval}
              disabled={isRetask}
              onChange={({ currentTarget = {} }) => {
                const { checked } = currentTarget;
                updateTaskState({
                  preApproval: checked
                });
              }}
            />
          </FormRow>
        )}

        {minimalGeometricConstraints === true && (
          <FormRow>
            <div className="search-dropdowns" data-testid="orbitalPlanes">
              <FormInput
                id="orbitalPlanes"
                name="orbitalPlanes"
                type="multiselect"
                label="Orbital Plane"
                clearable={false}
                disabled={isRetask}
                options={taskDefaults.AVAILABLE_ORBITAL_PLANES}
                value={returnOrbitalPlanesSelection(orbitalPlanes)}
                onChange={(selections) => {
                  let selectionsArray;
                  if (selections?.length && selections.length > 0) {
                    selectionsArray = selections.map(({ value }) => value);
                  }

                  updateTaskState({
                    shouldRefreshAccessTimes: true,
                    orbitalPlanes: selectionsArray
                  });
                }}
              />
            </div>
          </FormRow>
        )}

        {advancedGeometricConstraints === true && (
          <FormRow>
            <div className="search-dropdowns" data-testid="orbitalState">
              <FormInput
                id="ascDsc"
                type="select"
                label="Orbit State"
                clearable={true}
                disabled={isRetask}
                options={taskDefaults.AVAILABLE_ASC_DSC}
                defaultValue={ascDsc}
                onChange={(selection) => {
                  const { value: ascDscValue = '' } = selection || {};

                  updateTaskState({
                    shouldRefreshAccessTimes: true,
                    ascDsc: ascDscValue
                  });
                }}
              />
            </div>
          </FormRow>
        )}

        {advancedGeometricConstraints && (
          <FormRow>
            <div
              className="search-dropdowns"
              data-testid="observationDirection"
            >
              <FormInput
                id="lookDirection"
                type="select"
                label="Observation Direction"
                clearable={true}
                disabled={isRetask}
                options={taskDefaults.AVAILABLE_LOOK_DIRECTIONS}
                defaultValue={lookDirection}
                onChange={(selection) => {
                  const { value: lookDirectionValue = undefined } =
                    selection || {};

                  updateTaskState({
                    shouldRefreshAccessTimes: true,
                    lookDirection: lookDirectionValue
                  });
                }}
              />
            </div>
          </FormRow>
        )}

        {advancedGeometricConstraints === true && (
          <>
            <FormRow>
              <div className="search-dropdowns" data-testid="scene-framing">
                <InputButton
                  id="maintainSceneFraming"
                  label="Maintain Scene Framing"
                  type="checkbox"
                  disabled={isACDSelected || isRetask}
                  isChecked={isACDSelected ? true : maintainSceneFraming}
                  controlChecked={true}
                  onChange={({ target } = {}) =>
                    updateTaskState({
                      repetitionProperties: {
                        lookAngleTolerance: target?.checked ? 10 : null,
                        maintainSceneFraming: target?.checked
                      }
                    })
                  }
                />
              </div>
            </FormRow>

            {maintainSceneFraming === true && (
              <>
                <FormRow>
                  <div className="form-input">
                    <label className="form-label vertical-centered">Look Angle Tolerance
                      {isACDSelected && (
                        <Tooltip
                          className="access-requests-tip"
                          id="lookAngleToleranceTip"
                          delayHide={500}
                          delayUpdate={500}
                          place="top"
                          effect="solid"
                          type="dark"
                          multiline={true}
                          tooltipText={<div>
                            <p><b>123Larger</b> Look Angle Tolerance (e.g. 10°) will result in more collection opportunities and is more likely to be accepted. Change artifacts are also more likely.</p>
                            <p><b>Smaller</b> Look Angle Tolerance can help reduce change artifacts and noise, but results in fewer collection opportunities and is less likely to be accepted in competitive areas.</p>
                            <p className="tool-tip-footer">More details at <a href={CAPELLA_SUPPORT_URL} target="_blank" rel="noopenner noreferrer">Capella Support</a></p>
                          </div>}
                        />
                      )}
                    </label>
                  </div>
                </FormRow>
                <FormRow>
                  <div
                    className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                    data-testid="look-angle-tolerance"
                  >
                    <InputRangeExtended
                      className={`${isRetask ? 'disabled disabled-visually' : ''}`}
                      minValue={0}
                      maxValue={10}
                      value={lookAngleTolerance}
                      onChangeComplete={(value) =>
                        updateTaskState({
                          repetitionProperties: {
                            lookAngleTolerance: parseFloat(value)
                          }
                        })
                      }
                      step={1}
                      metric={' degrees'}
                      onChangeDelay={0.3}
                    />
                  </div>
                </FormRow>
              </>
            )}
          </>
        )}

        <FormRow className="form-row-block">
          <label className="form-label standalone-label" htmlFor="archiveHoldback">
            Archive Catalog Holdback
            <Tooltip
              className="customer-internal-id-tip"
              id="archiveHoldbackTip"
              multiline={true}
              tooltipText="Archive catalog holdback is an option for customers to delay the time in which a tasked image is included in the publicly available imagery archive. For a value of 'None', imagery will immediately flow into the public archive.  For any value other than 'None', an uplift charge will be included in the final task price and the imagery will only appear in the public archive after the holdback period. If no value is provided, it will automatically be set to the default value defined by the contract selected during the next step."
            />
          </label>
          <div className="search-dropdowns" data-testid="archiveHoldback">
            <FormInput
              type="select"
              id="archiveHoldback"
              className="archive-holdback-select"
              clearable={true}
              options={taskDefaults.ARCHIVE_HOLDBACK_VALUES}
              defaultValue={archiveHoldback}
              onChange={({ value }) => {
                updateTaskState({
                  archiveHoldback: value
                });
              }}
            />
          </div>
        </FormRow>

        <FormRow key={productCategory}>
          <div className="search-dropdowns" data-testid="productCategory">
            <FormInput
              id="productCategory"
              type="select"
              label="Product Category"
              clearable={false}
              options={isACDSelected !== true ? taskDefaults.AVAILABLE_PRODUCT_CATEGORIES : getACDProductCategories(collectMode)}
              defaultValue={productCategory}
              disabled={isRetask}
              onChange={(selection) => {
                const { value: productCategoryValue } = selection;
                const lookAngleDefaults = getLookAngleDefaults(collectMode, productCategoryValue);
                const updatedConstraints = {
                  offNadirMin: lookAngleDefaults.minValue,
                  offNadirMax: lookAngleDefaults.maxValue
                };

                updateTaskState({
                  shouldRefreshAccessTimes: true,
                  productCategory: selection?.value,
                  collectConstraints: {
                    ...updatedConstraints
                  }
                });
              }}
            />
          </div>
        </FormRow>

        {showCategoryOptions === true && (
          <div className="custom-fields">
            {showLookAngleRange && (
              <FormRow>
                <div
                  className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                  data-testid="look-angle"
                >
                  <label className="form-label-slider">Look Angle</label>
                  <InputRange
                    {...sliderValuesAndRange({
                      defaultValueMin: offNadirMin,
                      defaultValueMax: offNadirMax,
                      defaultRangeMin: getLookAngleRange(collectMode, productCategory).minValue,
                      defaultRangeMax: getLookAngleRange(collectMode, productCategory).maxValue,
                      valueSideEffects: {
                        min: offNadirMin,
                        max: offNadirMax
                      },
                      rangeSideEffects: () => {
                        return getLookAngleRange(collectMode, productCategory);
                      }
                    })}
                    disabled={isRetask}
                    onChange={(value) =>
                      handleMinMaxSliderChange(value, (updatedValue) => {
                        updateTaskState({
                          shouldRefreshAccessTimes: true,
                          collectConstraints: {
                            offNadirMin: updatedValue.min,
                            offNadirMax: updatedValue.max
                          }
                        });
                      })
                    }
                  />
                </div>
              </FormRow>
            )}
            { showGroundRangeResolution && (
              <FormRow>
                <div
                  className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                  data-testid="ground-range"
                >
                  <label className="form-label-slider">Ground Range Resolution</label>
                  <InputRange
                    {...sliderValuesAndRange({
                      defaultValueMin: grrMin,
                      defaultValueMax: grrMax,
                      defaultRangeMin: getGRRRange(collectMode).minValue,
                      defaultRangeMax: getGRRRange(collectMode).maxValue,
                      valueSideEffects: {
                        min: grrMin,
                        max: grrMax
                      },
                      rangeSideEffects: () => {
                        return getGRRRange(collectMode);
                      }
                    })}
                    step={0.05}
                    formatLabel={(value) => `${value}m`}
                    disabled={isRetask}
                    onChange={(value) =>
                      handleMinMaxSliderChange(value, (updatedValue) => {
                        updateTaskState({
                          collectConstraints: {
                            grrMin: updatedValue.min,
                            grrMax: updatedValue.max
                          }
                        });
                      })
                    }
                  />
                </div>
              </FormRow>
            )}
            { showAzimuthResolution && (
              <FormRow>
                <div
                  className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                  data-testid="azimuth-resolution"
                >
                  <label className="form-label-slider">Azimuth Resolution</label>
                  <InputRange
                    {...sliderValuesAndRange({
                      defaultValueMin: taskDefaults.DEFAULT_AZR_MIN,
                      defaultValueMax: taskDefaults.DEFAULT_AZR_MAX,
                      defaultRangeMin: taskDefaults.DEFAULT_AZR_MIN_RANGE,
                      defaultRangeMax: taskDefaults.DEFAULT_AZR_MAX_RANGE,
                      valueSideEffects: {
                        min: azrMin,
                        max: azrMax
                      },
                      rangeSideEffects: ({ minValue, maxValue }) => {
                        if (collectMode === 'stripmap') {
                          return {
                            minValue: 1.2,
                            maxValue: 11.5
                          };
                        }

                        if (collectMode === 'sliding_spotlight') {
                          return {
                            minValue: 0.7,
                            maxValue: 5.0
                          };
                        }

                        return {
                          minValue,
                          maxValue
                        };
                      }
                    })}
                    step={0.01}
                    formatLabel={(value) => `${value}m`}
                    disabled={isRetask}
                    onChange={(value) =>
                      handleMinMaxSliderChange(value, (updatedValue) => {
                        updateTaskState({
                          collectConstraints: {
                            azrMin: updatedValue.min,
                            azrMax: updatedValue.max
                          }
                        });
                      })
                    }
                  />
                </div>
              </FormRow>
            )}
            {showCustomSize && (
              <>
                <FormRow>
                  <div
                    className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                    data-testid="image-width"
                  >
                    <label className="form-label-slider">Image Width</label>
                    <InputRange
                      minValue={5000}
                      maxValue={10000}
                      value={imageWidth}
                      step={1000}
                      formatLabel={(value) => `${value / 1000}km`}
                      disabled={imageWidthDisabled || isRetask}
                      onChange={(value) =>
                        updateTaskState({
                          collectConstraints: {
                            imageWidth: value
                          }
                        })
                      }
                    />
                  </div>
                </FormRow>
                <FormRow>
                  <div
                    className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                    data-testid="image-length"
                  >
                    <label>Image Length</label>
                    <InputRange
                      {...sliderValuesAndRange({
                        defaultRangeMin: 0,
                        defaultRangeMax: 10000,
                        valueSideEffects: imageLength,
                        rangeSideEffects: ({ minValue, maxValue }) => {
                          if (collectMode === 'stripmap') {
                            return {
                              minValue: 5000,
                              maxValue: 200000
                            };
                          }

                          if (collectMode === 'sliding_spotlight') {
                            return {
                              minValue: 5000,
                              maxValue: 50000
                            };
                          }

                          return {
                            minValue,
                            maxValue
                          };
                        }
                      })}
                      step={1000}
                      formatLabel={(value) => `${value / 1000}km`}
                      disabled={imageLengthDisabled || isRetask}
                      onChange={(value) =>
                        updateTaskState({
                          collectConstraints: {
                            imageLength: value
                          }
                        })
                      }
                    />
                  </div>
                </FormRow>
              </>
            )}

            {!numLooksHidden && !isACDSelected && (
              <FormRow>
                <div
                  className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                  data-testid="number-looks"
                >
                  <label className="form-label-slider">Number of Looks</label>
                  <InputRange
                    {...sliderValuesAndRange({
                      defaultRangeMin: 1,
                      defaultRangeMax: 9,
                      valueSideEffects: numLooks,
                      rangeSideEffects: ({ minValue, maxValue }) => {
                        if (collectMode === 'stripmap') {
                          return {
                            minValue: 1,
                            maxValue: 5
                          };
                        }

                        if (collectMode === 'sliding_spotlight') {
                          return {
                            minValue: 1,
                            maxValue: 5
                          };
                        }

                        return {
                          minValue,
                          maxValue
                        };
                      }
                    })}
                    value={numLooks}
                    step={1}
                    formatLabel={(value) => `${value}`}
                    disabled={isRetask}
                    onChange={(value) =>
                      updateTaskState({
                        collectConstraints: {
                          numLooks: value
                        }
                      })
                    }
                  />
                </div>
              </FormRow>
            )}
          </div>
        )}

        <FormRow className="form-row-block">
          <label className="form-label standalone-label" htmlFor="customAttribute1">
            Custom Attribute 1
            <Tooltip
              className="customer-internal-id-tip"
              id="customAttribute1Tip"
              multiline={true}
              tooltipText='Attribute to help customers track a Capella task with their internal systems'
            />
          </label>
          <FormInput
            id="customAttribute1"
            defaultValue={customAttribute1}
            onChange={({ target: { value = '' } }) =>
              updateTaskState({
                customAttribute1: value
              })
            }
            validationMessage="2 character minimum for ID"
          />
        </FormRow>

        <FormRow className="form-row-block">
          <label className="form-label standalone-label" htmlFor="customAttribute2">
            Custom Attribute 2
            <Tooltip
              className="customer-po-id-tip"
              id="customAttribute2Tip"
              multiline={true}
              tooltipText='Attribute to help customers track a Capella task with their internal systems'
            />
          </label>
          <FormInput
            id="customAttribute2"
            defaultValue={customAttribute2}
            onChange={({ target: { value = '' } }) =>
              updateTaskState({
                customAttribute2: value
              })
            }
            validationMessage="2 character minimum for ID"
          />
        </FormRow>
      </OptionsPanel>

      {footerChildren}

      <FormRow className="form-row-actions">
        <Button role="button">Create Task</Button>

        <Button type="text" onClick={onCancel}>
          Cancel Task Request
        </Button>
      </FormRow>
    </Form>
  );
};

FormCreateTaskRepeat.propTypes = {
  primaryTaskFields: PropTypes.node,
  footerChildren: PropTypes.node,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  geoJson: PropTypes.object,
  enableCollectTimeSelect: PropTypes.bool
};

export default FormCreateTaskRepeat;
