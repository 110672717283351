exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-aois-js": () => import("./../../../src/pages/aois.js" /* webpackChunkName: "component---src-pages-aois-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-organization-js": () => import("./../../../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-reseller-js": () => import("./../../../src/pages/reseller.js" /* webpackChunkName: "component---src-pages-reseller-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-subreseller-js": () => import("./../../../src/pages/subreseller.js" /* webpackChunkName: "component---src-pages-subreseller-js" */),
  "component---src-pages-tasks-js": () => import("./../../../src/pages/tasks.js" /* webpackChunkName: "component---src-pages-tasks-js" */),
  "component---src-pages-user-authorize-js": () => import("./../../../src/pages/user/authorize.js" /* webpackChunkName: "component---src-pages-user-authorize-js" */),
  "component---src-pages-user-change-password-js": () => import("./../../../src/pages/user/change-password.js" /* webpackChunkName: "component---src-pages-user-change-password-js" */),
  "component---src-pages-user-login-js": () => import("./../../../src/pages/user/login.js" /* webpackChunkName: "component---src-pages-user-login-js" */),
  "component---src-pages-user-logout-js": () => import("./../../../src/pages/user/logout.js" /* webpackChunkName: "component---src-pages-user-logout-js" */),
  "component---src-pages-user-new-password-js": () => import("./../../../src/pages/user/new-password.js" /* webpackChunkName: "component---src-pages-user-new-password-js" */),
  "component---src-pages-user-password-reset-js": () => import("./../../../src/pages/user/password-reset.js" /* webpackChunkName: "component---src-pages-user-password-reset-js" */),
  "component---src-pages-user-premium-js": () => import("./../../../src/pages/user/premium.js" /* webpackChunkName: "component---src-pages-user-premium-js" */),
  "component---src-pages-user-premium-thank-you-js": () => import("./../../../src/pages/user/premium/thank-you.js" /* webpackChunkName: "component---src-pages-user-premium-thank-you-js" */),
  "component---src-pages-user-register-js": () => import("./../../../src/pages/user/register.js" /* webpackChunkName: "component---src-pages-user-register-js" */),
  "component---src-pages-user-register-thank-you-js": () => import("./../../../src/pages/user/register/thank-you.js" /* webpackChunkName: "component---src-pages-user-register-thank-you-js" */)
}

