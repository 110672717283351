import React from 'react';
import PropTypes from 'prop-types';
import { WonderLink } from 'fogg/ui';

import Container from 'components/Container';

import { useSiteMetadata } from 'hooks';

const Footer = ({ className }) => {
  const { name, url, links = {} } = useSiteMetadata();
  const { termsOfService, privacyPolicy } = links;

  let footerClassName = 'footer';

  if (className) {
    footerClassName = `${footerClassName} ${className}`;
  }

  return (
    <footer className={footerClassName}>
      <Container>
        <ul>
          <li>
            &copy; {new Date().getFullYear()},{' '}
            <WonderLink to={url}>{name}</WonderLink>
          </li>
          <li>
            <WonderLink to={termsOfService}>Terms of Service</WonderLink>
          </li>
          <li>
            <WonderLink to={privacyPolicy}>Privacy Policy</WonderLink>
          </li>
        </ul>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
