import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Panel, ItemList, PanelActions } from 'fogg/ui';
import { useLens } from 'fogg/hooks';
import { FaSyncAlt, FaTimes, FaSpinner, FaEraser } from 'react-icons/fa';
import { MdZoomIn } from 'react-icons/md';

import { tileLayerPropertiesFromFeature, MAP_RESULTS_FEATURE_GROUP_ID, MAP_CHECKED_RESULTS_FEATURE_GROUP_ID } from 'lib/map';
import { AVAILABLE_SEARCH_PARAMS } from 'lib/search';
import { useLocation } from 'hooks';
import HelpfulButton from './HelpfulButton';

const DEFAULT_LOADING_RESULTS_COUNT = 3;

const SearchResults = ({ isLoading = false, searchIsLoading = false, checkedFeatures, setCheckedFeatures }) => {
  const [isLoadMoreClick, setIsLoadMoreClick] = useState(false);

  const { clearQuerySearchParams } = useLocation();

  const { geoSearch = {}, geoFilters = {}, map = {} } = useLens();
  const {
    featureGroupById,
    addShapeToMap,
    clearLayers,
    addTileLayerToMap,
    clearTileLayer,
    zoomToBounds
  } = map;
  const { filters = {}, clearActiveFilters } = geoFilters;
  const { results = {}, clearSearch, loadMoreResults } = geoSearch;
  let { features, hasMoreResults, numberOfResults, hasResults } = results;

  /**
   * initial settings for a shape object to be added to a map layer
   */
  const initShapeSettings = ({
    geoJson = {},
    featureGroup = mapFeatureGroup,
    clearOtherLayers = true,
    panToShape = false,
    shapeColor = '#8000ff',
    colorFill = false,
    borderWeight = 2
  }) => ({
    geoJson,
    featureGroup,
    clearOtherLayers,
    panToShape,
    shapeOptions: {
      color: shapeColor,
      fill: colorFill,
      weight: borderWeight
    }
  });

  const { featureGroup: mapFeatureGroup } = featureGroupById(MAP_RESULTS_FEATURE_GROUP_ID);
  const { featureGroup: checkedFeatureGroup } = featureGroupById(MAP_CHECKED_RESULTS_FEATURE_GROUP_ID);

  let searchListClassName = 'search-results-list';

  // If we're in a loading state, patch the results with some filler values
  // and add a class so we can style it appropriately
  if (isLoading) {
    searchListClassName = `${searchListClassName} search-results-list-loading`;
    features = isLoadMoreClick
      ? features.concat(fillEmptyResults())
      : fillEmptyResults();
  }

  if (!isLoading && isLoadMoreClick) {
    setIsLoadMoreClick(false);
  }

  // Clean out shape layers on unmount to make sure we're not leaving any artifacts
  // on the map ui

  useEffect(() => {
    return () => {
      clearShapeLayers();
    };
  }, []);

  // If the search is loading, that means we're performing a new search. In that case
  // we want to clear out any results that are currently showing

  useEffect(() => {
    if (!searchIsLoading) return;
    clearShapeLayers();
    clearChecked();
  }, [searchIsLoading]);

  /**
   * clearShapeLayers
   */

  function clearShapeLayers () {
    clearLayers({
      featureGroup: mapFeatureGroup
    });
  }

  /**
   * featureFromId
   */

  function featureFromId (id) {
    const idSplit = id.split('/');
    const itemId = idSplit[0];
    const collectionID = idSplit[1];

    if (!itemId || !collectionID) return;

    const currentProduct = features.find(({ feature = {} } = {}) => {
      const { id, collection } = feature;
      return collection === collectionID && id === itemId;
    });

    if (!currentProduct) return;

    const { feature } = currentProduct;
    return feature;
  }

  /**
   * handleLoadMore
   * @description Fires when load more results button is clicked
   */

  function handleLoadMore (e) {
    if (hasMoreResults) {
      loadMoreResults(e);
      setIsLoadMoreClick(true);
    }
  }

  /**
   * handleClearActiveSearch
   * @description Fires when clear search button is clicked
   */

  function handleClearActiveSearch () {
    clearQuerySearchParams(AVAILABLE_SEARCH_PARAMS);
    if (typeof clearSearch === 'function') {
      clearSearch();
    }
  }

  /**
   * handleOnItemMouseEnter
   */

  function handleOnItemMouseEnter ({ currentTarget } = {}) {
    const { id = '' } = currentTarget;
    const feature = featureFromId(id);

    // if isZoomState is truthy, then don't add a new shape out of view
    if (!feature) return;

    addShapeToMap(
      initShapeSettings({
        geoJson: feature
      })
    );
  }

  /**
   * zooms to feature on the map
   * @param {object} currentTarget - the dom element that was clicked
   */
  function handleZoomInClick ({ currentTarget: { id = '' } }) {
    const feature = featureFromId(id);

    if (!feature) return;

    // add shape to the map
    addShapeToMap(
      initShapeSettings({
        geoJson: feature,
        panToShape: true
      })
    );

    // fit map bounds to feature bounding box
    const layerBounds = mapFeatureGroup.getBounds();
    zoomToBounds({
      layerBounds
    });
  }

  /**
   * onCheck
   */

  function onCheck ({ currentTarget = {} }) {
    let currentSelected = [
      ...checkedFeatures
    ];

    const { value = '' } = currentTarget;

    const feature = featureFromId(value);

    if (!feature) return;

    const tileLayerProperties = tileLayerPropertiesFromFeature(feature) || {};
    const { options = {} } = tileLayerProperties;
    options.zIndex = 5;
    const { name } = options;

    if (currentTarget.checked) {
      addTileLayerToMap({
        ...tileLayerProperties,
        featureGroup: checkedFeatureGroup
      });
      currentSelected.push(feature);
    } else {
      clearTileLayer({
        name,
        featureGroup: checkedFeatureGroup
      });
      currentSelected = currentSelected.filter(({ id }) => id !== feature.id);
    }

    if (typeof setCheckedFeatures === 'function') {
      setCheckedFeatures(currentSelected);
    }
  }

  /**
   * clearChecked
   */

  function clearChecked () {
    clearLayers({
      featureGroup: checkedFeatureGroup
    });
    setCheckedFeatures([]);
  }

  const filterActions = [
    {
      label: 'Clear Search',
      icon: <FaTimes />,
      onClick: handleClearActiveSearch,
      isVisible: !isLoading
    }
  ];

  const actions = <PanelActions actions={filterActions} />;

  const featuresWithId = features.map((feature) => {
    const { feature: featureItem = {} } = feature;
    const { id, collection } = featureItem;
    const isChecked = !!checkedFeatures.find(feature => feature.id === id);
    const labelWithZoom = (
      <>
        {feature.label}
        {!isLoading && (
          <HelpfulButton
            id={`${id}/${collection}`}
            className="feature-zoom-btn"
            onClick={handleZoomInClick}
            tooltipId={`zoomHelp${id}-${collection}`}
            tooltipText="Zoom map to image boundary"
          >
            <MdZoomIn size={24} />
          </HelpfulButton>
        )}
      </>
    );

    return {
      ...feature,
      label: labelWithZoom,
      id: `${id}/${collection}`,
      isChecked
    };
  });

  return (
    <>
      <Panel
        className="search-results"
        header={
          <>
            {'Results '}
            {isLoading ? (
              <FaSpinner className="icon-spin" id="loading" />
            ) : numberOfResults > 0 ? (
              `(${numberOfResults})`
            ) : (
              '(0)'
            )}
          </>
        }
        actions={actions}
      >
        {!hasResults && !isLoading && (
          <>
            <p className="text-center">Sorry, no results were found.</p>
            {filters.active.length > 0 && (
              <p className="text-center">
                <Button
                  type="text"
                  id="clearFilters"
                  onClick={clearActiveFilters}
                >
                  Clear Filters
                </Button>
              </p>
            )}
          </>
        )}

        {(hasResults || isLoading) && (
          <>
            <div className={searchListClassName}>
              <ItemList
                items={featuresWithId}
                onItemMouseEnter={handleOnItemMouseEnter}
                onCheck={onCheck}
              />

              {!isLoading && hasMoreResults && (
                <p className="search-results-more">
                  <Button full={true} id="loadMore" onClick={handleLoadMore}>
                    <FaSyncAlt /> Load More Results
                  </Button>
                </p>
              )}
              {checkedFeatures && checkedFeatures.length > 0 && (
                <p className="search-results-more">
                  <Button full={true} id="clearSelected" onClick={clearChecked}>
                    <FaEraser /> Clear selected
                  </Button>
                </p>
              )}
            </div>
          </>
        )}
      </Panel>
    </>
  );
};

SearchResults.propTypes = {
  results: PropTypes.array,
  loadMoreResults: PropTypes.func,
  filters: PropTypes.object,
  clearActiveSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  searchIsLoading: PropTypes.bool,
  numberOfResults: PropTypes.number,
  checkedFeatures: PropTypes.array,
  setCheckedFeatures: PropTypes.func
};

export default SearchResults;

/**
 * fillEmptyResults
 * @description Given the account, returns an array of empty search results
 */

function fillEmptyResults (count = DEFAULT_LOADING_RESULTS_COUNT) {
  return Array.apply(null, Array(count)).map(() => {
    return {
      label: 'Loading',
      sublabels: ['Loading', 'Loading', 'Loading'],
      icon: false,
      className: 'search-results-item-loading'
    };
  });
}
