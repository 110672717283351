import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import { routePathByName } from 'lib/routes';

import OrdersPage from 'pages-client/orders/orders';
import OrderPage from 'pages-client/orders/order';

const OrdersIndex = () => {
  return (
    <>
      <Router basepath="/">
        <OrdersPage path={routePathByName('orders')} />
        <OrderPage
          path={routePathByName('ordersDetails', {
            wildcard: [':orderId']
          })}
        />
      </Router>
    </>
  );
};

OrdersIndex.propTypes = {
  location: PropTypes.object
};

export default OrdersIndex;
