import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormRow,
  FormInput,
  ToggleButtonGroup
} from 'fogg/ui';
import ReactDatetime from 'react-datetime';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { TaskCollectionTiers } from 'data/task-collection-tiers';
import { RepeatCycleTypes } from 'data/repeat-cycle-types';
import { RepeatRequestEndTypes } from 'data/repeat-request-end-types';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';
import { taskDefaults, THIRTY_DAYS_MS, getAvailableAnalyticsCollectTypes } from 'lib/tasks';

import { useUser, useTaskContext, useCollectionTypes } from 'hooks';

const FormCreateTaskFields = ({
  isPointTask,
  isRepeatTask,
  acdIsSelected,
  activeAnalytic,
  defaultCollectionType = {}
}) => {
  const { collectionTypes } = useCollectionTypes();
  const collectionTypeSelectOptions = collectionTypes
    .filter(({ name }) => isPointTask || (name === 'stripmap_100'))
    .map(({ name, nameVerbose }) => ({ value: name, label: nameVerbose }));

  const { taskState, updateTaskState } = useTaskContext();
  const {
    repetitionProperties: {
      repeatStart,
      repeatEnd,
      repetitionCount = null,
      repetitionInterval = 2,
      repeatCycle = taskDefaults.repeat.DEFAULT_REPEAT_CYCLE_VALUE,
      repetitionEndType = taskDefaults.repeat.DEFAULT_REPEAT_TASK_END_TYPE_VALUE
    } = {},
    collectConstraints: {
      localTime = taskDefaults.DEFAULT_LOCAL_TIME
    } = {},
    windowOpenInt,
    windowCloseInt,
    customCloseDate = false,
    isRetask = false,
    taskingTier = taskDefaults.DEFAULT_TASKING_TIER,
    collectionType = defaultCollectionType.name,
    collectionTier = isRepeatTask ? taskDefaults.repeat.DEFAULT_REPEAT_TASK_COLLECTION_TIER : taskDefaults.DEFAULT_COLLECTION_TIER
  } = taskState;

  // Verify which analytics the user's org has enabled
  const {
    user: {
      organization = {}
    } = {}
  } = useUser();
  const { permittedCollectionTiers: { taskingRequest = [], repeatRequest = [] } = {} } = organization;

  // Rely on user org's permitted collection tiers
  const collectionTierOptions = isRepeatTask
    ? taskDefaults.repeat.AVAILABLE_REPEAT_TASK_COLLECTION_TIERS.filter(({ value }) => repeatRequest.includes(value))
    : taskDefaults.AVAILABLE_COLLECTION_TIERS.filter(({ value }) => taskingRequest.includes(value));

  useEffect(() => {
    if (!collectionTierOptions.find(({ value }) => collectionTier === value)) {
      let newCollectionTier = isRepeatTask
        ? taskDefaults.repeat.DEFAULT_REPEAT_TASK_COLLECTION_TIER
        : taskDefaults.DEFAULT_COLLECTION_TIER;

      if (!collectionTierOptions.find(({ value }) => newCollectionTier === value)) {
        newCollectionTier = collectionTierOptions[0]?.value;
      }

      updateTaskState({
        collectionTier: newCollectionTier
      });
    }
  }, [isRepeatTask]);

  // Show the Analytics options enabled both globally and per organization
  const flags = useFlags();
  const analyticsOptions = AnalyticProducts.Types
    .filter(o => o.repeatOnly ? isRepeatTask : true)
    .filter(o => o.orgEnabledFlagId === undefined || organization[o.orgEnabledFlagId])
    .filter(o => o.globalEnabledFlagId === undefined || flags[o.globalEnabledFlagId]);

  /**
   * gets repeatEnd date based on existing state properties: repetitionEndType, customCloseDate
   * @param {string} [endType=repetitionEndType] - provide a user selected end type or use local state by default
   * @param {number} [tierDays=taskingTier] - provide a user selected tier or use local state by default
   * @returns {number|null}
   */
  const getRepeatEndDate = ({
    endType = repetitionEndType
  } = {}) => {
    if (endType && endType === RepeatRequestEndTypes.DATE) {
      if (customCloseDate) {
        if (repeatEnd) {
          return repeatEnd;
        } else {
          // customCloseDate was set in standard task before switching to repeat
          // So set a default of 30 days from repeatStart
          const thirtyDays = new Date(repeatStart + THIRTY_DAYS_MS);
          return thirtyDays;
        }
      }

      // if end date was not explicitly set, we default to 1 month out from start date
      const startDate = new Date(parseInt(repeatStart));
      const endDate = new Date(startDate);
      endDate.setUTCMonth(endDate.getMonth() + 1);
      return new Date(endDate).valueOf();
    }

    return null;
  };

  function handleCollectionType ({ value: newCollectionType }) {
    const { accessParameters: defaultAccessConstraints, collectParameters: defaultCollectConstraints } = collectionTypes.find(({ name }) => name === newCollectionType);

    updateTaskState({
      collectionType: newCollectionType,
      shouldRefreshAccessTimes: true,
      collectConstraints: {
        imageWidth: defaultCollectConstraints.imageWidth.default,
        offNadirMin: defaultAccessConstraints.offNadirMin.default,
        offNadirMax: defaultAccessConstraints.offNadirMax.default,
        collectMode: defaultCollectConstraints.collectMode.default,
        polarization: defaultCollectConstraints.polarization.default
      }
    });
  }

  function handleOnCollectionTierChange ({ value: newCollectionTier }) {
    const { days } = TaskCollectionTiers.properties[newCollectionTier];
    const newTaskState = {
      taskingTier: parseInt(days, 10),
      collectionTier: newCollectionTier
    };

    if (isRepeatTask) {
      newTaskState.repetitionProperties = {
        repeatEnd: getRepeatEndDate()
      };
    } else {
      const open = windowOpenInt && new Date(windowOpenInt);
      const close = new Date(open);
      close.setUTCDate(close.getUTCDate() + parseInt(days));
      const closed = new Date(close).valueOf();

      newTaskState.shouldRefreshAccessTimes = true;
      newTaskState.windowCloseInt = !customCloseDate ? closed : windowCloseInt;
    }

    updateTaskState(newTaskState);
  }

  function handleOnWindowOpenChange ({ target: { value } = {} } = {}) {
    let newTaskState = {
      shouldRefreshAccessTimes: true
    };

    if (isRepeatTask) {
      newTaskState.repetitionProperties = {
        repeatStart: parseInt(value, 10),
        repeatEnd: getRepeatEndDate()
      };
    } else {
      const open = value && new Date(parseInt(value));
      const close = new Date(open);
      close.setUTCDate(close.getUTCDate() + parseInt(taskingTier));
      let closed = new Date(close).valueOf();
      let isCustomClose = false;

      // if close date was customized, and close date is valid, preserve the close date
      if (customCloseDate && windowCloseInt > open.valueOf()) {
        closed = windowCloseInt;
        isCustomClose = true;
      }

      newTaskState = {
        ...newTaskState,
        windowOpenInt: parseInt(value, 10),
        windowCloseInt: closed,
        customCloseDate: isCustomClose
      };
    }

    updateTaskState(newTaskState);
  }

  function handleOnWindowCloseChange ({ target: { value } = {} } = {}) {
    if (value) {
      const parsedValue = parseInt(value, 10);

      const newTaskState = {
        shouldRefreshAccessTimes: true,
        customCloseDate: true
      };

      if (isRepeatTask) {
        newTaskState.repetitionProperties = {
          repeatEnd: parsedValue
        };
      } else {
        newTaskState.windowCloseInt = parsedValue;
      }

      updateTaskState(newTaskState);
    } else {
      updateTaskState({
        customCloseDate: false
      });
    }
  }

  /**
   * this event handler causes a bunch of render side effects, so we need to keep the state clean when it changes
   */
  function handleRepeatCycleChange (selection) {
    const { value: repeatCycleValue } = selection;

    const advancedState = {
      preApproval: false,
      repetitionProperties: {
        repeatCycle: repeatCycleValue
      },
      collectConstraints: {
        imageWidth: taskDefaults.DEFAULT_IMAGE_WIDTH
      }
    };

    const minimalState = {
      ...advancedState,
      ascDsc: '',
      repetitionProperties: {
        ...advancedState.repetitionProperties,
        maintainSceneFraming: false
      },
      collectConstraints: {
        ...advancedState.collectConstraints
      }
    };

    const defaultState = {
      ...minimalState,
      orbitalPlanes: undefined
    };

    if (!isRetask && repeatCycleValue === RepeatCycleTypes.DAILY) {
      updateTaskState(defaultState);
    } else if (!isRetask && repeatCycleValue === RepeatCycleTypes.WEEKLY) {
      updateTaskState(minimalState);
    } else if (
      !isRetask &&
      (repeatCycleValue === RepeatCycleTypes.BIWEEKLY ||
      repeatCycleValue === RepeatCycleTypes.MONTHLY)
    ) {
      updateTaskState(advancedState);
    } else {
      updateTaskState({
        repetitionProperties: {
          repeatCycle: repeatCycleValue
        }
      });
    }
  }

  function handleAnalyticsChange ({ value: analyticsValue }) {
    const updatedTaskState = {
      analytics: analyticsValue ? [analyticsValue] : undefined
    };

    if (analyticsValue === 'ACD') {
      updatedTaskState.repetitionProperties = {
        maintainSceneFraming: true,
        repeatCycle: RepeatCycleTypes.BIWEEKLY
      };
    }

    updateTaskState(updatedTaskState);
  }

  const { AVAILABLE_REPEAT_CYCLE_OPTIONS, ACD_REPEAT_CYCLE_OPTIONS } = taskDefaults.repeat;

  return (
    <>
      <FormRow>
        <FormInput
          id="collectionType"
          type="select"
          label="Collection Type"
          clearable={false}
          disabled={isRetask}
          options={
            !activeAnalytic
              ? collectionTypeSelectOptions
              : getAvailableAnalyticsCollectTypes(activeAnalytic, collectionTypeSelectOptions)
          }
          defaultValue={collectionType}
          onChange={handleCollectionType}
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="collectionTier"
          key={collectionTier}
          type="select"
          label="Collection Tier"
          clearable={false}
          options={collectionTierOptions}
          defaultValue={collectionTier}
          required={true}
          onChange={handleOnCollectionTierChange}
        />
      </FormRow>

      <FormRow>
        <FormInput
          key={`start-datetime-input-${isRepeatTask}`}
          id={isRepeatTask ? 'repeatStart' : 'windowOpen'}
          label={`${isRepeatTask ? 'Repeat Task Start' : 'Window Open'} (UTC)`}
          type="datetime"
          value={isRepeatTask ? repeatStart : windowOpenInt}
          closeOnSelectDate={true}
          allowPastDate={false}
          utc={true}
          validationMessage="Please select a window start time after the current time"
          onChange={handleOnWindowOpenChange}
        />
        {isRepeatTask ? (
          <FormInput
            id="repetitionEndType"
            type="select"
            label="Repeat Task End"
            clearable={false}
            options={taskDefaults.repeat.AVAILABLE_REPEAT_TASK_END_OPTIONS}
            defaultValue={repetitionEndType}
            required={true}
            onChange={({ value }) => {
              updateTaskState({
                repetitionProperties: {
                  repetitionEndType: value,
                  repeatEnd: getRepeatEndDate({ endType: value }),
                  repetitionCount:
                    value === RepeatRequestEndTypes.COUNT ? 2 : null
                },
                customCloseDate: false,
                shouldRefreshAccessTimes: true
              });
            }}
          />
        ) : (
          <FormInput
            id="windowClose"
            label="Window Close (UTC)"
            type="datetime"
            value={windowCloseInt}
            disableFrom={{
              from: ReactDatetime.moment.utc(new Date(windowOpenInt))
            }}
            closeOnSelectDate={true}
            allowPastDate={false}
            utc={true}
            validationMessage="Please select a window close time after the tasking tier period"
            onChange={handleOnWindowCloseChange}
          />
        )}
      </FormRow>

      {(isRepeatTask && repetitionEndType !== RepeatRequestEndTypes.INDEFINITE) && (
        <FormRow className="form-row-indent">
          {repetitionEndType === RepeatRequestEndTypes.DATE && (
            <FormInput
              id="repeatEnd"
              label="Repeat Task End (UTC)"
              type="datetime"
              value={repeatEnd}
              disableFrom={{
                from: ReactDatetime.moment.utc(new Date(repeatStart))
              }}
              showClear={true}
              allowPastDate={false}
              utc={true}
              validationMessage="Please select a window close time after the tasking tier period"
              onChange={handleOnWindowCloseChange}
              className="no-max-w"
              closeOnSelectDate={true}
            />
          )}
          {repetitionEndType === RepeatRequestEndTypes.COUNT && (
            <FormInput
              id="repetitionCount"
              name="repetitionCount"
              type="number"
              label="Number of Collects (2 - 60)"
              defaultValue={repetitionCount}
              min={2}
              step={1}
              max={60}
              validationMessage="Please enter a minimum of 2 or maximum of 60 number of collects"
              onChange={({ target }) =>
                updateTaskState({
                  repetitionProperties: {
                    repetitionCount: parseInt(target?.value, 10) || -1
                  }
                })
              }
            />
          )}
        </FormRow>
      )}

      <FormRow className="form-row-block form-row-toggle-group">
        <label>Collection Time</label>
        <ToggleButtonGroup
          id="localTime"
          disabled={isRetask}
          className={`${isRetask ? 'disabled disabled-visually' : ''}`}
          activeValue={localTime}
          options={taskDefaults.AVAILABLE_COLLECT_TIMES.options}
          onChange={(value) => updateTaskState({
            collectConstraints: {
              localTime: value
            },
            shouldRefreshAccessTimes: true
          })}
        />
      </FormRow>

      {isRepeatTask && (
        <>
          <FormRow>
            <div className="search-dropdowns" data-testid="repeat-cycle">
              <FormInput
                id="repeatCycle"
                type="select"
                label="Repeat Cycle"
                clearable={false}
                options={acdIsSelected ? ACD_REPEAT_CYCLE_OPTIONS : AVAILABLE_REPEAT_CYCLE_OPTIONS}
                value={AVAILABLE_REPEAT_CYCLE_OPTIONS.find(opt => opt.value === repeatCycle)}
                onChange={handleRepeatCycleChange}
              />
            </div>
          </FormRow>
          {repeatCycle === RepeatCycleTypes.OTHER && (
            <FormRow className="form-row-indent pad-bottom-one">
              <div className="search-dropdowns" data-testid="number-of-days">
                <FormInput
                  id="repetitionInterval"
                  name="repetitionInterval"
                  type="number"
                  label="Number of days (2 - 90)"
                  defaultValue={repetitionInterval}
                  min={2}
                  step={1}
                  max={90}
                  onChange={({ target }) =>
                    updateTaskState({
                      repetitionProperties: {
                        repetitionInterval: parseInt(target?.value, 10) || -1
                      }
                    })
                  }
                  validationMessage="Please enter a minimum of 2 or maximum of 90 days"
                />
              </div>
            </FormRow>
          )}
        </>
      )}

      <FormRow>
        <FormInput
          id="analytics"
          type="select"
          label="Analytics"
          clearable={true}
          disabled={isRetask}
          defaultValue={activeAnalytic}
          className="analytics-select"
          options={analyticsOptions}
          onChange={handleAnalyticsChange}
        />
      </FormRow>
    </>
  );
};

FormCreateTaskFields.propTypes = {
  isPointTask: PropTypes.bool,
  isRepeatTask: PropTypes.bool,
  acdIsSelected: PropTypes.bool,
  activeAnalytic: PropTypes.string,
  defaultCollectionType: PropTypes.object
};

export default FormCreateTaskFields;
