import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Button } from 'fogg/ui';

import Layout from 'components/Layout';
import Container from 'components/Container';

const ThankYouLayout = ({
  children,
  basePage,
  title = 'Thank You!',
  actionRoutes = [],
  layout = {}
}) => {
  const hasActionRoutes =
    Array.isArray(actionRoutes) && actionRoutes.length > 0;
  let bodyClass = 'page-thank-you';

  if (typeof basePage === 'string') {
    bodyClass = `${bodyClass} page-${basePage}-thank-you`;
  }

  return (
    <Layout footer={true} {...layout}>
      <Helmet bodyAttributes={{ class: bodyClass }}>
        <title>{title}</title>
      </Helmet>
      <Container className="content" type="centered">
        <h1>{title}</h1>

        {children}

        {hasActionRoutes && (
          <div className="content-actions">
            <ul className="text-center">
              {actionRoutes.map(({ to, label } = {}, index) => {
                return (
                  <li key={`ThankYou-Action-${index}`}>
                    <Button to={to}>{label}</Button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </Container>
    </Layout>
  );
};

ThankYouLayout.propTypes = {
  children: PropTypes.node.isRequired,
  basePage: PropTypes.string,
  title: PropTypes.string,
  actionRoutes: PropTypes.array,
  layout: PropTypes.object
};

export default ThankYouLayout;
