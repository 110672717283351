import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import L from 'leaflet';
import turfArea from '@turf/area';
import bboxPolygon from '@turf/bbox-polygon';
import bbox from '@turf/bbox';

import { Button, Modal } from 'fogg/ui';
import { clearNotice } from 'state/actions';

import { FaTimes, FaUpload, FaArrowLeft } from 'react-icons/fa';
import StatusList from './StatusList';

/**
 * Upload GeoJSON validation modal for creating a new AOI (pulled from Analytics/CHMO)
 * @param {boolean} isOpen - is the modal open?
 * @param {func} onUploadComplete - callback to pass uploaded geoJson to map after validation
 * @param {func} [handleClosed] - callback to ontrol the closing of the modal
 */
const ModalAOIUpload = ({ isOpen, handleClosed, onUploadComplete }) => {
  const dispatch = useDispatch();
  const [fileState, SetFileState] = useState({ fileName: '', geo: {} });
  const { fileName, geo } = fileState;

  let fileReader;

  // Keep track of upload status for modal
  const [uploadActive, setUploadActive] = useState(false);
  const [uploadValid, setUploadValid] = useState(false);

  // Array of value objects to send to StatusList
  const [statusList, setStatusList] = useState([]);
  const [listProperties, setListProperties] = useState([]);

  const resetUploadState = () => {
    SetFileState({ fileName: '', geo: {} });
    setStatusList([]);
    setUploadValid(false);
    setUploadActive(false);
  };

  const onClosed = e => {
    resetUploadState();
    handleClosed(e);
  };

  // Scans a geojson's properties for HTML. Either warns or removes them.
  function scrubGeoProperties (geo) {
    const warningMessage =
      <span className="text-warning">
        Warning: HTML found, this property will not be passed to the API.
      </span>;

    if (Object.prototype.hasOwnProperty.call(geo, 'properties')) {
      for (const [key, value] of Object.entries(geo.properties)) {
        if (value === warningMessage) {
          delete geo.properties[key];
        }

        if (
          value !== null &&
          typeof value === typeof '' &&
          value.includes('<html') &&
          value.includes('</html>')
        ) {
          geo.properties[key] = warningMessage;
        }
      }
    }
  }

  /* eslint-disable no-prototype-builtins, react/prop-types */
  const isValidGeoJson = json => {
    let feature = null;
    if (json && json.features) {
      if (json.hasOwnProperty('features') && json.features.length > 0) {
        feature = json.features[0];
      } else if (json.hasOwnProperty('type') && json.type === 'Feature') {
        feature = json;
      }

      // Notify if a property found contains HTML, it will not be passed to the API (API Gateway doesn't accept HTML at all).
      scrubGeoProperties(feature);

      if (feature.hasOwnProperty('geometry') === false) {
        return 'Invalid GeoJson: Missing geometry.';
      }

      const geometry = feature.geometry;

      if (
        geometry.hasOwnProperty('type') === false ||
        (geometry.type !== 'Polygon' && geometry.type !== 'MultiPolygon')
      ) {
        return 'Invalid GeoJson: Geometry type must be a Polygon!';
      }

      const isInvalidPolygon = coordinates => {
        // Coordinates must be array
        if (!Array.isArray(coordinates)) {
          return 'Invalid geometry coordinates, must be array of rings.';
        }
        // Polygon coordinates must me a list of rings
        if (coordinates.length === 0) {
          return 'Invalid geometry coordinates, must contain rings.';
        }

        return false;
      };

      // Check for self-intersecting polygon
      if (geometry.type === 'Polygon') {
        const error = isInvalidPolygon(geometry.coordinates);
        if (error) {
          return error;
        }
      }

      // Check for self-intersecting MultiPolygon
      if (geometry.type === 'MultiPolygon') {
        return 'MultiPolygon geometry is not yet supported.';
      }

      try {
        L.geoJSON(json);
      } catch {
        return 'Invalid GeoJson: Could not parse geometry.';
      }
    }

    return null;
  };
  /* eslint-enable no-prototype-builtins, react/prop-types */

  const OnSubmit = e => {
    dispatch(clearNotice());
    resetUploadState();
    handleClosed(e);

    let geoSubmitting = null;

    /* eslint-disable-next-line no-prototype-builtins */
    if (geo.hasOwnProperty('features')) {
      geoSubmitting = geo.features[0];
    } else {
      geoSubmitting = geo;
    }

    // Drop any properties that contain HTML before submitting the payload to API.
    scrubGeoProperties(geoSubmitting);

    onUploadComplete(geoSubmitting);
  };

  // Upload action triggers a new File Preview/Status, including validation
  const onFileRead = async name => {
    // Build array to pass to StatusList as we validate
    const tempStatusList = [];
    const tempProperties = [];

    try {
      const json = JSON.parse(fileReader.result);
      SetFileState({ fileName: name, geo: json });
      setUploadActive(true);
      setUploadValid(true);

      // Add Filename
      tempStatusList.push({ label: 'Filename', value: name, valid: true });

      if (json) {
        // Build Features Count & Validity Object
        const featuresObject = {
          label: 'Feature Count',
          value: json.features ? json.features.length : 0
        };

        if (json.features && json.features.length) {
          featuresObject.value = json.features.length;
          featuresObject.valid = true;

          const warnings = [];

          if (json.features.length > 1) {
            warnings.push(
              'Multiple features found, only selecting the first feature.'
            );
          }

          // If feature has no properties, we will add an empty object
          if (!json.features[0].properties) {
            json.features[0].properties = {};
          }

          if (warnings.length) {
            featuresObject.details = <span className="text-warning">Warning: {warnings.join('; ')} </span>;
          }
        } else if (!json.features && json.type === 'Feature') {
          // If type = feature (Single Feature)
          featuresObject.value = 1;
          featuresObject.valid = true;
        } else {
          // Otherwise no features & type isn't feature
          featuresObject.value = 0;
          featuresObject.valid = false;
          featuresObject.details = 'Invalid GeoJson: Missing feature(s)';
          setUploadValid(false);
        }
        tempStatusList.push(featuresObject);

        const geometry = json.features
          ? json.features[0].geometry
          : json.geometry;
        const boundingBox = bboxPolygon(bbox(json));
        const area = turfArea(boundingBox);
        const areaSQKM = Number.parseFloat(
          (area / 1e6).toFixed(2)
        ).toLocaleString();

        // Check for other Geometry or JSON formatting errors
        const errorOccurred = await isValidGeoJson(json);

        if (errorOccurred !== null) {
          tempStatusList.push({
            label: 'Geometry',
            value: geometry && geometry.type ? geometry.type : '--',
            details: errorOccurred,
            valid: false
          });
          setUploadValid(false);
        } else {
          // Check minimum area and display if available
          if (area < (5000 * 5000)) {
            tempStatusList.push({
              label: 'Geometry',
              value: geometry && geometry.type ? geometry.type : '--',
              details: `Geometry area too small (${areaSQKM} sqkm). 25 km² minimum.)`,
              valid: false
            });
            setUploadValid(false);
          }
          // No Errors
          tempStatusList.push({
            label: 'Geometry',
            value: geometry && geometry.type ? geometry.type : '--',
            details:
              areaSQKM +
              ' sqkm' +
              (geometry.type === 'MultiPolygon'
                ? ` , ${geometry.coordinates[0].length} Polygon(s)`
                : ''),
            valid: true
          });
        }

        // Add GeoJSON (already checked above)
        tempStatusList.push({
          label: 'GeoJSON',
          value: 'Valid',
          valid: true
        });

        // Check for properties
        const feature = json.features ? json.features[0] : json;

        if (
          feature &&
          feature.properties &&
          Object.keys(feature.properties).length
        ) {
          const keys = Array.from(Object.keys(feature.properties));
          const properties = Array.from(Object.values(feature.properties));

          keys.forEach((key, i) => {
            tempProperties.push({
              label: keys[i],
              value: properties[i]
            });
          });
          setListProperties(tempProperties);
        } else {
          setListProperties([]);
        }
      }

      setStatusList(tempStatusList);
    } catch (err) {
      // Error in File Upload
      setUploadActive(true);
      setUploadValid(false);
      tempStatusList.push({
        label: 'JSON Format',
        value: 'Invalid',
        details: 'Failed to parse json object, malformed json.',
        valid: false
      });
      setStatusList(tempStatusList);
    }
  };

  const onFileChosen = file => {
    fileReader = new FileReader();
    fileReader.onloadend = e => onFileRead(file.name);
    fileReader.readAsText(file);
  };

  const fileText =
    fileName === '' ? (
      <>
        <a>Click to browse</a> or drag and drop to upload
      </>
    ) : (
      fileName
    );

  return (
    <Modal
      isOpen={isOpen}
      name="upload-aoi-modal"
      handleCloseModal={handleClosed}
      hasFooter={false}
      appElement="#___gatsby"
    >
      <div className="modal-header">
        <label className="modal-header-title">
          Upload Your Area of Interest
        </label>
        <button
          className="modal-header-close"
          aria-label="Close Modal"
          onClick={onClosed}
        >
          <FaTimes />
        </button>
      </div>

      <div className={`modal-body${!uploadValid ? ' invalid-upload' : ''}`}>
        {!uploadActive && (
          <>
            <div className="modal-body-input-wrapper">
              <div className="modal-body-input-content">
                <FaUpload className="modal-body-input-icon" size={70} />
                <label className="modal-body-input-text">{fileText}</label>
              </div>
              <input
                type="file"
                id="file"
                className="modal-body-input"
                accept=".json, .geojson"
                onChange={e => onFileChosen(e.target.files[0])}
              />
            </div>
            <div className="modal-body-accepted">
              <b>Accepted File Types</b>: .geojson .json
            </div>
            <div className="modal-body-disclaimer">
              Only the first Feature of a FeatureCollection will be accepted.
            </div>
          </>
        )}
        {uploadActive && (
          <>
            <h4 className="text-center text-color-gray-1 margin-top-0">
              UPLOAD AOI STATUS
            </h4>
            <StatusList list={statusList} properties={listProperties} />
          </>
        )}
      </div>

      <div className="modal-footer modal-footer-flex">
        {uploadActive && (
          <Button
            type="text"
            onClick={() => resetUploadState()}
            id="backButton"
          >
            <FaArrowLeft className="margin-right-1" /> Back
          </Button>
        )}
        <Button
          className="button"
          id="continueButton"
          onClick={OnSubmit}
          disabled={!uploadValid}
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};

ModalAOIUpload.propTypes = {
  isOpen: PropTypes.bool,
  handleClosed: PropTypes.func,
  onUploadComplete: PropTypes.func
};

export default ModalAOIUpload;
