import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Notice } from 'fogg/ui';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import AccessRequestItem from './AccessRequestItem';
import Arrow from './ScrollArrow';

/**
 * Main body that contains the horizontal scrolling UI of Access Times
 * @param {array} accesses
 * @param {string} message
 * @param {boolean} isPoint
 * @param {boolean} isLoading
 * @param {function} hoverAccess
 */

const AccessRequestsBody = ({
  accesses = [],
  message,
  isPoint,
  isLoading,
  hoverAccess,
  displayUtc
}) => {
  // Horizontal scroller state
  const [selected, setSelected] = useState('');

  const handleItemClick = (itemId) => () => {
    setSelected(selected !== itemId ? itemId : '');
  };

  return (
    <div className="access-requests" data-access-requests-is-loading={`${isLoading}`}>
      {accesses.length && isPoint ? (
        <ScrollMenu
          className="access-requests-times"
          LeftArrow={<Arrow direction='left' isLoading={isLoading} />}
          RightArrow={<Arrow direction='right' isLoading={isLoading} />}
        >
          {accesses.map((access) => {
            const { accessId } = access;
            return (
              <AccessRequestItem
                access={access}
                hoverAccess={hoverAccess}
                key={accessId}
                onClick={handleItemClick(accessId)}
                displayUtc={displayUtc}
              />
            );
          })}
        </ScrollMenu>
      ) : isLoading ? <div className="dot-flashing" /> : null}
      {message && (!isPoint || (!isLoading && !accesses?.length)) && (
        <Notice type="info" weight="bold">
          {message}
        </Notice>
      )}
    </div>
  );
};

AccessRequestsBody.propTypes = {
  accesses: PropTypes.array,
  message: PropTypes.string,
  isPoint: PropTypes.bool,
  isLoading: PropTypes.bool,
  hoverAccess: PropTypes.func,
  displayUtc: PropTypes.bool
};
export default AccessRequestsBody;
