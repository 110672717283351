import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAdmin } from 'hooks';

import { errorMessageByCode } from 'lib/errors';
import { logError } from 'lib/logger';
import { fetchOrganizationById, triggerNotice } from 'state/actions';
import Contract from 'commonLib/src/models/contract';

const errorBase = 'Failed to fetch organization';

export default function useAdminFetchOrganizationsContract ({
  organizationId: id
} = {}) {
  const dispatch = useDispatch();

  const { organizations = [], actions = {} } = useAdmin();

  // Temp
  const organization = organizations.find(({ id: orgId } = {}) => orgId === id);
  const contract = organization?.contract;

  if (typeof id !== 'string') {
    throw new Error(`${errorBase}: Invalid ID ${id}`);
  }

  /**
   * fetchOrganization
   */

  function fetchOrganization (organizationId) {
    dispatch(fetchOrganizationById(organizationId)).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchOrganization = useCallback(() => {
    fetchOrganization(id);
  }, [id]);

  useEffect(() => {
    memoizedFetchOrganization();
  }, [memoizedFetchOrganization]);

  return {
    fetchOrganization: memoizedFetchOrganization,
    ...actions.fetchOrganizationById,
    contract: new Contract(contract)
  };
}
