import { useMappedState } from 'redux-react-hook';
import Item from 'commonLib/src/models/item';
import { groupItemsByCollect } from 'commonLib/src/lib/items';

export default function useCart () {
  const mapState = ({ cart }) => cart;
  const cartState = useMappedState(mapState);
  const { items = [], contract = {}, addOnItems = [] } = cartState;
  const count = items.length + addOnItems.length;

  // Group the items by their parent collect ID
  const groupedByCollect = groupItemsByCollect(items);

  return {
    ...cartState,
    items: items.map((item) => new Item(item)),
    groupedItems: groupedByCollect,
    contract,
    count,
    addOnItems
  };
}
