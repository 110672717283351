class Collection {
  constructor (data) {
    Object.assign(this, data);

    this.stacVersion = this.stac_version;
    this.stacExtensions = this.stac_extensions;
  }
}

export default Collection;
