import React, { useState, useRef } from 'react';
import { flushSync } from 'react-dom';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

/**
 * Customizable download button for downloading data as csv via react-csv
 *
 * @param {object} props
 * @param {string|React.Component|HTMLElement} [props.as='button'] Element type for rending the download button
 * @param {string} [label='Export (.csv)']
 * @param {string} [downloadingLabel='Downloading...']
 * @param {string} [filename='csv-download.csv']
 * @param {Function} [onClickPromise] Promise based click handler
 * @param  {...any} [extraProps] any additional properties provided will be added to the element before rendering
 * @returns {CsvDownloadButton}
 */
const CsvDownloadButton = ({
  as = 'button',
  label = 'Export (.csv)',
  downloadingLabel = 'Downloading...',
  filename = 'csv-download.csv',
  onClickPromise,
  ...extraProps
}) => {
  const ButtonComponent = as;
  const csvLinkRef = useRef();
  const [exportCsvLoading, setExportCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  function handleClick () {
    if (exportCsvLoading) return false;
    if (typeof onClickPromise === 'function') {
      setExportCsvLoading(true);
      onClickPromise()
        .then((data) => {
          // Use flushSync here to ensure that we actually re-render twice (once with CSV data, once without). This is necessary to actually trigger
          // the download, as otherwise the component will only re-render once with no CSV data after the promise resolves in its entirety
          flushSync(() => {
            setCsvData(data);
          });
          csvLinkRef.current.link.click();

          setExportCsvLoading(false);
          setCsvData([]);
        })
        .catch(() => {
          setExportCsvLoading(false);
          setCsvData([]);
        });
    }
  }

  return (
    <>
      <ButtonComponent
        onClick={handleClick}
        {...extraProps}
        {...(exportCsvLoading ? { disabled: true } : undefined)}
      >
        {exportCsvLoading ? downloadingLabel : label}
      </ButtonComponent>
      <CSVLink
        data={csvData}
        ref={csvLinkRef}
        className="disabled-and-hidden"
        filename={filename}
      />
    </>
  );
};

CsvDownloadButton.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]),
  filename: PropTypes.string,
  label: PropTypes.string,
  downloadingLabel: PropTypes.string,
  onClickPromise: PropTypes.func
};

export default CsvDownloadButton;
