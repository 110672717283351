import clone from 'clone';
import deepmerge from 'deepmerge';

const defaultState = {
  actions: {},
  collectionTypes: []
};

const collectionTypes = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);

  switch (type) {
    case 'UPDATE_COLLECTION_TYPES': {
      return {
        ...workingState,
        collectionTypes: [...workingData]
      };
    }
    case 'UPDATE_COLLECTION_TYPES_ACTION_STATE': {
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    }
    default: {
      return workingState;
    }
  }
};

export default collectionTypes;
