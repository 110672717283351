import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FaUpload, FaCircle, FaSquareFull, FaBars } from 'react-icons/fa';
import { Button } from '@trussworks/react-uswds';
import IconPolygon from 'components/IconPolygon';

/**
 * Custom Draw & Upload panel for MapPreview
 * @param {function} triggerUploadModal - show/hide the ModalAOIUpload modal
 * @param {function} handleDraw - click handler/callback for drawing on map
 */
const MapPreviewDrawPanel = ({ triggerUploadModal, handleDraw }) => {
  const [panelCollapsed, setPanelCollapsed] = useState(false);

  return (
    <div className={`draw-aoi-panel ${panelCollapsed ? 'collapsed' : ''}`}>
      <div className="draw-aoi-panel-header">
        <Button
          type="text"
          className="collapse-button"
          onClick={() => setPanelCollapsed(!panelCollapsed)}
        >
          <FaBars />
        </Button>
        <h5>Draw Area of Interest</h5>
        <Button type="text" className="upload-button" onClick={triggerUploadModal}>
          <FaUpload /> Upload
        </Button>
      </div>
      <div className="draw-aoi-panel-body">
        <Button
          base={true}
          className="icon-before"
          onClick={e => handleDraw('polygon', e)}
          id="drawPolygon"
        >
          <IconPolygon className="icon-polygon" /> Polygon
        </Button>
        <Button
          base={true}
          className="icon-before"
          onClick={e => handleDraw('rectangle', e)}
          id="drawRectangle"
        >
          <FaSquareFull /> Rectangle
        </Button>
        <Button
          base={true}
          className="icon-before"
          onClick={e => handleDraw('circle', e)}
          id="drawCircle"
        >
          <FaCircle /> Circle
        </Button>
      </div>
    </div>
  );
};

MapPreviewDrawPanel.propTypes = {
  triggerUploadModal: PropTypes.func,
  handleDraw: PropTypes.func
};

export default MapPreviewDrawPanel;
