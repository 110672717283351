import React from 'react';
import PropTypes from 'prop-types';
import { useSubResellerFetchOrganization } from 'hooks';
import TemplateContractDetails from 'commonLib/src/templates/TemplateContractDetails';
import { SUB_RESELLER_ROLE } from 'data/roles';
import {
  ROUTE_SUB_RESELLER_ORGS,
  ROUTE_SUB_RESELLER_ORG_DETAILS
} from 'data/route-names';
import { routePathByName } from 'lib/routes';

import Contract from 'commonLib/src/models/contract';

const SubresellerOrganizationContractDetails = ({
  contractId,
  organizationId
}) => {
  const { organization = {}, isLoading } = useSubResellerFetchOrganization({
    id: organizationId
  });

  let contract =
    organization.contracts?.find((contract) => contract.id === contractId) ||
    {};
  if (typeof contract.isExpired !== 'function') {
    contract = new Contract(contract);
  }

  const organizationState = {
    isLoading
  };

  const organizationDetailsPath = routePathByName(ROUTE_SUB_RESELLER_ORG_DETAILS, {
    wildcard: [organizationId]
  });
  const organizationPath = routePathByName(ROUTE_SUB_RESELLER_ORGS);
  // default breadcrumbs for contract details view
  const breadcrumbs = [
    {
      label: 'Organizations',
      to: organizationPath
    },
    {
      label: 'Organization Details',
      to: organizationDetailsPath
    }
  ];

  return (
    <TemplateContractDetails
      accessType={SUB_RESELLER_ROLE}
      breadcrumbs={breadcrumbs}
      contract={contract}
      organization={organization}
      state={organizationState}
    />
  );
};

SubresellerOrganizationContractDetails.propTypes = {
  contractId: PropTypes.string,
  organizationId: PropTypes.string
};

export default SubresellerOrganizationContractDetails;
