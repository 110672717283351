import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { device } from 'fogg/lib';

import { useLocation, useBootstrap, useUi } from 'hooks';
import { useAccessRights } from 'commonLib/src/hooks';
import {
  chainRoutes,
  redirectTo,
  routePathByName,
  isPublicPath
} from 'lib/routes';

import AppLayout from 'components/AppLayout';
import ServerErrorPage from 'pages/500';

const { isDomAvailable } = device;

const Layout = ({
  children,
  className,
  path,
  navigation = true,
  footer = true,
  location,
  forceNew = false
}) => {
  const { search, pathname, queryParams = {} } = useLocation();

  const { next, redirect } = queryParams || {};

  const { isAuthorized = false, isLoggedIn } = useAccessRights({
    path: path || pathname
  });

  const { notice } = useUi() || {};

  useBootstrap({
    location,
    forceNew
  });

  // ?next used to redirect to specific url after login
  if (isDomAvailable() && next && isLoggedIn) {
    redirectTo(next);
  }

  // Handle redirects & logout for unauth'd users
  if (!isAuthorized) {
    if (isDomAvailable()) {
      if (isLoggedIn) {
        redirectTo(next || routePathByName('search'));
      } else if (!redirect && !isPublicPath(pathname)) {
        let redirectPath = pathname;
        if (search) redirectPath += search;
        redirectTo(
          chainRoutes([routePathByName('userLogout'), redirectPath])
        );
      } else if (!isPublicPath(pathname)) {
        redirectTo(routePathByName('userLogout'));
      }
    }
    return null;
  }

  const ErrorFallbackComponent = (props) => (
    <ServerErrorPage caughtError={props} />
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
      <AppLayout
        className={className}
        notice={notice}
        navigation={navigation}
        footer={footer}
      >
        {children}
      </AppLayout>
    </ErrorBoundary>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  className: PropTypes.string,
  navigation: PropTypes.bool,
  footer: PropTypes.bool,
  location: PropTypes.object,
  forceNew: PropTypes.bool,
  componentStack: PropTypes.string,
  error: PropTypes.object
};

export default Layout;
