import clone from 'clone';

import { exportClassData, updateClassData, sortByDateKey } from '../lib/util';
import { geometryToGeoJson } from '../lib/map';
import {
  DEFAULT_LOOK_DIRECTION,
  DEFAULT_ASC_DSC,
  DEFAULT_LOCAL_TIME,
  DEFAULT_OFF_NADIR_MIN,
  DEFAULT_OFF_NADIR_MAX,
  DEFAULT_COLLECT_MODE,
  DEFAULT_IMAGE_LENGTH,
  DEFAULT_IMAGE_WIDTH,
  DEFAULT_GRR_MIN,
  DEFAULT_GRR_MAX,
  DEFAULT_AZR_MIN,
  DEFAULT_AZR_MAX,
  DEFAULT_NESZ_MAX,
  DEFAULT_NUM_LOOKS,
  DEFAULT_COLLECTION_TIER
} from '../lib/tasks';
import TaskCollect from '../models/task-collect';
import TaskTile from '../models/task-tile';
import { TASKING_REQUEST_ID } from '../data/property-names';

const defaultTask = {
  type: 'Feature',
  geometry: null,
  properties: {
    submissionTime: null,
    [TASKING_REQUEST_ID]: null,
    taskingrequestName: null,
    taskingrequestDescription: null,
    orgId: null,
    userId: null,
    repeatrequestId: null,
    sandbox: false,
    windowOpen: null,
    windowDuration: null,
    windowClose: null,
    collectionTier: DEFAULT_COLLECTION_TIER,
    statusHistory: [],
    collectConstraints: {
      lookDirection: DEFAULT_LOOK_DIRECTION,
      targetHeight: null,
      orbitalPlanes: [],
      ascDsc: DEFAULT_ASC_DSC,
      localTime: DEFAULT_LOCAL_TIME,
      offNadirMin: DEFAULT_OFF_NADIR_MIN,
      offNadirMax: DEFAULT_OFF_NADIR_MAX,
      collectMode: DEFAULT_COLLECT_MODE,
      imageWidth: DEFAULT_IMAGE_WIDTH,
      imageLength: DEFAULT_IMAGE_LENGTH,
      grrMax: DEFAULT_GRR_MAX,
      grrMin: DEFAULT_GRR_MIN,
      azrMax: DEFAULT_AZR_MAX,
      azrMin: DEFAULT_AZR_MIN,
      neszMax: DEFAULT_NESZ_MAX,
      numLooks: DEFAULT_NUM_LOOKS,
      polarization: null
    },
    preApproval: false,
    order: undefined,
    transactionStatus: undefined
  }
};

class Task {
  constructor (data = {}) {
    Object.assign(this, data);
    this._data = clone(defaultTask);
  }

  ingestTaskBody (data) {
    updateClassData(this._data, {
      ...data
    });

    this.updateInstanceProperties();

    return this;
  }

  updateInstanceProperties () {
    this.geometry = this._data.geometry && { ...this._data.geometry };
    this.name = this._data.properties.taskingrequestName;
    this.description = this._data.properties.taskingrequestDescription;
    this.id = this._data.properties[TASKING_REQUEST_ID];
    this.windowOpen = this._data.properties.windowOpen;
    this.windowOpenInt = new Date(this.windowOpen).getTime();
    this.windowClose = this._data.properties.windowClose;
    this.windowCloseInt = new Date(this.windowClose).getTime();
    this.collectionTier = this._data.properties.collectionTier;
    this.collectMode = this._data.properties.collectConstraints.collectMode;
    this.preApproval = this._data.properties.preApproval;
    this.user = this._data.user;
    this.order = this._data.properties.order;
    this.transactionStatus = this._data.properties.transactionStatus;
    this.orbitalPlanes = this._data.properties.collectConstraints.orbitalPlanes;
    this.ascDsc = this._data.properties.collectConstraints.ascDsc;
    this.lookDirection = this._data.properties.collectConstraints.lookDirection;
    this.targetHeight = this._data.properties.collectConstraints.targetHeight;
    this.productCategory = this._data.properties.productCategory;
    this.archiveHoldback = this._data.properties.archiveHoldback;
    this.collectConstraints = this._data.properties.collectConstraints;
  }

  update (data = {}) {
    const task = updateClassData(this._data, data);
    this.updateInstanceProperties();
    return task;
  }

  data () {
    return exportClassData(this);
  }

  geoJson () {
    return this.geometry && geometryToGeoJson(this.geometry);
  }

  get lastStatus () {
    const history = this._data.properties.statusHistory;

    if (!Array.isArray(history)) return undefined;

    const sorted = sortByDateKey(history, 'time');
    const length = sorted.length;

    return sorted[length - 1];
  }

  get status () {
    return this.lastStatus && this.lastStatus.code;
  }

  get statusMessage () {
    return this.lastStatus && this.lastStatus.message;
  }

  get windowDuration () {
    return durationFromWindow(this.windowOpenInt, this.windowCloseInt);
  }

  get collects () {
    const collects = this._data.properties && this._data.properties.collects;
    return collects ? collects.map((collect) => new TaskCollect(collect)) : [];
  }

  get tiles () {
    const tiles = this._data.properties && this._data.properties.tiles;
    return tiles ? tiles.map((collect) => new TaskTile(collect)) : [];
  }

  get collectsWithTiles () {
    const collects = this.collects;
    const tiles = this.tiles;

    return collects.map((collect) => {
      const { tileId: collectTileId } = collect;

      const tile = tiles.find(
        ({ properties }) => properties.tileId === collectTileId
      );

      return {
        collect,
        tile
      };
    });
  }

  get isSandbox () {
    return !!(this._data.properties && this._data.properties.sandbox);
  }
}

export default Task;

/**
 * durationFromWindow
 */

function durationFromWindow (open, close) {
  let windowOpen = open;
  let windowClose = close;

  if (!(windowOpen instanceof Date)) {
    windowOpen = new Date(windowOpen);
  }

  if (!(windowClose instanceof Date)) {
    windowClose = new Date(windowClose);
  }

  // The API wants seconds instead of miliseconds

  return (windowClose - windowOpen) / 1000;
}
