import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { errorMessageByCode } from 'lib/errors';
import { useSubReseller, useSubResellerTask } from 'hooks';

import { logError } from 'lib/logger';
import { fetchSubResellerTaskByTaskId, triggerNotice } from 'state/actions';
import { TASKING_REQUEST_ID } from 'data/property-names';

export default function useSubResellerFetchTask (taskId, forceNew = false) {
  const dispatch = useDispatch();

  const { actions = {} } = useSubReseller();
  const task = useSubResellerTask(taskId) || {};
  const { properties = {} } = task;
  const taskingrequestId = properties[TASKING_REQUEST_ID];

  useEffect(() => {
    if (!taskId || (taskingrequestId && !forceNew)) return;
    dispatch(fetchSubResellerTaskByTaskId(taskId)).catch((error) => {
      logError(error.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }, [dispatch, taskId, taskingrequestId, forceNew]);

  return {
    ...actions.fetchSubResellerTaskByTaskId,
    task
  };
}
