import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBitbucket } from 'react-icons/fa';
import { ROUTE_ORG, ROUTE_ORG_DETAILS, ROUTE_ORG_EDIT } from 'data/route-names';

import {
  routePathByName,
  redirectTo,
  logError,
  adminCreateNewOrganizationS3Bucket,
  triggerSuccess,
  triggerError,
  Breadcrumbs,
  PageHeader,
  Container,
  FormBucketConfigAddEdit
} from 'commonLib';
import { useOrgFetchOrganization } from 'hooks';

import Layout from 'components/Layout';

const PAGE_TITLE = 'Add Bucket Config';

const OrganizationsBucketConfigAddPage = () => {
  const { organization } = useOrgFetchOrganization();
  const organizationId = organization.id;

  const dispatch = useDispatch();

  const organizationEditPath = routePathByName(ROUTE_ORG_EDIT);
  const organizationOverviewPath = routePathByName(ROUTE_ORG);
  const organizationDetailsPath = routePathByName(ROUTE_ORG_DETAILS);

  function handleOnSubmit ({ data }) {
    dispatch(adminCreateNewOrganizationS3Bucket(organizationId, data))
      .then(() => {
        redirectTo(organizationEditPath);
        dispatch(
          triggerSuccess(
            `Successfully added bucket config ${data.bucketName}/${data.prefix}!`
          )
        );
      })
      .catch((error) => {
        logError(error.message, data);
        dispatch(
          triggerError(
            `Not able to add bucket config ${data.bucketName}/${data.prefix}!`
          )
        );
      });
  }

  function handleOnCancel () {
    redirectTo(organizationEditPath);
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-organization page-organization-bucket-config page-organization-bucket-config-add'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Organization',
              to: organizationOverviewPath
            },
            {
              label: 'Details',
              to: organizationDetailsPath
            }
          ]}
        />
        <PageHeader title={PAGE_TITLE} icon={<FaBitbucket />} />
        <FormBucketConfigAddEdit
          orgId={organizationId}
          mode="add"
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
        />
      </Container>
    </Layout>
  );
};

OrganizationsBucketConfigAddPage.propTypes = {};

export default OrganizationsBucketConfigAddPage;
