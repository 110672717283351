export const DEFAULT_ERROR_CODE = 'UNKNOWN_ERROR';

// prettier-ignore
export const ERROR_CODES = {
  UNKNOWN_ERROR: 'Uh oh, something went wrong. Please try again!',

  // User Management

  EMAIL_ALREADY_EXISTS: 'Email already exists. Please check the existing user or use a new email address.',

  // Authorization

  AUTHORIZATION_INSUFFICIENT_FUNDS: 'Insufficient funds available for this order.',
  UNKNOWN_AUTHORIZATION_ERROR: 'Unknown error during authorization.',

  // Search

  SEARCH_INVALID_JSON: 'Oops, looks like that file doesn\'t include valid JSON. Please try again.',
  SEARCH_INVALID_GEOJSON: 'Oops, looks like that file doesn\'t include valid GeoJSON. Please try again.'

};
