import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

/**
 * React tooltip wrapper, standardizes and simplifies tooltip generation
 * @param {string} id
 * @param {string} tooltipText
 * @param {string} className
 * @param {string} placement
 * @param {node} icon
 * @param {node} children
 * @param {object} rest Any other react-tooltip props to pass through
 */

const Tooltip = ({
  id = 'tool-tip',
  tooltipText,
  className,
  placement = 'top',
  icon,
  children,
  ...rest
}) => {
  let classString = 'tool-tip';
  if (className) classString += ` ${className}`;

  // Different CSS class if default icon-based vs. children passed in
  let triggerClass = 'general-tool-tip';
  if (children) {
    triggerClass = 'tool-tip-trigger';
  }
  return (
    <div className={classString}>
      <span className={triggerClass} data-tip data-for={id}>
        {children || (icon || '?')}
      </span>
      <ReactTooltip
        id={id}
        place={placement}
        effect="solid"
        type="dark"
        multiline={true}
        className="customTip"
        {...rest}
      >
        {tooltipText && tooltipText}
      </ReactTooltip>
    </div>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  placement: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node,
  rest: PropTypes.object
};

export default Tooltip;
