import { useMappedState } from 'redux-react-hook';
import Item from '../models/item';

export default function useItem ({ collectionId, itemId }) {
  const mapState = ({ items }) => items;

  const { items } = useMappedState(mapState);

  if (!Array.isArray(items) || items.length === 0) {
    return;
  }

  const itemCollection = items.map((item) => new Item(item));

  return itemCollection.find(
    (item) => item.id === itemId && item.collection === collectionId
  );
}
