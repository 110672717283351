import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy, FaRegCopy } from 'react-icons/fa';
import HelpfulButton from './HelpfulButton';

/**
 * Button for copying text to user's clipboard
 *
 * @param {object} props
 * @param {string} props.copyText Text to be copied to clipboard
 * @param {string} [props.helperText='Copied to clipboard!'] Text to display after copying/button click
 * @param {string} [props.className=''] Custom CSS class override
 * @param {Function} [props.onClick] optional click handler after text is copied
 */

const CopyButton = ({
  copyText,
  helperText = 'Copied to clipboard!',
  className = '',
  onClick
}) => {
  const [copied, setCopied] = useState(false);

  function onCopyHandler (e, bool) {
    setCopied(true);
    if (typeof onClick === 'function') {
      onClick(e, bool);
    }
  }

  // Watch for copied state, reset it after 3 seconds to clear notification
  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [copied]);

  let parentClass = 'copy-button';
  if (copied) parentClass += ' copied';
  if (className) parentClass += ` ${className}`;

  return (
    <CopyToClipboard text={copyText} onCopy={onCopyHandler}>
      <HelpfulButton
        id={`copy-${copyText}`}
        className={parentClass}
        tooltipId={`copy-${copyText}`}
        tooltipText={copied ? 'Text copied!' : helperText }
      >
        <span id="copyCollectId">
          {copied ? <FaCopy /> : <FaRegCopy />}
        </span>
      </HelpfulButton>
    </CopyToClipboard>
  );
};

CopyButton.propTypes = {
  copyText: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default CopyButton;
