import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useCollectionTypes } from 'hooks';

import { logError } from 'lib/logger';
import { triggerNotice, fetchCollectionTypes } from 'state/actions';

export default function useFetchCollectionTypes (forceNew = false) {
  const dispatch = useDispatch();

  const { actions, collectionTypes } = useCollectionTypes();

  useEffect(() => {
    if (collectionTypes?.length && !forceNew) return;
    dispatch(fetchCollectionTypes()).catch((error) => {
      logError(error.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
    });
  }, [forceNew, dispatch]);

  return {
    ...actions.fetchCollectionTypes,
    collectionTypes
  };
}
