import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FaUsers } from 'react-icons/fa';
import {
  DataTable,
  TableSearchInput,
  TableSearchFilters,
  TableActions
} from 'fogg/ui';
import { useTableData } from 'fogg/hooks';

import { routePathByName } from 'lib/routes';
import {
  organizationsToTableData,
  organizationsTableDataTransformers
} from '../lib/organizations';
import { DEFAULT_ORGS_TABLE_COLS } from '../data/organization';
import { combineColumnsWithTransformers } from '../lib/tables';
import { sortByKey, renderOrNot } from '../lib/util';
import { fillEmptyOrganizations } from '../lib/data';
import NavigatorLayout from '../components/NavigatorLayout';
import NavigatorSidebar from '../components/NavigatorSidebar';
import NavigatorBody from '../components/NavigatorBody';
import Breadcrumbs from '../components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from '../components/Container';

const DEFAULT_COLUMNS = combineColumnsWithTransformers(
  DEFAULT_ORGS_TABLE_COLS,
  organizationsTableDataTransformers
);

const DISABLED_FILTER_MENU_OPTIONS = [{
  columnId: 'disabled',
  type: 'radio',
  Header: 'Status'
}];

const TemplateOrganizations = ({
  state = {},
  options = {},
  organizations,
  tableColumns = DEFAULT_COLUMNS,
  breadcrumbs = [],
  emptyActions = []
}) => {
  const { isLoading = false } = state;

  const {
    detailsRouteName,
    createRouteName,
    itemName = 'Organization',
    bodyClass = 'template-organizations',
    pageTitle = 'Organizations',
    showSearchFilters = false
  } = options;

  let activeOrganizations = organizations && [...organizations];

  // If we're loading, fill the data with empty organizations to add a loading state

  if (isLoading) {
    activeOrganizations = fillEmptyOrganizations();
  }

  activeOrganizations = sortByKey(activeOrganizations, 'name');

  const organizationsData = organizationsToTableData(activeOrganizations, {
    editPathName: detailsRouteName
  });

  const { columns, data, sort, filter, clearFilters, filters } = useTableData({
    columns: tableColumns,
    data: organizationsData
  });

  const searchFilters = filters.find(
    ({ filterType } = {}) => filterType === 'search'
  );
  const { filterValue } = searchFilters || {};

  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;

  /**
   * handleOnSort
   */

  function handleOnSort (cell) {
    sort(cell);
  }

  /**
   * handleOnFilter
   */

  function handleOnFilter (filterData, cell) {
    filter(filterData, cell);
  }
  /**
   * handleOnInputChange
   */

  function handleOnInputChange ({ currentTarget = {} } = {}) {
    const { value } = currentTarget;

    const filterData = {
      value
    };

    handleOnFilter(filterData);
  }

  /**
   * handleOnFiltersChange
   */
  function handleOnFiltersChange ({ columnId, selectedOptions } = {}) {
    const checkedOptions = selectedOptions.filter(
      ({ isChecked } = {}) => !!isChecked
    );
    const values = checkedOptions.map(({ value } = {}) => value);

    const cell = {
      filterType: 'checklist',
      columnId
    };

    handleOnFilter({
      value: values
    }, cell);
  }

  return (
    <NavigatorLayout className="layout-navigator fixed-footer">
      <Helmet bodyAttributes={{ class: bodyClass }}>
        <title>{pageTitle}</title>
      </Helmet>
      <Container type="full">
        <NavigatorSidebar>
          {hasBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}

          <PageHeader
            title={pageTitle}
            icon={<FaUsers className="icon-fa" />}
          />

          <TableSearchInput
            value={filterValue}
            onChange={handleOnInputChange}
            onClear={clearFilters}
          />
          {renderOrNot(showSearchFilters, (
            <TableSearchFilters
              isLoading={isLoading}
              options={DISABLED_FILTER_MENU_OPTIONS}
              defaultTableData={organizationsData}
              onChange={handleOnFiltersChange}
            />
          ))}
        </NavigatorSidebar>

        <NavigatorBody>
          <TableActions
            actions={[
              {
                to: routePathByName(createRouteName),
                label: `Create a New ${itemName}`,
                buttonType: ['text', 'icon-before'],
                icon: 'FaPlusCircle'
              }
            ]}
          />

          <div className="data-table-wrapper with-side-layout">
            <DataTable
              label="Organizations"
              onSort={handleOnSort}
              columns={columns}
              data={data}
              isLoading={isLoading}
              emptyActions={emptyActions}
            />
          </div>
        </NavigatorBody>
      </Container>
    </NavigatorLayout>
  );
};

TemplateOrganizations.propTypes = {
  state: PropTypes.object,
  options: PropTypes.object,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableColumns: PropTypes.array,
  breadcrumbs: PropTypes.array,
  emptyActions: PropTypes.array
};

export default TemplateOrganizations;
