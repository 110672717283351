import React from 'react';
import PropTypes from 'prop-types';
import { WonderLink } from 'fogg/ui';
import { FaAngleRight } from 'react-icons/fa';

const Breadcrumbs = ({ items }) => {
  const itemsLength = Array.isArray(items) && items.length;

  return (
    <div className="breadcrumbs">
      <ul>
        {itemsLength &&
          items.map(({ to, label }, index) => {
            const linkProps = {
              to
            };
            const isLast = index === itemsLength - 1;
            return (
              <React.Fragment key={`BreadcrumbItem-${index}`}>
                <li className="breadcrumbs-item">
                  <WonderLink {...linkProps}>{label}</WonderLink>
                </li>
                {!isLast && (
                  <li className="breadcrumbs-item breadcrumbs-divider">
                    <FaAngleRight />
                  </li>
                )}
              </React.Fragment>
            );
          })}
      </ul>
    </div>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.array
};

export default Breadcrumbs;
