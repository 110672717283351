import React from 'react';
import { Helmet } from 'react-helmet';
import { routePathByName } from 'lib/routes';
import { useUser } from 'hooks';
import { resellerRouteLinks, mapToLinkListItem } from 'commonLib';
import * as routeNames from 'commonLib/src/data/route-names';
import { IconByName } from 'commonLib/components';
import Layout from 'components/Layout';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import LinkBlockList from 'components/LinkBlockList';

const ResellerIndexPage = () => {
  let orgId;
  const { user = {} } = useUser();

  if (user && user.organizationId) {
    orgId = user.organizationId;
  }

  const links = resellerRouteLinks().map((link) =>
    mapToLinkListItem(
      link,
      (routeName) =>
        routePathByName(routeNames[routeName], { wildcard: [orgId] }),
      IconByName
    )
  );

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-reseller page-reseller-index' }}>
        <title>Reseller</title>
      </Helmet>
      <Container className="content">
        <PageHeader title="Reseller" icon={<IconByName name="FaStore" />} />
        <LinkBlockList links={links} />
      </Container>
    </Layout>
  );
};

export default ResellerIndexPage;
