import { constructRequestActionManager } from '../../lib/actions';
import { routePathByName } from 'lib/routes';

/**
 * setItemsActionState
 * @description Updates the items store's status
 */

export function setItemsActionState (status) {
  return {
    type: 'UPDATE_ITEMS_ACTION_STATE',
    data: status
  };
}

const createDispatchRequestAction =
  constructRequestActionManager(setItemsActionState);

/**
 * fetchItemById
 * @description Fetches a feature/item/granule by it's collection ID and item ID
 */

export const fetchItemById = ({ collectionId, itemId }) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchItemById',
      scope: 'items',
      request: {
        url: routePathByName('apiCollections'),
        method: 'fetch',
        params: {
          collection_id: collectionId,
          item_id: itemId
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(addItem([data]));

    return data;
  };
};

/**
 * fetchAllCollections
 * @description Fetches all available collections
 */

export const fetchAllCollections = () => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchAllCollections',
      scope: 'items',
      request: {
        url: routePathByName('apiCollections'),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request || {};
    const { collections } = data;

    dispatch(addCollections(collections));

    return data;
  };
};

/**
 * fetchStacRecordById
 * @description Fetches a stac record by its collection ID and item ID
 */

export const fetchStacRecordById = ({ collectionId, itemId }) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchStacRecordById',
      scope: 'orders',
      request: {
        url: routePathByName('apiStacDownload', {
          wildcard: [collectionId, itemId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(addStac([data]));

    return data;
  };
};
/******************
 * STATE UPDATERS *
 ******************/

/**
 * addItem
 * @description Add an item to the state
 */

export const addItem = (data) => {
  return {
    type: 'ADD_ITEM',
    data
  };
};

/**
 * updateActiveItem
 * @description Update the active item
 */

export const updateActiveItem = (data) => {
  return {
    type: 'UPDATE_ACTIVE_ITEM',
    data
  };
};

/**
 * clearActiveItem
 * @description Clear the active item
 */

export const clearActiveItem = (data) => {
  return {
    type: 'CLEAR_ACTIVE_ITEM',
    data
  };
};

/**
 * addCollections
 */

export const addCollections = (data) => {
  return {
    type: 'ADD_COLLECTIONS',
    data
  };
};

/**
 * addStac
 */

export const addStac = (data) => {
  return {
    type: 'ADD_STAC',
    data
  };
};
