import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'fogg/ui';

import { logError } from 'lib/logger';

import AppLayout from 'components/AppLayout';
import Container from 'components/Container';

const ErrorLayout = ({ children, caughtError = {} }) => {
  const { componentStack, error } = caughtError;

  useEffect(() => {
    if (!error) return;
    let message = error ? error.name : 'Unknown Error';
    message = `Caught Rendering Error: ${message}`;
    logError(message, {
      stackTrace: `${componentStack}`,
      details: `${error.message}`
    });
  }, [componentStack, error]);

  return (
    <AppLayout navigation={false}>
      <Container className="content" type="centered">
        {children}
        <p className="text-center">
          <Button to="/">Back to Home</Button>
        </p>
      </Container>
      {error && process.env.NODE_ENV === 'development' && (
        <Container className="content content-error">
          <h2>Here’s what we know…</h2>
          <h3>Error</h3>
          <pre>
            <code>{error.toString()}</code>
          </pre>
          <h3>Stacktrace</h3>
          <pre>
            <code>{componentStack}</code>
          </pre>
        </Container>
      )}
    </AppLayout>
  );
};

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
  caughtError: PropTypes.object
};

export default ErrorLayout;
