import React from 'react';
import PropTypes from 'prop-types';

// Is there a better name for this?

const NavigatorSidebar = ({ children }) => {
  return <div className="layout-navigator-sidebar">{children}</div>;
};

NavigatorSidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default NavigatorSidebar;
