import React from 'react';
import PropTypes from 'prop-types';

// Is there a better name for this?

const NavigatorBody = ({
  className = 'layout-navigator-body',
  children
}) => {
  return <div className={className}>{children}</div>;
};

NavigatorBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default NavigatorBody;
