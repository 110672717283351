import { TASKS_SCOPE } from 'data/scopes';
import {
  ROUTE_API_RETASK_TASK,
  ROUTE_API_RETASK_COLLECT,
  ROUTE_API_RETASK_REPEAT_REQUEST
} from 'data/route-names';
import { TaskRequestTypes } from 'data/task-request-types';
import { routePathByName } from 'lib/routes';
import { constructRequestActionManager } from 'commonLib/src/lib/actions';
import { setTasksActionState, addTask } from 'state/actions';

const createDispatchRequestAction =
  constructRequestActionManager(setTasksActionState);

const TASK_TO_RETASK_POST_PROPERTIES = {
  taskingrequestName: 'retaskRequestName',
  taskingrequestDescription: 'retaskRequestDescription',
  windowOpen: 'windowOpen',
  windowClose: 'windowClose',
  collectionTier: 'collectionTier',
  archiveHoldback: 'archiveHoldback'
};

const COLLECT_TO_RETASK_POST_PROPERTIES = {
  taskingrequestName: 'retaskRequestName',
  taskingrequestDescription: 'retaskRequestDescription',
  windowOpen: 'windowOpen',
  windowClose: 'windowClose',
  collectionTier: 'collectionTier',
  archiveHoldback: 'archiveHoldback',
  collectConstraints: {
    offNadirMin: 'offNadirMin',
    offNadirMax: 'offNadirMax'
  }
};

const REPEAT_REQUEST_TO_RETASK_POST_PROPERTIES = {
  repeatrequestName: 'retaskRequestName',
  repeatrequestDescription: 'retaskRequestDescription',
  repetitionProperties: {
    repeatStart: 'repeatStart',
    repeatEnd: 'repeatEnd',
    repetitionInterval: 'repetitionInterval',
    repetitionCount: 'repetitionCount'
  },
  collectionTier: 'collectionTier',
  archiveHoldback: 'archiveHoldback'
};

/**
 * postRetaskRequest
 * @description Retasks a previous task, customized with the provided data
 * @param {object} data The object data of the new task
 */
export const postRetaskRequest = (data = {}) => {
  return async (dispatch, getState) => {
    const { contractId, properties, fromArchive, origTaskingId } = data;
    const { taskRequestType = TaskRequestTypes.STANDARD } = properties;
    const isRepeatTask = taskRequestType === TaskRequestTypes.REPEAT;

    const taskData = {};
    if (contractId) {
      taskData.contractId = contractId;
    }

    let retaskingAPI;
    let taskProperties;
    if (fromArchive) {
      retaskingAPI = ROUTE_API_RETASK_COLLECT;
      taskProperties = COLLECT_TO_RETASK_POST_PROPERTIES;
    } else if (isRepeatTask) {
      retaskingAPI = ROUTE_API_RETASK_REPEAT_REQUEST;
      taskProperties = REPEAT_REQUEST_TO_RETASK_POST_PROPERTIES;
    } else {
      retaskingAPI = ROUTE_API_RETASK_TASK;
      taskProperties = TASK_TO_RETASK_POST_PROPERTIES;
    }

    // Limit the properties to only the ones we want to actively send
    Object.keys(taskProperties).forEach((property) => {
      if (!properties[property]) return;
      if (property === 'repetitionProperties' || property === 'collectConstraints') {
        Object.keys(taskProperties[property]).forEach((subProperty) => {
          if (!properties[property][subProperty]) return;
          taskData[taskProperties[property][subProperty]] = properties[property][subProperty];
        });
      } else {
        taskData[taskProperties[property]] = properties[property];
      }
    });

    const dispatchAction = createDispatchRequestAction({
      name: 'postRetaskRequest',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName(retaskingAPI, {
          wildcard: [origTaskingId]
        }),
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: taskData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(addTask(responseData));

    return responseData;
  };
};
