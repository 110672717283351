import { useMappedState } from 'redux-react-hook';

export default function useActiveItem () {
  const mapState = ({ items }) => items;

  const items = useMappedState(mapState);

  return {
    ...items.active
  };
}
