import React from 'react';

import {
  useSubResellerFetchUsers,
  useUser,
  useSubReseller
} from 'hooks';
import { routePathByName } from 'lib/routes';
import {
  ROUTE_SUB_RESELLER,
  ROUTE_SUB_RESELLER_USERS_CREATE,
  ROUTE_SUB_RESELLER_USER_DETAILS
} from 'data/route-names';

import { TemplateUsers, RESELLER_COLUMNS } from 'commonLib';

const breadcrumbs = [
  {
    label: 'Sub-Reseller',
    to: routePathByName(ROUTE_SUB_RESELLER)
  }
];

const SubResellerUsersPage = () => {
  let isLoadingUser;

  const { user = {} } = useUser();
  const { users: subResellerUsers = [] } = useSubReseller();

  if (!user.organizationId) {
    isLoadingUser = true;
  }

  // Determine if we're in a loading state between the various requests
  const { isLoading: isLoadingSubResellerUsers } = useSubResellerFetchUsers({
    subResellerId: user.organizationId
  });

  const isLoading = isLoadingUser || isLoadingSubResellerUsers;

  const usersState = {
    isLoading
  };

  const templateOptions = {
    detailsRouteName: ROUTE_SUB_RESELLER_USER_DETAILS,
    createRouteName: ROUTE_SUB_RESELLER_USERS_CREATE
  };

  return (
    <TemplateUsers
      users={subResellerUsers}
      breadcrumbs={breadcrumbs}
      state={usersState}
      options={templateOptions}
      tableColumns={RESELLER_COLUMNS}
    />
  );
};

export default SubResellerUsersPage;
