import React from 'react';
import { Helmet } from 'react-helmet';
import { routePathByName } from 'lib/routes';
import { useUser } from 'hooks';
import { subresellerRouteLinks, mapToLinkListItem } from 'commonLib';
import * as routeNames from 'commonLib/src/data/route-names';
import { IconByName } from 'commonLib/components';
import Layout from 'components/Layout';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import LinkBlockList from 'components/LinkBlockList';

const SubResellerIndexPage = () => {
  let orgId;
  const { user = {} } = useUser();

  if (user && user.organizationId) {
    orgId = user.organizationId;
  }

  const links = subresellerRouteLinks().map((link) =>
    mapToLinkListItem(
      link,
      (routeName) =>
        routePathByName(routeNames[routeName], { wildcard: [orgId] }),
      IconByName
    )
  );

  return (
    <Layout>
      <Helmet
        bodyAttributes={{ class: 'page-subreseller page-subreseller-index' }}
      >
        <title>Sub-Reseller</title>
      </Helmet>
      <Container className="content">
        <PageHeader
          title="Sub-Reseller"
          icon={<IconByName name="FaSuitcase" />}
        />
        <LinkBlockList links={links} />
      </Container>
    </Layout>
  );
};

export default SubResellerIndexPage;
