import { useResellerAllUsers } from 'hooks';

export default function useResellerUser ({ id }) {
  if (typeof id === 'undefined') {
    throw new Error('Failed to get reseller user: Invalid ID');
  }

  const { users = [] } = useResellerAllUsers();
  const user = users.find(({ id: userId }) => userId === id);

  return {
    user
  };
}
