import clone from 'clone';
import { device } from 'fogg/lib';

import { logError } from './logger';

const { isDomAvailable } = device;

/**
 * queryParamsToObject
 * @description Takes the URL param string and turns it into an oobject
 */

export function queryParamsToObject (string) {
  if (typeof string !== 'string') return null;

  const queryObject = {};
  const queryString = string.replace('?', '');

  if (queryString.length === 0) {
    return queryObject;
  }

  const querySplit = queryString.split('&');

  for (let i = 0, len = querySplit.length; i < len; i++) {
    const currentSplit = querySplit[i].split('=');
    queryObject[decodeURIComponent(currentSplit[0])] = decodeURIComponent(
      currentSplit[1]
    );
  }

  return queryObject;
}

/**
 * hashToObject
 * @description Takes the URL hash string and turns it into an oobject
 */

export function hashToObject (string) {
  if (typeof string !== 'string') return null;

  const queryString = string.replace('#', '');
  const querySplit = queryString.split('&');
  const queryObject = {};

  for (let i = 0, len = querySplit.length; i < len; i++) {
    const currentSplit = querySplit[i].split('=');
    queryObject[decodeURIComponent(currentSplit[0])] = decodeURIComponent(
      currentSplit[1]
    );
  }

  return queryObject;
}

/**
 * addParamsToUrl
 * @description takes a url and query param object and adds the params to the url
 */

export function addParamsToUrl (url, object, encodeComponents) {
  if (typeof url !== 'string' || typeof object !== 'object') return url;

  if (typeof encodeComponents === 'undefined') encodeComponents = true;

  const urlSplit = url.split('?');
  const urlBase = urlSplit[0];
  const urlSearch = urlSplit[1];
  let urlSearchObject = urlSearch ? queryParamsToObject(urlSearch) : {};
  const objectParams = {};
  let urlSearchObjectKeys = [];

  for (const key in object) {
    if (!Object.prototype.hasOwnProperty.call(object, key)) continue;
    if (typeof object[key] === 'undefined' || object[key] === null) continue;

    objectParams[key] = object[key];
  }

  urlSearchObject = Object.assign(urlSearchObject, objectParams);
  urlSearchObjectKeys = Object.keys(urlSearchObject);

  // If we don't have any url params, just pass back the URL as is

  if (urlSearchObjectKeys.length === 0) {
    return urlBase;
  }

  // Take the keys and map them into key value pairs into the string form

  return (
    urlBase +
    '?' +
    urlSearchObjectKeys
      .map(function (k) {
        if (encodeComponents) {
          return (
            encodeURIComponent(k) + '=' + encodeURIComponent(urlSearchObject[k])
          );
        }

        return k + '=' + urlSearchObject[k];
      })
      .join('&')
  );
}

/**
 * validateObjectKeysExist
 * @description Takes an obejct and set of keys and makes sure everything exists
 */

export function validateObjectKeysExist (data = {}, keys = []) {
  // Filter through all required keys. Return true if the key is NOT
  // defined. If the filter results do NOT equal 0, that means we have
  // invalid key values
  return (
    keys.filter((key) => {
      const value = data[key];

      if (typeof value === 'undefined') return true;
      if (value === null) return true;

      return false;
    }).length === 0
  );
}

/**
 * filterObjectByKeys
 * @description Removes any property that doesnt exist in the allowed keys array
 */

export function filterObjectByAllowedKeys (object = {}, allowedKeys = []) {
  if (typeof object !== 'object') return {};
  if (!Array.isArray(allowedKeys)) return object;

  const newObject = {};

  for (const key in object) {
    if (!Object.prototype.hasOwnProperty.call(object, key)) continue;
    if (!allowedKeys.includes(key)) continue;
    newObject[key] = object[key];
  }

  return newObject;
}

/**
 * exportClassData
 * @description Dumps out all keys from a class and clones the value
 */

export function exportClassData (classInstance) {
  const data = {};

  for (const key in classInstance) {
    if (!Object.prototype.hasOwnProperty.call(classInstance, key)) continue;

    data[key] = clone(classInstance[key]);
  }

  return data;
}

/**
 * updateClassData
 * @description Updates a class instance with new data
 */

export function updateClassData (classInstance, data) {
  for (const key in data) {
    if (!Object.prototype.hasOwnProperty.call(data, key)) continue;

    const isFunction = typeof data[key] === 'function';
    const isClassFunction = typeof classInstance[key] === 'function';
    const isArray = Array.isArray(data[key]);
    const isObject = data[key] && typeof data[key] === 'object';
    const isUpdatedable =
      !!classInstance[key] && typeof classInstance[key].update === 'function';

    // Don't update any methods

    if (isFunction || isClassFunction) continue;

    if (isUpdatedable) {
      classInstance[key].update(data[key]);
    } else if (isArray) {
      classInstance[key] = data[key].slice(0);
    } else if (isObject && data[key] !== null) {
      classInstance[key] = {
        ...classInstance[key],
        ...data[key]
      };
    } else {
      classInstance[key] = data[key];
    }
  }

  return classInstance;
}

/**
 * destroyClassData
 * @description Clears out any data
 */

export function destroyClassData (classInstance) {
  for (const key in classInstance) {
    if (!Object.prototype.hasOwnProperty.call(classInstance, key)) continue;

    const isFunction = typeof classInstance[key] === 'function';
    const isArray = Array.isArray(classInstance[key]);
    const isObject = typeof classInstance[key] === 'object';

    // Don't update any methods

    if (isFunction) continue;

    if (isArray) {
      classInstance[key] = [];
    } else if (isObject && classInstance[key] !== null) {
      classInstance[key] = {};
    } else {
      classInstance[key] = undefined;
    }
  }
  return classInstance;
}

/**
 * fieldValueOrDefault
 * @description Returns the value if it's defied or the default
 */

export function fieldValueOrDefault ({ value } = {}, defaultValue) {
  if (typeof value === 'undefined') return defaultValue;
  return value;
}

/**
 * fieldsObjectToValues
 * @description Returns an object of fields and extracts their values
 */

export function fieldsObjectToValues (fields) {
  const data = {};

  for (const key in fields) {
    if (!Object.prototype.hasOwnProperty.call(fields, key)) continue;
    const value = fieldValueOrDefault(fields[key]);
    if (typeof value !== 'undefined') {
      data[key] = value;
    }
  }

  return data;
}

/**
 * checkedInputToBool
 * @description Turns a radio or checkbox input value to a bool
 */

export function checkedInputToBool (value) {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return !!value;
  }
}

/**
 * globalErrorHandler
 * @description Catches errors that aren't being caught elsewhere and logs
 * that information to ElasticSearch
 */

export function globalErrorHandler () {
  if (isDomAvailable()) {
    window.onerror = function (message, source, lineno, colno, error) {
      logError(message, {
        error_source: source,
        error_line: lineno,
        error_column: colno,
        error_object: error
      });
    };

    window.addEventListener('unhandledrejection', (event) => {
      // This only works in Chrome.
      // Firefox: https://developer.mozilla.org/en-US/docs/Web/API/Window/onunhandledrejection

      // Prevent error output on the console:
      event.preventDefault();
      logError(event.reason);
    });
  }
}

/**
 * parseGeoJsonString
 * @description Takes in a geoJson string and parses it to JSON
 */

export function parseGeoJsonString (string) {
  if (typeof string !== 'string') return string;
  let geoJson;
  // Try to parse the object if it's available for us
  try {
    geoJson = JSON.parse(string);
  } catch (e) {
    console.error(`Failed to parse geoJson: ${e}`);
  }
  return geoJson;
}

export function sortByDateKey (array = [], key) {
  const sortKey = 'sortKey';

  const normalizedDates = array.map((item) => {
    return {
      ...item,
      [sortKey]: new Date(item[key])
    };
  });

  const sorted = sortByKey(normalizedDates, sortKey);

  return sorted.map((item) => {
    const object = { ...item };
    delete object[sortKey];
    return object;
  });
}

/**
 * sortByKey
 * @description Sort the given array by the object key
 */

export function sortByKey (array = [], key, type = 'asc') {
  function compare (a, b) {
    let keyA = a[key];
    let keyB = b[key];

    if (typeof keyA === 'string') {
      keyA = keyA.toLowerCase();
    }

    if (typeof keyB === 'string') {
      keyB = keyB.toLowerCase();
    }

    if (keyA < keyB) {
      return -1;
    }

    if (keyA > keyB) {
      return 1;
    }

    return 0;
  }

  const newArray = [...array];

  if (typeof key !== 'string') return newArray;

  const sorted = newArray.sort(compare);

  if (type === 'asc') return sorted;
  if (type === 'desc') return sorted.reverse();

  return newArray;
}

/**
 * compareVersionNumbers
 * @description Compare two software version numbers (e.g. 1.7.1)
 * Returns:
 *
 *  0 if they're identical
 *  negative if v1 < v2
 *  positive if v1 > v2
 *  Nan if they in the wrong format
 *
 *  E.g.:
 *
 *  assert(version_number_compare("1.7.1", "1.6.10") > 0);
 *  assert(version_number_compare("1.7.1", "1.7.10") < 0);
 *
 *  "Unit tests": http://jsfiddle.net/ripper234/Xv9WL/28/
 *
 *  Taken from http://stackoverflow.com/a/6832721/11236
 */

export function compareVersionNumbers (v1, v2) {
  var v1parts = v1.split('.');
  var v2parts = v2.split('.');

  // First, validate both numbers are true version numbers

  if (!validateVersionParts(v1parts) || !validateVersionParts(v2parts)) {
    return NaN;
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    }
    if (v1parts[i] > v2parts[i]) {
      return 1;
    }
    return -1;
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

/**
 * isPositiveInteger
 * @description Is the number a positive integer?
 * @see http://stackoverflow.com/a/1019526/11236
 */

function isPositiveInteger (x) {
  return /^\d+$/.test(x);
}

/**
 * validateVersionParts
 * @description Checks if each is a valid part of the version
 * @see http://stackoverflow.com/a/1019526/11236
 */

function validateVersionParts (parts) {
  for (var i = 0; i < parts.length; ++i) {
    if (!isPositiveInteger(parts[i])) {
      return false;
    }
  }
  return true;
}

/**
 * setPropertyIfExists
 * @description Given the property key and value, only set on object if not undefined
 */

export function setPropertyIfExists (key, value) {
  const data = {};
  if (typeof value !== 'undefined') {
    data[key] = value;
  }
  return data;
}

/**
 * isEmptyObject
 * @description Checks if an object is empty
 */

export function isEmptyObject (obj) {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
}

/**
 * obfuscateEmail
 * @description Returns email with the pattern a****z@email.com
 */

export function obfuscateEmail (email) {
  return email.replace(/(?!^).(?=[^@]+@)/g, '*');
}

/**
 * sortStringCompare
 * @description
 */

export function sortStringCompare (a, b) {
  const aLower = typeof a === 'string' && a.toLowerCase();
  const bLower = typeof b === 'string' && b.toLowerCase();

  if (aLower < bLower) {
    return -1;
  }

  if (aLower > bLower) {
    return 1;
  }

  return 0;
}

/**
 * getByKey
 * @description Clone of lodash get
 * @via https://gomakethings.com/how-to-get-the-value-of-an-object-from-a-specific-path-with-vanilla-js/
 */

export function getByKey (obj, path, def) {
  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  var stringToPath = function (path) {
    // If the path isn't a string, return it
    if (typeof path !== 'string') return path;

    // Create new array
    var output = [];

    // Split to an array with dot notation
    path.split('.').forEach(function (item, index) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key);
        }
      });
    });

    return output;
  };

  // Get the path as an array
  path = stringToPath(path);

  // Cache the current object
  var current = obj;

  // For each item in the path, dig into the object
  for (var i = 0; i < path.length; i++) {
    // If the item isn't found, return the default (or null)
    if (!current[path[i]]) return def;

    // Otherwise, update the current  value
    current = current[path[i]];
  }

  return current;
}

/**
 * chompFloat
 * Method that ensures value returned will be to the same number decimals
 */

export function chompFloat (floatToChomp, maxFix = 0) {
  let updatedFloat = floatToChomp;

  if (typeof floatToChomp !== 'number') {
    updatedFloat = parseFloat(floatToChomp);
  }

  // If parsing the float didn't work out, return zero

  if (isNaN(updatedFloat)) {
    throw new Error(`Invalid value passed to fixFloat ${floatToChomp}`);
  }

  updatedFloat = updatedFloat.toFixed(maxFix);

  return parseFloat(updatedFloat);
}

/**
 * checks if a number is between a range of numbers
 * @param {number} [numInput=1] - number to check
 * @param {number} [min=0] - minimum value of range
 * @param {number} [max=100] - maximum value of range
 * @returns {boolean} - returns true or false if number is within given range
 */
export function between ({ numInput = 1, min = 0, max = 100 }) {
  if (isNaN(numInput) || isNaN(min) || isNaN(max)) {
    throw new Error('Invalid param values.');
  }

  return numInput >= min && numInput <= max;
}

/**
 * isUuid
 * @description Is the input string a UUID?
 * @param {string} input - string to test
 * @returns {boolean} - returns true or false if the string is UUID format or not
 */
export function isUuid (input) {
  // First strip any spaces
  if (typeof input === 'string') {
    input = input.trim();
  }
  // Then test input on the regex
  const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return uuidRegex.test(input);
}

/**
 * renders component or element if supplied condition is met, otherwise returns null.
 * Use this in the returns of components, to avoid writing ternary or logical operators
 *
 * @param {bool} renderCondition
 * @param {React.Component|HTMLElement} componentToRender
 * @returns {React.Component|HTMLElement|null}
 */
export function renderOrNot (renderCondition, componentToRender) {
  if (renderCondition) {
    return componentToRender;
  }
  return null;
}
