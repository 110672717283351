import { useMappedState } from 'redux-react-hook';
import { isEmptyObject } from 'lib/util';

import Task from 'models/task';
import RepeatRequest from 'models/repeatRequest';
import { NEW_TASK } from 'data/action-types';
import { TASKING_REQUEST_ID } from 'data/property-names';
import { TaskRequestTypes } from 'data/task-request-types';

export default function useTask (taskId) {
  const mapState = ({ tasks }) => tasks;

  const { tasks, new: newTask } = useMappedState(mapState);
  let task;

  if (taskId === NEW_TASK) {
    task = { ...newTask };
  } else {
    task = tasks.find(({ properties = {} }) => {
      return `${properties[TASKING_REQUEST_ID]}` === `${taskId}`;
    });
  }

  if (isEmptyObject(task)) {
    return {};
  }

  let TaskModel = Task;

  if (task.properties.taskRequestType === TaskRequestTypes.REPEAT) {
    TaskModel = RepeatRequest;
  }

  const outputTask = new TaskModel().ingestTaskBody(task);

  return outputTask;
}
