import clone from 'clone';
import deepmerge from 'deepmerge';

import {
  ADD_SUB_RESELLER_TASK,
  CLEAR_NEW_SUB_RESELLER_TASK,
  NEW_SUB_RESELLER_TASK,
  SET_SUB_RESELLER_ORG,
  UPDATE_SUB_RESELLER_ORG,
  UPDATE_SUB_RESELLER_ACTION_STATE,
  UPDATE_SUB_RESELLER_TASK,
  UPDATE_SUB_RESELLER_TASKS,
  UPDATE_SUB_RESELLER_USER,
  UPDATE_SUB_RESELLER_ORG_USERS,
  UPDATE_SUB_RESELLER_REPEAT_REQUESTS,
  UPDATE_SUB_RESELLER_TASKS_PAGED,
  UPDATE_SUB_RESELLER_REPEAT_REQUESTS_PAGED,
  UPDATE_SUB_RESELLER_TASKS_COLUMNS,
  UPDATE_SUB_RESELLER_REPEAT_REQUESTS_COLUMNS,
  UPDATE_SUB_RESELLER_ORGANIZATION_CONTRACT
} from 'data/action-types';
import { TASKING_REQUEST_ID } from 'data/property-names';
import { updateSingleEntityByKey } from 'lib/state';
import {
  updateTaskData,
  updateTasksData,
  RESELLER_TASKS_TABLE_COLUMNS,
  RESELLER_REPEAT_REQUESTS_TABLE_COLUMNS
} from 'lib/tasks';

const defaultState = {
  users: [],
  organization: {},
  actions: {},
  tasks: [],
  repeatRequests: [],
  columns: RESELLER_TASKS_TABLE_COLUMNS,
  repeatColumns: RESELLER_REPEAT_REQUESTS_TABLE_COLUMNS
};

// Changes here most likely should be made identically to reducers/reseller.js
// as they share the same root properties

const subreseller = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);
  let taskingrequestId;

  if (workingData && workingData.properties) {
    taskingrequestId = workingData.properties[TASKING_REQUEST_ID];
  }

  const { tasks: workingStateTasks = [], new: workingStateNewTask } =
    workingState;

  switch (type) {
    case UPDATE_SUB_RESELLER_TASK:
      if (typeof workingData !== 'object' || Array.isArray(workingData)) {
        throw new Error(`Invalid data passed to ${UPDATE_SUB_RESELLER_TASK}`);
      }

      if (taskingrequestId === NEW_SUB_RESELLER_TASK) {
        return {
          ...workingState,
          new: updateTaskData(workingStateNewTask, workingData)
        };
      } else {
        return {
          ...workingState,
          tasks: updateTasksData(workingStateTasks, [workingData])
        };
      }
    case UPDATE_SUB_RESELLER_TASKS: {
      return {
        ...workingState,
        tasks: [...workingData]
      };
    }
    case UPDATE_SUB_RESELLER_REPEAT_REQUESTS: {
      return {
        ...workingState,
        repeatRequests: [...workingData]
      };
    }
    case UPDATE_SUB_RESELLER_TASKS_PAGED: {
      if (!Array.isArray(workingData.results)) {
        throw new Error(`Invalid data passed to ${UPDATE_SUB_RESELLER_TASKS_PAGED}`);
      }
      return {
        ...workingState,
        tasks: workingData.results,
        currentPage: workingData.currentPage,
        totalPages: workingData.totalPages
      };
    }
    case UPDATE_SUB_RESELLER_REPEAT_REQUESTS_PAGED: {
      if (!Array.isArray(workingData.results)) {
        throw new Error(`Invalid data passed to ${UPDATE_SUB_RESELLER_TASKS_PAGED}`);
      }
      return {
        ...workingState,
        repeatRequests: workingData.results,
        currentPage: workingData.currentPage,
        totalPages: workingData.totalPages
      };
    }
    case ADD_SUB_RESELLER_TASK:
      if (taskingrequestId === NEW_SUB_RESELLER_TASK) {
        return {
          ...workingState,
          new: workingData
        };
      } else {
        return {
          ...workingState,
          tasks: [...workingState.tasks, workingData]
        };
      }
    case CLEAR_NEW_SUB_RESELLER_TASK:
      return {
        ...workingState,
        new: {}
      };
    case SET_SUB_RESELLER_ORG:
      return {
        ...workingState,
        organization: workingData
      };
    case UPDATE_SUB_RESELLER_ORG:
      return {
        ...workingState,
        organization: deepmerge(workingState.organization, workingData)
      };
    case UPDATE_SUB_RESELLER_USER:
      workingState.organization.users = updateSingleEntityByKey({
        key: 'id',
        entity: workingData,
        previousEntities: workingState.organization.users
      });

      return {
        ...workingState,
        organization: {
          ...workingState.organization,
          users: workingState.organization.users
        }
      };
    case UPDATE_SUB_RESELLER_ORG_USERS:
      return {
        ...workingState,
        users: workingData
      };
    case UPDATE_SUB_RESELLER_ACTION_STATE:
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    case 'ADD_SUB_RESELLER_USER': {
      return {
        ...workingState,
        users: [...(state.users || []), workingData]
      };
    }
    case UPDATE_SUB_RESELLER_TASKS_COLUMNS:
      return {
        ...workingState,
        columns: workingData
      };
    case UPDATE_SUB_RESELLER_REPEAT_REQUESTS_COLUMNS:
      return {
        ...workingState,
        repeatColumns: workingData
      };
    case UPDATE_SUB_RESELLER_ORGANIZATION_CONTRACT: {
      const { contract } = workingData;
      const organization = workingState.organization;

      organization.contracts = updateSingleEntityByKey({
        key: 'id',
        entity: contract,
        previousEntities: organization.contracts
      });
      if (organization.contract.id === contract.id) {
        organization.contract = contract;
      }

      return {
        ...workingState,
        organization
      };
    }
    default:
      return workingState;
  }
};

export default subreseller;
