import {
  constructActionIsLoading,
  constructActionIsError,
  constructActionIsLoaded,
  constructRequestActionManager
} from 'commonLib/src/lib/actions';
import {
  mapFeatureCollectionToResults,
  responseHasMoreResults,
  getFilters,
  lensDateToSatTime,
  searchResultsToHeatmap,
  SEARCH_RESULTS_LIMIT,
  DEFAULT_COLLECTIONS_TO_EXCLUDE
} from 'lib/search';
import { routePathByName } from 'lib/routes';

/**
 * setSearchActionState
 * @description Updates the search store's status
 */

export function setSearchActionState (status) {
  return {
    type: 'UPDATE_SEARCH_ACTION_STATE',
    data: status
  };
}

export const _setSearchActionIsLoading =
  constructActionIsLoading(setSearchActionState);
export const _setSearchActionIsError =
  constructActionIsError(setSearchActionState);
export const _setSearchActionIsLoaded =
  constructActionIsLoaded(setSearchActionState);

const createDispatchRequestAction =
  constructRequestActionManager(setSearchActionState);

/**
 * pushSearchHistory
 * @description Adds a search query to the history state
 */

export const pushSearchHistory = ({ args = {}, results = {} } = {}) => {
  return {
    type: 'PUSH_SEARCH_HISTORY',
    data: {
      args,
      results
    }
  };
};

/**
 * updateUserOrganizationCollections
 * @description Update user's org collections
 */

export const updateUserOrganizationCollections = (data) => {
  return {
    type: 'UPDATE_USER_ORGANIZATION_COLLECTIONS',
    data
  };
};

/**
 * fetchUserOrganizationCollections
 * @param {string} organizationId
 */

export const fetchUserOrganizationCollections = (organizationId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchUsersCollections',
      scope: 'user',
      request: {
        url: routePathByName('apiUsersCollections', {
          wildcard: [organizationId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(
      updateUserOrganizationCollections({
        collections: requestData
      })
    );

    return requestData;
  };
};

/**
 * searchCatalog
 * @description POST search to /catalog
 * @param {*} searchData - params to pass to catalog
 * @param heatMapSearch - Boolean for if a heatmap search
 */
export const searchCatalog = (searchData = {}, heatMapSearch = false) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'searchCatalog',
      scope: 'search',
      request: {
        url: routePathByName('apiSearch'),
        method: 'post',
        data: searchData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};
    // If heatmap search just return the response data
    if (heatMapSearch) {
      return data;
    } else {
      // Otherwise convert to search results
      const { context = {} } = data;
      const { matched } = context;
      return {
        features: mapFeatureCollectionToResults(data),
        hasMoreResults: responseHasMoreResults(context),
        numberOfResults: matched
      };
    }
  };
};

/**
 * resolveLensSearch
 * @description Async function that manages search results mapping them to atlas item values
 */

export const resolveLensSearch = ({
  geoJson = {},
  page,
  date,
  filters,
  type,
  searchString,
  searchCollections,
  heatmapActive
} = {}) => {
  return async (dispatch, getState) => {
    const errorBase = 'Failed to resolve search';
    let results;
    // Collect ID Search
    if (type === 'collect-id' || type === 'item-id') {
      const searchData = {
        zoom: 'auto',
        limit: SEARCH_RESULTS_LIMIT,
        page: 1
      };
      if (type === 'collect-id') {
        // catalog /search requires Collect ID's to be lowercase, also trim whitespace
        const query = searchString.toLowerCase().trim();
        searchData.query = {
          'capella:collect_id': {
            eq: query
          }
        };
      }
      if (type === 'item-id') {
        // STAC ID's (item-id) need to be uppercase, also trim whitespace
        const query = searchString.toUpperCase();
        const itemIds = query.split(',').map(i => i.trim());

        if (!Array.isArray(itemIds)) {
          throw new Error('Failed to get search results');
        }

        searchData.ids = itemIds;
      }

      try {
        results = await dispatch(searchCatalog(searchData, false));
        return results;
      } catch (e) {
        throw new Error(`Failed to get search results: ${e}`);
      }
    } else {
      // Standard OR Heatmap Search
      const { geometry, queryFilters, collections } = getFilters(
        geoJson,
        filters
      );

      // Set default collections to filter on if none are explicitly set, or if "All Values"
      // The filter is built as all collections accessible to user's org except
      // those specified in DEFAULT_COLLECTIONS_TO_EXCLUDE above (per client's request)
      let filteredCollections = collections;
      if ((!collections || collections.length === 0 || collections.includes('All Values')) && searchCollections) {
        filteredCollections = searchCollections.filter(
          (collection) => !DEFAULT_COLLECTIONS_TO_EXCLUDE.includes(collection)
        );
      }
      // Start building search object
      let searchData = {
        intersects: geometry,
        limit: SEARCH_RESULTS_LIMIT,
        datetime: lensDateToSatTime(date),
        page,
        query: queryFilters,
        collections: filteredCollections,
        aggregations: {
          large_grid: {
            type: 'geohash_grid',
            precision: 5
          }
        }
      };

      // Heatmap Search
      if (type === 'heatmap') {
        if (heatmapActive) {
          searchData = {
            ...searchData,
            limit: 0,
            page: 1
          };
        } else {
          // Reset heatmap search
          searchData = {
            query: {
              collections: filteredCollections,
              zoom: 'auto',
              limit: 0,
              page: 1
            }
          };
        }
      }

      // Only trigger a standard search with a geometry (intersects) OR datetime range
      const isValidSearch = searchData.intersects || searchData.datetime || type === 'heatmap';

      // Now trigger a search
      try {
        // FIXME - For now we run two searches for the "night" collection time setting due to our STAC implementation not supporting
        // more advanced search parameters. Once we have that updated to a better system we should change this and remove the hack.
        // When doing this we'll also need to update the getFilters function in lib/search.js to add the night filters properly.
        const collectionTimeFilter = filters && filters.find(filter => filter.id === 'collection_time');
        if (collectionTimeFilter && collectionTimeFilter.value === 'night' && searchData.query['locale:time']) { // last condition is to cover heatmap reset when the "night" filter is applied
          results = await searchCatalogNightCollection(searchData, type === 'heatmap', dispatch);
        } else if (isValidSearch) {
          results = await dispatch(searchCatalog(searchData, type === 'heatmap'));
        } else {
          // Invalid search, mock an empty response
          return ({
            features: [],
            hasMoreResults: false,
            numberOfResults: 0
          });
        }

        if (type === 'heatmap') {
          // If heatmap, plot on map
          return searchResultsToHeatmap(results);
        }
        return results;
      } catch (e) {
        throw new Error(`${errorBase}: ${e}`);
      }
    }
  };
};

async function searchCatalogNightCollection (searchData, isHeatmapSearch, dispatch) {
  const [amSearchData, pmSearchData] = [searchData, searchData].map(
    (data, i) => ({
      ...data,
      query: {
        ...data.query,
        'locale:time': data.query['locale:time'][i]
      }
    })
  );
  const [amResults, pmResults] = await Promise.all([
    dispatch(searchCatalog(amSearchData, isHeatmapSearch)),
    dispatch(searchCatalog(pmSearchData, isHeatmapSearch))
  ]);

  const results = {
    ...amResults,
    features: [...amResults.features, ...pmResults.features],
    numberOfResults: amResults.numberOfResults + pmResults.numberOfResults
  };

  // Properly sum up heatmap results if we're doing that search
  if (amResults.aggregations && pmResults.aggregations) {
    const bucketsFlat = [
      ...amResults.aggregations.large_grid.buckets,
      ...pmResults.aggregations.large_grid.buckets
    ];
    /* eslint-disable camelcase */
    const buckets = Array.from(
      bucketsFlat.reduce(
        (m, { key, doc_count }) => m.set(key, (m.get(key) || 0) + doc_count), // eslint-disable-line camelcase
        new Map()
      ),
      ([key, doc_count]) => ({ key, doc_count }) // eslint-disable-line camelcase
    );

    results.aggregations.large_grid.buckets = buckets;
  }

  return results;
}
