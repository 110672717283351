import React from 'react';
import PropTypes from 'prop-types';
import { FaCheck, FaTimes, FaChevronRight } from 'react-icons/fa';
import { Button } from 'fogg/ui';

import ClassName from '../models/classname';

const Verifications = ({ className, verifications = [], onChange }) => {
  const componentClass = new ClassName('verifications');
  if (className) componentClass.add(className);

  function handleToggleVerification (e, { id } = {}) {
    if (typeof onChange === 'function') {
      onChange(e, { id });
    }
  }

  return (
    <ul className={componentClass.string}>
      {verifications.map((verification, index) => {
        const {
          id,
          label,
          isVerified,
          readOnly,
          actionLabels = {}
        } = verification;
        return (
          <li key={`VerificationItem-${index}`} className="verifications-item">
            <span className="verifications-item-label">{label}</span>
            <span className="verifications-item-status">
              {isVerified ? (
                <FaCheck className="icon-check" />
              ) : (
                <FaTimes className="icon-times" />
              )}
            </span>
            {!readOnly && (
              <span className="verifications-item-change">
                <Button
                  type={['text', 'icon-after']}
                  onClick={(e) => handleToggleVerification(e, { id })}
                >
                  {isVerified ? actionLabels.remove : actionLabels.add}
                  <FaChevronRight />
                </Button>
              </span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

Verifications.propTypes = {
  verifications: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func
};

export default Verifications;
