import React from 'react';
import {
  FaSearch,
  FaTasks,
  FaDatabase,
  FaUser,
  FaUsers,
  FaCog,
  FaStore,
  FaSuitcase,
  FaShoppingCart,
  FaQuestion
} from 'react-icons/fa';
import { BiMapPin } from 'react-icons/bi';
import { NavBar, WonderLink } from 'fogg/ui';
import Logo from './Logo';

import { useLocation, useUser } from 'hooks';
import { routePathByName } from 'lib/routes';
import {
  ROUTE_ACCOUNT,
  ROUTE_ADMIN,
  ROUTE_AOIS,
  ROUTE_CART,
  ROUTE_ORDERS,
  ROUTE_ORG,
  ROUTE_RESELLER,
  ROUTE_SEARCH,
  ROUTE_SUB_RESELLER,
  ROUTE_TASKS,
  ROUTE_SHARED_PLATFORM,
  CAPELLA_SUPPORT_URL
} from 'data/route-names';

import User from 'models/user';

const PRIMARY_NAVIGATION = [
  {
    label: 'Search & Discover',
    to: routePathByName(ROUTE_SEARCH),
    icon: <FaSearch />,
    state: {
      resetSearch: true
    }
  },
  {
    label: 'Tasks',
    to: routePathByName(ROUTE_TASKS),
    icon: <FaTasks />
  },
  {
    label: 'Orders',
    to: routePathByName(ROUTE_ORDERS),
    icon: <FaDatabase />
  },
  {
    label: 'AOIs',
    to: routePathByName(ROUTE_AOIS),
    icon: <BiMapPin />
  }
];

const SECONDARY_NAVIGATION = [
  {
    label: 'Cart',
    to: routePathByName(ROUTE_CART),
    icon: <FaShoppingCart />
  },
  {
    label: 'User Profile',
    to: routePathByName(ROUTE_ACCOUNT),
    icon: <FaUser />
  }
];

const Navigation = () => {
  const { pathname } = useLocation();
  const { user = {} } = useUser();
  const currentUser = new User(user);

  const navItems = [...SECONDARY_NAVIGATION];

  if (currentUser.isOrgManager()) {
    navItems.push({
      label: 'Organization',
      to: routePathByName(ROUTE_ORG),
      icon: <FaUsers className="icon-users" />
    });
  }

  if (currentUser.isAdmin()) {
    navItems.push({
      label: 'Admin',
      to: routePathByName(ROUTE_ADMIN),
      icon: <FaCog />
    });
  }

  if (currentUser.isReseller()) {
    navItems.push({
      label: 'Reseller',
      to: routePathByName(ROUTE_RESELLER),
      icon: <FaStore />
    });
  }

  if (currentUser.isSubreseller()) {
    navItems.push({
      label: 'Sub-Reseller',
      to: routePathByName(ROUTE_SUB_RESELLER),
      icon: <FaSuitcase />
    });
  }

  navItems.push({
    label: 'Docs',
    to: CAPELLA_SUPPORT_URL,
    icon: <FaQuestion />,
    target: '_blank'
  });

  return (
    <header className="navigation">
      <div className="logo">
        <WonderLink
          to={routePathByName(ROUTE_SHARED_PLATFORM)}
          state={{
            resetSearch: true
          }}
        >
          <Logo />
        </WonderLink>
      </div>
      <NavBar
        orientation="vertical"
        primary={PRIMARY_NAVIGATION}
        secondary={navItems}
        activePage={pathname}
      />
    </header>
  );
};

export default Navigation;
