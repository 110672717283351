function daysTimeInMs (days = 1) {
  const oneDayInMs = 86400000;
  return oneDayInMs * days;
}

/**
 * type enumerators for task collection tiers
 */
export const TaskCollectionTiers = {
  TIER_STANDARD: 'standard',
  TIER_PRIORITY: 'priority',
  TIER_URGENT: 'urgent',
  TIER_FLEXIBLE: 'flexible',
  TIER_ROUTINE: 'routine',
  TIER_INTERNAL: 'internal',

  properties: {
    standard: {
      label: 'Standard',
      timeMs: daysTimeInMs(7),
      days: 7
    },
    priority: {
      label: 'Priority',
      timeMs: daysTimeInMs(3),
      days: 3
    },
    urgent: {
      label: 'Urgent',
      timeMs: daysTimeInMs(1),
      days: 1
    },
    flexible: {
      label: 'Flexible',
      timeMs: daysTimeInMs(7),
      days: 7
    },
    routine: {
      label: 'Routine',
      timeMs: daysTimeInMs(1),
      days: 1
    },
    internal: {
      label: 'Internal',
      timeMs: daysTimeInMs(7),
      days: 7
    }
  }
};
