import { useOrgTasks } from 'hooks';

export default function useOrgTask ({ id }) {
  const { tasks = [] } = useOrgTasks();

  if (!Array.isArray(tasks)) return;

  const task = tasks.find((task = {}) => task.id === id);

  if (!task) {
    return {
      id
    };
  }

  return task;
}
