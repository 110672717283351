import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
import { FaTasks } from 'react-icons/fa';
import { Button } from '@trussworks/react-uswds';
import { useDispatch } from 'redux-react-hook';
import {
  NavigatorLayout,
  NavigatorBody,
  PageHeader,
  Breadcrumbs,
  Container,
  ROUTE_TASKS,
  ROUTE_REPEAT_REQUESTS,
  ROUTE_TASKS_DETAILS,
  ROUTE_REPEAT_REQUEST_DETAILS
} from 'commonLib';
import { RepeatCycleTypes } from 'commonLib/src/data/repeat-cycle-types';
import { routePathByName, navigateTo } from 'lib/routes';
import {
  TASKS_TABLE_FILTER_OPTIONS,
  TASKS_TABLE_COLUMNS,
  tasksToSortableTable
} from 'lib/tasks';
import {
  updateTasksPage,
  updateTasksPagedLimit,
  updateTasksFilters,
  updateTasksSort
} from 'state/actions';
import { useLocation } from 'hooks';
import { USER_ROLE } from 'data/roles';
import Tabs from '../components/Tabs';
import SortableTable from '../components/SortableTable';
import FiltersPanel from '../components/FiltersPanel';
import CsvDownloadButton from '../components/CsvDownloadButton';

/**
 * New Paged Tasks Table template with filtering, sorting, searching
 * Used in regular /tasks page as well as org mgr tasks, and reseller/sub
 * @param {object} props
 * @param {array} props.tasks=[]
 * @param {array} props.collectionTypes=[]
 * @param {object} props.paging={currentPage: 1, totalPages = 1, limit = DEFAULT_PAGING_LIMIT}
 * @param {object} props.filters={} (e.g. {collectionTier: '7_day'})
 * @param {object} [props.filterOptions=TASKS_TABLE_FILTER_OPTIONS] Filters for FiltersPanel
 * @param {array} [props.tableColumns=TASKS_TABLE_COLUMNS] Array of current task table columns (and which are active)
 * @param {Function} [props.handleColumnsChange] Handler for check/uncheck columns, or re-ordering
 * @param {Function} [props.resetColumns] Handler to reset columns to their default state & order
 * @param {object} [props.routeNames={}]
 * @param {string} [props.id='tabpanel_0'] ID passed to SortableTable to help with tab accessibility
 * @param {boolean} [props.isLoading=false]
 * @param {string} [props.accessType=USER_ROLE]
 * @param {array} [props.breadcrumbs=[]]
 * @param {string} [props.activeTab='single'] ID of active tab ('single' or 'repeat')
 * @param {Function} [props.exportHandler]
 * @param {string} [props.exportFileName='capella-task-requests-download.csv']
 */
const TemplateTasks = ({
  tasks = [],
  collectionTypes = [],
  paging = {},
  filters = {},
  filterOptions = TASKS_TABLE_FILTER_OPTIONS,
  tableColumns = TASKS_TABLE_COLUMNS,
  handleColumnsChange,
  resetColumns,
  routeNames = {
    tasks: ROUTE_TASKS,
    repeatRequests: ROUTE_REPEAT_REQUESTS,
    taskDetails: ROUTE_TASKS_DETAILS,
    repeatDetails: ROUTE_REPEAT_REQUEST_DETAILS
  },
  id = 'tabpanel_0',
  isLoading = false,
  accessType = USER_ROLE,
  breadcrumbs = [],
  activeTab = 'single',
  exportHandler,
  exportFileName = 'capella-task-requests-download.csv'
}) => {
  const dispatch = useDispatch();

  // Only display columns with isActive: true
  const columnDefinitions = tableColumns.filter(col => col.isActive);

  const [activeDatepicker, setActiveDatepicker] = useState(null);

  const { updateQuerySearchParams, objectToQueryString, queryParams = {} } = useLocation();
  const { sort: sortParam, order: orderParam } = queryParams;

  const { currentPage, totalPages, limit } = paging;

  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;
  const isSingleTask = activeTab === 'single';

  function handleLimitChange (number = 10) {
    if (!number || typeof number !== 'number') return;
    dispatch(updateTasksPage(1)); // for now reset page when changing limit to avoid issues
    dispatch(updateTasksPagedLimit(number));
    updateQuerySearchParams({ limit: number, page: 1 }, { replace: false });
  }

  function handlePageChange ({ selected = 1 }) {
    dispatch(updateTasksPage(selected + 1));
    updateQuerySearchParams({ page: selected + 1 }, { replace: false });
  }

  const tabList = [
    {
      label: 'Single Tasks',
      action: () => navigateTo(routePathByName(routeNames.tasks)),
      isActive: isSingleTask
    },
    {
      label: 'Repeat Tasks',
      action: () => navigateTo(routePathByName(routeNames.repeatRequests)),
      isActive: !isSingleTask
    }
  ];

  function isFilterActive (filterGroup, value) {
    if (!filters || !Object.keys(filters).length) {
      return false;
    }
    if (Object.keys(filters).includes(filterGroup)) {
      if (filters[filterGroup]?.includes(value.toString())) {
        return true;
      }
    }
  }

  /**
   * Handler for filter changes, updates query params & redux state accordingly
   * @param {string} type='date'
   * @param {string} id='' parent filter selected (e.g. 'status')
   * @param {string|null} [selection=null] value of selected filter, or null to reset it
  */
  function handleFilterSelect (type = 'date', id = '', selection) {
    if (!id) return;

    const updatedFilters = filters;
    if (!selection) {
      delete updatedFilters[id];
    } else if (type === 'customDate') {
      // Custom date filters always override any existing date selections
      updatedFilters[id] = `${selection.start}/${selection.end}`;
      setActiveDatepicker(null);
    } else if (isFilterActive(id, selection)) {
      // Filter already active?
      const index = updatedFilters[id].indexOf(selection);
      if (index.toString()) {
        updatedFilters[id].splice(index, 1)
          .filter(val => val.trim() !== '');
        // Remove any empty filter groups
        if (updatedFilters[id].length <= 0) delete updatedFilters[id];
      }
    } else if (selection) {
      // Multi-select vs. not (radio vs. checkbox)
      switch (type) {
        case 'multi':
          if (Array.isArray(updatedFilters[id])) {
            updatedFilters[id].push(selection);
          } else updatedFilters[id] = [selection];
          break;
        case 'multi-other':
          // Multi-Other is a multi-select with custom "Other" option e.g. Repeat Cycle
          if (Array.isArray(updatedFilters[id])) {
            // Remove any custom "Other" values first to avoid persisting stale "Other" entries
            const repeatCycleFilters = updatedFilters[id].filter(
              val => Object.keys(RepeatCycleTypes).includes(val.toUpperCase()));
            repeatCycleFilters.push(selection);
            updatedFilters[id] = repeatCycleFilters;
          } else updatedFilters[id] = [selection];
          break;
        case 'date':
          updatedFilters[id] = selection;
          break;
      }
    }
    const searchParams = objectToQueryString(updatedFilters).toString();
    updateQuerySearchParams({ filters: searchParams }, { replace: false });
    dispatch(updateTasksFilters(updatedFilters));
  }

  /**
   * Triggered when user submits a search in FiltersPanel
   * We treat it as another filter, and update query params & filter state
   * @param {object} event={}
   */
  function handleSearch (event = {}) {
    event.preventDefault();
    const { target } = event;
    const newFilters = {
      ...filters
    };

    if (target && target[0]?.value) {
      newFilters.search = target[0].value;
    } else {
      delete newFilters.search;
    }

    const searchParams = objectToQueryString(newFilters).toString();
    updateQuerySearchParams({ filters: searchParams }, { replace: false });
    dispatch(updateTasksFilters());
  }

  /**
   * Click handler for column sort buttons
   * @param {string} column=undefined
   * @param {string} order='asc'
   */
  function handleSortClick (column, order = 'asc') {
    if (!column) return;

    let sortObj = {
      sort: column,
      order: order
    };
    // Selecting an active sort should clear it
    if (sortParam === column && orderParam === order) {
      sortObj = { sort: undefined, order: undefined };
    }

    dispatch(updateTasksSort(sortObj));
    updateQuerySearchParams(sortObj, { replace: false });
  }

  // Reset all active Filters (including Search), but NOT currently sorting
  function resetFilters () {
    updateQuerySearchParams({ filters: undefined, sort: undefined }, { replace: false });
    dispatch(updateTasksFilters({}));
  }

  // Different task details paths for single, repeat, org mgr, reseller, etc.
  const taskDetailsPath = isSingleTask ? routeNames.taskDetails : routeNames.repeatDetails;

  const currentColumns = columnDefinitions.map(col => col.columnId);

  return (
    <NavigatorLayout
      access={accessType}
      className="layout-navigator full-relative"
    >
      <Helmet bodyAttributes={{ class: 'template-tasks' }}>
        <title>Tasks</title>
      </Helmet>
      <Container type="full">
        <NavigatorBody className="layout-navigator-body">
          <div className="data-table-page-header">
            <div>
              {hasBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}
              <PageHeader
                title="Tasks"
                icon={<FaTasks className="icon-fa" />}
              />
            </div>
          </div>

          <Tabs tabList={tabList} />

          <FiltersPanel
            filters={filters}
            filterOptions={filterOptions}
            handleFilterSelect={handleFilterSelect}
            handleSearch={handleSearch}
            activeDatepicker={activeDatepicker}
            setActiveDatepicker={setActiveDatepicker}
            resetFilters={resetFilters}
            columns={tableColumns.sort((a, b) => b.isActive - a.isActive)}
            handleColumns={handleColumnsChange}
            resetColumns={resetColumns}
            exportComponent={typeof exportHandler === 'function'
              ? <CsvDownloadButton
                as={Button}
                type="button"
                className="usa-button usa-button--base"
                onClickPromise={exportHandler}
                filename={exportFileName}
              />
              : undefined
            }
          />
          <SortableTable
            columns={columnDefinitions}
            tableBody={tasksToSortableTable(tasks, collectionTypes, currentColumns, taskDetailsPath)}
            isLoading={isLoading}
            tableId={id}
            isSortable={true}
            handleSortClick={handleSortClick}
          />

          {currentPage && totalPages && (
            <div
              className={`pagination-container ${
                isLoading ? 'is-loading' : ''
              }`}
            >
              <div className="pagination-left">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={totalPages}
                  forcePage={currentPage - 1} // react-paginate is 0 based
                  previousLabel="Prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName="active"
                  pageClassName="page-item"
                  pageLinkClassName="button button-text"
                />
              </div>
              <div className="pagination-right">
                <div className="paging-limit">
                  {[250, 500].map((val) => (
                    <Button
                      type="button"
                      base
                      key={val}
                      className={`${limit === val ? 'active' : ''}`}
                      onClick={() => handleLimitChange(val)}
                    >
                      {val}
                    </Button>
                  ))}
                </div>
                <label className="text-color-gray-1">Results per page</label>
              </div>
            </div>
          )}
        </NavigatorBody>
      </Container>
    </NavigatorLayout>
  );
};

TemplateTasks.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  collectionTypes: PropTypes.array,
  paging: PropTypes.object.isRequired,
  tableColumns: PropTypes.array,
  handleColumnsChange: PropTypes.func,
  resetColumns: PropTypes.func,
  id: PropTypes.string,
  filters: PropTypes.object,
  filterOptions: PropTypes.object,
  isLoading: PropTypes.bool,
  routeNames: PropTypes.object,
  accessType: PropTypes.string,
  breadcrumbs: PropTypes.array,
  emptyActions: PropTypes.array,
  activeTab: PropTypes.string,
  exportHandler: PropTypes.func,
  exportFileName: PropTypes.string
};

export default TemplateTasks;
