import { exportClassData, updateClassData, destroyClassData } from 'lib/util';
import { formatDateTime } from 'lib/datetime';
import {
  formatNumberToCurrency,
  formatCurrencyToNumber,
  formatPercentageFloat
} from '../lib/currency';
import { contractPeriodStateByName } from '../lib/contracts';

const defaultContractPeriod = {
  id: null,
  contractId: null,
  startDate: null,
  endDate: null,
  createdAt: null,
  updatedAt: null,

  subscriptionQuota: null,
  subscriptionUsage: null,
  subscriptionReserve: null,
  availableSubscriptionUnits: null,

  valueCommitment: null,
  availableCommitment: null,
  reserve: null,
  usage: null,
  resellerDiscount: null
};

class ContractPeriod {
  constructor (data = {}) {
    Object.assign(this, defaultContractPeriod);
    this.update(data);
  }

  data () {
    return exportClassData(this);
  }

  update (data) {
    return updateClassData(this, data);
  }

  destroy () {
    return destroyClassData(this);
  }

  get startDateFormatted () {
    return formatDateTime(this.startDate);
  }

  get startDateInt () {
    return this.startDate && new Date(this.startDate).getTime();
  }

  get endDateFormatted () {
    return formatDateTime(this.endDate);
  }

  get endDateInt () {
    return this.endDate && new Date(this.endDate).getTime();
  }

  get createdAtFormatted () {
    return formatDateTime(this.createdAt);
  }

  get updatedAtFormatted () {
    return formatDateTime(this.updatedAt);
  }

  get valueCommitmentFormatted () {
    return formatNumberToCurrency(this.valueCommitment);
  }

  get valueCommitmentInt () {
    return formatCurrencyToNumber(this.valueCommitment);
  }

  get availableCommitmentFormatted () {
    return formatNumberToCurrency(this.availableCommitment);
  }

  get reserveFormatted () {
    return formatNumberToCurrency(this.reserve);
  }

  get usageFormatted () {
    return formatNumberToCurrency(this.usage);
  }

  get resellerDiscountFormatted () {
    return formatPercentageFloat(this.resellerDiscount);
  }

  get isPast () {
    const now = Date.now();
    const endDate = this.endDate && new Date(this.endDate);

    if (!endDate) return false;

    return now > endDate.getTime();
  }

  get isFuture () {
    const now = Date.now();
    const startDate = this.startDate && new Date(this.startDate);

    if (!startDate) return false;

    return now < startDate.getTime();
  }

  get isActive () {
    return !this.isPast && !this.isFuture;
  }

  get state () {
    let state;

    if (this.isActive) {
      state = contractPeriodStateByName('active');
    } else if (this.isPast) {
      state = contractPeriodStateByName('passed');
    } else if (this.isFuture) {
      state = contractPeriodStateByName('upcoming');
    }

    return state;
  }
}

export default ContractPeriod;
