import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useSubReseller } from 'hooks';

import { logError } from 'lib/logger';
import { errorMessageByCode } from 'lib/errors';
import { triggerNotice, fetchUsersBySubResellerId } from 'state/actions';

export default function useSubResellerFetchUsers ({ subResellerId } = {}) {
  const dispatch = useDispatch();
  const { users = [], actions = {} } = useSubReseller();

  /**
   * fetchUsers
   */

  function subResellerFetchUsers () {
    dispatch(fetchUsersBySubResellerId(subResellerId)).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedSubResellerFetchUsers = useCallback(() => {
    subResellerFetchUsers();
  }, [subResellerId]);

  useEffect(() => {
    memoizedSubResellerFetchUsers();
  }, [memoizedSubResellerFetchUsers]);

  return {
    subResellerFetchUsers: memoizedSubResellerFetchUsers,
    ...actions.fetchUsersBySubResellerId,
    users
  };
}
