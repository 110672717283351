import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormRow,
  FormInput,
  InputButton,
  InputRangeExtended
} from 'fogg/ui';
import InputRange from 'react-input-range';

import {
  taskDefaults,
  sliderValuesAndRange
} from 'lib/tasks';
import { chompFloat } from 'lib/util';
import { RepeatCycleTypes } from 'data/repeat-cycle-types';
import { CAPELLA_SUPPORT_URL } from 'commonLib/src/data/route-names';

import { useTaskContext } from 'hooks';

import Tooltip from './Tooltip';
import { OptionsPanel } from 'commonLib';

const FormCreateTaskOptions = ({
  isRepeatTask,
  acdIsSelected = false,
  isNotSpotlightMode = true,
  selectedAccessParameters,
  selectedCollectParameters
}) => {
  const { taskState, updateTaskState } = useTaskContext();
  const polarizationSelectOptions = selectedCollectParameters.polarization.values.map(value => ({ value, label: value }));

  const {
    collectConstraints: {
      imageWidth = selectedCollectParameters.imageWidth.default,
      offNadirMin = selectedAccessParameters.offNadirMin.default,
      offNadirMax = selectedAccessParameters.offNadirMax.default,
      polarization = selectedCollectParameters.polarization.default,
      targetHeight = null
    } = {},
    repetitionProperties: {
      lookAngleTolerance,
      maintainSceneFraming = false,
      repeatCycle = taskDefaults.repeat.DEFAULT_REPEAT_CYCLE_VALUE
    } = {},
    ascDsc = '',
    lookDirection = '',
    orbitalPlanes,
    preApproval = false,
    archiveHoldback = null,
    windowCloseInt,
    isRetask = false,
    fromArchive = false,
    customAttribute1,
    customAttribute2
  } = taskState;

  const returnOrbitalPlanesSelection = (selections = []) => selections?.length
    ? taskDefaults.AVAILABLE_ORBITAL_PLANES.filter((option) => {
      const { value } = option;
      return selections.includes(value);
    })
    : [];

  /**
   * input ref and useEffect keeps the style of the floating number element for a slider updated depending on its position
   */
  const inputRef = useRef();
  useEffect(() => {
    if (inputRef && inputRef.current) {
      const inputBox = inputRef.current;
      const rangeValue = inputBox.querySelector('#rangeValue');
      localStorage.setItem('rangeFloatStyle', rangeValue.style.left);
      rangeValue.style.left = localStorage.getItem('rangeFloatStyle');
      const timer = setTimeout(() => {
        rangeValue.style.left = localStorage.getItem('rangeFloatStyle');
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [targetHeight]);

  const {
    allGeometricConstraints = false,
    minimalGeometricConstraints = false,
    advancedGeometricConstraints = false
  } = RepeatCycleTypes.properties[repeatCycle];

  const disableLookAngle = isRetask && (!isRepeatTask && !fromArchive);

  return (
    <OptionsPanel label="Optional Parameters" id="optional-parameters" defaultIsOpen={isRetask}>
      <FormRow>
        <div
          className={`search-filters-range-slider ${disableLookAngle ? 'disabled' : ''}`}
          data-testid="look-angle"
        >
          <label className="form-label-slider">Look Angle</label>
          <InputRange
            {...sliderValuesAndRange({
              defaultValueMin: offNadirMin,
              defaultValueMax: offNadirMax,
              defaultRangeMin: selectedAccessParameters.offNadirMin.minimum,
              defaultRangeMax: selectedAccessParameters.offNadirMax.maximum,
              valueSideEffects: {
                min: offNadirMin,
                max: offNadirMax
              },
              rangeSideEffects: () => ({
                minValue: selectedAccessParameters.offNadirMin.minimum,
                maxValue: selectedAccessParameters.offNadirMax.maximum
              })
            })}
            disabled={disableLookAngle}
            onChange={({ min, max } = {}) => {
              const newTaskState = {
                shouldRefreshAccessTimes: true,
                collectConstraints: {
                  offNadirMin: typeof min === 'number' ? chompFloat(min, 2) : min,
                  offNadirMax: typeof max === 'number' ? chompFloat(max, 2) : max
                }
              };
              if (!isRepeatTask) newTaskState.windowCloseInt = windowCloseInt;

              updateTaskState(newTaskState);
            }}
          />
        </div>
      </FormRow>

      {isNotSpotlightMode && (
        <FormRow>
          <div
            className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
            data-testid="image-width"
          >
            <label className="form-label-slider">Image Width</label>
            <InputRange
              minValue={selectedCollectParameters.imageWidth.minimum}
              maxValue={selectedCollectParameters.imageWidth.maximum}
              value={imageWidth}
              step={1000}
              formatLabel={(value) => `${value / 1000}km`}
              disabled={isRetask}
              onChange={(value) =>
                updateTaskState({
                  collectConstraints: {
                    imageWidth: value
                  }
                })
              }
            />
          </div>
        </FormRow>
      )}

      {(!isRepeatTask || minimalGeometricConstraints) && (
        <FormRow className="horizontal-button-list">
          <div className="search-dropdowns" data-testid="orbitalPlanes">
            <FormInput
              id="orbitalPlanes"
              name="orbitalPlanes"
              type="multiselect"
              label="Orbital Plane"
              clearable={false}
              disabled={isRetask}
              options={taskDefaults.AVAILABLE_ORBITAL_PLANES}
              value={returnOrbitalPlanesSelection(orbitalPlanes)}
              onChange={(selections) => {
                let selectionsArray;
                if (selections?.length && selections.length > 0) {
                  selectionsArray = selections.map(({ value }) => value);
                }

                const newTaskState = {
                  orbitalPlanes: selectionsArray,
                  shouldRefreshAccessTimes: true
                };
                if (!isRepeatTask) newTaskState.windowCloseInt = windowCloseInt;

                updateTaskState(newTaskState);
              }}
            />
          </div>
        </FormRow>
      )}

      {(!isRepeatTask || advancedGeometricConstraints) && (
        <>
          <FormRow>
            <div className="search-dropdowns" data-testid="orbitalState">
              <FormInput
                id="ascDsc"
                type="select"
                label="Orbit State"
                clearable={true}
                disabled={isRetask}
                options={taskDefaults.AVAILABLE_ASC_DSC}
                defaultValue={ascDsc}
                onChange={({ value = '' } = {}) => {
                  const newTaskState = {
                    ascDsc: value,
                    shouldRefreshAccessTimes: true
                  };
                  if (!isRepeatTask) newTaskState.windowCloseInt = windowCloseInt;

                  updateTaskState(newTaskState);
                }}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className="search-dropdowns" data-testid="observationDirection">
              <FormInput
                id="lookDirection"
                type="select"
                label="Observation Direction"
                clearable={true}
                disabled={isRetask}
                options={taskDefaults.AVAILABLE_LOOK_DIRECTIONS}
                defaultValue={lookDirection}
                onChange={({ value } = {}) => {
                  const newTaskState = {
                    lookDirection: value,
                    shouldRefreshAccessTimes: true
                  };
                  if (!isRepeatTask) newTaskState.windowCloseInt = windowCloseInt;

                  updateTaskState(newTaskState);
                }}
              />
            </div>
          </FormRow>
          {isRepeatTask && (
            <>
              <FormRow>
                <div className="search-dropdowns" data-testid="scene-framing">
                  <InputButton
                    id="maintainSceneFraming"
                    label="Maintain Scene Framing"
                    type="checkbox"
                    disabled={acdIsSelected || isRetask}
                    isChecked={acdIsSelected ? true : maintainSceneFraming}
                    controlChecked={true}
                    onChange={({ target } = {}) =>
                      updateTaskState({
                        repetitionProperties: {
                          lookAngleTolerance: target?.checked ? 10 : null,
                          maintainSceneFraming: target?.checked
                        }
                      })
                    }
                  />
                </div>
              </FormRow>
              {maintainSceneFraming && (
                <>
                  <FormRow>
                    <div className="form-input">
                      <label className="form-label vertical-centered">Look Angle Tolerance
                        {acdIsSelected && (
                          <Tooltip
                            className="access-requests-tip"
                            id="lookAngleToleranceTip"
                            delayHide={500}
                            delayUpdate={500}
                            place="top"
                            effect="solid"
                            type="dark"
                            multiline={true}
                            tooltipText={<div>
                              <p><b>123Larger</b> Look Angle Tolerance (e.g. 10°) will result in more collection opportunities and is more likely to be accepted. Change artifacts are also more likely.</p>
                              <p><b>Smaller</b> Look Angle Tolerance can help reduce change artifacts and noise, but results in fewer collection opportunities and is less likely to be accepted in competitive areas.</p>
                              <p className="tool-tip-footer">More details at <a href={CAPELLA_SUPPORT_URL} target="_blank" rel="noopenner noreferrer">Capella Support</a></p>
                            </div>}
                          />
                        )}
                      </label>
                    </div>
                  </FormRow>
                  <FormRow>
                    <div
                      className={`search-filters-range-slider ${isRetask ? 'disabled' : ''}`}
                      data-testid="look-angle-tolerance"
                    >
                      <InputRangeExtended
                        className={`${isRetask ? 'disabled disabled-visually' : ''}`}
                        minValue={0}
                        maxValue={10}
                        value={lookAngleTolerance}
                        onChangeComplete={(value) =>
                          updateTaskState({
                            repetitionProperties: {
                              lookAngleTolerance: parseFloat(value)
                            }
                          })
                        }
                        step={1}
                        metric={' degrees'}
                        onChangeDelay={0.3}
                      />
                    </div>
                  </FormRow>
                </>
              )}
            </>
          )}
        </>
      )}

      <FormRow>
        <div className="search-dropdowns" data-testid="polarization">
          <FormInput
            id="polarization"
            type="select"
            label="Polarization"
            clearable={true}
            disabled={isRetask}
            options={polarizationSelectOptions}
            defaultValue={polarization}
            onChange={(selection) =>
              updateTaskState({
                collectConstraints: {
                  polarization: selection?.value || undefined
                }
              })
            }
          />
        </div>
      </FormRow>

      <FormRow>
        <div className="search-dropdowns" data-testid="targetHeight">
          <FormInput
            id="showTargetHeight"
            type="select"
            label="Target Height"
            clearable={false}
            disabled={isRetask}
            options={taskDefaults.SHOW_TARGET_HEIGHT}
            onChange={({ value }) =>
              updateTaskState({
                collectConstraints: {
                  targetHeight: value === 'custom' ? targetHeight || 0 : null
                }
              })
            }
            defaultValue={targetHeight ? 'custom' : 'default'}
          />
        </div>
      </FormRow>
      {targetHeight !== null && (
        <FormRow>
          <div className="search-filters-range-slider" ref={inputRef}>
            <InputRangeExtended
              className={`${isRetask ? 'disabled disabled-visually' : ''}`}
              minValue={-1000}
              maxValue={9000}
              value={targetHeight || 0}
              disabled={isRetask}
              onChangeComplete={(value) =>
                updateTaskState({
                  collectConstraints: {
                    targetHeight: parseInt(value, 10)
                  }
                })
              }
              step={1}
              metric={'m'}
              onChangeDelay={0.3}
            />
          </div>
        </FormRow>
      )}

      <FormRow className="form-row-block">
        <label className="form-label standalone-label" htmlFor="archiveHoldback">
          Archive Catalog Holdback
          <Tooltip
            className="customer-internal-id-tip"
            id="archiveHoldbackTip"
            multiline={true}
            tooltipText="Archive catalog holdback is an option for customers to delay the time in which a tasked image is included in the publicly available imagery archive. For a value of 'None', imagery will immediately flow into the public archive. For any value other than 'None', an uplift charge will be included in the final task price and the imagery will only appear in the public archive after the holdback period. If no value is provided, it will automatically be set to the default value defined by the contract selected during the next step."
          />
        </label>
        <div className="search-dropdowns" data-testid="archiveHoldback">
          <FormInput
            type="select"
            id="archiveHoldback"
            className="archive-holdback-select"
            clearable={true}
            options={taskDefaults.ARCHIVE_HOLDBACK_VALUES}
            defaultValue={archiveHoldback}
            onChange={({ value }) => {
              updateTaskState({
                archiveHoldback: value
              });
            }}
          />
        </div>
      </FormRow>

      <FormRow className="form-row-block">
        <label className="form-label standalone-label" htmlFor="customAttribute1">
          Custom Attribute 1
          <Tooltip
            className="customer-internal-id-tip"
            id="customAttribute1Tip"
            multiline={true}
            tooltipText='Attribute to help customers track a Capella task with their internal systems'
          />
        </label>
        <FormInput
          id="customAttribute1"
          defaultValue={customAttribute1}
          onChange={({ target: { value = '' } }) =>
            updateTaskState({
              customAttribute1: value
            })
          }
          validationMessage="2 character minimum for ID"
        />
      </FormRow>
      <FormRow className="form-row-block">
        <label className="form-label standalone-label" htmlFor="customAttribute2">
          Custom Attribute 2
          <Tooltip
            className="customer-po-id-tip"
            id="customAttribute2Tip"
            multiline={true}
            tooltipText='Attribute to help customers track a Capella task with their internal systems'
          />
        </label>
        <FormInput
          id="customAttribute2"
          defaultValue={customAttribute2}
          onChange={({ target: { value = '' } }) =>
            updateTaskState({
              customAttribute2: value
            })
          }
          validationMessage="2 character minimum for ID"
        />
      </FormRow>

      {(!isRepeatTask || (allGeometricConstraints && repeatCycle !== RepeatCycleTypes.OTHER)) && (
        <FormRow>
          <InputButton
            id="preApproval"
            label="Pre-Approved"
            type="checkbox"
            value="true"
            isChecked={preApproval}
            disabled={isRetask}
            onChange={({ currentTarget = {} }) => {
              const { checked } = currentTarget;
              updateTaskState({
                preApproval: checked
              });
            }}
          />
        </FormRow>
      )}
    </OptionsPanel>
  );
};

FormCreateTaskOptions.propTypes = {
  isRepeatTask: PropTypes.bool,
  acdIsSelected: PropTypes.bool,
  isNotSpotlightMode: PropTypes.bool,
  selectedAccessParameters: PropTypes.object,
  selectedCollectParameters: PropTypes.object
};

export default FormCreateTaskOptions;
