import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaUndo } from 'react-icons/fa';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { Button, Checkbox } from '@trussworks/react-uswds';

/**
 * SortableTable column chooser with drag & drop functionality
 * @param {object} props
 * @param {array} props.columns=[]
 * @param {func} props.handleColumns
 * @param {func} props.resetColumns
 */
const TableColumnChooser = ({ columns = [], handleColumns, resetColumns }) => {
  // Event driven click handler for column checkboxes
  function handleColumnCheckbox ({ target = {} }) {
    const { value, checked } = target;
    const updatedColumns = columns.map((col) => {
      if (col.columnId === value) {
        return ({
          ...col,
          isActive: checked
        });
      } else return col;
    });
    handleColumns(updatedColumns);
  }

  // Drag & Drop handler for columns list
  function onDragEnd (result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newOrder = Array.from(columns);
    const [removed] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, removed);
    handleColumns(newOrder);
  }

  return (
    <nav id="columnChooser" className="dropdown-menu" data-testid="table-column-chooser">
      <Button className="reset-columns-button" unstyled onClick={resetColumns}>
        <FaUndo /> Reset Column Defaults
      </Button>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul className="active-columns" {...provided.droppableProps} ref={provided.innerRef}>
              {columns.map(({ columnId, label, isActive }, index) => (
                <Draggable key={columnId} draggableId={columnId} index={index}>
                  {(provided, snapshot) => (
                    <li
                      className="draggable-row"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Checkbox
                        name="checkbox"
                        label={label}
                        value={columnId}
                        onChange={handleColumnCheckbox}
                        checked={isActive}
                        id={`checkbox-${columnId}`}
                        data-testid={`checkbox-${columnId}`}
                      />
                      <MdOutlineDragIndicator />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </nav>
  );
};

TableColumnChooser.propTypes = {
  columns: PropTypes.array.isRequired,
  handleColumns: PropTypes.func.isRequired,
  resetColumns: PropTypes.func.isRequired
};

export default TableColumnChooser;
