import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import { normalizePathname, routePathByName, redirectTo } from 'lib/routes';
import { useLocation } from 'hooks';

import AccountProfilePage from 'pages-client/account/profile';

import Layout from 'components/Layout';
import Container from 'components/Container';

const AccountIndex = ({ location }) => {
  const { pathname } = useLocation(location);

  if (normalizePathname(pathname) === routePathByName('account')) {
    redirectTo(routePathByName('accountProfile'));

    // Return a layout component to avoid a page flicker of no content

    return (
      <Layout>
        <Container className="content" />
      </Layout>
    );
  }

  return (
    <>
      <Router basepath="/">
        <AccountProfilePage path={routePathByName('accountProfile')} />
      </Router>
    </>
  );
};

AccountIndex.propTypes = {
  location: PropTypes.object
};

export default AccountIndex;
