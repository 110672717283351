import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useOrg } from 'hooks';
import { logError } from 'lib/logger';
import { fetchOrgUserById, triggerNotice } from 'state/actions';

export default function useOrgFetchUser (
  { id } = {},
  { ignoreValidation = false } = {}
) {
  const { users = [], actions = {} } = useOrg();
  const dispatch = useDispatch();

  const user = users.find((user) => user.id === id);
  const isValidId = typeof id === 'string';

  useEffect(() => {
    // Provide a way for the fetch assets request to happen without
    // already having the whole order object

    if (!ignoreValidation && !isValidId) return;

    dispatch(fetchOrgUserById(id)).catch((error) => {
      logError(error.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
    });
  }, [dispatch, id]);

  return {
    ...actions.fetchOrgUserById,
    user
  };
}
