import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBriefcase } from 'react-icons/fa';

import { routePathByName, redirectTo } from 'lib/routes';
import { logError } from 'lib/logger';
import {
  addOrganizationContractPeriod,
  triggerSuccess,
  triggerError
} from 'state/actions';
import { useResellerFetchOrganization } from 'hooks';

import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from 'commonLib/src/data/contracts';

import Layout from 'components/Layout';

import {
  Breadcrumbs,
  PageHeader,
  Container,
  FormContractPeriodAdd
} from 'commonLib';

const PAGE_TITLE = 'Add Contract Period';

const ResellerOrganizationsContractPeriodAddPage = ({
  organizationId,
  contractId
}) => {
  const dispatch = useDispatch();

  const { organization = {}, isLoading } = useResellerFetchOrganization({
    id: organizationId
  });

  const contract = organization?.contracts?.find(contract => contract.id === contractId);
  const isArchiveSub = contract?.type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;

  const organizationDetailsPath = routePathByName(
    'resellerOrganizationsDetails',
    {
      wildcard: organizationId
    }
  );
  const contractDetailsPath = routePathByName('resellerOrgContractDetails', {
    wildcard: [organizationId, contractId]
  });

  function handleOnSubmit ({ data }) {
    dispatch(addOrganizationContractPeriod(organizationId, contractId, data))
      .then(() => {
        redirectTo(contractDetailsPath);
        dispatch(triggerSuccess('Successfully added a new contract period!'));
      })
      .catch((error) => {
        logError(error.message, data);
        dispatch(triggerError());
      });
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-reseller page-reseller-contract page-reseller-contract-edit'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Reseller',
              to: routePathByName('reseller')
            },
            {
              label: 'Organizations',
              to: routePathByName('resellerOrganizations')
            },
            {
              label: 'Organization',
              to: organizationDetailsPath
            },
            {
              label: 'Contract',
              to: contractDetailsPath
            }
          ]}
        />
        <PageHeader title={PAGE_TITLE} icon={<FaBriefcase />} />
        <FormContractPeriodAdd
          disabled={isLoading}
          isArchiveSub={isArchiveSub}
          onSubmit={handleOnSubmit}
        />
      </Container>
    </Layout>
  );
};

ResellerOrganizationsContractPeriodAddPage.propTypes = {
  organizationId: PropTypes.string,
  contractId: PropTypes.string
};

export default ResellerOrganizationsContractPeriodAddPage;
