import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBriefcase } from 'react-icons/fa';

import { useResellerFetchOrganization } from 'hooks';
import { routePathByName, redirectTo } from 'lib/routes';
import { logError } from 'lib/logger';
import {
  updateOrganizationContractPeriod,
  triggerSuccess,
  triggerError
} from 'state/actions';

import ContractPeriod from 'commonLib/src/models/contract-period';

import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from 'commonLib/src/data/contracts';

import Layout from 'components/Layout';

import {
  Breadcrumbs,
  PageHeader,
  Container,
  FormContractPeriodEdit
} from 'commonLib';

const PAGE_TITLE = 'Edit Contract Period';

const ResellerOrganizationsContractPeriodEditPage = ({
  organizationId,
  contractPeriodId
}) => {
  const dispatch = useDispatch();

  const { organization = {}, isLoading } = useResellerFetchOrganization({
    id: organizationId
  });

  const contract =
    organization?.contracts?.find((orgContract) =>
      orgContract?.contractPeriods?.find((cp) => cp.id === contractPeriodId)
    ) || {};
  let contractPeriod = contract?.contractPeriods?.find(
    (cp) => cp.id === contractPeriodId
  );
  contractPeriod = new ContractPeriod(contractPeriod);

  const isArchiveSub = contract?.type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;

  const organizationDetailsPath = routePathByName(
    'resellerOrganizationsDetails',
    {
      wildcard: organizationId
    }
  );
  const contractDetailsPath = routePathByName('resellerOrgContractDetails', {
    wildcard: [organizationId, contract.id]
  });

  function handleOnSubmit ({ data }) {
    dispatch(
      updateOrganizationContractPeriod(organizationId, contractPeriodId, data)
    )
      .then(() => {
        redirectTo(contractDetailsPath);
        dispatch(
          triggerSuccess(
            `Successfully updated contract period ${contractPeriodId}!`
          )
        );
      })
      .catch((error) => {
        logError(error.message, data);
        dispatch(triggerError());
      });
  }

  // Admins can "cancel an edit" by navigating back to the org details page
  function handleOnCancel () {
    redirectTo(contractDetailsPath);
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-reseller page-reseller-contract page-reseller-contract-edit'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Reseller',
              to: routePathByName('reseller')
            },
            {
              label: 'Organizations',
              to: routePathByName('resellerOrganizations')
            },
            {
              label: 'Organization',
              to: organizationDetailsPath
            },
            {
              label: 'Contract',
              to: contractDetailsPath
            }
          ]}
        />
        <PageHeader title={PAGE_TITLE} icon={<FaBriefcase />} />
        <FormContractPeriodEdit
          contract={contract}
          contractPeriod={contractPeriod}
          isArchiveSub={isArchiveSub}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
          disabled={isLoading}
        />
      </Container>
    </Layout>
  );
};

ResellerOrganizationsContractPeriodEditPage.propTypes = {
  organizationId: PropTypes.string,
  contractPeriodId: PropTypes.string
};

export default ResellerOrganizationsContractPeriodEditPage;
