import React from 'react';
import PropTypes from 'prop-types';
import { useFetchRepeatRequest, useRepeatRequest } from 'hooks';
import { routePathByName } from 'lib/routes';
import {
  ROUTE_REPEAT_REQUESTS,
  ROUTE_REPEAT_REQUEST_DETAILS
} from 'data/route-names';
import TemplateEditRepeatRequest from 'templates/TemplateEditRepeatRequest';

const RepeatRequestEditPage = ({ repeatRequestId }) => {
  const repeatRequest = useRepeatRequest(repeatRequestId) || {
    id: repeatRequestId
  };

  const { name } = repeatRequest || {};

  const { isLoading } = useFetchRepeatRequest({
    repeatRequestId,
    forceNew: true
  });

  const breadcrumbs = [
    {
      label: 'Repeat Requests',
      to: routePathByName(ROUTE_REPEAT_REQUESTS)
    },
    {
      label: name,
      to: routePathByName(ROUTE_REPEAT_REQUEST_DETAILS, {
        wildcard: [repeatRequestId]
      })
    }

  ];

  return (
    <TemplateEditRepeatRequest
      repeatRequest={repeatRequest}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    />
  );
};

RepeatRequestEditPage.propTypes = {
  repeatRequestId: PropTypes.string
};

export default RepeatRequestEditPage;
