import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useOrder, useOrders } from 'hooks';

import { logError } from 'lib/logger';
import { downloadOrder, triggerNotice } from 'state/actions';

const ORDER_STATUSES_SHOULD_DOWNLOAD = ['completed'];

export default function useFetchOrderAssets (
  orderId,
  { ignoreValidation = false } = {}
) {
  const dispatch = useDispatch();

  const { actions = {} } = useOrders();
  const order = useOrder(orderId);

  const { orderDate, items = [] } = order || {};
  const hasItems = Array.isArray(items) && items.length > 0;
  const isValidOrder = orderId && typeof orderDate === 'string';
  const isValidStatus =
    order && ORDER_STATUSES_SHOULD_DOWNLOAD.includes(order.status);
  const shouldFetch = isValidOrder && hasItems && isValidStatus;

  useEffect(() => {
    // Provide a way for the fetch assets request to happen without
    // already having the whole order object

    if (!ignoreValidation && !shouldFetch) return;

    // We still require the order ID to even make the request

    if (!orderId) return;

    dispatch(downloadOrder(orderId)).catch((error = {}) => {
      const { message } = error;

      logError(message);

      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
    });
  }, [dispatch, orderId, shouldFetch, ignoreValidation]);

  return {
    ...actions.downloadOrder,
    items
  };
}
