import React from 'react';
import clone from 'clone';
import deepmerge from 'deepmerge';
import { FiClock, FiMoon, FiSun } from 'react-icons/fi';
import {
  fieldValueOrDefault,
  checkedInputToBool,
  between,
  isUuid
} from '../lib/util';
import { routePathByName } from '../lib/routes';
import { taskFilterDateHandler, formatDateTime } from '../lib/datetime';
import { ROUTE_TASKS_DETAILS } from '../data/route-names';

import { TASKING_REQUEST_ID } from '../data/property-names';
import {
  TASK_STATUS_COMPLETE,
  TASK_STATUS_ACTIVE,
  TASK_STATUS_ACCEPTED,
  TASK_STATUS_RECEIVED,
  TASK_STATUS_REVIEW,
  TASK_STATUS_SUBMITTED,
  AVAILABLE_ORBITAL_PLANES,
  AVAILABLE_ORBITAL_PLANES_NO_97
} from '../data/tasks';
import { TaskRequestTypes } from '../data/task-request-types';
import { RepeatRequestEndTypes } from '../data/repeat-request-end-types';
import { RepeatCycleTypes } from '../data/repeat-cycle-types';
import { TaskCollectionTiers } from '../data/task-collection-tiers';
import { TaskCollectModes } from '../data/task-collect-modes';
import { WonderLink } from 'fogg/ui';

export const DEFAULT_LOOK_DIRECTION = 'either';
export const DEFAULT_ASC_DSC = 'either';
export const DEFAULT_LOCAL_TIME = 'anytime';
export const DEFAULT_OFF_NADIR_MIN = 25;
export const DEFAULT_OFF_NADIR_MAX = 50;
export const DEFAULT_COLLECT_MODE = TaskCollectModes.SPOTLIGHT;
export const DEFAULT_COLLECT_MODE_AREA = TaskCollectModes.STRIPMAP;
export const DEFAULT_IMAGE_WIDTH = 5000;
export const DEFAULT_IMAGE_LENGTH = 5000;
export const DEFAULT_GRR_MIN = 0.4;
export const DEFAULT_GRR_MAX = 3.1;
export const DEFAULT_AZR_MIN = 0.5;
export const DEFAULT_AZR_MAX = 0.5;
export const DEFAULT_NESZ_MAX = -11;
export const DEFAULT_NUM_LOOKS = 5;
export const DEFAULT_NUM_LOOKS_SPOTLIGHT = 9;
export const DEFAULT_COLLECTION_TIER = TaskCollectionTiers.TIER_STANDARD;
export const DEFAULT_PREAPPROVAL = false;
export const DEFAULT_PRODUCT_CATEGORY = 'standard';
export const DEFAULT_ARCHIVE_HOLDBACK = 'none';
export const DEFAULT_REPEAT = true;
export const DEFAULT_TASKING_TIER = 7;

export const DEFAULT_RANGE_MODE = 'max_width';
export const DEFAULT_ELEVATION_MIN = 10;
export const DEFAULT_ELEVATION_MAX = 20;
export const DEFAULT_SQUINT = '';
export const DEFAULT_RADAR_PARAMETERS_BANDWIDTH = '';
export const DEFAULT_RADAR_PARAMETERS_CENTER_FREQUENCY = '';
export const DEFAULT_RADAR_PARAMETERS_PRF = '';
export const DEFAULT_SRR_MIN = '';
export const DEFAULT_SRR_MAX = '';
export const WINDOW_OPEN_BUFFER = -(60 * 60 * 1000);

// SLIDER RANGE MIN/MAX DEFAULTS
export const DEFAULT_GRR_MIN_RANGE = 0.4;
export const DEFAULT_GRR_MAX_RANGE = 3.1;
export const DEFAULT_AZR_MIN_RANGE = 0.5;
export const DEFAULT_AZR_MAX_RANGE = 3.1;
export const DEFAULT_NUM_LOOKS_MIN_RANGE = 1;

// OOSM Specific values
export const DEFAULT_OOSM_LOOK_ANGLE_MIN = 45;
export const DEFAULT_OOSM_LOOK_ANGLE_MAX = 50;
export const DEFAULT_ANGLE_CORRESPONDENCE = 90;
export const DEFAULT_OOSM_IMAGE_LENGTH = 50000;
export const DEFAULT_OOSM_COLLECT_MODE = TaskCollectModes.STRIPMAP;
export const DEFAULT_OOSM_COLLECTION_TIER = TaskCollectionTiers.TIER_URGENT;
export const DEFAULT_OOSM_WINDOW_CLOSE_PERIOD = 3; // number of days
export const AVAILABLE_OOSM_IMAGE_LENGTHS = [50000, 100000];

// temporary default values for azrMin and azrMax for OOSM tasks due to CAPI limitations,
// per: https://element84.atlassian.net/browse/JAM-2352?focusedCommentId=75119
export const DEFAULT_OOSM_AZR_MIN = 1.2;
export const DEFAULT_OOSM_AZR_MAX = 1.2;

// Repeat Task  specific values
export const DEFAULT_REPEAT_TASK_COLLECTION_TIER = TaskCollectionTiers.TIER_ROUTINE;
export const DEFAULT_REPEAT_TASK_END_TYPE_VALUE =
  RepeatRequestEndTypes.INDEFINITE;
export const DEFAULT_REPEAT_TASK_TASKING_TIER = 30;
export const DEFAULT_REPEAT_CYCLE_VALUE = RepeatCycleTypes.WEEKLY;

// Area Task specific values
export const DEFAULT_AREA_TASK_IMAGE_WIDTH = 8000;
export const DEFAULT_AREA_TASK_IMAGE_LENGTH = 100000;

export const DEFAULT_AREA_TASK_GRR_MIN = 1.1;
export const DEFAULT_AREA_TASK_GRR_MAX = 1.6;
export const DEFAULT_AREA_TASK_AZR_MIN = 1.2;
export const DEFAULT_AREA_TASK_AZR_MAX = 1.2;

export const DEFAULT_AREA_TASK_TASKING_TIER = 30;

const DEFAULT_LOOK_ANGLE_RANGE_MIN = 5;
const DEFAULT_LOOK_ANGLE_RANGE_MAX = 40;

/**
 * getLookAngleRange
 * @description get look angle range by collectMode and productCategory
 */
export function getLookAngleRange (collectMode, productCategory) {
  const lookAngleRange = {
    minValue: DEFAULT_LOOK_ANGLE_RANGE_MIN,
    maxValue: DEFAULT_LOOK_ANGLE_RANGE_MAX
  };
  if (
    ['spotlight', 'sliding_spotlight', 'stripmap'].includes(collectMode) &&
    ['custom', 'extended'].includes(productCategory)
  ) {
    lookAngleRange.maxValue = 50;
  }

  if (productCategory === 'extended') {
    lookAngleRange.minValue = 15;
  }
  return lookAngleRange;
}

// TODO - Remove this along with FormCreateTaskStandard and FormCreateTaskRepeat
/**
 * getLookAngleDefaults
 * @description get look angle defaults by collectMode and productCategory
 */
export function getLookAngleDefaults (collectMode, productCategory) {
  const lookAngleDefaults = {
    minValue: DEFAULT_OFF_NADIR_MIN,
    maxValue: DEFAULT_OFF_NADIR_MAX
  };
  if (
    ['spotlight', 'sliding_spotlight', 'stripmap'].includes(collectMode) &&
    ['custom', 'extended'].includes(productCategory)
  ) {
    lookAngleDefaults.maxValue = 50;
  }

  if (productCategory === 'custom') {
    lookAngleDefaults.minValue = 5;
  }

  if (productCategory === 'extended') {
    lookAngleDefaults.minValue = 15;
  }

  return lookAngleDefaults;
}

/**
 * getGRRRange
 * @description get ground range resolution range by collectMode
 */
export function getGRRRange (collectMode) {
  const grrRange = {
    minValue: DEFAULT_GRR_MIN_RANGE,
    maxValue: DEFAULT_GRR_MAX_RANGE
  };

  if (collectMode === TaskCollectModes.SPOTLIGHT) {
    grrRange.minValue = 0.4;
    grrRange.maxValue = 3.1;
  }

  if (collectMode === TaskCollectModes.STRIPMAP) {
    grrRange.minValue = 1.1;
    grrRange.maxValue = 11.5;
  }

  if (collectMode === TaskCollectModes.SLIDING_SPOTLIGHT) {
    grrRange.minValue = 0.7;
    grrRange.maxValue = 5.0;
  }
  return grrRange;
}

/**
 * getGRRDefaults
 * @description get ground range resolution defaults by collectMode
 */
export function getGRRDefaults (collectMode) {
  const grrDefaults = {
    minValue: DEFAULT_GRR_MIN,
    maxValue: DEFAULT_GRR_MAX
  };

  // full range for custom by default
  if (collectMode === TaskCollectModes.SPOTLIGHT) {
    grrDefaults.minValue = 0.4;
    grrDefaults.maxValue = 3.1;
  }

  if (collectMode === TaskCollectModes.STRIPMAP) {
    grrDefaults.minValue = 1.1;
    grrDefaults.maxValue = 11.5;
  }

  if (collectMode === TaskCollectModes.SLIDING_SPOTLIGHT) {
    grrDefaults.minValue = 0.7;
    grrDefaults.maxValue = 5.0;
  }

  return grrDefaults;
}

export const THIRTY_DAYS_MS = 2.592e+9;

export const TASK_ENVIRONMENTS = [
  {
    label: 'Production',
    id: 'production'
  },
  {
    label: 'Sandbox',
    id: 'sandbox',
    isDefault: true
  }
];

export const AVAILABLE_COLLECT_MODES = [
  {
    value: 'spotlight',
    label: 'Spotlight'
  },
  {
    value: 'stripmap',
    label: 'Stripmap'
  },
  {
    value: 'sliding_spotlight',
    label: 'Sliding Spotlight'
  }
];

const { TIER_FLEXIBLE, TIER_INTERNAL, TIER_STANDARD, TIER_PRIORITY, TIER_URGENT } =
  TaskCollectionTiers;
export const AVAILABLE_COLLECTION_TIERS = [
  {
    value: TIER_URGENT,
    ...TaskCollectionTiers.properties[TIER_URGENT]
  },
  {
    value: TIER_PRIORITY,
    ...TaskCollectionTiers.properties[TIER_PRIORITY]
  },
  {
    value: TIER_STANDARD,
    ...TaskCollectionTiers.properties[TIER_STANDARD]
  },
  {
    value: TIER_FLEXIBLE,
    ...TaskCollectionTiers.properties[TIER_FLEXIBLE]
  },
  {
    value: TIER_INTERNAL,
    ...TaskCollectionTiers.properties[TIER_INTERNAL]
  }
];

const { TIER_ROUTINE } = TaskCollectionTiers;
export const AVAILABLE_REPEAT_TASK_COLLECTION_TIERS = [
  {
    value: TIER_ROUTINE,
    ...TaskCollectionTiers.properties[TIER_ROUTINE]
  },
  {
    value: TIER_FLEXIBLE,
    ...TaskCollectionTiers.properties[TIER_FLEXIBLE]
  },
  {
    value: TIER_INTERNAL,
    ...TaskCollectionTiers.properties[TIER_INTERNAL]
  }
];

const {
  INDEFINITE: INDEFINITE_END_TYPE,
  DATE: DATE_END_TYPE,
  COUNT: COUNT_END_TYPE
} = RepeatRequestEndTypes;

export const AVAILABLE_REPEAT_TASK_END_OPTIONS = [
  {
    value: INDEFINITE_END_TYPE,
    label: RepeatRequestEndTypes.properties[INDEFINITE_END_TYPE].label
  },
  {
    value: DATE_END_TYPE,
    label: RepeatRequestEndTypes.properties[DATE_END_TYPE].label
  },
  {
    value: COUNT_END_TYPE,
    label: RepeatRequestEndTypes.properties[COUNT_END_TYPE].label
  }
];

const { DAILY, WEEKLY, BIWEEKLY, MONTHLY, OTHER } = RepeatCycleTypes;

export const AVAILABLE_REPEAT_CYCLE_OPTIONS = [
  {
    value: DAILY,
    label: RepeatCycleTypes.properties[DAILY].label
  },
  {
    value: WEEKLY,
    label: RepeatCycleTypes.properties[WEEKLY].label
  },
  {
    value: BIWEEKLY,
    label: RepeatCycleTypes.properties[BIWEEKLY].label
  },
  {
    value: MONTHLY,
    label: RepeatCycleTypes.properties[MONTHLY].label
  },
  {
    value: OTHER,
    label: RepeatCycleTypes.properties[OTHER].label
  }
];

export const ACD_REPEAT_CYCLE_OPTIONS = AVAILABLE_REPEAT_CYCLE_OPTIONS.filter(
  ({ value }) => [BIWEEKLY, MONTHLY, OTHER].includes(value)
);

export const AVAILABLE_ASC_DSC = [
  {
    value: 'ascending',
    label: 'Ascending'
  },
  {
    value: 'descending',
    label: 'Descending'
  }
];

export const AVAILABLE_LOOK_DIRECTIONS = [
  {
    value: 'left',
    label: 'Left'
  },
  {
    value: 'right',
    label: 'Right'
  }
];

export const AVAILABLE_POLARIZATION = [
  { value: 'HH', label: 'HH' },
  { value: 'VV', label: 'VV' }
];

export const AVAILABLE_PRODUCT_CATEGORIES = [
  {
    value: 'standard',
    label: 'Standard'
  },
  {
    value: 'extended',
    label: 'Extended'
  },
  {
    value: 'custom',
    label: 'Custom'
  }
];

export const ARCHIVE_HOLDBACK_VALUES = [
  {
    value: 'none',
    label: 'None'
  },
  {
    value: '30_day',
    label: '30 Days'
  },
  {
    value: '1_year',
    label: '1 Year'
  },
  {
    value: 'permanent',
    label: 'Permanent'
  }
];

// The available status and order that a task goes through
// from start to completion. This doesn't include errors which
// are handled separately.

const taskStatusProcessChainIds = [
  TASK_STATUS_RECEIVED,
  TASK_STATUS_REVIEW,
  TASK_STATUS_SUBMITTED,
  TASK_STATUS_ACTIVE,
  TASK_STATUS_ACCEPTED,
  TASK_STATUS_COMPLETE
];

export const TASK_STATUS_UNKNOWN = {
  label: 'Unknown',
  id: 'unknown'
};

export const AVAILABLE_TASK_STATUSES = [
  {
    label: 'Accepted',
    id: TASK_STATUS_ACCEPTED
  },
  {
    label: 'Active',
    id: TASK_STATUS_ACTIVE
  },
  {
    label: 'Tasking Anomaly',
    id: 'anomaly',
    type: 'error'
  },
  {
    label: 'Collection Anomaly',
    id: 'collection_anomaly',
    type: 'error'
  },
  {
    label: 'Processing Anomaly',
    id: 'processing_anomaly',
    type: 'error'
  },
  {
    label: 'Canceled',
    id: 'canceled',
    type: 'error'
  },
  {
    label: 'Collected',
    id: 'collected'
  },
  {
    label: 'Completed',
    id: 'completed'
  },
  {
    label: 'Error',
    id: 'error',
    type: 'error'
  },
  {
    label: 'Expired',
    id: 'expired',
    type: 'error'
  },
  {
    label: 'Manual',
    id: 'manual'
  },
  {
    label: 'Processing',
    id: 'processing'
  },
  {
    label: 'Received',
    id: TASK_STATUS_RECEIVED
  },
  {
    label: 'Rejected',
    id: 'rejected',
    type: 'error'
  },
  {
    label: 'Review',
    id: TASK_STATUS_REVIEW
  },
  {
    label: 'Scheduled',
    id: 'scheduled'
  },
  {
    label: 'Submitted',
    id: TASK_STATUS_SUBMITTED
  },
  {
    label: 'Tasked',
    id: 'tasked'
  }
];

export const TASK_STATUS_PROCESS_CHAIN = taskStatusProcessChainIds.map(
  (status) => {
    const available = AVAILABLE_TASK_STATUSES.find(({ id }) => id === status);
    return available;
  }
);

export const TASK_ERROR_LIST = AVAILABLE_TASK_STATUSES.filter(
  ({ type }) => type === 'error'
);

// Format task tiers in value/label format for task table filters
export const tierFilterOptions = Object.values(TaskCollectionTiers.properties).map((val, i) => {
  return ({
    value: Object.keys(TaskCollectionTiers.properties)[i],
    label: val.label
  });
});

const BASE_TABLE_COLUMNS = [
  {
    label: 'Task Name & ID',
    columnId: 'name',
    isActive: true
  },
  {
    label: 'Start Date (UTC)',
    columnId: 'unifiedStartDate',
    defaultSorted: 'desc',
    isActive: true
  },
  {
    label: 'End Date (UTC)',
    columnId: 'unifiedEndDate',
    isActive: true
  },
  {
    label: 'Status',
    columnId: 'status',
    isActive: true
  },
  {
    label: 'Collection Type',
    columnId: 'collectionType',
    isActive: true
  },
  {
    label: 'Collection Tier',
    columnId: 'collectionTier',
    isActive: true
  },
  {
    label: 'Contract',
    columnId: 'contractId',
    isActive: true
  },
  {
    label: 'Collect Mode',
    columnId: 'collectMode',
    isActive: false
  },
  {
    label: 'Submission Time',
    columnId: 'submissionTime',
    isActive: false
  },
  {
    label: 'Latest Status',
    columnId: 'latestStatus',
    isActive: false
  },
  {
    label: 'Image Length',
    columnId: 'imageLength',
    isActive: false
  },
  {
    label: 'Image Width',
    columnId: 'imageWidth',
    isActive: false
  },
  {
    label: 'Archive Holdback',
    columnId: 'archiveHoldback',
    isActive: false
  },
  {
    label: 'Description',
    columnId: 'description',
    isActive: false
  },
  {
    label: 'Custom Attribute 1',
    columnId: 'customAttribute1',
    isActive: false,
    sortableColumn: false // Sortable by default unless otherwise defined
  },
  {
    label: 'Custom Attribute 2',
    columnId: 'customAttribute2',
    isActive: false,
    sortableColumn: false
  },
  {
    label: 'Analytics',
    columnId: 'analytics',
    isActive: false,
    sortableColumn: false
  }
];

const USER_TABLE_COLUMN = {
  label: 'User',
  columnId: 'user',
  isActive: true
};
const USER_NAME_TABLE_COLUMN = {
  label: 'User',
  columnId: 'userName',
  isActive: false,
  sortableColumn: false
};
const ORG_NAME_TABLE_COLUMN = {
  label: 'Organization',
  columnId: 'organizationName',
  isActive: true,
  sortableColumn: false
};
const REPEAT_CYCLE_TABLE_COLUMN = {
  label: 'Repeat Cycle',
  columnId: 'repeatCycle'
};

export const TASKS_TABLE_COLUMNS = [...BASE_TABLE_COLUMNS];

export const REPEAT_REQUESTS_TABLE_COLUMNS = [
  ...BASE_TABLE_COLUMNS,
  REPEAT_CYCLE_TABLE_COLUMN
];

export const ORG_TASKS_TABLE_COLUMNS = [
  ...TASKS_TABLE_COLUMNS,
  USER_TABLE_COLUMN
];

export const ORG_REPEAT_REQUESTS_TABLE_COLUMNS = [
  ...REPEAT_REQUESTS_TABLE_COLUMNS,
  USER_TABLE_COLUMN
];

// use this for both resellers and subresellers
export const RESELLER_TASKS_TABLE_COLUMNS = [
  ...TASKS_TABLE_COLUMNS,
  USER_NAME_TABLE_COLUMN,
  ORG_NAME_TABLE_COLUMN
];
export const RESELLER_REPEAT_REQUESTS_TABLE_COLUMNS = [
  ...REPEAT_REQUESTS_TABLE_COLUMNS,
  USER_NAME_TABLE_COLUMN,
  ORG_NAME_TABLE_COLUMN
];

// Return array of just column id's based on isActive fields in TASKS_TABLE_COLUMNS
export const DEFAULT_TASKS_TABLE_COLUMNS = TASKS_TABLE_COLUMNS.filter(
  col => col.isActive).map(col => col.columnId);

export const DEFAULT_ORG_TASKS_TABLE_COLUMNS = [
  ...DEFAULT_TASKS_TABLE_COLUMNS,
  'user'
];

export const DATE_FILTER_OPTIONS = [
  { value: 7, label: 'Last 7 Days' },
  { value: 30, label: 'Last 30 Days' },
  { value: 90, label: 'Last 90 Days' },
  { value: 'custom', label: 'Custom Date Range' }
];

export const COMMON_TASKS_TABLE_FILTER_OPTIONS = {
  unifiedStartDate: {
    label: 'Start Date (UTC)',
    type: 'date',
    options: DATE_FILTER_OPTIONS,
    id: 'windowOpen',
    repeatId: 'repeatStart'
  },
  unifiedEndDate: {
    label: 'End Date (UTC)',
    type: 'date',
    options: DATE_FILTER_OPTIONS,
    id: 'windowClose',
    repeatId: 'repeatEnd'
  },
  status: {
    label: 'Status',
    type: 'multi',
    options: [
      { value: 'accepted', label: 'Accepted' },
      { value: 'completed', label: 'Completed' },
      { value: 'active', label: 'Active' },
      { value: 'rejected', label: 'Rejected' },
      { value: 'anomaly', label: 'Anomaly' },
      { value: 'error', label: 'Error' },
      { value: 'review', label: 'Review' },
      { value: 'received', label: 'Received' },
      { value: 'submitted', label: 'Submitted' },
      { value: 'canceled', label: 'Canceled' }
    ],
    id: 'lastStatusCode'
  },
  collectionType: {
    label: 'Collection Type',
    type: 'multi',
    options: []
  },
  collectMode: {
    label: 'Collect Mode',
    type: 'multi',
    options: AVAILABLE_COLLECT_MODES,
    id: 'type'
  },
  collectionTier: {
    label: 'Collection Tier',
    type: 'multi',
    options: tierFilterOptions
  },
  submissionTime: {
    label: 'Submission Time',
    type: 'date',
    options: DATE_FILTER_OPTIONS
  },
  latestStatus: {
    label: 'Latest Status',
    type: 'date',
    options: DATE_FILTER_OPTIONS,
    id: 'lastStatusTime'
  },
  // TODO - Remove this when we get rid of the per-org collectionType flags
  productCategory: {
    label: 'Product Category',
    type: 'multi',
    options: AVAILABLE_PRODUCT_CATEGORIES,
    id: 'taskingrequestType',
    repeatId: 'repeatrequestType'
  }
};

export const COMMON_REPEAT_REQUESTS_FILTER_OPTIONS = {
  ...COMMON_TASKS_TABLE_FILTER_OPTIONS,
  repeatCycle: {
    label: 'Repeat Cycle',
    type: 'multi-other',
    options: AVAILABLE_REPEAT_CYCLE_OPTIONS
  }
};

/**
 * taskStatusById
 * @description Given the task ID, returns the status object including it's label
 */

export function taskStatusById (taskId, defaultStatus = TASK_STATUS_UNKNOWN) {
  const status = AVAILABLE_TASK_STATUSES.find(({ id }) => id === taskId);
  return status || defaultStatus;
}

// TODO - Remove this along with FormCreateTaskStandard and FormCreateTaskRepeat
/**
 * getAvailableAnalyticsCollectModes
 * @description get collect modes supported by Analytic
 */
export function getAvailableAnalyticsCollectModes (productType) {
  // Note: this function is kept around to place potential
  // future constrained analytics in here

  // all collect modes whitelisted for VS, VC, and ACD
  return AVAILABLE_COLLECT_MODES;
}

export const AVAILABLE_COLLECT_TIMES = {
  apiMap: {
    anytime: undefined,
    day: [[21600, 64800]],
    night: [[0, 21600], [64800, 86400]]
  },
  options: [
    {
      label: 'Anytime',
      value: 'anytime',
      subLabel: 'No Preference',
      icon: <FiClock />
    },
    {
      label: 'Day',
      value: 'day',
      subLabel: '6AM - 6PM Local',
      icon: <FiSun />
    },
    {
      label: 'Night',
      value: 'night',
      subLabel: '6PM - 6AM Local',
      icon: <FiMoon />
    }
  ]
};

/**
 * getACDProductCategories
 * @description get ACD product categories by collect mode
 */
export function getACDProductCategories (collectMode) {
  if (
    ['sliding_spotlight', 'spotlight'].includes(collectMode)
  ) {
    return [
      {
        value: 'standard',
        label: 'Standard'
      }
    ];
  }

  return [
    {
      value: 'standard',
      label: 'Standard'
    },
    {
      value: 'custom',
      label: 'Custom'
    }
  ];
}

export const SHOW_TARGET_HEIGHT = [
  {
    value: 'default',
    label: 'Default'
  },
  {
    value: 'custom',
    label: 'Custom'
  }
];

const boolProperties = ['insarCompatibleRepeat', 'repeat', 'preApproval'];

/**
 * mapCreateTaskFieldsToTask
 * @description
 */

export function mapCreateTaskFieldsToTask (fields = {}, collectionTypesEnabled = false) {
  const {
    taskRequestType: { value: taskRequestTypeValue = TaskRequestTypes.STANDARD }
  } = fields;
  const isStandardTask = taskRequestTypeValue === TaskRequestTypes.STANDARD;
  const isRepeatTask = taskRequestTypeValue === TaskRequestTypes.REPEAT;
  const isOosmTask = taskRequestTypeValue === TaskRequestTypes.OOSM;

  const task = {
    type: 'Feature',
    geometry: {
      coordinates: fieldValueOrDefault(fields.geometryCoordinates),
      type: fieldValueOrDefault(fields.geometryType)
    },
    properties: {
      taskingrequestName: fieldValueOrDefault(fields.name),
      taskingrequestDescription: fieldValueOrDefault(fields.description),
      [TASKING_REQUEST_ID]: fieldValueOrDefault(fields.id),
      orgId: fieldValueOrDefault(fields.orgId),
      userId: fieldValueOrDefault(fields.userId),
      windowOpen: isRepeatTask
        ? undefined
        : fieldValueOrDefault(fields.windowOpen),
      windowClose: isRepeatTask
        ? undefined
        : fieldValueOrDefault(fields.windowClose),
      collectionTier: fieldValueOrDefault(
        fields.collectionTier,
        isRepeatTask
          ? DEFAULT_REPEAT_TASK_COLLECTION_TIER
          : DEFAULT_COLLECTION_TIER
      ),
      collectionType: collectionTypesEnabled ? fields.collectionType.value : undefined,
      analytics: fields.analytics && fields.analytics.value,
      collectConstraints: {
        localTime: fieldValueOrDefault(fields.localTime, DEFAULT_LOCAL_TIME),
        offNadirMin: fieldValueOrDefault(fields.offNadirMin, DEFAULT_OFF_NADIR_MIN),
        offNadirMax: fieldValueOrDefault(fields.offNadirMax, DEFAULT_OFF_NADIR_MAX),
        collectMode: fieldValueOrDefault(
          fields.collectMode,
          DEFAULT_COLLECT_MODE
        ),
        /* TODO: Add back once Image Length plays nicely with Sliding Spotlight

          imageLength: fieldValueOrDefault(
          fields.imageLength,
          DEFAULT_IMAGE_LENGTH
        ),
        */

        imageWidth: fields.imageWidth && fields.imageWidth.value,
        imageLength: fields.imageLength && fields.imageLength.value,
        neszMax: fieldValueOrDefault(fields.neszMax, DEFAULT_NESZ_MAX),
        polarization: fieldValueOrDefault(fields.polarization, undefined),
        mode: fieldValueOrDefault(fields.mode, DEFAULT_RANGE_MODE),
        squint: fieldValueOrDefault(fields.squint, DEFAULT_SQUINT),
        radarParametersBandwidth: fieldValueOrDefault(
          fields.radarParametersBandwidth,
          DEFAULT_RADAR_PARAMETERS_BANDWIDTH
        ),
        radarParametersCenterFrequency: fieldValueOrDefault(
          fields.radarParametersCenterFrequency,
          DEFAULT_RADAR_PARAMETERS_CENTER_FREQUENCY
        ),
        radarParametersPrf: fieldValueOrDefault(
          fields.radarParametersPrf,
          DEFAULT_RADAR_PARAMETERS_PRF
        ),
        srrMin: fieldValueOrDefault(fields.srrMin, DEFAULT_SRR_MIN),
        srrMax: fieldValueOrDefault(fields.srrMax, DEFAULT_SRR_MAX),
        azrMax: fields.azrMax?.value || null,
        azrMin: fields.azrMin?.value || null
      },
      preApproval: fieldValueOrDefault(fields.preApproval, DEFAULT_PREAPPROVAL),
      taskRequestType: fieldValueOrDefault(
        fields.taskRequestType,
        TaskRequestTypes.STANDARD
      ),
      customAttribute1: fieldValueOrDefault(fields.customAttribute1, undefined),
      customAttribute2: fieldValueOrDefault(fields.customAttribute2, undefined)
    }
  };

  // properties specific to a standard or repeat task
  if (isStandardTask || isRepeatTask) {
    task.properties = {
      ...task.properties,
      collectConstraints: {
        ...task.properties.collectConstraints,
        grrMax: fields.grrMax?.value || null,
        grrMin: fields.grrMin?.value || null,
        targetHeight: fieldValueOrDefault(fields.targetHeight),
        orbitalPlanes: fieldValueOrDefault(fields.orbitalPlanes),
        lookDirection: fieldValueOrDefault(
          fields.lookDirection,
          DEFAULT_LOOK_DIRECTION
        ),
        ascDsc: fieldValueOrDefault(fields.ascDsc, DEFAULT_ASC_DSC),
        numLooks: fieldValueOrDefault(fields.numLooks, undefined)
      },
      productCategory: fieldValueOrDefault(
        fields.productCategory,
        taskDefaults.DEFAULT_PRODUCT_CATEGORY
      ),
      archiveHoldback: fieldValueOrDefault(
        fields.archiveHoldback,
        taskDefaults.DEFAULT_ARCHIVE_HOLDBACK
      )
    };

    // properties specific to a repeat task
    if (isRepeatTask) {
      task.properties = {
        ...task.properties,
        repeatrequestName: fieldValueOrDefault(fields.name),
        repeatrequestDescription: fieldValueOrDefault(fields.description),
        repetitionProperties: {
          ...task.properties.repetitionProperties,
          repeatStart: fieldValueOrDefault(fields.repeatStart),
          repetitionEndType: fieldValueOrDefault(
            fields.repetitionEndType,
            taskDefaults.repeat.DEFAULT_REPEAT_TASK_END_TYPE_VALUE
          ),
          // we shouldn't set repeatEnd if repetitionEndType value is not DATE
          repeatEnd:
            fields.repetitionEndType?.value === RepeatRequestEndTypes.DATE
              ? fieldValueOrDefault(fields.repeatEnd)
              : undefined,
          repetitionCount:
            fields.repetitionEndType?.value === RepeatRequestEndTypes.COUNT
              ? fieldValueOrDefault(fields.repetitionCount, 2)
              : undefined,
          maintainSceneFraming: fieldValueOrDefault(
            fields.maintainSceneFraming,
            false
          ),
          repeatCycle: fieldValueOrDefault(
            fields.repeatCycle,
            taskDefaults.repeat.DEFAULT_REPEAT_CYCLE_VALUE
          ),
          repetitionInterval: fieldValueOrDefault(fields.repetitionInterval, 2),
          lookAngleTolerance: fields.lookAngleTolerance?.value
        }
      };
    }
  }

  // properties specific to an OOSM task
  if (isOosmTask) {
    task.properties = {
      ...task.properties,
      collectConstraints: {
        ...task.properties.collectConstraints,
        grrMax: undefined,
        grrMin: undefined,
        targetHeight: undefined,
        orbitalPlanes: undefined,
        lookDirection: undefined,
        ascDsc: undefined,
        numLooks: undefined
      },
      productCategory: undefined
    };
  }

  return task;
}

/**
 * mapUpdatedFieldsToTask
 * @description
 */

export function mapUpdatedFieldsToTask (fields = {}) {
  let task = {};
  const properties = {};
  let value;

  for (const key in fields) {
    if (key === 'advanced') continue;
    value = boolProperties.includes(key)
      ? checkedInputToBool(fields[key].value)
      : fields[key].value;
    properties[key] = value;
  }

  task = { properties };

  return task;
}

/**
 * buildTaskAssetUrl
 * @description Constructs a task asset URL depending on type
 */

export function buildTaskAssetUrl (assetType = 'optical', { taskId }) {
  if (assetType === 'optical') {
    return buildOpticalAssetUrl({
      id: taskId
    });
  }
}

/**
 * buildOpticalAssetUrl
 * @description Builds an optical asset URL given the ID
 */

export function buildOpticalAssetUrl ({
  id,
  bucket = 'capella-postcardsfromdenali',
  region = 'us-west-2'
} = {}) {
  const first16 = typeof id === 'string' && id.substr(0, 16);
  if (!first16) return;
  return `https://${bucket}.s3.${region}.amazonaws.com/${first16}.jpg`;
}

/**
 * Takes in filters objects from table, renames fields where necessary and converts
 * dates to format the /tasks/search endpoint requires
 * @param {object} filters = {} e.g. { collectMode: ['spotlight, 'stripmap']}
 * @param {string} [taskType='single'|'repeat'] flag for repeat requests which have different options/fields
 * @param {string} [userId=''] Optional user ID to be passed
 * @returns {object}
 */
export const taskFiltersToAPI = (filters = {}, taskType = 'single', userId) => {
  const obj = {};

  // If filters provided, map them to corresponding API values
  if (filters && Object.keys(filters).length > 0) {
    for (const key in filters) {
      switch (key) {
        case 'status': {
          obj.lastStatusCode = filters[key];
          break;
        }
        case 'collectMode': {
          obj.type = filters[key];
          break;
        }
        case 'unifiedStartDate': {
          obj[taskType === 'single'
            ? 'windowOpen'
            : 'repeatStart'
          ] = taskFilterDateHandler(filters[key]);
          break;
        }
        case 'unifiedEndDate': {
          obj[taskType === 'single'
            ? 'windowClose'
            : 'repeatEnd'
          ] = taskFilterDateHandler(filters[key]);
          break;
        }
        case 'submissionTime': {
          obj.submissionTime = taskFilterDateHandler(filters[key]);
          break;
        }
        case 'latestStatus': {
          obj.lastStatusTime = taskFilterDateHandler(filters[key]);
          break;
        }
        case 'repeatCycle': {
          const selections = filters[key];
          const repeatNumber = [];
          selections.forEach(val => {
            /**
             * Convert label value to # of days (e.g. daily > 1)
             * "Other" number strings can be passed directly
            */
            const dayNum = RepeatCycleTypes.properties[val]?.value;
            repeatNumber.push(dayNum || Number(val));
          });
          obj['repetitionProperties.repetitionInterval'] = repeatNumber;
          break;
        }
        // Use regex to detect search for ID or Task Request Name
        case 'search': {
          const query = filters[key][0];
          if (query) {
            if (isUuid(query)) {
              // Task (or Repeat Request) ID Search
              if (taskType === 'repeat') {
                obj.repeatrequestId = query;
                // ...same with task vs. repeat ID
              } else obj.taskingrequestId = query;
            } else {
              // Task (or Repeat Request) Name Search
              if (taskType === 'repeat') {
                obj.repeatrequestName = { contains: query };
              } else obj.taskName = { contains: query };
            }
          }
          break;
        }
        case 'productCategory': {
          if (taskType === 'single') {
            obj.taskingrequestType = filters[key];
          } else {
            obj.repeatrequestType = filters[key];
          }
          break;
        }
        default: obj[key] = filters[key];
      }
    }
  }

  if (userId) {
    obj.userId = userId;
  }

  // For now always exclude spawned repeat requests from /tasks/search
  if (taskType === 'single') {
    obj.includeRepeatingTasks = false;
  }

  return obj;
};

/**
 * Convert task table column field id's to more common API names (e.g. unifiedStartDate > windowOpen)
 * @param {string} tableField = ''
 * @param {string} [taskType = 'single'] task type to account for single vs. repeat task fields
 * @returns {string}
 */
export const mapTaskTableField = (tableField, taskType = 'single') => {
  if (taskType === 'repeat') {
    return COMMON_TASKS_TABLE_FILTER_OPTIONS[tableField]?.repeatId ||
      COMMON_TASKS_TABLE_FILTER_OPTIONS[tableField]?.id ||
      tableField;
  } else return COMMON_TASKS_TABLE_FILTER_OPTIONS[tableField]?.id || tableField;
};

/*
 * maps related tasks of a task to a data table, i.e. conflicting tasks
 *
 * @param {array} [tasks=[]] - array of tasks to map to dataTable rows
 * @param {object} [options={}] - optional options for task data row
 * @returns {array}
 */
export function relatedTasksToTableData (tasks = [], options = {}) {
  if (!Array.isArray(tasks) || tasks.length === 0) return [];

  const {
    handleCancel,
    detailsRouteName = ROUTE_TASKS_DETAILS,
    routeWildcards = ['id']
  } = options;

  return tasks.map((task = {}) => {
    const {
      id: childTaskId,
      collectionTier,
      name,
      status,
      statusAndId,
      windowClose,
      windowOpen
    } = task;
    const access = task.getCustomProperty('access');
    const isFetching = task.getCustomProperty('fetching');
    const taskStatus = taskStatusById(status);
    const wildcards = routeWildcards.map((wildcard) => task[wildcard]);

    const taskDetailsRoute = routePathByName(detailsRouteName, {
      wildcard: wildcards
    });
    // default action to navigate to task details page
    const taskActions = [
      {
        to: taskDetailsRoute,
        label: 'View Details',
        buttonType: ['text-positive']
      }
    ];

    // if handleCancel is defined as a function, add it to actions
    if (typeof handleCancel === 'function') {
      taskActions.push({
        to: () => {
          handleCancel();
        },
        label: 'Cancel Task',
        buttonType: ['text-negative']
      });
    }

    const nameText = name || `Task ${childTaskId}`;

    return {
      access,
      isFetching,
      name: (
        <p>
          <WonderLink to={taskDetailsRoute}>
            {nameText}
          </WonderLink>
          <br />
          <small>ID: {childTaskId}</small>
        </p>
      ),
      nameText,
      idText: childTaskId,
      href: taskDetailsRoute,
      collectionTier,
      windowClose: formatDateTime(windowClose),
      windowOpen: formatDateTime(windowOpen),
      status: taskStatus.label,
      actions: taskActions,
      statusAndId
    };
  });
}

export function overwriteMerge (destinationArray, sourceArray, options) {
  return sourceArray;
}

/**
 * updateTaskData
 * @description
 */

export function updateTaskData (task = {}, data) {
  // Overwrite the arrays instead of default, which concats the values
  return deepmerge(task, data || {}, {
    arrayMerge: overwriteMerge
  });
}

/**
 * updateTasksData
 * @description
 */

export function updateTasksData (currentTasks = [], updatedTasks = []) {
  const newTasks = clone(currentTasks);

  updatedTasks.forEach((task = {}) => {
    const { properties = {} } = task;
    const taskingrequestId = properties[TASKING_REQUEST_ID];

    const index = newTasks.findIndex(
      ({ properties: newTaskProperties = {} }) => {
        return newTaskProperties[TASKING_REQUEST_ID] === taskingrequestId;
      }
    );

    // If we found an existing task, update it with new data
    if (index !== -1) {
      newTasks[index] = updateTaskData(newTasks[index], task);
    } else {
      newTasks.push(task);
    }
  });

  return newTasks;
}

/**
 * gets min and max look values
 * @param {number} [min=DEFAULT_OFF_NADIR_MIN] - min value
 * @param {number} [max=DEFAULT_OFF_NADIR_MAX] - max value
 * @param {number} [offset=0] - an offset number to use for a corresponding range of values
 * @returns {object} - min and max properties
 */
export const lookAngleValue = ({
  min = DEFAULT_OFF_NADIR_MIN,
  max = DEFAULT_OFF_NADIR_MAX,
  offset = 0
} = {}) => {
  let defaultMin = DEFAULT_OOSM_LOOK_ANGLE_MIN;
  let defaultMax = 50;

  const defaultOosmLookAngleRange = {
    min: DEFAULT_OOSM_LOOK_ANGLE_MIN,
    max: DEFAULT_OOSM_LOOK_ANGLE_MAX
  };

  // we have to check to ensure that the offNadirMin and offNadirMax values fall within the required range of the OOSM look range field
  // because the look range defaults are outside of this newly defined range as they were made before these OOSM requirements
  if (
    between({
      ...defaultOosmLookAngleRange,
      numInput: min
    })
  ) {
    defaultMin = min;
  }

  if (
    between({
      ...defaultOosmLookAngleRange,
      numInput: max
    })
  ) {
    defaultMax = max;
  }

  if (offset > 0) {
    return {
      min: offset - defaultMax,
      max: offset - defaultMin
    };
  }

  return {
    min: defaultMin,
    max: defaultMax
  };
};

/**
 * identifies geoJson object as a point geometry type or not
 * @param {object} [geoJson={}] - geoJson object to inspect for geometry type
 * @returns {boolean}
 */
export const isPointGeometryType = ({ geoJson: { features = [] } = {} }) =>
  features.map(({ geometry } = {}) => geometry.type).includes('Point');

/**
 * configurable range and values for slider inputs
 * @param {number} [defaultValueMin=25] - minimum slider input value
 * @param {number} [defaultValueMax=40] - maximum slider input value
 * @param {number} [defaultRangeMin=5] - minimum value allowed
 * @param {number} [defaultRangeMax=40] - maximum value allowed
 * @param {object|number} [valueSideEffects=null] - overrides slider default values, e.g. { min: 25, max: 40 } for range sliders, or just a number for single value sliders
 * @param {function|null} [rangeSideEffects=null] - overrides slider allowed range, initRange is the input, and returns mutated range based on outside conditions, e.g (inputRange) => { ...conditions ... return mutatedRange }
 * @returns {any}
 */
export const sliderValuesAndRange = ({
  defaultValueMin = 25,
  defaultValueMax = 40,
  defaultRangeMin = 5,
  defaultRangeMax = 40,
  valueSideEffects = null,
  rangeSideEffects = null
}) => {
  const initRange = {
    minValue: defaultRangeMin,
    maxValue: defaultRangeMax
  };
  let sliderRange = initRange;

  // range side effects will override range defaults
  if (typeof rangeSideEffects === 'function') {
    sliderRange = rangeSideEffects(initRange);
  }

  // value side effects will override value defaults
  return {
    value: valueSideEffects || {
      min: defaultValueMin,
      max: defaultValueMax
    },
    ...sliderRange
  };
};

/**
 * initial window open and close dates
 */
export const defaultWindowDates = ({
  taskingTier = taskDefaults.DEFAULT_TASKING_TIER
}) => {
  const currentDate = new Date();
  const futureDate = new Date().setUTCDate(
    currentDate.getUTCDate() + taskingTier
  );

  return {
    windowOpen: currentDate,
    windowClose: futureDate
  };
};

/**
 * sets task defaults in task object format
 *
 * @param {object} [taskEntry={}]
 * @param {bool} [taskEntry.isOosm=false] if true, sets oosm defaults over standard task defaults
 * @param {bool} [taskEntry.isRepeat=false] if true, sets repeat request defaults over standard task defaults
 * @param {bool} [taskEntry.isPoint] if true or not defined (falsy), default collect mode, otherwise alternate default collect mode
 * @param {bool} [taskEntry.persistSharedValues=false] If false reset all fields, if true everything but shared ones like name & description
 * @returns {object} returns formatted task object with default values
 */
export const initDefaultTaskValues = ({
  isOosm = false,
  isRepeat = false,
  isPoint,
  persistSharedValues = false
} = {}) => {
  const { windowOpen, windowClose } = defaultWindowDates({
    taskingTier: isPoint
      ? isRepeat
        ? taskDefaults.repeat.DEFAULT_REPEAT_TASK_TASKING_TIER
        : taskDefaults.DEFAULT_TASKING_TIER
      : taskDefaults.area.DEFAULT_AREA_TASK_TASKING_TIER
  });

  const { min: oosmMinLookAngle, max: oosmMaxLookAngle } = lookAngleValue();

  const taskDefaultsObject = {
    collectMode: isPoint === false
      ? taskDefaults.DEFAULT_COLLECT_MODE_AREA
      : taskDefaults.DEFAULT_COLLECT_MODE,
    collectionTier: isRepeat
      ? taskDefaults.repeat.DEFAULT_REPEAT_TASK_COLLECTION_TIER
      : taskDefaults.DEFAULT_COLLECTION_TIER,
    windowOpenInt: windowOpen.getTime(),
    windowCloseInt: windowClose,
    preApproval: false,
    orbitalPlanes: [],
    ascDsc: '',
    lookDirection: '',
    targetHeight: null,
    productCategory: taskDefaults.DEFAULT_PRODUCT_CATEGORY,
    archiveHoldback: undefined, // despite having a default value for this we leave it undefined to avoid clashes with the user's organization/contract-level value

    // ui mutable properties that shouldn't bubble to the api request
    isNotSpotlightMode: false,
    taskingTier: isRepeat
      ? taskDefaults.repeat.DEFAULT_REPEAT_TASK_TASKING_TIER
      : taskDefaults.DEFAULT_TASKING_TIER,
    shouldRefreshAccessTimes: false,
    showCategoryOptions: false,

    collectConstraints: {
      azrMin: isOosm ? DEFAULT_OOSM_AZR_MIN : taskDefaults.DEFAULT_AZR_MIN,
      azrMax: isOosm ? DEFAULT_OOSM_AZR_MAX : taskDefaults.DEFAULT_AZR_MAX,
      grrMin: taskDefaults.DEFAULT_GRR_MIN,
      grrMax: taskDefaults.DEFAULT_GRR_MAX,
      offNadirMin: isOosm
        ? oosmMinLookAngle
        : taskDefaults.DEFAULT_OFF_NADIR_MIN,
      offNadirMax: isOosm
        ? oosmMaxLookAngle
        : taskDefaults.DEFAULT_OFF_NADIR_MAX,
      imageWidth: isPoint ? taskDefaults.DEFAULT_IMAGE_WIDTH : taskDefaults.DEFAULT_AREA_TASK_IMAGE_WIDTH,
      imageLength: isOosm
        ? taskDefaults.oosm.DEFAULT_OOSM_IMAGE_LENGTH
        : taskDefaults.DEFAULT_IMAGE_LENGTH,
      numLooks: taskDefaults.DEFAULT_NUM_LOOKS_SPOTLIGHT,
      polarization: isOosm ? 'HH' : undefined,
      mode: isOosm ? taskDefaults.DEFAULT_RANGE_MODE : null,
      targetHeight: null
    },

    repetitionProperties: isRepeat
      ? {
        maintainSceneFraming: false,
        repeatCycle: DEFAULT_REPEAT_CYCLE_VALUE,
        repetitionInterval: 2,
        repeatEnd: null,
        repeatStart: windowOpen.getTime(),
        repetitionCount: null,
        repetitionEndType: DEFAULT_REPEAT_TASK_END_TYPE_VALUE
      }
      : undefined
  };

  if (!persistSharedValues) {
    // Reset ALL task default values including Name & Description
    taskDefaultsObject.name = '';
    taskDefaultsObject.description = '';
  }

  return taskDefaultsObject;
};

/**
 * determines if a task can be cancelled or not based on it's given status
 * @param {string} [taskStatus=''] - current status of a task
 * @returns {bool}
 */
export const taskCanBeCancelled = (taskStatus = '') => {
  let canCancel = true;

  if (
    taskStatus === 'rejected' ||
    taskStatus === 'expired' ||
    taskStatus === 'canceled' ||
    taskStatus === 'cancelled' ||
    taskStatus === 'error' ||
    taskStatus === 'anomaly' ||
    taskStatus === 'collection_anomaly' ||
    taskStatus === 'processing_anomaly' ||
    taskStatus === 'completed' ||
    taskStatus === ''
  ) {
    canCancel = false;
  }

  return canCancel;
};

// all task default values lumped into a single object variable
export const taskDefaults = {
  DEFAULT_LOOK_DIRECTION,
  DEFAULT_ASC_DSC,
  DEFAULT_LOCAL_TIME,
  DEFAULT_OFF_NADIR_MIN,
  DEFAULT_OFF_NADIR_MAX,
  DEFAULT_COLLECT_MODE,
  DEFAULT_COLLECT_MODE_AREA,
  DEFAULT_IMAGE_WIDTH,
  DEFAULT_IMAGE_LENGTH,
  DEFAULT_GRR_MIN,
  DEFAULT_GRR_MAX,
  DEFAULT_AZR_MIN,
  DEFAULT_AZR_MAX,
  DEFAULT_NESZ_MAX,
  DEFAULT_NUM_LOOKS,
  DEFAULT_NUM_LOOKS_SPOTLIGHT,
  DEFAULT_COLLECTION_TIER,
  DEFAULT_PREAPPROVAL,
  DEFAULT_PRODUCT_CATEGORY,
  DEFAULT_ARCHIVE_HOLDBACK,
  DEFAULT_REPEAT,
  DEFAULT_RANGE_MODE,
  DEFAULT_ELEVATION_MIN,
  DEFAULT_ELEVATION_MAX,
  DEFAULT_SQUINT,
  DEFAULT_RADAR_PARAMETERS_BANDWIDTH,
  DEFAULT_RADAR_PARAMETERS_CENTER_FREQUENCY,
  DEFAULT_RADAR_PARAMETERS_PRF,
  DEFAULT_SRR_MIN,
  DEFAULT_SRR_MAX,
  TASK_ENVIRONMENTS,
  DEFAULT_TASKS_TABLE_COLUMNS,
  TASK_STATUS_UNKNOWN,
  AVAILABLE_TASK_STATUSES,
  TASK_STATUS_PROCESS_CHAIN,
  TASK_ERROR_LIST,
  AVAILABLE_COLLECT_MODES,
  AVAILABLE_COLLECT_TIMES,
  AVAILABLE_COLLECTION_TIERS,
  AVAILABLE_ORBITAL_PLANES,
  AVAILABLE_ORBITAL_PLANES_NO_97,
  AVAILABLE_ASC_DSC,
  AVAILABLE_LOOK_DIRECTIONS,
  AVAILABLE_POLARIZATION,
  AVAILABLE_PRODUCT_CATEGORIES,
  ARCHIVE_HOLDBACK_VALUES,
  SHOW_TARGET_HEIGHT,
  DEFAULT_TASKING_TIER,
  WINDOW_OPEN_BUFFER,
  DEFAULT_AZR_MIN_RANGE,
  DEFAULT_AZR_MAX_RANGE,
  DEFAULT_GRR_MIN_RANGE,
  DEFAULT_GRR_MAX_RANGE,
  DEFAULT_NUM_LOOKS_MIN_RANGE,

  oosm: {
    DEFAULT_OOSM_LOOK_ANGLE_MIN,
    DEFAULT_OOSM_LOOK_ANGLE_MAX,
    DEFAULT_ANGLE_CORRESPONDENCE,
    DEFAULT_OOSM_IMAGE_LENGTH,
    DEFAULT_OOSM_COLLECT_MODE,
    DEFAULT_OOSM_COLLECTION_TIER,
    DEFAULT_OOSM_WINDOW_CLOSE_PERIOD,
    AVAILABLE_OOSM_IMAGE_LENGTHS,
    DEFAULT_OOSM_AZR_MIN,
    DEFAULT_OOSM_AZR_MAX
  },

  repeat: {
    AVAILABLE_REPEAT_TASK_COLLECTION_TIERS,
    DEFAULT_REPEAT_TASK_COLLECTION_TIER,
    AVAILABLE_REPEAT_TASK_END_OPTIONS,
    DEFAULT_REPEAT_TASK_END_TYPE_VALUE,
    DEFAULT_REPEAT_TASK_TASKING_TIER,
    DEFAULT_REPEAT_CYCLE_VALUE,
    AVAILABLE_REPEAT_CYCLE_OPTIONS,
    ACD_REPEAT_CYCLE_OPTIONS
  },

  area: {
    DEFAULT_AREA_TASK_IMAGE_WIDTH,
    DEFAULT_AREA_TASK_IMAGE_LENGTH,
    DEFAULT_AREA_TASK_GRR_MIN,
    DEFAULT_AREA_TASK_GRR_MAX,
    DEFAULT_AREA_TASK_AZR_MIN,
    DEFAULT_AREA_TASK_AZR_MAX,
    DEFAULT_AREA_TASK_TASKING_TIER
  }
};

// Map a collection's status history and return object based on the status code
export const returnStatusByCode = (statusHistory = [], code) => {
  if (!statusHistory?.length || !code) return;
  return statusHistory.find((status) => status.code === code);
};
