import appPackage from '../../../package';

const appState = {
  version: appPackage.version
};

const app = () => {
  return appState;
};

export default app;
