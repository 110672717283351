import React from 'react';
import PropTypes from 'prop-types';

import ClassName from '../models/classname';
import ContractPeriod from '../models/contract-period';

import ContractPeriodCard from './ContractPeriodCard';

const ContractPeriodCards = ({
  isLoading,
  contractPeriods = [],
  organizationId,
  canEdit = false,
  isArchiveSub = false,
  isReseller = false,
  isSubreseller = false,
  hideOrderPricing = false
}) => {
  const componentClass = new ClassName('contract-period-cards');

  const hasContractPeriods =
    Array.isArray(contractPeriods) && contractPeriods.length > 0;

  let periods = [];

  if (hasContractPeriods) {
    const passed = contractPeriods.filter((period) => period.isPast);
    const active = contractPeriods.filter((period) => period.isActive);
    const future = contractPeriods.filter((period) => period.isFuture);
    periods = [...active, ...future, ...passed];
  }

  if (isLoading) {
    periods = [...new Array(3)].map(() => new ContractPeriod());
  }

  return (
    <div className={componentClass.string}>
      {periods.length > 0 && (
        <ul>
          {periods.map((contractPeriod, key) => {
            return (
              <li key={`ContractPeriod-${key}`}>
                <ContractPeriodCard
                  isLoading={isLoading}
                  contractPeriod={contractPeriod}
                  organizationId={organizationId}
                  canEdit={canEdit}
                  isArchiveSub={isArchiveSub}
                  isReseller={isReseller}
                  isSubreseller={isSubreseller}
                  hideOrderPricing={hideOrderPricing}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

ContractPeriodCards.propTypes = {
  isLoading: PropTypes.bool,
  contractPeriods: PropTypes.array,
  organizationId: PropTypes.string,
  canEdit: PropTypes.bool,
  isArchiveSub: PropTypes.bool,
  isReseller: PropTypes.bool,
  isSubreseller: PropTypes.bool,
  hideOrderPricing: PropTypes.bool
};

export default ContractPeriodCards;
