/**
 * Function for shaping column definitions with transformers
 *
 * @example
 * const definedCols = [
 *   { columnId: 'col1' },
 *   { columnId: 'col2' }
 * ];
 * const transformers = {
 *   col1: {
 *     cellTransformer: (value) => value
 *   }
 * };
 * const combined = combineColumnsWithTransformers(definedCols, transformers);
 * // => [{ columnId: 'col1', cellTransformer: (value) => value }, { columnId: 'col2' }]
 *
 * @param {object[]} defaultColumns
 * @param {object} transformers
 * @returns {object[]} definedColumns combined with transformers
 */
export function combineColumnsWithTransformers (
  definedColumns = [],
  transformers = {}
) {
  const cols = definedColumns.map((col) => {
    const { columnId } = col;
    const cellTransformers = Object.fromEntries(
      Object.entries(transformers).filter(([key]) => key === columnId)
    )[columnId];
    if (cellTransformers) {
      return {
        ...col,
        ...cellTransformers
      };
    }
    return col;
  });
  return cols;
}
