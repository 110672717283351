import {
  MAP_COLOR_YELLOW,
  MAP_COLOR_ORANGE,
  MAP_COLOR_MAGENTA,
  MAP_COLOR_GREEN,
  MAP_COLOR_RED,
  MAP_COLOR_LIGHTBLUE,
  MAP_COLOR_PURPLE
} from './colors';

export const TASK_STATUS_ACCEPTED = 'accepted';
export const TASK_STATUS_ACTIVE = 'active';
export const TASK_STATUS_COMPLETE = 'completed';
export const TASK_STATUS_RECEIVED = 'received';
export const TASK_STATUS_REJECTED = 'rejected';
export const TASK_STATUS_REVIEW = 'review';
export const TASK_STATUS_SUBMITTED = 'submitted';

/**
 * Task Transaction status
 * The transaction status is different from the top level status, which refers to the
 * CAPI status. Transaction on the otherhand defines where the task is at in relation
 * to the financial transaction and ordering of the task
 * Avaiable statuses:
 * - COMPLETED: no transaction modifications possible. Perhaps the "download order"?
 * - PENDING_COST_CALCULATION: we're waiting for CAPI to estimate size
 * - PENDING_USER_APPROVAL: only status where you should show "approve and reject"
 * - IN_PROGRESS: this is where you would show cancellation options
 * - FAILED: The task failed at some point after it was in-progress and determined feasible.
 * - DENIED: CAPI could not accommodate the request. We have refunded any reserved funds.
 * - REJECTED: 'customer-rejected'
 */

export const TASK_TRANSACTION_STATUS_COMPLETED = 'completed';
export const TASK_TRANSACTION_STATUS_PENDING_COST_CALCULATION =
  'pending-cost-calculation';
export const TASK_TRANSACTION_STATUS_PENDING_USER_APPROVAL =
  'pending-customer-approval';
export const TASK_TRANSACTION_STATUS_IN_PROGRESS = 'in-progress';
export const TASK_TRANSACTION_STATUS_FAILED = 'failed';
export const TASK_TRANSACTION_STATUS_DENIED = 'denied';
export const TASK_TRANSACTION_STATUS_REJECTED = 'customer-rejected';
export const TASK_TRANSACTION_STATUS_SERVICE_REJECTED = 'service-rejected';
export const TASK_TRANSACTION_STATUS_CUSTOMER_CANCELLED = 'customer-cancelled';

export const TASK_TRANSACTION_STATUSES = [
  TASK_TRANSACTION_STATUS_COMPLETED,
  TASK_TRANSACTION_STATUS_PENDING_COST_CALCULATION,
  TASK_TRANSACTION_STATUS_PENDING_USER_APPROVAL,
  TASK_TRANSACTION_STATUS_IN_PROGRESS,
  TASK_TRANSACTION_STATUS_FAILED,
  TASK_TRANSACTION_STATUS_DENIED,
  TASK_TRANSACTION_STATUS_REJECTED,
  TASK_TRANSACTION_STATUS_CUSTOMER_CANCELLED
];

/**
 * TASK COLLECTS
 */

export const TASK_COLLECT_STATUS_UNKNOWN = 'unknown';
export const TASK_COLLECT_STATUS_PREDICTED = 'predicted';
export const TASK_COLLECT_STATUS_TASKED = 'tasked';
export const TASK_COLLECT_STATUS_COLLECTED = 'collected';
export const TASK_COLLECT_STATUS_PROCESSING = 'processing';
export const TASK_COLLECT_STATUS_QA = 'qa';
export const TASK_COLLECT_STATUS_DELIVERED = 'delivered';
export const TASK_COLLECT_STATUS_ANOMALY = 'anomaly';
export const TASK_COLLECT_STATUS_COLLECTION_ANOMALY = 'collection_anomaly';
export const TASK_COLLECT_STATUS_PROCESSING_ANOMALY = 'processing_anomaly';

export const TASK_COLLECT_STATUSES = [
  {
    id: TASK_COLLECT_STATUS_UNKNOWN,
    label: 'Unknown'
  },
  {
    id: TASK_COLLECT_STATUS_PREDICTED,
    label: 'Predicted',
    color: MAP_COLOR_LIGHTBLUE
  },
  {
    id: TASK_COLLECT_STATUS_TASKED,
    label: 'Tasked',
    color: MAP_COLOR_PURPLE
  },
  {
    id: TASK_COLLECT_STATUS_COLLECTED,
    label: 'Collected',
    color: MAP_COLOR_MAGENTA
  },
  {
    id: TASK_COLLECT_STATUS_PROCESSING,
    label: 'Processing',
    color: MAP_COLOR_ORANGE
  },
  {
    id: TASK_COLLECT_STATUS_QA,
    label: 'QA',
    color: MAP_COLOR_YELLOW
  },
  {
    id: TASK_COLLECT_STATUS_DELIVERED,
    label: 'Delivered',
    color: MAP_COLOR_GREEN
  },
  {
    id: TASK_COLLECT_STATUS_ANOMALY,
    label: 'Anomaly',
    color: MAP_COLOR_RED
  },
  {
    id: TASK_COLLECT_STATUS_COLLECTION_ANOMALY,
    label: 'Collect Anomaly',
    color: MAP_COLOR_RED
  },
  {
    id: TASK_COLLECT_STATUS_PROCESSING_ANOMALY,
    label: 'Processing Anomaly',
    color: MAP_COLOR_RED
  }
];

/**
 * Possible Orbital Planes
 *
 * @type {{value: '45'|'53'|'97', label: 'Mid-latitude: 45°'|'Mid-latitude: 53°'|'SSO: 97°'}[]}
 * @readonly
 */
export const AVAILABLE_ORBITAL_PLANES = /** @type const */ ([
  {
    value: 45,
    label: 'Mid-latitude: 45°'
  },
  {
    value: 53,
    label: 'Mid-latitude: 53°'
  },
  {
    value: 97,
    label: 'SSO: 97°'
  }
]);

export const AVAILABLE_ORBITAL_PLANES_NO_97 = AVAILABLE_ORBITAL_PLANES.filter(({ value }) => value !== 97);

/**
 * Tasking Request Table
 */

export const DEFAULT_PAGING_LIMIT = 250;
