import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAdmin, useAdminOrganization } from 'hooks';

import { errorMessageByCode } from 'lib/errors';
import { logError } from 'lib/logger';
import { fetchOrganizationById, triggerNotice } from 'state/actions';

const errorBase = 'Failed to fetch organization';

export default function useAdminFetchOrganization ({ id } = {}) {
  const dispatch = useDispatch();

  const { actions = {} } = useAdmin();
  const organization = useAdminOrganization({ id });

  if (typeof id !== 'string') {
    throw new Error(`${errorBase}: Invalid ID ${id}`);
  }

  /**
   * fetchOrganization
   */

  function fetchOrganization (organizationId) {
    dispatch(fetchOrganizationById(organizationId, {
      eagerLedgerIncludes: true
    })).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchOrganization = useCallback(() => {
    fetchOrganization(id);
  }, [id]);

  useEffect(() => {
    memoizedFetchOrganization();
  }, [memoizedFetchOrganization]);

  return {
    fetchOrganization: memoizedFetchOrganization,
    ...actions.fetchOrganizationById,
    organization
  };
}
