import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useTask, useTasks } from 'hooks';

import { logError } from 'lib/logger';
import { errorMessageByCode } from 'lib/errors';
import { triggerNotice, fetchTasksTilesById } from 'state/actions';

import {
  TASK_STATUS_ACCEPTED,
  TASK_STATUS_ACTIVE,
  TASK_STATUS_COMPLETE
} from 'data/tasks';

const VALID_TASK_STATUSES = [
  TASK_STATUS_ACCEPTED,
  TASK_STATUS_ACTIVE,
  TASK_STATUS_COMPLETE
];

export default function useFetchTaskTiles ({ taskId } = {}) {
  const dispatch = useDispatch();
  const { actions } = useTasks();
  const task = useTask(taskId);

  const shouldFetch = VALID_TASK_STATUSES.includes(task.status);

  /**
   * _fetchTiles
   */

  function _fetchTiles (id) {
    dispatch(fetchTasksTilesById(id)).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchTiles = useCallback(() => {
    _fetchTiles(taskId);
  }, [taskId]); // eslint-disable-line

  useEffect(() => {
    if (!shouldFetch) return;
    memoizedFetchTiles();
  }, [memoizedFetchTiles, shouldFetch]);

  return {
    fetchTasksTilesById: memoizedFetchTiles,
    ...actions.fetchTasksTilesById
  };
}
