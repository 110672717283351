import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, FormInput, FormRow, Notice, InputButton, Badge } from 'fogg/ui';
import { Button, ModalToggleButton, Modal, ModalFooter } from '@trussworks/react-uswds';
import { FaExclamationTriangle, FaQuestion } from 'react-icons/fa';

import { routePathByName, navigateTo } from 'lib/routes';
import { ROUTE_AOIS } from 'data/route-names';
import {
  CREATE_AOI_EXCLUDE_OLDER_THAN_VALUES,
  AOI_SHAPE_RANGE
} from 'data/aois';
import Tooltip from './Tooltip';

/**
 * Form section for the Create New AOI page
 * @param {function} onSubmit - handler for submitting the new task
 * @param {function} onDelete - handler to delete the AOI
 * @param {object} [aoi={}] - current aoi properties (and state), starts as defaults
 * @param {boolean} [isLoading=false]
 * @param {boolean} [isEdit=false] - Form is in Edit AOI mode (vs. Create AOI mode)
 */
const FormCreateEditAoi = ({ onSubmit, onDelete, aoi = {}, isLoading = false, isEdit = false }) => {
  const { properties = {}, geometry, isLargeAOISize = false } = aoi;
  const {
    name,
    description,
    active,
    excludeTaskedByOwnOrganization,
    excludeOlderThanEnabled = false,
    excludeOlderThanSetting = ''
  } = properties;

  const modalRef = useRef();
  const [receiveNotifications, setReceiveNotifications] = useState(active);
  const [excludeOrgImagery, setReceiveOrgImagery] = useState(excludeTaskedByOwnOrganization);
  const [excludeImageryDate, setExcludeImageryDate] = useState(getExcludeDropdownValue(''));

  const [showGeometryNotice, setShowGeometryNotice] = useState(false);
  const [showLargeAOISizeNotice, setShowLargeAOISizeNotice] = useState(false);
  function handleReceiveNotifications ({ currentTarget = {} }) {
    const { checked } = currentTarget;
    setReceiveNotifications(checked);
  }

  function handleExcludeOrgImagery ({ currentTarget = {} }) {
    const { checked } = currentTarget;
    setReceiveOrgImagery(checked);
  }

  /**
   * handleSubmit
   */

  function handleSubmit (e, fields = {}) {
    // Convert various form fields to payload object
    const payload = {
      type: 'Feature',
      geometry,
      properties: {
        name: fields.name.value,
        description: fields.description.value,
        // React-select doesn't like an undefined default value, so convert '' to undefined
        excludeOlderThanSetting: excludeImageryDate.value !== ''
          ? excludeImageryDate.value
          : undefined,
        excludeOlderThanEnabled: excludeImageryDate.value !== '',
        active: receiveNotifications,
        excludeTaskedByOwnOrganization: excludeOrgImagery
      }
    };

    if (typeof onSubmit === 'function') {
      if (geometry && geometry.type) {
        onSubmit(payload);
      } else {
        // No AOI Geometry found so tell the user to draw one
        setShowGeometryNotice(true);
      }
    }
  }

  function handleCancel (e) {
    if (e) e.preventDefault();
    navigateTo(routePathByName(ROUTE_AOIS));
  }

  async function handleDelete (e) {
    if (e) e.preventDefault();
    onDelete();
  }
  useEffect(() => {
    // Clear notice if AOI geometry is now present
    if (geometry && geometry.type) {
      setShowGeometryNotice(false);
    }
  }, [geometry]);
  useEffect(() => {
    setShowLargeAOISizeNotice(isLargeAOISize);
  }, [isLargeAOISize]);

  useEffect(() => {
    setReceiveNotifications(active);
    setReceiveOrgImagery(excludeTaskedByOwnOrganization);
  }, [active, excludeTaskedByOwnOrganization]);

  useEffect(() => {
    setExcludeImageryDate(
      excludeOlderThanEnabled
        ? getExcludeDropdownValue(excludeOlderThanSetting)
        : getExcludeDropdownValue('')
    );
  }, [excludeOlderThanEnabled, excludeOlderThanSetting]);

  function getExcludeDropdownValue (selection) {
    return CREATE_AOI_EXCLUDE_OLDER_THAN_VALUES.find(val => val.value === selection);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormRow>
        <FormInput
          id="name"
          label={<strong>Name</strong>}
          className="form-input-required"
          type="text"
          required={true}
          defaultValue={name}
          validationMessage='A name is required to create an AOI'
        />
      </FormRow>
      <FormRow>
        <FormInput
          id="description"
          label="Description"
          type="text"
          required={false}
          defaultValue={description}
        />
      </FormRow>

      <h4 className="notifications-header">
        Notifications:
        <Tooltip
          className="aoi-notifications-tooltip"
          id="aoi-notifications-tip"
          icon={<FaQuestion />}
          tooltipText={
            <div>
              <p>When active, you will receive an email every time new imagery intersects your AOI.</p>
              <p>To avoid receiving too many AOI email notifications, reduce your AOI size.</p>
            </div>
          }
        />
      </h4>
      <FormRow>
        <InputButton
          id="receiveNotifications"
          label={
            <span className="notifications-active-label">
              Receive email notifications
              {receiveNotifications
                ? <Badge type="badge-solid success" label="ACTIVE" />
                : <Badge type="badge-solid" label="INACTIVE" />
              }
            </span>
          }
          type="checkbox"
          controlChecked={true}
          isChecked={receiveNotifications}
          disabled={isLoading}
          onChange={handleReceiveNotifications}
        />
      </FormRow>

      <FormRow>
        <InputButton
          id="excludeOrgImagery"
          label="Exclude imagery tasked by my organization"
          type="checkbox"
          controlChecked={true}
          isChecked={excludeOrgImagery}
          disabled={isLoading}
          onChange={handleExcludeOrgImagery}
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="excludeOlderThanSetting"
          label={<strong>Imagery to include</strong>}
          type="select"
          options={CREATE_AOI_EXCLUDE_OLDER_THAN_VALUES}
          required={false}
          clearable={false}
          disabled={isLoading}
          onChange={(sel) => setExcludeImageryDate(sel)}
          value={excludeImageryDate}
        />
      </FormRow>
      {showGeometryNotice && (
        <Notice type="error small" className="icon-before align-center">
          <FaExclamationTriangle /> Draw or upload your AOI geometry on the map
        </Notice>
      )}
      {
        showLargeAOISizeNotice && (
          <Notice type="warning small" className="icon-before align-center">
            <FaExclamationTriangle size={50} />
            <span>
              Warning: AOI size is greater than {AOI_SHAPE_RANGE.max.friendlyLabel}.{' '}
              You may receive an excessive number of email notifications for large AOIs.{' '}
              Consider reducing your AOI size or click <i><b>{isEdit ? 'Update AOI' : 'Create AOI'}</b></i> to continue.
            </span>
          </Notice>
        )
      }

      <FormRow className="create-aoi-buttons">
        <Button size="big" className="primary" disabled={isLoading}>
          {isEdit ? 'Update AOI' : 'Create AOI'}
        </Button>
        <Button
          size="big"
          onClick={handleCancel}
          unstyled={true}
        >
          Cancel
        </Button>
      </FormRow>
      {isEdit && (
        <FormRow className="delete-aoi-button">
          <ModalToggleButton size="big" unstyled={true} className="danger" modalRef={modalRef} opener>
          Delete AOI
          </ModalToggleButton>
        </FormRow>
      )}

      <Modal
        ref={modalRef}
        id="delete-modal"
        aria-labelledby="delete-modal-heading"
      >
        <h3 id="delete-modal-heading" className="flat-all trim-all">
          Are you sure you want to delete the AOI?
        </h3>
        <ModalFooter>
          <ModalToggleButton size="big" className="primary" onClick={handleDelete} modalRef={modalRef} closer>
            Yes, Delete AOI
          </ModalToggleButton>
          <ModalToggleButton
            modalRef={modalRef}
            closer
            unstyled
            size="big"
            className="padding-105 text-center">
            No, Nevermind!
          </ModalToggleButton>
        </ModalFooter>
      </Modal>
    </Form>
  );
};

FormCreateEditAoi.propTypes = {
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  aoi: PropTypes.object,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool
};

export default FormCreateEditAoi;
