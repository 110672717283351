import React from 'react';
import PropTypes from 'prop-types';
import { WonderLink } from 'fogg/ui';
import { FaPen } from 'react-icons/fa';

import ClassName from '../models/classname';
import { routePathByName } from '../lib/routes';

const ContractPeriodCard = ({
  isLoading,
  contractPeriod = {},
  organizationId,
  canEdit,
  isArchiveSub = false,
  isReseller = false,
  isSubreseller = false,
  hideOrderPricing = false
}) => {
  const componentClass = new ClassName('contract-period-card');
  const sectionClass = new ClassName(componentClass.childString('section'));

  let routeToEdit;
  if (isReseller) {
    routeToEdit = 'resellerOrganizationsContractPeriodEdit';
  } else if (isSubreseller) {
    routeToEdit = 'subresellerOrganizationsContractPeriodEdit';
  } else {
    routeToEdit = 'adminOrganizationsContractPeriodEdit';
  }

  const {
    valueCommitmentFormatted,
    availableCommitmentFormatted,
    reserveFormatted,
    usageFormatted,
    resellerDiscountFormatted,
    id,
    startDateFormatted,
    endDateFormatted,
    createdAtFormatted,
    updatedAtFormatted,
    state = {},
    subscriptionQuota,
    subscriptionUsage,
    availableSubscriptionUnits
  } = contractPeriod;

  const { label: stateLabel, name: stateName } = state;

  const periodStateClassName = `${componentClass.childString(
    'state'
  )} ${componentClass.childString(`state-${stateName}`)}`;

  componentClass.add(componentClass.childString(stateName));
  componentClass.addIf(componentClass.childString('loading'), isLoading);

  return (
    <div className={componentClass.string}>
      <div className={componentClass.childString('header')}>
        <h3>
          {startDateFormatted} - {endDateFormatted}
        </h3>
        <ul className={componentClass.childString('header-actions')}>
          {canEdit && (
            <li>
              <WonderLink
                to={routePathByName(routeToEdit, {
                  wildcard: [organizationId, id]
                })}
              >
                <FaPen />
                Edit
              </WonderLink>
            </li>
          )}
        </ul>
      </div>
      <div className={sectionClass.string}>
        <p className={periodStateClassName}>{stateLabel}</p>
        <ul>
          <li>
            <p>ID: {id}</p>
          </li>
          {isArchiveSub ? (
            <>
              <li>
                <p>Subscription Total: {subscriptionQuota}</p>
              </li>
              <li>
                <p>Subscription Usage: {subscriptionUsage}</p>
              </li>
              <li>
                <p>Subscription Balance: {availableSubscriptionUnits}</p>
              </li>
            </>
          ) : (
            <>
              {!hideOrderPricing && (
                <li>
                  <p>Value Commitment: {valueCommitmentFormatted}</p>
                </li>
              )}
              {!hideOrderPricing && (
                <li>
                  <p>Available Commitment: {availableCommitmentFormatted}</p>
                </li>
              )}
              {!hideOrderPricing && (
                <li>
                  <p>Reserve: {reserveFormatted}</p>
                </li>
              )}
              {!hideOrderPricing && (
                <li>
                  <p>Usage: {usageFormatted}</p>
                </li>
              )}
              {!hideOrderPricing && (
                <li>
                  <p>Reseller Discount: {resellerDiscountFormatted}</p>
                </li>
              )}
            </>
          )}
        </ul>
      </div>
      <div
        className={`${sectionClass.string} ${componentClass.childString(
          'meta'
        )}`}
      >
        <ul>
          <li>
            <p>
              <strong>Start Date:</strong> {startDateFormatted}
            </p>
          </li>
          <li>
            <p>
              <strong>End Date:</strong> {endDateFormatted}
            </p>
          </li>
          <li>
            <p>
              <strong>Created Date:</strong> {createdAtFormatted}
            </p>
          </li>
          <li>
            <p>
              <strong>Updated Date:</strong> {updatedAtFormatted}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

ContractPeriodCard.propTypes = {
  isLoading: PropTypes.bool,
  contractPeriod: PropTypes.object,
  organizationId: PropTypes.string,
  canEdit: PropTypes.bool,
  isArchiveSub: PropTypes.bool,
  isReseller: PropTypes.bool,
  isSubreseller: PropTypes.bool,
  hideOrderPricing: PropTypes.bool
};

export default ContractPeriodCard;
