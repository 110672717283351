import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormRow, InputButtonList } from 'fogg/ui';
import ReactTooltip from 'react-tooltip';

import { sortByKey } from '../lib/util';
import { routePathByName } from '../lib/routes';
import { ADMIN_ROLE, RESELLER_ROLE, SUB_RESELLER_ROLE } from '../data/roles';
import {
  ROUTE_ADMIN_ORGS_EDIT,
  ROUTE_ORG_EDIT,
  ROUTE_RESELLER_ORG_DETAILS_EDIT,
  ROUTE_SUB_RESELLER_ORG_DETAILS_EDIT
} from '../data/route-names';
import { ORGANIZATION_HIDDEN_PREFERENCE_FIELDS } from '../data/organization';

import ProfileSidebar from './ProfileSidebar';
import ProfileSidebarSection from './ProfileSidebarSection';
import ProfileSidebarList from './ProfileSidebarList';
import Verifications from './Verifications';

const EXCLUDED_COLLECTION_IDS = ['capella-qa', 'e84-qa', 'capella-diu'];

const OrganizationSidebar = ({
  accessType,
  isLoading = false,
  organization = {},
  collections,
  onUpdateCollections,
  detailView,
  onUpdateHiddenFields,
  onUpdateVerification
}) => {
  const {
    id,
    usersCount,
    createdAtFormatted,
    updatedAtFormatted,
    disabled,
    collections: organizationCollections = []
  } = organization;

  let activeCollections = collections || [];

  activeCollections = sortByKey(activeCollections, 'title');
  activeCollections = activeCollections.filter(
    ({ id }) => !EXCLUDED_COLLECTION_IDS.includes(id)
  );

  const accessFull = accessType === ADMIN_ROLE;
  const accessReseller = accessType === RESELLER_ROLE;
  const accessSubReseller = accessType === SUB_RESELLER_ROLE;

  let orgEditPath;

  if (accessFull) {
    orgEditPath = routePathByName(ROUTE_ADMIN_ORGS_EDIT, {
      wildcard: [id]
    });
  } else {
    orgEditPath = routePathByName(ROUTE_ORG_EDIT);
  }

  if (accessReseller) {
    orgEditPath = routePathByName(ROUTE_RESELLER_ORG_DETAILS_EDIT, {
      wildcard: [id]
    });
  }

  if (accessSubReseller) {
    orgEditPath = routePathByName(ROUTE_SUB_RESELLER_ORG_DETAILS_EDIT, {
      wildcard: [id]
    });
  }

  /**
   * handleOnCollectionsChange
   */

  function handleOnCollectionsChange (event, changes) {
    const { currentTarget = {} } = event;
    const isChecked = currentTarget.checked;
    const name = currentTarget.value;
    const collection = collections.find(({ id }) => id === name);

    if (typeof onUpdateCollections === 'function') {
      onUpdateCollections({
        updates: [
          {
            id: name,
            isChecked,
            collection
          }
        ],
        collections: changes
      });
    }
  }

  /**
   * handleOnHiddenFieldsChange
   */

  function handleOnHiddenFieldsChange (event) {
    const { currentTarget = {} } = event;
    const isChecked = currentTarget.checked;
    const name = currentTarget.value;

    if (typeof onUpdateHiddenFields === 'function') {
      onUpdateHiddenFields({
        id: name,
        isChecked
      });
    }
  }

  // Add sidebar content, which is different depending on user type / permissions
  let sidebarSections = [];
  if (accessFull) {
    sidebarSections.push({
      header: 'Status',
      className: 'profile-sidebar-list',
      items: [
        <p key="Org-Status">
          <Verifications
            verifications={[
              {
                id: 'disabled',
                label: 'Active',
                isVerified: !disabled,
                actionLabels: {
                  add: 'Activate Organization',
                  remove: 'Disable Organization'
                }
              }
            ]}
            onChange={onUpdateVerification}
          />
        </p>
      ]
    });
  }

  sidebarSections.push({
    header: 'Details',
    items: [
      <p key={'Org-Members'}>Members: {usersCount}</p>,
      <p key={'Org-Created'}>Created: {createdAtFormatted}</p>,
      <p key={'Org-Updated'}>Updated: {updatedAtFormatted}</p>
    ]
  });

  if (!detailView) {
    sidebarSections.push({
      header: 'Organization',
      items: [
        <p key={'Org-Members'}>
          <Button to={orgEditPath} type="icon-before" full={true}>
            Edit Organization
          </Button>
        </p>
      ]
    });
  }

  if (accessFull) {
    sidebarSections.push({
      header: 'Collections',
      items: [
        <Form key="Org-CollectionAccess">
          <FormRow>
            {Array.isArray(activeCollections) && (
              <InputButtonList
                label="Access"
                name="access"
                type="checkbox"
                options={activeCollections.map((collection) => {
                  const { id, title } = collection;
                  return {
                    children: collection.isPublic === false && (
                      <>
                        <ReactTooltip
                          id={`privateCollectionTip-${id}`}
                          place="top"
                          effect="solid"
                          type="dark"
                          multiline={true}
                          className="customTip"
                        >
                          <div>
                            This is a restricted collection, if you need to make changes please email <a href="mailto:support@capellaspace.com">support@capellaspace.com</a>.
                          </div>
                        </ReactTooltip>
                        <span
                          data-text="This is a restricted collection."
                          className="text-tooltip"
                          data-for={`privateCollectionTip-${id}`}
                          data-tip
                        >{title}</span>
                      </>
                    ),
                    label: title,
                    value: id,
                    isChecked: organizationCollections.includes(id),
                    disabled: !collection.isPublic
                  };
                })}
                onChange={handleOnCollectionsChange}
                controlChecked={true}
                disabled={isLoading}
              />
            )}
          </FormRow>
        </Form>
      ]
    });
    sidebarSections.push({
      header: 'Preferences',
      items: [
        <Form key="Org-HiddenFields">
          <FormRow>
            <InputButtonList
              name="hidden-fields"
              type="checkbox"
              options={ORGANIZATION_HIDDEN_PREFERENCE_FIELDS.map((field) => {
                const { id, label } = field;
                return {
                  label,
                  value: id,
                  isChecked: organization[id]
                };
              })}
              onChange={handleOnHiddenFieldsChange}
              controlChecked={true}
              disabled={isLoading}
            />
          </FormRow>
        </Form>
      ]
    });
  }

  if (isLoading) {
    sidebarSections = sidebarSections.map((section) => {
      return {
        ...section,
        items: [...new Array(3)].map(() => 'Loading')
      };
    });
  }

  return (
    <ProfileSidebar>
      {sidebarSections.map(({ header, className, items = [] } = {}, key) => {
        return (
          <ProfileSidebarSection
            key={`ProfileSidebarSection-${key}`}
            header={header}
            className={className || 'profile-sidebar-management' }
          >
            <ProfileSidebarList
              items={items}
            />
          </ProfileSidebarSection>
        );
      })}
    </ProfileSidebar>
  );
};

OrganizationSidebar.propTypes = {
  accessType: PropTypes.string,
  isLoading: PropTypes.bool,
  organization: PropTypes.object,
  collections: PropTypes.array,
  onUpdateCollections: PropTypes.func,
  detailView: PropTypes.bool,
  onUpdateHiddenFields: PropTypes.func,
  onUpdateVerification: PropTypes.func
};

export default OrganizationSidebar;
