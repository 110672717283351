import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';

import ClassName from '../models/classname';

const ProfileSidebarList = ({ className, items }) => {
  const sidebarClass = new ClassName('profile-sidebar');
  const componentClass = new ClassName(sidebarClass.childString('list'));

  componentClass.addIf(className, className);

  const isList = Array.isArray(items);
  const hasItems = isList && items.length > 0;

  return (
    <div className={componentClass.string}>
      {hasItems && (
        <ul>
          {items.map((item = {}, key) => {
            let { Item } = item;

            if (typeof item === 'string') {
              const ItemComponent = () => <p>{item}</p>;
              Item = ItemComponent;
            } else if (isValidElement(item)) {
              Item = () => item;
            }

            if (!Item) return null;

            return (
              <li key={`ProfileSidebarList-${key}`}>
                <Item />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

ProfileSidebarList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  items: PropTypes.array
};

export default ProfileSidebarList;
