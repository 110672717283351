import { useMappedState } from 'redux-react-hook';
import { isEmptyObject } from 'lib/util';

import RepeatRequest from 'models/repeatRequest';
import { NEW_REPEAT_REQUEST } from 'data/action-types';
import { REPEAT_REQUEST_ID } from 'data/property-names';

export default function useRepeatRequest (repeatRequestId) {
  const mapState = ({ repeatRequests }) => repeatRequests;

  const { repeatRequests, new: newRepeatRequest } = useMappedState(mapState);
  let repeatRequest;

  if (repeatRequestId === NEW_REPEAT_REQUEST) {
    repeatRequest = { ...newRepeatRequest };
  } else {
    repeatRequest = repeatRequests.find(({ properties = {} }) => {
      return `${properties[REPEAT_REQUEST_ID]}` === `${repeatRequestId}`;
    });
  }

  if (isEmptyObject(repeatRequest)) {
    return {};
  }

  const outputRepeatRequest = new RepeatRequest().ingestTaskBody(repeatRequest);

  return outputRepeatRequest;
}
