import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

/**
 * shows a very helpful button
 * @param {string} [id=''] - id for the button element
 * @param {string} [className=''] - className for the button element
 * @param {any} [as='button'] - alternate element instead of the default <button>
 * @param {function} [onClick] - handles click event of the button
 * @param {string} [tooltipId=''] - tooltip id for the ReactTooltip
 * @param {string} [tooltipPlacement='right'] - direction the ReactTooltip appears
 * @param {string} [tooltipText=''] - text for the ReactTooltip
 * @param {string} [tooltipClassName='customTip'] - className for the ReactTooltip
 * @param {string} [tooltipType='success'] - type for the ReactTooltip
 * @param {string} [tooltipEffect='solid'] - effect for the ReactTooltip
 * @param {any} [children] - children elements in the button
 */
const HelpfulButton = ({
  id = '',
  className = '',
  as = 'button',
  onClick,
  tooltipId = '',
  tooltipPlacement = 'right',
  tooltipText = '',
  tooltipClassName = 'customTip',
  tooltipType = 'dark',
  tooltipEffect = 'solid',
  children
}) => {
  const Button = as;
  const enableTooltip = !!(tooltipText && tooltipId);

  return (
    <>
      <Button
        id={id}
        className={className}
        onClick={(e) => {
          // prevent default behavior and event bubble up
          e.preventDefault();
          e.stopPropagation();

          if (typeof onClick === 'function') {
            onClick(e);
          }
        }}
        data-for={tooltipId}
        data-tip={enableTooltip}
      >
        {children}
      </Button>

      {enableTooltip && (
        <ReactTooltip
          id={tooltipId}
          place={tooltipPlacement}
          effect={tooltipEffect}
          type={tooltipType}
          multiline={true}
          className={tooltipClassName}
        >
          {tooltipText}
        </ReactTooltip>
      )}
    </>
  );
};

HelpfulButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.any,
  onClick: PropTypes.func,
  tooltipId: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltipType: PropTypes.string,
  tooltipEffect: PropTypes.string,
  children: PropTypes.any
};

export default HelpfulButton;
