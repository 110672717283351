import Request from 'models/request';
import { device } from 'fogg/lib';

const { isDomAvailable } = device;

async function postLog (data) {
  const BASE_URL = process.env.LOG_EVENT_ENDPOINT;

  const request = new Request(`${BASE_URL}/logs/event`);
  let response;

  const win = isDomAvailable() ? window : {};
  const nav = isDomAvailable() ? navigator : {};

  request.setOptions({
    headers: {
      'x-api-key': process.env.ELASTICSEARCH_API_KEY,
      'Content-Type': 'application/json'
    }
  });

  const payload = {
    event: {
      source: 'Dashboard App',
      environment: process.env.NODE_ENV,
      window_origin: win.location && win.location.origin,
      window_pathname: win.location && win.location.pathname,
      window_params: win.location && win.location.search.substring(1),
      window_hash: win.location && win.location.hash,
      ua_string: nav.userAgent,
      ...data
    }
  };

  // If we're in development mode, dump this out for easier debugging in the
  // web console

  /* eslint-disable */
  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed(`${payload.event.source}: Log`);
    Object.keys(payload.event).forEach((key) => {
      console.log(key, payload.event[key]);
    });
    console.groupEnd(`${payload.event.source}: Log`);
  }
  /* eslint-enable */

  // If we dont have the base of the logger endpoint, return out before
  // we make the request

  if (!BASE_URL) return;

  request.setData(payload);

  try {
    response = await request.post();
  } catch (e) {
    console.error(`Failed to log event. Error Details: ${e}`);
  }

  return response;
}

export function logRequestEvent (requestMethod, request, response) {
  const pageSessionID =
    request.options &&
    request.options.headers &&
    request.options.headers.PageSessionId
      ? request.options.headers.PageSessionId
      : null;

  postLog({
    request_method: requestMethod,
    request_url: request.url,
    request_data: request.data,
    page_session_id: pageSessionID,
    response: response
  });
}

export function logError (message, data = {}) {
  postLog({
    error: {
      message: message,
      data: data
    }
  });
}
