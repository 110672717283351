import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StatusIndicator } from 'fogg/ui';

import { formatDateTime } from 'lib/datetime';
import { TASK_STATUS_PROCESS_CHAIN, TASK_ERROR_LIST } from 'lib/tasks';

const DEFAULT_HEADERS = ['Window Open (UTC)', 'Window Close (UTC)'];

const TaskStatusAnimated = ({
  headers = DEFAULT_HEADERS,
  speed = 1,
  task = {},
  windowOnly = false,
  customLoadingMessage = ''
}) => {
  const { statusMessage, windowOpenInt, windowCloseInt } = task;
  const speedInMs = speed * 1000;
  const [activeStatusIndex, setActiveStatusIndex] = useState(0);

  const loadingStatusChain = TASK_STATUS_PROCESS_CHAIN.map(({ id }) => ({
    id,
    label: 'Still Loading'
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStatusIndex((prevIndex) => {
        if (prevIndex + 1 === loadingStatusChain.length) {
          return 0;
        }

        return prevIndex + 1;
      });
    }, speedInMs);

    return () => clearInterval(interval);
  }, []);

  const activeStatus = loadingStatusChain[activeStatusIndex].id;

  return (
    <div className="task-status">
      <div className="task-status-window">
        <h4>{headers[0]}</h4>
        <p className="task-status-date">{formatDateTime(windowOpenInt)}</p>
      </div>

      {!windowOnly && (
        <div className="task-status-indicator">
          <StatusIndicator
            activeId={activeStatus}
            statusList={loadingStatusChain}
            errorList={TASK_ERROR_LIST}
          />
          <p className="task-status-indicator-message">{customLoadingMessage || statusMessage}</p>
        </div>
      )}

      <div className="task-status-window">
        <h4>{headers[1]}</h4>
        <p className="task-status-date">{formatDateTime(windowCloseInt)}</p>
      </div>
    </div>
  );
};

TaskStatusAnimated.propTypes = {
  headers: PropTypes.array,
  speed: PropTypes.number,
  task: PropTypes.object,
  windowOnly: PropTypes.bool,
  customLoadingMessage: PropTypes.string
};

export default TaskStatusAnimated;
