import clone from 'clone';
import deepmerge from 'deepmerge';

const defaultState = {
  actions: {},
  contracts: []
};

const contractsReducer = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);

  switch (type) {
    case 'UPDATE_CONTRACTS': {
      return {
        ...workingState,
        contracts: workingData
      };
    }

    case 'UPDATE_CONTRACTS_ACTION_STATE': {
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    }

    default:
      return workingState;
  }
};

export default contractsReducer;
