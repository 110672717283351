/*
  ### Reseller ###
*/
export const ADD_RESELLER_TASK = 'ADD_RESELLER_TASK';
export const CLEAR_NEW_RESELLER_TASK = 'CLEAR_NEW_RESELLER_TASK';
export const NEW_RESELLER_TASK = 'NEW_RESELLER_TASK';
export const SET_RESELLER_ORG = 'SET_RESELLER_ORG';
export const UPDATE_RESELLER_ORG = 'UPDATE_RESELLER_ORG';
export const UPDATE_RESELLER_ACTION_STATE = 'UPDATE_RESELLER_ACTION_STATE';
export const UPDATE_RESELLER_USER = 'UPDATE_RESELLER_USER';
export const UPDATE_RESELLER_ORG_USERS = 'UPDATE_RESELLER_ORG_USERS';
export const UPDATE_RESELLER_TASK = 'UPDATE_RESELLER_TASK';
export const UPDATE_RESELLER_TASKS = 'UPDATE_RESELLER_TASKS';
export const UPDATE_RESELLER_REPEAT_REQUESTS = 'UPDATE_RESELLER_REPEAT_REQUESTS';
export const UPDATE_RESELLER_ORGANIZATION_CONTRACT = 'UPDATE_RESELLER_ORGANIZATION_CONTRACT';
// Paged reseller tasks
export const UPDATE_RESELLER_TASKS_PAGED = 'UPDATE_RESELLER_TASKS_PAGED';
export const UPDATE_RESELLER_REPEAT_REQUESTS_PAGED = 'UPDATE_RESELLER_REPEAT_REQUESTS_PAGED';
export const UPDATE_RESELLER_TASKS_COLUMNS = 'UPDATE_RESELLER_TASKS_COLUMNS';
export const UPDATE_RESELLER_REPEAT_REQUESTS_COLUMNS = 'UPDATE_RESELLER_REPEAT_REQUESTS_COLUMNS';

/*
  ### Subreseller ###
*/
export const ADD_SUB_RESELLER_TASK = 'ADD_SUB_RESELLER_TASK';
export const CLEAR_NEW_SUB_RESELLER_TASK = 'CLEAR_NEW_SUB_RESELLER_TASK';
export const NEW_SUB_RESELLER_TASK = 'NEW_SUB_RESELLER_TASK';
export const SET_SUB_RESELLER_ORG = 'SET_SUB_RESELLER_ORGANIZATION';
export const UPDATE_SUB_RESELLER_ACTION_STATE = 'UPDATE_SUB_RESELLER_ACTION_STATE';
export const UPDATE_SUB_RESELLER_ORG = 'UPDATE_SUB_RESELLER_ORGANIZATION';
export const UPDATE_SUB_RESELLER_TASK = 'UPDATE_SUB_RESELLER_TASK';
export const UPDATE_SUB_RESELLER_TASKS = 'UPDATE_SUB_RESELLER_TASKS';
export const UPDATE_SUB_RESELLER_USER = 'UPDATE_SUB_RESELLER_USER';
export const UPDATE_SUB_RESELLER_ORG_USERS = 'UPDATE_RESELLER_ORG_USERS';
export const UPDATE_SUB_RESELLER_REPEAT_REQUESTS = 'UPDATE_SUB_RESELLER_REPEAT_REQUESTS';
export const UPDATE_SUB_RESELLER_ORGANIZATION_CONTRACT = 'UPDATE_SUB_RESELLER_ORGANIZATION_CONTRACT';
// Paged subreseller tasks
export const UPDATE_SUB_RESELLER_TASKS_PAGED = 'UPDATE_SUB_RESELLER_TASKS_PAGED';
export const UPDATE_SUB_RESELLER_REPEAT_REQUESTS_PAGED = 'UPDATE_SUB_RESELLER_REPEAT_REQUESTS_PAGED';

/*
  ### Tasks ###
*/
export const ADD_TASK = 'ADD_TASK';
export const CLEAR_NEW_TASK = 'CLEAR_NEW_TASK';
export const NEW_TASK = 'NEW_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const UPDATE_SEARCH_TASKS = 'UPDATE_SEARCH_TASKS';
// Paged tasks
export const UPDATE_TASKS_PAGED = 'UPDATE_TASKS_PAGED';
export const UPDATE_TASKS_ACTION_STATE = 'UPDATE_TASKS_ACTION_STATE';
export const UPDATE_TASKS_LIMIT = 'UPDATE_TASKS_LIMIT';
export const UPDATE_TASKS_PAGE = 'UPDATE_TASKS_PAGE';
export const UPDATE_TASKS_FILTERS = 'UPDATE_TASKS_FILTERS';
export const UPDATE_TASKS_COLUMNS = 'UPDATE_TASKS_COLUMNS';
export const UPDATE_TASKS_SORT = 'UPDATE_TASKS_SORT';

/*
  ### Repeat Requests ###
*/
export const ADD_REPEAT_REQUEST = 'ADD_REPEAT_REQUEST';
export const CLEAR_NEW_REPEAT_REQUEST = 'CLEAR_NEW_REPEAT_REQUEST';
export const NEW_REPEAT_REQUEST = 'NEW_REPEAT_REQUEST';
export const UPDATE_REPEAT_REQUEST = 'UPDATE_REPEAT_REQUEST';
export const UPDATE_REPEAT_REQUESTS = 'UPDATE_REPEAT_REQUESTS';
export const UPDATE_REPEAT_REQUESTS_PAGED = 'UPDATE_REPEAT_REQUESTS_PAGED';
export const UPDATE_REPEAT_REQUESTS_COLUMNS = 'UPDATE_REPEAT_REQUESTS_COLUMNS';
export const UPDATE_REPEAT_REQUESTS_ACTION_STATE = 'UPDATE_REPEAT_REQUESTS_ACTION_STATE';
export const UPDATE_REPEAT_REQUEST_CHILD_TASKS = 'UPDATE_REPEAT_REQUEST_CHILD_TASKS';
export const UPDATE_REPEAT_REQUEST_CHILD_TASKS_ACTION_STATE = 'UPDATE_REPEAT_REQUEST_CHILD_TASKS_ACTION_STATE';

/*
  ### Org Mgr ###
*/
export const UPDATE_ORG_TASK = 'UPDATE_ORG_TASK';
export const UPDATE_ORG_TASKS = 'UPDATE_ORG_TASKS';
export const UPDATE_ORG_REPEAT_REQUEST = 'UPDATE_ORG_REPEAT_REQUEST';
export const UPDATE_ORG_REPEAT_REQUESTS = 'UPDATE_ORG_REPEAT_REQUESTS';
// Paged
export const UPDATE_ORG_TASKS_PAGED = 'UPDATE_ORG_TASKS_PAGED';
export const UPDATE_ORG_REPEAT_REQUESTS_PAGED = 'UPDATE_ORG_REPEAT_REQUESTS_PAGED';
export const UPDATE_ORG_TASKS_COLUMNS = 'UPDATE_ORG_TASKS_COLUMNS';
export const UPDATE_ORG_REPEAT_REQUESTS_COLUMNS = 'UPDATE_ORG_REPEAT_REQUESTS_COLUMNS';

/* user */
export const USER_DESTROY_SESSION = 'USER_DESTROY_SESSION';

/* test */
export const TEST_REPLACE_STATE = 'TEST_REPLACE_STATE';
