import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { device } from 'fogg/lib';

import {
  TemplateUser,
  useReseller,
  useAdminFetchUser,
  DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS,
  UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS
} from 'commonLib';

import { ROUTE_RESELLER, ROUTE_RESELLER_USERS } from 'data/route-names';
import { routePathByName, redirectTo } from 'lib/routes';
import { logError } from 'lib/logger';
import { userIdIsValid } from 'lib/user';
import {
  resellerPutUpdateUser,
  reissuePassword,
  triggerNotice,
  triggerSuccess
} from 'state/actions';

import { RESELLER_ROLE } from 'data/roles';

const { isDomAvailable } = device;

const ResellerUsersDetails = ({ userId }) => {
  const [needsForceDisable, setNeedsForceDisable] = useState(false);
  const [needsForceTaskerRoleRemoval, setNeedsForceTaskerRoleRemoval] = useState(false);
  const [userDataToResubmit, setUserDataToResubmit] = useState({});

  const dispatch = useDispatch();

  const { actions = {} } = useReseller();
  const { resellerPutUpdateUser: actionResellerPutUpdateUser = {} } = actions;

  const { user = {}, isLoading: isLoadingUser } = useAdminFetchUser({
    id: userId
  });

  const isLoading = isLoadingUser || actionResellerPutUpdateUser.isLoading;

  if (!userIdIsValid(userId)) {
    // Navigate only works on the client, throws an error during compilation
    if (isDomAvailable()) {
      redirectTo(routePathByName(ROUTE_RESELLER_USERS));
    }
    return null;
  }

  const breadcrumbs = [
    {
      label: 'Reseller',
      to: routePathByName(ROUTE_RESELLER)
    },
    {
      label: 'Users',
      to: routePathByName(ROUTE_RESELLER_USERS)
    }
  ];

  /**
   * handleUpdateUser
   * @description Dispatches an update to a user based on the given user object
   */

  async function handleUpdateUser (userData, { showSuccess = true } = {}) {
    let showSuccessBanner = showSuccess;

    let response;
    try {
      response = await dispatch(resellerPutUpdateUser(userId, userData));
    } catch (e) {
      logError(e.message, userData);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
      return;
    }

    if (response.message === DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS) {
      setNeedsForceDisable(true);
      showSuccessBanner = false;
    } else if (response.message === UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS) {
      setNeedsForceTaskerRoleRemoval(true);
      setUserDataToResubmit(userData);
      showSuccessBanner = false;
    }

    if (showSuccessBanner) {
      dispatch(
        triggerNotice({
          type: 'success',
          align: 'center',
          text: 'Successfully updated user!'
        })
      );
    }

    return response;
  }

  /**
   * handleUpdateVerification
   * @description Triggers an update to toggle a user's verification settings
   */

  function handleUpdateVerification (e, { id }) {
    handleUpdateUser({
      [id]: !user[id]
    });
  }

  /**
   * handleReissuePassword
   * @description Dispatches a request to reissue a user's temporary password
   */

  async function handleReissuePassword () {
    try {
      await dispatch(reissuePassword(userId));
      dispatch(triggerSuccess('Successfully reissued password'));
    } catch (error) {
      logError(error.message, {
        id: userId
      });
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'User has already reset temporary password. Please have them use the Forgot Password feature on the UI to reset.'
        })
      );
    }
  }

  function handleCloseModal () {
    setNeedsForceTaskerRoleRemoval(false);
    setNeedsForceDisable(false);
    setUserDataToResubmit(undefined);
  }

  function handleUpdateConfirmation () {
    if (needsForceTaskerRoleRemoval) {
      handleForceTaskerRoleRemoval();
    } else if (needsForceDisable) {
      handleForceDisableUser();
    }
  }

  function handleForceDisableUser () {
    handleUpdateUser({
      disable: true,
      forceDisabled: true
    });
    handleCloseModal();
  }

  function handleForceTaskerRoleRemoval () {
    userDataToResubmit.forceTaskerRoleRemoval = true;
    handleUpdateUser(userDataToResubmit);
    handleCloseModal();
  }

  return (
    <TemplateUser
      user={user}
      accessType={RESELLER_ROLE}
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      onUpdateUser={handleUpdateUser}
      onUpdateVerification={handleUpdateVerification}
      onReissuePassword={handleReissuePassword}
      forceDisable={needsForceDisable}
      forceTaskerRoleRemoval={needsForceTaskerRoleRemoval}
      handleConfirmation={handleUpdateConfirmation}
      handleRejectConfirmation={handleCloseModal}
    />
  );
};

ResellerUsersDetails.propTypes = {
  userId: PropTypes.string
};

export default ResellerUsersDetails;
