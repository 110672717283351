import moment from 'moment';

/**
 * Returns the UTC date in desired format ('MM/DD/YYYY' by default)
 * @param {Date|string|Number} dateTime = Date to convert/format, anything moment accepts
 * @param {string} [defaultValue = '-'] fallback display value if empty/invalid date
 * @param {string} [format = 'MM/DD/YY h:mm a'] moment date format
 * @returns date string
 */

export function formatDate (dateTime, defaultValue = '-', format = 'MM/DD/YYYY') {
  if (!dateTime && dateTime !== 0) return defaultValue;

  let date = dateTime;

  if (date instanceof Date) {
    date = date.getTime();
  }

  date = moment.utc(date);

  return date.format(format);
}

export const defaultInternationalFormat = 'YYYY-MM-DD h:mm a';

/**
 * formatDateTime
 * @description Given a timestamp input, returns a date in format MM/DD/YYYY HH:MM
 */

export function formatDateTime (dateTime, defaultValue = '-') {
  if (!dateTime && dateTime !== 0) return defaultValue;

  let date = dateTime;

  if (date instanceof Date) {
    date = date.getTime();
  }

  date = moment.utc(date);

  return date.format('MM/DD/YY h:mm a');
}

/**
 * dateTimeFormats
 */

export function dateTimeFormats (dateTime, defaultValue = '-', options = {}) {
  if (!dateTime && dateTime !== 0) return defaultValue;

  const { dateFormat = 'MM/DD/YY', timeFormat = 'h:mm a' } = options;

  let date = dateTime;

  if (date instanceof Date) {
    date = date.getTime();
  }

  date = moment.utc(date);

  return {
    date: date.format(dateFormat),
    time: date.format(timeFormat)
  };
}

/**
 * formatDuration
 * @description Given a duration in seconds, formats as x days, x minutes, x seconds
 */

export function formatDuration (duration) {
  const momentDuration = moment.duration(duration, 's');
  const time = [];

  const years = momentDuration.years();
  const months = momentDuration.months();
  const days = momentDuration.days();
  const hours = momentDuration.hours();
  const minutes = momentDuration.minutes();
  const seconds = momentDuration.seconds();

  if (years > 0) time.push(`${years} Year(s)`);
  if (months > 0) time.push(`${months} Month(s)`);
  if (days > 0) time.push(`${days} Day(s)`);
  if (hours > 0) time.push(`${hours} Hour(s)`);
  if (minutes > 0) time.push(`${minutes} Minute(s)`);
  if (seconds > 0) time.push(`${seconds} Second(s)`);

  return time.join(', ');
}

/**
 * dateOffsetByHours
 * @description Datetime offset by number of hours. Defaults to now
 */

export function dateOffsetByHours (hours, userDate) {
  const date = userDate || new Date();
  const newHours = date.getHours() + parseInt(hours);
  date.setHours(newHours);
  return date;
}

/**
 * dateOffsetByHours
 * @description Datetime offset by number of hours. Defaults to now
 */

export function dateOffsetBySeconds (seconds, userDate) {
  const date = userDate || new Date();
  const newSeconds = date.getSeconds() + parseInt(seconds);
  date.setSeconds(newSeconds);
  return date;
}

/**
 * dateIsExpired
 * @description Is the passed in datetime expired? Uses the current time to check
 */

export function dateIsExpired (date) {
  const dateNow = Date.now();
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return date < dateNow;
}

/**
 * getNumberOfDays
 * @description Number of days between 2 dates
 */
export function getNumberOfDays (start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // 1 day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculate the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Convert to days
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

/**
 * Takes a numeric day (e.g. 7) and returns a UTC time range to today
 * @param {string} daysAgo
 * @returns {string} e.g. '2023-05-01T00:00:00.000Z/2023-06-08T23:59:59.999Z'
 */
export function daysAgoToDate (daysAgo) {
  if (!daysAgo) return new Date();
  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - daysAgo));
  return `${priorDate.toISOString()}/${today.toISOString()}`;
}

/**
 * Takes in array of days (e.g. [7]) or JS date range and converts to UTC date range
 * @param {array} value = [7]
 * @returns {string} e.g. '1685131000/1685131027'
 */
export function taskFilterDateHandler (value) {
  if (!value || !Array.isArray(value)) return null;
  const dateString = value[0];
  if (dateString.includes('/')) {
    const rangeString = dateString.split('/');
    const start = new Date(Number(rangeString[0])).toISOString();
    const end = new Date(Number(rangeString[1])).toISOString();
    return `${start}/${end}`;
  } else {
    return daysAgoToDate(dateString);
  }
}

/**
 * Helper to convert tasks table date range filter to a more friendly object format
 * @param {string} rangeString e.g. '1685131000/1685131027'
 * @returns {object} e.g. { start: 1685131000, end: 1685131027 }
 */
export function dateRangeFilterToObject (rangeString) {
  if (typeof rangeString !== 'string') return;
  const rangeArray = rangeString.split('/');

  if (rangeArray?.length === 2) {
    return {
      start: Number(rangeArray[0]),
      end: Number(rangeArray[1])
    };
  }
}
