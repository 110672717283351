import React from 'react';
import PropTypes from 'prop-types';

import { WonderLink } from 'fogg/ui';

import { contractByName } from '../lib/contracts';
import { CONTRACT_EXCLUSIVITY_UPLIFTS } from '../data/contracts';
import { useFlags } from 'gatsby-plugin-launchdarkly';

const ContractCard = ({
  contract,
  licenses,
  isReseller,
  accessFull,
  shouldHidePricing,
  isIndefinite,
  isArchiveSub,
  canEdit = false,
  organization = {},
  contractManagementActions
}) => {
  const {
    type,
    license,
    id,
    startDateFormatted,
    endDateFormatted,
    resellerTierFormatted,
    fundsFormatted,
    enforceAvailableFundsCap,
    isInvoiceable,
    createdAtFormatted,
    updatedAtFormatted,
    entityUpliftFormatted,
    customUpliftsFormatted = [],
    exclusivityHoldsFormatted = [],
    volumeDiscountFormatted,
    discretionaryDiscountFormatted = [],
    salesforceContractId,
    salesforceOpportunityId,
    isAnalyticsVcSubscription,
    analyticsVcPricingOverrideFormatted
  } = contract;

  const { vesselClassificationEnabled = false } = organization;
  const isActive = !contract.isExpired();
  const { label: contractLabel = '-' } = contractByName(type);

  const contractLicense = licenses?.all?.find(
    (licenseDef) => licenseDef.value === license
  );
  const licenseName = (contractLicense && contractLicense.label) || 'None';

  const flags = useFlags();
  const displayAnalyticsVcBillingType =
    vesselClassificationEnabled && flags.vesselClassificationEnabled && flags.customAnalyticUplifts;

  return (
    <div className="contract-card">
      <div className="contract-card-header">
        <h3>
          {startDateFormatted} - {endDateFormatted}
        </h3>
        <ul className="contract-card-header-actions">
          {contractManagementActions.map((action) => {
            const { to, label, icon } = action;
            return (
              <li key={to}>
                <WonderLink to={to}>
                  {icon} {label}
                </WonderLink>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="contract-card-section">
        <p
          className={`contract-card-state ${
            isActive
              ? 'contract-card-state-active'
              : 'contract-card-state-passed'
          }`}
        >
          {isActive ? 'Active' : 'Inactive'}
        </p>
        <ul className="contract-details-details">
          <li>
            <p>
              <span className="contract-detail-key">ID: </span>
              <span className="contract-detail-value">{id}</span>
            </p>
          </li>
          <li>
            <p>
              <ul>
                <li>
                  <p>
                    <strong>Start Date:</strong> {startDateFormatted}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>End Date:</strong> {endDateFormatted}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Created Date:</strong> {createdAtFormatted}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Updated Date:</strong> {updatedAtFormatted}
                  </p>
                </li>
              </ul>
            </p>
          </li>
        </ul>
      </div>
      <div className="contract-card-section contract-card-meta">
        <ul>
          <li>
            <p key="Contract-Type">
              <strong>Type:</strong> {contractLabel}
            </p>
          </li>
          {licenseName && (
            <li>
              <p key="Contract-License">
                <strong>License:</strong> {licenseName}
              </p>
            </li>
          )}
          <li>
            <p key="Contract-ResellerTier">
              {isReseller && (
                <>
                  <strong>Reseller Tier:</strong> {resellerTierFormatted}
                </>
              )}
            </p>
          </li>
          <li>
            <p key="Contract-StartDate">
              <strong>Start Date:</strong> {startDateFormatted}
            </p>
          </li>
          <li>
            <p key="Contract-EndDate">
              <strong>End Date:</strong> {endDateFormatted}
            </p>
          </li>
          {!isArchiveSub && (
            <>
              <li>
                <p key="Contract-Funds">
                  {!shouldHidePricing && (
                    <span>
                      {!isIndefinite && (
                        <>
                          <strong>Funds:</strong> {fundsFormatted}
                        </>
                      )}
                    </span>
                  )}
                </p>
              </li>
              <li>
                <p key="Contract-EnforceAvailableFundsCap">
                  {!isIndefinite && (
                    <>
                      <strong>Enforce Available Funds Cap:</strong>{' '}
                      {enforceAvailableFundsCap ? 'Yes' : 'No'}
                    </>
                  )}
                </p>
              </li>
              <li>
                <p key="Contract-Invoiceable">
                  {accessFull && (
                    <>
                      <strong>Invoice Contract:</strong>{' '}
                      {isInvoiceable ? 'Yes' : 'No'}
                    </>
                  )}
                </p>
              </li>
              <li>
                <p key="Contract-EntityUplift">
                  {!shouldHidePricing && (
                    <span>
                      <strong>Entity Uplift:</strong> {entityUpliftFormatted}
                    </span>
                  )}
                </p>
              </li>
              <li>
                <p key="Contract-CustomUplifts">
                  {!shouldHidePricing && (
                    <span>
                      <strong>Custom Uplifts:</strong>
                      {customUpliftsFormatted.length > 1 ? <br /> : ' '}
                      {customUpliftsFormatted.map(({ label, value, id }, index) => {
                        return (
                          <span key={id}>
                            {index > 0 && <br />}
                            {label}: {value}
                          </span>
                        );
                      })}
                    </span>
                  )}
                </p>
              </li>
              <li>
                <p key="Contract-ExclusivityHolds">
                  {!shouldHidePricing && (
                    <span>
                      <strong>Exclusivity Uplifts:</strong>
                      {exclusivityHoldsFormatted.length > 1 ? <br /> : ' '}
                      {exclusivityHoldsFormatted.map(
                        ({ label, value, id }, index) => {
                          const displayValue = CONTRACT_EXCLUSIVITY_UPLIFTS.filter(
                            (uplift) => uplift.name === value
                          );
                          return (
                            <span key={id}>
                              {index > 0 && <br />}
                              {label}: {displayValue[0] && displayValue[0].label}
                            </span>
                          );
                        }
                      )}
                    </span>
                  )}
                </p>
              </li>
              <li>
                <p key="Contract-EntityUplift">
                  {!shouldHidePricing && (
                    <span>
                      <strong>Volume Discount:</strong> {volumeDiscountFormatted}
                    </span>
                  )}
                </p>
              </li>
              <li>
                <p key="Contract-DiscretionaryDiscount">
                  {!shouldHidePricing && (
                    <span>
                      <strong>Discretionary Discount:</strong>
                      {discretionaryDiscountFormatted.length > 1 ? <br /> : ' '}
                      {discretionaryDiscountFormatted.map(
                        ({ label, value, id }, index) => {
                          return (
                            <span key={id}>
                              {index > 0 && <br />}
                              {label}: {value}
                            </span>
                          );
                        }
                      )}
                    </span>
                  )}
                </p>
              </li>
            </>
          )}
          <li>
            <p key="Contract-Analytics-Charges">
              {!shouldHidePricing && displayAnalyticsVcBillingType && (
                <span>
                  <strong>Analytics Charges:</strong>
                  <br />
                  {isAnalyticsVcSubscription ? (
                    <span key="contract-analytics-vc-subscription">
                      VC Subscription: Yes
                    </span>
                  ) : (
                    <span key="contract-analytics-vc-uplift">
                    VC Uplift: {analyticsVcPricingOverrideFormatted}
                    </span>
                  )}
                </span>
              )}
            </p>
          </li>
          <li>
            <p key="Contract-CreatedAt">
              <strong>Created At:</strong> {createdAtFormatted}
            </p>
          </li>
          <li>
            <p key="Contract-UpdatedAt">
              <strong>Updated At:</strong> {updatedAtFormatted}
            </p>
          </li>
          <li>
            <p key="Contract-SalesforceContractId">
              <strong>Salesforce Contract ID:</strong>{' '}
              {salesforceContractId || '-'}
            </p>
          </li>
          <li>
            <p key="Contract-SalesforceOpportunityId">
              <strong>Salesforce Opportunity ID:</strong>{' '}
              {salesforceOpportunityId || '-'}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

ContractCard.propTypes = {
  contract: PropTypes.object,
  licenses: PropTypes.object,
  isReseller: PropTypes.bool,
  accessFull: PropTypes.bool,
  shouldHidePricing: PropTypes.bool,
  isIndefinite: PropTypes.bool,
  isArchiveSub: PropTypes.bool,
  canEdit: PropTypes.bool,
  organization: PropTypes.object,
  contractManagementActions: PropTypes.array
};

export default ContractCard;
