import { device } from 'fogg/lib';
import Cookies from 'js-cookie';
import ApiRequest from '../models/api-request';
import { refreshTokenAndRetry } from '../state/actions';
import { routePathByName, navigateTo, constructRedirectUrl } from '../lib/routes';
import { ROUTE_USER_LOG_OUT, ROUTE_API_TOKEN } from '../data/route-names';
import { DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS, UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS } from '../data/errors';

const { isDomAvailable } = device;

export const actionStateIsLoading = {
  isLoading: true,
  isError: false
};

export const actionStateIsError = {
  isLoading: false,
  isError: true,
  isRetrying: false
};

export const actionStateIsLoaded = {
  firstLoad: true,
  isError: false,
  isLoading: false,
  isRetrying: false
};

export const actionStateIsRetrying = {
  isError: false,
  isLoading: true,
  isRetrying: true
};

/**
 * constructRequestActionManager
 * @description Creates an async function that scaffolds a request action
 */

export function constructRequestActionManager (setActionState) {
  const _setActionIsLoading = constructActionIsLoading(setActionState);
  const _setActionIsError = constructActionIsError(setActionState);
  const _setActionIsLoaded = constructActionIsLoaded(setActionState);
  const _setActionIsRetrying = constructActionIsRetrying(setActionState);

  return function (settings = {}) {
    const { name, request = {}, scope } = settings;
    const {
      url,
      method,
      data,
      dataValidation,
      options,
      params,
      headers,
      signal
    } = request;
    const errorBase = `Failed to ${name}`;

    return async function dispatchAction (dispatch, getState) {
      dispatch(_setActionIsLoading(name));

      const fullState = getState();
      const { user: userState } = fullState;
      const scopeState = fullState[scope];
      if (!scopeState) {
        throw new Error(`${errorBase}: Invalid state scope ${scope}`);
      }

      const { user = {}, pageSession = {} } = userState;
      const refreshToken = Cookies.get('refreshToken');

      const request = new ApiRequest(url, user, pageSession);
      const isRetrying =
        scopeState.actions[name] && scopeState.actions[name].isRetrying;
      const isRefresh = url.includes(ROUTE_API_TOKEN);
      let response;
      let status;
      let error;
      let errorCode;
      let errorMessage;

      if (data) request.setData(data);
      if (options) request.setOptions(options);
      if (params) request.setParams(params);
      if (headers) request.updateHeaders(headers);
      if (signal) {
        request.options = {
          ...request.options,
          signal: signal
        };
      }

      if (typeof dataValidation === 'function' && dataValidation(data)) {
        dispatch(_setActionIsError(name));
        throw new Error(
          `${errorBase}: Invalid Request - Does not include all required properties`
        );
      }

      if (typeof request[method] !== 'function') {
        throw new Error(`${errorBase}: Unknown method ${method}`);
      }

      // Try to trigger the request. If it fails, we want to deconstruct
      // the error object to know what we're dealing with
      try {
        response = await request[method]();
      } catch (e) {
        error = e;
        response = error.response;
        status = response.status;
        errorMessage = error.message;

        // Get and set error code returned from failed request
        if (response?.data?.error?.code) {
          errorCode = response.data.error.code;
        } else if (response?.data?.code) errorCode = response.data.code;
      }
      // If our status is a 401, our token probably expired, so let's try to refresh it
      // We may also get a 400 : "INVALID_TOKEN" so try the refresh there too
      if ((status === 401 || (errorCode && errorCode === 'INVALID_TOKEN')) && !isRefresh && !isRetrying) {
        // Construct url to redirect user to if refresh & retry fails (ie. session expired)
        const urlParams = new URLSearchParams(window?.location.search);
        const redirectConsole = urlParams.get('redirectConsole');
        const redirectAnalytics = urlParams.get('redirectAnalytics');

        const logoutUrl = constructRedirectUrl({
          baseUrl: routePathByName(ROUTE_USER_LOG_OUT),
          redirectConsole,
          redirectAnalytics,
          sessionExpired: true
        });

        try {
          dispatch(_setActionIsRetrying(name));
          const createDispatchAction = constructRequestActionManager(setActionState);
          const retryAction = createDispatchAction(settings);
          // If we don't have a refresh token, log em out!
          if (refreshToken) {
            response = await refreshTokenAndRetry({
              refreshToken: refreshToken,
              action: retryAction,
              dispatchArgs: [dispatch, getState]
            });
          } else {
            navigateTo(logoutUrl);
          }
          error = undefined;
        } catch (e) {
          // Couldn't refresh token or validate existing, force logout and back to login
          navigateTo(logoutUrl);
        }
      }

      // If error is 'canceled' it means it was aborted by a new async request
      if (errorMessage === 'canceled') {
        dispatch(_setActionIsLoading(true));
        error.setMessage('canceled');
        throw error;
      } else if (errorCode === DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS || errorCode === UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS) {
        dispatch(_setActionIsLoaded(name));
        dispatch(_setActionIsError(errorCode));
        error.setMessage(`${errorCode}`);
        throw error;
      } else if (error) {
        // If at this point we still have an error, bail out
        dispatch(_setActionIsError(name));
        error.setMessage(`${errorBase}: ${error}`);
        throw error;
      }

      dispatch(_setActionIsLoaded(name));

      return response;
    };
  };
}

/**
 * constructFormActionManager
 * @description Creates an async function that scaffolds a request action
 */

export function constructFormActionManager (setActionState) {
  const _setActionIsLoading = constructActionIsLoading(setActionState);
  const _setActionIsError = constructActionIsError(setActionState);
  const _setActionIsLoaded = constructActionIsLoaded(setActionState);

  return function (settings = {}) {
    const { name, request = {} } = settings;
    const { url, data } = request;
    const errorBase = `Failed to ${name}`;

    return async function dispatchAction (dispatch, getState) {
      dispatch(_setActionIsLoading(name));

      if (!isDomAvailable()) {
        dispatch(_setActionIsError(name));
        throw new Error(`${errorBase}: DOM is unavailable`);
      }

      const wrapper = document.createElement('div');
      const form = document.createElement('form');

      const formFields = [
        {
          name: 'encoding',
          value: 'UTF-8'
        },
        ...data
      ];

      form.method = 'POST';
      form.action = url;

      formFields.forEach((field) => {
        const element = document.createElement('input');
        element.name = field.name;
        element.value = field.value;
        form.appendChild(element);
      });

      wrapper.className = 'visually-hidden';
      wrapper.appendChild(form);
      document.body.appendChild(wrapper);

      form.submit();

      dispatch(_setActionIsLoaded(name));

      return formFields;
    };
  };
}

/**
 * constructActionIsLoading
 * @description Makes a new function that sets action state to is loading
 */

export function constructActionIsLoading (setActionState) {
  return function (action, settings = {}) {
    return setActionState({
      [action]: {
        ...actionStateIsLoading,
        ...settings
      }
    });
  };
}

/**
 * constructActionIsError
 * @description Makes a new function that sets action state to is error
 */

export function constructActionIsError (setActionState) {
  return function (action, settings = {}) {
    return setActionState({
      [action]: {
        ...actionStateIsError,
        ...settings
      }
    });
  };
}

/**
 * constructActionIsLoaded
 * @description Makes a new function that sets action state to is loaded
 */

export function constructActionIsLoaded (setActionState) {
  return function (action, settings = {}) {
    return setActionState({
      [action]: {
        ...actionStateIsLoaded,
        ...settings
      }
    });
  };
}

/**
 * constructActionIsRetrying
 * @description Makes a new function that sets action state to is retrying
 */

export function constructActionIsRetrying (setActionState) {
  return function (action, settings = {}) {
    return setActionState({
      [action]: {
        ...actionStateIsRetrying,
        ...settings
      }
    });
  };
}
