import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { useDispatch } from 'redux-react-hook';
import { useLens } from 'fogg/hooks';
import { routePathByName, normalizePathname } from 'lib/routes';
import { MAP_RESULTS_FEATURE_GROUP_ID, MAP_CHECKED_RESULTS_FEATURE_GROUP_ID } from 'lib/map';
import {
  compareSearchParams,
  filtersFromQueryString,
  AVAILABLE_SEARCH_PARAMS
} from 'lib/search';
import { useLocation, useSearchSync } from 'hooks';
import { triggerNotice } from 'state/actions';

import SidebarSearch from 'components/SidebarSearch';
import SidebarDetails from 'components/SidebarDetails';
import LensDiscover from 'components/LensDiscover';
import BasemapControl from 'components/BasemapControl';

let mapFeatureGroup, checkedFeatureGroup;

const SearchSidebar = (props = {}) => {
  const dispatch = useDispatch();
  const [checkedFeatures, setCheckedFeatures] = useState([]);

  const {
    queryParams,
    clearQuerySearchParams,
    prevPath,
    prevSearch,
    activeState: { resetSearch },
    pathname
  } = useLocation();

  const searchPath = routePathByName('search');

  const { geoSearch = {}, geoFilters = {}, map = {} } = useLens();
  const {
    results = {},
    search,
    searchPlacename,
    queryParams: searchQueryParams,
    clearSearch
  } = geoSearch;
  const { features } = results;
  const { geoJson, textInput, date, showCart } = searchQueryParams;
  const { filters = {}, setActiveFilters } = geoFilters;
  const { unsaved = [], available = [], active = [] } = filters;
  const { mapConfig = {}, mapState, createFeatureGroup, clearLayers } = map;
  const { zoom } = mapConfig;
  const { initialized: mapHasInitialized } = mapState;

  if (!mapFeatureGroup) {
    mapFeatureGroup = createFeatureGroup(MAP_RESULTS_FEATURE_GROUP_ID);
  }

  if (!checkedFeatureGroup) {
    checkedFeatureGroup = createFeatureGroup(
      MAP_CHECKED_RESULTS_FEATURE_GROUP_ID
    );
  }

  const textQuery = queryParams.q;
  // Make the updating of the unsaved IDs dependent of a string that
  // consists of the unsaved IDs to avoid the useEffect running each time
  // as the dependency array doesnt work well with arrays generally

  const unsavedIdsString = unsaved.map(({ id } = {}) => id).join();

  // If our active serach changes, we want to make sure we're keeping the url
  // up to date with the query params

  const { params } = useSearchSync({
    results: features,
    activeFilters: active,
    geoJson,
    textInput,
    zoom,
    date,
    showCart
  });

  // Debounce Check of Query params to try and account for zoom delay
  const paramsAreEqual = compareSearchParams(queryParams, params, {
    exclude: ['zoom']
  });

  const currentPathIsSearch = isSearchPage(pathname);

  let tabAction;

  if (!currentPathIsSearch) {
    tabAction = 'back-to-search';
  }

  // If the query params have changed in the URL, we'll want to retrigger
  // another search. This effectively preloads URL parameters allowing
  // navigation and landing on the map to trigger a search

  useEffect(() => {
    if (resetSearch) {
      handleClearActiveSearch({ resetView: true });
      clearLayers({
        featureGroup: mapFeatureGroup
      });
      clearLayers({
        featureGroup: checkedFeatureGroup
      });
    }
  }, [resetSearch]);

  /**
   * isSearchPage
   * @description Is the given path the search page?
   */

  function isSearchPage (path) {
    if (!path) return false;
    let normalizedPath = path.split('?')[0];

    normalizedPath = normalizePathname(normalizedPath);

    return searchPath === normalizedPath;
  }

  /**
   * isSearchDetailsPage
   * @description Is the given path the search details page?
   */

  function isSearchDetailsPage (path) {
    if (!path) return false;

    return normalizePathname(path).includes(routePathByName('searchDetails'));
  }

  /**
   * shouldSearch
   * @description Should we perform a search?
   */

  function shouldSearch () {
    // We only want to search on a map thats ready

    if (!mapHasInitialized) return false;

    if (!currentPathIsSearch) return false;

    const prevPathIsSearch = isSearchPage(prevPath);
    const prevPathIsSearchDetails = isSearchDetailsPage(prevPath);

    const { forceSearch = false } = queryParams;
    if (!forceSearch && (prevPathIsSearch || prevPathIsSearchDetails) && !paramsAreEqual[0]) return false;

    return true;
  }

  useEffect(() => {
    if (!mapHasInitialized || !shouldSearch()) return;
    // Only update search state and filters if necessary
    const settings = {};

    if (textQuery && textQuery.length > 0) {
      settings.textInput = textQuery;
    }

    if (queryParams.properties) {
      const activeFilters = filtersFromQueryString(
        queryParams.properties,
        available
      );
      settings.filters = activeFilters;
      setActiveFilters(settings.filters);
    }

    if (queryParams?.date) {
      settings.date = JSON.parse(queryParams.date);
    }

    if (queryParams.geoJson) {
      try {
        settings.geoJson = JSON.parse(queryParams.geoJson);
      } catch (e) {
        dispatch(
          triggerNotice({
            type: 'warning',
            weight: 'bold',
            align: 'center',
            text: 'Oops, we had trouble loading that URL, please double check the formatting and try again.'
          })
        );
        return;
      }
    }

    settings.zoom = queryParams.zoom;

    const activeSearchParams = AVAILABLE_SEARCH_PARAMS.filter(
      (property) => !!settings[property]
    );
    const hasSearchParams = activeSearchParams.length > 0;

    if (!hasSearchParams) return;

    if (settings.textInput) {
      searchPlacename(settings);
    } else {
      search(settings);
    }
  }, [mapHasInitialized, prevPath, prevSearch]);

  // In order to effectively update any filters upon change, we want to
  // trigger a new search any time we discover there's unsaved filter IDs

  useEffect(() => {
    if (unsavedIdsString.length > 0) {
      search({
        saveUnsavedFilters: true,
        closeFilters: false
      });
    }
  }, [unsavedIdsString, search]);

  /**
   * handleClearActiveSearch
   * @description Wraps the clearActiveSearch function and clears query params when search cleared
   */

  function handleClearActiveSearch (settings) {
    if (typeof clearSearch === 'function') {
      clearSearch(settings);
      clearQuerySearchParams(AVAILABLE_SEARCH_PARAMS);
    }
  }

  return (
    <>
      <LensDiscover tabAction={tabAction} searchType="default" />
      <Router className="lens-sidebar-router" basepath="/">
        <SidebarSearch
          path={searchPath}
          checkedFeatures={checkedFeatures}
          setCheckedFeatures={setCheckedFeatures}
        />
        <SidebarDetails
          path={routePathByName('searchDetails', {
            wildcard: [':collectionId', ':itemId']
          })}
          {...props}
        />
      </Router>
      <BasemapControl layers={props.layers} onChange={props.toggleLayer} />
    </>
  );
};

SearchSidebar.propTypes = {
  filters: PropTypes.shape({
    unsaved: PropTypes.array
  }),
  geoJson: PropTypes.object,
  textInput: PropTypes.string,
  search: PropTypes.func,
  searchType: PropTypes.string,
  clearActiveSearch: PropTypes.func,
  results: PropTypes.array,
  layers: PropTypes.shape({
    base: PropTypes.arrayOf(PropTypes.object),
    overlay: PropTypes.arrayOf(PropTypes.object)
  }),
  toggleLayer: PropTypes.func
};

export default SearchSidebar;
