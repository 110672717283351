import React from 'react';
import PropTypes from 'prop-types';
import { WonderLink } from 'fogg/ui';

const LinkList = ({ className, links = [] }) => {
  let linkListClassName = 'link-list';

  if (className) {
    linkListClassName = `${linkListClassName} ${className}`;
  }

  return (
    <ul className={linkListClassName}>
      {Array.isArray(links) &&
        links.map(({ to, label, icon, properties }, index) => {
          return (
            <li className="link-list-item" key={`LinkList-Link-${index}`}>
              <WonderLink className="link-list-link" to={to} {...properties}>
                {icon && <span className="link-list-item-icon">{icon}</span>}
                {label}
              </WonderLink>
            </li>
          );
        })}
    </ul>
  );
};

LinkList.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string,
      properties: PropTypes.object
    })
  )
};

export default LinkList;
