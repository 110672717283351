import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';

import { triggerNotice } from 'state/actions';
import { routePathByName } from 'lib/routes';
import { obfuscateEmail } from 'lib/util';
import { useUser } from 'hooks';
import User from 'models/user';

import Layout from 'components/Layout';
import Container from 'components/Container';
import FormResetPassword from 'components/FormResetPassword';

const UserPasswordResetPage = () => {
  const dispatch = useDispatch();

  const { user: userData } = useUser();
  const user = new User(userData); // TODO: useUser should return a User

  const links = [];

  if (!user.isLoggedIn()) {
    links.push({
      label: 'Log In to Your Account',
      to: routePathByName('userLogin')
    });
  }

  /**
   * handleOnSuccess
   */

  function handleOnSuccess (data, { fields } = {}) {
    const { email = {} } = fields;
    dispatch(
      triggerNotice({
        type: 'success',
        weight: 'bold',
        align: 'center',
        text: `Please check your email at ${obfuscateEmail(
          email.value
        )} to continue to reset your password.`
      })
    );
  }

  /**
   * handleOnFailure
   */

  function handleOnFailure () {
    dispatch(
      triggerNotice({
        type: 'error',
        weight: 'bold',
        align: 'center',
        text: 'Oops, something went wrong. Try again'
      })
    );
  }

  return (
    <Layout navigation={user.isLoggedIn()}>
      <Helmet bodyAttributes={{ class: 'page-user page-user-password-reset' }}>
        <title>Reset Password</title>
      </Helmet>
      <Container className="content" type="centered">
        <div className="container-row container-row-narrow">
          <h1>Reset Password</h1>
          {user.isLoggedIn() && (
            <p className="push-bottom-two">
              Need to update your password? We&apos;ll send a link to the email
              address below that will let you reset and change your password.
            </p>
          )}
          <FormResetPassword
            email={user.id && user.email}
            links={links}
            onSuccess={handleOnSuccess}
            onFailure={handleOnFailure}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default UserPasswordResetPage;
