import clone from 'clone';
import deepmerge from 'deepmerge';
import { location } from 'fogg/lib';

import { searchArgsToUrlParams } from 'lib/search';

const { objectToQueryString } = location;

const defaultState = {
  history: [],
  actions: {},
  searchCollections: {}
};

export const SEARCH_HISTORY_LIMIT = 5;

const search = (state = defaultState, { type, data }) => {
  const workingState = clone(state);
  const workingData = clone(data);

  switch (type) {
    case 'UPDATE_SEARCH_ACTION_STATE': {
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    }
    case 'PUSH_SEARCH_HISTORY': {
      const { args = {}, results } = workingData;
      const { history: workingHistory = [] } = workingState;
      const params = searchArgsToUrlParams(args);
      const search = `?${objectToQueryString(params)}`;

      // Create a new entry that we'll consider the last search made that includes
      // the args we are using to build this and the params which we add for convenience
      // for use within the application

      const last = {
        args,
        params,
        search,
        results,
        datetime: Date.now()
      };

      let history = [...workingHistory, last];

      const length = history.length;

      // If our history is bigger than our defined limit, trim it down

      if (length > SEARCH_HISTORY_LIMIT) {
        history = history.slice(length - SEARCH_HISTORY_LIMIT, length);
      }

      return {
        ...workingState,
        last,
        history
      };
    }
    case 'UPDATE_USER_ORGANIZATION_COLLECTIONS': {
      const { collections = {} } = workingData;
      return {
        ...workingState,
        searchCollections: collections
      };
    }
    default: {
      return workingState;
    }
  }
};

export default search;
