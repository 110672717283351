import React from 'react';
import PropTypes from 'prop-types';
import {
  useAdminFetchOrganization,
  useAdminFetchLicenses
} from 'commonLib/src/hooks';
import { TemplateContractDetails } from 'commonLib/src/templates';
import { ADMIN_ROLE } from '../../../../data/roles';
import {
  ROUTE_ADMIN,
  ROUTE_ADMIN_ORGS,
  ROUTE_ADMIN_ORGS_DETAILS
} from '../../../../data/route-names';
import { routePathByName } from '../../../../lib/routes';

const AdminOrganizationsContractDetailsPage = ({
  contractId,
  organizationId
}) => {
  const { organization = {}, isLoading: fetchOrgIsLoading } =
    useAdminFetchOrganization({
      id: organizationId
    });
  const { licenses = {}, isLoading: fetchLicensesIsLoading } =
    useAdminFetchLicenses();

  const detailedContract = organization.contracts?.find(
    (contract) => contract.id === contractId
  );

  const breadcrumbs = [
    {
      label: 'Admin',
      to: routePathByName(ROUTE_ADMIN)
    },
    {
      label: 'Organizations',
      to: routePathByName(ROUTE_ADMIN_ORGS)
    },
    {
      label: 'Organization',
      to: routePathByName(ROUTE_ADMIN_ORGS_DETAILS, {
        wildcard: [organizationId]
      })
    }
  ];

  return (
    <TemplateContractDetails
      accessType={ADMIN_ROLE}
      breadcrumbs={breadcrumbs}
      contract={detailedContract}
      organization={organization}
      licenses={licenses}
      state={{ isLoading: fetchOrgIsLoading || fetchLicensesIsLoading }}
    />
  );
};

AdminOrganizationsContractDetailsPage.propTypes = {
  contractId: PropTypes.string,
  organizationId: PropTypes.string
};

export default AdminOrganizationsContractDetailsPage;
