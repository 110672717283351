import { useEffect, useRef } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useUser, useTasks } from 'hooks';

import { logError } from 'lib/logger';
import {
  triggerNotice,
  searchTasksByUserId,
  fetchTasksCsvByUserId
} from 'state/actions';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';

export default function useFetchTasks ({
  forceNew = false,
  page = 1,
  limit = DEFAULT_PAGING_LIMIT,
  filters = {},
  sortObject = {}
} = {}) {
  const dispatch = useDispatch();
  const { tasks, actions = {} } = useTasks();
  const { user = {} } = useUser();
  const { id } = user;

  // Stringified objects to for useEffect to key off of
  const filtersKey = JSON.stringify(filters);
  const sortKey = JSON.stringify(sortObject);
  const controller = useRef(new AbortController());

  useEffect(() => {
    if (typeof id !== 'string') return;

    // Reset abort controller for each new request
    controller.current = new AbortController();

    dispatch(searchTasksByUserId(id, { page, limit }, filters, sortObject, controller.current))
      .catch((error) => {
        logError(error.message);
        // Don't display error message if request was canceled (via AbortController)
        if (!error.message?.includes('canceled')) {
          dispatch(
            triggerNotice({
              type: 'error',
              weight: 'bold',
              align: 'center',
              text: 'Uh oh, we had a problem searching tasks. Try refreshing the page.'
            })
          );
        }
      });
    // Cleanup to abort previous async requests
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    };
  }, [id, page, limit, forceNew, dispatch, filtersKey, sortKey]);

  function dispatchTasksCsvByUserId () {
    return dispatch(
      fetchTasksCsvByUserId(id, filters, sortObject)
    ).catch((error) => {
      logError(error.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Trey refreshing the page.'
        })
      );
    });
  }

  return {
    ...actions.searchTasksByUserId,
    tasks,
    dispatchTasksCsvByUserId
  };
}
