import { useMappedState } from 'redux-react-hook';
import User from '../models/user';

export default function useUser () {
  const mapState = ({ user }) => user;

  const { actions = {}, pageSession, user = {} } = useMappedState(mapState);

  return {
    actions,
    pageSession,
    user: new User(user)
  };
}
