import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useReseller } from '../hooks';

import { logError } from '../lib/logger';
import { errorMessageByCode } from '../lib/errors';
import { triggerNotice, fetchUsersByResellerId } from '../state/actions';

export default function useResellerFetchUsers ({ resellerId } = {}) {
  const dispatch = useDispatch();
  const { users = [], actions = {} } = useReseller();

  /**
   * fetchUsers
   */

  function resellerFetchUsers () {
    dispatch(fetchUsersByResellerId(resellerId)).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedResellerFetchUsers = useCallback(() => {
    resellerFetchUsers();
  }, [resellerId]);

  useEffect(() => {
    memoizedResellerFetchUsers();
  }, [memoizedResellerFetchUsers]);

  return {
    resellerFetchUsers: memoizedResellerFetchUsers,
    ...actions.fetchUsersByResellerId,
    users
  };
}
