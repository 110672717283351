import React from 'react';
import { PageTemplate } from 'fogg/ui';
import { FaUser } from 'react-icons/fa';

import { ORGANIZATION_ROUTES } from 'data/organization';

const OrganizationProfilePage = () => {
  return (
    <PageTemplate
      id="organizationProfile"
      title="Organization Profile"
      navigation={ORGANIZATION_ROUTES}
      icon={<FaUser />}
    >
      <div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed
        magna ultrices, aliquam elit quis, feugiat nibh. Curabitur efficitur
        nisl pharetra, blandit nunc sit amet, hendrerit lorem. Nullam luctus
        tempor consequat. Pellentesque a bibendum lorem, eget iaculis tortor.
        Suspendisse potenti.
      </div>
    </PageTemplate>
  );
};

export default OrganizationProfilePage;
