import { useItems, useFetchCollections } from 'hooks';

export default function useFetchCollection ({ collectionId } = {}) {
  const { fetchAllCollections, collections } = useFetchCollections();
  const { actions = {} } = useItems();

  const collection = collections.find(({ id }) => id === collectionId);

  return {
    fetchAllCollections,
    ...actions.fetchAllCollections,
    collection
  };
}
