import React from 'react';
import PropTypes from 'prop-types';

import {
  TemplateCollection,
  routePathByName,
  useFetchCollection,
  ADMIN_ROLE
} from 'commonLib';

const AdminCollectionPage = ({ collectionId }) => {
  const { collection, isLoading: collectionIsLoading } = useFetchCollection({
    collectionId
  });

  const breadcrumbs = [
    {
      label: 'Admin',
      to: routePathByName('admin')
    },
    {
      label: 'Collections',
      to: routePathByName('adminCollections')
    }
  ];

  const collectionState = {
    isLoading: collectionIsLoading
  };

  return (
    <TemplateCollection
      collection={
        collection || {
          id: collectionId
        }
      }
      accessType={ADMIN_ROLE}
      breadcrumbs={breadcrumbs}
      state={collectionState}
    />
  );
};

AdminCollectionPage.propTypes = {
  collectionId: PropTypes.string
};

export default AdminCollectionPage;
