import { useEffect, useState } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAois } from 'hooks';

import { logError } from 'lib/logger';
import { fetchAOIs, triggerNotice } from 'state/actions';

/**
 * Hook for fetching the list of aois and tracking the loading state
 * @param {object} props
 * @param {boolean} [props.forceNew = false] Flag to manually trigger initial fetch
 * @returns {object} containing actions state & array of fetched aois
 */
export default function useFetchAois ({ forceNew = true } = {}) {
  const dispatch = useDispatch();
  const [triggerFetch, setTriggerFetch] = useState(forceNew);
  const [searchTerm, setSearchTerm] = useState('');

  const { aois = [], actions = {} } = useAois();

  useEffect(() => {
    if (triggerFetch) {
      dispatch(fetchAOIs(searchTerm)).catch((error) => {
        logError(error.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'We had an issue fetching your AOIs. Try refreshing the page.'
          })
        );
      });
    }
  }, [triggerFetch, searchTerm, dispatch]);

  function triggerFetchAOIs (searchFor) {
    setSearchTerm(searchFor);
    setTriggerFetch(true);
  }

  return {
    ...actions.fetchAOIs,
    aois,
    triggerFetchAOIs
  };
}
