import { ACCESS_ROLES, TASKER, ARCHIVE_BUYER, USER_ROLE } from '../data';

const TASKING_ROLES = [TASKER, USER_ROLE];
const ARCHIVE_BUYING_ROLES = [ARCHIVE_BUYER, USER_ROLE];

/**
 * Determines whether a user with the provided roles should have access to creating tasking/repeat requests.
 *
 * @param {Array<string>} userRoles The roles granted to the user
 * @param {Map<string, boolean>} flags The feature flags key value pairs
 * @returns {boolean} True if the user has Tasking access
 */
export function userHasTaskingAccess (userRoles = [], flags = {}) {
  const taskingEnabledRoles = ACCESS_ROLES.filter(r => TASKING_ROLES.includes(r.id));
  return hasAccess(userRoles, taskingEnabledRoles, flags)
}

/**
 * Determines whether a user with the provided roles should have access to creating non-zero dollar orders.
 *
 * @param {Array<string>} userRoles The roles granted to the user
 * @param {Map<string, boolean>} flags The feature flags key value pairs
 * @returns {boolean} True if the user has Archive Buyer access
 */
export function userHasArchiveBuyerAccess (userRoles = [], flags = {}) {
  const buyingEnabledRoles = ACCESS_ROLES.filter(r => ARCHIVE_BUYING_ROLES.includes(r.id));
  return hasAccess(userRoles, buyingEnabledRoles, flags)
}

/**
 * Determines whether a user with the provided roles has access to the roles in question.
 *
 * @param {Array<string>} userRoles The roles granted to the user
 * @param {Array<string>} roleRestrictions The roles to check against the user roles
 * @param {Map<string, boolean>} flags The feature flags key value pairs
 * @returns {boolean} True if the user has access to the restricted roles
 */
function hasAccess(userRoles, roleRestrictions, flags) {
  return !!(
    Array.isArray(userRoles) &&
    userRoles.length > 0 &&
    roleRestrictions?.filter(r => userRoles.includes(r.id) &&
      (!r.globalEnabledFlag || flags[r.globalEnabledFlag.id] === r.globalEnabledFlag.value)).length > 0
  );
}

export function isEnabled(role, flags) {
  return role.globalEnabledFlag === undefined || flags[role.globalEnabledFlag.id] === role.globalEnabledFlag.value
}
