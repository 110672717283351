import React, { useState } from 'react';
import { Button } from 'fogg/ui';
import { logError } from 'lib/logger';
import { testOrgBucketConfig, triggerNotice } from '../state/actions';
import { FaSpinner } from 'react-icons/fa';
import { useDispatch } from 'redux-react-hook';


const S3BucketConfigTestConnectivity = ({
  orgId,
  s3BucketName,
  s3Prefix,
  isGovcloud,
  setTestAccessSuccess
}) => {
    const dispatch = useDispatch();
    const [fetchS3Push, setFetchS3Push] = useState(false);
  
    async function handlePushTest (e) {
      // prevent submit within form
      if (e) e.preventDefault();
      
      setFetchS3Push(true);
      
      try {
        await dispatch(testOrgBucketConfig(orgId, {
          bucketName: s3BucketName,
          prefix: s3Prefix,
          isGovcloud: isGovcloud
        }));
        
        dispatch(
          triggerNotice({
            type: 'success',
            weight: 'bold',
            align: 'center',
            text: 'Success! Your bucket is configured correctly.'
          })
        );
        setTestAccessSuccess(true);
      } catch (e) {
        logError(e);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: <span>Unable to establish connectivity. Please check your bucket configuration.</span>
          })
        );
        setTestAccessSuccess(false);
      } finally {
        setFetchS3Push(false);
      }
    }
  
  return (
    <>
      {fetchS3Push ? (
        <Button className='active s3-bucket-config-test-connectivity' full={true} disabled={true}>
          Testing Access <FaSpinner className="icon-spin s3-bucket-config-test-loading" id="loading" />
        </Button>
      ) : (
        <Button className='active s3-bucket-config-test-connectivity' full={true}
          onClick={handlePushTest}
        >
          Test Access
        </Button>
      )}
    </>
    );
};

S3BucketConfigTestConnectivity.propTypes = {};
  
export default S3BucketConfigTestConnectivity;