/**
 * type enumerators for collect modes
 */
// FUTURE adopt this everywhere for collectMode
export const TaskCollectModes = {
  SPOTLIGHT: 'spotlight',
  STRIPMAP: 'stripmap',
  SLIDING_SPOTLIGHT: 'sliding_spotlight',
  properties: {
    spotlight: {
      label: 'Spotlight'
    },
    stripmap: {
      label: 'Stripmap'
    },
    sliding_spotlight: {
      label: 'Sliding Spotlight'
    }
  }
};
