
import React from 'react';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { Badge, Button } from 'fogg/ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { useUser, useCart } from 'hooks';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';
import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from 'commonLib';

/**
 * Custom dropdown used to select "AddOn" items from the cart
 * @param {object} props
 * @param {object} props.item
 * @param {array} props.addOnItems
 * @param {number} [props.activeDropdown]
 * @param {func} [props.setActiveDropdown]
 * @param {func} [props.handleAddProduct]
 */
const AddProductDropdown = ({
  item = {},
  addOnItems,
  activeDropdown = undefined,
  setActiveDropdown,
  handleAddProduct
}) => {
  const { itemIndex, itemId } = item;
  const { user: { organization = {} } = {} } = useUser();
  const flags = useFlags();
  // For now, we only support adding VC or VS
  const analyticsOptions = AnalyticProducts.Types
    .filter(o => o.value === 'VC' || o.value === 'VS')
    .filter(o => o.orgEnabledFlagId === undefined || organization[o.orgEnabledFlagId])
    .filter(o => o.globalEnabledFlagId === undefined || flags[o.globalEnabledFlagId])
    .map(o => o.value);

  const { contract: cartContract = {} } = useCart();

  const isArchiveSubContract = organization.contracts?.find(({ id }) => id === cartContract.contractId)?.type ===
    CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;

  const addedToCollectItems = addOnItems.filter(i => i.collectId === itemId).map(i => i.productType);
  const availableAddOnItems = analyticsOptions.filter(
    o => !addedToCollectItems.includes(o)
  );

  return (
    <div className="dropdown-button-wrapper">
      {/* Add Product button, for now only "VS" and "VC" but eventually more */}
      <Button type="secondary"
        onClick={() => setActiveDropdown(activeDropdown !== itemIndex ? itemIndex : undefined)}>
        {activeDropdown !== itemIndex
          ? <span>Add Product <FaPlus /></span>
          : <span>Cancel <GrClose /></span>
        }
      </Button>
      {activeDropdown === itemIndex && (
        <div className="cart-addon-dropdown" key={itemIndex}>
          <small className="addon-note"><i>Additional charges may apply, per product added.</i></small>
          {/* For now only "VS" and "VC" supported, more analytics/product types in the future */}
          {availableAddOnItems.includes('VS') && !isArchiveSubContract && (
            <div className="cart-dropdown-item VS" onClick={() => handleAddProduct('VS', itemId)}>
              <Badge label='VS' type="info" />
              <strong>Vessel Detection</strong>
              <FaPlus />
            </div>
          )}
          {availableAddOnItems.includes('VC') && (
            <div className="cart-dropdown-item VC" onClick={() => handleAddProduct('VC', itemId)}>
              <Badge label='VC' type="info" />
              <strong>Vessel Classification</strong>
              <FaPlus />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AddProductDropdown.propTypes = {
  item: PropTypes.object.isRequired,
  addOnItems: PropTypes.array.isRequired,
  activeDropdown: PropTypes.number,
  setActiveDropdown: PropTypes.func,
  handleAddProduct: PropTypes.func
};

export default AddProductDropdown;
