import React from 'react';
import PropTypes from 'prop-types';

import TemplateContractDetails from 'commonLib/src/templates/TemplateContractDetails';

import { useOrgFetchOrganization } from 'hooks';
import { ORG_MANAGER_ROLE } from 'data/roles';
import { ROUTE_ORG, ROUTE_ORG_DETAILS } from 'data/route-names';
import { routePathByName } from 'lib/routes';

const OrganizationContractDetailsPage = ({ contractId }) => {
  const { organization = {}, isLoading } = useOrgFetchOrganization();

  const contract = organization.contracts?.find(
    (contract) => contract.id === contractId
  );

  const organizationState = {
    isLoading
  };

  const organizationDetailsPath = routePathByName(ROUTE_ORG_DETAILS);
  const organizationPath = routePathByName(ROUTE_ORG);
  // default breadcrumbs for contract details view
  const breadcrumbs = [
    {
      label: 'Organization',
      to: organizationPath
    },
    {
      label: 'Organization Details',
      to: organizationDetailsPath
    }
  ];

  return (
    <TemplateContractDetails
      accessType={ORG_MANAGER_ROLE}
      breadcrumbs={breadcrumbs}
      contract={contract}
      organization={organization}
      state={organizationState}
    />
  );
};

OrganizationContractDetailsPage.propTypes = {
  contractId: PropTypes.string,
  orderId: PropTypes.string,
  orgDetails: PropTypes.bool
};

export default OrganizationContractDetailsPage;
