export const USER_ROLE = 'user';
export const ARCHIVE_VIEWER = 'archive-viewer';
export const ARCHIVE_BUYER = 'archive-buyer';
export const TASKER = 'tasker';
export const ORG_MANAGER_ROLE = 'organization-manager';
export const ADMIN_ROLE = 'admin';
export const RESELLER_ROLE = 'reseller';
export const SUB_RESELLER_ROLE = 'sub-reseller';
export const ANALYTICS_USER_ROLE = 'analytics-user';
export const ANALYTICS_OPERATOR_ROLE = 'analytics-operator';
export const ANALYTICS_DEVELOPER_ROLE = 'analytics-developer';
export const ANALYTICS_ACCESS_ROLE = 'analytics-access';
export const ANALYTICS_INITIALIZED_ROLE = 'analytics-initialized';
export const UNAUTH_ROLE = 'unauth';
export const WILDCARD_ROLE = '*';

export const ACCESS_ROLES = [
  // List of A&E Access Roles
  // Note that the apiKey *must* be equal to A&Es matching role, as: `make` + camelCase(roleName)
  {
    id: USER_ROLE,
    label: 'User',
    apiKey: 'makeUser',
    // The user role is applied by default and should not be managed
    manageAccess: [],
    globalEnabledFlag: {
      id: 'fineGrainedUserRoles',
      value: false
    }
  },
  {
    id: ARCHIVE_VIEWER,
    label: 'Archive Viewer',
    apiKey: 'makeArchiveViewer',
    manageAccess: [RESELLER_ROLE, ORG_MANAGER_ROLE, ADMIN_ROLE],
    // The viewer role is applied by default and should not be managed
    updateDisabled: true,
    globalEnabledFlag: {
      id: 'fineGrainedUserRoles',
      value: true
    }
  },
  {
    id: ARCHIVE_BUYER,
    label: 'Archive Buyer',
    apiKey: 'makeArchiveBuyer',
    manageAccess: [RESELLER_ROLE, ORG_MANAGER_ROLE, ADMIN_ROLE],
    globalEnabledFlag: {
      id: 'fineGrainedUserRoles',
      value: true
    }
  },
  {
    id: TASKER,
    label: 'Tasker',
    apiKey: 'makeTasker',
    manageAccess: [RESELLER_ROLE, ORG_MANAGER_ROLE, ADMIN_ROLE],
    globalEnabledFlag: {
      id: 'fineGrainedUserRoles',
      value: true
    }
  },
  {
    id: ORG_MANAGER_ROLE,
    label: 'Organization Manager',
    apiKey: 'makeOrganizationManager',
    manageAccess: [ORG_MANAGER_ROLE, ADMIN_ROLE],
    warnOnCreate: true
  },
  {
    id: ADMIN_ROLE,
    label: 'Admin',
    apiKey: 'makeAdmin',
    manageAccess: [ADMIN_ROLE],
    excludedLocations: ['user-create'],
    warnOnCreate: true
  },
  {
    id: RESELLER_ROLE,
    label: 'Reseller',
    apiKey: 'makeReseller',
    manageAccess: [ADMIN_ROLE]
  },
  {
    id: SUB_RESELLER_ROLE,
    label: 'Sub Reseller',
    apiKey: 'makeSubReseller',
    manageAccess: [ADMIN_ROLE, RESELLER_ROLE]
  },
  {
    id: ANALYTICS_USER_ROLE,
    label: 'GCM User',
    apiKey: 'makeAnalyticsUser',
    manageAccess: [ADMIN_ROLE]
  },
  {
    id: ANALYTICS_OPERATOR_ROLE,
    label: 'GCM Operator',
    apiKey: 'makeAnalyticsOperator',
    manageAccess: [ADMIN_ROLE]
  },
  {
    id: ANALYTICS_DEVELOPER_ROLE,
    label: 'GCM Developer',
    apiKey: 'makeAnalyticsDeveloper',
    manageAccess: [ADMIN_ROLE]
  }
];
