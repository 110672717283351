import React from 'react';
import PropTypes from 'prop-types';

import TemplateOrder from 'templates/TemplateOrder';

import { useFetchOrder, useFetchOrderAssets } from 'hooks';
import { routePathByName } from 'lib/routes';

const organizationPath = routePathByName('subreseller');
const ordersPath = routePathByName('subresellerOrders');

const OrganizationOrderPage = ({ orderId }) => {
  const defaultOrder = {
    id: orderId
  };

  const {
    isLoading: fetchOrderIsLoading,
    order = defaultOrder
  } = useFetchOrder(orderId, true);

  const {
    isLoading: fetchAssetsIsLoading,
    items
  } = useFetchOrderAssets(orderId);

  const assets = {
    items
  };

  const isLoading =
    fetchOrderIsLoading || fetchAssetsIsLoading;

  const breadcrumbs = [
    {
      label: 'Sub-Reseller',
      to: organizationPath
    },
    {
      label: 'Orders',
      to: ordersPath
    }
  ];

  const orderState = {
    isLoading
  };

  return (
    <TemplateOrder
      order={order}
      assets={assets}
      breadcrumbs={breadcrumbs}
      state={orderState}
    />
  );
};

OrganizationOrderPage.propTypes = {
  orderId: PropTypes.string
};

export default OrganizationOrderPage;
