export const ITEM_PROPERTIES = [
  {
    id: 'sar:constellation',
    label: 'SAR Constellation'
  },
  {
    id: 'instruments',
    label: 'Instruments'
  },
  {
    id: 'sar:frequency_band',
    label: 'SAR Frequency Band'
  },
  {
    id: 'sar:polarizations',
    label: 'Polarization'
  },
  {
    id: 'sar:center_wavelength',
    label: 'SAR Center Wavelength'
  },
  {
    id: 'sar:center_frequency',
    label: 'SAR Center Frequency',
    type: 'gigahertz'
  },
  {
    id: 'sar:resolution_range',
    label: 'Resolution - Slant Range',
    type: 'meters'
  },
  {
    id: 'sar:resolution_azimuth',
    label: 'Resolution - Azimuth',
    type: 'meters'
  },
  {
    id: 'sar:pixel_spacing_range',
    label: 'Pixel Spacing - Range',
    type: 'meters'
  },
  {
    id: 'sar:pixel_spacing_azimuth',
    label: 'Pixel Spacing - Azimuth',
    type: 'meters'
  },
  {
    id: 'collection',
    label: 'Collection'
  },
  {
    id: 'datetime',
    label: 'Datetime (UTC)',
    type: 'datetime'
  },
  {
    id: 'locale:datetime',
    label: 'Local Datetime',
    type: 'datetime'
  },
  {
    id: 'locale:timezone',
    label: 'Local Timezone'
  },
  {
    id: 'start_datetime',
    label: 'Start Datetime',
    type: 'datetime'
  },
  {
    id: 'end_datetime',
    label: 'End Datetime',
    type: 'datetime'
  },
  {
    id: 'platform',
    label: 'Platform'
  },
  {
    id: 'sar:instrument_mode',
    label: 'Imaging Mode'
  },
  {
    id: 'sar:product_type',
    label: 'Product Type'
  },
  {
    id: 'sar:absolute_orbit',
    label: 'SAR Absolute Orbit'
  },
  {
    id: 'sar:orbit_state',
    label: 'SAR Orbit State'
  },
  {
    id: 'sar:looks_range',
    label: 'Looks - Range'
  },
  {
    id: 'sar:looks_azimuth',
    label: 'Looks - Azimuth'
  },
  {
    id: 'view:incidence_angle',
    label: 'Incidence Angle',
    type: 'degrees'
  },
  {
    id: 'sar:relative_orbit',
    label: 'SAR Relative Orbit'
  },
  {
    id: 'constellation',
    label: 'Constellation'
  },
  {
    id: 'instrument',
    label: 'Instrument'
  },
  {
    id: 'sar:looks_equivalent_number',
    label: 'SAR Looks Equivalent Number'
  },
  {
    id: 'capella:squint_angle',
    label: 'Squint Angle',
    type: 'degrees'
  },
  {
    id: 'sar:observation_direction',
    label: 'Observation Direction'
  },
  {
    id: 'capella:collect_id',
    label: 'Collect Id'
  },
  {
    id: 'capella:image_length',
    label: 'Image Length',
    type: 'meters'
  },
  {
    id: 'capella:image_width',
    label: 'Image Width',
    type: 'meters'
  },
  {
    id: 'sat:orbit_state',
    label: 'Orbit State'
  },
  {
    id: 'sar:type',
    label: 'SAR Type'
  },
  {
    id: 'sar:pass_direction',
    label: 'SAR Pass Direction'
  },
  {
    id: 'sar:incidence_angle',
    label: 'SAR Incidence Angle',
    type: 'degrees'
  },
  {
    id: 'created',
    label: 'Date Created',
    type: 'datetime'
  },
  {
    id: 'updated',
    label: 'Date Updated',
    type: 'datetime'
  },
  {
    id: 'capella:billable_area',
    label: 'Billable Area'
  },
  {
    id: 'capella:product_category',
    label: 'Product Category'
  },
  {
    id: 'view:look_angle',
    label: 'Look Angle',
    type: 'degrees'
  },
  {
    id: 'capella:archive_holdback_date',
    label: 'Archive Holdback Date',
    type: 'datetime'
  },
  {
    id: 'capella:orbital_plane',
    label: 'Orbital Plane',
    type: 'degrees'
  },
  {
    id: 'capella:resolution_ground_range',
    label: 'Resolution - Ground Range',
    type: 'meters'
  },
  {
    id: 'capella:look_angle_delta',
    label: 'Look Angle Delta',
    type: 'degrees'
  },
  {
    id: 'capella:image_formation_algorithm',
    label: 'Image Formation Algorithm'
  }
];
