import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({
  children,
  title,
  icon,
  subtitle,
  className = 'page-header'
}) => {
  return (
    <div className={className}>
      <div className="page-header-content">
        <h1>
          {icon && (
            <div className="header-icons">
              {icon?.length ? icon.map(i => i) : icon}
            </div>
          )}
          {title || <>&nbsp;</>}
        </h1>
        {subtitle && <span className="page-header-subtitle">{subtitle}</span>}
      </div>
      {children && <div className="page-header-extras">{children}</div>}
    </div>
  );
};

PageHeader.propTypes = {
  children: PropTypes.any,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  subtitle: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string
};

export default PageHeader;
