import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { logError } from 'lib/logger';
import { useAoi } from 'hooks';
import { fetchAOI, triggerNotice } from 'state/actions';

/**
 * Hook for fetching a specific AOI, and tracking the loading state of that fetch
 * @param {object} props
 * @param {boolean} [props.forceNew = false] Flag to manually trigger re-fetch
 * @param {string} props.id ID of AOI to fetch
 * @returns {object} actions state & fetch aoi
 */
export default function useFetchAoi ({ forceNew = false, id = undefined } = {}) {
  const dispatch = useDispatch();

  const { aoi, actions = {} } = useAoi(id);

  useEffect(() => {
    dispatch(fetchAOI(id)).catch((error) => {
      logError(error.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'We had an issue fetching your AOI. Try refreshing the page.'
        })
      );
    });
  }, [id, forceNew, dispatch]);

  return {
    ...actions.fetchAOI,
    aoi
  };
}
