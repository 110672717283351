import { useReseller } from 'hooks';

/**
 * useResellerUsers
 * @description Returns the users that are within a Reseller's organization
 * @note This does NOT return users in the Reseller's child organizations
 */

export default function useResellerUsers () {
  const { organization = {} } = useReseller();
  const { users = [] } = organization;

  return {
    users
  };
}
