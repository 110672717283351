import React from 'react';
import PropTypes from 'prop-types';
import { StatusIndicator } from 'fogg/ui';
import { RepeatRequestEndTypes } from 'data/repeat-request-end-types';
import { RepeatCycleTypes } from 'data/repeat-cycle-types';
import { formatDateTime } from 'lib/datetime';
import { TASK_STATUS_PROCESS_CHAIN, TASK_ERROR_LIST } from 'lib/tasks';
import { formatInfinityToString } from 'lib/currency';

const RepeatTaskStatus = ({ repeatRequest = {}, windowOnly = false }) => {
  const {
    status,
    statusMessage,
    repeatRequestEndType,
    totalTasksToRun,
    repetitionProperties: {
      repeatStart,
      repeatEnd,
      repetitionCount,
      repeatCycle,
      repetitionInterval
    } = {}
  } = repeatRequest;

  return (
    <>
      <div className="repeat-task-status">
        <div className="task-status-window">
          <h4>Repeat Task Start (UTC)</h4>
          <p className="task-status-date">{formatDateTime(repeatStart)}</p>
        </div>

        {!windowOnly && (
          <div className="task-status-indicator">
            <StatusIndicator
              activeId={status}
              statusList={TASK_STATUS_PROCESS_CHAIN}
              errorList={TASK_ERROR_LIST}
            />
            <p className="task-status-indicator-message">{statusMessage}</p>
          </div>
        )}

        <div className="task-status-window">
          <h4>Repeat Task End {repeatRequestEndType === RepeatRequestEndTypes.DATE ? '(UTC)' : ''}</h4>
          {repeatRequestEndType === RepeatRequestEndTypes.INDEFINITE && (
            <p className="task-status-text">Until Canceled</p>
          )}
          {repeatRequestEndType === RepeatRequestEndTypes.DATE && (
            <p className="task-status-date">{formatDateTime(repeatEnd)}</p>
          )}
          {repeatRequestEndType === RepeatRequestEndTypes.COUNT && (
            <p className="task-status-text">After {repetitionCount} Collects</p>
          )}
        </div>
      </div>

      {windowOnly && (
        <>
          <div className="repeat-task-status">
            <div className="task-status-window">
              <h4>Repeat Cycle</h4>
              <p className="task-status-text">
                {repeatCycle === RepeatCycleTypes.OTHER
                  ? `${repetitionInterval} Days`
                  : RepeatCycleTypes.properties[repeatCycle].label}
              </p>
            </div>

            <div className="task-status-window">
              <h4>Estimated Number of Collects</h4>
              <p className="task-status-text">{formatInfinityToString({ number: totalTasksToRun })}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

RepeatTaskStatus.propTypes = {
  repeatRequest: PropTypes.object,
  windowOnly: PropTypes.bool
};

export default RepeatTaskStatus;
