import clone from 'clone';
import deepmerge from 'deepmerge';
import { REPEAT_REQUEST_ID } from 'data/property-names';
import { ROUTE_REPEAT_REQUEST_CHILD_TASK } from 'data/route-names';
import { overwriteMerge, relatedTasksToTableData } from './tasks';

/**
 * updates a single repeat request
 * @param {object} [repeatRequest={}] - previous state
 * @param {object} [data={}] - next state
 * @returns {object}
 */
export function updateRepeatRequestData (repeatRequest = {}, data) {
  // Overwrite the arrays instead of default, which concats the values
  return deepmerge(repeatRequest, data || {}, {
    arrayMerge: overwriteMerge
  });
}

/**
 * updates array of repeat requests
 * @param {array} [currentRepeatRequests=[]] - previous state
 * @param {array} [updatedRepeatRequests=[]] - next state
 * @returns {array}
 */
export function updateRepeatRequestsData (
  currentRepeatRequests = [],
  updatedRepeatRequests = []
) {
  const newRepeatRequests = clone(currentRepeatRequests);

  updatedRepeatRequests.forEach((repeatRequest = {}) => {
    const { properties = {} } = repeatRequest;
    const repeatRequestId = properties[REPEAT_REQUEST_ID];

    const index = newRepeatRequests.findIndex(
      ({ properties: newRepeatRequestProperties = {} }) => {
        return (
          newRepeatRequestProperties[REPEAT_REQUEST_ID] === repeatRequestId
        );
      }
    );

    // If we found an existing repeat request, update it with new data
    if (index !== -1) {
      newRepeatRequests[index] = updateRepeatRequestData(
        newRepeatRequests[index],
        repeatRequest
      );
    } else {
      newRepeatRequests.push(repeatRequest);
    }
  });

  return newRepeatRequests;
}

/**
 * maps child tasks of a repeatRequest to a data table
 * @param {array} [tasks=[]] - array of tasks to map to dataTable rows
 * @param {object} [options={}] - optional options for task data row
 * @returns {array}
 */
export function childTasksToTableData (tasks = [], options = {}) {
  return relatedTasksToTableData(tasks, {
    ...options,
    detailsRouteName: options?.detailsRouteName || ROUTE_REPEAT_REQUEST_CHILD_TASK,
    routeWildcards: ['repeatRequestId', 'id']
  });
}
