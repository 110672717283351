import L, { geoJson as LeafletGeoJson, Circle, Rectangle } from 'leaflet';
import { GEO_SUBTYPES } from '../data/aois';

/**
 * hook for adding geoJson AOIs as layers to the leaflet map, equipped with events, editing, and more
 *
 * @param {object} param0
 * @param {React.MutableRefObject<L.FeatureGroup>} param0.featureRef ref for the leaflet feature group
 */
function useAoiMapLayers ({
  featureRef
} = {}) {
  /**
   * convenient function to programmatically enable editing of a layer on a leaflet-editable enabled map
   *
   * @param {L.Layer} layer
   * @param {Function} onEditing
   * @returns {void}
   */
  function enableEditForLayer (layer, onEditing) {
    layer.enableEdit();
    if (typeof onEditing === 'function') {
      layer.on('editable:editing', onEditing);
    }
  }

  /**
   * returns true if the geoJson object arg includes valid centroid and radius properties
   *
   * @param {object} aoiGeoFeature geoJson object
   * @returns {boolean}
   */
  function aoiIsCircle (aoiGeoFeature) {
    // ensure centroid is defined, and has point coordinates
    const hasCentroid = aoiGeoFeature?.properties?.centroid?.coordinates?.length === 2;

    // ensure radius is defined, and a positive number
    let hasRadius = false;
    if (aoiGeoFeature?.properties?.radius) {
      const numRadius = Number(aoiGeoFeature?.properties?.radius);
      if (!Number.isNaN(numRadius)) {
        if (numRadius > 0) {
          hasRadius = true;
        }
      }
    }
    return hasCentroid && hasRadius;
  }

  /**
   * returns true if the geoJson object arg has the extended `subType` property and it equals 'Rectangle'
   *
   * @param {object} aoiGeoFeature
   * @returns {boolean}
   */
  function aoiIsRectangle (aoiGeoFeature) {
    return aoiGeoFeature?.properties?.subType === GEO_SUBTYPES.RECTANGLE;
  }

  function coordsToLatLng (coords) {
    return new L.LatLng(coords[1], coords[0], coords[2]);
  }

  /**
   * @param {object} aoiGeoFeature geoJson object
   * @param {object} [layerOptions={}] optional configuration for the newly added layer
   * @param {boolean} [makeEditable=false] makes the added layer editable
   * @param {Function} [editableHandler] callback function to handle editable:editing event
   * @returns {L.Layer} returns the last added layer from the FeatureGroup
   * @throws
   */
  function addLayerToFeatureGroup (aoiGeoFeature, {
    afterLayerAdded,
    makeEditable = false,
    editableHandler
  } = {}) {
    const { leafletElement } = featureRef?.current || {};
    if (!leafletElement) {
      throw new Error('Feature group ref not defined.');
    }
    const addList = [];

    const feature = LeafletGeoJson(aoiGeoFeature, {
      onEachFeature: (_feature, layer) => {
        let addLayer = layer;
        // layer transformations happen here if needed
        if (aoiIsCircle(_feature)) {
          addLayer = new Circle(coordsToLatLng(_feature.properties.centroid.coordinates), +`${_feature.properties.radius}`);
        } else if (aoiIsRectangle(_feature)) {
          addLayer = new Rectangle(layer.getBounds());
        }
        addList.push(addLayer);
      }
    });

    let layerToAdd = feature.getLayers()[0];
    if (addList.length) {
      // add in the layers from onEachFeature
      addList.forEach((layer) => {
        layerToAdd = layer;
      });
    }
    leafletElement.addLayer(layerToAdd);

    if (makeEditable === true) {
      enableEditForLayer(layerToAdd, editableHandler);
    }
    if (typeof afterLayerAdded === 'function') {
      afterLayerAdded(layerToAdd);
    }
    return layerToAdd;
  }

  return {
    addLayerToFeatureGroup,
    aoiIsCircle,
    aoiIsRectangle,
    enableEditForLayer
  };
}

export default useAoiMapLayers;
