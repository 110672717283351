import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, FormInput } from 'fogg/ui';

/**
 * Contracts select UI
 * @param  {object} props - all properties for the component
 * @param  {array} [contracts=[]] - array of contracts as select options, e.g. [{ label, value}]
 * @param  {bool} [defaultValue] - current selected value (contractId)
 * @param  {string} [errorMessage='Please select a contract to apply this order to.'] - message to display when isValid = false
 * @param  {string} [formRowClassName='contracts-select-row push-bottom-twohalf'] - class name for the FormRow component
 * @param  {string} [id='contracts'] - id of the FormInput component
 * @param  {bool} [isValid=true] - used to provide a helpful validation message
 * @param  {string} [label='Billing Contract'] - label for FormInput
 * @param  {function} onChange - required elevated state function for handling change
 * @param  {bool} disable - set to true to not display the component
 * @param  {bool} [required=true] - selection required or not
 * @param  {string}[ wrapperClassName='contracts-wrapper'] - className for the outer div wrapper element
 * @param  {string} selectedValue - selected contract
 * @param  {function} setSelectedValue - function to set the selected value
 */
const ContractsSelect = ({
  contracts = [],
  errorMessage = 'Please select a contract to apply this order to.',
  formRowClassName = 'contracts-select-row push-bottom-twohalf',
  id = 'contracts',
  isValid = true,
  label = 'Contract',
  onChange,
  disable = false,
  required = true,
  wrapperClassName = 'contracts-wrapper',
  selectedValue,
  setSelectedValue
}) => {
  /**
   * on change handler
   */
  function handleContractChange ({ value }) {
    onChange(value);
  }

  if (disable) return null;

  return (
    <div className={wrapperClassName}>
      <h2>{label}</h2>
      <FormRow className={formRowClassName}>
        <FormInput
          id={id}
          type="select"
          clearable={false}
          defaultValue={selectedValue}
          key={selectedValue}
          options={contracts}
          onChange={handleContractChange}
          required={required}
          validationMessage={errorMessage}
          disabled={contracts.length < 2}
        />
        {!isValid && (
          <p className="error">
            {errorMessage}
          </p>
        )}
      </FormRow>
    </div>
  );
};

ContractsSelect.propTypes = {
  contracts: PropTypes.array,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  formRowClassName: PropTypes.string,
  id: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disable: PropTypes.bool,
  required: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  selectedValue: PropTypes.string,
  setSelectedValue: PropTypes.func
};

export default ContractsSelect;
