import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBriefcase } from 'react-icons/fa';

import { routePathByName, redirectTo } from 'lib/routes';
import { logError } from 'lib/logger';
import {
  addOrganizationContractPeriod,
  triggerSuccess,
  triggerError
} from 'state/actions';
import { useSubResellerFetchOrganization } from 'hooks';

import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from 'commonLib/src/data/contracts';

import Layout from 'components/Layout';

import {
  Breadcrumbs,
  PageHeader,
  Container,
  FormContractPeriodAdd
} from 'commonLib';

const PAGE_TITLE = 'Add Contract Period';

const SubResellerOrganizationsContractPeriodAddPage = ({
  organizationId,
  contractId
}) => {
  const dispatch = useDispatch();

  const { organization = {}, isLoading } = useSubResellerFetchOrganization({
    id: organizationId
  });

  const contract = organization?.contracts?.find(contract => contract.id === contractId);
  const isArchiveSub = contract?.type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;

  const organizationDetailsPath = routePathByName(
    'subresellerOrganizationsDetails',
    {
      wildcard: organizationId
    }
  );
  const contractDetailsPath = routePathByName(
    'subresellerOrganizationContractDetails',
    {
      wildcard: [organizationId, contractId]
    }
  );

  function handleOnSubmit ({ data }) {
    dispatch(addOrganizationContractPeriod(organizationId, contractId, data))
      .then(() => {
        redirectTo(contractDetailsPath);
        dispatch(triggerSuccess('Successfully added a new contract period!'));
      })
      .catch((error) => {
        logError(error.message, data);
        dispatch(triggerError());
      });
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-subreseller page-subreseller-contract page-subreseller-contract-edit'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Sub-Reseller',
              to: routePathByName('subreseller')
            },
            {
              label: 'Organizations',
              to: routePathByName('subresellerOrganizations')
            },
            {
              label: 'Organization',
              to: organizationDetailsPath
            },
            {
              label: 'Contract',
              to: contractDetailsPath
            }
          ]}
        />
        <PageHeader title={PAGE_TITLE} icon={<FaBriefcase />} />
        <FormContractPeriodAdd
          disabled={isLoading}
          isArchiveSub={isArchiveSub}
          onSubmit={handleOnSubmit}
        />
      </Container>
    </Layout>
  );
};

SubResellerOrganizationsContractPeriodAddPage.propTypes = {
  organizationId: PropTypes.string,
  contractId: PropTypes.string
};

export default SubResellerOrganizationsContractPeriodAddPage;
