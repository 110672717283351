import React from 'react';
import { useFetchContracts, useContracts } from 'hooks';
import { routePathByName } from 'lib/routes';
import { ROUTE_ORG } from 'data/route-names';
import TemplateContracts from 'commonLib/src/templates/TemplateContracts';

const organizationPath = routePathByName(ROUTE_ORG);

const OrganizationContractsPage = () => {
  const { contracts = [] } = useContracts();
  const { isLoading: isContractsLoading } = useFetchContracts({ forceNew: true });

  const contractsState = {
    isLoading: isContractsLoading
  };
  const breadcrumbs = [
    {
      label: 'Organization',
      to: organizationPath
    }
  ];

  return (
    <TemplateContracts
      state={contractsState}
      contracts={contracts}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default OrganizationContractsPage;
