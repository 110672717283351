import { useTokenCookie, useUser } from '../hooks';
import { ANALYTICS_ACCESS_ROLE } from '../data/roles';
import { accessByPath } from '../lib/routes';

export default function useAccessRights ({ path } = {}) {
  const { user = {} } = useUser();
  const { roles = [], organization = {} } = user;
  const pathAccess = accessByPath(path);
  let isAuthorized = false;

  const { refresh, expiration } = useTokenCookie();
  // Check if Org has Analytics Access OR if ACD (amplitude change) is enabled
  const { roles: orgRoles = [], amplitudeChangeDetectionEnabled } = organization || {};
  const orgHasAnalyticsAccess = user?.id && 
    (orgRoles?.includes(ANALYTICS_ACCESS_ROLE) || amplitudeChangeDetectionEnabled);

  const userRoles = [...roles, '*'];

  // The unauth route access is intended to be used for routes that it's expected that
  // ONLY unauth users would hit it, whereas, * routes ANYONE can hit it. If we can't
  // find a user's ID, expect that they're unauth, and tag their roles with it

  if (!user.id) userRoles.push('unauth');

  if (Array.isArray(pathAccess)) {
    const permittedRoles = pathAccess.filter(access =>
      userRoles.includes(access)
    );
    isAuthorized = permittedRoles.length > 0;
  }

  return {
    isLoggedIn: !!user.id,
    isAuthorized,
    orgHasAnalyticsAccess,
    hasToken: !!refresh && !!expiration
  };
}
