import { navigate } from 'gatsby';
import picomatch from 'picomatch-browser';
import { device } from 'fogg/lib';

import routes from 'data/routes';
import { addParamsToUrl } from 'lib/util';

const { isDomAvailable } = device;

/**
 * getRouteState
 * @description Default state for navigation routing
 */

export function getRouteState () {
  if (!isDomAvailable()) return {};
  const { location = {} } = window;
  const { pathname, search } = location;
  return {
    prevPath: normalizePathname(pathname),
    prevSearch: search
  };
}

/**
 * navigateTo
 * @description Wraps Gatsby navigate and patches previous path to state
 */

export function navigateTo (to, state) {
  if (!isDomAvailable()) return;

  return navigate(to, {
    state: {
      ...getRouteState(),
      ...state
    }
  });
}

/**
 * promiseToNavigate
 * @description Returns a promise that resolves once the path matches the window location
 */

export function promiseToNavigateTo (
  to,
  state,
  { maxCount = 10, timeout = 100 } = {}
) {
  let count = 0;
  const normalTo = normalizePathname(to);
  const normalToPath = normalTo.split('?')[0];

  return new Promise((resolve, reject) => {
    if (!isDomAvailable()) {
      reject(new Error('DOM not available'));
      return;
    }

    const navigateReturn = navigateTo(to, state);

    function checkPathTimeout () {
      const normalPathname = normalizePathname(window.location.pathname);

      if (count >= maxCount) {
        reject(new Error(`Exceeded max timeout count: ${maxCount}`));
        return;
      }

      setTimeout(() => {
        if (normalToPath === normalPathname) {
          resolve(navigateReturn);
        } else {
          checkPathTimeout();
        }
      }, timeout);

      count++;
    }

    checkPathTimeout();
  });
}

/**
 * redirectTo
 * @description Wraps a navigate call with redirect specific state
 */

export function redirectTo (to, state) {
  return navigateTo(to, {
    isRedirect: true,
    ...state
  });
}

/**
 * normalizePathname
 * @description
 */

export function normalizePathname (string) {
  let pathname = string;

  if (typeof pathname !== 'string') return pathname;

  if (pathname.substr(-1) !== '/') {
    pathname = `${pathname}/`;
  }

  return pathname;
}

/**
 * accessByPath
 * @description Returns the required access for a particular path
 */

export function accessByPath (path) {
  if (typeof path !== 'string') return [];

  const currentRoute = routes.find((route) => {
    if (typeof route.path !== 'string') return false;
    // We needed to move from micromatch to picomatch, which has a dependency on node that webpack bundlers don't like.
    // To address this, we can use a forked, browser-only version of picomatch (picomatch-browser).
    // https://github.com/micromatch/micromatch/issues/175 - Issue on micromatch
    // https://github.com/micromatch/picomatch/pull/23#issuecomment-1038995069 - Comment on picomatch leading me to the browser-only forked version
    return picomatch.isMatch(path, route.path);
  });

  const { access } = currentRoute || {};

  return access;
}

/**
 * resolveWildcardPath
 * @description Makes changes to path to swap wildcard values
 */

export function resolveWildcardPath (path, values = []) {
  if (typeof path !== 'string') return path;

  const replacementValues = Array.isArray(values) && [...values];

  if (!Array.isArray(replacementValues)) return path;

  const split = path.split('/');

  return split
    .map((chunk) => {
      if (chunk !== '*') return chunk;
      const nextValue = replacementValues.shift();
      if (nextValue === undefined) {
        return '*';
      }
      return nextValue;
    })
    .filter((value) => value !== '*')
    .join('/');
}

/**
 * routePathByName
 * @description Returns a route by it's friendly name
 */

export function routePathByName (name, { wildcard = [], params } = {}) {
  const route = routes.find((route) => route.name === name);
  if (name && !route) throw new Error(`unable to find a path by name ${name}`);

  let { path } = route || {};

  if (!Array.isArray(wildcard) && typeof wildcard === 'string') {
    wildcard = [wildcard];
  }

  if (Array.isArray(wildcard)) {
    path = resolveWildcardPath(path, wildcard);
  }

  if (params) {
    path = addParamsToUrl(path, params);
  }

  return path;
}

/**
 * canRedirectToAfterAuth
 * @description Determines if a path can be redirected to after log in.
 */

export function canRedirectToAfterAuth (path) {
  const cannotRedirectTo = [
    routePathByName('userLogout'),
    routePathByName('userPasswordReset'),
    routePathByName('userRegister'),
    routePathByName('userAuthorize')
  ];

  return !cannotRedirectTo.includes(path);
}

/**
 * isPublicPath
 * @description Is it a public path?
 */

export function isPublicPath (path) {
  const access = accessByPath(path);
  return access.includes('*');
}

/**
 * isUnauthPath
 * @description Can an unauth user hit the path?
 */

export function isUnauthPath (path) {
  const access = accessByPath(path);
  return isPublicPath(path) || access.includes('unauth');
}

/**
 * Chains multiple pathnames/routes with ?next= query parameter
 * @param {array} routes=[] array of pathnames to chain together via query params
 * @returns {string} formatted url route/pathname
 */
export function chainRoutes (routes) {
  return routes
    .slice() // clone routes
    .reverse()
    .reduce((prev, cur, i) =>
      i === 0 ? cur : cur + '?next=' + encodeURIComponent(prev)
    );
}

/**
 * Construct url for logging in and redirecting based on a number of optional params
 * @param {object} props={}
 * @param {string} [props.redirectConsole] which app to redirect user to after platform login?
 * @param {string} [props.redirectAnalytics] is the user's session has expired?
 * @param {bool} [props.sessionExpired=false]
 * @returns {string} formatted url route/pathname
 */
export function constructRedirectUrl ({
  baseUrl,
  redirectConsole,
  redirectAnalytics,
  sessionExpired = false
}) {
  let url = baseUrl;

  // Construct logout url with optional query params
  if (redirectConsole) {
    url += `${url.includes('?') ? '&' : '?'}redirectConsole=${encodeURIComponent(redirectConsole)}`;
  } else if (redirectAnalytics) {
    url += `${url.includes('?') ? '&' : '?'}redirectAnalytics=${encodeURIComponent(redirectAnalytics)}`;
  }

  if (sessionExpired) {
    url += `${url.includes('?') ? '&' : '?'}sessionExpired=true`;
  }

  return url;
}

/**
 * Chains multiple pathnames/routes with ?redirectAnalytics= or ?redirectConsole=
 * query param to redirect user to the desired app and page after login
 * @param {array} routes=[] array of pathnames to chain together via query params
 * @param {string} [app='Console'] which app to redirect user to after platform login?
 * @param {bool} [sessionExpired=false] is the user's session has expired?
 * @returns {string} formatted url route/pathname
 */
export function chainPlatformRoutes (routes, app = 'Console', sessionExpired = false) {
  let url = routes
    .slice() // clone routes
    .reverse()
    .reduce((prev, cur, i) =>
      i === 0 ? cur : cur + `?redirect${app}=` + encodeURIComponent(prev)
    );

  if (sessionExpired) {
    url += url.includes('?')
      ? '&sessionExpired=true'
      : '?sessionExpired=true';
  }
  return url;
}
