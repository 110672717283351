import React from 'react';
import { useDispatch } from 'redux-react-hook';

import {
  resellerPostNewUser,
  triggerNotice,
  triggerErrorByCode
} from 'state/actions';
import { redirectTo, routePathByName } from 'lib/routes';
import { logError } from 'lib/logger';
import { useUser, useResellerFetchOrganization } from 'hooks';

import { TemplateUserCreate } from 'commonLib';

import { RESELLER_ROLE } from 'data/roles';

const ResellerUsersCreatePage = () => {
  const dispatch = useDispatch();
  const organizations = [];
  const { user = {} } = useUser();

  const { isLoading, organization = {} } = useResellerFetchOrganization({
    id: user.organizationId
  });

  if (organization) {
    // Add the user's org to org array for dropdown
    if (organization.name) {
      organizations.push(organization);
    }
    // As well as all child orgs
    const { childOrganizations } = organization;
    if (childOrganizations.length) {
      childOrganizations.forEach((org) => {
        organizations.push(org);
      });
    }
  }

  const breadcrumbs = [
    {
      label: 'Reseller',
      to: routePathByName('reseller')
    },
    {
      label: 'Users',
      to: routePathByName('resellerUsers')
    }
  ];

  const createState = {
    isLoading
  };

  /**
   * handleSubmitForm
   */

  function handleSubmitForm (data) {
    dispatch(resellerPostNewUser(data))
      .then(() => {
        redirectTo(routePathByName('resellerUsers'));
        dispatch(
          triggerNotice({
            type: 'success',
            weight: 'bold',
            align: 'center',
            text: 'User was successfully created!'
          })
        );
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        const { error = {} } = data;

        logError(e.message, data);

        dispatch(triggerErrorByCode(error.code));
      });
  }

  return (
    <TemplateUserCreate
      organizations={organizations}
      accessType={RESELLER_ROLE}
      breadcrumbs={breadcrumbs}
      onCreate={handleSubmitForm}
      state={createState}
    />
  );
};

export default ResellerUsersCreatePage;
