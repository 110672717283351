import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'hooks';
import { COOKIE_PATH, COOKIE_DOMAIN } from '../lib/storage';

export default function useTokenCookie () {
  const [token, setToken] = useState(undefined);
  const [refresh, setRefresh] = useState(undefined);
  const [expiration, setExpiration] = useState(undefined);
  const location = useLocation();

  // Set the cookies to local state
  useEffect(() => {
    setToken(Cookies.get('token'));
    setRefresh(Cookies.get('refreshToken'));
    setExpiration(Cookies.get('refreshExpiration'));
  }, [Cookies, location, token, refresh, expiration]);

  const isValidCookie = (token) => (typeof token === 'string' || typeof token === 'number') && token.length > 0;

  useEffect(() => {
    // Kill any token-related cookies that are invalid
    if (token && !isValidCookie(token)) {
      Cookies.remove('token', {
        path: COOKIE_PATH,
        domain: COOKIE_DOMAIN
      });
    }
    if (refresh && !isValidCookie(refresh)) {
      Cookies.remove('refreshToken', {
        path: COOKIE_PATH,
        domain: COOKIE_DOMAIN
      });
    }
    if (expiration && !isValidCookie(expiration)) {
      Cookies.remove('refreshExpiration', {
        path: COOKIE_PATH,
        domain: COOKIE_DOMAIN
      });
    }
  }, [Cookies, token, refresh, expiration, isValidCookie]);

  return { token, refresh, expiration };
}
