import React from 'react';
import { useDispatch } from 'redux-react-hook';
import { Helmet } from 'react-helmet';
import { MdPersonAdd } from 'react-icons/md';

import {
  createNewOrganization,
  triggerNotice,
  useAdmin,
  ADMIN_ROLE,
  fieldsObjectToValues,
  redirectTo,
  routePathByName,
  logError,
  Breadcrumbs,
  PageHeader,
  Container,
  FormCreateOrganization
} from 'commonLib';

import Layout from 'components/Layout';

const PAGE_TITLE = 'Create New Organization';

const AdminOrganizationCreatePage = () => {
  const dispatch = useDispatch();

  const { actions: adminActions = {} } = useAdmin();
  const { createNewOrganization: createAction = {} } = adminActions;
  const { isLoading: createIsLoading } = createAction;

  const isLoading = createIsLoading;

  /**
   * handleSubmitForm
   * @description Makes a request to create an org on form submit
   */

  async function handleSubmitForm (e, fields) {
    const organizationData = fieldsObjectToValues(fields);

    try {
      await dispatch(createNewOrganization(organizationData));
    } catch (e) {
      logError(e.message, organizationData);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try again!'
        })
      );
      return;
    }

    redirectTo(routePathByName('adminOrganizations'));

    dispatch(
      triggerNotice({
        type: 'success',
        weight: 'bold',
        align: 'center',
        text: 'Organization was successfully created!'
      })
    );
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-admin page-admin-organizations page-admin-organizations-create'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Admin',
              to: routePathByName('admin')
            },
            {
              label: 'Organizations',
              to: routePathByName('adminOrganizations')
            }
          ]}
        />
        <PageHeader
          title={PAGE_TITLE}
          icon={<MdPersonAdd className="icon-md" />}
        />
        <FormCreateOrganization
          onSubmit={handleSubmitForm}
          disabled={isLoading}
          formAccess={ADMIN_ROLE}
        />
      </Container>
    </Layout>
  );
};

export default AdminOrganizationCreatePage;
