import { exportClassData, updateClassData } from 'lib/util';

import Item from 'commonLib/src/models/item';

const UNKNOWN_AUTHORIZATION_ERROR = 'UNKNOWN_AUTHORIZATION_ERROR';

class Order {
  constructor (data) {
    this.id = data.orderId || data.id || null;
    this.status = data.orderStatus || data.status || null;
    this.userId = data.userId || null;
    this.orderDate = data.orderDate || null;
    this.expirationDate = data.expirationDate || null;
    this.contractId = data.contractId || null;
    this.contractLabel = data.contractLabel || null;
    this.items = [];
    this.addItems(data.items);
    this.totalCost = data.totalCost || null;
    this.orderDetails = data.orderDetails || undefined;
    this.authorized = data.authorized || false;
    this.authorizationDenialReason = data.authorizationDenialReason || {
      code: UNKNOWN_AUTHORIZATION_ERROR
    };
    this.costBreakdown = data.costBreakdown || null;
    this.parentOrderId = data.parentOrderId || null;
    this.task = data.task || undefined;
    this.addOnItems = data.addOnItems || [];
    this.userName = data.userName || '';
    this.organizationName = data.organizationName || '';
  }

  update (data = {}) {
    return updateClassData(this, data);
  }

  addItems (newItems = []) {
    const items = [...this.items, ...newItems];
    this.items = items.map((item) => {
      return item instanceof Item ? item : new Item(item);
    });
    /**
     * Traditionally we have an item ID (granuleId or STAC ID) for each order item.
     * But with archive ordering of analytics, we may not yet have one while "in-progress"
     * So generate a temporary one using the product type and item index
     */
    const ids = new Set(
      this.items.map(({ id, productType }, i) => id || `${productType}-${i}`)
    );
    this.items = Array.from(ids).map((id) => {
      return this.items.find(
        ({ id: itemId, productType }, i) =>
          itemId === id || id === `${productType}-${i}`
      );
    });
    return this.items;
  }

  data () {
    return exportClassData(this);
  }

  addOrderDetails ({
    orderDetails = {},
    authorized,
    authorizationDenialReason
  }) {
    this.authorized = authorized;
    this.authorizationDenialReason = authorizationDenialReason;
    this.orderDetails = {
      ...orderDetails
    };
    return this.data();
  }

  static mapUsersToOrders (orders, users) {
    return orders.map((order) => ({
      ...order,
      user: users.find(({ id } = {}) => id === order?.userId)
    }));
  }
}

export default Order;
