import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { useDispatch } from 'redux-react-hook';
import { Modal } from 'fogg/ui';
import { useLens } from 'fogg/hooks';

import { errorMessageByCode } from 'lib/errors';
import { triggerError, clearNotice } from 'state/actions';
import { isValidGeoJson, sanitizeGeoJson, limitGeoJsonFeatures } from 'lib/map';

import FileUpload from 'components/FileUpload';

const SearchModalUpload = ({ name, modal = {} }) => {
  const dispatch = useDispatch();

  const { handleModalClose, state } = modal;
  const modalState = state.modals[name];

  const { map = {}, geoSearch = {} } = useLens();
  const { search } = geoSearch;

  /**
   * handleOnFileRead
   */

  function handleOnFileRead ({ content }) {
    const { refMap } = map;
    const { current = {} } = refMap;
    const { leafletElement } = current;

    let geoJson;

    dispatch(clearNotice());

    try {
      geoJson = JSON.parse(content);
    } catch (e) {
      closeModal();
      dispatch(triggerError(errorMessageByCode('SEARCH_INVALID_JSON')));
      return;
    }

    try {
      if (!isValidGeoJson(geoJson)) throw new Error('Invalid GeoJSON');
      geoJson = sanitizeGeoJson(geoJson);
    } catch (e) {
      closeModal();
      dispatch(triggerError(errorMessageByCode('SEARCH_INVALID_GEOJSON')));
      return;
    }

    geoJson = limitGeoJsonFeatures(geoJson, 1);

    if (leafletElement && geoJson) {
      const geoJsonBounds = L.geoJSON(geoJson).getBounds();
      leafletElement.fitBounds(geoJsonBounds);
    }

    closeModal();

    search({
      geoJson
    });
  }

  /**
   * closeModal
   */

  function closeModal () {
    handleModalClose(
      {
        currentTarget: {
          dataset: {}
        }
      },
      name
    );
  }

  return (
    <Modal
      name={name}
      handleCloseModal={handleModalClose}
      isOpen={modalState.isOpen}
      appElement="#___gatsby"
    >
      <div className="search-modal-upload">
        <h1>Upload an Area of Interest</h1>
        <FileUpload
          acceptedTypes={['.geojson', '.json']}
          onFileRead={handleOnFileRead}
        />
        <p>
          Note: Only the first Feature of a FeatureCollection will be accepted.
        </p>
      </div>
    </Modal>
  );
};

SearchModalUpload.propTypes = {
  name: PropTypes.string,
  modal: PropTypes.object
};

export default SearchModalUpload;
