import React, { useMemo, useEffect } from 'react';
import TemplateTasks from 'templates/TemplateTasks';
import { useDispatch } from 'redux-react-hook';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import {
  useFetchTasks,
  useLocation,
  useTasks,
  useUser,
  useFetchCollectionTypes
} from 'hooks';
import { routePathByName } from 'lib/routes';
import {
  TASKS_TABLE_COLUMNS,
  taskFilterParamsToObject,
  prependDateTimeString,
  getFilterOptions,
  parseTableColumnsByFlag
} from 'lib/tasks';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';
import { updateTasksColumns } from 'state/actions';
import { USER_ROLE } from 'data/roles';

const TasksPage = () => {
  const { queryParams } = useLocation();
  const {
    user: {
      organizationId,
      organization: {
        permittedCollectionTiers: { taskingRequest: permittedTiers = [] } = {}
      } = {}
    }
  } = useUser();

  const {
    tasks = [],
    totalPages,
    columns
  } = useTasks({ includeSpawned: true, includeContractDetails: true });

  const { page = 1, limit = DEFAULT_PAGING_LIMIT, filters = {}, sort, order } = queryParams || {};
  const dispatch = useDispatch();

  // Convert filters & sort in query params to format tasks table can use
  // memoize the obj to avoid unnecessary re-renders
  const filtersParamObject = useMemo(() => {
    return taskFilterParamsToObject(filters);
  }, [filters]);

  const sortObject = useMemo(() => {
    return { sort, order };
  }, [sort, order]);

  // use forceNew to make sure we always have a fresh list
  const { isLoading = false, dispatchTasksCsvByUserId } = useFetchTasks({
    forceNew: false,
    page,
    limit,
    filters: filtersParamObject,
    sortObject
  });

  const { isLoading: isLoadingCollectionTypes, collectionTypes } = useFetchCollectionTypes();

  const noTasksActions = [
    {
      to: routePathByName('search'),
      label: 'Go to Search'
    }
  ];

  const flags = useFlags();

  // Reset to default tasks table columns order & active state
  const resetColumns = () => {
    dispatch(updateTasksColumns(parseTableColumnsByFlag({ columns: TASKS_TABLE_COLUMNS, flags, organizationId })));
  };

  useEffect(() => {
    if (columns && flags.collectionTypeTaskingFormEnabled) {
      dispatch(updateTasksColumns(parseTableColumnsByFlag({ columns, flags, organizationId })));
    }
  }, [flags]);

  return (
    <TemplateTasks
      id="tabpanel_0"
      isLoading={isLoading || isLoadingCollectionTypes}
      tasks={tasks}
      collectionTypes={collectionTypes}
      paging={{ currentPage: Number(page), totalPages, limit: Number(limit) }}
      filters={filtersParamObject}
      filterOptions={getFilterOptions({ permittedTiers, flags, organizationId, collectionTypes })}
      accessType={USER_ROLE}
      emptyActions={noTasksActions}
      tableColumns={columns}
      resetColumns={resetColumns}
      handleColumnsChange={(cols) => dispatch(updateTasksColumns(cols))}
      activeTab="single"
      exportHandler={dispatchTasksCsvByUserId}
      exportFileName={prependDateTimeString('capella-user-task-requests-download.csv')}
    />
  );
};

export default TasksPage;
