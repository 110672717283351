export const ROUTE_ADMIN = 'admin';
export const ROUTE_ADMIN_COLLECTIONS = 'adminCollections';
export const ROUTE_ADMIN_COLLECTIONS_DETAILS = 'adminCollectionsDetails';
export const ROUTE_ADMIN_ORGS = 'adminOrganizations';
export const ROUTE_ADMIN_ORGS_CREATE = 'adminOrganizationsCreate';
export const ROUTE_ADMIN_ORGS_CONTRACT_ADD = 'adminOrganizationsContractAdd';
export const ROUTE_ADMIN_ORGS_CONTRACT_EDIT = 'adminOrganizationsContractEdit';
export const ROUTE_ADMIN_ORGS_CONTRACT_PERIOD_ADD =
  'adminOrganizationsContractPeriodAdd';
export const ROUTE_ADMIN_ORGS_CONTRACT_PERIOD_EDIT =
  'adminOrganizationsContractPeriodEdit';
export const ROUTE_ADMIN_ORGS_BUCKET_CONFIG_ADD =
  'adminOrganizationsBucketConfigAdd';
export const ROUTE_ADMIN_ORGS_BUCKET_CONFIG_EDIT =
  'adminOrganizationsBucketConfigEdit';
export const ROUTE_ADMIN_ORGS_DETAILS = 'adminOrganizationsDetails';
export const ROUTE_ADMIN_ORGS_EDIT = 'adminOrganizationsEdit';
export const ROUTE_ADMIN_ORGS_CONTRACT_DETAILS =
  'adminOrganizationContractDetails';
export const ROUTE_ADMIN_USERS = 'adminUsers';
export const ROUTE_ADMIN_USERS_CREATE = 'adminUsersCreate';
export const ROUTE_ADMIN_USERS_DETAILS = 'adminUsersDetails';

export const ROUTE_AOIS = 'aois';
export const ROUTE_AOI_DETAILS = 'aoiDetails';
export const ROUTE_AOI_CREATE = 'aoiCreate';

export const ROUTE_API_AOIS = 'apiAois';
export const ROUTE_API_AOIS_SEARCH = 'apiAoisSearch';
export const ROUTE_API_AOI = 'apiAoi';

export const ROUTE_API_ACCESS_REQUESTS = 'apiAccessRequests';
export const ROUTE_API_ACCESS_REQUEST_BY_ID = 'apiAccessRequestById';
export const ROUTE_API_ACCESSES_BY_REQUEST_ID = 'apiAccessesByRequestId';
export const ROUTE_API_COLLECTIONS = 'apiCollections';
export const ROUTE_API_COLLECTION_PERMISSIONS = 'apiCollectionPermissions';
export const ROUTE_API_PUBLIC_COLLECTIONS = 'apiPublicCollections';
export const ROUTE_API_ORDERS = 'apiOrders';
export const ROUTE_API_REORDER = 'apiReorder';
export const ROUTE_API_ORDERS_CREATE_TASK = 'apiOrdersCreateTask';
export const ROUTE_API_ORG = 'apiOrganization';
export const ROUTE_API_ORGS = 'apiOrganizations';
export const ROUTE_API_ORGS_PAGED = 'apiOrganizationsPaged';
export const ROUTE_API_ORGS_COLLECTIONS = 'apiOrganizationsCollections';
export const ROUTE_API_ORG_CONTRACTS = 'apiOrganizationsContracts';
export const ROUTE_API_ORG_CONTRACT_PERIOD =
  'apiOrganizationsContractPeriodsById';
export const ROUTE_API_ORG_CONTRACT_PERIODS = 'apiOrganizationsContractPeriods';
export const ROUTE_API_ORG_CONTRACT_DATASHARE =
  'apiOrganizationsContractDatashare';
export const ROUTE_API_ORG_USERS = 'apiOrgUsers';
export const ROUTE_API_ORG_S3_PUSH_TEST = 'apiOrgS3PushTest';
export const ROUTE_API_ORG_S3_BUCKET_POST_GET = 'apiOrgS3BucketPostGet';
export const ROUTE_API_ORG_S3_BUCKET_PUT_DEL = 'apiOrgS3BucketPutDel';
export const ROUTE_API_ORDER_ASSETS_S3 = 'apiOrderAssetsS3';
export const ROUTE_API_ORGS_ORG = 'apiOrganizationsOrganization';
export const ROUTE_API_ORGS_ORG_CONTRACTS =
  'apiOrganizationsOrganizationContracts';
export const ROUTE_API_RESELLER_ORG = 'apiResellerOrganization';
export const ROUTE_API_RESELLER_ORG_USERS = 'apiResellerOrganizationUsers';
export const ROUTE_API_SEARCH = 'apiSearch';
export const ROUTE_API_STAC_DOWNLOAD = 'apiStacDownload';
export const ROUTE_API_SUB_RESELLER_ORG = 'apiSubResellerOrganization';
export const ROUTE_API_TASK = 'apiTask';
export const ROUTE_API_TASKS = 'apiTasks';
export const ROUTE_API_TASKS_PAGED = 'apiTasksPaged';
export const ROUTE_API_TASK_COLLECTS = 'apiTaskCollects';
export const ROUTE_API_TASK_FROM_COLLECT = 'apiCollectTask';
export const ROUTE_API_TASKS_SEARCH = 'apiTasksSearch';
export const ROUTE_API_TASKS_STATUS = 'apiTasksStatus';
export const ROUTE_API_TASKS_TASK = 'apiTasksTask';
export const ROUTE_API_TASK_TILES = 'apiTaskTiles';
export const ROUTE_API_ACCESS_REQUEST_TILES = 'apiAccessRequestTiles';
export const ROUTE_API_TASK_OOSM = 'apiTaskOOSM';
export const ROUTE_API_RETASK_TASK = 'apiRetaskTask';
export const ROUTE_API_RETASK_REPEAT_REQUEST = 'apiRetaskRepeatRequest';
export const ROUTE_API_RETASK_COLLECT = 'apiRetaskCollect';
export const ROUTE_API_USER = 'apiUser';
export const ROUTE_API_USERS = 'apiUsers';
export const ROUTE_API_USERS_PAGED = 'apiUsersPaged';
export const ROUTE_API_USERS_COLLECTIONS = 'apiUsersCollections';
export const ROUTE_API_USER_REG = 'apiUserRegister';
export const ROUTE_API_TOKEN = 'apiToken';
export const ROUTE_API_PASSWORDS = 'apiPasswords';
export const ROUTE_API_USERS_RESEND_PW = 'apiUsersResendPassword';
export const ROUTE_API_REPEAT_REQUEST = 'apiRepeatRequest';
export const ROUTE_API_REPEAT_REQUEST_STATUS = 'apiRepeatRequestStatus';
export const ROUTE_API_REPEAT_REQUESTS_SEARCH = 'apiRepeatRequestsSearch';
export const ROUTE_API_CONTRACTS = 'apiContracts';
export const ROUTE_API_EXPORT_TASKS_CSV = 'apiExportTasksCsv';
export const ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV =
  'apiExportRepeatRequestsCsv';
export const ROUTE_API_LICENSES = 'apiLicenses';
export const ROUTE_API_COLLECTION_TYPES = 'apiCollectionTypes';

// CHMO/Analytics Specific Routes
export const ROUTE_CHMOAPI_ACTION = 'chmoApiAction';

export const ROUTE_ERROR_NOT_FOUND = 'errorNotFound';
export const ROUTE_ERROR_SERVER_ERROR = 'errorServerError';

export const ROUTE_HOME = 'home';
export const ROUTE_SEARCH = 'search';
export const ROUTE_SEARCH_DETAILS = 'searchDetails';
export const ROUTE_ACCOUNT = 'account';
export const ROUTE_ACCOUNT_PROFILE = 'accountProfile';
export const ROUTE_CART = 'cart';
export const ROUTE_CART_VIEW = 'cartView';
export const ROUTE_CART_REVIEW = 'cartReview';
export const ROUTE_ORDERS = 'orders';
export const ROUTE_ORDERS_DETAILS = 'ordersDetails';
export const ROUTE_ORDER_CONTRACT_DETAILS = 'orderContractDetails';
export const ROUTE_ORG = 'organization';
export const ROUTE_ORG_DETAILS = 'organizationDetails';
export const ROUTE_ORG_DETAILS_CONTRACT = 'organizationDetailsContract';
export const ROUTE_ORG_EDIT = 'organizationEdit';
export const ROUTE_ORG_ORDERS = 'organizationOrders';
export const ROUTE_ORG_ORDERS_DETAILS = 'organizationOrdersDetails';
export const ROUTE_ORG_PROFILE = 'organizationProfile';
export const ROUTE_ORG_REPEAT_REQUESTS = 'organizationRepeatRequests';
export const ROUTE_ORG_REPEAT_REQUESTS_DETAILS =
  'organizationRepeatRequestsDetails';
export const ROUTE_ORG_TASKS = 'organizationTasks';
export const ROUTE_ORG_TASKS_DETAILS = 'organizationTasksDetails';
export const ROUTE_ORG_TASKS_CONTRACT_DETAILS =
  'organizationTasksContractDetails';
export const ROUTE_ORG_USERS = 'organizationUsers';
export const ROUTE_ORG_USERS_CREATE = 'organizationUsersCreate';
export const ROUTE_ORG_USERS_DETAILS = 'organizationUsersDetails';
export const ROUTE_ORG_CONTRACTS = 'organizationContracts';
export const ROUTE_ORG_CONTRACT_DETAILS = 'organizationContractDetails';
export const ROUTE_ORG_BUCKET_CONFIG_ADD = 'organizationsBucketConfigAdd';
export const ROUTE_ORG_BUCKET_CONFIG_EDIT = 'organizationsBucketConfigEdit';

export const ROUTE_RESELLER = 'reseller';
export const ROUTE_RESELLER_DETAILS = 'resellerDetails';
export const ROUTE_RESELLER_CONTRACT_DETAILS = 'resellerContractDetails';
export const ROUTE_RESELLER_USERS = 'resellerUsers';
export const ROUTE_RESELLER_USERS_CREATE = 'resellerUsersCreate';
export const ROUTE_RESELLER_USERS_DETAILS = 'resellerUsersDetails';
export const ROUTE_RESELLER_ORDERS = 'resellerOrders';
export const ROUTE_RESELLER_ORDER_DETAILS = 'resellerOrdersDetails';
export const ROUTE_RESELLER_ORGS = 'resellerOrganizations';
export const ROUTE_RESELLER_ORG_CREATE = 'resellerOrganizationsCreate';
export const ROUTE_RESELLER_ORG_DETAILS = 'resellerOrganizationsDetails';
export const ROUTE_RESELLER_ORG_DETAILS_EDIT =
  'resellerOrganizationsDetailsEdit';
export const ROUTE_RESELLER_ORG_CONTRACT_DETAILS = 'resellerOrgContractDetails';
export const ROUTE_RESELLER_TASKS = 'resellerTasks';
export const ROUTE_RESELLER_REPEAT_REQUESTS = 'resellerRepeatRequests';
export const ROUTE_RESELLER_TASK_DETAILS = 'resellerTasksDetails';
export const ROUTE_RESELLER_REPEAT_REQUEST_DETAILS =
  'resellerRepeatRequestDetails';

export const ROUTE_SUB_RESELLER = 'subreseller';
export const ROUTE_SUB_RESELLER_DETAILS = 'subresellerDetails';
export const ROUTE_SUB_RESELLER_CONTRACT_DETAILS = 'subresellerContractDetails';
export const ROUTE_SUB_RESELLER_ORDERS = 'subresellerOrders';
export const ROUTE_SUB_RESELLER_ORDER_DETAILS = 'subresellerOrdersDetails';
export const ROUTE_SUB_RESELLER_ORGS = 'subresellerOrganizations';
export const ROUTE_SUB_RESELLER_ORG_CREATE = 'subresellerOrganizationsCreate';
export const ROUTE_SUB_RESELLER_ORG_DETAILS = 'subresellerOrganizationsDetails';
export const ROUTE_SUB_RESELLER_ORG_DETAILS_EDIT =
  'subresellerOrganizationsDetailsEdit';
export const ROUTE_SUB_RESELLER_ORG_CONTRACT_DETAILS =
  'subresellerOrganizationContractDetails';
export const ROUTE_SUB_RESELLER_TASKS = 'subresellerTasks';
export const ROUTE_SUB_RESELLER_TASK_DETAILS = 'subresellerTasksDetails';
export const ROUTE_SUB_RESELLER_REPEAT_REQUESTS = 'subresellerRepeatRequests';
export const ROUTE_SUB_RESELLER_REPEAT_REQUEST_DETAILS =
  'subresellerRepeatRequestDetails';

export const ROUTE_SUB_RESELLER_USERS = 'subresellerUsers';
export const ROUTE_SUB_RESELLER_USERS_CREATE = 'subresellerUsersCreate';
export const ROUTE_SUB_RESELLER_USER_DETAILS = 'subresellerUsersDetails';

export const ROUTE_TASKS = 'tasks';
export const ROUTE_TASKS_CREATE = 'tasksCreate';
export const ROUTE_TASKS_DETAILS = 'tasksDetails';
export const ROUTE_TASKS_RELATED_DETAILS = 'relatedTaskDetails';
export const ROUTE_TASKS_REVIEW = 'tasksReview';
export const ROUTE_TASK_EDIT = 'taskEdit';

export const ROUTE_USER_LOGIN = 'userLogin';
export const ROUTE_USER_LOG_OUT = 'userLogout';
export const ROUTE_USER_LOG_OUT_EXPIRED = 'userLogoutExpired';
export const ROUTE_USER_PW_RESET = 'userPasswordReset';
export const ROUTE_USER_CHANGE_PW = 'userChangePassword';
export const ROUTE_USER_NEW_PW = 'userNewPassword';
export const ROUTE_USER_PREMIUM_SIGNUP = 'userPremiumSignup';
export const ROUTE_USER_PREMIUM_SIGNUP_THX = 'userPremiumSignupThankYou';
export const ROUTE_USER_REGISTER = 'userRegister';
export const ROUTE_USER_REGISTER_THX = 'userRegisterThankYou';
export const ROUTE_USER_REGISTER_EXTERNAL = 'userRegisterExternal';
export const ROUTE_USER_AUTHORIZE = 'userAuthorize';

export const ROUTE_REPEAT_REQUESTS = 'repeatRequests';
export const ROUTE_REPEAT_REQUEST_DETAILS = 'repeatRequestDetails';
export const ROUTE_REPEAT_REQUEST_CHILD_TASK = 'repeatRequestChildTask';
export const ROUTE_REPEAT_REQUEST_EDIT = 'repeatRequestEdit';

export const ROUTE_SHARED_PLATFORM = 'sharedPlatform';
export const ROUTE_SHARED_LOGIN = 'sharedLogin';
export const ROUTE_SHARED_LOGOUT = 'sharedLogout';

/** Docs & Support Routes (External URLs) */
export const CAPELLA_SUPPORT_URL = 'https://capellaspace.zendesk.com/hc/en-us/';
export const CONSOLE_URL = `https://${process.env.DASHBOARD_ENDPOINT}`;
export const ANALYTICS_URL = `https://${process.env.ANALYTICS}`;

/* Used for local testing */
// export const CONSOLE_URL = 'http://console.local.capellaspace.com:8001';
// export const ANALYTICS_URL = 'http://analytics.local.capellaspace.com:8002';
