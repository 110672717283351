import React from 'react';
import { WonderLink } from 'fogg/ui';
import { routePathByName } from '../lib/routes';
import { CONTRACT_TYPES, CONTRACT_PERIOD_STATES } from '../data/contracts';

export function contractByName (name) {
  return CONTRACT_TYPES.find((contract) => contract.name === name) || {};
}

export function contractPeriodStateByName (name) {
  return CONTRACT_PERIOD_STATES.find((type) => type.name === name);
}

export function contractPeriodStateLabelByName (name) {
  const { label } = contractPeriodStateByName(name) || {};
  return label || 'Unknown';
}

export function contractLabel (contract) {
  const { id = '', label = '' } = contract;
  return label || `ID: ${id}`;
}

export function formatContractIdColumn (value) {
  if (value.toLowerCase?.() === 'Loading') return value;
  if (Array.isArray(value)) {
    const [
      contractId = null,
      contractName = null,
      routeName = null,
      parentId = null // can be order.orderId or task.id, used for passing along breadcrumbs
    ] = value;
    const cellContent = (content) => (
      <div className="data-table-advanced-name-col">
        {content}
      </div>
    );
    const cellLink = (content, href) => (
      <WonderLink className="data-table-details-link" to={href}>{content}</WonderLink>
    );
    const cellIdText = (content) => {
      let linkContent = content;
      if (typeof content === 'string') {
        linkContent = `ID: ${content}`;
      }
      return <div className="col-muted-text larger flow">{linkContent}</div>;
    };

    if (!contractId) return cellContent(cellIdText('-'));
    if (!contractName && !routeName) return cellContent(cellIdText(contractId));

    const routeWildCards = [contractId];
    if (parentId) {
      routeWildCards.unshift(parentId);
    }

    const contractDetailsPath = routePathByName(routeName, {
      wildcard: routeWildCards
    });

    if (!routeName) {
      return cellContent(
        <>
          <div>{contractName}</div>
          {cellIdText(contractId)}
        </>
      );
    }
    if (!contractName) {
      return cellContent(
        <>
          {cellIdText(
            <>
              {cellLink(`ID: ${contractId}`, contractDetailsPath)}
            </>
          )}
        </>
      );
    }
    return cellContent(
      <>
        {cellLink(contractName, contractDetailsPath)}
        {cellIdText(contractId)}
      </>
    );
  }
  return value;
}
