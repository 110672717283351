import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAdmin } from 'hooks';
import { logError } from 'lib/logger';
import { errorMessageByCode } from 'lib/errors';
import { triggerNotice, fetchOrganizationCollections } from 'state/actions';

export default function useFetchOrganizationCollections ({ organizationId }) {
  const dispatch = useDispatch();

  const { actions = {} } = useAdmin();

  /**
   * _fetchOrganizationCollections
   */

  function _fetchOrganizationCollections () {
    dispatch(fetchOrganizationCollections(organizationId)).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchOrganizationCollections = useCallback(() => {
    _fetchOrganizationCollections();
  }, []); // eslint-disable-line

  useEffect(() => {
    memoizedFetchOrganizationCollections();
  }, [memoizedFetchOrganizationCollections]);

  return {
    fetchOrganizationCollections: memoizedFetchOrganizationCollections,
    ...actions.fetchOrganizationCollections
  };
}
