import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBitbucket } from 'react-icons/fa';
import { ROUTE_ORG, ROUTE_ORG_DETAILS, ROUTE_ORG_EDIT } from 'data/route-names';

import {
  routePathByName,
  redirectTo,
  logError,
  adminUpdateOrganizationS3Bucket,
  adminDeleteOrganizationS3BucketConfig,
  triggerSuccess,
  triggerError,
  Breadcrumbs,
  PageHeader,
  Container,
  FormBucketConfigAddEdit
} from 'commonLib';
import { useOrgFetchOrganization } from 'hooks';

import Layout from 'components/Layout';

const PAGE_TITLE = 'Edit Bucket Config';

const OrganizationsBucketConfigEditPage = ({ bucketConfigId }) => {
  const dispatch = useDispatch();

  const organizationOverviewPath = routePathByName(ROUTE_ORG);
  const organizationDetailsPath = routePathByName(ROUTE_ORG_DETAILS);
  const organizationEditPath = routePathByName(ROUTE_ORG_EDIT);

  const { organization } = useOrgFetchOrganization();
  const organizationId = organization.id;
  const { s3BucketConfigs } = organization;
  const bucketConfig = s3BucketConfigs.find((bc) => bc.id === bucketConfigId);

  function handleOnSubmit ({ data }) {
    dispatch(
      adminUpdateOrganizationS3Bucket(
        organizationId,
        bucketConfigId,
        data,
        false
      )
    )
      .then(() => {
        dispatch(
          triggerSuccess(
            `Successfully updated bucket config ${data.bucketName}/${data.prefix}!`
          )
        );
        redirectTo(organizationEditPath);
      })
      .catch((error) => {
        logError(error.message, data);
        dispatch(
          triggerError(
            `Not able to updated bucket config ${data.bucketName}/${data.prefix}`
          )
        );
      });
  }

  async function handleOnDelete () {
    dispatch(
      adminDeleteOrganizationS3BucketConfig(organizationId, bucketConfigId)
    )
      .then(() => {
        redirectTo(organizationEditPath);
        dispatch(triggerSuccess('Successfully deleted bucket config!'));
      })
      .catch((error) => {
        logError(error.message);
        dispatch(triggerError('Not able to delete bucket config!'));
      });
  }

  function handleOnCancel () {
    redirectTo(organizationEditPath);
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-organization page-organization-bucket-config page-organization-bucket-config-edit'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Organization',
              to: organizationOverviewPath
            },
            {
              label: 'Details',
              to: organizationDetailsPath
            }
          ]}
        />
        <PageHeader title={PAGE_TITLE} icon={<FaBitbucket />} />
        <FormBucketConfigAddEdit
          orgId={organizationId}
          mode="edit"
          bucketConfig={bucketConfig}
          onSubmit={handleOnSubmit}
          onDelete={handleOnDelete}
          onCancel={handleOnCancel}
        />
      </Container>
    </Layout>
  );
};

OrganizationsBucketConfigEditPage.propTypes = {
  organizationId: PropTypes.string,
  bucketConfigId: PropTypes.string
};

export default OrganizationsBucketConfigEditPage;
