// shared contracts lib
export * from 'commonLib/src/lib/util';
// console specific console lib can go below here

export const flattenObject = (source, target) => {
  Object.keys(source).forEach(function (k) {
    if (source[k] !== null && typeof source[k] === 'object') {
      flattenObject(source[k], target);
      return;
    }
    target[k] = source[k];
  });
};

/**
 * is the provided string a STAC ID format?
 * @param {string|string[]} id Single ID or multiple id's split by comma
 * @returns {boolean}
 */
export const isSTACID = (id) => {
  if (!id || typeof id !== 'string') return false;
  const idArray = id.split(',');
  // Regex for checking if string(s) match our STAC ID format
  const regexp = /^[a-z0-9]+_[a-z0-9_]+_[a-z0-9]+$/i;
  const find = idArray.filter(val => val.search(regexp) !== -1);
  return find.length > 0;
};
