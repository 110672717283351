import { constructRequestActionManager } from 'commonLib/src/lib/actions';
import { routePathByName } from 'lib/routes';
import Order from 'models/order';
import Item from 'commonLib/src/models/item';

/**
 * setOrdersActionState
 * @description Updates the orders store's status
 */

export function setOrdersActionState (status) {
  return {
    type: 'UPDATE_ORDERS_ACTION_STATE',
    data: status
  };
}

const createDispatchRequestAction =
  constructRequestActionManager(setOrdersActionState);

/**
 * fetchAllOrders
 * @description Fetches all orders from the given user
 * @param {string} userId The given user's ID/OrgId depending on user type
 */

export const fetchAllOrders = (userId, userType = 'customerId') => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchAllOrders',
      scope: 'orders',
      request: {
        url: `${routePathByName('apiOrders')}?${userType}=${userId}`,
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    const orders = data.map((order) => new Order(order).data());

    dispatch(updateOrders(orders));

    return orders;
  };
};

/**
 * fetchOrderById
 * @description Fetches an order by it's ID
 * @param {string} orderId ID of the given order
 */

export const fetchOrderById = (orderId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrderById',
      scope: 'orders',
      request: {
        url: `${routePathByName('apiOrders')}/${orderId}`,
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    const order = new Order(data);

    dispatch(addOrder(order));

    return order;
  };
};

/**
 * postReviewOrder
 * @description Makes a request to review an order for validation and authorization
 * @param {object} orderData Order data to review for purchase
 * @param {boolean} isPreFlightCheck Is this only a "pre-flight" check?
 */

export const postReviewOrder = (orderData = {}, isPreFlightCheck = false) => {
  return async (dispatch, getState) => {
    const { cart: cartState = {}, orders: orderState = {} } = getState();
    const {
      items: cartItems = [],
      addOnItems = [],
      contract: {
        contractId = null
      }
    } = cartState;
    const newOrder = orderState.orders.find(
      (order) => order.id === 'NEW_ORDER'
    );

    const order = new Order(newOrder || {});

    order.update(orderData);

    // add the cart items to the order
    order.addItems(cartItems);

    if (!order || !Array.isArray(order.items)) {
      throw new Error('Missing order details');
    }

    const { items = [] } = order;

    if (items.length === 0) {
      throw new Error('No items in order request');
    }

    const orderItems = items.map((item) => {
      const { collection, id } = item;
      // Legacy format of cart/orders with single collection and STAC ID
      return {
        collectionId: collection,
        granuleId: id
      };
    });

    // New /orders/review format that can be submitted w/o a STAC/granule ID
    // This allows us to add analytics (or other products) to an archive order
    if (addOnItems.length) {
      const addOns = addOnItems.map(({ productType, collectId }) => {
        if (productType && collectId) {
          return ({
            productType,
            collectIds: [collectId]
          });
        }
      });
      orderItems.push(...addOns);
    }

    // Don't include contractId on pre-flight checks
    const payload = {
      items: orderItems
    };
    if (!isPreFlightCheck && contractId) {
      payload.contractId = contractId;
    }

    const dispatchAction = createDispatchRequestAction({
      name: 'postReviewOrder',
      scope: 'orders',
      request: {
        url: `${routePathByName('apiOrders')}/review`,
        method: 'post',
        data: payload
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    // TODO remove injecting contractID once its returned properly
    const { orderDetails, ...restData } = data;
    order.addOrderDetails({
      orderDetails: {
        contractId,
        ...orderDetails
      },
      ...restData
    });

    if (newOrder) {
      dispatch(updateOrders([order.data()]));
    } else {
      dispatch(addOrder(order.data()));
    }

    return order.data();
  };
};

/**
 * postOrderRequest
 * @description Makes an order given the provided order data
 * @param {object} {order} Data of the object being ordered
 */

export const postOrderRequest = ({ order: orderData = {} }) => {
  return async (dispatch, getState) => {
    const order = new Order(orderData);

    if (!order || !Array.isArray(order.items)) {
      throw new Error('Missing order details');
    }

    const {
      contractId = null,
      items = [],
      addOnItems = []
    } = order;

    if (items.length === 0) {
      throw new Error('No items');
    }

    const orderItems = items.map(({ collection, id }) => {
      return {
        collectionId: collection,
        granuleId: id
      };
    });

    const addOns = addOnItems.map(i => {
      return ({
        productType: i.productType,
        collectIds: [i.collectId]
      });
    });

    const dispatchAction = createDispatchRequestAction({
      name: 'postOrderRequest',
      scope: 'orders',
      request: {
        url: routePathByName('apiOrders'),
        method: 'post',
        data: {
          contractId,
          items: [...orderItems, ...addOns]
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    // We create a new order to easily convert the response keys to the order model keys
    const createdOrder = new Order(data);

    order.update(createdOrder.data());

    dispatch(storeNewOrder(order));

    return data;
  };
};

/**
 * downloadOrder
 * @description Prepares an order for download
 * @param {orderId} orderId ID of the order to download
 */

export const downloadOrder = (orderId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'downloadOrder',
      scope: 'orders',
      request: {
        url: `${routePathByName('apiOrders')}/${orderId}/download`,
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    const items = data.map((item) => new Item(item));

    const updatedOrderItems = {
      orderId,
      items
    };

    dispatch(updateOrderItems(updatedOrderItems));

    return updatedOrderItems;
  };
};

/**
 * downloadOrderZip
 * @description Prepares an order for zip download
 * @param {string} orderId ID of the order item to download
 * @param {string} collectionId collection of the order item
 * @param {string} granuleId STAC ID or granule ID of the order item
 */

export const downloadOrderZip = (orderId, collectionId, granuleId) => {
  if (!orderId || !collectionId || !granuleId) return;
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'downloadOrderZip',
      scope: 'orders',
      request: {
        url: `${routePathByName(
          'apiOrders'
        )}/${orderId}/assets/${collectionId}/${granuleId}/archive`,
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    return data;
  };
};

/**
 * pushOrdertoS3
 * @description Pushes a given order to the org's s3 bucket
 * @param {string} orderId, @param {string} collectionId,
 * @param {string} granuleId, @param {array} bucketConfigIds
 */

export const pushOrderToS3 = (orderId, collectionId, granuleId, bucketConfigIds) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'pushOrderToS3',
      scope: 'orders',
      request: {
        url: routePathByName('apiOrderAssetsS3', {
          wildcard: [orderId, collectionId, granuleId]
        }),
        data: { s3BucketConfigIds: bucketConfigIds },
        method: 'post'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};
    return requestData;
  };
};

/**
 * createOrderByTaskId
 * @description Creates a new order with the given Task ID
 * @param {string} taskId ID of the order to download
 */

export const createOrderByTaskId = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'createOrderByTaskId',
      scope: 'orders',
      request: {
        url: routePathByName('apiOrdersCreateTask', {
          wildcard: [taskId]
        }),
        method: 'post'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(storeNewOrder(data));

    return data;
  };
};

/******************
 * STATE UPDATERS *
 ******************/

/**
 * storeNewOrder
 * @description
 */

export const storeNewOrder = (data) => {
  let order = data;
  if (!(order instanceof Order)) {
    order = new Order(data);
  }
  return addOrder(order.data());
};

/**
 * clearNewOrder
 * @description
 */

export const clearNewOrder = () => {
  return {
    type: 'CLEAR_NEW_ORDER'
  };
};

/**
 * updateOrders
 * @description Set the order results
 */

export const updateOrders = (data) => {
  return {
    type: 'UPDATE_ORDERS',
    data
  };
};
/**
 * addOrder
 * @description Add an order to the store
 */

export const addOrder = (data) => {
  return {
    type: 'ADD_ORDER',
    data
  };
};

/**
 * updateOrderItems
 * @description
 */

export const updateOrderItems = (data) => {
  return {
    type: 'UPDATE_ORDER_ITEMS',
    data
  };
};
