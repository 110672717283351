import {
  ROUTE_API_RESELLER_ORG,
  ROUTE_API_RESELLER_ORG_USERS,
  ROUTE_RESELLER,
  ROUTE_RESELLER_DETAILS,
  ROUTE_RESELLER_CONTRACT_DETAILS,
  ROUTE_RESELLER_ORDERS,
  ROUTE_RESELLER_ORDER_DETAILS,
  ROUTE_RESELLER_ORGS,
  ROUTE_RESELLER_ORG_CREATE,
  ROUTE_RESELLER_ORG_DETAILS,
  ROUTE_RESELLER_ORG_DETAILS_EDIT,
  ROUTE_RESELLER_ORG_CONTRACT_DETAILS,
  ROUTE_RESELLER_TASK_DETAILS,
  ROUTE_RESELLER_TASKS,
  ROUTE_RESELLER_REPEAT_REQUESTS,
  ROUTE_RESELLER_REPEAT_REQUEST_DETAILS,
  ROUTE_RESELLER_USERS,
  ROUTE_RESELLER_USERS_CREATE,
  ROUTE_RESELLER_USERS_DETAILS
} from './route-names';
import { RESELLER_ROLE } from './roles';

const routes = [];

/**
 * UI
 */

routes.push({
  name: ROUTE_RESELLER,
  path: '/reseller/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_USERS,
  path: '/reseller/users/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_USERS_CREATE,
  path: '/reseller/users/create/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_USERS_DETAILS,
  path: '/reseller/users/details/*/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_ORGS,
  path: '/reseller/organizations/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_ORG_CREATE,
  path: '/reseller/organizations/create/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_ORG_DETAILS,
  path: '/reseller/organizations/details/*/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_ORG_CONTRACT_DETAILS,
  path: '/reseller/organizations/*/contract/details/*/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: 'resellerOrganizationsContractEdit',
  path: '/reseller/organizations/*/contract/*/edit/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: 'resellerOrganizationsContractPeriodAdd',
  path: '/reseller/organizations/*/contract/*/contract-period/add/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: 'resellerOrganizationsContractPeriodEdit',
  path: '/reseller/organizations/*/contract-period/*/edit/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_ORG_DETAILS_EDIT,
  path: '/reseller/organizations/details/*/edit/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_TASKS,
  path: '/reseller/tasks/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_REPEAT_REQUESTS,
  path: '/reseller/tasks/repeat-requests/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_TASK_DETAILS,
  path: '/reseller/tasks/details/*/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_REPEAT_REQUEST_DETAILS,
  path: '/reseller/tasks/repeat-requests/details/*/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_DETAILS,
  path: '/reseller/details/*/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_CONTRACT_DETAILS,
  path: '/reseller/*/contract/details/*/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_ORDERS,
  path: '/reseller/orders/',
  access: [RESELLER_ROLE]
});

routes.push({
  name: ROUTE_RESELLER_ORDER_DETAILS,
  path: '/reseller/orders/details/*/',
  access: [RESELLER_ROLE]
});
/**
 * API
 */

routes.push({
  name: ROUTE_API_RESELLER_ORG,
  path: '/resellers/*/'
});

routes.push({
  name: ROUTE_API_RESELLER_ORG_USERS,
  path: '/resellers/*/users'
});

export default routes;
