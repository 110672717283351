import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { TaskAction, Button } from 'fogg/ui';
import { device } from 'fogg/lib';
import { IconContext } from 'react-icons';
import { FaSync } from 'react-icons/fa';
import { FiInfo } from 'react-icons/fi';

import {
  TASK_STATUS_COMPLETE,
  TASK_STATUS_REJECTED,
  TASK_STATUS_RECEIVED,
  TASK_STATUS_REVIEW,
  TASK_STATUS_SUBMITTED
} from 'data/tasks';
import { logError } from 'lib/logger';
import { formatNumberToCurrency, formatInfinityToString } from 'lib/currency';
import { updateRepeatRequestStatusById, triggerError } from 'state/actions';

/**
 * RepeatRequest Transaction status
 * The transaction status is different from the top level status, which refers to the
 * CAPI status. Transaction on the other hand defines where the task is at in relation
 * to the financial transaction and ordering of the task
 * Available statuses:
 * - COMPLETED: no transaction modifications possible. Perhaps the "download order"?
 * - PENDING_COST_CALCULATION: we're waiting for CAPI to estimate size
 * - PENDING_USER_APPROVAL: only status where you should show "approve and reject"
 * - IN_PROGRESS: this is where you would show cancellation options
 * - FAILED: The task failed at some point after it was in-progress and determined feasible.
 * - DENIED: CAPI could not accommodate the request. We have refunded any reserved funds.
 * - REJECTED: 'customer-rejected'
 * - SERVICE_REJECTED: 'service-rejected', Capella's systems rejected the task.
 */

const STATUSES_CAN_CANCEL = [TASK_STATUS_REVIEW, TASK_STATUS_RECEIVED];

const STATUSES_CAN_APPROVE = [TASK_STATUS_REVIEW];

const TaskCostAction = ({ repeatRequest = {} }) => {
  const dispatch = useDispatch();
  const { isDomAvailable } = device;

  const {
    status,
    id,
    estimatedCostPerTask,
    estimatedCostPerMonth,
    totalTasksToRun,
    estimatedFiniteTotalCost
  } = repeatRequest;

  const repeatRequestUnknownStatus = !status;
  const repeatRequestStatusComplete = status === TASK_STATUS_COMPLETE;
  const repeatRequestPendingCalculation =
    status === TASK_STATUS_SUBMITTED || status === TASK_STATUS_RECEIVED;
  const repeatRequestIsRejected = status === TASK_STATUS_REJECTED;
  const repeatRequestCanCancel = STATUSES_CAN_CANCEL.includes(status);
  const repeatRequestCanApprove = STATUSES_CAN_APPROVE.includes(status);

  const displayCost =
    typeof estimatedCostPerTask !== 'undefined' &&
    status === TASK_STATUS_REVIEW
  ;

  /**
   * handleSubmit
   */

  function handleSubmit () {
    dispatch(
      updateRepeatRequestStatusById(id, {
        status: 'submitted'
      })
    ).catch((error) => {
      logError(error.message, {
        repeatRequestId: id
      });
      dispatch(triggerError());
    });
  }

  /**
   * handleCancel
   */

  function handleCancel () {
    dispatch(
      updateRepeatRequestStatusById(id, {
        status: 'canceled'
      })
    ).catch((error) => {
      logError(error.message, {
        repeatRequestId: id
      });
      dispatch(triggerError());
    });
  }

  let actionPositive;
  let actionNegative;

  if (repeatRequestCanApprove) {
    actionPositive = {
      onSubmit: handleSubmit,
      label: 'Submit Request'
    };
  }

  if (repeatRequestCanCancel) {
    actionNegative = {
      onSubmit: handleCancel,
      label: 'Cancel Request'
    };
  }

  const hasActionsAvailable = !!(actionPositive || actionNegative);

  function refreshPage () {
    if (!isDomAvailable()) return;
    window.location.reload(false);
  }

  if (repeatRequestUnknownStatus) {
    return null;
  }

  return (
    <div
      className="task-cost-action with-footer"
      data-has-actions-available={hasActionsAvailable}
    >
      {repeatRequest && status && (
        <>
          <TaskAction positive={actionPositive} negative={actionNegative}>
            <div>
              <>
                {displayCost && (
                  <>
                    <p className="repeat-request-cost-action-status">
                      <span>Estimated cost per task:</span>
                      <span>
                        {formatNumberToCurrency(estimatedCostPerTask)}
                      </span>
                    </p>
                    <p className="repeat-request-cost-action-status">
                      <span>Estimated number of collects:</span>
                      <span>
                        {formatInfinityToString({ number: totalTasksToRun })}
                      </span>
                    </p>
                    {estimatedCostPerMonth && (
                      <p className="repeat-request-cost-action-status">
                        <span>Estimated monthly cost for series:</span>
                        <span>{formatNumberToCurrency(estimatedCostPerMonth)}</span>
                      </p>
                    )}
                    {estimatedFiniteTotalCost && (
                      <p className="repeat-request-cost-action-status">
                        <span>Estimated total cost for series:</span>
                        <span>{formatNumberToCurrency(estimatedFiniteTotalCost)}</span>
                      </p>
                    )}
                  </>
                )}

                {!displayCost &&
                  !repeatRequestIsRejected &&
                  !repeatRequestPendingCalculation && (
                  <>
                    <p className="task-cost-action-status">
                      <span>Contact Reseller for pricing.</span>
                    </p>
                  </>
                )}

                {repeatRequestPendingCalculation && (
                  <p className="task-cost-action-status pending-calculation">
                    <span className="task-action-heading">Pending Calculation</span>
                    <small>This will take a few minutes & require the page to reload. Check back later or refresh below.</small>
                    <br/>
                    <Button onClick={refreshPage} type="text"><FaSync /> Reload Page</Button>
                  </p>
                )}

                {repeatRequestIsRejected && (
                  <p className="task-cost-action-note">
                    *Repeat Tasking Request was canceled by user. No charges
                    were made.
                  </p>
                )}
              </>
            </div>
          </TaskAction>
          {!repeatRequestStatusComplete && !repeatRequestIsRejected && displayCost && (
            <div className="task-cost-action-footer">
              <div className="icon">
                <IconContext.Provider
                  value={{ className: 'info-icon', size: 22 }}
                >
                  <FiInfo />
                </IconContext.Provider>
              </div>
              <div className="task-action-disclaimer">
                {`Costs are best estimates at time of task submission 
                based on your current organization and contract. By clicking 
                ‘Submit Request’ you are agreeing to the cost of the repeat 
                task series. Charges are only incurred as individual tasks 
                are scheduled and completed. Individual repeat tasks or the 
                entire repeat task series can be cancelled following Capella’s 
                standard cancellation policy.`}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

TaskCostAction.propTypes = {
  repeatRequest: PropTypes.object
};

export default TaskCostAction;
