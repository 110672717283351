import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, FormRow, FormInput } from 'fogg/ui';
import { useUser } from 'hooks';
import { triggerError, triggerSuccess } from 'state/actions';
import { pushOrderToS3 } from 'state/actions/orders';
import { useDispatch } from 'redux-react-hook';

const ItemS3PushActions = ({
  order = {},
  id = '',
  collection = '',
  isRejected = false
}) => {
  const dispatch = useDispatch();
  const { id: orderID, status: orderStatus } = order;

  // Get s3 Bucket config from user's org
  const { user = {} } = useUser();
  const { organization = {} } = user;
  const { s3BucketConfigs = [], s3PushEnabled = false } = organization;

  const inProgressStatus = orderStatus === 'in-progress';
  const canPushToS3 = s3PushEnabled && orderID && collection && id && s3BucketConfigs.length > 0 && !inProgressStatus;

  const [pushS3Loading, setPushS3Loading] = useState(false);
  const [pushS3Selecting, setPushS3Selecting] = useState(false);

  // pre-select all active
  const s3BucketSelectOptions = s3BucketConfigs.map((bc) => ({
    value: bc.id,
    label: `${bc.bucketName}/${bc.prefix}`,
    isActive: bc.isActive,
    id: bc.id
  }));
  const [selectedS3BucketOptions, setSelectedS3BucketOptions] = useState(
    s3BucketSelectOptions.filter((x) => x.isActive)
  );

  async function handlePushToS3 () {
    if (!canPushToS3) return;

    // show bucket config multi select
    if (s3BucketConfigs.length > 1) {
      setPushS3Selecting(true);
    } else {
      triggerPushToS3();
    }
  }

  async function triggerPushToS3 () {
    try {
      setPushS3Loading(true);
      const selectedS3BucketConfigIds = selectedS3BucketOptions.map(
        (x) => x.id
      );
      await dispatch(
        pushOrderToS3(orderID, collection, id, selectedS3BucketConfigIds)
      );
      dispatch(triggerSuccess('Successfully initiated push to S3!'));
      setPushS3Selecting(false);
      setPushS3Loading(false);
    } catch (e) {
      dispatch(triggerError('There was a problem initiating push to S3!'));
      setPushS3Loading(false);
    }
  }

  function handleClose () {
    setPushS3Selecting(false);
  }

  return (
    <span>
      <button
        onClick={handlePushToS3}
        className={`archive-link push-to-s3-link ${
          isRejected || !canPushToS3 || pushS3Loading || pushS3Selecting
            ? 'processing'
            : ''
        }`}
      >
        {pushS3Loading
          ? 'Pushing to S3...'
          : pushS3Selecting
            ? 'Select bucket(s)'
            : 'Push to S3'}
      </button>

      {pushS3Selecting && (
        <Form onSubmit={triggerPushToS3}>
          <FormRow className="form-row-slim">
            <FormInput
              id="bucketConfigs"
              name="bucketConfigs"
              type="multiselect"
              clearable={false}
              defaultValue={selectedS3BucketOptions}
              options={s3BucketSelectOptions}
              onChange={(selection) => {
                setSelectedS3BucketOptions(selection);
              }}
            />
          </FormRow>
          <FormRow className="form-row-slim form-row-actions">
            <Button full={true} disabled={selectedS3BucketOptions.length === 0}>
              Push to S3
            </Button>
            <Button type="text" full={true} onClick={handleClose}>
              Close
            </Button>
          </FormRow>
        </Form>
      )}
    </span>
  );
};

ItemS3PushActions.propTypes = {
  order: PropTypes.object,
  id: PropTypes.string,
  collection: PropTypes.string,
  isRejected: PropTypes.bool
};

export default ItemS3PushActions;
