import { useStaticQuery, graphql } from 'gatsby';

export default function useSiteMetadata () {
  const query = graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          url
          name
          nameShort
          links {
            dataLicensing
            termsOfService
            privacyPolicy
          }
        }
      }
    }
  `;
  const { site = {} } = useStaticQuery(query);
  return site.siteMetadata || {};
}
