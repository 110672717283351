import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { BiMapPin } from 'react-icons/bi';
import { useDispatch } from 'redux-react-hook';
import {
  NavigatorLayout,
  NavigatorBody,
  PageHeader,
  Breadcrumbs
} from 'commonLib';

import {
  triggerNotice,
  patchAOI,
  deleteAOI,
  handleAoiDraw,
  handleAoiEdit
} from 'state/actions';
import { routePathByName, redirectTo } from 'lib/routes';
import { ROUTE_AOIS } from 'data/route-names';
import { logError } from 'lib/logger';
import { isLayerGreaterThanMaxSize } from 'lib/aoi';
import FormCreateEditAoi from 'components/FormCreateEditAoi';
import MapPreview from 'components/MapPreview';
import ModalAOIUpload from 'components/ModalAOIUpload';
import MapPreviewDrawPanel from 'components/MapPreviewDrawPanel';
import { useAoi, useFetchAoi, useEditableHandler, useAoiMapLayers } from 'hooks';

import { triggerLeafletDraw } from 'commonLib/src/lib/map';
import { DEFAULT_MAP_CENTER } from '../../data/aois';

/**
 * AOI Details page, with the ability to edit
 * @param {object} props
 * @param {string} aoiId ID of the AOI, included via url wildcard
 */
function EditAOIPage ({ aoiId }) {
  const dispatch = useDispatch();
  const mapRef = useRef();
  const featureRef = useRef();

  const { isLoading: isLoadingFetch = false } = useFetchAoi({ id: aoiId });
  const { aoi, aoiFeatureState, updateAoiFeatureState } = useAoi(aoiId);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [isLargeAOISize, setIsLargeAOISize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { addLayerToFeatureGroup } = useAoiMapLayers({ featureRef });
  const [isLayerEdit, setIsLayerEdit] = useState(false);

  const breadcrumbs = [
    {
      label: 'AOIs',
      to: routePathByName('aois')
    }
  ];

  async function handleSubmit (payload) {
    const data = {
      ...payload,
      geometry: aoiFeatureState.geometry
    };

    // handle injecting radius property into the payload
    if (aoiFeatureState.properties.radius) {
      data.properties.radius = aoiFeatureState.properties.radius;
    }
    // handle injecting subType property into the payload
    if (aoiFeatureState.properties.subType) {
      data.properties.subType = aoiFeatureState.properties.subType;
    }

    try {
      setIsLoading(true);
      await dispatch(patchAOI(data, aoiId));
      dispatch(
        triggerNotice({
          type: 'success',
          weight: 'bold',
          align: 'center',
          text: 'AOI Successfully Updated!'
        })
      );
      setIsLoading(false);
      redirectTo(routePathByName(ROUTE_AOIS));
    } catch (e) {
      setIsLoading(false);
      logError(e.message, data);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'We had a problem updating your AOI! Please try again'
        })
      );
    }
  }

  async function handleDelete () {
    if (!aoiId) return;
    try {
      setIsLoading(true);
      await dispatch(deleteAOI(aoiId));
      setIsLoading(false);
      redirectTo(routePathByName(ROUTE_AOIS));
    } catch (e) {
      setIsLoading(false);
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'We weren\'t able to delete your AOI! Please try again'
        })
      );
    }
  }
  // Clears any previously uploaded layers after drawing new shape
  // NOTE: leafletElement.clearLayers() prevents drawing new shape for some reason
  function clearMapLayers () {
    if (!featureRef.current) return;

    const { leafletElement } = featureRef.current;
    const drawnItems = leafletElement._layers;

    if (Object.keys(drawnItems).length > 1) {
      Object.keys(drawnItems).forEach((layerid, index) => {
        if (index > 0) return;
        const layer = drawnItems[layerid];
        featureRef.current.leafletElement.removeLayer(layer);
      });
    }
  }

  // Draw (or Edit Draw) callback handler, after validating shape area
  // the leafletElement is converted to GeoJSON
  async function handleOnDraw (layer, leafletElement) {
    if (!leafletElement) return;
    await leafletElement.clearLayers();
    await clearMapLayers();
    const aoiGeometry = await dispatch(handleAoiDraw(leafletElement, layer));
    updateAoiFeatureState(aoiGeometry);
  }

  // updateAoiFeatureState after file upload
  async function handleUpload (feature) {
    const { leafletElement } = featureRef.current;
    clearMapLayers();
    leafletElement.clearLayers();

    updateAoiFeatureState(feature);
  }

  // updateAoiFeatureState after layer edit
  const { handleEditing } = useEditableHandler({
    editableHandler: async (layer, leafletElement) => {
      setIsLayerEdit(true);
      clearMapLayers();
      const { lat, lng } = layer.getBounds().getCenter();
      // stores changes on a short delay after last edit
      const aoiGeometry = await dispatch(handleAoiEdit(leafletElement, layer));
      updateAoiFeatureState({
        ...aoiGeometry,
        properties: {
          ...aoiGeometry.properties,
          centroid: { coordinates: [lng, lat] }
        }
      });
    }
  });

  // updateAoiFeatureState on aoi load
  useEffect(() => {
    if (aoi && !isLoadingFetch && !isLoaded) {
      updateAoiFeatureState(aoi);
      setIsLoaded(true);
    }
  }, [aoi, isLoadingFetch, isLoaded]);

  // Display aoi geometry (existing or uploaded) on map
  useEffect(() => {
    if (!aoiFeatureState?.geometry) return;

    const { leafletElement } = featureRef.current;
    if (aoiFeatureState?.geometry) {
      leafletElement.clearLayers();
    }

    // Try to add the layer to the map
    try {
      addLayerToFeatureGroup(aoiFeatureState, {
        afterLayerAdded: (addedLayer) => {
          // center the map on feature center
          if (isLayerEdit) {
            const center = leafletElement?.getBounds()?.getCenter();
            if (center) {
              mapRef.current.panTo(center);
            }
          } else {
            // fit map bounds to the layer
            mapRef.current.fitBounds(leafletElement.getBounds().pad(1.0));
          }
          setIsLargeAOISize(isLayerGreaterThanMaxSize(addedLayer));
        },
        makeEditable: true,
        editableHandler: handleEditing
      });
    } catch (err) {
      logError('Error adding AOI to map layers.', {
        message: err.message,
        aoiDetails: aoiFeatureState
      });
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: "We weren't able to display your AOI on the map! Please try again"
        })
      );
    }
  }, [aoiFeatureState]);

  const handleMapEffect = ({ leafletElement: map } = {}) => {
    mapRef.current = map;
  };

  /**
   * Handler for the custom MapPreview draw buttons, also resets any previously uploaded GeoJSON
   * We currently disable draw if user uploads a GeoJSON bc Edit doesn't play nice
   * @param {string} shape - Leaflet shape types e.g. 'rectangle', 'polygon', 'circle', 'marker'
   * @param {object} e - click event object
   */
  async function handleDrawClick (shape, e) {
    triggerLeafletDraw(shape, e);
  }

  return (
    <NavigatorLayout access='user' className="layout-navigator split-map-view">
      <Helmet bodyAttributes={{ class: 'edit-aoi' }}>
        <title>Edit AOI</title>
      </Helmet>
      <ModalAOIUpload
        isOpen={openUploadModal}
        handleClosed={() => setOpenUploadModal(!openUploadModal)}
        onUploadComplete={handleUpload}
      />
      <NavigatorBody className="create-aoi-body layout-navigator-body">
        <section className="create-aoi-container">
          <Breadcrumbs items={breadcrumbs} />

          <PageHeader
            title="Edit AOI"
            icon={<BiMapPin className="icon-bi" />}
          />
          <FormCreateEditAoi
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            aoi={{
              ...aoiFeatureState,
              isLargeAOISize
            }}
            isEdit={true}
            isLoading={isLoading || isLoadingFetch}
          />
        </section>
      </NavigatorBody>
      <section className="aois-map">
        <MapPreview
          center={DEFAULT_MAP_CENTER}
          emptyMap={true}
          useMapEffect={handleMapEffect}
          fitGeoJson={false}
          displayAccessRequests={false}
          displayAOIDetails={false}
          disableDraw={false}
          disableEdit={true} // Default edit mode is disabled in favor of automatic edit mode
          onDrawCreated={handleOnDraw}
          featureRef={featureRef}
          drawControlOptions={{
            circle: true,
            circlemarker: false,
            marker: false,
            polygon: true,
            polyline: false,
            rectangle: true
          }}
          zoom={3}
        >
          <MapPreviewDrawPanel
            triggerUploadModal={() => setOpenUploadModal(!openUploadModal)}
            handleDraw={handleDrawClick}
          />
        </MapPreview>
      </section>
    </NavigatorLayout>
  );
}

EditAOIPage.propTypes = {
  aoiId: PropTypes.string
};

export default EditAOIPage;
