import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { Modal, Button } from 'fogg/ui';

import { updateTaskStatusById, triggerError } from 'state/actions';
import { logError } from 'lib/logger';

const CancelTaskModal = ({ name, modal = {}, task }) => {
  const dispatch = useDispatch();
  const { handleModalClose, state } = modal;
  const modalState = state.modals[name];
  const { id: taskId } = task;

  /**
   * closeModal
   */

  function closeModal () {
    handleModalClose(
      {
        currentTarget: {
          dataset: {}
        }
      },
      name
    );
  }

  // Cancel Task API call
  function handleTaskCancel (id) {
    dispatch(
      updateTaskStatusById(id, {
        status: 'cancelled'
      })
    )
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        logError(error.message, {
          taskId: id
        });
        dispatch(triggerError());
      });
  }

  return (
    <Modal
      name={name}
      handleCloseModal={handleModalClose}
      isOpen={modalState.isOpen}
      appElement="#___gatsby"
    >
      <div className="cancel-modal">
        <h2>Are you sure you want to cancel this task?</h2>
        <p className="text-center">
          <Button onClick={() => handleTaskCancel(taskId)}>
            Yes, Cancel Task
          </Button>
          <Button type="text" onClick={closeModal}>
            No, Nevermind
          </Button>
        </p>
      </div>
    </Modal>
  );
};

CancelTaskModal.propTypes = {
  name: PropTypes.string,
  modal: PropTypes.object,
  task: PropTypes.object
};

export default CancelTaskModal;
