import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { Button, DataTable } from 'fogg/ui';
import { useTableData } from 'fogg/hooks';
import { childTasksToTableData } from 'lib/repeatRequests';
import { taskCanBeCancelled } from 'lib/tasks';
import { logError } from 'lib/logger';
import { updateTaskStatusById, triggerError } from 'state/actions';

const RepeatChildTasksTable = ({ tasks = [], isLoading = false }) => {
  const taskData = childTasksToTableData(tasks);
  const dispatch = useDispatch();

  const TASKS_COLUMNS = [
    {
      Header: 'Task Name',
      columnId: 'name',
      widthRatio: 3
    },
    {
      Header: 'Window Open',
      columnId: 'windowOpen',
      widthRatio: 2
    },
    {
      Header: 'Window Close',
      columnId: 'windowClose',
      widthRatio: 2
    },
    {
      Header: 'Status',
      columnId: 'status',
      widthRatio: 1
    },
    {
      columnId: 'statusAndId',
      Header: false,
      widthRatio: 1,
      align: 'right',
      canSort: false,
      canFilter: false,
      Cell: (data) => {
        const { cell: { value: statusAndId = {} } = {} } = data;
        const { status = '', id: taskId = '' } = statusAndId;
        const canBeCancelled = taskCanBeCancelled(status);

        if (canBeCancelled) {
          return (
            <Button
              className="button-text-negative whitespace-nowrap"
              onClick={() => {
                dispatch(
                  updateTaskStatusById(taskId, {
                    status: 'cancelled'
                  })
                ).catch((error) => {
                  logError(error.message, {
                    taskId: taskId
                  });
                  dispatch(triggerError(`Unable to cancel task ${taskId}.`));
                });
              }}
              data-testid="btn-cancel"
            >
              Cancel Task
            </Button>
          );
        }

        return null;
      }
    },
    {
      columnId: 'actions',
      Header: false,
      widthRatio: 1,
      align: 'right',
      type: 'action',
      canSort: false,
      canFilter: false
    }
  ];

  const { columns, data } = useTableData({
    columns: TASKS_COLUMNS,
    data: taskData
  });

  const emptyLoadingData = [
    {
      name: 'Loading',
      id: 'Loading',
      status: 'Loading'
    }
  ];

  return (
    <DataTable
      label="Tasks"
      columns={columns}
      data={isLoading ? emptyLoadingData : data}
      isLoading={isLoading}
      fixHeightToContent={true}
      rowHeight={55}
    />
  );
};

RepeatChildTasksTable.propTypes = {
  tasks: PropTypes.array,
  isLoading: PropTypes.bool
};

export default RepeatChildTasksTable;
