import clone from 'clone';
import equal from 'fast-deep-equal';
import rewind from '@mapbox/geojson-rewind';
import center from '@turf/center';
import { util, location } from 'fogg/lib';
import { GeoHash } from 'geohash';
import Item from 'commonLib/src/models/item';
import { formatDateTime } from 'lib/datetime';
import { searchCatalog } from 'state/actions';
import { searchFilters, DAY_NIGHT_TIME_RANGES, ALL_VALUES_ITEM } from 'data/search';
import createStore from 'state/createStore';
import ACDIcon from 'commonLib/src/assets/icons/ACD-Icon.svg';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';

const { isEmptyObject } = util;
const { queryParamsToObject, addParamsToUrl, objectToQueryString } = location;

export const SEARCH_RESULTS_LIMIT = 20;

// The client would like to exclude certain collections if a user does a search through the UI but
// does not explicitly choose collections to filter on.
export const DEFAULT_COLLECTIONS_TO_EXCLUDE = [
  'sentinel-s1-l2',
  's1-change',
  'capella-change',
  'capella-bitemp-change'
];

let activeLayerCache = [];
let querySearchTypeString = '';

const radiantEarthLayer = {
  name: 'Radiant Earth',
  projections: 'epsg3857',
  attribution: 'Copyright 2018 Radiant.Earth',
  maxZoom: 18,
  nativeZoom: 18,
  minZoom: 3,
  tileSize: 256
};

export const AVAILABLE_SEARCH_PARAMS = [
  'q',
  'geoJson',
  'properties',
  'textInput',
  'zoom',
  'forceSearch',
  'date',
  'showCart'
];

// These represent the URL query params we're using to build out the search linking
// capabilities and the Lens data props they correlate with

const LENS_PROPERTY_MAP = [
  {
    name: 'textInput',
    param: 'q',
    paramBuilder: (value) => value && value.length > 0 && value
  },
  {
    name: 'activeFilters',
    param: 'properties',
    paramBuilder: (value) => {
      if (!Array.isArray(value) || value.length === 0) return;
      let queryFilters = queryStringFromFilters(value);

      queryFilters = queryFilters.filter(({ value } = {}) => !!value);

      return queryFilters
        .map(({ key, value }) => {
          if (typeof value === 'object') {
            value = JSON.stringify(value);
          }
          return objectToQueryString(
            {
              [key]: value
            },
            { encodeKey: false }
          );
        })
        .join('&');
    }
  },
  {
    name: 'geoJson',
    param: 'geoJson',
    paramBuilder: (value) => JSON.stringify(value),
    excludeIfPresent: ['textInput']
  },
  {
    name: 'zoom',
    param: 'zoom',
    paramBuilder: (value) => value
  },
  {
    name: 'forceSearch',
    param: 'forceSearch',
    paramBuilder: (value) => value
  },
  {
    name: 'date',
    param: 'date',
    paramBuilder: (value) => {
      if (!value || !value.start) return;
      if (typeof value.end === 'number' && !isNaN(value.end)) {
        return JSON.stringify(value);
      } else {
        const dateObj = value;
        dateObj.end = new Date().getTime();
        return JSON.stringify(dateObj);
      }
    }
  },
  {
    name: 'showCart',
    param: 'showCart',
    paramBuilder: (value) => value
  }
];

/**
 * getFilters
 * helper method to reduce code repetition
 */

export function getFilters (geoJson, filters) {
  const errorBase = 'Failed to resolve search';

  const _geometry = geoJson.features && geoJson.features[0].geometry;

  const shift = (point) => {
    point[0] = (((point[0] % 360) + 540) % 360) - 180;
    point[1] = (((point[1] % 180) + 270) % 180) - 90;
  };

  if (
    _geometry &&
    _geometry?.coordinates?.length === 1 &&
    _geometry?.coordinates[0]?.length === 5 &&
    // if  there are 2 unique X coordinates, it is BBOX
    _geometry.coordinates[0]
      .map((pair) => pair[0])
      .filter((value, index, self) => self.indexOf(value) === index).length ===
      2
  ) {
    // This is a bounding box
    const coords = _geometry.coordinates[0];

    // Extract min lat,lon, max lat,lon
    const minLon = coords[0][0];
    const maxLon = coords[2][0];
    const minLat = coords[0][1];
    const maxLat = coords[2][1];
    const minLl = [minLon, minLat];
    const maxLl = [maxLon, maxLat];
    shift(minLl);
    shift(maxLl);
    const newCoords = [
      [minLon, minLat],
      [maxLon, minLat],
      [maxLon, maxLat],
      [minLon, maxLat],
      [minLon, minLat]
    ];
    _geometry.coordinates[0] = newCoords;
    geoJson.features[0].geometry = _geometry;
  } else {
    geoJson = rewind(geoJson);
    // When the user shifts the leaflet map more than 360 lon or 180 lat degrees
    // we need to clamp the drawn coordinates back into the -180...180, -90..90
    if (geoJson.features) {
      for (let i = 0; i < geoJson.features.length; i++) {
        if (geoJson.features[i].geometry.type === 'Point') {
          rewind.coordinates = shift(geoJson.features[i].geometry.coordinates);
        } else if (geoJson.features[i].geometry.type === 'Polygon') {
          for (
            let j = 0;
            j < geoJson.features[i].geometry.coordinates.length;
            j++
          ) {
            for (
              let k = 0;
              k < geoJson.features[i].geometry.coordinates[j].length;
              k++
            ) {
              shift(geoJson.features[i].geometry.coordinates[j][k]);
            }
          }
        }
      }
    }
  }

  // Get the first geometry
  const { features = [] } = geoJson;
  const { geometry } = features[0] || { geometry: undefined };

  const hasFilters = Array.isArray(filters) && filters.length > 0;
  let collections =
    hasFilters && filters.find(({ id }) => id === 'collections');
  let queryFilters;

  if (filters) {
    queryFilters = filtersToQuery(filters);
  }

  if (collections) {
    collections = collections.value;

    // Normalize collections into an array to allow a string to be added. This is
    // mostly for backwards compatibility between the radiolist for filters
    // and the checklist

    if (typeof collections === 'string') {
      collections = [collections];
    }
  }

  if (!!collections && !Array.isArray(collections)) {
    throw new Error(
      `${errorBase}: Invalid collections ${JSON.stringify(collections)}`
    );
  }

  return { geometry, queryFilters, collections };
}

// Map results to points for heatmap
export const searchResultsToHeatmap = (data) => {
  const points = [];
  let countMin = 0;
  let countMax = 0;

  const buckets =
    data &&
    data.aggregations &&
    data.aggregations.large_grid &&
    data.aggregations.large_grid.buckets;

  buckets &&
    buckets.forEach((item) => {
      if (item.doc_count) {
        countMin = Math.min(item.doc_count, countMin);
        countMax = Math.max(item.doc_count, countMax);
      }
    });

  buckets &&
    buckets.forEach((item) => {
      if (item.doc_count) {
        const box = GeoHash.decodeGeoHash(item.key);
        const lat = (box.latitude[0] + box.latitude[1]) / 2;
        const lon = (box.longitude[0] + box.longitude[1]) / 2;
        points.push([lat, lon, item.doc_count / countMax]);
      }
    });
  return points;
};

/**
 * filtersToQuery
 * @description Takes a list of filters and builds a SAT API query
 */

function filtersToQuery (activeFilters) {
  let filterQuery = {};

  activeFilters.forEach((activeFilter) => {
    const filter = searchFilters.find(({ id }) => id === activeFilter.id);
    let value = activeFilter.value;

    if (!value) return;
    if (Array.isArray(value) && (!value.length || value[0] === ALL_VALUES_ITEM)) return;

    if (filter && filter.id === 'frequency_band') {
      value = value.replace(' band', '');
    }

    filterQuery = updateQueryWithFilter(filterQuery, {
      ...filter,
      value
    });
  });

  return filterQuery;
}

/**
 * updateQueryWithFilter
 * @description Given the query and filter, update the query with any filter properties and values
 */

function updateQueryWithFilter (query, filter) {
  let filterQuery = clone(query);
  let { id, name, value } = filter;
  let parent;

  if (Array.isArray(name)) {
    const filters = name.map((key) => ({
      ...filter,
      name: key
    }));
    filters.forEach((activeFilter) => {
      filterQuery = updateQueryWithFilter(filterQuery, activeFilter);
    });
    return filterQuery;
  }

  if (typeof name === 'string' && name.includes('/')) {
    name = name.split('/');
    parent = name[0];
    name = name[1];
  }

  if (parent === 'properties') {
    if (id === 'collection_time') {
      filterQuery[name] = DAY_NIGHT_TIME_RANGES[value];
    } else {
      filterQuery[name] = filterValueToQueryValue(value);
    }
  }

  return filterQuery;
}

/**
 * queryStringFromFilters
 */

export function queryStringFromFilters (filters = {}) {
  const queryFilters = [];

  Object.keys(filters).forEach((key) => {
    const filter = filters[key];
    const { value } = filter;
    const { name } = searchFilters.find(({ id }) => id === filter.id) || {};
    if (typeof name === 'string') {
      queryFilters.push({
        key: name,
        value
      });
    } else if (Array.isArray(name)) {
      name.forEach((nameInstance) => {
        queryFilters.push({
          key: nameInstance,
          value
        });
      });
    }
  });

  return queryFilters;
}

function filterValueToQueryValue (value) {
  if (value && (value.min || value.max)) {
    return {
      ...(value.min && {
        gte: value.min
      }),
      ...(value.max && {
        lte: value.max
      })
    };
  }

  if (Array.isArray(value)) {
    return {
      in: value
    };
  }

  return {
    eq: value
  };
}

/**
 * mapFeatureCollectionToResults
 * @description Returns a list of arrays given a list of features in atlas item format
 */

export function mapFeatureCollectionToResults ({ features } = {}) {
  if (!Array.isArray(features)) return [];

  return features.map((data = {}) => {
    const item = new Item(data);
    const date = item.propertyByKey('datetime');
    const collection = item.collection;
    const sarInstrumentMode = item.propertyByKey('sar:instrument_mode');
    const viewIncidenceAngle = item.propertyByKey('view:incidence_angle');
    const sarType = item.propertyByKey('sar:product_type');
    const sarPolarizations = item.propertyByKey('sar:polarizations') || [];
    let itemThumbnail = item.thumbnail;
    let itemClassName = '';

    const subLabels = [
      `Start: ${formatDateTime(date)}`,
      `Type: ${sarType}`,
      `Mode: ${sarInstrumentMode || '-'}`,
      `Polarization: ${sarPolarizations.join(', ')}`,
      `Incidence: ${
        viewIncidenceAngle ? viewIncidenceAngle.toFixed(2) : '-'
      }°`
    ];

    // Show Vessel Count instead of "Incidence" if Vessel Detection (VS) result if defined
    if (sarType === 'VS' || sarType === 'VC') {
      const vesselCount = item.propertyByKey('capella:vs:vessel_count');

      subLabels.pop();
      if (vesselCount !== undefined) {
        subLabels.push(`Vessel Count: ${vesselCount}`);
      }
    }

    // ACD specific sub-labels
    if (sarType === 'ACD') {
      // custom ACD icon instead of provided item thumbnail
      itemClassName = 'acd-icon';
      itemThumbnail = ACDIcon;

      // start and end datetime's for the ACD images
      const startDatetime = item.propertyByKey('start_datetime');
      const endDatetime = item.propertyByKey('end_datetime');

      // alter the subLabels array to conform to ACD item
      subLabels.pop();
      subLabels.shift();
      subLabels.unshift(`Start: ${formatDateTime(startDatetime)}`, `End: ${formatDateTime(endDatetime)}`);
    }

    return {
      thumb: itemThumbnail,
      preview: item.preview,
      label: collection,
      sublabels: subLabels,
      to: `/search/details/${collection}/${item.id}`,
      feature: item.feature,
      className: itemClassName
    };
  });
}

/**
 * lensDateToSatTime
 * @description Converts an Lens date object to SAT API friendly string
 * @see http://sat-utils.github.io/sat-api/#search-stac-items-by-simple-filtering-
 */

export function lensDateToSatTime ({ start, end } = {}) {
  let dateStart;
  let dateEnd;
  let dateFull;

  if (start) {
    dateStart = new Date(start).toISOString();
  }

  if (end) {
    dateEnd = new Date(end).toISOString();
    // If End date selected, but no Start, default start to "beginning of time"
    // aka 1/1/1970 epoch time so we get results as expected
    if (!start) {
      dateStart = new Date(0).toISOString();
    }
  }

  // Return either a period of time or
  if (dateStart && dateEnd) {
    dateFull = `${dateStart}/${dateEnd}`;
  } else {
    dateFull = dateStart || dateEnd;
  }

  return dateFull;
}

/**
 * responseHasMoreResults
 * @description Checks to see if the response is capable of loading additional results
 */

export function responseHasMoreResults ({
  page = 1,
  limit = SEARCH_RESULTS_LIMIT,
  matched = 0
} = {}) {
  if (page * limit < matched) return true;
  return false;
}

/**
 * getSearchPropertiesFromQueryString
 * @description
 */

export function getSearchPropertiesFromQueryString (queryString) {
  const propertiesString = decodeURIComponent(queryString);
  const cleaned = propertiesString
    .split('&')
    .filter((i) => i && i !== '')
    .join('&');
  return queryParamsToObject(cleaned);
}

/**
 * createFiltersFromProperties
 * @description
 */

export function createFiltersFromProperties (properties = {}) {
  const propertyKeys = Object.keys(properties);

  return propertyKeys
    .filter((property) => !!property)
    .map((property) => {
      let value = properties[property];

      // Try to check and see if we're passing an object / JSON into
      // the filter value by trying a parse on the value. If it works
      // we'll set our value to it but if it fails we just ignore and
      // continue with the original value

      try {
        value = JSON.parse(value);
      } catch (e) {}

      return {
        name: property,
        value
      };
    });
}

/**
 * filtersFromQueryString
 * @description
 */

export function filtersFromQueryString (string, filters = []) {
  const properties = getSearchPropertiesFromQueryString(string);

  // The filters are set up with the ability to set up multiple keys
  // as the name, allowing us to have 1 ID set multiple values, if
  // a pair will and should always remain the same. This will go through
  // our properties and add any that are missing so that we can make sure
  // our filter query will include that as part of it's search

  Object.keys(properties).forEach((name) => {
    const value = properties[name];

    // Find any filters that include the name of our property

    const filtersWithProperty = filtersByName(name);

    // Loop through any of our matches and if our properties object doesn't
    // currently have the property value set, go ahead and add it

    filtersWithProperty.forEach(({ name: filterNames } = {}) => {
      if (!Array.isArray(filterNames)) {
        filterNames = [filterNames];
      }
      filterNames.forEach((filterName) => {
        if (!properties[filterName]) {
          properties[filterName] = value;
        }
      });
    });
  });

  const propertyFilters = createFiltersFromProperties(properties);

  return propertyFilters.map((filter) => {
    const match = filters.find(
      ({ name } = {}) => name && name.includes(filter.name)
    );
    return {
      ...filter,
      id: match && match.id
    };
  });
}

/**
 * filtersByName
 * @description Finds a filter definition by it's name
 */

function filtersByName (filterName) {
  return searchFilters.filter(
    ({ name } = {}) => name && name.includes(filterName)
  );
}

/**
 * buildTileEndpoint
 * @description
 */

export function buildTileEndpoint ({ url }) {
  const jwt = document.cookie.split(';').reduce((cookies, cookie) => {
    const [name, value] = cookie.split('=').map((c) => c.trim());
    cookies[name] = value;
    return cookies;
    // eslint-disable-next-line dot-notation
  }, {})['jwt'];

  const tileUri = url.replace('*', '{z}/{x}/{y}.png');
  const params = {
    jwt: jwt,
    nodata: '0'
  };
  return addParamsToUrl(tileUri, params);
}

/**
 * setActiveItemTiles
 * @description Given the map and controls, constructs tiles out of the given items and adds to map
 */

export function setActiveItemTiles ({ map, control, tileLayer, items = [] }) {
  const errorBase = 'Failed to set active item tiles';

  if (!map) {
    throw new Error(`${errorBase}: Invalid map`);
  }

  if (!control) {
    throw new Error(`${errorBase}: Invalid control`);
  }

  if (!tileLayer) {
    throw new Error(`${errorBase}: Invalid tile layer`);
  }

  // Find all the items that don't already appear in our cache so we can
  // add them to the map. If it exists in the cache, we don't want to try to
  // keep reading

  const itemUpdates = items.filter((item) => {
    const { id } = item;
    const match = activeLayerCache.find((layer) => layer.id === id);
    return !match;
  });

  // Find the layers that are in cache but no longer active and store them
  // to clean up

  const garbageLayers = activeLayerCache.filter((layerConfig) => {
    const { id } = layerConfig;
    const match = items.find((item) => item.id === id);
    return !match;
  });

  // Go through each item update, construct a layer, and add it ot the map

  itemUpdates.forEach((item) => {
    const { id, assets: { preview: { href } = {} } = {} } = item;
    const endpoint =
      href &&
      buildTileEndpoint({
        url: href
      });

    // If we couldn't figure out how to build a tile layer endpoint, then
    // there's nothing we can do here.

    if (!endpoint) return;

    const layer = tileLayer(endpoint, radiantEarthLayer);

    addMapOverlay({
      map,
      control,
      layerConfig: {
        id,
        layer
      }
    });
  });

  // Loop through all of our garbage layers and clean it out

  garbageLayers.forEach((layer) => {
    removeMapOverlay({
      map,
      control,
      layerConfig: layer
    });
  });
}

/**
 * addMapOverlay
 * @description Adds a layer to the active map's control group
 */

export function addMapOverlay ({ map, control, layerConfig }) {
  const { layer } = layerConfig;
  map.addLayer(layer);
  control.addOverlay(layer);
  activeLayerCache.push(layerConfig);
}

/**
 * removeMapOverlay
 * @description Removes a layer from the active map's control group
 */

export function removeMapOverlay ({ map, control, layerConfig }) {
  const { layer, id } = layerConfig;
  control.removeLayer(layer);
  map.removeLayer(layer);
  activeLayerCache = activeLayerCache.filter((l) => l.id !== id);
}

/**
 * buildSearchParams
 * @description Loops through LENS_PROPERTY_MAP and builds the search params given the data
 */

export function buildSearchParams (searchData = {}) {
  const paramsObject = {};

  let params = LENS_PROPERTY_MAP.map((property = {}) => {
    const { name, param, paramBuilder, excludeIfPresent = [] } = property;
    const searchParameter = searchData[name];

    let exclusions = excludeIfPresent.map((e) => searchData[e]);
    exclusions = exclusions.filter((e) => !!e);

    if (!searchParameter || exclusions.length > 0) return {};

    const value = paramBuilder(searchParameter);

    if (!value) return {};

    return {
      key: param,
      value: paramBuilder(searchParameter)
    };
  });

  params = params.filter((param) => !!param && !isEmptyObject(param));

  params.forEach(({ key, value } = {}) => {
    if (!key) return;
    paramsObject[key] = value;
  });

  return paramsObject;
}

/**
 * searchArgsToUrlParams
 * @description Given an array of search history items, returns the array with search urls
 */

export function searchArgsToUrlParams (args = {}) {
  const {
    textInput,
    geoJson,
    filters: activeFilters,
    zoom,
    activeSearchOption,
    date
  } = args;

  const params = buildSearchParams({
    textInput,
    activeFilters,
    geoJson,
    zoom,
    activeSearchOption,
    date
  });

  return params;
}

/**
 * compareSearchParams
 * @description Compares the 2 given objects, checking only the available search params, if the objects are equal
 */

export function compareSearchParams (one, two, { exclude = [] } = {}) {
  let isSame = true;

  AVAILABLE_SEARCH_PARAMS.forEach((param) => {
    const paramOne = one[param];
    const paramTwo = two[param];

    if (exclude.includes(param)) return;

    // Simple check if one exists and the other doesn't

    if ((paramOne && !paramTwo) || (!paramOne && paramTwo)) {
      isSame = false;
      return;
    }

    // If we're still here, do a deep equality check on the values

    if (!equal(paramOne, paramTwo)) {
      isSame = false;
    }
  });

  return isSame;
}

/**
 * compareSearchCoordinates
 */

export function compareSearchCoordinates (one, two) {
  let isSame = true;

  if (!equal(one, two)) {
    isSame = false;
  }

  return isSame;
}

/**
 * resetSearchType
 */

export async function resetSearchType (type) {
  querySearchTypeString = type;
}

/**
 * querySearchType
 */

export const querySearchType = querySearchTypeString;

/**
 * mapGeocodeCandidates
 */

export function mapGeocodeCandidates ({
  place_name: placeName,
  center = [],
  bbox
} = {}) {
  const [x, y] = center;

  const candidate = {
    label: placeName,
    sublabel: `Location: ${x.toFixed(3)}, ${y.toFixed(3)}`,
    value: {
      x,
      y
    },
    bbox
  };
  return candidate;
}

export function coordinateFeature (lng, lat) {
  return {
    center: [lng, lat],
    geometry: {
      type: 'Point',
      coordinates: [lng, lat]
    },
    place_name: 'Lng: ' + lng + ' Lat: ' + lat,
    place_type: ['coordinate'],
    properties: {},
    type: 'Feature'
  };
}

function geometryToGeoJson (geometry) {
  if (!geometry || !geometry.type) {
    throw new Error(
      'Failed to convert geometry to GeoJson: Invalid geometry type'
    );
  }
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: geometry
      }
    ]
  };
}

/**
 * getGeoJsonCenter
 * @description
 */

function getGeoJsonCenter (geoJson) {
  if (!geoJson || !geoJson.type) {
    throw new Error('Failed to get geoJson center: Invalid geoJson type');
  }
  return center(geoJson);
}

async function resolveSearch (searchData, searchString) {
  const store = createStore();
  let response;
  // Only hit /search when query is valid ID (ie. 36 characters)
  try {
    response = await store.dispatch(searchCatalog(searchData, false));
  } catch (e) {
    throw new Error(`Failed to get search results: ${e}`);
  }
  const { features = [] } = response;
  let featuresArray = [];
  // If we get a feature back we need to convert it to format map can use
  if (features.length) {
    featuresArray = features.map(({ feature }) => {
      const { bbox, geometry } = feature;
      const geoJson = geometryToGeoJson(geometry);
      const center = getGeoJsonCenter(geoJson);
      return {
        place_name: searchString,
        bbox: bbox,
        center: center.geometry.coordinates
      };
    });

    return featuresArray.map(mapGeocodeCandidates);
  } else {
    return [];
  }
}

/**
 * resolveCollectionSearch
 * @description Triggers a request to /search by collection ID,
 * then returns features array for Mapbox autocomplete and ultimately to plot on map
 */

export async function resolveCollectionSearch (query) {
  querySearchTypeString = query.type;
  // Hit /catalog/search with collect ID (needs to be lowercase)
  const { searchString } = query;

  const searchData = {
    query: {
      'capella:collect_id': {
        eq: searchString.toLowerCase().trim()
      }
    },
    zoom: 'auto',
    limit: SEARCH_RESULTS_LIMIT,
    page: 1
  };

  return resolveSearch(searchData, searchString);
}

/**
 * resolveItemIdSearch
 * @description Triggers a request to /search by item ids,
 * then returns features array for Mapbox autocomplete and ultimately to plot on map
 */
export async function resolveItemIdSearch (query) {
  querySearchTypeString = query.type;
  // Hit /catalog/search with collect ID
  const { searchString } = query;

  // catalog likes STAC ID's to be uppercase
  const itemIds = searchString.toUpperCase().split(',');

  if (!Array.isArray(itemIds)) {
    throw new Error('Failed to get search results');
  }

  const searchData = {
    ids: itemIds,
    zoom: 'auto',
    limit: SEARCH_RESULTS_LIMIT,
    page: 1
  };

  return resolveSearch(searchData, itemIds.join(' '));
}

/**
 * Helper that takes in a search item feature from SidebarSearch or SidebarDetails
 * Checks for derived_from, field & if found include that in the array of items to
 * ultimately add to the cart.
 * @param {object} feature={}
 */
export const returnDerivedCartItems = (feature = {}) => {
  const checked = [];
  const { collection, id, links = [], properties = {} } = feature;
  // Add directly to cart as long as we have collection and item ID's
  if (collection && id) {
    const item = {
      collectionId: collection,
      itemId: id
    };
    checked.push(item);
  }

  // For Analytics we want to auto-add the corresponding GEO item
  // 1. check for the "derived_from" field in links
  const derivedObjects = [];
  links.forEach((link) => {
    if (link.rel === 'derived_from') {
      derivedObjects.push(link);
    }
  });

  // 2. Verify the item is an Analytic
  const isAnalytic = properties['sar:product_type'] && AnalyticProducts.isAnalytic(properties['sar:product_type']);

  // 3. If 1 + 2 above are true, tentatively add to cart (without collection yet)
  if (derivedObjects.length && isAnalytic) {
    derivedObjects.forEach((derivedObject) => {
      checked.push({
        itemId: derivedObject.id
      });
    });
  }

  return checked;
};
