import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useTask, useTasks } from 'hooks';
import { logError } from 'lib/logger';
import {
  fetchTaskByTaskId,
  fetchTasksByTaskIds,
  fetchTaskAndConflictedTaskByIDs,
  triggerNotice
} from 'state/actions';
import { TASKING_REQUEST_ID } from 'data/property-names';

/**
 * fetch a single task from the tasking api, with an option to retry
 * @param {string} taskId
 * @param {bool} [forceNew=false] - forces call to the api
 * @param {bool} [retryOptions={}] - optional options for retrying the fetch
 * @param {bool} [retryOptions.quietNotice=false] - suppresses the notice on the UI on error
 * @param {bool} [retryOptions.retryOnFail=false] - retry fetch on failure response
 * @param {number|null} [retryOptions.retryOnResponseCode="GENERAL_API_ERROR"] - retry fetch on specific failure response code (i.e., 'GENERAL_API_ERROR')
 * @param {number} [retryOptions.retriesAllowed=10] - number of retries before quit, can be Infinity
 * @param {number} [retryOptions.retryWait=5000] - wait interval in milliseconds
 * @param {string} [conflictId]
 */
export default function useFetchTask (
  taskId,
  forceNew = false,
  {
    quietNotice = false,
    retryOnFail = false,
    retryOnResponseCode = 'GENERAL_API_ERROR',
    retriesAllowed = 10,
    wait = 5000
  } = {},
  conflictId
) {
  const dispatch = useDispatch();
  const retryRef = useRef({}); // ref to hold the retry abort signal
  const [conflicts, setConflicts] = useState([]);
  const hasFetchedConflicts = useRef(false);

  const { actions = {} } = useTasks();
  const task = useTask(taskId) || {};

  const { properties = {}, conflictingTasks } = task;
  const taskingRequestId = properties[TASKING_REQUEST_ID];

  function waitBetweenRetry () {
    return new Promise((resolve) => setTimeout(resolve, wait));
  }

  function doDispatch (tries = retriesAllowed) {
    // check for abort signal
    if (retryRef?.current?.abort === true) return;

    const numberOfRetries = tries - 1;

    function caughtError (error) {
      const { error: { code: errorResponseCode = retryOnResponseCode } = {} } =
        error;
      logError(error.message);

      // trigger notice on error unless explicitly specified,
      // and if a retryOnResponseCode is set, and the returned error response code is not the same
      if (
        !quietNotice ||
        (quietNotice &&
          retryOnResponseCode &&
          errorResponseCode !== retryOnResponseCode)
      ) {
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      }

      /**
       * retry on failure
       *  condition 1: retryOnFail is truthy
       *  condition 2: retriesAllowed is not exceeded
       *  condition 3: error response code matches exactly retryOnResponseCode (if retryOnResponseCode is truthy)
       */
      if (
        retryOnFail &&
        numberOfRetries > 0 &&
        retryOnResponseCode &&
        errorResponseCode === retryOnResponseCode
      ) {
        return waitBetweenRetry().then(() => {
          return doDispatch(numberOfRetries);
        });
      }
    }

    return dispatch(fetchTaskByTaskId(taskId)).catch(caughtError);
  }

  useEffect(() => {
    if (
      !taskId ||
      (!!taskId && !!conflictId) ||
      (taskingRequestId && !forceNew) ||
      retryRef?.current.abort
    ) {
      return;
    }

    // initial try
    doDispatch();

    // cleanup
    return () => {
      if (retryRef?.current) {
        retryRef.current = { abort: true };
      }
    };
  }, [dispatch, taskId, conflictId, taskingRequestId, forceNew, retryRef]);

  async function fetchConflictingTasks (taskIds) {
    try {
      const res = await dispatch(fetchTasksByTaskIds(taskIds, true));
      setConflicts(res);
      hasFetchedConflicts.current = true;
    } catch (e) {
      setConflicts([]);
      hasFetchedConflicts.current = true;
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
    }
  }

  async function fetchTaskWithConflicted () {
    await dispatch(fetchTaskAndConflictedTaskByIDs(taskId, conflictId))
      .catch((e) => {
        logError(e.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      });
  }

  const taskIds = conflictingTasks?.length
    ? conflictingTasks.map((conflict) => conflict[TASKING_REQUEST_ID])
    : [];

  useEffect(() => {
    if (taskIds && taskIds.length > 0 && !hasFetchedConflicts.current) {
      fetchConflictingTasks(taskIds);
      hasFetchedConflicts.current = true;
    }
  }, [taskIds]);

  useEffect(() => {
    if (!!taskId && !!conflictId) {
      fetchTaskWithConflicted();
    }
  }, [dispatch, taskId, conflictId]);

  return {
    ...actions.fetchTaskByTaskId,
    task,
    fetchConflictingTasksActions: actions.fetchTasksByTaskIds,
    conflictingTasks: conflicts
  };
}
