import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTask } from 'hooks';
import { initDefaultTaskValues } from 'lib/tasks';
import { NEW_TASK } from 'data/action-types';
import { TaskRequestTypes } from 'data/task-request-types';

const TaskContext = React.createContext();

function TaskContextProvider ({ children }) {
  const IS_POINT_DEFAULT = true;
  const [isPoint, setIsPoint] = useState(IS_POINT_DEFAULT);
  const {
    windowOpenInt: defaultWindowOpen,
    windowCloseInt: defaultWindowClose,
    collectMode: defaultCollectMode
  } = initDefaultTaskValues({ isPoint });

  const newTask = useTask(NEW_TASK);

  const defaultNewTask = {
    ...newTask,
    windowOpenInt: defaultWindowOpen,
    windowCloseInt: defaultWindowClose,
    collectMode: defaultCollectMode
  };

  const [taskState, setTaskState] = useState(defaultNewTask);

  function changeTaskRequestType (requestType) {
    setTaskState((prevState) => {
      const defaultTaskValues = initDefaultTaskValues({
        isRepeat: requestType === TaskRequestTypes.REPEAT,
        isOosm: requestType === TaskRequestTypes.OOSM,
        isPoint,
        persistSharedValues: true // Don't reset Name & Description
      });

      return {
        ...defaultTaskValues,
        ...prevState,
        collectConstraints: {
          ...defaultTaskValues.collectConstraints,
          ...prevState.collectConstraints
        },
        repetitionProperties: {
          ...defaultTaskValues.repetitionProperties,
          ...prevState.repetitionProperties
        },
        taskRequestType: requestType
      };
    });
  }

  /**
   * updates state change for task and collectConstraints
   * @param {object} newTaskState - value to update
   */
  function updateTaskState (newTaskState) {
    setTaskState((prevTaskState) => {
      const {
        collectConstraints: prevCollectConstraints = {},
        repetitionProperties: prevRepetitionProperties = {},
        ...prevTaskProps
      } = prevTaskState;

      const {
        collectConstraints: nextCollectConstraints = null,
        repetitionProperties: nextRepetitionProperties = null,
        ...nextTaskProps
      } = newTaskState;

      return {
        ...prevTaskProps,
        ...nextTaskProps,
        collectConstraints: {
          ...prevCollectConstraints,
          ...nextCollectConstraints
        },
        repetitionProperties: {
          ...prevRepetitionProperties,
          ...nextRepetitionProperties
        }
      };
    });
  }

  /**
   * resets task state with optional default value
   * @param {object} initTaskValue - initial task value after reset
   */
  function resetTaskState (initTaskValue = {}) {
    setTaskState(initTaskValue);
  }

  // when isPoint changes, it's a new search, which is the start point for a possible new task request
  useEffect(() => {
    setTaskState(defaultNewTask);
  }, [isPoint]);

  return (
    <TaskContext.Provider
      value={{
        taskState,
        updateTaskState,
        resetTaskState,
        changeTaskRequestType,
        setIsPoint,
        IS_POINT_DEFAULT
      }}
    >
      {children}
    </TaskContext.Provider>
  );
}

function useTaskContext (taskEntry) {
  const context = React.useContext(TaskContext);

  if (context === 'undefined') {
    throw new Error(
      'useTaskCreateForm must be used within a TaskCreateFormProvider.'
    );
  }

  return context;
}

TaskContextProvider.propTypes = {
  children: PropTypes.node
};

export { TaskContextProvider, useTaskContext };
