import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'fogg/ui';
import { FaTrash } from 'react-icons/fa';
import { fillEmptyItem } from 'lib/data';
import { isEmptyObject } from 'lib/util';
import { ORDER_STATUS_MAP } from 'data/orders';
import { useFetchStac } from 'hooks';
import AssetLinks from 'components/AssetLinks';
import ItemOverview from 'components/ItemOverview';
import ItemS3PushActions from 'components/ItemS3PushActions';

/**
 * Reusable component to display order items as cards, optionally with "children"
 * Also conditionally can display the asset/download links
 * @param {object} props
 * @param {object} [props.assets={}]
 * @param {object} [props.item={}]
 * @param {string} [props.className='']
 * @param {object} [props.assetSettings={}]
 * @param {object} [props.actionSettings={}']
 * @param {bool} [props.isChild=false]
 * @param {object} [props.archives={}]
 */
const ItemCard = ({
  order = {},
  item = {},
  className = '',
  assetSettings = {},
  actionSettings = {},
  isChild = false,
  archives = {}
}) => {
  const { collection, id, assets } = item;
  const { status } = order;

  // Get the stac record as an asset
  const { stacs, isLoading: stacIsLoading } = useFetchStac({
    collectionId: collection,
    itemId: id
  });

  const stacRecord = stacs.find((s) => s.id === id);
  const stacBlob = new Blob([JSON.stringify(stacRecord)], {
    type: 'application/json'
  });
  const {
    allowDownload = false,
    isLoading,
    excludeTypes = []
  } = assetSettings;

  const { isRemovable = false, isDisabled = false, removeItem } = actionSettings;

  const isRejected = status === 'rejected';
  const isFailed = status === 'failed';
  const isInProgress = status === 'in-progress';

  // If we have assets passed in from the original item prop, use those, as they're
  // most likely assets prepared for download. Otherwise use what we fetched

  let activeItemAssets =
    assets && !isEmptyObject(assets) ? assets : item.assets;

  const archivesDownload = archives && !isEmptyObject(archives) ? archives : '';

  const archiveSets = [];
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(archivesDownload)) {
    archiveSets.push({
      message: value[0].message,
      url: value[0].url,
      granule: value[0].granule
    });
  }

  let specificItem = archiveSets.filter(
    (unique) => unique.granule === item.id
  );

  if (Object.keys(specificItem).length === 0) {
    specificItem = [{ message: 'processing' }];
  }

  let archiveLink = specificItem.map((i) => {
    return (
      <span key={`${i}`} className={`archive-link ${i.message}`}>
        {i.url ? <a
          className={`archive-link-${i.message}`}
          href={i.url}
          target="_blank"
          rel="noreferrer"
        >
          {i.message === 'finished'
            ? 'Download All Assets'
            : 'Preparing One-Click Download'}
        </a> : 'Preparing Assets'}
      </span>
    );
  });

  if (isRejected || isFailed) {
    specificItem = [{ message: status }];
    archiveLink = (
      <span className="archive-link processing">{status}</span>
    );
  }

  // Add in the stac item as an asset
  if ((!!stacRecord && !isLoading && !stacIsLoading) && activeItemAssets) {
    activeItemAssets.stac = {
      title: 'STAC File',
      href: URL.createObjectURL(stacBlob),
      download: item.id + '.json'
    };
  }

  // If we're in a loading state, fill the card with placeholder item assets
  if (isLoading || stacIsLoading) {
    activeItemAssets = fillEmptyItem().assets;
  }

  // If we want to exclude any types from our assets, delete them by key

  if (Array.isArray(excludeTypes) && excludeTypes.length > 0) {
    excludeTypes.forEach((type) => {
      if (activeItemAssets && activeItemAssets[type]) {
        delete activeItemAssets[type];
      }
    });
  }

  const cardActions = [];

  if (isRemovable) {
    cardActions.push({
      label: 'Remove Item',
      icon: <FaTrash />,
      action: handleRemoveItem,
      disabled: isDisabled
    });
  }

  /**
   * handleRemoveItem
   * @description Fires when an item is removed
   */

  function handleRemoveItem () {
    if (typeof removeItem === 'function') {
      removeItem(item);
    }
  }

  let itemCardClass = `item-card-wrapper ${className}`;
  if (isChild) itemCardClass += ' is-child';
  return (
    <div
      id={`${collection}/${id}`}
      className={itemCardClass}
      data-has-actions={cardActions.length > 0}
    >
      <div className="item-card">
        <ItemOverview
          item={item}
          assets={allowDownload && (
            <div
              className={`item-card-assets ${
                (isLoading || stacIsLoading)
                  ? 'item-card-assets-is-loading'
                  : ''
              }`}
            >
              <div className="item-card-assets-title">
                <span>Asset Downloads</span>
                {allowDownload && (
                  <div className="download-links">
                    {archiveLink}
                    <ItemS3PushActions
                      order={order}
                      id={id}
                      collection={collection}
                      isRejected={isRejected}
                    />
                  </div>)
                }
              </div>
              {isRejected || isFailed || isInProgress ? (
                <div className="item-card-assets-expired">
                  {ORDER_STATUS_MAP[status].message}
                </div>
              ) : (
                <AssetLinks assets={activeItemAssets} />
              )}
            </div>
          )}
        />
      </div>
      {cardActions.length > 0 && (
        <div className="item-card-actions">
          <ul>
            {cardActions.map((cardAction = {}, index) => {
              const { label, action, icon, disabled } = cardAction;
              return (
                <li key={`ItemCardAction-${index}`}>
                  <Button onClick={action} disabled={disabled}>
                    <span className="visually-hidden">{label}</span>
                    {icon}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

ItemCard.propTypes = {
  actionSettings: PropTypes.object,
  assetSettings: PropTypes.object,
  item: PropTypes.object,
  className: PropTypes.string,
  isChild: PropTypes.bool,
  archives: PropTypes.object,
  order: PropTypes.object
};

export default ItemCard;
