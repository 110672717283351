import { device } from 'fogg/lib';
import Cookies from 'js-cookie';
import { validateObjectKeysExist } from '../../lib/util';
import { routePathByName, navigateTo, isPublicPath } from '../../lib/routes';
import { ROUTE_USER_LOG_OUT_EXPIRED } from '../../data/route-names';
import { clearState, COOKIE_PATH, COOKIE_EXPIRATION, COOKIE_DOMAIN } from '../../lib/storage';
import {
  constructRequestActionManager,
  constructFormActionManager
} from '../../lib/actions';
import { dateOffsetBySeconds } from '../../lib/datetime';

const { isDomAvailable } = device;

const REQUIRED_REGISTER_KEYS = ['givenName', 'familyName', 'email', 'address'];

/**
 * setUserActionState
 * @description Updates the user store's status
 */

export function setUserActionState (data) {
  return {
    type: 'UPDATE_USER_ACTION_STATE',
    data
  };
}

const createDispatchRequestAction =
  constructRequestActionManager(setUserActionState);
const createDispatchFormAction = constructFormActionManager(setUserActionState);

/**
 * fetchUser
 * @description Fetches a user's profile
 */

export function fetchUser () {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchUser',
      scope: 'user',
      request: {
        url: routePathByName('apiUser'),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const updatedUserState = {
      ...request.data
    };

    dispatch(_updateUserInternalState(updatedUserState));

    return updatedUserState;
  };
}

/**
 * updateUser
 * @description Updates a user profile given the provided user data
 */

export function updateUser (userData = {}) {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateUser',
      scope: 'user',
      request: {
        url: routePathByName('apiUser'),
        method: 'put',
        data: userData
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const updatedUserState = {
      ...request.data
    };

    dispatch(_updateUserInternalState(updatedUserState));

    return updatedUserState;
  };
}

/**
 * registerUser
 * @description Registers a new user for the application given the provided details
 */

export function registerUser (userData = {}) {
  const name = 'registerUser';

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name,
      scope: 'user',
      request: {
        url: routePathByName('apiUserRegister'),
        method: 'post',
        data: userData,
        dataValidation: (data) => {
          return validateObjectKeysExist(data, REQUIRED_REGISTER_KEYS);
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const { data = {} } = request || {};
    const { id } = data;

    // Only set the ID here, the user can't log in yet so don't
    // do anything aside from the appearance of success

    const updatedUserState = {
      id
    };

    dispatch(_updateUserInternalState(updatedUserState));

    return updatedUserState;
  };
}

/**
 * verifyToken
 * Verify a token is valid
 */

export function verifyToken (token) {
  const name = 'verifyToken';

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name,
      scope: 'user',
      request: {
        url: routePathByName('apiToken'),
        method: 'fetch',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    });

    const { data = {} } = await dispatchAction(dispatch, getState);
    const { message } = data;

    if (message === 'valid' && token) {
      return token;
    } else return undefined;
  };
}

/**
 * verifyRefreshToken
 * @description Checks refreshToken for validity, if valid returns new jwt and we
 * set those in global app state
 */

export function verifyRefreshToken (refreshToken) {
  const tokenValue = refreshToken;
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'verifyRefreshToken',
      scope: 'user',
      request: {
        url: `${routePathByName('apiToken')}/refresh`,
        method: 'post',
        data: { refreshToken: tokenValue }
      }
    });

    const response = await dispatchAction(dispatch, getState);

    const { data = {} } = response || {};
    const { accessToken } = data;
    setTokenCookie(accessToken);
    dispatch(setToken(accessToken));
    // dispatch(setRefreshToken(refreshToken));
    return accessToken;
  };
}

/**
 * fetchToken
 * @description
 */

export function fetchToken (username, password) {
  const name = 'fetchToken';

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name,
      scope: 'user',
      request: {
        url: routePathByName('apiToken'),
        method: 'post',
        options: {
          auth: {
            username,
            password
          }
        }
      }
    });

    const response = await dispatchAction(dispatch, getState);

    const { data = {} } = response || {};
    const {
      accessToken,
      refreshToken,
      challengeName,
      refreshTokenExpirationTs
    } = data;

    const isChallenge = typeof challengeName === 'string';
    if (isChallenge) {
      return data;
    }

    setTokenCookie(accessToken);
    setRefreshCookie(refreshToken, refreshTokenExpirationTs);
    setExpirationCookie(refreshTokenExpirationTs);
    dispatch(setToken(accessToken));
    // dispatch(setRefreshToken(refreshToken));
    // dispatch(setRefreshTokenExpiration(refreshTokenExpirationTs));
    return accessToken;
  };
}

/**
 * putToken
 * @description
 */

export function putToken (challenge) {
  const name = 'putToken';

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name,
      scope: 'user',
      request: {
        url: routePathByName('apiToken'),
        method: 'put',
        data: challenge
      }
    });

    const response = await dispatchAction(dispatch, getState);

    const { data = {} } = response || {};
    const { accessToken } = data;

    return accessToken;
  };
}

/**
 * deleteToken
 * @description Invalidates all user tokens effectively signing them out
 */

export function deleteToken (token, refreshToken) {
  const name = 'deleteToken';

  const requestObject = {
    url: routePathByName('apiToken'),
    method: 'delete',
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  // Optionally invalidate refreshToken
  if (refreshToken) {
    requestObject.data = {
      refreshToken: refreshToken
    };
  }

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name,
      scope: 'user',
      request: requestObject
    });

    const response = await dispatchAction(dispatch, getState);

    dispatch(setToken(undefined));
    // dispatch(setRefreshToken(undefined));
    // dispatch(setRefreshTokenExpiration(undefined));
    return response;
  };
}

/**
 * postPasswords
 * @description
 */

export function postPasswords (email) {
  const name = 'postPasswords';

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name,
      scope: 'user',
      request: {
        url: routePathByName('apiPasswords'),
        method: 'post',
        data: {
          email
        }
      }
    });

    const response = await dispatchAction(dispatch, getState);

    dispatch(resetPassword({ email }));

    return response;
  };
}

/**
 * putPassword
 * @description
 */

export function putPasswords ({
  confirmationCode,
  email,
  password,
  passwordConfirmation
}) {
  const name = 'putPassword';

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name,
      scope: 'user',
      request: {
        url: routePathByName('apiPasswords'),
        method: 'put',
        data: {
          confirmationCode,
          email,
          password,
          passwordConfirmation
        }
      }
    });

    const response = await dispatchAction(dispatch, getState);

    dispatch(resetPassword({ email }));

    return response;
  };
}

/**
 * submitPremiumRequest
 * @description
 */

export const submitPremiumRequest = (formData) => {
  const name = 'submitPremiumRequest';

  if (!isDomAvailable()) {
    throw new Error(`${name}: DOM is unavailable`);
  }

  return async (dispatch, getState) => {
    const { user: userState = {} } = getState();
    const { user = {} } = userState;

    const formFields = [
      {
        name: 'oid',
        value: '00D0x0000000at8'
      },
      {
        name: 'lead_source',
        value: 'Capella Portal'
      },
      {
        name: 'retURL',
        value:
          window.location.origin + routePathByName('userPremiumSignupThankYou')
      },
      {
        name: 'first_name',
        value: user.givenName
      },
      {
        name: 'last_name',
        value: user.familyName
      },
      {
        name: 'email',
        value: user.email
      },
      {
        name: 'company',
        value: user.organization
      }
    ];

    for (const key in formData) {
      if (!Object.prototype.hasOwnProperty.call(formData, key)) continue;
      formFields.push({
        name: key,
        value: formData[key].value
      });
    }

    const dispatchAction = createDispatchFormAction({
      name,
      request: {
        url: 'https://webto.salesforce.com/servlet/servlet.WebToLead',
        data: formFields
      }
    });

    const response = await dispatchAction(dispatch, getState);

    return response;
  };
};

/**
 * resetPassword
 * @description
 */

export const resetPassword = (email) => {
  return {
    type: 'RESET_PASSWORD',
    data: email
  };
};

/**
 * JWT & Refresh Cookie setters
 */
export const setTokenCookie = (token) => {
  Cookies.set('token', token, {
    path: COOKIE_PATH,
    // Should pull in the "expiresIn" value
    expires: dateOffsetBySeconds(COOKIE_EXPIRATION),
    domain: COOKIE_DOMAIN
  });
};

export const setRefreshCookie = (token, expiration) => {
  const now = new Date().getTime() + 30000;
  const cookieSettings = {
    path: COOKIE_PATH,
    domain: COOKIE_DOMAIN
  };
  // An expiration may or may not get passed (but usually should)
  if (expiration) {
    cookieSettings.expires = dateOffsetBySeconds(Math.floor((expiration - now) / 1000));
  }
  Cookies.set('refreshToken', token, cookieSettings);
};

export const setExpirationCookie = (expiration) => {
  const now = new Date().getTime() + 30000;
  Cookies.set('refreshExpiration', expiration, {
    path: COOKIE_PATH,
    expires: dateOffsetBySeconds(Math.floor((expiration - now) / 1000)),
    domain: COOKIE_DOMAIN
  });
};

/**
 * setToken
 * @description
 */

export const setToken = (data) => {
  return {
    type: 'SET_TOKEN',
    data: {
      token: data
    }
  };
};

/**
 * setRefreshToken
 * @description Sets the refreshToken value we get back in order to use once original token expires
 */

export const setRefreshToken = (data) => {
  return {
    type: 'SET_REFRESH_TOKEN',
    data: {
      refreshToken: data
    }
  };
};

/**
 * setRefreshToken
 * @description Sets the refreshToken value we get back in order to use once original token expires
 */

export const setRefreshTokenExpiration = (data) => {
  return {
    type: 'SET_REFRESH_TOKEN_EXPIRATION',
    data: {
      refreshTokenExpiration: data
    }
  };
};

/**
 * checkTokenExpiration
 * @description Verify if refresh token expiration date has passed -- if so log user out
 */
export const checkTokenExpiration = (expiration, path) => {
  if (!expiration || !typeof expiration === 'number' || isPublicPath(path)) return;
  const now = new Date().getTime() + 60000;
  // console.log("EXPIRATION: ", dateOffsetBySeconds(Math.floor((expiration - now) / 1000)));
  // Logout user if token expiration has passed
  if (expiration < now) {
    navigateTo(routePathByName(ROUTE_USER_LOG_OUT_EXPIRED));
    return false;
  }
  return true;
};

/**
 * logOut
 * @description Logs a user out of the application
 */

export const logOut = () => {
  return async (dispatch, getState) => {
    const token = Cookies.get('token');
    const refreshToken = Cookies.get('refreshToken');
    // We need to set the cookie path to / so we can successfully
    // remove the cookie after adding it for the session
    // See: hooks/useTokenCookie.js
    Cookies.remove('token', { path: COOKIE_PATH, domain: COOKIE_DOMAIN });
    Cookies.remove('refreshToken', { path: COOKIE_PATH, domain: COOKIE_DOMAIN });
    Cookies.remove('refreshExpiration', { path: COOKIE_PATH, domain: COOKIE_DOMAIN });
    clearState();
    await dispatch(_destroyUserSession());
    if (token) await dispatch(deleteToken(token, refreshToken));

    return {};
  };
};

/**
 * _destroyUserSession
 * @description Clears out a user's session
 */

export const _destroyUserSession = () => {
  return {
    type: 'USER_DESTROY_SESSION',
    data: undefined
  };
};

/**
 * _updateUserInternalState
 * @description Handles any internal updates to the user state, should only be used
 *     within this file. Exported for testing
 */

export const _updateUserInternalState = (data) => {
  return {
    type: 'UPDATE_USER',
    data
  };
};

/**
 * refreshTokenAndRetry
 * @description Triggers a refresh of a user's token then tries to run the previous action
 */

export async function refreshTokenAndRetry ({
  action,
  dispatchArgs,
  refreshToken
}) {
  await verifyRefreshToken(refreshToken).apply(null, dispatchArgs);
  return action.apply(null, dispatchArgs);
}
