import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAdmin, useAdminOrganizations, useUser } from '../hooks';
import { logError } from '../lib/logger';
import {
  triggerNotice,
  fetchOrganizationsPaged,
  updateAdminOrganizations
} from '../state/actions';

export default function useAdminFetchOrganizations ({ forceNew = false } = {}) {
  const dispatch = useDispatch();

  // Verify user has admin priviledges
  const { user = {} } = useUser();
  const isAdmin = user.isAdmin();

  const organizations = useAdminOrganizations() || [];
  const { actions = {} } = useAdmin();
  const { fetchOrganizationsPaged: fetchOrganizationsAction = {} } = actions;
  const { firstLoad } = fetchOrganizationsAction;

  useEffect(() => {
    if ((firstLoad && !forceNew) || !isAdmin) return;
    // Fetch orgs until we reach end of paged results
    async function fetchOrgs () {
      let totalOrgs;
      try {
        const request = await dispatch(fetchOrganizationsPaged({ page: 1 }));
        const { results = [], currentPage = 1, totalPages = 1 } = request || {};
        totalOrgs = results;

        // 1 page of results...
        if (currentPage === totalPages) {
          dispatch(updateAdminOrganizations(totalOrgs));
          return;
        } else {
          // More than 1 page, so loop through them
          for (let i = 2; i <= totalPages; i++) {
            const { results } = await dispatch(
              fetchOrganizationsPaged({ page: i })
            );
            totalOrgs.push(...results);
            // Update organizations store at the final page
            if (i === totalPages) {
              dispatch(updateAdminOrganizations(totalOrgs));
            }
          }
        }
      } catch (e) {
        logError(e.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      }
    }

    fetchOrgs();
  }, [dispatch, forceNew, firstLoad, isAdmin]);

  return {
    ...actions.fetchOrganizationsPaged,
    organizations
  };
}
