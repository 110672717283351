export const PUT_UPDATE_USER_VALID_FIELDS = [
  'apiEnvironmentRole',
  'disabled',
  'familyName',
  'givenName',
  'organization',
  'organizationId'
];

export const PUT_UPDATE_USER_VALID_ADDRESS_FIELDS = [
  'street',
  'city',
  'state',
  'zip'
];
