import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Notice } from 'fogg/ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { routePathByName } from 'lib/routes';
import { TASK_COLLECT_STATUSES } from 'data/tasks';
import { isPointGeometryType } from 'lib/tasks';
import { useUser, useAreaOfInterest } from 'hooks';
import { ROUTE_REPEAT_REQUEST_DETAILS, ROUTE_TASKS } from 'data/route-names';
import Layout from 'components/Layout';
import Breadcrumbs from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import TaskStatusAnimated from 'components/TaskStatusAnimated';
import TaskConfigTable from 'components/TaskConfigTable';
import MapPreview from 'components/MapPreview';
import AccessRequests from 'components/AccessRequests';

const DEFAULT_BREADCRUMBS = [
  {
    label: 'Tasks',
    to: routePathByName(ROUTE_TASKS)
  }
];

/**
 * renders a stripped down task details component designated for spawned child tasks from repeat requests
 */
const TemplateRepeatRequestChildTask = ({
  task = {},
  breadcrumbs = DEFAULT_BREADCRUMBS,
  state = {}
}) => {
  const [activeTileId, setActiveTileId] = useState();

  const { id: taskId, name, description, repeatRequestId, collectsWithTiles } = task;
  const { isLoading = false } = state;
  const containerType = [];

  if (isLoading) {
    containerType.push('is-loading');
  }

  const geoJson = task.geoJson && task.geoJson();

  const mapRef = useRef();
  const accessesFetched = useRef(false);
  const { position: mapPosition } = useAreaOfInterest({ geoJson });

  const flags = useFlags();
  const { user: { organizationId } = {} } = useUser();
  const collectionTypesEnabled = flags.collectionTypeTaskingFormEnabled?.organizations?.includes(organizationId) || false;

  // add repeat request details to bookmarks if the task has a repeatRequestId
  let taskBreadcrumbs = breadcrumbs;
  if (repeatRequestId && taskBreadcrumbs) {
    taskBreadcrumbs = [
      ...breadcrumbs,
      {
        label: 'Repeat Request',
        to: routePathByName(ROUTE_REPEAT_REQUEST_DETAILS, {
          wildcard: [repeatRequestId]
        })
      }
    ];
  }

  geoJson &&
    geoJson.features.forEach((feature) => {
      feature.properties.featureType = 'aoi';
    });

  let collectFeatures = collectsWithTiles || [];

  // We need both the collect itself and the tile for each of our collects to
  // show them as features on the map

  collectFeatures = collectFeatures.filter(
    ({ collect, tile }) => tile && collect
  );

  // Loop through all of our existing features and try to apply the collect's status
  // to the tile

  collectFeatures = collectFeatures.map(({ collect = {}, tile = {} }) => {
    return {
      ...tile,
      properties: {
        ...tile.properties,
        collectStatus: collect.lastStatus.code
      }
    };
  });

  // We want to take our collects and the associated features and create geojson features
  // that we can add to our MapPreview component. this includes the original AOI and
  // each tile/collect. Once we have that list, we additionally check if it has a collect
  // status, which means its a collect, but also helps us determine the color we want
  // to show the feature on the map as

  if (geoJson && geoJson.features && collectFeatures.length > 0) {
    // Combine the original AOI and the collects

    geoJson.features = [...geoJson.features, ...collectFeatures];

    // Loop through all of our features and configure the style based on status

    geoJson.features = geoJson.features.map((feature, index) => {
      const { properties = {} } = feature;
      const { tileId } = properties;

      const statusConfig = TASK_COLLECT_STATUSES.find(
        ({ id }) => id === properties.collectStatus
      );

      const shapeOptions = {
        style: {
          fill: false
        }
      };

      // If we can find a status config, try to use the color for the shape options of the feature
      // If we dont provide one, it defaults to the component's default color

      if (statusConfig && statusConfig.color) {
        shapeOptions.style.color = statusConfig.color;
        shapeOptions.style.fill = tileId === activeTileId;
        shapeOptions.style.fillOpacity = 0.4;
      }

      return {
        ...feature,
        properties: {
          ...properties,
          shapeOptions,
          onMouseover: () => setActiveTileId(tileId),
          onMouseout: () => setActiveTileId()
        }
      };
    });
  }

  const loadingMessage = 'More information on the spawned task is still being loaded. Hang tight for now, or feel free to come back later.';

  // Access Requests stuff
  const isPoint = isPointGeometryType({ geoJson });
  const mapZoom = isPoint ? 13 : 5;

  const handleMapEffect = ({ leafletElement: map } = {}) => {
    mapRef.current = map;
  };

  const [accessRequestsProps, setAccessRequestsProps] = useState();

  useEffect(() => {
    // Don't pass props to AccessRequests until we've fetched all the necessary ones...
    if (task?.windowOpen && geoJson?.features?.length && !accessesFetched.current) {
      setAccessRequestsProps({
        taskRequestType: task.taskRequestType,
        geoJson,
        windowOpen: task.windowOpen,
        windowClose: task.windowClose,
        tier: task.collectionTier,
        accessRequestOffNadirMin: task.collectConstraints?.offNadirMin,
        accessRequestOffNadirMax: task.collectConstraints?.offNadirMax,
        accessRequestAscending: task.collectConstraints?.ascDsc,
        accessRequestLookDirection: task.collectConstraints?.lookDirection,
        accessRequestOrbitalPlanes: task.collectConstraints?.orbitalPlanes,
        isPoint,
        imageLength: task.collectConstraints?.imageLength,
        imageWidth: task.collectConstraints?.imageWidth,
        refMap: mapRef
      });
      // Avoid calling /accessrequests more than once
      accessesFetched.current = true;
    }
  }, [task, geoJson]);

  const hasActiveCollects = task.collects?.length;
  // Only show access requests times when...
  const showAccessRequests = isPoint && accessRequestsProps && !hasActiveCollects;

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'template-task' }}>
        <title>Task</title>
      </Helmet>
      <Container className="content" type={containerType}>
        <Breadcrumbs items={taskBreadcrumbs} />

        <PageHeader title={name} subtitle={`ID: ${taskId}`}>
          <div className="container-is-loading">
            <Notice weight="bold" type="default">
              <h1>loading</h1>
            </Notice>
          </div>
        </PageHeader>

        <div className="task-details">

          <div className="task-details-card loading-ripple">
            <TaskStatusAnimated
              task={task}
              speed={0.5}
              customLoadingMessage={loadingMessage}
            />
          </div>

          <div className="task-details-info">
            <div className="task-details-map">
              {geoJson ? (
                <MapPreview
                  zoom={mapZoom}
                  center={mapPosition}
                  geoJson={geoJson}
                  fitGeoJson={!isPoint}
                  useMapEffect={handleMapEffect}
                  displayAccessRequests={showAccessRequests}
                >
                  {showAccessRequests && (
                    <AccessRequests
                      {...accessRequestsProps}
                      key={taskId}
                    />
                  )}
                </MapPreview>) : 'Loading'}
            </div>

            <div className="task-details-meta">
              {description && (
                <div className="task-details-description">
                  <h3>Description</h3>
                  <p>{description}</p>
                </div>
              )}

              <div className="task-configuration flat-top">
                <h3>Configuration</h3>

                <TaskConfigTable
                  task={task}
                  className="flat-top"
                  showRepeatCycle={true}
                  showExtraFields={false}
                  collectionTypesEnabled={collectionTypesEnabled}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

TemplateRepeatRequestChildTask.propTypes = {
  task: PropTypes.object,
  state: PropTypes.object,
  breadcrumbs: PropTypes.array
};

export default TemplateRepeatRequestChildTask;
