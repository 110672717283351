import React from 'react';
import { useDispatch } from 'redux-react-hook';

import {
  postNewUser,
  triggerNotice,
  triggerErrorByCode,
  redirectTo,
  routePathByName,
  logError,
  useAdminFetchOrganizations,
  TemplateUserCreate,
  ADMIN_ROLE
} from 'commonLib';

const AdminUsersCreatePage = () => {
  const dispatch = useDispatch();

  const { organizations = [], isLoading } = useAdminFetchOrganizations();

  const breadcrumbs = [
    {
      label: 'Admin',
      to: routePathByName('admin')
    },
    {
      label: 'Users',
      to: routePathByName('adminUsers')
    }
  ];

  const createState = {
    isLoading
  };

  /**
   * handleSubmitForm
   */

  function handleSubmitForm (data) {
    dispatch(postNewUser(data))
      .then(() => {
        redirectTo(routePathByName('adminUsers'));
        dispatch(
          triggerNotice({
            type: 'success',
            weight: 'bold',
            align: 'center',
            text: 'User was successfully created!'
          })
        );
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        const { error = {} } = data;

        logError(e.message, data);

        dispatch(triggerErrorByCode(error.code));
      });
  }

  return (
    <TemplateUserCreate
      organizations={organizations}
      accessType={ADMIN_ROLE}
      breadcrumbs={breadcrumbs}
      onCreate={handleSubmitForm}
      state={createState}
    />
  );
};

export default AdminUsersCreatePage;
