import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useItem, useItems } from 'hooks';

import { logError } from 'lib/logger';
import { fetchItemById, triggerNotice, updateActiveItem } from 'state/actions';

export default function useFetchItem ({
  itemId,
  collectionId,
  forceNew = false,
  setActive = false
}) {
  const dispatch = useDispatch();

  const { actions = {} } = useItems();
  const item = useItem({
    collectionId,
    itemId
  });
  const { id } = item || {};

  useEffect(() => {
    if (id && !forceNew) return;
    if (!itemId) return;

    dispatch(
      fetchItemById({
        collectionId,
        itemId
      })
    )
      .then((fetchedItem) => {
        if (setActive) {
          dispatch(updateActiveItem(fetchedItem));
        }
      })
      .catch((error) => {
        logError(error.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      });
  }, [itemId, id, forceNew]); // eslint-disable-line

  return {
    ...actions.fetchItemById,
    item
  };
}
