import { parseGeoJsonString, isEmptyObject } from 'lib/util';
import { geometryToGeoJson, latLngFromGeoJson } from 'lib/map';
import center from '@turf/center';

export default function useAreaOfInterest ({ position, geometry, geoJson }) {
  let mapPosition;
  let mapGeoJson;

  // Try to parse the geojson object if it's available for us

  if (geoJson) {
    mapGeoJson = parseGeoJsonString(geoJson);
  } else if (geometry && !isEmptyObject(geometry)) {
    mapGeoJson = geometryToGeoJson(geometry);
  }

  // If we have a mapPosition, split it out into lat lng for use
  // If we don't but we have a geometry available, make use of that

  if (position) {
    mapPosition = position.split(',');
    mapPosition = {
      lat: mapPosition[0],
      lng: mapPosition[1]
    };
  } else if (geometry && geometry.type === 'Point') {
    mapPosition = {
      lat: geometry.coordinates[1],
      lng: geometry.coordinates[0]
    };
  } else if (mapGeoJson) {
    mapPosition = center(mapGeoJson);
    mapPosition = latLngFromGeoJson(mapPosition)[0];
  }

  const { features = [] } = mapGeoJson || {};
  const { geometry: featureGeometry } = features[0] || {};
  const { type } = featureGeometry || {};

  return {
    position: mapPosition,
    geoJson: mapGeoJson,
    type
  };
}
