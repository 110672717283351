import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import { routePathByName } from 'lib/routes';

import { ROUTE_ADMIN_ORGS_CONTRACT_DETAILS } from 'data/route-names';

import {
  AdminIndexPage,
  AdminUsersPage,
  AdminUsersCreatePage,
  AdminUserPage,
  AdminOrganizationsPage,
  AdminOrganizationsCreatePage,
  AdminOrganizationPage,
  AdminOrganizationEditPage,
  AdminOrganizationsContractAddPage,
  AdminOrganizationsContractEditPage,
  AdminOrganizationsContractPeriodAddPage,
  AdminOrganizationsContractPeriodEditPage,
  AdminOrganizationsBucketConfigAddPage,
  AdminOrganizationsBucketConfigEditPage,
  AdminCollectionsPage,
  AdminCollectionPage,
  AdminOrganizationsContractDetailsPage
} from 'commonLib';

const AdminIndex = () => {
  const organizationRouteOptions = {
    wildcard: [':organizationId', ':contractPeriodId']
  };

  const ledgerOrganizationRouteOptions = {
    wildcard: [':organizationId', ':contractId']
  };

  const bucketConfigAddRouteOptions = {
    wildcard: [':organizationId', ':bucketConfigId']
  };

  return (
    <>
      <Router basepath="/">
        <AdminIndexPage path={routePathByName('admin')} default />
        <AdminUsersPage path={routePathByName('adminUsers')} />
        <AdminUsersCreatePage path={routePathByName('adminUsersCreate')} />
        <AdminUserPage
          path={routePathByName('adminUsersDetails', {
            wildcard: ':userId'
          })}
        />
        <AdminOrganizationsPage path={routePathByName('adminOrganizations')} />
        <AdminOrganizationsCreatePage
          path={routePathByName('adminOrganizationsCreate')}
        />
        <AdminOrganizationPage
          path={routePathByName(
            'adminOrganizationsDetails',
            organizationRouteOptions
          )}
        />
        <AdminOrganizationEditPage
          path={routePathByName(
            'adminOrganizationsEdit',
            organizationRouteOptions
          )}
        />
        <AdminOrganizationsContractAddPage
          path={routePathByName(
            'adminOrganizationsContractAdd',
            organizationRouteOptions
          )}
        />
        <AdminOrganizationsContractEditPage
          path={routePathByName(
            'adminOrganizationsContractEdit',
            ledgerOrganizationRouteOptions
          )}
        />
        <AdminOrganizationsContractPeriodAddPage
          path={routePathByName(
            'adminOrganizationsContractPeriodAdd',
            ledgerOrganizationRouteOptions
          )}
        />
        <AdminOrganizationsContractPeriodEditPage
          path={routePathByName(
            'adminOrganizationsContractPeriodEdit',
            organizationRouteOptions
          )}
        />
        <AdminOrganizationsBucketConfigAddPage
          path={routePathByName(
            'adminOrganizationsBucketConfigAdd',
            organizationRouteOptions
          )}
        />
        <AdminOrganizationsBucketConfigEditPage
          path={routePathByName(
            'adminOrganizationsBucketConfigEdit',
            bucketConfigAddRouteOptions
          )}
        />

        <AdminCollectionsPage path={routePathByName('adminCollections')} />
        <AdminCollectionPage
          path={routePathByName('adminCollectionsDetails', {
            wildcard: [':collectionId']
          })}
        />
        <AdminOrganizationsContractDetailsPage
          path={routePathByName(
            ROUTE_ADMIN_ORGS_CONTRACT_DETAILS,
            ledgerOrganizationRouteOptions
          )}
        />
      </Router>
    </>
  );
};

AdminIndex.propTypes = {
  location: PropTypes.object
};

export default AdminIndex;
