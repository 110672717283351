import React from 'react';
import PropTypes from 'prop-types';
import { FaLayerGroup } from 'react-icons/fa';
import { useDispatch } from 'redux-react-hook';

import { useMapLayers } from 'hooks';
import { getThumbFromLayer } from 'lib/layers';
import { setBasemap } from 'state/actions';

/**
 * Basemap selector component that passes onChange events up to SearchSidebar
 * and ultimately Lens to control basemap selection changes.
 * @param {object} props
 * @param {object} props.layers
 * @param {func} [props.onChange]
 * @param {string} [props.className]
 */
const BasemapControl = ({ layers, onChange, className = '' }) => {
  const dispatch = useDispatch();
  const { basemap } = useMapLayers();

  function handleChange (id) {
    dispatch(setBasemap(id));
    if (typeof onChange === 'function') {
      onChange(id);
    }
  }

  return (
    <div className={`basemap-control-wrapper ${className}`}>
      <div className="basemap-options">
        {layers?.base?.length > 0 && (
          <ul>
            {layers.base.map((layer = {}) => (
              <li key={layer.serviceName} onClick={() => handleChange(layer.serviceName)}>
                <img
                  className={basemap === layer.serviceName ? 'active-layer' : ''}
                  src={getThumbFromLayer(layer)}
                  alt={`Thumbnail for ${layer.name} Basemap Layer`}
                ></img>
                <span>{layer.name}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="basemap-control">
        {layers.base.map(
          (layer = {}) =>
            basemap === layer.serviceName && (
              <img
                key={layer.serviceName}
                src={getThumbFromLayer(layer)}
                alt={`Thumbnail for ${layer.name} Basemap Layer`}
              ></img>
            )
        )}
        <div className="svg-wrapper">
          <FaLayerGroup />
        </div>
      </div>
    </div>
  );
};

BasemapControl.propTypes = {
  layers: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string
};

export default BasemapControl;
