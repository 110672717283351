import React from 'react';
import PropTypes from 'prop-types';
import { Layout as FoggLayout } from 'fogg/ui';

import 'assets/stylesheets/application.scss';

const Layout = ({ children, className, notice }) => {
  return (
    <FoggLayout notice={notice}>
      <div className={`wrapper ${className || ''}`}>
        <main>{children}</main>
      </div>
    </FoggLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  notice: PropTypes.object
};

export default Layout;
