import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { Helmet } from 'react-helmet';
import { MdPersonAdd } from 'react-icons/md';

import {
  adminUpdateOrganization,
  triggerNotice,
  postOrganizationAction,
  addOrganizationCollectionPermissions,
  fieldsObjectToValues,
  redirectTo,
  routePathByName,
  logError,
  DEFAULT_ANALYTICS_COLLECTIONS,
  useAdmin,
  useAdminFetchOrganization,
  useAdminFetchLicenses,
  Breadcrumbs,
  PageHeader,
  Container,
  FormAdminEditOrganization
} from 'commonLib';

import Layout from 'components/Layout';

const PAGE_TITLE = 'Edit Organization';

const AdminOrganizationEditPage = ({ organizationId }) => {
  const dispatch = useDispatch();

  const { organization = {}, isLoading: fetchAdminIsLoading } =
    useAdminFetchOrganization({
      id: organizationId
    });
  const { licenses = {}, isLoading: fetchLicensesIsLoading } = useAdminFetchLicenses();

  const { actions: adminActions = {} } = useAdmin();
  const { adminUpdateOrganization: updateAction = {} } = adminActions;
  const { isLoading: updateIsLoading } = updateAction;

  const {
    adminCreateNewOrganizationS3Bucket:
      adminCreateNewOrganizationS3BucketAction = {}
  } = adminActions;
  const {
    adminUpdateOrganizationS3Bucket: adminUpdateOrganizationS3BucketAction = {}
  } = adminActions;
  const { isLoading: adminCreateNewOrganizationS3BucketIsLoading } =
    adminCreateNewOrganizationS3BucketAction;
  const { isLoading: adminUpdateOrganizationS3BucketIsLoading } =
    adminUpdateOrganizationS3BucketAction;

  const isLoading =
    updateIsLoading ||
    fetchAdminIsLoading ||
    fetchLicensesIsLoading ||
    adminCreateNewOrganizationS3BucketIsLoading ||
    adminUpdateOrganizationS3BucketIsLoading;

  /**
   * handleSubmitForm
   * @description Makes a request to create an org on form submit
   */

  async function handleSubmitForm (e, fields, analyticsOptions) {
    const organizationData = fieldsObjectToValues(fields);
    try {
      await dispatch(adminUpdateOrganization(organizationId, organizationData));
    } catch (e) {
      logError(e.message, organizationData);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try again!'
        })
      );
      return;
    }
    // If user checks to enable Analytics Module (and maybe Continuous Analytics)
    if (analyticsOptions) {
      const {
        ingestWindow,
        backfill = null,
        analyticWindow = null,
        spacing = null
      } = analyticsOptions;

      // Object passed if only "Analytics Module" is checked, no Continuous
      const actionObject = {
        organization_id: organizationId,
        ingest: true,
        ingest_commencement: ingestWindow,
        jobs: []
      };

      // Only pass these values if "Continuous Analytics" values are present
      const ingestActive = !!(backfill || analyticWindow || spacing);
      if (ingestActive) {
        actionObject.jobs = [
          {
            continuous_lookback_in_months: backfill,
            continuous_window_in_months: analyticWindow,
            continuous_spacing_in_months: spacing,
            method: 'cov'
          },
          {
            continuous_lookback_in_months: 6,
            continuous_window_in_months: 3,
            method: 'bitemp',
            collect_mode: 'stripmap'
          },
          {
            continuous_lookback_in_months: 6,
            continuous_window_in_months: 3,
            method: 'bitemp',
            collect_mode: 'spotlight'
          }
        ];
      }
      dispatch(postOrganizationAction(actionObject))
        .then(() => {
          const analyticsCollections = DEFAULT_ANALYTICS_COLLECTIONS.map(
            ({ value }) => value
          );
          dispatch(
            addOrganizationCollectionPermissions(
              organizationId,
              analyticsCollections
            )
          ).catch((error) => {
            logError(error.message);
          });
        })
        .catch((error) => {
          logError(error.message);
          dispatch(
            triggerNotice({
              type: 'error',
              weight: 'bold',
              align: 'center',
              text: 'Analytic Options NOT successfully submitted. Please contact support.'
            })
          );
        });
    }

    redirectTo(
      routePathByName('adminOrganizationsDetails', {
        wildcard: [organizationId]
      })
    );

    dispatch(
      triggerNotice({
        type: 'success',
        weight: 'bold',
        align: 'center',
        text: 'Organization was successfully updated!'
      })
    );
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-admin page-admin-organizations page-admin-organizations-create'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Admin',
              to: routePathByName('admin')
            },
            {
              label: 'Organizations',
              to: routePathByName('adminOrganizations')
            }
          ]}
        />
        <PageHeader
          title={PAGE_TITLE}
          icon={<MdPersonAdd className="icon-md" />}
        />
        <FormAdminEditOrganization
          organization={organization}
          licenses={licenses}
          onSubmit={handleSubmitForm}
          disabled={isLoading}
        />
      </Container>
    </Layout>
  );
};

AdminOrganizationEditPage.propTypes = {
  organizationId: PropTypes.string
};

export default AdminOrganizationEditPage;
