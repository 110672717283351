import clone from 'clone';
import deepmerge from 'deepmerge';

const defaultState = {
  actions: {},
  aois: [],
  searchTerm: ''
};

const aoisReducer = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);

  const { aois = [] } = workingState;

  switch (type) {
    case 'UPDATE_AOIS': {
      return {
        ...workingState,
        aois: workingData
      };
    }

    case 'ADD_AOI': {
      return {
        ...workingState,
        aois: [workingData, ...aois]
      };
    }

    case 'UPDATE_AOI': {
      const matchingAOI = aois.findIndex(i => i.id === workingData.id);
      aois[matchingAOI] = workingData;

      return {
        ...workingState,
        aois: aois
      };
    }

    case 'UPDATE_AOIS_ACTION_STATE': {
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    }

    case 'DELETE_AOI': {
      return {
        ...workingState,
        aois: aois.filter((i) => i.id !== workingData)
      };
    }

    case 'UPDATE_AOIS_SEARCH_TERM': {
      return {
        ...workingState,
        searchTerm: workingData
      };
    }

    default:
      return workingState;
  }
};

export default aoisReducer;
