import React from 'react';

import TemplateOrders from 'templates/TemplateOrders';

import { useResellerFetchOrders, useOrders } from 'hooks';
import { routePathByName } from 'lib/routes';
import { RESELLER_ORDERS_TABLE_COLUMNS } from 'lib/orders';

const ResellerOrganizationsPage = () => {
  // setting forceNew makes sure we always get updated orders when we visit the page
  const { isLoading } = useResellerFetchOrders({ forceNew: true });
  const { orders = [] } = useOrders();

  const ordersState = {
    isLoading
  };

  const breadcrumbs = [
    {
      label: 'Reseller',
      to: routePathByName('reseller')
    }
  ];

  return (
    <TemplateOrders
      state={ordersState}
      orders={orders}
      tableColumns={RESELLER_ORDERS_TABLE_COLUMNS}
      breadcrumbs={breadcrumbs}
      detailsRouteName="resellerOrdersDetails"
    />
  );
};

export default ResellerOrganizationsPage;
