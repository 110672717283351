import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBriefcase } from 'react-icons/fa';

import { routePathByName, redirectTo } from 'lib/routes';
import { logError } from 'lib/logger';
import { useResellerFetchOrganization } from 'hooks';
import {
  updateResellerOrganizationContract,
  triggerSuccess,
  triggerError
} from 'state/actions';

import Contract from 'commonLib/src/models/contract';

import Layout from 'components/Layout';

import {
  Breadcrumbs,
  PageHeader,
  Container,
  FormContractEdit
} from 'commonLib';

const PAGE_TITLE = 'Edit Contract';

const ResellerOrganizationsContractEditPage = ({
  organizationId,
  contractId
}) => {
  const dispatch = useDispatch();

  const { organization = {}, isLoading } = useResellerFetchOrganization({
    id: organizationId
  });

  let contract =
    organization?.contracts?.find(
      (orgContract) => orgContract.id === contractId
    ) || {};
  if (typeof contract.isExpired !== 'function') {
    contract = new Contract(contract);
  }

  const organizationDetailsPath = routePathByName(
    'resellerOrganizationsDetails',
    {
      wildcard: organizationId
    }
  );
  const contractDetailsPath = routePathByName('resellerOrgContractDetails', {
    wildcard: [organizationId, contractId]
  });

  // Loading state after hitting submit button
  const [submitLoading, setSubmitLoading] = useState(false);

  function handleOnSubmit ({ data }) {
    setSubmitLoading(true);
    dispatch(updateResellerOrganizationContract(organizationId, contractId, data))
      .then(() => {
        setSubmitLoading(false);
        redirectTo(contractDetailsPath);
        dispatch(triggerSuccess('Contract was successfully updated!'));
      })
      .catch((error) => {
        setSubmitLoading(false);
        logError(error.message, data);
        dispatch(triggerError());
      });
  }

  // Admins can "cancel an edit" by navigating back to the contract details page
  function handleOnCancel () {
    redirectTo(contractDetailsPath);
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-reseller page-reseller-contract page-reseller-contract-edit'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Reseller',
              to: routePathByName('reseller')
            },
            {
              label: 'Organizations',
              to: routePathByName('resellerOrganizations')
            },
            {
              label: 'Organization',
              to: organizationDetailsPath
            },
            {
              label: 'Contract',
              to: contractDetailsPath
            }
          ]}
        />
        <PageHeader
          title={PAGE_TITLE}
          subtitle={`ID: ${contractId}`}
          icon={<FaBriefcase />}
        />
        <FormContractEdit
          contract={contract}
          onSubmit={handleOnSubmit}
          disabled={isLoading || submitLoading}
          onCancel={handleOnCancel}
          showSalesforce={false}
          organization={organization}
        />
      </Container>
    </Layout>
  );
};

ResellerOrganizationsContractEditPage.propTypes = {
  organizationId: PropTypes.string,
  contractId: PropTypes.string
};

export default ResellerOrganizationsContractEditPage;
