import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { useModal } from 'fogg/hooks';

import { userHasTaskingAccess } from 'commonLib/src/lib/role-util';
import { ROUTE_REPEAT_REQUESTS, ROUTE_REPEAT_REQUEST_EDIT } from 'commonLib/src/data/route-names';

import {
  TASK_STATUS_RECEIVED,
  TASK_STATUS_REVIEW
} from 'data/tasks';
import { routePathByName, navigateTo } from 'lib/routes';
import { taskDefaults, getAnalyticLabel, isPointGeometryType } from 'lib/tasks';
import { TaskRequestTypes } from '../data/task-request-types';
import { RepeatCycleTypes } from '../data/repeat-cycle-types';
import { RepeatRequestEndTypes } from '../data/repeat-request-end-types';
import { useTaskCreate, useTaskContext, useUser } from 'hooks';

import { Helmet } from 'react-helmet';
import { FaTools } from 'react-icons/fa';
import { Badge, Notice } from 'fogg/ui';
import { Button } from '@trussworks/react-uswds';
import { IconBadge, redirectTo } from 'commonLib';
import Layout from 'components/Layout';
import Breadcrumbs from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import RepeatTaskStatus from 'components/RepeatTaskStatus';
import RepeatTaskCostAction from 'components/RepeatTaskCostAction';
import RepeatTaskConfigTable from 'components/RepeatTaskConfigTable';
import TaskCollectsTable from 'components/TaskCollectsTable';
import MapPreview from 'components/MapPreview';
import CancelRepeatRequestModal from 'components/CancelRepeatRequestModal';
import RepeatChildTasksTable from 'components/RepeatChildTasksTable';
import ACDIcon from 'commonLib/src/assets/icons/ACD-Icon.svg';
import VesselIcon from 'commonLib/src/assets/icons/Vessel-Icon.svg';

const DEFAULT_BREADCRUMBS = [
  {
    label: 'Repeat Requests',
    to: routePathByName(ROUTE_REPEAT_REQUESTS)
  }
];

const TemplateRepeatRequest = ({
  repeatRequest = {},
  breadcrumbs = DEFAULT_BREADCRUMBS,
  state = {},
  orgIsArchSubOnly,
  repeatRequestChildTasks = []
}) => {
  const { id: repeatRequestId = 'Loading' } = repeatRequest;
  const { isLoading = false, isChildTasksLoading = false } = state;
  const containerType = [];

  if (isLoading) {
    containerType.push('is-loading');
  }

  const { name, description, isSandbox, analytics = [] } = repeatRequest;
  const geoJson = repeatRequest.geoJson && repeatRequest.geoJson();
  const isPoint = isPointGeometryType({ geoJson });

  const { path: createTaskPath } = useTaskCreate({ geoJson });
  const { updateTaskState } = useTaskContext() || {};

  const flags = useFlags();
  const { user: { roles = {}, organizationId } = {} } = useUser();
  const isTaskingAllowed = userHasTaskingAccess(roles, flags);
  const collectionTypesEnabled = flags.collectionTypeTaskingFormEnabled?.organizations?.includes(organizationId) || false;

  // Logic to determine if "Cancel Repeat Request" button should be displayed
  const { _data: { properties = {} } = {} } = repeatRequest;
  const {
    statusHistory: repeatRequestStatusHistory,
    customAttribute1,
    customAttribute2
  } = properties;

  let canCancel = false;
  const repeatRequestStatus =
    repeatRequestStatusHistory && repeatRequestStatusHistory.length
      ? repeatRequestStatusHistory[0].code
      : null;

  if (repeatRequestStatus) {
    // First check for task statuses that can't be canceled
    if (
      repeatRequestStatus === 'rejected' ||
      repeatRequestStatus === 'canceled' ||
      repeatRequestStatus === 'cancelled' ||
      repeatRequestStatus === 'error' ||
      repeatRequestStatus === 'anomaly' ||
      repeatRequestStatus === 'collection_anomaly' ||
      repeatRequestStatus === 'processing_anomaly' ||
      repeatRequestStatus === 'completed'
    ) {
      canCancel = false;
    } else {
      // If repeat request status is one that can be canceled, we still need to
      // verify the windowOpen date is at least 3 hours from now
      const { collects = [] } = properties;
      if (collects.length) {
        const dates = collects.map((collect) => {
          return moment(collect.windowOpen);
        });
        const moreThanThreeHours = (date) => {
          return moment(date).isAfter(moment().add(3, 'hours'));
        };
        if (moreThanThreeHours(moment.min(dates))) {
          canCancel = true;
        }
      }
      // ...or if no collects yet, it's probably in review and should be able to be cancelled
      if (!collects.length) {
        canCancel = true;
      }
    }
  }

  // Hide cost panel when repeatRequest status is 'canceled', 'error', or 'rejected' & no order cost estimate
  const { order: { summary: { total: orderTotal } = {} } = {} } = repeatRequest;
  const showMessagingPanel =
    !(
      repeatRequestStatus &&
      repeatRequestStatus === 'canceled' &&
      !orderTotal
    ) &&
    !(repeatRequestStatus && repeatRequestStatus === 'error' && !orderTotal) &&
    !(repeatRequestStatus && repeatRequestStatus === 'rejected' && !orderTotal);

  // Analytic(s) associated are an array to support future support for multiple
  // but for now just get the one
  const analytic = analytics?.length ? analytics[0] : undefined;
  const analyticLabel = getAnalyticLabel(analytic);

  // When the cancel button exists in the messagingPanel (RepeatTaskCostAction), we hide the cancel button in the header
  const canCancelInHeader = (
    ![TASK_STATUS_REVIEW, TASK_STATUS_RECEIVED].includes(repeatRequestStatus) &&
    showMessagingPanel
  );

  const initialModalState = {
    modals: {
      cancel: {
        isOpen: false
      }
    }
  };

  const modalName = 'cancel';
  const modal = useModal(initialModalState);
  const { ModalContextProvider, handleModalOpen } = modal;

  function openModal (e) {
    handleModalOpen(e, 'cancel');
  }

  geoJson &&
    geoJson.features.forEach((feature) => {
      feature.properties.featureType = 'aoi';
    });

  const headerIcons = [
    <Badge
      label={repeatRequest?.taskRequestTypeLabel}
      type="info"
      isLoading={isLoading}
      className="repeat-task-badge"
      key={1}
    />
  ];

  if (analyticLabel) {
    headerIcons.push(
      <IconBadge
        className={`${analytic}`}
        icon={analytic === 'VS' || analytic === 'VC' ? <img src={VesselIcon} alt="Vessel Detection Icon" /> : <img src={ACDIcon} alt="ACD Icon" />}
        label={`${analyticLabel} Included`}
        key={2}
        hiddenLabel={true}
        isLoading={isLoading}
      />
    );
  }

  // retask
  function prepRetaskFormDataAndRedirect () {
    const now = new Date();

    // repetition properties
    const retaskRepetitionProperties = { ...repeatRequest.repetitionProperties };
    const origRepeatStart = new Date(retaskRepetitionProperties.repeatStart);
    retaskRepetitionProperties.repeatStart = now;

    // repetitionEndType
    if (retaskRepetitionProperties.repeatEnd !== null) {
      retaskRepetitionProperties.repetitionEndType = RepeatRequestEndTypes.DATE;
      const repeatEndInt = new Date(retaskRepetitionProperties.repeatEnd).getTime();
      const timeDelta = repeatEndInt - origRepeatStart.getTime();
      retaskRepetitionProperties.repeatEnd = new Date(now.getTime() + timeDelta);
    } else if (retaskRepetitionProperties.repetitionCount !== null) {
      retaskRepetitionProperties.repetitionEndType = RepeatRequestEndTypes.COUNT;
    } else {
      retaskRepetitionProperties.repetitionEndType = RepeatRequestEndTypes.INDEFINITE;
    }

    // repeatCycle
    const interval = retaskRepetitionProperties.repetitionInterval;
    if (interval !== undefined) {
      if (interval === 1) {
        retaskRepetitionProperties.repeatCycle = RepeatCycleTypes.DAILY;
      } else if (interval === 7) {
        retaskRepetitionProperties.repeatCycle = RepeatCycleTypes.WEEKLY;
      } else if (interval === 14) {
        retaskRepetitionProperties.repeatCycle = RepeatCycleTypes.BIWEEKLY;
      } else if (interval === 30) {
        retaskRepetitionProperties.repeatCycle = RepeatCycleTypes.MONTHLY;
      } else {
        retaskRepetitionProperties.repeatCycle = RepeatCycleTypes.OTHER;
      }
    } else {
      retaskRepetitionProperties.repeatCycle = taskDefaults.repeat.DEFAULT_REPEAT_CYCLE_VALUE;
    }

    const retaskCollectConstraints = { ...repeatRequest.collectConstraints };

    // target height from geometry
    const coordinates = repeatRequest.geometry.coordinates;
    if (coordinates.length === 3) {
      retaskCollectConstraints.targetHeight = coordinates[2];
    }

    updateTaskState({
      name: `${repeatRequest.name} Retask`,
      analytics: repeatRequest.analytics,
      collectionTier: repeatRequest.collectionTier,
      collectConstraints: retaskCollectConstraints,
      preApproval: repeatRequest.preApproval,
      productCategory: collectionTypesEnabled ? undefined : (repeatRequest.repeatrequestType || 'custom'),
      collectionType: collectionTypesEnabled ? repeatRequest.collectionType : undefined,
      collectMode: repeatRequest.collectMode,
      // Note: those should be in collectConstraints but changing this in the respective
      // forms had unintended side effects
      ascDsc: repeatRequest.ascDsc,
      lookDirection: repeatRequest.lookDirection,
      orbitalPlanes: repeatRequest.orbitalPlanes,
      repetitionProperties: retaskRepetitionProperties,
      taskRequestType: TaskRequestTypes.REPEAT,
      isRepeatRequest: true,
      isRetask: true,
      fromArchive: false,
      origTaskingId: repeatRequest.id,
      archiveHoldback: repeatRequest.archiveHoldback
    });

    redirectTo(createTaskPath);
  }

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'template-task' }}>
        <title>Repeat Request</title>
      </Helmet>
      <Container className="content" type={containerType}>
        <Breadcrumbs items={breadcrumbs} />

        <PageHeader
          title={name}
          subtitle={`ID: ${repeatRequestId}`}
          className="repeat-task-header"
          icon={headerIcons}
        >
          {isSandbox && (
            <Notice className="task-environment" type="warning" weight="bold">
              <FaTools /> Sandbox
            </Notice>
          )}
          <div className="button-group">
            <Button
              type="button"
              className="action-edit-task"
              disabled={isLoading}
              base={true}
              onClick={() => navigateTo(routePathByName(ROUTE_REPEAT_REQUEST_EDIT, {
                wildcard: [repeatRequestId]
              }))}>
                  Edit
            </Button>
            {isPoint && !orgIsArchSubOnly && isTaskingAllowed && (
              <Button
                type="button"
                onClick={prepRetaskFormDataAndRedirect}
                className="action-retask"
                disabled={isLoading}
                base={true}>
                  Retask
              </Button>
            )}
            {canCancel && canCancelInHeader && !isLoading && (
              <>
                <Button type="button" onClick={openModal} className="action-cancel danger" base={true}>
                  Cancel Series
                </Button>
                <ModalContextProvider>
                  <CancelRepeatRequestModal
                    name={modalName}
                    modal={modal}
                    repeatRequest={repeatRequest}
                  />
                </ModalContextProvider>
              </>
            )}
          </div>
        </PageHeader>

        <div className="task-details">
          <div className="repeat-task-details-card">
            <RepeatTaskStatus repeatRequest={repeatRequest} />
          </div>

          {showMessagingPanel &&
            !isLoading &&
            repeatRequestChildTasks?.length < 1 && (
            <div className="task-details-card">
              <RepeatTaskCostAction repeatRequest={repeatRequest} />
            </div>
          )}

          {repeatRequestChildTasks?.length > 0 && !isLoading && (
            <div className="repeat-task-details-card">
              <RepeatChildTasksTable
                tasks={repeatRequestChildTasks}
                isLoading={isChildTasksLoading}
              />
            </div>
          )}

          {isLoading && (
            <div className="repeat-task-details-card">
              <div className="task-cost-action">
                <h1 className="loading-fluff">loading</h1>
              </div>
            </div>
          )}

          <div className="task-details-info">
            <div className="task-details-map">
              {geoJson ? <MapPreview geoJson={geoJson} /> : 'Loading'}
            </div>

            <div className="task-details-meta">
              {(description || customAttribute1 || customAttribute2) && (
                <div className="task-details-description">
                  {description && (
                    <div className="description-row">
                      <h3>Description</h3>
                      <p>{description}</p>
                    </div>
                  )}
                  {customAttribute1 && (
                    <div className="description-row">
                      <h3>Custom Attribute 1</h3>
                      <p>{customAttribute1}</p>
                    </div>
                  )}
                  {customAttribute2 && (
                    <div className="description-row">
                      <h3>Custom Attribute 2</h3>
                      <p>{customAttribute2}</p>
                    </div>
                  )}
                </div>
              )}
              {repeatRequest.collects?.length
                ? <div className="task-details-collects">
                  <TaskCollectsTable
                    task={repeatRequest}
                    collects={repeatRequest.collects}
                    isLoading={isLoading}
                    onChangeActiveCollect={() => {}}
                    activeTileId={null}
                  />
                </div> : null
              }

              <div className="task-configuration">
                <h3>Configuration</h3>

                <RepeatTaskConfigTable
                  className="flat-top"
                  task={repeatRequest}
                  showRepeatCycle={true}
                  showExtraFields={false}
                  collectionTypesEnabled={collectionTypesEnabled}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

TemplateRepeatRequest.propTypes = {
  repeatRequest: PropTypes.object,
  state: PropTypes.object,
  breadcrumbs: PropTypes.array,
  repeatRequestChildTasks: PropTypes.array,
  orgIsArchSubOnly: PropTypes.bool
};

export default TemplateRepeatRequest;
