import React from 'react';
import PropTypes from 'prop-types';
import { routePathByName } from '../lib/routes';
import {
  ROUTE_ADMIN_ORGS_BUCKET_CONFIG_ADD,
  ROUTE_ADMIN_ORGS_BUCKET_CONFIG_EDIT,
  ROUTE_ORG_BUCKET_CONFIG_ADD,
  ROUTE_ORG_BUCKET_CONFIG_EDIT
} from '../data/route-names';
import {
  DataTable,
  TableActions
} from 'fogg/ui';

import { FaCheck } from 'react-icons/fa';
import { useTableData } from 'fogg/hooks';

const S3_BUCKET_CONFIGS_COLUMNS = [
  {
    Header: 'Bucket Name',
    columnId: 'bucketName',
    widthRatio: 4
  },
  {
    Header: 'Prefix',
    columnId: 'prefix',
    widthRatio: 2
  },
  {
    Header: 'GovCloud',
    columnId: 'isGovcloudStr',
    widthRatio: 2
  },
  {
    Header: 'Active',
    columnId: 'isActiveStr',
    widthRatio: 2
  },
  {
    columnId: 'actions',
    Header: '',
    align: 'right',
    type: 'action',
    canSort: false,
    canFilter: false,
    widthRatio: 1
  }
];

/**
 * Table to display active s3 bucket configs in org details
 * @param  {object} props
 * @param  {object} [props.s3BucketConfigs={}]
 * @param  {string} props.orgId
 * @param  {bool} [props.isAdmin=false]
 */
const S3BucketConfigTable = ({
  s3BucketConfigs = {},
  orgId = '',
  isAdmin = false
}) => {
  s3BucketConfigs.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const addBucketConfigPath = isAdmin ? routePathByName(ROUTE_ADMIN_ORGS_BUCKET_CONFIG_ADD, {
    wildcard: [orgId]
  }) : routePathByName(ROUTE_ORG_BUCKET_CONFIG_ADD);

  function s3BucketConfigsToTableData (
    orgId,
    s3BucketConfigs = []
  ) {
    if (!Array.isArray(s3BucketConfigs) || s3BucketConfigs.length === 0) return [];

    return s3BucketConfigs.map((s3BucketConfig = {}, index) => {
      const { isGovcloud, isActive } = s3BucketConfig;

      const indexStr = (index + 1).toString();
      const isActiveStr = isActive ? <FaCheck className="text-success"/> : '';
      const isGovcloudStr = isGovcloud ? <FaCheck className="text-success"/> : '';

      const obj = {
        ...s3BucketConfig,
        indexStr,
        isActiveStr,
        isGovcloudStr
      };

      obj.actions = [
        {
          to: '',
          label: 'Edit',
          icon: 'FaPen',
          buttonType: ['text', 'icon-before']
        }
      ];

      obj.actions[0].to = isAdmin ? routePathByName(ROUTE_ADMIN_ORGS_BUCKET_CONFIG_EDIT, {
        wildcard: [orgId, s3BucketConfig.id]
      }) : routePathByName(ROUTE_ORG_BUCKET_CONFIG_EDIT, {
        wildcard: [s3BucketConfig.id]
      });
      return obj;
    });
  }

  const s3BucketConfigsData = s3BucketConfigsToTableData(orgId, s3BucketConfigs);

  const { columns, data, sort } = useTableData({
    columns: S3_BUCKET_CONFIGS_COLUMNS,
    data: s3BucketConfigsData
  });

  return (
    <>
      <TableActions
        actions={[
          {
            to: addBucketConfigPath,
            label: 'Add Bucket Config',
            buttonType: ['text', 'icon-before'],
            icon: 'FaPlusCircle'
          }
        ]}
      />

      <DataTable
        className="s3-bucket-configs-table"
        label="S3 Bucket Configs"
        onSort={sort}
        columns={columns}
        data={data}
        isLoading={false}
        emptyMessage="No Bucket Config set up. Please add one above."
      />
    </>
  );
};

S3BucketConfigTable.propTypes = {
  s3BucketConfigs: PropTypes.arrayOf(PropTypes.object),
  orgId: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default S3BucketConfigTable;
