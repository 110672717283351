export const ORDER_STATUS_MAP = {
  // Status
  pending: {
    label: 'Pending',
    id: 'pending'
  },
  'in-progress': {
    label: 'In Progress',
    id: 'in-progress',
    message: 'Assets are being prepared...'
  },
  approved: {
    label: 'Approved',
    id: 'approved'
  },
  shipped: {
    label: 'Shipped',
    id: 'shipped'
  },
  completed: {
    label: 'Completed',
    id: 'completed'
  },
  // Errors
  rejected: {
    label: 'Rejected',
    id: 'rejected',
    message: 'Order was rejected. Please try again.'
  },
  cancelled: {
    label: 'Cancelled',
    id: 'cancelled'
  },
  failed: {
    label: 'Failed',
    id: 'failed',
    message: 'Order has failed. Please try again.'
  },
  unavailable: {
    label: 'Unavailable',
    id: 'unavailable'
  }
};

export const STATUS_LIST = [
  ORDER_STATUS_MAP.pending,
  ORDER_STATUS_MAP['in-progress'],
  ORDER_STATUS_MAP.approved,
  ORDER_STATUS_MAP.shipped,
  ORDER_STATUS_MAP.completed
];

export const ERROR_LIST = [
  ORDER_STATUS_MAP.rejected,
  ORDER_STATUS_MAP.cancelled,
  ORDER_STATUS_MAP.failed,
  ORDER_STATUS_MAP.unavailable
];
