import {
  exportClassData,
  updateClassData,
  destroyClassData
} from '../lib/util';
import { formatDateTime, dateIsExpired } from 'lib/datetime';
import {
  formatNumberToCurrency,
  formatCurrencyToNumber,
  formatPercentageFloat
} from '../lib/currency';

import { CONTRACT_RESELLER_TIERS } from '../data/contracts';

import ContractPeriod from './contract-period';

const PRICING_TYPES = [
  {
    id: 'data_order',
    label: 'Data Orders'
  },
  {
    id: 'tasking_request',
    label: 'Tasking Requests'
  }
];

// Although the API supports exclusivity hold for data orders and task requests, right now they want to only show the Tasking one on the UI
const PRICING_TYPES_UPLIFT = [
  // {
  //   id: 'data_order',
  //   label: 'Data Orders'
  // },
  {
    id: 'tasking_request',
    label: 'Tasking Requests'
  }
];

const DEFAULT_ANALYTICS_VC_UPLIFT = 0.15;

const defaultContractPricingTypes = PRICING_TYPES.reduce(
  (accumulator, type) => {
    accumulator[type.id] = null;
    return accumulator;
  },
  {}
);

const defaultContractPricingTypesUplift = PRICING_TYPES_UPLIFT.reduce(
  (accumulator, type) => {
    accumulator[type.id] = null;
    return accumulator;
  },
  {}
);

const defaultContract = {
  id: null,
  organizationId: null,
  type: null,
  resellerTier: null,
  startDate: null,
  endDate: null,
  createdAt: null,
  updatedAt: null,

  salesforceContractId: null,
  salesforceOpportunityId: null,

  enforceAvailableFundsCap: null,
  funds: null,
  customUplifts: defaultContractPricingTypes,
  discretionaryDiscount: defaultContractPricingTypes,
  exclusivityHolds: defaultContractPricingTypesUplift,
  entityUplift: null,
  volumeDiscount: null,
  pricingOverrides: null,

  contractPeriods: []
};

class Contract {
  constructor (data = {}) {
    Object.assign(this, defaultContract);
    this.update(data);
  }

  data () {
    return exportClassData(this);
  }

  update (data) {
    const { contractPeriods = [] } = data || {};
    return updateClassData(this, {
      ...data,
      contractPeriods: contractPeriods.map((period) => {
        const isInstance = period && typeof period.data === 'function';
        return isInstance ? period : new ContractPeriod(period);
      })
    });
  }

  destroy () {
    return destroyClassData(this);
  }

  get resellerTierFormatted () {
    const tier = CONTRACT_RESELLER_TIERS.find(
      ({ name }) => name === this.resellerTier
    );
    return tier && tier.label;
  }

  get startDateInt () {
    return this.startDate && new Date(this.startDate).getTime();
  }

  get startDateFormatted () {
    return formatDateTime(this.startDate);
  }

  get endDateInt () {
    return this.endDate && new Date(this.endDate).getTime();
  }

  get endDateFormatted () {
    return formatDateTime(this.endDate);
  }

  get createdAtFormatted () {
    return formatDateTime(this.createdAt);
  }

  get updatedAtFormatted () {
    return formatDateTime(this.updatedAt);
  }

  get fundsInt () {
    return formatCurrencyToNumber(this.funds);
  }

  get fundsFormatted () {
    return formatNumberToCurrency(this.funds);
  }

  get customUpliftsFormatted () {
    return PRICING_TYPES.map((type) => {
      const { id } = type;

      if (typeof this.customUplifts[id] !== 'number') {
        return type;
      }

      return {
        ...type,
        value: formatPercentageFloat(this.customUplifts[id])
      };
    });
  }

  get discretionaryDiscountFormatted () {
    return PRICING_TYPES.map((type) => {
      const { id } = type;

      if (typeof this.discretionaryDiscount[id] !== 'number') {
        return type;
      }

      return {
        ...type,
        value: formatPercentageFloat(this.discretionaryDiscount[id])
      };
    });
  }

  get entityUpliftFormatted () {
    const isNumber = typeof this.entityUplift === 'number';
    return !isNumber ? undefined : formatPercentageFloat(this.entityUplift);
  }

  get volumeDiscountFormatted () {
    const isNumber = typeof this.volumeDiscount === 'number';
    return !isNumber ? undefined : formatPercentageFloat(this.volumeDiscount);
  }

  get exclusivityHoldsFormatted () {
    return PRICING_TYPES_UPLIFT.map((type) => {
      const { id } = type;

      return {
        ...type,
        value: this.exclusivityHolds[id]
      };
    });
  }

  get isAnalyticsVcSubscription () {
    return !this.pricingOverrides ||
      typeof this.pricingOverrides !== 'object' ||
      typeof this.pricingOverrides?.analyticImageryUplifts?.VC !== 'number';
  }

  get analyticsVcPricingOverrideFormatted () {
    return formatPercentageFloat(this.pricingOverrides?.analyticImageryUplifts?.VC || DEFAULT_ANALYTICS_VC_UPLIFT);
  }

  isExpired () {
    return dateIsExpired(this?.endDate);
  }
}

export default Contract;
