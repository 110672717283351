import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, FormRow, FormInput, InputButton } from 'fogg/ui';
import InputRange from 'react-input-range';
import ReactTooltip from 'react-tooltip';
import ReactDatetime from 'react-datetime';
import { TaskRequestTypes } from 'data/task-request-types';
import { taskDefaults, sliderValuesAndRange } from 'lib/tasks';
import { chompFloat } from 'lib/util';
import { fieldByName } from 'lib/fields';
import { useTaskContext } from 'hooks';
import Tooltip from './Tooltip';
import { OptionsPanel } from 'commonLib';

const FormCreateTaskOosm = ({
  primaryTaskFields,
  footerChildren,
  onSubmit,
  onCancel
}) => {
  const { taskState, updateTaskState } = useTaskContext();

  const {
    collectConstraints: {
      imageLength = taskDefaults.oosm.DEFAULT_OOSM_IMAGE_LENGTH,
      mode: oosmModeValue = taskDefaults.DEFAULT_RANGE_MODE,
      offNadirMax = taskDefaults.oosm.DEFAULT_OOSM_LOOK_ANGLE_MAX,
      offNadirMin = taskDefaults.oosm.DEFAULT_OOSM_LOOK_ANGLE_MIN,
      polarization = 'HH'
    } = {},
    customAttribute1,
    customAttribute2
  } = taskState;

  /**
   * event handlers
   */
  function handleRadioGroupChange (type, input) {
    const { target } = input;
    if (type === 'oosmImageLength') {
      updateTaskState({
        collectConstraints: {
          imageLength: parseInt(target.value, 10)
        }
      });
    }
    if (type === 'oosmMode') {
      updateTaskState({
        collectConstraints: {
          mode: target.value
        }
      });
    }
  }

  function handleMinMaxSliderChange (value, stateCallback) {
    const { min, max } = value;
    const updatedValue = {
      min: typeof min === 'number' ? chompFloat(min, 2) : min,
      max: typeof max === 'number' ? chompFloat(max, 2) : max
    };

    // this callback can be used to save the state right in the UI component
    if (typeof stateCallback === 'function') {
      stateCallback(updatedValue);
    }
  }

  /**
   * OOSM task submit handler
   */
  function handleSubmit (e, fields) {
    const payload = fields;

    payload.collectionTier = {
      value: taskDefaults.oosm.DEFAULT_OOSM_COLLECTION_TIER
    };
    payload.collectMode = { value: 'stripmap' };
    payload.windowOpen = { value: new Date(Date.now()).toISOString() };
    payload.windowClose = {
      value: ReactDatetime.moment
        .utc()
        .add(taskDefaults.oosm.DEFAULT_OOSM_WINDOW_CLOSE_PERIOD, 'days')
        .toISOString()
    };
    payload.offNadirMin = { value: offNadirMin };
    payload.offNadirMax = { value: offNadirMax };
    payload.imageLength = { value: parseInt(imageLength, 10) };
    payload.mode = { value: oosmModeValue };
    payload.polarization = { value: polarization || 'HH' };
    payload.squint = { value: taskDefaults.DEFAULT_SQUINT };
    payload.radarParametersBandwidth = {
      value: taskDefaults.DEFAULT_RADAR_PARAMETERS_BANDWIDTH
    };
    payload.radarParametersCenterFrequency = {
      value: taskDefaults.DEFAULT_RADAR_PARAMETERS_CENTER_FREQUENCY
    };
    payload.radarParametersPrf = {
      value: taskDefaults.DEFAULT_RADAR_PARAMETERS_PRF
    };
    payload.srrMin = { value: taskDefaults.DEFAULT_SRR_MIN };
    payload.srrMax = { value: taskDefaults.DEFAULT_SRR_MAX };
    payload.preApproval = { value: false };
    payload.isOosmTask = true;
    payload.azrMin = { value: taskDefaults.oosm.DEFAULT_OOSM_AZR_MIN };
    payload.azrMax = { value: taskDefaults.oosm.DEFAULT_OOSM_AZR_MAX };

    payload.taskRequestType = { value: TaskRequestTypes.OOSM };

    if (typeof onSubmit === 'function') {
      onSubmit(e, payload);
    }
  }

  /**
   * validation rules for handleSubmit
   */
  const validationRules = {
    windowOpen: {
      ...fieldByName('windowOpen'),
      required: false,
      isValid: (date) => {
        return date > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    windowClose: {
      ...fieldByName('windowClose'),
      isValid: (date) => {
        return date > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    customAttribute1: {
      ...fieldByName('customAttribute1'),
      isValid: () => {
        return customAttribute1?.length > 1;
      }
    },
    customAttribute2: {
      ...fieldByName('customAttribute2'),
      isValid: () => {
        return customAttribute2?.length > 1;
      }
    }
  };

  return (
    <Form
      className="create-task-form"
      onSubmit={handleSubmit}
      rules={validationRules}
    >
      {primaryTaskFields}

      <FormRow>
        <div className="search-filters-range-slider" data-testid="look-angle">
          <label>Look Angle:</label>
          <InputRange
            {...sliderValuesAndRange({
              defaultValueMin: taskDefaults.oosm.DEFAULT_OOSM_LOOK_ANGLE_MIN,
              defaultValueMax: taskDefaults.oosm.DEFAULT_OOSM_LOOK_ANGLE_MAX,
              defaultRangeMin: 45,
              defaultRangeMax: 55,
              valueSideEffects: {
                min: offNadirMin,
                max: offNadirMax
              }
            })}
            onChange={(value) =>
              handleMinMaxSliderChange(value, (updatedValue) => {
                updateTaskState({
                  collectConstraints: {
                    offNadirMin: updatedValue.min,
                    offNadirMax: updatedValue.max
                  }
                });
              })
            }
          />
        </div>
      </FormRow>
      <div className="oosm-mode">
        <label>Mode:</label>
        <ReactTooltip
          id="modeTip"
          place="top"
          effect="solid"
          type="dark"
          multiline={true}
          className="customTip"
        >
          <div>
            Maximum image width will assure at least a 20km wide image but with
            variable image quality at the edges. The other three options will
            ensure more consistent image quality at slightly smaller image
            widths (15km – 20km). High range resolution is best for cases when
            optimizing for NESZ is not the primary concern (e.g., vessel
            detection). Medium range resolution is used to balance between range
            resolution and NESZ (e.g., iceberg detection). Low range resolution
            is best for cases where NESZ optimization is a primary concern
            (e.g., oil spill detection).
          </div>
        </ReactTooltip>
        <span className="general-tool-tip" data-tip data-for="modeTip">
          ?
        </span>
        <div className="oosm-mode-inputs" data-testid="image-width">
          <InputButton
            id="maxMode"
            name="oosmMode"
            label="Max image width"
            type="radio"
            isChecked={oosmModeValue === taskDefaults.DEFAULT_RANGE_MODE}
            value={taskDefaults.DEFAULT_RANGE_MODE}
            onChange={(value) => handleRadioGroupChange('oosmMode', value)}
          />
          <InputButton
            id="highMode"
            name="oosmMode"
            label="High range resolution (1 - 2 meters)"
            type="radio"
            isChecked={oosmModeValue === 'high_grr'}
            value="high_grr"
            onChange={(value) => handleRadioGroupChange('oosmMode', value)}
          />
          <InputButton
            id="mediumMode"
            name="oosmMode"
            label="Medium range resolution (3 - 5 meters)"
            type="radio"
            isChecked={oosmModeValue === 'medium_grr'}
            value="medium_grr"
            onChange={(value) => handleRadioGroupChange('oosmMode', value)}
          />
          <InputButton
            id="lowMode"
            name="oosmMode"
            label="Low range resolution (8 - 10 meters)"
            type="radio"
            isChecked={oosmModeValue === 'low_grr'}
            value="low_grr"
            onChange={(value) => handleRadioGroupChange('oosmMode', value)}
          />
        </div>
      </div>
      <div className="oosm-imagelength" data-testid="image-length">
        <label>Image Length:</label>
        <div className="oosm-imagelength-inputs">
          <InputButton
            id="oosmImageLength50"
            name="oosmImageLengthValue"
            label="50km"
            type="radio"
            isChecked={
              imageLength === taskDefaults.oosm.DEFAULT_OOSM_IMAGE_LENGTH
            }
            value={taskDefaults.oosm.DEFAULT_OOSM_IMAGE_LENGTH.toString()}
            onChange={(value) =>
              handleRadioGroupChange('oosmImageLength', value)
            }
          />
          <InputButton
            id="oosmImageLength100"
            name="oosmImageLengthValue"
            label="100km"
            type="radio"
            isChecked={imageLength === 100000}
            value={'100000'}
            onChange={(value) =>
              handleRadioGroupChange('oosmImageLength', value)
            }
          />
        </div>
      </div>
      <FormRow>
        <div className="search-dropdowns" data-testid="polarization">
          <FormInput
            id="oosmPolarization"
            type="select"
            label="Polarization"
            clearable={false}
            options={taskDefaults.AVAILABLE_POLARIZATION}
            defaultValue={polarization}
            onChange={(selection) =>
              updateTaskState({
                collectConstraints: {
                  polarization: selection?.value || undefined
                }
              })
            }
          />
        </div>
      </FormRow>
      <OptionsPanel label="Optional Parameters" id="optional-parameters">
        <FormRow className="form-row-block">
          <label className="form-label standalone-label" htmlFor="customAttribute1">
            Custom Attribute 1
            <Tooltip
              className="customer-internal-id-tip"
              id="customAttribute1Tip"
              multiline={true}
              tooltipText='Attribute to help customers track a Capella task with their internal systems'
            />
          </label>
          <FormInput
            id="customAttribute1"
            defaultValue={customAttribute1}
            onChange={({ target: { value = '' } }) =>
              updateTaskState({
                customAttribute1: value
              })
            }
            validationMessage="2 character minimum for ID"
          />
        </FormRow>
        <FormRow className="form-row-block">
          <label className="form-label standalone-label" htmlFor="customAttribute2">
            Custom Attribute 2
            <Tooltip
              className="customer-po-id-tip"
              id="customAttribute2Tip"
              multiline={true}
              tooltipText='Attribute to help customers track a Capella task with their internal systems'
            />
          </label>
          <FormInput
            id="customAttribute2"
            defaultValue={customAttribute2}
            onChange={({ target: { value = '' } }) =>
              updateTaskState({
                customAttribute2: value
              })
            }
            validationMessage="2 character minimum for ID"
          />
        </FormRow>
      </OptionsPanel>

      <FormRow>
        <p className="caption">
          Dates and times are processed as UTC upon submission
        </p>
      </FormRow>

      {footerChildren}

      <FormRow className="form-row-actions">
        <Button>Create Task</Button>

        <Button type="text" onClick={onCancel}>
          Cancel Task Request
        </Button>
      </FormRow>
    </Form>
  );
};

FormCreateTaskOosm.propTypes = {
  primaryTaskFields: PropTypes.node,
  footerChildren: PropTypes.node,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default FormCreateTaskOosm;
