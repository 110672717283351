import { Circle, GeometryUtil } from 'leaflet';
import { AOI_SHAPE_RANGE } from 'data/aois';

const MAX_KM = AOI_SHAPE_RANGE.max.km;

function metersToKM (meters = 0) {
  return meters / 1000;
}

/**
 * returns true or false if the area in meters is over or under the max size in kilometers
 *
 * @param {number} areaInMeters
 * @param {number} [maxSizeInKM=MAX_KM]
 * @returns {boolean}
 */
function isAreaGreaterThanMaxSize (areaInMeters, maxSizeInKM = MAX_KM) {
  const areaInKM = metersToKM(areaInMeters);
  return areaInKM > maxSizeInKM;
}

/**
 * determines layer type and returns true or false if area is over or under the max size in kilometers
 *
 * @param {import('leaflet').Layer} layer
 * @param {number} maxSizeInKM
 * @returns {boolean}
 */
function isLayerGreaterThanMaxSize (layer, maxSizeInKM = MAX_KM) {
  if (layer instanceof Circle) {
    // _mRadius returns the radius in meters
    const areaInMeters = Math.PI * Math.pow(layer._mRadius, 2) / 1000;
    return isAreaGreaterThanMaxSize(areaInMeters, maxSizeInKM);
  } else {
    // geodesicArea returns area in centimeters??
    const areaInCentimeters = GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
    return isAreaGreaterThanMaxSize(areaInCentimeters / 1000, maxSizeInKM);
  }
}

/**
 * shapes the AOI data before attaching as a payload to the api request
 *
 * @param {object} aoiJson geoJson data of AOI
 */
function shapeAoiPayload (aoiJson) {
  const payload = { ...aoiJson };

  // ensure there is a properties object property
  if (!payload.properties) {
    payload.properties = {};
  }

  // ensure radius is null if undefined
  if (typeof payload.properties.radius === 'undefined') {
    payload.properties.radius = null;
  }

  return payload;
}

export {
  isAreaGreaterThanMaxSize,
  isLayerGreaterThanMaxSize,
  metersToKM,
  shapeAoiPayload
};
