import { useState, useEffect } from 'react';
import { useMappedState } from 'redux-react-hook';
import AOI from '../models/aoi';

export default function useAois () {
  const mapState = ({ aois }) => aois;
  const aoisState = useMappedState(mapState);

  const { aois = [] } = aoisState;
  const count = aois.length;

  const [aoisStore, setAoisStore] = useState(aois);
  const [aoiFeatures, setAoiFeatures] = useState(undefined);

  useEffect(() => {
    if (!aois) setAoisStore([]);
    setAoisStore(aois.map((aoi) => new AOI(aoi)));

    // Convert array of aois into a geoJson FeatureCollection for MapPreview
    let aoiFeatures;
    if (aois.length) {
      aoiFeatures = {
        type: 'FeatureCollection',
        features: aois.map((aoi = {}) => {
          // Customize each aoi feature to add thicker outline for better zoomed out visibility
          const { properties = {} } = aoi;
          aoi.properties = {
            ...properties,
            shapeOptions: {
              style: {
                weight: 5
              }
            }
          };
          return aoi;
        })
      };
    }
    setAoiFeatures(aoiFeatures);
  }, [aois, count]);

  return {
    ...aoisState,
    aois: aoisStore,
    aoiFeatures: aoiFeatures,
    count
  };
}
