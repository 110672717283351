import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Layout from 'components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from '../components/Container';
import ProfileSidebar from '../components/ProfileSidebar';
import ProfileSidebarSection from '../components/ProfileSidebarSection';
import ProfileSidebarList from '../components/ProfileSidebarList';

import { fillEmptyCollection } from '../lib/data';

const TemplateCollection = ({
  collection = {},
  breadcrumbs = [],
  state = {}
}) => {
  const { id } = collection;

  const { isLoading = false } = state;

  let activeCollection = collection;

  if (isLoading) {
    activeCollection = fillEmptyCollection();
  }

  const {
    title,
    description,
    keywords,
    summaries,
    stacVersion,
    stacExtensions = [],
    extent
  } = activeCollection;

  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'template-collection' }}>
        <title>{title}</title>
      </Helmet>
      <Container className={`content ${isLoading ? 'content-is-loading' : ''}`}>
        {hasBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}

        <PageHeader title={title} subtitle={`ID: ${id}`} />

        <div className="profile">
          <div className="profile-content">
            <div className="profile-content-header">
              <h3>Description</h3>
            </div>
            <div className="profile-section">
              <p>{description}</p>
            </div>
            {extent && (
              <>
                <div className="profile-content-header">
                  <h3>Extent</h3>
                </div>
                <div className="profile-section collection-extents">
                  {Object.keys(extent).map((key) => {
                    return (
                      <div
                        key={key}
                        className="collection-card collection-extent"
                      >
                        <h4>{key}</h4>
                        <ul>
                          {extent[key].map((ext, index) => {
                            return (
                              <li key={`${ext}-${index}`}>
                                <p>{ext}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {summaries && (
              <>
                <div className="profile-content-header">
                  <h3>Summaries</h3>
                </div>
                <div className="profile-section collection-summaries">
                  {Object.keys(summaries).map((key) => {
                    return (
                      <div
                        key={key}
                        className="collection-card collection-summary"
                      >
                        <h4>{key}</h4>
                        <ul>
                          {summaries[key].map((summary) => {
                            return (
                              <li key={summary}>
                                <p>{summary}</p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>

          <ProfileSidebar>
            <ProfileSidebarSection
              header="Keywords"
              className="profile-sidebar-list"
            >
              <ProfileSidebarList
                className="profile-sidebar-management"
                items={keywords}
              />
            </ProfileSidebarSection>
            <ProfileSidebarSection
              header="STAC Version"
              className="profile-sidebar-list"
            >
              <p>{stacVersion}</p>
            </ProfileSidebarSection>
            <ProfileSidebarSection
              header="STAC Extensions"
              className="profile-sidebar-list"
            >
              <ProfileSidebarList
                className="profile-sidebar-management"
                items={stacExtensions}
              />
            </ProfileSidebarSection>
          </ProfileSidebar>
        </div>
      </Container>
    </Layout>
  );
};

TemplateCollection.propTypes = {
  collection: PropTypes.object,
  breadcrumbs: PropTypes.array,
  state: PropTypes.object
};

export default TemplateCollection;
