import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const IconBadge = ({
    className,
    icon,
    label,
    hiddenLabel = false,
    isLoading = false
  }) => {
  let badgeClass = 'icon-badge';

  if (isLoading) {
    return <span className="icon-badge loading">Loading...</span>;
  }
  if (className) {
    badgeClass = `${badgeClass} ${className}`;
  }

  return (
    <>
    <span className={badgeClass} data-tip data-for="labelTooltip">
        <i>{icon}</i>
        {!hiddenLabel && (label)}
    </span>
    {hiddenLabel && (
      // Label text displayed in tooltip when hiddenLabel=true
      <ReactTooltip
        id="labelTooltip"
        place="right"
        effect="solid"
        type="dark"
        multiline={true}
        className="customTip"
      >
        <div>{label}</div>
      </ReactTooltip>
    )}
    </>
  );
};

IconBadge.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  hiddenLabel: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default IconBadge;
