import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { Form, FormInput, FormRow } from 'fogg/ui';
import { Button } from '@trussworks/react-uswds';
import { useDispatch } from 'redux-react-hook';
import { fieldByName } from 'lib/fields';
import { routePathByName, navigateTo } from 'lib/routes';
import { ROUTE_TASKS_DETAILS } from 'data/route-names';
import { updateTaskById } from 'state/actions/tasks';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Tooltip from 'components/Tooltip';
import PageHeader from 'components/PageHeader';
import Breadcrumbs from 'components/Breadcrumbs';
import { triggerError, triggerSuccess, clearNotice } from 'commonLib';

const TemplateEditTask = ({ task = {}, isLoading = false, breadcrumbs }) => {
  const { id: taskId, name } = task;
  const dispatch = useDispatch();

  const [disableSubmit, setDisableSubmit] = useState(false);

  // Route to task details of the task being edited
  const taskDetailsPage = routePathByName(ROUTE_TASKS_DETAILS, {
    wildcard: [taskId]
  });

  const [newProperties, updateNewProperties] = useState({});

  const { properties = {} } = task;

  useEffect(() => {
    updateNewProperties(properties);
  }, [task]);

  function handleSubmit (e, fields) {
    dispatch(clearNotice());
    setDisableSubmit(true);

    const payload = {
      properties: {
        customAttribute1: fields.customAttribute1?.value,
        customAttribute2: fields.customAttribute2?.value
      }
    };
    dispatch(updateTaskById(taskId, payload))
      .then(() => {
        setDisableSubmit(false);
        dispatch(triggerSuccess('Task updated successfully!'));
        navigateTo(taskDetailsPage);
      })
      .catch(() => {
        setDisableSubmit(false);
        dispatch(triggerError('There was a problem updating your task! Please retry, or contact support.'));
      });
  }

  /**
   * validation rules for handleSubmit
   */
  const validationRules = {
    customAttribute1: {
      ...fieldByName('customAttribute1'),
      isValid: () => {
        return (
          newProperties.customAttribute1?.length > 1 ||
          newProperties?.customAttribute1?.length === 0
        );
      }
    },
    customAttribute2: {
      ...fieldByName('customAttribute2'),
      isValid: () => {
        return (
          newProperties?.customAttribute2?.length > 1 ||
          newProperties?.customAttribute2?.length === 0
        );
      }
    }
  };

  function handleCancel (event) {
    event.preventDefault();
    navigateTo(taskDetailsPage);
  }
  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'template-task-edit' }}>
        <title>Edit Task</title>
      </Helmet>
      <Container className="content" type={isLoading ? 'is-loading' : null}>
        <Breadcrumbs items={breadcrumbs} />
        <PageHeader title={name} subtitle={`ID: ${taskId}`} />
        <Form
          className="edit-task-form"
          onSubmit={handleSubmit}
          rules={validationRules}
        >
          <FormRow className="form-row-block">
            <label
              className="form-label standalone-label"
              htmlFor="customAttribute1"
            >
              Custom Attribute 1
              <Tooltip
                className="custom-attribute-1-tip"
                id="customAttribute1Tip"
                multiline={true}
                tooltipText="Attribute to help customers track a Capella task with their internal systems"
              />
            </label>
            <FormInput
              id="customAttribute1"
              placeholder="Enter a Custom Attribute..."
              defaultValue={newProperties.customAttribute1}
              onChange={({ target: { value = '' } }) =>
                updateNewProperties({
                  ...newProperties,
                  customAttribute1: value
                })
              }
              disabled={isLoading}
              validationMessage="2 character minimum for ID"
            />
          </FormRow>
          <FormRow className="form-row-block">
            <label
              className="form-label standalone-label"
              htmlFor="customAttribute2"
            >
              Custom Attribute 2
              <Tooltip
                className="custom-attribute-2-tip"
                id="customAttribute2Tip"
                multiline={true}
                tooltipText="Attribute to help customers track a Capella task with their internal systems"
              />
            </label>
            <FormInput
              id="customAttribute2"
              placeholder="Enter a Custom Attribute..."
              defaultValue={newProperties.customAttribute2}
              disabled={isLoading}
              onChange={({ target: { value = '' } }) =>
                updateNewProperties({
                  ...newProperties,
                  customAttribute2: value
                })
              }
              validationMessage="2 character minimum for ID"
            />
          </FormRow>
          <FormRow className="form-row-actions">
            <Button
              disabled={isLoading || disableSubmit}
              type="submit"
              className="primary"
              size="big"
              data-testid="submit-button"
            >
              Update Task
            </Button>
            <Button type="text" unstyled onClick={handleCancel}>
              Cancel
            </Button>
          </FormRow>
        </Form>
      </Container>
    </Layout>
  );
};

TemplateEditTask.propTypes = {
  task: PropTypes.object,
  isLoading: PropTypes.bool,
  breadcrumbs: PropTypes.array
};

export default TemplateEditTask;
