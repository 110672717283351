import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormRow, FormInput } from 'fogg/ui';

import { getAvailableCountries } from 'lib/fields';

const countries = getAvailableCountries();

const SELECT_SINGLE_VALUE_TYPES = ['country'];

// This is just like FormAdminEditOrganization but for now the
// only difference is salesforce ID textbox is disabled
const FormEditOrganization = ({
  organization = {},
  disabled = false,
  onSubmit
}) => {
  const { name, address = {}, salesforceAccountId } = organization;
  const { id: addressId, street, city, state, postalCode, country } = address;

  function handleSubmit (e, fields = {}) {
    // Normalize array based select fields to their selected value
    SELECT_SINGLE_VALUE_TYPES.forEach((type) => {
      const value = fields[type] && fields[type].value;
      if (Array.isArray(value) && value.length > 0) {
        fields[type].value = value[0];
      }
    });

    if (typeof onSubmit === 'function') {
      onSubmit(e, fields);
    }
  }

  return (
    <Form className="form-admin-create-user" rules={{}} onSubmit={handleSubmit}>
      <FormRow>
        <p className="caption">All fields are required.</p>
      </FormRow>

      <FormInput
        id="addressId"
        type="hidden"
        required={true}
        defaultValue={addressId}
        disabled={disabled}
      />

      <FormRow>
        <FormInput
          id="organizationName"
          label="Organization Name"
          required={true}
          defaultValue={name}
          disabled={disabled}
          autoComplete="organization org"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="country"
          label="Country"
          type="select"
          options={countries}
          defaultValue={country}
          required={true}
          disabled={disabled}
          autoComplete="organization country"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="street"
          label="Street Address"
          defaultValue={street}
          required={true}
          disabled={disabled}
          autoComplete="organization street-address"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="city"
          label="City"
          defaultValue={city}
          required={true}
          disabled={disabled}
          autoComplete="organization address-level2"
        />
        <FormInput
          id="state"
          label="State / Province"
          defaultValue={state}
          required={true}
          disabled={disabled}
          autoComplete="organization address-level1"
        />
        <FormInput
          id="postalCode"
          label="Postal Code"
          defaultValue={postalCode}
          required={true}
          disabled={disabled}
          autoComplete="organization postal-code"
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="salesforceAccountId"
          label="Salesforce Account ID"
          defaultValue={salesforceAccountId}
          disabled={true}
        />
      </FormRow>

      <FormRow className="form-row-actions">
        <Button full={true} disabled={disabled}>
          Save Changes
        </Button>
      </FormRow>
    </Form>
  );
};

FormEditOrganization.propTypes = {
  organization: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool
};

export default FormEditOrganization;
