import React from 'react';
import { useDispatch } from 'redux-react-hook';
import { Helmet } from 'react-helmet';
import { MdPersonAdd } from 'react-icons/md';

import { ROUTE_RESELLER_ORGS, ROUTE_RESELLER } from 'data/route-names';
import { RESELLER_ROLE } from 'data/roles';
import { createNewOrganization, triggerNotice } from 'state/actions';
import { fieldsObjectToValues } from 'lib/util';
import { redirectTo, routePathByName } from 'lib/routes';
import { logError } from 'lib/logger';
import { useAdmin } from 'hooks';

import Layout from 'components/Layout';
import Breadcrumbs from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import FormCreateOrganization from 'components/FormCreateOrganization';

const PAGE_TITLE = 'Create New Organization';

const ResellerOrganizationsCreate = () => {
  const dispatch = useDispatch();

  const { actions: adminActions = {} } = useAdmin();
  const { createNewOrganization: createAction = {} } = adminActions;
  const { isLoading: createIsLoading } = createAction;

  const isLoading = createIsLoading;

  /**
   * handleSubmitForm
   * @description Makes a request to create an org on form submit
   */

  async function handleSubmitForm (e, fields) {
    const organizationData = fieldsObjectToValues(fields);

    const { type, subResellerId } = organizationData;

    if (Array.isArray(type) && type.length) {
      organizationData.type = type[0];
    }

    if (typeof subResellerId !== 'undefined') {
      if (Array.isArray(subResellerId) && subResellerId.length) {
        organizationData.subResellerId = subResellerId[0];
      }
    }

    try {
      await dispatch(createNewOrganization(organizationData));
    } catch (e) {
      logError(e.message, organizationData);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try again!'
        })
      );
      return;
    }

    redirectTo(routePathByName(ROUTE_RESELLER_ORGS));

    dispatch(
      triggerNotice({
        type: 'success',
        weight: 'bold',
        align: 'center',
        text: 'Organization was successfully created!'
      })
    );
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-reseller page-reseller-organizations page-reseller-organizations-create'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Reseller',
              to: routePathByName(ROUTE_RESELLER)
            },
            {
              label: 'Organizations',
              to: routePathByName(ROUTE_RESELLER_ORGS)
            }
          ]}
        />
        <PageHeader
          title={PAGE_TITLE}
          icon={<MdPersonAdd className="icon-md" />}
        />
        <FormCreateOrganization
          onSubmit={handleSubmitForm}
          disabled={isLoading}
          formAccess={RESELLER_ROLE}
        />
      </Container>
    </Layout>
  );
};

export default ResellerOrganizationsCreate;
