import React from 'react';
import PropTypes from 'prop-types';
import { FormRow } from 'fogg/ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { ANALYTICS_ACCESS_ROLE } from '../data/roles';
import { ROUTE_ADMIN_ORGS_DETAILS } from '../data/route-names';
import { routePathByName } from '../lib/routes';
import { useUser } from '../hooks';

import ReadOnlyField from './FormReadOnlyField';

const OrganizationProfile = ({ organization = {}, licenses }) => {
  const {
    type,
    address = {},
    salesforceAccountId,
    roles = [],
    s3PushEnabled,
    amplitudeChangeDetectionEnabled = false,
    vesselClassificationEnabled = false,
    resellerOrg,
    availableLicenses = []
  } = organization;
  const { street, city, state, postalCode, country } = address;

  const { user = {} } = useUser();

  const flags = useFlags();

  const displayLicenses = licenses && availableLicenses.map(
    license => licenses.all.find(licenseDef => licenseDef.value === license));

  const hasAnalyticsAccess =
    roles.length && roles.includes(ANALYTICS_ACCESS_ROLE);
  return (
    <div className="organization-profile">
      <ul>
        <li>
          <FormRow>
            <ReadOnlyField label="Type" value={type} />
            {resellerOrg && (
              <ReadOnlyField label="Reseller Organization" value={
                user.isAdmin()
                  ? <a href={routePathByName(ROUTE_ADMIN_ORGS_DETAILS, {
                    wildcard: [resellerOrg.id]
                  })}>{resellerOrg.name}</a>
                  : resellerOrg.name
              } />)
            }
          </FormRow>
        </li>
        <li>
          <FormRow>
            <ReadOnlyField label="Street" value={street} />
          </FormRow>
          <FormRow>
            <ReadOnlyField label="City" value={city} />
            <ReadOnlyField label="State" value={state} />
            <ReadOnlyField label="Postal Code" value={postalCode} />
          </FormRow>
          <FormRow>
            <ReadOnlyField label="Country" value={country} />
          </FormRow>
        </li>
        <li>
          <FormRow>
            <ReadOnlyField
              label="Salesforce Account ID"
              value={salesforceAccountId || '-'}
            />
          </FormRow>
        </li>
        <li className="analytics-status" data-testid="analytic-test">
          <h4 className='form-subheader'>Analytics</h4>
          <FormRow>
            <ReadOnlyField
              label="GCM Module"
              value={hasAnalyticsAccess ? 'ENABLED' : 'NOT ENABLED'}
              className={hasAnalyticsAccess ? 'text-success' : ''}
            />
            <ReadOnlyField
              label="Amplitude Change Detection"
              value={amplitudeChangeDetectionEnabled ? 'ENABLED' : 'NOT ENABLED'}
              className={amplitudeChangeDetectionEnabled ? 'text-success' : ''}
            />
            {flags.vesselClassificationEnabled &&
              <ReadOnlyField
                label="Vessel Classification"
                value={vesselClassificationEnabled ? 'ENABLED' : 'NOT ENABLED'}
                className={vesselClassificationEnabled ? 'text-success' : ''}
              />}
          </FormRow>
        </li>
        {licenses && (
          <li className="available-licenses">
            <FormRow>
              <ReadOnlyField
                label="Available Licenses"
                value={displayLicenses.map((license, i) => (
                  <p key={i}>{license.label}</p>
                ))}
              />
            </FormRow>
          </li>
        )}
        <li className="s3-config-table">
          <FormRow>
            <ReadOnlyField
              label="Push to Amazon S3"
              value={s3PushEnabled ? 'ENABLED' : 'NOT ENABLED'}
              className={s3PushEnabled ? 'text-success' : ''}
            />
          </FormRow>
        </li>
      </ul>
    </div>
  );
};

OrganizationProfile.propTypes = {
  organization: PropTypes.object,
  licenses: PropTypes.object
};

export default OrganizationProfile;
