export const ACCESS_SEQUENCE_STATUS_READY = 'ready';
export const ACCESS_SEQUENCE_STATUS_LOADING = 'loading';
export const ACCESS_SEQUENCE_STATUS_ERROR = 'error';

export const ACCESS_SEQUENCE_MESSAGE_DEFAULT = undefined;
export const ACCESS_SEQUENCE_MESSAGE_NOT_POINT =
  'Access information is only available for point targets';
export const ACCESS_SEQUENCE_MESSAGE_CREATING = 'Creating access request...';
export const ACCESS_SEQUENCE_MESSAGE_CHECKING_STATUS =
  'Checking request status...';
export const ACCESS_SEQUENCE_MESSAGE_FETCHING_ACCESSES = 'Fetching accesses...';
export const ACCESS_SEQUENCE_MESSAGE_FETCHING_FOOTPRINTS = 'Fetching potential access footprints...';
export const ACCESS_SEQUENCE_MESSAGE_SUCCESS_PART_1 =
  'Access opportunities for ';
export const ACCESS_SEQUENCE_MESSAGE_SUCCESS_PART_2 =
  ' days after Window open';
export const ACCESS_SEQUENCE_MESSAGE_ERROR = 'Error!';
export const ACCESS_SEQUENCE_NOT_FOUND_ERROR = 'No access times found';
export const ACCESS_SEQUENCE_ACQUISITION_ERROR =
  'No acquisition opportunities available for the selected location and time window';
export const ACCESS_SEQUENCE_WINDOW_CLOSE_ERROR = 'Access information only available within 90 days from today';

export const TASK_ACCESS_REQUEST_STATUS_COMPLETED = 'completed';
export const TASK_ACCESS_REQUEST_STATUS_ERROR = 'error';
