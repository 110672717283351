import React from 'react';
import PropTypes from 'prop-types';

import TemplateOrder from 'templates/TemplateOrder';

import { useFetchOrder, useFetchOrderAssets, useFetchContracts, useContracts } from 'hooks';

const OrderPage = ({ orderId }) => {
  const defaultOrder = {
    id: orderId
  };

  const {
    isLoading: fetchOrderIsLoading,
    order = defaultOrder
  } = useFetchOrder(orderId, true);

  const {
    isLoading: fetchAssetsIsLoading,
    items
  } = useFetchOrderAssets(orderId);

  const {
    isLoading: isContractsLoading,
    ledgerBillingEnabled = false
  } = useFetchContracts();
  const { contracts } = useContracts();

  const assets = {
    items
  };

  const orderState = {
    isLoading: fetchOrderIsLoading || fetchAssetsIsLoading || isContractsLoading
  };

  // loop in contract
  let contractOrder = order;
  if (ledgerBillingEnabled && order.contractId) {
    const contract = contracts.find((contract) => contract.id === order.contractId);
    contractOrder = {
      ...order,
      contract
    };
  }

  return <TemplateOrder order={contractOrder} assets={assets} state={orderState} />;
};

OrderPage.propTypes = {
  orderId: PropTypes.string
};

export default OrderPage;
