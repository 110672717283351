import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import {
  useLocation,
  useOrgFetchTasks,
  useOrgFetchUsers,
  useOrgTasks,
  useUser,
  useFetchCollectionTypes
} from 'hooks';
import { routePathByName } from 'lib/routes';
import {
  ORG_TASKS_TABLE_COLUMNS,
  taskFilterParamsToObject,
  prependDateTimeString,
  getFilterOptions,
  parseTableColumnsByFlag
} from 'lib/tasks';

import TemplateTasks from 'templates/TemplateTasks';

import { ORG_MANAGER_ROLE } from 'data/roles';
import {
  ROUTE_ORG_TASKS,
  ROUTE_ORG_TASKS_DETAILS,
  ROUTE_ORG_REPEAT_REQUESTS,
  ROUTE_ORG_REPEAT_REQUESTS_DETAILS,
  ROUTE_ORG_TASKS_CONTRACT_DETAILS
} from 'data/route-names';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';
import { updateOrgTasksColumns } from 'state/actions';

const organizationPath = routePathByName('organization');

const OrganizationTasksPage = () => {
  let orgId, isLoadingUser;
  const { queryParams = {} } = useLocation();
  const { user = {} } = useUser();
  const {
    tasks = [],
    totalPages,
    columns
  } = useOrgTasks({
    includeSpawned: true,
    includeContractDetails: true,
    contractRouteName: ROUTE_ORG_TASKS_CONTRACT_DETAILS
  }) || {};

  const dispatch = useDispatch();

  const { organization = {} } = user;
  const {
    permittedCollectionTiers: { taskingRequest: permittedTiers = [] }
  } = organization;

  if (organization.id) {
    orgId = organization.id;
    isLoadingUser = false;
  } else {
    isLoadingUser = true;
  }

  const { page = 1, limit = DEFAULT_PAGING_LIMIT, filters = {}, sort, order } = queryParams || {};

  // Convert filters & sort in query params to format tasks table can use
  // memoize the obj to avoid unnecessary re-renders
  const filtersParamObject = useMemo(() => {
    return taskFilterParamsToObject(filters);
  }, [filters]);

  const sortObject = useMemo(() => {
    return { sort, order };
  }, [sort, order]);

  // setting forceNew makes sure we always get updated tasks when we visit the page

  const { isLoading: isLoadingOrgTasks, dispatchTasksCsvByOrgId } = useOrgFetchTasks({
    orgId,
    forceNew: true,
    page,
    limit,
    filters: filtersParamObject,
    sortObject
  });

  const { isLoading: isLoadingOrgUsers } = useOrgFetchUsers({
    orgId
  });

  const { isLoading: isLoadingCollectionTypes, collectionTypes } = useFetchCollectionTypes();

  const isLoading =
    isLoadingUser ||
    isLoadingOrgTasks ||
    isLoadingOrgUsers ||
    isLoadingCollectionTypes;

  const breadcrumbs = [
    {
      label: 'Organization',
      to: organizationPath
    }
  ];

  const routeNames = {
    tasks: ROUTE_ORG_TASKS,
    repeatRequests: ROUTE_ORG_REPEAT_REQUESTS,
    taskDetails: ROUTE_ORG_TASKS_DETAILS,
    repeatDetails: ROUTE_ORG_REPEAT_REQUESTS_DETAILS
  };

  const flags = useFlags();

  // Reset to default tasks table columns order & active state
  const resetColumns = () => {
    dispatch(updateOrgTasksColumns(parseTableColumnsByFlag({ columns: ORG_TASKS_TABLE_COLUMNS, flags, organizationId: orgId })));
  };

  useEffect(() => {
    if (columns && flags.collectionTypeTaskingFormEnabled) {
      dispatch(updateOrgTasksColumns(parseTableColumnsByFlag({ columns: ORG_TASKS_TABLE_COLUMNS, flags, organizationId: orgId })));
    }
  }, [flags]);

  return (
    <TemplateTasks
      id="tabpanel_0"
      isLoading={isLoading}
      tasks={tasks}
      collectionTypes={collectionTypes}
      paging={{ currentPage: Number(page), totalPages, limit: Number(limit) }}
      filters={filtersParamObject}
      filterOptions={getFilterOptions({ permittedTiers, flags, organizationId: orgId, collectionTypes })}
      routeNames={routeNames}
      accessType={ORG_MANAGER_ROLE}
      breadcrumbs={breadcrumbs}
      tableColumns={columns}
      resetColumns={resetColumns}
      handleColumnsChange={(cols) => dispatch(updateOrgTasksColumns(cols))}
      activeTab="single"
      exportHandler={dispatchTasksCsvByOrgId}
      exportFileName={prependDateTimeString('capella-organization-task-requests-download.csv')}
    />
  );
};

export default OrganizationTasksPage;
