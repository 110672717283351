import React from 'react';
import PropTypes from 'prop-types';
import { IoClose } from 'react-icons/io5';

import {
  useCart,
  useVerifyCart
} from 'hooks';
import TemplateCart from '../templates/TemplateCart';
/**
 * Standalone, floating version of the /cart/view page
 * @param {object} props
 * @param {function} [props.handleCloseCart] - triggers cart close in parent
 * @param {boolean} [props.showCart=false] - if true, show the cart!
 */
const FloatingCart = ({ handleCloseCart, showCart = false }) => {
  const { count = 0 } = useCart();

  const cartObject = useVerifyCart();

  return (
    <div className={`template-cart floating-cart${!showCart ? ' hide' : ''}`}>
      <h3 className="floating-cart-header">
        Your Cart
        <span className="cart-item-count" aria-labelledby={`Item Count: ${count}`}>
          ({ count })
        </span>
        <button
          onClick={handleCloseCart}
          className="close-cart"
          data-testid='close-cart-button'
          aria-labelledby='Close Cart'
        ><IoClose />
        </button>
      </h3>
      {showCart && (
        <TemplateCart
          floatingCart={true}
          hideFloatingCart={handleCloseCart}
          cartObject={cartObject}
        />
      )}
    </div>
  );
};

FloatingCart.propTypes = {
  handleCloseCart: PropTypes.func,
  showCart: PropTypes.bool
};

export default FloatingCart;
