// Boulder Colorado -- default center of map if no aois to display
export const DEFAULT_MAP_CENTER = {
  lng: 0,
  lat: 0
};

export const DEFAULT_AOI_VALUES = {
  type: 'Feature',
  geometry: {},
  id: undefined,
  properties: {
    name: undefined,
    description: undefined,
    active: true,
    excludeTaskedByOwnOrganization: true,
    excludeOlderThanEnabled: false,
    excludeOlderThanSetting: '',
    excludeOlderThanCustomDate: undefined
  }
};

export const CREATE_AOI_EXCLUDE_OLDER_THAN_VALUES = [
  {
    value: '',
    label: 'All imagery'
  },
  {
    value: '30_days',
    label: 'Within the last 30 days'
  },
  {
    value: '1_year',
    label: 'Within the last year'
  }
];

export const AOI_SHAPE_RANGE = {
  min: {
    meters: 5000,
    km: 5,
    friendlyLabel: '5km²'
  },
  max: {
    meters: 5000000000,
    km: 5000000,
    friendlyLabel: '5M sq km'
  }
};

export const GEO_SUBTYPES = {
  CIRCLE: 'Circle',
  RECTANGLE: 'Rectangle'
};
