import {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_PROFILE,
  ROUTE_ADMIN,
  ROUTE_ADMIN_COLLECTIONS,
  ROUTE_ADMIN_COLLECTIONS_DETAILS,
  ROUTE_ADMIN_ORGS,
  ROUTE_ADMIN_ORGS_CONTRACT_ADD,
  ROUTE_ADMIN_ORGS_CONTRACT_EDIT,
  ROUTE_ADMIN_ORGS_CONTRACT_PERIOD_ADD,
  ROUTE_ADMIN_ORGS_CONTRACT_PERIOD_EDIT,
  ROUTE_ADMIN_ORGS_BUCKET_CONFIG_ADD,
  ROUTE_ADMIN_ORGS_BUCKET_CONFIG_EDIT,
  ROUTE_ADMIN_ORGS_CREATE,
  ROUTE_ADMIN_ORGS_DETAILS,
  ROUTE_ADMIN_ORGS_EDIT,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_USERS_CREATE,
  ROUTE_ADMIN_USERS_DETAILS,
  ROUTE_AOIS,
  ROUTE_AOI_DETAILS,
  ROUTE_AOI_CREATE,
  ROUTE_API_AOIS,
  ROUTE_API_AOIS_SEARCH,
  ROUTE_API_AOI,
  ROUTE_API_ACCESS_REQUEST_BY_ID,
  ROUTE_API_ACCESSES_BY_REQUEST_ID,
  ROUTE_API_ACCESS_REQUESTS,
  ROUTE_CHMOAPI_ACTION,
  ROUTE_API_COLLECTION_PERMISSIONS,
  ROUTE_API_COLLECTIONS,
  ROUTE_API_STAC_DOWNLOAD,
  ROUTE_API_ORDERS,
  ROUTE_API_ORDERS_CREATE_TASK,
  ROUTE_API_REORDER,
  ROUTE_API_ORG,
  ROUTE_API_ORGS,
  ROUTE_API_ORGS_PAGED,
  ROUTE_API_ORGS_ORG,
  ROUTE_API_ORGS_ORG_CONTRACTS,
  ROUTE_API_ORGS_COLLECTIONS,
  ROUTE_API_ORG_CONTRACTS,
  ROUTE_API_ORG_CONTRACT_PERIOD,
  ROUTE_API_ORG_CONTRACT_PERIODS,
  ROUTE_API_ORG_CONTRACT_DATASHARE,
  ROUTE_API_ORG_USERS,
  ROUTE_API_ORG_S3_PUSH_TEST,
  ROUTE_API_ORG_S3_BUCKET_POST_GET,
  ROUTE_API_ORG_S3_BUCKET_PUT_DEL,
  ROUTE_API_ORDER_ASSETS_S3,
  ROUTE_API_PASSWORDS,
  ROUTE_API_USERS_RESEND_PW,
  ROUTE_API_USERS_COLLECTIONS,
  ROUTE_API_SEARCH,
  ROUTE_API_TASK,
  ROUTE_API_TASKS,
  ROUTE_API_TASKS_PAGED,
  ROUTE_API_TASK_COLLECTS,
  ROUTE_API_TASK_FROM_COLLECT,
  ROUTE_API_TASK_TILES,
  ROUTE_API_TASKS_SEARCH,
  ROUTE_API_TASKS_STATUS,
  ROUTE_API_TASKS_TASK,
  ROUTE_API_TASK_OOSM,
  ROUTE_API_REPEAT_REQUEST,
  ROUTE_API_REPEAT_REQUEST_STATUS,
  ROUTE_API_REPEAT_REQUESTS_SEARCH,
  ROUTE_API_RETASK_TASK,
  ROUTE_API_RETASK_REPEAT_REQUEST,
  ROUTE_API_RETASK_COLLECT,
  ROUTE_API_TOKEN,
  ROUTE_API_USER,
  ROUTE_API_USER_REG,
  ROUTE_API_USERS,
  ROUTE_API_USERS_PAGED,
  ROUTE_API_CONTRACTS,
  ROUTE_CART,
  ROUTE_CART_VIEW,
  ROUTE_CART_REVIEW,
  ROUTE_ERROR_NOT_FOUND,
  ROUTE_ERROR_SERVER_ERROR,
  ROUTE_HOME,
  ROUTE_ORDERS,
  ROUTE_ORDERS_DETAILS,
  ROUTE_ORG,
  ROUTE_ORG_EDIT,
  ROUTE_ORG_DETAILS,
  ROUTE_ORG_DETAILS_CONTRACT,
  ROUTE_ORG_ORDERS,
  ROUTE_ORG_ORDERS_DETAILS,
  ROUTE_ORDER_CONTRACT_DETAILS,
  ROUTE_ORG_PROFILE,
  ROUTE_ORG_REPEAT_REQUESTS,
  ROUTE_ORG_REPEAT_REQUESTS_DETAILS,
  ROUTE_ORG_TASKS,
  ROUTE_ORG_TASKS_DETAILS,
  ROUTE_ORG_TASKS_CONTRACT_DETAILS,
  ROUTE_ORG_USERS,
  ROUTE_ORG_USERS_CREATE,
  ROUTE_ORG_USERS_DETAILS,
  ROUTE_ORG_CONTRACTS,
  ROUTE_ORG_CONTRACT_DETAILS,
  ROUTE_ORG_BUCKET_CONFIG_ADD,
  ROUTE_ORG_BUCKET_CONFIG_EDIT,
  ROUTE_SEARCH,
  ROUTE_SEARCH_DETAILS,
  ROUTE_TASKS,
  ROUTE_TASKS_CREATE,
  ROUTE_TASKS_DETAILS,
  ROUTE_TASK_EDIT,
  ROUTE_REPEAT_REQUESTS,
  ROUTE_TASKS_RELATED_DETAILS,
  ROUTE_REPEAT_REQUEST_DETAILS,
  ROUTE_REPEAT_REQUEST_EDIT,
  ROUTE_USER_AUTHORIZE,
  ROUTE_USER_CHANGE_PW,
  ROUTE_USER_LOGIN,
  ROUTE_USER_LOG_OUT,
  ROUTE_USER_LOG_OUT_EXPIRED,
  ROUTE_USER_NEW_PW,
  ROUTE_USER_PREMIUM_SIGNUP,
  ROUTE_USER_PREMIUM_SIGNUP_THX,
  ROUTE_USER_PW_RESET,
  ROUTE_USER_REGISTER,
  ROUTE_USER_REGISTER_THX,
  ROUTE_USER_REGISTER_EXTERNAL,
  ROUTE_TASKS_REVIEW,
  ROUTE_REPEAT_REQUEST_CHILD_TASK,
  ROUTE_SHARED_PLATFORM,
  ROUTE_SHARED_LOGIN,
  ROUTE_SHARED_LOGOUT,
  ROUTE_ADMIN_ORGS_CONTRACT_DETAILS,
  ROUTE_API_ACCESS_REQUEST_TILES,
  ROUTE_API_PUBLIC_COLLECTIONS,
  ROUTE_API_LICENSES,
  ROUTE_API_COLLECTION_TYPES
} from './route-names';
import resellerRoutes from './routes-reseller';
import {
  ADMIN_ROLE,
  ORG_MANAGER_ROLE,
  UNAUTH_ROLE,
  USER_ROLE,
  ARCHIVE_VIEWER,
  WILDCARD_ROLE
} from './roles';
import subResellerRoutes from './routes-subreseller';

/**
 * Note: All paths must end with a /
 */

const routes = [];

routes.push({
  name: ROUTE_HOME,
  path: '/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_SEARCH,
  path: '/search/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_SEARCH_DETAILS,
  path: '/search/details/*/*/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

/**
 * Account Routes
 */

routes.push({
  name: ROUTE_ACCOUNT,
  path: '/account/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_ACCOUNT_PROFILE,
  path: '/account/profile/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

/**
 * AOIs
 */

routes.push({
  name: ROUTE_AOIS,
  path: '/aois/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_AOI_DETAILS,
  path: '/aois/details/*/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_AOI_CREATE,
  path: '/aois/create/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

/**
 * User Routes
 */

routes.push({
  name: ROUTE_USER_LOGIN,
  path: '/user/login/',
  access: [UNAUTH_ROLE]
});

routes.push({
  name: ROUTE_USER_LOG_OUT,
  path: '/user/logout/',
  access: [WILDCARD_ROLE]
});

routes.push({
  name: ROUTE_USER_LOG_OUT_EXPIRED,
  path: '/user/logout/?sessionExpired=true',
  access: [WILDCARD_ROLE]
});

routes.push({
  name: ROUTE_USER_PW_RESET,
  path: '/user/password-reset/',
  access: [WILDCARD_ROLE]
});

routes.push({
  name: ROUTE_USER_CHANGE_PW,
  path: '/user/change-password/',
  access: [WILDCARD_ROLE]
});

routes.push({
  name: ROUTE_USER_NEW_PW,
  path: '/user/new-password/',
  access: [WILDCARD_ROLE]
});

routes.push({
  name: ROUTE_USER_PREMIUM_SIGNUP,
  path: '/user/premium/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_USER_PREMIUM_SIGNUP_THX,
  path: '/user/premium/thank-you/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_USER_REGISTER,
  path: '/user/register/',
  access: [WILDCARD_ROLE]
});

routes.push({
  name: ROUTE_USER_REGISTER_THX,
  path: '/user/register/thank-you/',
  access: [UNAUTH_ROLE]
});

routes.push({
  name: ROUTE_USER_REGISTER_EXTERNAL,
  path: 'https://www.capellaspace.com/community/',
  access: [UNAUTH_ROLE]
});

routes.push({
  name: ROUTE_USER_AUTHORIZE,
  path: '/user/authorize/',
  access: [WILDCARD_ROLE]
});

/**
 * Tasks Routes
 */

routes.push({
  name: ROUTE_TASKS,
  path: '/tasks/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_TASKS_DETAILS,
  path: '/tasks/details/*/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_TASKS_RELATED_DETAILS,
  path: '/tasks/conflict/*/*',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_TASKS_CREATE,
  path: '/tasks/create/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_TASKS_REVIEW,
  path: '/tasks/review/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_TASK_EDIT,
  path: '/tasks/details/*/edit/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

/**
 * Repeat Requests Routes
 */

routes.push({
  name: ROUTE_REPEAT_REQUESTS,
  path: '/tasks/repeat-requests/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_REPEAT_REQUEST_DETAILS,
  path: '/tasks/repeat-request/details/*/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_REPEAT_REQUEST_CHILD_TASK,
  path: '/tasks/repeat-request/*/task/*/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_REPEAT_REQUEST_EDIT,
  path: '/tasks/repeat-request/details/*/edit/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

/**
 * Orders Routes
 */

routes.push({
  name: ROUTE_ORDERS,
  path: '/orders/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_ORDERS_DETAILS,
  path: '/orders/details/*/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

/**
 * Cart Routes
 */

routes.push({
  name: ROUTE_CART,
  path: '/cart/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_CART_VIEW,
  path: '/cart/view/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_CART_REVIEW,
  path: '/cart/review/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

/**
 * Organization Routes
 */

routes.push({
  name: ROUTE_ORG,
  path: '/organization/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_PROFILE,
  path: '/organization/profile/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_USERS,
  path: '/organization/users/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_USERS_DETAILS,
  path: '/organization/users/details/*/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_USERS_CREATE,
  path: '/organization/users/create/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_ORDERS,
  path: '/organization/orders/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_ORDERS_DETAILS,
  path: '/organization/orders/details/*/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORDER_CONTRACT_DETAILS,
  path: '/organization/contracts/order/*/*/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORDER_CONTRACT_DETAILS,
  path: '/organization/contracts/order/*/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_REPEAT_REQUESTS,
  path: '/organization/tasks/repeat-requests/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_REPEAT_REQUESTS_DETAILS,
  path: '/organization/tasks/repeat-request/details/*/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_TASKS,
  path: '/organization/tasks/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_TASKS_DETAILS,
  path: '/organization/tasks/details/*/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_TASKS_CONTRACT_DETAILS,
  path: '/organization/contracts/task/*/*/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_TASKS_CONTRACT_DETAILS,
  path: '/organization/contracts/task/*/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_BUCKET_CONFIG_ADD,
  path: '/organization/bucket-config/add/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_BUCKET_CONFIG_EDIT,
  path: '/organization/bucket-config/*/edit/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_DETAILS,
  path: '/organization/details/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_DETAILS_CONTRACT,
  path: '/organization/details/contracts/*/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_EDIT,
  path: '/organization/details/edit/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_CONTRACTS,
  path: '/organization/contracts/',
  access: [ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_ORG_CONTRACT_DETAILS,
  path: '/organization/contracts/*/',
  access: [ORG_MANAGER_ROLE]
});

/**
 * Admin Routes
 */

routes.push({
  name: ROUTE_ADMIN,
  path: '/admin/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_USERS,
  path: '/admin/users/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_USERS_DETAILS,
  path: '/admin/users/details/*/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_USERS_CREATE,
  path: '/admin/users/create/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS,
  path: '/admin/organizations/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_DETAILS,
  path: '/admin/organizations/details/*/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_EDIT,
  path: '/admin/organizations/details/*/edit/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_CREATE,
  path: '/admin/organizations/create/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_CONTRACT_ADD,
  path: '/admin/organizations/*/contract/add/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_CONTRACT_EDIT,
  path: '/admin/organizations/*/contract/*/edit/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_CONTRACT_PERIOD_ADD,
  path: '/admin/organizations/*/contract/*/contract-period/add/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_CONTRACT_PERIOD_EDIT,
  path: '/admin/organizations/*/contract-period/*/edit/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_BUCKET_CONFIG_ADD,
  path: '/admin/organizations/*/bucket-config/add/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_BUCKET_CONFIG_EDIT,
  path: '/admin/organizations/*/bucket-config/*/edit/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_COLLECTIONS,
  path: '/admin/collections/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_COLLECTIONS_DETAILS,
  path: '/admin/collections/details/*/',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_ADMIN_ORGS_CONTRACT_DETAILS,
  path: '/admin/organizations/*/contract/details/*/',
  access: [ADMIN_ROLE]
});

/**
 * Error Routes
 */

routes.push({
  name: ROUTE_ERROR_NOT_FOUND,
  path: '/400/',
  access: [WILDCARD_ROLE]
});

routes.push({
  name: ROUTE_ERROR_SERVER_ERROR,
  path: '/500/',
  access: [WILDCARD_ROLE]
});

/**
 * Shared Platform Routes
 */
/* Use localhost path for local testing of Platform + Analytics */
const platformBaseUrl = `https://${process.env.PLATFORM}`;
// const platformBaseUrl = 'http://localhost:8000';

routes.push({
  name: ROUTE_SHARED_PLATFORM,
  path: platformBaseUrl,
  access: [UNAUTH_ROLE]
});

routes.push({
  name: ROUTE_SHARED_LOGOUT,
  path: `${platformBaseUrl}/user/logout/`,
  access: [UNAUTH_ROLE]
});

routes.push({
  name: ROUTE_SHARED_LOGIN,
  path: `${platformBaseUrl}/user/login/`,
  access: [UNAUTH_ROLE]
});

/**
 * API Routes
 */

// Aois

routes.push({
  name: ROUTE_API_AOIS,
  path: '/aois'
});

routes.push({
  name: ROUTE_API_AOI,
  path: '/aois/*/'
});

routes.push({
  name: ROUTE_API_AOIS_SEARCH,
  path: '/aois/search'
});

// Search & Discovery

routes.push({
  name: ROUTE_API_SEARCH,
  path: '/catalog/search'
});

routes.push({
  name: ROUTE_API_COLLECTIONS,
  path: '/catalog/collections'
});

routes.push({
  name: ROUTE_API_STAC_DOWNLOAD,
  path: '/catalog/collections/*/items/*/download'
});

routes.push({
  name: ROUTE_API_PUBLIC_COLLECTIONS,
  path: '/collections'
});

// Orders

routes.push({
  name: ROUTE_API_ORDERS,
  path: '/orders'
});

routes.push({
  name: ROUTE_API_ORDERS_CREATE_TASK,
  path: '/orders/task/*/'
});

routes.push({
  name: ROUTE_API_REORDER,
  path: '/orders/*/reorder'
});

// Tasks

routes.push({
  name: ROUTE_API_TASK,
  path: '/task'
});

routes.push({
  name: ROUTE_API_TASKS,
  path: '/tasks'
});

routes.push({
  name: ROUTE_API_TASKS_PAGED,
  path: '/tasks/paged'
});

routes.push({
  name: ROUTE_API_TASKS_SEARCH,
  path: '/tasks/search'
});

routes.push({
  name: ROUTE_API_TASKS_TASK,
  path: '/task/*/'
});

routes.push({
  name: ROUTE_API_TASKS_STATUS,
  path: '/task/*/status'
});

routes.push({
  name: ROUTE_API_TASK_COLLECTS,
  path: '/collects/list/*'
});

routes.push({
  name: ROUTE_API_TASK_FROM_COLLECT,
  path: '/collects/*/task'
});

routes.push({
  name: ROUTE_API_TASK_TILES,
  path: '/tiles/list/*'
});

routes.push({
  name: ROUTE_API_ACCESS_REQUEST_TILES,
  path: '/tiles/list/accessrequest/*'
});

routes.push({
  name: ROUTE_API_TASK_OOSM,
  path: '/oosmtasks'
});

// Repeat requests
routes.push({
  name: ROUTE_API_REPEAT_REQUEST,
  path: '/repeat-requests'
});

routes.push({
  name: ROUTE_API_REPEAT_REQUEST_STATUS,
  path: '/repeat-requests/*/status'
});

// Retask
routes.push({
  name: ROUTE_API_RETASK_TASK,
  path: '/task/*/retask'
});

routes.push({
  name: ROUTE_API_RETASK_REPEAT_REQUEST,
  path: '/repeat-requests/*/retask'
});

routes.push({
  name: ROUTE_API_RETASK_COLLECT,
  path: '/collects/*/retask'
});

routes.push({
  name: ROUTE_API_REPEAT_REQUESTS_SEARCH,
  path: '/repeat-requests/search'
});

// Tasking Mission Awareness

routes.push({
  name: ROUTE_API_ACCESS_REQUESTS,
  path: '/ma/accessrequests/'
});

routes.push({
  name: ROUTE_API_ACCESS_REQUEST_BY_ID,
  path: '/ma/accessrequests/*/'
});

routes.push({
  name: ROUTE_API_ACCESSES_BY_REQUEST_ID,
  path: '/ma/accesses/*/'
});

// User

routes.push({
  name: ROUTE_API_USER,
  path: '/user'
});

routes.push({
  name: ROUTE_API_USERS,
  path: '/users'
});

routes.push({
  name: ROUTE_API_USERS_PAGED,
  path: '/users/paged'
});

routes.push({
  name: ROUTE_API_USER_REG,
  path: '/register'
});

routes.push({
  name: ROUTE_API_TOKEN,
  path: '/token'
});

routes.push({
  name: ROUTE_API_PASSWORDS,
  path: '/passwords'
});

routes.push({
  name: ROUTE_API_USERS_RESEND_PW,
  path: '/users/*/resend/',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

routes.push({
  name: ROUTE_API_COLLECTION_TYPES,
  path: '/collectiontypes',
  access: [USER_ROLE]
});

// Admin

routes.push({
  name: ROUTE_API_ORG,
  path: '/organization'
});

routes.push({
  name: ROUTE_API_ORGS,
  path: '/organizations',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_API_ORGS_PAGED,
  path: '/organizations/paged',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_API_ORGS_ORG,
  path: '/organizations/*/?eagerLedgerIncludes=true',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_API_ORGS_COLLECTIONS,
  path: '/organizations/*/permitted-collections',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_API_USERS_COLLECTIONS,
  path: '/organizations/*/permitted-collections'
});

routes.push({
  name: ROUTE_API_ORGS_ORG_CONTRACTS,
  path: '/organizations/*/contracts',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_API_LICENSES,
  path: '/licenses',
  access: [ADMIN_ROLE]
});

/**
 * SALESFORCE CONTRACTS ROUTES
 */

routes.push({
  name: ROUTE_API_CONTRACTS,
  path: '/ledger/contracts'
});

/**
 * BEGIN LEGACY CONTRACTS ROUTES
 */

routes.push({
  name: ROUTE_API_ORG_CONTRACTS,
  path: '/organizations/*/contracts/*',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_API_ORG_CONTRACT_PERIODS,
  path: '/organizations/*/contracts/*/periods',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_API_ORG_CONTRACT_PERIOD,
  path: '/organizations/*/contract/periods/*',
  access: [ADMIN_ROLE]
});

routes.push({
  name: ROUTE_API_ORG_CONTRACT_DATASHARE,
  path: '/organizations/*/contract/*/datashare/*',
  access: [ADMIN_ROLE]
});

/**
 * END LEGACY CONTRACTS ROUTES
 */

// Org Manager

routes.push({
  name: ROUTE_API_ORG_USERS,
  path: '/organizations/*/users',
  access: [ADMIN_ROLE, ORG_MANAGER_ROLE]
});

// S3

routes.push({
  name: ROUTE_API_ORG_S3_PUSH_TEST,
  path: '/organizations/*/s3pushtest',
  access: [ADMIN_ROLE, ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_API_ORG_S3_BUCKET_POST_GET,
  path: '/organizations/*/s3bucketconfigs',
  access: [ADMIN_ROLE, ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_API_ORG_S3_BUCKET_PUT_DEL,
  path: '/organizations/*/s3bucketconfigs/*',
  access: [ADMIN_ROLE, ORG_MANAGER_ROLE]
});

routes.push({
  name: ROUTE_API_ORDER_ASSETS_S3,
  path: '/orders/*/assets/*/*/s3push',
  access: [USER_ROLE, ARCHIVE_VIEWER]
});

// Collections

routes.push({
  name: ROUTE_API_COLLECTION_PERMISSIONS,
  path: '/collection-permissions',
  access: [ADMIN_ROLE]
});

// CHMO-SPECIFIC API REQUEST ROUTES
routes.push({
  name: ROUTE_CHMOAPI_ACTION,
  path: `${process.env.ANALYTICS_ENDPOINT}/action`,
  access: [ADMIN_ROLE]
});

export default routes.concat(resellerRoutes, subResellerRoutes);
