import clone from 'clone';
import deepmerge from 'deepmerge';

const defaultState = {
  items: [],
  collections: [],
  stacs: [],
  active: {},
  actions: {}
};

const items = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);
  const { items, stacs } = workingState;

  switch (type) {
    case 'ADD_ITEM': {
      const itemExists = items.findIndex((i) => i.id === workingData.id) !== -1;
      const updatedItems = itemExists ? items : [...items, ...workingData];

      return {
        ...state,
        items: updatedItems
      };
    }
    case 'UPDATE_ACTIVE_ITEM': {
      return {
        ...workingState,
        active: workingData
      };
    }
    case 'CLEAR_ACTIVE_ITEM': {
      return {
        ...workingState,
        active: {}
      };
    }
    case 'UPDATE_ITEMS_ACTION_STATE': {
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    }
    case 'ADD_COLLECTIONS': {
      return {
        ...state,
        collections: data
      };
    }
    case 'ADD_STAC': {
      const stacExists = stacs.findIndex((i) => i.id === workingData.id) !== -1;
      const updatedStacs = stacExists ? stacs : [...stacs, ...workingData];

      return {
        ...state,
        stacs: updatedStacs
      };
    }
    default: {
      return workingState;
    }
  }
};

export default items;
