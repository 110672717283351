export { default as useABComponent } from './useABComponent';
export { default as useAccessRequests } from './useAccessRequests';
export { default as useActiveItem } from './useActiveItem';
export { default as useAoi } from './useAoi';
export { default as useAois } from './useAois';
export { default as useAoiMapLayers } from './useAoiMapLayers.js';
export { default as useAreaOfInterest } from './useAreaOfInterest';
export { default as useBootstrap } from './useBootstrap';
export { default as useCancellablePromise } from './useCancellablePromise';
export { default as useCart } from './useCart';
export { default as useVerifyCart } from './useVerifyCart';
export { default as useClearNewEntries } from './useClearNewEntries';
export { default as useCreateNewEntry } from './useCreateNewEntry';
export { default as useCognitoToken } from './useCognitoToken';
export { default as useEditableHandler } from './useEditableHandler';
export { default as useEventListener } from './useEventListener';
export { default as useFetchAoi } from './useFetchAoi';
export { default as useFetchAois } from './useFetchAois';
export { default as useFetchOrder } from './useFetchOrder';
export { default as useFetchOrderAssets } from './useFetchOrderAssets';
export { default as useFetchOrders } from './useFetchOrders';
export { default as useFetchRepeatRequest } from './useFetchRepeatRequest';
export { default as useFetchRepeatRequestChildTasks } from './useFetchRepeatRequestChildTasks';
export { default as useFetchRepeatRequests } from './useFetchRepeatRequests';
export { default as useFetchStac } from './useFetchStac';
export { default as useFetchTask } from './useFetchTask';
export { default as useFetchTaskCollects } from './useFetchTaskCollects';
export { default as useFetchTaskTiles } from './useFetchTaskTiles';
export { default as useFetchTasks } from './useFetchTasks';
export { default as useFetchUser } from './useFetchUser';
export { default as useItemProperties } from './useItemProperties';
export { default as useLastSearch } from './useLastSearch';
export { default as useMapLayers } from './useMapLayers';
export { default as useLogOut } from './useLogOut';
export { default as useOrder } from './useOrder';
export { default as useOrders } from './useOrders';
export { default as useOrgTask } from './useOrgTask';
export { default as useOrgTasks } from './useOrgTasks';
export { default as useOrgOrders } from './useOrgOrders';
export { default as useOrgFetchOrganization } from './useOrgFetchOrganization';
export { default as useOrgFetchTasks } from './useOrgFetchTasks';
export { default as useOrgFetchOrders } from './useOrgFetchOrders';
export { default as useOrgFetchRepeatRequests } from './useOrgFetchRepeatRequests';
export { default as useOrgRepeatRequests } from './useOrgRepeatRequests';
export { default as usePageReqs } from './usePageReqs';
export { default as useRepeatRequest } from './useRepeatRequest';
export { default as useRepeatRequestChildTasks } from './useRepeatRequestChildTasks';
export { default as useRepeatRequests } from './useRepeatRequests';
export { default as useResellerAllUsers } from './useResellerAllUsers';
export { default as useResellerTasks } from './useResellerTasks';
export { default as useResellerFetchOrders } from './useResellerFetchOrders';
export { default as useResellerUser } from './useResellerUser';
export { default as useResellerUsers } from './useResellerUsers';
export { default as useResellerFetchTasks } from './useResellerFetchTasks';
export { default as useResellerRepeatRequests } from './useResellerRepeatRequests';
export { default as useResellerFetchRepeatRequests } from './useResellerFetchRepeatRequests';
export { default as useSearch } from './useSearch';
export { default as useSearchSync } from './useSearchSync';
export { default as useSiteMetadata } from './useSiteMetadata';
export { default as useSubReseller } from './useSubReseller';
export { default as useSubResellerAllUsers } from './useSubResellerAllUsers';
export { default as useSubResellerTask } from './useSubResellerTask';
export { default as useSubResellerTasks } from './useSubResellerTasks';
export { default as useSubResellerRepeatRequests } from './useSubResellerRepeatRequests';
export { default as useSubResellerUser } from './useSubResellerUser';
export { default as useSubResellerUsers } from './useSubResellerUsers';
export { default as useSubResellerFetchOrganization } from './useSubResellerFetchOrganization';
export { default as useSubResellerFetchOrders } from './useSubResellerFetchOrders';
export { default as useSubResellerFetchTask } from './useSubResellerFetchTask';
export { default as useSubResellerFetchTaskCollects } from './useSubResellerFetchTaskCollects';
export { default as useSubResellerFetchTaskTiles } from './useSubResellerFetchTaskTiles';
export { default as useSubResellerFetchTasks } from './useSubResellerFetchTasks';
export { default as useSubResellerFetchRepeatRequests } from './useSubResellerFetchRepeatRequests';
export { default as useSubResellerFetchUsers } from './useSubResellerFetchUsers.js';
export { default as useTask } from './useTask';
export { default as useTaskCreate } from './useTaskCreate';
export { useTaskContext, TaskContextProvider } from './useTaskContext';
export { default as useTasks } from './useTasks';
export { default as useUi } from './useUi';
export { default as useContracts } from './useContracts';
export { default as useFetchContracts } from './useFetchContracts';
export { default as useConflictedTask } from './useConflictedTask';
export { default as useFetchCollectionTypes } from './useFetchCollectionTypes';
export { default as useCollectionTypes } from './useCollectionTypes';

/**
 * From common-web-lib
 */

export { default as useResellerFetchOrganization } from 'commonLib/src/hooks/useResellerFetchOrganization';
export { default as useAdminFetchOrganizations } from 'commonLib/src/hooks/useAdminFetchOrganizations';
export { default as useAdminFetchUsers } from 'commonLib/src/hooks/useAdminFetchUsers';
export { default as useReseller } from 'commonLib/src/hooks/useReseller';
export { default as useResellerFetchUsers } from 'commonLib/src/hooks/useResellerFetchUsers';

/**
 * TODO use common-web-lib
 * The hooks below already exist in common-web-lib, and should be referenced from there instead of here.
 * It doesn't have to be all at once, just when we make changes to one we can also take the time to update the reference.
 * This way we can remove duplicate code every chance we get.
 */

/** @deprecated use common-web-lib reference instead */
export { default as useAdmin } from './useAdmin';

/** @deprecated use common-web-lib reference instead */
export { default as useAdminFetchOrganization } from './useAdminFetchOrganization';

/** @deprecated use common-web-lib reference instead */
export { default as useAdminFetchOrganizationsContract } from './useAdminFetchOrganizationsContract';

/** @deprecated use common-web-lib reference instead */
export { default as useAdminFetchUser } from './useAdminFetchUser';

/** @deprecated use common-web-lib reference instead */
export { default as useAdminOrganization } from './useAdminOrganization';

/** @deprecated use common-web-lib reference instead */
export { default as useAdminOrganizations } from './useAdminOrganizations';

/** @deprecated use common-web-lib reference instead */
export { default as useFetchOrganizationCollections } from './useFetchOrganizationCollections';

/** @deprecated use common-web-lib reference instead */
export { default as useFetchCollection } from './useFetchCollection';

/** @deprecated use common-web-lib reference instead */
export { default as useFetchCollections } from './useFetchCollections';

/** @deprecated use common-web-lib reference instead */
export { default as useFetchItem } from './useFetchItem';

/** @deprecated use common-web-lib reference instead */
export { default as useUser } from './useUser';

/** @deprecated use common-web-lib reference instead */
export { default as useItem } from './useItem';

/** @deprecated use common-web-lib reference instead */
export { default as useItems } from './useItems';

/** @deprecated use common-web-lib reference instead */
export { default as useLocation } from './useLocation';

/** @deprecated use common-web-lib reference instead */
export { default as useOrg } from './useOrg';

/** @deprecated use common-web-lib reference instead */
export { default as useOrgFetchUser } from './useOrgFetchUser';

/** @deprecated use common-web-lib reference instead */
export { default as useOrgFetchUsers } from './useOrgFetchUsers';

/** @deprecated use common-web-lib reference instead */
export { default as useOrgFetchS3Bucket } from './useOrgFetchS3Bucket';
