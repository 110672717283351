import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { Button } from 'fogg/ui';
import { FaUser, FaPowerOff, FaLock } from 'react-icons/fa';

import { routePathByName } from 'lib/routes';
import { logError } from 'lib/logger';
import { useUser, useSiteMetadata } from 'hooks';
import { triggerNotice } from 'state/actions';

import Layout from 'components/Layout';
import {
  updateUser,
  PageHeader,
  Breadcrumbs,
  Container,
  LinkList,
  UserProfile
} from 'commonLib';

const AccountProfilePage = () => {
  const dispatch = useDispatch();

  const { user = {}, actions = {} } = useUser();
  const { fetchUser = {} } = actions;
  const { isLoading } = fetchUser;

  const { links } = useSiteMetadata();

  const additionalLinks = [
    {
      label: 'Data Licensing',
      to: links.dataLicensing,
      properties: {
        target: '_blank'
      }
    }
  ];

  function handleUpdateUser (userData) {
    dispatch(updateUser(userData))
      .then(() => {
        dispatch(
          triggerNotice({
            type: 'success',
            align: 'center',
            text: 'Successfully updated profile.'
          })
        );
      })
      .catch((error) => {
        logError(error.message, userData);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      });
  }

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-account page-account-profile' }}>
        <title>User Profile and Account Information</title>
      </Helmet>

      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Account'
            }
          ]}
        />
        <PageHeader title="My Profile" icon={<FaUser className="icon-fa" />} />
        <div className="profile">
          <div className="profile-content">
            <div className="profile-section">
              <UserProfile user={user} updateUser={handleUpdateUser} />
            </div>

            <div className="profile-section">
              <ul className="profile-links">
                <li id="change-password">
                  <Button
                    type={['text', 'icon-before']}
                    to={routePathByName('userPasswordReset')}
                  >
                    <FaLock /> Change Password
                  </Button>
                </li>
                <li id="log-out">
                  <Button
                    type={['text', 'icon-before']}
                    to={routePathByName('userLogout')}
                    state={{ manualLogout: true }}
                    className="logout-button"
                    disabled={isLoading}
                  >
                    <FaPowerOff /> Log Out
                  </Button>
                </li>
              </ul>
            </div>

            <div className="profile-section">
              <LinkList links={additionalLinks} />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default AccountProfilePage;
