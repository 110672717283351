import React from 'react';
import PropTypes from 'prop-types';

import { useResellerFetchOrganization } from 'hooks';
import { routePathByName } from 'lib/routes';
import { SUB_RESELLER_ROLE } from 'data/roles';
import {
  ROUTE_SUB_RESELLER,
  ROUTE_SUB_RESELLER_CONTRACT_DETAILS
} from 'data/route-names';

import { TemplateOrganizationDetails } from 'commonLib';

const SubResellerDetailsPage = ({ organizationId }) => {
  const {
    isLoading,
    organization = {},
    parentOrganization = {},
    isLoading: isLoadingParent
  } = useResellerFetchOrganization({
    id: organizationId
  });

  if (parentOrganization.name) {
    organization.resellerOrg = parentOrganization;
  }

  const breadcrumbs = [
    {
      label: 'Sub-Reseller',
      to: routePathByName(ROUTE_SUB_RESELLER)
    }
  ];

  const organizationState = {
    isLoading: isLoading || isLoadingParent
  };

  return (
    <TemplateOrganizationDetails
      organization={organization}
      accessType={SUB_RESELLER_ROLE}
      breadcrumbs={breadcrumbs}
      state={organizationState}
      detailView={true}
      contractDetailsRouteName={ROUTE_SUB_RESELLER_CONTRACT_DETAILS}
      contractDetailsRouteOptions={[organizationId]}
    />
  );
};

SubResellerDetailsPage.propTypes = {
  organizationId: PropTypes.string.isRequired
};

export default SubResellerDetailsPage;
