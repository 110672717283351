import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useRepeatRequestChildTasks } from 'hooks';

import { logError } from 'lib/logger';
import { fetchChildTasksByRepeatRequestId, triggerNotice } from 'state/actions';

export default function useFetchRepeatRequestChildTasks ({
  repeatRequestId,
  forceNew = false
}) {
  const dispatch = useDispatch();
  const { repeatRequestChildTasks, actions = {} } =
    useRepeatRequestChildTasks();

  useEffect(() => {
    if (!repeatRequestId) return;
    dispatch(fetchChildTasksByRepeatRequestId(repeatRequestId)).catch(
      (error) => {
        logError(error.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      }
    );
  }, [dispatch, forceNew, repeatRequestId]);

  return {
    ...actions.fetchChildTasksByRepeatRequestId,
    repeatRequestChildTasks
  };
}
