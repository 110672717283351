import { useMappedState } from 'redux-react-hook';

export default function useOrders () {
  const mapState = ({ orders }) => orders;

  const ordersState = useMappedState(mapState);
  const { orders } = ordersState;

  return {
    ...ordersState,
    orders: [...orders]
  };
}
