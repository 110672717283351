import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useModal } from 'fogg/hooks';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { routePathByName, navigateTo } from 'lib/routes';
import { fillEmptyTask } from 'lib/data';
import {
  getAnalyticLabel,
  isPointGeometryType,
  relatedTasksToTableData
} from 'lib/tasks';
import { formatDateTime } from 'lib/datetime';
import {
  TASK_COLLECT_STATUSES,
  TASK_STATUS_RECEIVED,
  TASK_STATUS_REVIEW
} from 'data/tasks';
import { TaskCollectionTiers } from 'data/task-collection-tiers';
import {
  ROUTE_REPEAT_REQUEST_DETAILS,
  ROUTE_TASKS,
  ROUTE_TASKS_DETAILS,
  ROUTE_TASKS_RELATED_DETAILS,
  ROUTE_TASK_EDIT
} from 'data/route-names';
import { TASKING_REQUEST_ID } from 'data/property-names';
import { TaskRequestTypes } from '../data/task-request-types';
import { userHasTaskingAccess } from 'commonLib/src/lib/role-util';
import { useAreaOfInterest, useTaskCreate, useTaskContext, useUser } from 'hooks';

import Task from 'models/task';

import { Helmet } from 'react-helmet';
import { FaTools, FaExclamationTriangle, FaEyeSlash } from 'react-icons/fa';
import { Notice, Badge, WonderLink } from 'fogg/ui';
import { Button } from '@trussworks/react-uswds';
import { IconBadge, redirectTo } from 'commonLib';
import Layout from 'components/Layout';
import Breadcrumbs from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import TaskStatus from 'components/TaskStatus';
import TaskCostAction from 'components/TaskCostAction';
import TaskConfigTable from 'components/TaskConfigTable';
import TaskCollectsTable from 'components/TaskCollectsTable';
import MapPreview from 'components/MapPreview';
import AccessRequests from 'components/AccessRequests';
import CancelTaskModal from 'components/CancelTaskModal';
import ACDIcon from 'commonLib/src/assets/icons/ACD-Icon.svg';
import VesselIcon from 'commonLib/src/assets/icons/Vessel-Icon.svg';
import Tooltip from '../components/Tooltip';

const DEFAULT_BREADCRUMBS = [
  {
    label: 'Tasks',
    to: routePathByName(ROUTE_TASKS)
  }
];

const TemplateTask = ({
  task = {},
  conflictingTasks = [],
  contract = {},
  displayMultiContract = false,
  breadcrumbs = DEFAULT_BREADCRUMBS,
  state = {},
  conflictId,
  orgIsArchSubOnly
}) => {
  const [activeTileId, setActiveTileId] = useState();
  const [fetchedConflictingTasks, setFetchedConflictingTasks] = useState([]);

  const flags = useFlags();
  const { user: { roles = {}, organizationId } = {} } = useUser();
  const isTaskingAllowed = userHasTaskingAccess(roles, flags);
  const collectionTypesEnabled = flags.collectionTypeTaskingFormEnabled?.organizations?.includes(organizationId) || false;

  const mapRef = useRef();
  const accessesFetched = useRef(false);
  const editModalRef = useRef();

  const { id: taskId } = task;
  const { isLoading = false, isConflictingTasksLoading = false } = state;
  const containerType = [];

  let activeTask = task;

  if (isLoading) {
    activeTask = fillEmptyTask();
    containerType.push('is-loading');
  }

  const {
    name,
    description,
    isSandbox,
    repeatRequestId,
    analytics = []
  } = activeTask;
  const geoJson = activeTask.geoJson && activeTask.geoJson();
  const collectsWithTiles = activeTask.collectsWithTiles;

  const { position: mapPosition } = useAreaOfInterest({ geoJson });
  const { path: createTaskPath } = useTaskCreate({ geoJson });
  const { updateTaskState } = useTaskContext() || {};

  // If Analytic included (like Vessel Detection or Bitemp), display that
  const analytic = analytics?.length ? analytics[0] : undefined;
  const taskAnalytic = getAnalyticLabel(analytic);

  // add repeat request details to bookmarks if the task has a repeatRequestId
  let taskBreadcrumbs = breadcrumbs;
  if (repeatRequestId && taskBreadcrumbs) {
    taskBreadcrumbs = [
      ...breadcrumbs,
      {
        label: 'Repeat Request',
        to: routePathByName(ROUTE_REPEAT_REQUEST_DETAILS, {
          wildcard: [repeatRequestId]
        })
      }
    ];
  }

  // Logic to determine if "Cancel Task" button should be displayed
  const { _data: { properties = {} } = {} } = activeTask;
  const {
    statusHistory: taskStatusHistory,
    customAttribute1,
    customAttribute2
  } = properties;
  let canCancel = false;
  const taskStatus =
    taskStatusHistory && taskStatusHistory.length
      ? taskStatusHistory[0].code
      : null;

  if (taskStatus) {
    // First check for task statuses that can't be cancelled
    if (
      taskStatus === 'rejected' ||
      taskStatus === 'canceled' ||
      taskStatus === 'cancelled' ||
      taskStatus === 'error' ||
      taskStatus === 'anomaly' ||
      taskStatus === 'collection_anomaly' ||
      taskStatus === 'processing_anomaly' ||
      taskStatus === 'completed'
    ) {
      canCancel = false;
    } else {
      // If task status is one that can be cancelled, we still need to
      // verify the windowOpen date is at least 3 hours from now
      const { collects = [] } = properties;
      if (collects.length) {
        const dates = collects.map((collect) => {
          return moment(collect.windowOpen);
        });
        const moreThanThreeHours = (date) => {
          return moment(date).isAfter(moment().add(3, 'hours'));
        };
        if (moreThanThreeHours(moment.min(dates))) {
          canCancel = true;
        }
      }
      // ...or if no collects yet, it's probably in review and should be able to be cancelled
      if (!collects.length) {
        canCancel = true;
      }
    }
  }

  // Hide cost panel when task status is 'cancelled', 'error', or 'rejected' & no order cost estimate
  const { order: { summary: { total: orderTotal } = {} } = {} } = activeTask;
  const showMessagingPanel =
    !(taskStatus && taskStatus === 'canceled' && !orderTotal) &&
    !(taskStatus && taskStatus === 'error' && !orderTotal) &&
    !(taskStatus && taskStatus === 'rejected' && !orderTotal);

  // When the cancel button exists in the messagingPanel (RepeatTaskCostAction), we hide the cancel button in the header
  const canCancelInHeader =
    ![TASK_STATUS_REVIEW, TASK_STATUS_RECEIVED].includes(taskStatus) &&
    showMessagingPanel;

  const initialModalState = {
    modals: {
      cancel: {
        isOpen: false
      }
    }
  };

  const modalName = 'cancel';
  const modal = useModal(initialModalState);
  const { ModalContextProvider, handleModalOpen } = modal;

  function openModal (e) {
    handleModalOpen(e, 'cancel');
  }

  geoJson &&
    geoJson.features.forEach((feature) => {
      feature.properties.featureType = 'aoi';
    });

  let collectFeatures = collectsWithTiles || [];

  // We need both the collect itself and the tile for each of our collects to
  // show them as features on the map

  collectFeatures = collectFeatures.filter(
    ({ collect, tile }) => tile && collect
  );

  // Loop through all of our existing features and try to apply the collect's status
  // to the tile

  collectFeatures = collectFeatures.map(({ collect = {}, tile = {} }) => {
    return {
      ...tile,
      properties: {
        ...tile.properties,
        collectStatus: collect.lastStatus.code
      }
    };
  });

  // We want to take our collects and the associated features and create geojson features
  // that we can add to our MapPreview component. this includes the original AOI and
  // each tile/collect. Once we have that list, we additionally check if it has a collect
  // status, which means its a collect, but also helps us determine the color we want
  // to show the feature on the map as

  if (geoJson && geoJson.features && collectFeatures.length > 0) {
    // Combine the original AOI and the collects

    geoJson.features = [...geoJson.features, ...collectFeatures];

    // Loop through all of our features and configure the style based on status

    geoJson.features = geoJson.features.map((feature, index) => {
      const { properties = {} } = feature;
      const { tileId } = properties;

      const statusConfig = TASK_COLLECT_STATUSES.find(
        ({ id }) => id === properties.collectStatus
      );

      const shapeOptions = {
        style: {
          fill: false
        }
      };

      // If we can find a status config, try to use the color for the shape options of the feature
      // If we dont provide one, it defaults to the component's default color

      if (statusConfig && statusConfig.color) {
        shapeOptions.style.color = statusConfig.color;
        shapeOptions.style.fill = tileId === activeTileId;
        shapeOptions.style.fillOpacity = 0.4;
      }

      return {
        ...feature,
        properties: {
          ...properties,
          shapeOptions,
          onMouseover: () => setActiveTileId(tileId),
          onMouseout: () => setActiveTileId()
        }
      };
    });
  }

  /**
   * handleOnChangeActiveCollectRow
   */

  function handleOnChangeActiveCollectRow ({ collectId } = {}) {
    if (!Array.isArray(collectsWithTiles)) return;

    if (!collectId) {
      setActiveTileId();
      return;
    }

    const active = collectsWithTiles.find(
      ({ collect }) => collect.collectId === collectId
    );
    const tileId = active && active.tile && active.tile.id;

    setActiveTileId(tileId);
  }

  // Access Requests stuff
  const isPoint = isPointGeometryType({ geoJson });
  const mapZoom = isPoint ? 13 : 5;

  const handleMapEffect = ({ leafletElement: map } = {}) => {
    mapRef.current = map;
  };

  const [accessRequestsProps, setAccessRequestsProps] = useState();

  useEffect(() => {
    // Don't pass props to AccessRequests until we've fetched all the necessary ones...
    if (
      activeTask?.windowOpen &&
      geoJson?.features?.length &&
      !accessesFetched.current
    ) {
      setAccessRequestsProps({
        taskRequestType: activeTask.taskRequestType,
        geoJson,
        windowOpen: activeTask.windowOpen,
        windowClose: activeTask.windowClose,
        tier: activeTask.collectionTier,
        accessRequestOffNadirMin: activeTask.collectConstraints?.offNadirMin,
        accessRequestOffNadirMax: activeTask.collectConstraints?.offNadirMax,
        accessRequestAscending: activeTask.collectConstraints?.ascDsc,
        accessRequestLookDirection:
          activeTask.collectConstraints?.lookDirection,
        accessRequestOrbitalPlanes:
          activeTask.collectConstraints?.orbitalPlanes,
        isPoint,
        imageLength: activeTask.collectConstraints?.imageLength,
        imageWidth: activeTask.collectConstraints?.imageWidth,
        refMap: mapRef,
        localTime: activeTask.collectConstraints?.localTime
      });
      // Avoid calling /accessrequests more than once
      accessesFetched.current = true;
    }
  }, [activeTask, geoJson, isPoint]);

  useEffect(() => {
    const emptyTask = fillEmptyTask();

    function conflictTableData (conflicts) {
      return relatedTasksToTableData(conflicts, {
        detailsRouteName: ROUTE_TASKS_RELATED_DETAILS,
        routeWildcards: ['conflictId', 'id']
      });
    }

    // only run this on a task that has been loaded
    if (activeTask && activeTask.id !== emptyTask.id && !isLoading) {
      const { conflictingTasks: activeTaskConflictingTasks = [] } = activeTask;

      if (activeTaskConflictingTasks && activeTaskConflictingTasks.length) {
        if (isConflictingTasksLoading) {
          // Set the state with existing conflicting tasks data from original task request
          const conflictsForFetching = activeTaskConflictingTasks.map(
            (conflict) => {
              const conflictAsTask = new Task().ingestTaskBody({
                properties: { ...conflict, conflictId: activeTask.id }
              });
              conflictAsTask.setCustomProperty('fetching', true);
              return conflictAsTask;
            }
          );

          setFetchedConflictingTasks(conflictTableData(conflictsForFetching));
        } else {
          const conflictsFetched = activeTaskConflictingTasks.map(
            (conflict) => {
              // Use task returned from the search api
              const matchingConflict = conflictingTasks.find(
                (foundTask) =>
                  foundTask.properties[TASKING_REQUEST_ID] ===
                  conflict[TASKING_REQUEST_ID]
              );
              if (matchingConflict) {
                const conflictAsTask = new Task().ingestTaskBody(
                  matchingConflict
                );
                conflictAsTask.setCustomProperty('access', true);
                conflictAsTask.conflictId = activeTask.id;
                return conflictAsTask;
              }

              // Use conflicting task from original task request
              return new Task().ingestTaskBody({
                properties: { ...conflict, conflictId: activeTask.id }
              });
            }
          );

          // Overwrite any matching base tasks with what was fetched
          setFetchedConflictingTasks(conflictTableData(conflictsFetched));
        }
      }
    }
  }, [
    activeTask,
    isLoading,
    conflictingTasks,
    isConflictingTasksLoading,
    conflictId
  ]);

  const hasActiveCollects = activeTask.collects?.length;

  // Only show access requests times when...
  const showAccessRequests =
    isPoint &&
    taskStatus &&
    taskStatus !== 'completed' &&
    taskStatus !== 'canceled' &&
    taskStatus !== 'error' &&
    taskStatus !== 'rejected' &&
    !hasActiveCollects &&
    !!accessRequestsProps;

  const showConflictingTasks = activeTask?.conflictingTasks?.length > 0;
  const statusConflictsClassName = showConflictingTasks ? 'slim-bottom' : '';

  // retask
  function prepRetaskFormDataAndRedirect () {
    const open = new Date();
    const timeDeltaInt = activeTask.windowCloseInt - activeTask.windowOpenInt;

    const retaskCollectConstraints = { ...activeTask.collectConstraints };

    // target height from geometry
    const coordinates = activeTask.geometry.coordinates;
    if (coordinates.length === 3) {
      retaskCollectConstraints.targetHeight = coordinates[2];
    }

    updateTaskState({
      name: `${activeTask.name} Retask`,
      windowOpenInt: open.getTime(),
      windowCloseInt: open.getTime() + timeDeltaInt,
      collectionTier: activeTask.collectionTier,
      collectConstraints: retaskCollectConstraints,
      preApproval: activeTask.preApproval,
      productCategory: collectionTypesEnabled ? undefined : (activeTask.taskingrequestType || 'custom'),
      collectionType: collectionTypesEnabled ? activeTask.collectionType : undefined,
      collectMode: activeTask.collectMode,
      // Note: those should be in collectConstraints but changing this in the respective
      // forms had unintended side effects
      ascDsc: activeTask.ascDsc,
      lookDirection: activeTask.lookDirection,
      orbitalPlanes: activeTask.orbitalPlanes,
      taskRequestType: TaskRequestTypes.STANDARD,
      isRetask: true,
      fromArchive: false,
      origTaskingId: activeTask.id,
      archiveHoldback: activeTask.archiveHoldback
    });
    redirectTo(createTaskPath);
  }

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'template-task' }}>
        <title>Task</title>
      </Helmet>
      <Container className="content" type={containerType}>
        <Breadcrumbs items={taskBreadcrumbs} />

        <PageHeader
          title={name}
          subtitle={`ID: ${taskId}`}
          icon={
            taskAnalytic ? (
              <IconBadge
                className={`${analytic}`}
                icon={
                  analytic === 'VS' || analytic === 'VC' ? (
                    <img src={VesselIcon} alt="Vessel Detection Icon" />
                  ) : (
                    <img src={ACDIcon} alt="ACD Icon" />
                  )
                }
                label={`${taskAnalytic} Included`}
                key={2}
                hiddenLabel={true}
                isLoading={isLoading}
              />
            ) : undefined
          }
        >
          {isSandbox && (
            <Notice className="task-environment" type="warning" weight="bold">
              <FaTools /> Sandbox
            </Notice>
          )}
          <div className="button-group">
            <Button
              modalRef={editModalRef}
              opener
              type="button"
              className="action-edit-task"
              disabled={isLoading}
              base={true}
              onClick={() =>
                navigateTo(
                  routePathByName(ROUTE_TASK_EDIT, {
                    wildcard: [taskId]
                  })
                )
              }
            >
              Edit
            </Button>
            {isPoint && !orgIsArchSubOnly && isTaskingAllowed && (
              <Button
                type="button"
                onClick={prepRetaskFormDataAndRedirect}
                className="action-retask"
                disabled={isLoading}
                base={true}
              >
                Retask
              </Button>
            )}
            {canCancel && canCancelInHeader && (
              <>
                <Button
                  type="button"
                  onClick={openModal}
                  className="action-cancel danger"
                  base={true}
                >
                  Cancel Task
                </Button>
                <ModalContextProvider>
                  <CancelTaskModal
                    name={modalName}
                    modal={modal}
                    task={activeTask}
                  />
                </ModalContextProvider>
              </>
            )}
          </div>
        </PageHeader>

        <div className="task-details">
          {conflictId && (
            <div
              className="task-details-card"
              data-testid="task-details-conflicting"
            >
              <div className="task-details-conflicting">
                <FaExclamationTriangle color="orange" />
                <span>
                  This task conflicts with task ID{' '}
                  <WonderLink
                    to={routePathByName(ROUTE_TASKS_DETAILS, {
                      wildcard: [conflictId]
                    })}
                  >
                    {conflictId}
                  </WonderLink>
                  .
                </span>
              </div>
            </div>
          )}

          <div className={`task-details-card ${statusConflictsClassName}`}>
            <TaskStatus task={activeTask} />
          </div>

          {showConflictingTasks && (
            <div
              className="task-details-card"
              data-testid="conflict-tasks-card"
            >
              <table className="conflict-tasks-table">
                <thead>
                  <tr>
                    <th>Conflicting Task</th>
                    <th>Window open</th>
                    <th>Window close</th>
                    <th>Status</th>
                    <th>Tier</th>
                    <th>Task type</th>
                  </tr>
                </thead>
                <tbody>
                  {fetchedConflictingTasks.map(
                    (
                      {
                        access,
                        isFetching,
                        href,
                        nameText,
                        idText,
                        windowOpen,
                        windowClose,
                        status,
                        collectionTier,
                        repeatRequestId
                      },
                      conflictIndex
                    ) => {
                      const uniqueId = `${idText}-${conflictIndex}`;
                      const tooltipId = `${uniqueId}-tooltip`;
                      return (
                        <tr
                          key={uniqueId}
                          className={`${!access ? 'no-access' : ''} ${
                            isFetching ? 'is-fetching' : ''
                          }`}
                          data-testid="conflict-tasks-table-row"
                        >
                          <td>
                            <div data-tip data-for={tooltipId}>
                              <div className="conflict-name-link">
                                {access === true && (
                                  <WonderLink to={href}>{nameText}</WonderLink>
                                )}
                                {!access && (
                                  <Tooltip
                                    id={tooltipId}
                                    multiline={true}
                                    className="conflicting-task-tooltip"
                                    disabled={isFetching}
                                    tooltipText={
                                      <>
                                        <b>Why is this task disabled?</b>
                                        <br />
                                        It is owned by another user, you do not
                                        have permission to view it.
                                      </>
                                    }
                                  >
                                    <span className="name-text">
                                      {nameText}
                                    </span>
                                  </Tooltip>
                                )}
                                <span>
                                  <Badge
                                    className="conflict-badge"
                                    label="conflicting"
                                  />
                                </span>
                              </div>
                              <small>ID: {idText}</small>
                            </div>
                          </td>
                          <td>
                            <span>{formatDateTime(windowOpen)}</span>
                          </td>
                          <td>
                            <span>{formatDateTime(windowClose)}</span>
                          </td>
                          <td>
                            {access ? (
                              <Badge
                                label={status}
                                className={`status-badge ${status?.toLowerCase?.()}-status-badge`}
                              />
                            ) : (
                              <Tooltip
                                className="conflicting-hidden-status"
                                id="conflictingHiddenStatus"
                                icon={<FaEyeSlash />}
                                multiline={true}
                                tooltipText="Status is hidden because the task is owned by another user."
                              />
                            )}
                          </td>
                          <td>
                            <b>
                              {
                                TaskCollectionTiers.properties[collectionTier]
                                  ?.label
                              }
                            </b>
                          </td>
                          <td>
                            <Badge
                              label={
                                repeatRequestId ? 'repeat task' : 'single task'
                              }
                              type={repeatRequestId ? 'info' : 'default'}
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          )}

          <div className={'task-details-card'}>
            <TaskCostAction
              task={activeTask}
              showContractOnly={!showMessagingPanel}
              contractLabel={displayMultiContract ? contract?.label || '' : ''}
            />
          </div>

          <div className="task-details-info">
            <div className="task-details-map">
              {geoJson ? (
                <MapPreview
                  zoom={mapZoom}
                  center={mapPosition}
                  geoJson={geoJson}
                  fitGeoJson={!isPoint}
                  useMapEffect={handleMapEffect}
                  displayAccessRequests={showAccessRequests}
                >
                  {showAccessRequests && (
                    <AccessRequests {...accessRequestsProps} key={taskId} />
                  )}
                </MapPreview>
              ) : (
                'Loading'
              )}
            </div>

            <div className="task-details-meta">
              {(description || customAttribute1 || customAttribute2) && (
                <div className="task-details-description">
                  {description && (
                    <div className="description-row">
                      <h3>Description</h3>
                      <p>{description}</p>
                    </div>
                  )}
                  {customAttribute1 && (
                    <div className="description-row">
                      <h3>Custom Attribute 1</h3>
                      <p>{customAttribute1}</p>
                    </div>
                  )}
                  {customAttribute2 && (
                    <div className="description-row">
                      <h3>Custom Attribute 2</h3>
                      <p>{customAttribute2}</p>
                    </div>
                  )}
                </div>
              )}
              {hasActiveCollects ? (
                <div
                  className="task-details-collects"
                  data-testid="task-details-collects"
                >
                  <TaskCollectsTable
                    task={activeTask}
                    collects={activeTask.collects}
                    isLoading={isLoading}
                    onChangeActiveCollect={handleOnChangeActiveCollectRow}
                    activeTileId={activeTileId}
                  />
                </div>
              ) : null}

              <div className="task-configuration">
                <h3>Configuration</h3>

                <TaskConfigTable
                  className="flat-top"
                  task={activeTask}
                  showExtraFields={false}
                  showCollectionTier={true}
                  collectionTypesEnabled={collectionTypesEnabled}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

TemplateTask.propTypes = {
  task: PropTypes.object,
  conflictingTasks: PropTypes.array,
  contract: PropTypes.object,
  displayMultiContract: PropTypes.bool,
  state: PropTypes.object,
  breadcrumbs: PropTypes.array,
  conflictId: PropTypes.string,
  orgIsArchSubOnly: PropTypes.bool
};

export default TemplateTask;
