import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { Helmet } from 'react-helmet';
import { MdPlaylistAdd } from 'react-icons/md';
import { Badge, Button, Notice } from 'fogg/ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import {
  useTask,
  usePageReqs,
  useAreaOfInterest,
  useUser,
  useTaskContext
} from 'hooks';
import { postTaskRequest, postRetaskRequest, clearNewTask, triggerNotice } from 'state/actions';
import { routePathByName, promiseToNavigateTo } from 'lib/routes';
import { logError } from 'lib/logger';
import { isEmptyObject } from 'lib/util';
import {
  AVAILABLE_COLLECTION_TIERS,
  AVAILABLE_REPEAT_TASK_COLLECTION_TIERS,
  getAnalyticLabel,
  isPointGeometryType,
  THIRTY_DAYS_MS
} from 'lib/tasks';

import { OptionsPanel, PageHeader, Container, IconBadge, CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from 'commonLib';
import Layout from 'components/Layout';
import TaskConfigTable from 'components/TaskConfigTable';
import RepeatTaskConfigTable from 'components/RepeatTaskConfigTable';
import TaskStatus from 'components/TaskStatus';
import RepeatTaskStatus from 'components/RepeatTaskStatus';
import ContractsSelect from 'components/ContractsSelect';
import AccessRequests from 'components/AccessRequests';
import MapPreview from 'components/MapPreview';

import { NEW_TASK } from 'data/action-types';
import { TASKING_REQUEST_ID, REPEAT_REQUEST_ID } from 'data/property-names';
import {
  ROUTE_TASKS_DETAILS,
  ROUTE_REPEAT_REQUEST_DETAILS
} from 'data/route-names';
import { TaskRequestTypes } from 'data/task-request-types';
import ACDIcon from 'commonLib/src/assets/icons/ACD-Icon.svg';
import VesselIcon from 'commonLib/src/assets/icons/Vessel-Icon.svg';

const TasksReviewPage = () => {
  const dispatch = useDispatch();

  const task = useTask(NEW_TASK) || {};
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [proceedError, setProceedError] = useState(false);
  const mapRef = useRef();

  const { reqsMet } = usePageReqs({
    condition: !isEmptyObject(task),
    error: {
      text: 'Please create a task to review',
      to: routePathByName('search'),
      message: 'No task to review'
    }
  });

  const {
    name,
    description,
    geometry = {},
    collectionTier,
    collectionType,
    taskRequestType = TaskRequestTypes.STANDARD,
    taskRequestTypeLabel,
    analytics,
    windowOpenInt,
    windowCloseInt,
    orbitalPlanes,
    lookDirection,
    collectMode,
    ascDsc,
    productCategory,
    collectConstraints: {
      offNadirMax,
      offNadirMin,
      localTime
    } = {},
    repetitionProperties: {
      repeatStart,
      repeatEnd
    } = {},
    properties: {
      customAttribute1,
      customAttribute2
    } = {}
  } = task;

  /**
   * Grabbing imageLength & imageWidth from TaskContext because we only
   * store that in NEW_TASK (and pass to /task) if explicitly set
   * For /accessrequests footprints we need the exact values though
   *
   * Additionally, we need to know if this is a retask to dispatch accordingly.
   */
  const { taskState } = useTaskContext();
  const {
    collectConstraints: {
      imageLength,
      imageWidth
    } = {},
    isRetask,
    fromArchive,
    origTaskingId
  } = taskState;

  // combine all available tasking tiers between standard and repeat tasking requests
  const taskingTier = AVAILABLE_COLLECTION_TIERS.concat(AVAILABLE_REPEAT_TASK_COLLECTION_TIERS).find(
    ({ value }) => value === collectionTier
  );
  const taskingTierLabel = taskingTier && taskingTier.label;

  const { position: mapPosition, geoJson: mapGeoJson } = useAreaOfInterest({
    geometry
  });

  const activeAnalytic = analytics && analytics.length ? analytics[0] : undefined;

  const { user = {} } = useUser();
  const { apiEnvironmentRole, organizationId } = user;
  const isSandbox = apiEnvironmentRole === 'sandbox';

  const flags = useFlags();
  const collectionTypesEnabled = flags.collectionTypeTaskingFormEnabled?.organizations?.includes(organizationId) || false;

  const allowedTaskingContracts = user.organization.contracts
    .filter(({ type }) => type !== CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION)
    .map(({ id, label, isDefault, exclusivityHolds }) => ({
      value: id,
      label,
      isDefault,
      exclusivityHolds
    }));

  const [selectedContractId, setSelectedContract] = useState(
    allowedTaskingContracts.find(contract => contract.isDefault)?.value
  );

  useEffect(() => {
    if (!selectedContractId) setSelectedContract(allowedTaskingContracts.find(contract => contract.isDefault)?.value);
  }, [allowedTaskingContracts]);

  if (!reqsMet) return null;

  function handleCreateClick () {
    if (!selectedContractId) {
      setProceedError(true);
      return;
    }

    setProceedError(false);
    setIsSubmitted(true);
    const targetHeight = task._data.properties.collectConstraints.targetHeight;
    delete task._data.properties.collectConstraints.targetHeight;

    if (task._data.geometry.coordinates[0].constructor === Array) {
      task._data.geometry.coordinates[0].map(function (e) {
        if (!isNaN(targetHeight) && !e.includes(targetHeight)) {
          e.push(targetHeight);
        }
      });
    } else if (
      !isNaN(targetHeight) &&
      !task._data.geometry.coordinates.includes(targetHeight)
    ) {
      task._data.geometry.coordinates.push(targetHeight);
    }

    task._data.properties.archiveHoldback = getTaskArchiveHoldbackValue(allowedTaskingContracts, selectedContractId, task._data.properties.archiveHoldback);

    const dispatchAction = isRetask ? postRetaskRequest : postTaskRequest;
    dispatch(dispatchAction({
      ...task._data,
      contractId: selectedContractId,
      fromArchive,
      origTaskingId
    }))
      .then((data = {}) => {
        const { properties = {} } = data;
        let entityIdPropertyName = TASKING_REQUEST_ID;
        let entityRoutePathName = ROUTE_TASKS_DETAILS;

        if (taskRequestType === TaskRequestTypes.REPEAT) {
          entityIdPropertyName = REPEAT_REQUEST_ID;
          entityRoutePathName = ROUTE_REPEAT_REQUEST_DETAILS;
        }

        const requestId = properties[entityIdPropertyName];

        if (!requestId) {
          throw new Error('Unable to find ID for request');
        }

        const taskRoute = routePathByName(entityRoutePathName, {
          wildcard: [requestId]
        });

        promiseToNavigateTo(taskRoute)
          .then(() => {
            dispatch(clearNewTask());
          })
          .catch((error) => {
            logError(error.message, {
              route: taskRoute
            });
          });
        setIsSubmitted(false);
        return data;
      })
      .catch((error) => {
        logError(error.message, task);
        setIsSubmitted(false);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Please try again!'
          })
        );
      });
  }

  function getTaskArchiveHoldbackValue (allContracts, currentSelectedContractId, currentArchiveHoldbackValue) {
    if (currentArchiveHoldbackValue) return currentArchiveHoldbackValue;

    const currentContract = allContracts.find(contract => contract.value === currentSelectedContractId);
    return currentContract?.exclusivityHolds?.tasking_request;
  }

  /**
   * updates task store with selected contract information
   */
  function handleContractChange (contractId) {
    setProceedError(false);
    setSelectedContract(contractId);
  }

  if (!reqsMet) return null;

  let TaskStatusDetail = TaskStatus;
  let TaskConfigDetail = TaskConfigTable;
  let taskStatusDetailProps = {
    task,
    windowOnly: true
  };

  if (taskRequestType === TaskRequestTypes.REPEAT) {
    TaskStatusDetail = RepeatTaskStatus;
    TaskConfigDetail = RepeatTaskConfigTable;
    taskStatusDetailProps = {
      repeatRequest: task,
      windowOnly: true
    };
  }

  const isPoint = isPointGeometryType({ geoJson: mapGeoJson }) || (geometry?.type && geometry.type === 'Point');

  // Remove auto-fit for points
  const mapFitGeoJson = !isPoint;
  const mapZoom = isPoint ? 13 : 5;

  const handleMapEffect = ({ leafletElement: map } = {}) => {
    mapRef.current = map;
  };

  const windowOpen = repeatStart || windowOpenInt;
  const windowClose = taskRequestType === 'repeat'
    ? repeatEnd || new Date(new Date(repeatStart).getTime() + THIRTY_DAYS_MS).getTime()
    : windowCloseInt;

  const accessRequestsProps = {
    taskRequestType,
    geoJson: mapGeoJson,
    windowOpen: windowOpen,
    windowClose: windowClose,
    tier: collectionTier,
    accessRequestOffNadirMin: offNadirMin,
    accessRequestOffNadirMax: offNadirMax,
    accessRequestAscending: ascDsc,
    accessRequestLookDirection: lookDirection,
    accessRequestOrbitalPlanes: orbitalPlanes,
    isPoint,
    imageLength,
    imageWidth,
    imagingMode: collectMode,
    productCategory,
    refMap: mapRef,
    localTime
  };

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-tasks page-tasks-review' }}>
        <title>Tasks Review</title>
      </Helmet>
      <div className="tasks-create">
        <Container className="content tasks-create-form">
          {taskRequestType === TaskRequestTypes.REPEAT && (
            <Badge icon={<MdPlaylistAdd className="icon-md" />} label={taskRequestTypeLabel} className="repeat-task-badge" type="info" />
          )}
          <PageHeader
            title="Review New Task"
            icon={<MdPlaylistAdd className="icon-md" />}
          />
          <h2>{name}</h2>
          <p>{description}</p>

          <div className="tasks-create-window">
            <TaskStatusDetail {...taskStatusDetailProps} />
            <div className="task-status">
              <div className="task-status-window">
                <h4>{`${collectionTypesEnabled ? 'Collection' : 'Tasking'} Tier`}</h4>
                <p>{taskingTierLabel}</p>
              </div>
              <div className="task-status-window" style={{ textTransform: 'capitalize' }}>
                <h4>Collection Time</h4>
                <p>{localTime}</p>
              </div>
            </div>
            {collectionTypesEnabled && (
              <div className="task-status">
                <div className="task-status-window" style={{ textTransform: 'capitalize' }}>
                  <h4>Collection Type</h4>
                  <p>{collectionType}</p>
                </div>
              </div>
            )}
            {activeAnalytic && (
              <div className="task-status-analytic">
                <p>
                  <IconBadge className={`${activeAnalytic}`} icon={activeAnalytic === 'VS' || activeAnalytic === 'VC' ? <img src={VesselIcon} alt="Vessel Detection Icon" /> : <img src={ACDIcon} alt="ACD Icon" />}
                    label={`${getAnalyticLabel(activeAnalytic)} Included`}
                  /></p>
              </div>
            )}
            <p className="caption">
              Dates and times are processed as UTC upon submission
            </p>
          </div>

          {(customAttribute1 || customAttribute2) && (
            <div className="tasks-create-window task-custom-attributes">
              {customAttribute1 && (
                <div className="task-attribute">
                  <h4>Custom Attribute 1</h4>
                  <p>{customAttribute1}</p>
                </div>
              )}
              {customAttribute2 && (
                <div className="task-attribute">
                  <h4>Custom Attribute 2</h4>
                  <p>{customAttribute2}</p>
                </div>
              )}
            </div>
          )}

          <div className="tasks-create-configuration">
            <OptionsPanel
              label="View Full Configuration"
              id="tasks-create-configuration-panel"
            >
              <TaskConfigDetail task={task} collectionTypesEnabled={collectionTypesEnabled} />
            </OptionsPanel>
          </div>

          <ContractsSelect
            wrapperClassName="contracts-task-create"
            contracts={allowedTaskingContracts}
            onChange={handleContractChange}
            disable = {allowedTaskingContracts.length < 2}
            isValid={!proceedError}
            selectedValue={selectedContractId}
            setSelectedValue={setSelectedContract}
          />

          {isSandbox && (
            <Notice type="warning" weight="bold">
              Your account is currently configured for simulated tasking only.
            </Notice>
          )}

          <p>
            <Button onClick={handleCreateClick} disabled={isSubmitted}>
              Submit New Task
            </Button>
            <Button type="text" to={routePathByName('tasksCreate')}>
              Modify
            </Button>
          </p>
        </Container>

        <Container className="tasks-create-preview">
          {mapPosition && (
            <>
              <MapPreview
                zoom={mapZoom}
                center={mapPosition}
                geoJson={mapGeoJson}
                fitGeoJson={mapFitGeoJson}
                useMapEffect={handleMapEffect}
                displayAccessRequests={true}
              >
                <AccessRequests
                  {...accessRequestsProps}
                  key={taskRequestType}
                />
              </MapPreview>
            </>
          )}
        </Container>
      </div>
    </Layout>
  );
};

export default TasksReviewPage;
