import { regexByFieldName } from 'lib/input';

const fields = [];

fields.push({
  name: 'email',
  label: 'Email',
  regex: regexByFieldName('email') // note: check fogg to see how to do this
});

fields.push({
  name: 'confirmEmail',
  label: 'Confirm Email',
  regex: regexByFieldName('email') // note: check fogg to see how to do this
});

fields.push({
  name: 'password',
  label: 'Password',
  minLength: 8,
  validationMessage: 'Password must be a minimum of 8 characters'
});

fields.push({
  name: 'passwordConfirmation',
  label: 'Confirm Password',
  validationMessage: 'Passwords do not match'
});

fields.push({
  name: 'familyName',
  label: 'First Name'
});

fields.push({
  name: 'givenName',
  label: 'Last Name'
});

fields.push({
  name: 'windowOpen',
  label: 'Window Open'
});

fields.push({
  name: 'windowClose',
  label: 'Window Close'
});

fields.push({
  name: 'analyticWindow',
  label: 'Window',
  validationMessage: 'Must be greater than or equal Spacing'
});

fields.push({
  name: 'customAttribute1',
  label: 'Custom Attribute 1',
  validationMessage: '2 character minimum for custom attribute'
});

fields.push({
  name: 'customAttribute2',
  label: 'Custom Attribute 2',
  validationMessage: '2 character minimum for custom attribute'
});

export default fields;

// Generates dropdown options array of 1 - 12 months & "None" which = 0
const twelveMonthOptions = [...Array(12)].map((_, i) => {
  return { value: i + 1, label: `${i + 1} months` };
});
twelveMonthOptions.unshift({ value: 0, label: 'None' });
export const defaultMonthOptions = twelveMonthOptions;
