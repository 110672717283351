import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { Lens, Button } from 'fogg/ui';
import { useModal } from 'fogg/hooks';
import heatMapLogo from '../assets/images/heat-map.png';
import L from 'leaflet';
import 'leaflet.heat';
import ReactTooltip from 'react-tooltip';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import Layout from 'components/Layout';
import SearchSidebar from 'components/SearchSidebar';
import SearchModalUpload from 'components/SearchModalUpload';
import FloatingCart from 'components/FloatingCart';
import { LoadingSpinner } from 'commonLib';
import ClassName from 'models/classname';
import MapboxRequest from 'models/mapbox-request';
import { coordinateFeature, mapGeocodeCandidates, resolveItemIdSearch, resolveCollectionSearch } from 'lib/search';
import { routePathByName, normalizePathname, navigateTo } from 'lib/routes';
import { initDefaultTaskValues, isPointGeometryType } from 'lib/tasks';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';
import { logError } from 'lib/logger';
import { isUuid, isSTACID } from 'lib/util';
import { updateQuerySearchParams } from 'lib/location';
import {
  useSearch,
  useUser,
  useCart,
  useLocation,
  useMapLayers,
  useTaskContext
} from 'hooks';
import { useTokenCookie } from 'commonLib/src/hooks';
import {
  triggerNotice,
  _setSearchActionIsLoading,
  _setSearchActionIsLoaded,
  _setSearchActionIsError,
  pushSearchHistory,
  fetchUserOrganizationCollections,
  resolveLensSearch
} from 'state/actions';

import { searchFilters } from 'data/search';
import { mapServices } from 'data/map-services';

import { FaShoppingCart, FaCheck, FaSpinner } from 'react-icons/fa';

const DEFAULT_CENTER = {
  lat: 0,
  lng: 0
};

if (typeof window !== 'undefined') {
  const L = window.L;
  L.Map.addInitHook(function () {
    const map = this;
    window.MAP_GLOBAL = map;
  });
}

const SearchPage = () => {
  const dispatch = useDispatch();

  const className = new ClassName('search');

  const {
    activeState: { resetSearch } = {},
    pathname,
    queryParams: {
      showCart: showCartParam = false
    } = {}
  } = useLocation();

  const detailsPath = routePathByName('searchDetails');
  const onDetailsPath =
    pathname && normalizePathname(pathname).includes(detailsPath);

  const { user = {}, pageSession = {} } = useUser();
  const { actions = {}, searchCollections } = useSearch();

  const { count: itemCount } = useCart();
  const { refresh } = useTokenCookie();
  const { layers: activeLayers } = useMapLayers();

  const { resolveLensSearch: resolveLensSearchAction = {} } = actions;
  const { isLoading } = resolveLensSearchAction;
  const hasItems = itemCount > 0;
  const { resetTaskState, setIsPoint, IS_POINT_DEFAULT } =
    useTaskContext() || {};

  const [searchOption, setSearchOption] = useState('default');
  const [searchArgs, setSearchArgs] = useState({
    geoJson: {},
    filters: undefined,
    date: {}
  });
  const [heatMapOption, setHeatMapOption] = useState(false);
  const [isAutoCompleteLoading, setAutoCompleteLoading] = useState(false);
  const [loadingHeatMap, setLoadingHeatMap] = useState(false);
  const [showCart, setShowCart] = useState(String(showCartParam).toLowerCase() === 'true');

  const heatmapRef = useRef(null);
  const mapRef = useRef();
  const scaleControlRef = useRef();

  // Get the collections available to the user's org
  useEffect(() => {
    if (!user.organization?.id) return;
    dispatch(fetchUserOrganizationCollections(user.organization.id)).catch(
      (e) => {
        navigateTo(routePathByName('userLogout'));
        logError(e);
      }
    );
  }, [refresh]);

  const flags = useFlags();

  // populate org specific product_type search filters
  if (user.organization) {
    const productTypeFilterIndex = searchFilters.findIndex((item) => item.id === 'product_type');
    const productTypeFilters = searchFilters[productTypeFilterIndex].list;

    for (const analytic of AnalyticProducts.Types) {
      if (user.organization[analytic.orgEnabledFlagId]) {
        if (analytic.globalEnabledFlagId === undefined || flags[analytic.globalEnabledFlagId]) {
          if (!productTypeFilters.includes(analytic.value)) {
            productTypeFilters.push(analytic.value);
          }
        }
      }
    }
  }

  // Populate the collections filter list with the org's allowed collections
  if (Array.isArray(searchCollections)) {
    const collectionsFilterIndex = searchFilters.findIndex(
      (item) => item.id === 'collections'
    );

    // Set the collections search filter to the allowed collections
    // This temporarily filters out change products because their images don't display
    // correctly in the UI - this filter should be removed when that issue is solved
    searchFilters[collectionsFilterIndex].list = searchCollections.filter(
      (item) =>
        item !== 's1-change' &&
        item !== 'capella-change' &&
        item !== 'capella-bitemp-change'
    );
  }

  if (flags && flags.collectionTypeTaskingFormEnabled) {
    if (!flags.collectionTypeTaskingFormEnabled?.organizations?.includes(user.organizationId)) {
      const squintAngleIndex = searchFilters.findIndex(({ id }) => id === 'squint_angle');
      const productCategoryIndex = searchFilters.findIndex(({ id }) => id === 'product_category');

      if (squintAngleIndex !== -1) {
        searchFilters.splice(squintAngleIndex, 1);
      }

      if (productCategoryIndex === -1) {
        searchFilters.splice(4, 0, {
          label: 'Product Category',
          id: 'product_category',
          name: 'properties/capella:product_category',
          type: 'radiolist',
          list: ['standard', 'extended', 'custom']
        });
      }
    }
  }

  // Check if we've fetched the user's available collections
  const searchCollectionsFetched = searchCollections?.length;

  if (isLoading) {
    className.add('search-loading');
  }

  const initialModalState = {
    modals: {
      upload: {
        isOpen: false
      }
    }
  };

  const modalName = 'upload';
  const modal = useModal(initialModalState);
  const { ModalContextProvider, handleModalOpen } = modal;

  /**
   * handleResolveLensSearch
   * @description Patches the search request promise to include required args
   */

  async function handleResolveLensSearch (args) {
    if (pathname !== '/search/') navigateTo(routePathByName('search'));
    dispatch(_setSearchActionIsLoading('resolveLensSearch'));
    const { textInput } = args;
    let response;
    // Check search string for Collect ID, STAC ID, or regular location string search
    const searchType = isUuid(textInput) ? 'collect-id'
      : isSTACID(textInput) ? 'item-id'
        : 'default';

    setSearchArgs(args);
    setSearchOption(searchType);

    try {
      if (heatmapRef.current) {
        const request = handleHeatMapAddition(true, args);
        const loadHeatPoints = async () => {
          const returnedPoints = await request;
          if (Array.isArray(returnedPoints)) {
            heatmapRef.current.setLatLngs(returnedPoints);
          } else {
            // Assume that the heatmap has been disabled or search reset
            heatmapRef.current.setLatLngs([]);
          }
        };
        loadHeatPoints();
      }

      response = await dispatch(resolveLensSearch({
        ...args,
        user,
        pageSession,
        type: searchType,
        searchString: textInput,
        searchCollections
      }));
    } catch (e) {
      logError(e.message, args);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
      dispatch(_setSearchActionIsError('resolveLensSearch'));
      return;
    }

    dispatch(pushSearchHistory({ args, results: response }));
    dispatch(_setSearchActionIsLoaded('resolveLensSearch'));

    return response;
  }

  /**
   * Triggers a request to the Mapbox geocode API based on a query
   * @param {string} query Location, STAC ID, Collect ID, or Coordinates
   */

  async function resolveGeocodeSearch (query) {
    const errorBase = 'Failed to resolve geocode search';
    const queryIsString = typeof query === 'string';

    if (typeof query === 'undefined' || (queryIsString && query.length === 0)) {
      return [];
    }

    if (typeof query !== 'string') {
      throw new Error(`${errorBase}: Invalid query type ${query}`);
    }

    // If search is for a coordinate pair...
    let matches = query.match(
      /^(?:lat)[itude:\s]*(-?\d+\.?\d*)[,\s]+[long]{3,}[itude:\s]*(-?\d+\.?\d*)\s*$/i
    );

    if (matches) {
      const coord1 = Number(matches[1]);
      const coord2 = Number(matches[2]);
      const geocodes = [];

      geocodes.push(coordinateFeature(coord2, coord1));

      return geocodes.map(mapGeocodeCandidates);
    }

    matches = query.match(
      /^[long]{3,}[itude:\s]*(-?\d+\.?\d*)[,\s]+(?:lat)[itude:\s]*(-?\d+\.?\d*)\s*$/i
    );

    if (matches) {
      const coord1 = Number(matches[1]);
      const coord2 = Number(matches[2]);
      const geocodes = [];

      // must be lng, lat
      geocodes.push(coordinateFeature(coord1, coord2));

      return geocodes.map(mapGeocodeCandidates);
    }

    matches = query.match(/^\s*(-?\d+\.?\d*)[,\s]+(-?\d+\.?\d*)\s*$/i);

    if (matches) {
      const coord1 = Number(matches[1]);
      const coord2 = Number(matches[2]);
      const geocodes = [];

      if (coord1 < -90 || coord1 > 90) {
        // must be lng, lat
        geocodes.push(coordinateFeature(coord1, coord2));
      } else {
        if (coord2 < -90 || coord2 > 90) {
          // must be lat, lng
          geocodes.push(coordinateFeature(coord2, coord1));
        }
      }

      if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push(coordinateFeature(coord1, coord2));
        geocodes.push(coordinateFeature(coord2, coord1));
      }

      return geocodes.map(mapGeocodeCandidates);
    }

    // If search is NOT coordinates
    if (!matches) {
      // Collect ID Search
      if (isUuid(query)) {
        setAutoCompleteLoading(true);
        const response = await resolveCollectionSearch({
          searchString: query
        });
        setAutoCompleteLoading(false);
        return response;
      } else if (isSTACID(query)) {
        setAutoCompleteLoading(true);
        // ITEM ID Search (aka STAC ID Search)
        // if search entry isn't a valid collection ID
        const response = await resolveItemIdSearch({
          searchString: query
        });
        setAutoCompleteLoading(false);
        return response;
      } else {
        const searchQuery = encodeURIComponent(query);
        const request = new MapboxRequest(
          `/geocoding/v5/mapbox.places/${searchQuery}.json`,
          {
            params: {
              types: 'country,region,district,place,locality,neighborhood'
            }
          }
        );
        let response;

        try {
          response = await request.fetch();
        } catch (e) {
          throw new Error(`${errorBase}: ${e}`);
        }

        const { data = {} } = response;
        const { features = [] } = data;

        return features.map(mapGeocodeCandidates);
      }
    }
  }

  /**
   * handleUseMapEffect
   * @description Fires in a useEffect instance on the Map component
   */

  function handleUseMapEffect ({ leafletElement, layersControl }) {
    // Here we add some map object reference, so we can add leaflet objects
    mapRef.current = leafletElement;

    if (leafletElement) {
      const map = leafletElement;
      let scaleControl;
      if (scaleControlRef.current) {
        scaleControl = scaleControlRef.current;
      } else {
        scaleControl = L.control.scale({ position: 'bottomright' });
        scaleControlRef.current = scaleControl;
      }

      map.removeControl(scaleControl);
      scaleControl.addTo(map);
    }

    const mapIsReady = leafletElement && layersControl;

    // If the maps not ready yet, bail

    if (!mapIsReady) return;

    // Set the active area of the map to the container we predefine

    leafletElement.setActiveArea('lens-active-area');
  }

  /**
   * handleOnUploadAoi
   */

  function handleOnUploadAoi () {
    handleModalOpen(
      {
        currentTarget: {
          dataset: {}
        }
      },
      modalName
    );
  }

  const AutocompleteLoader = (isLoading) => {
    if (!isLoading) return null;
    return (
      <div className="autocomplete-loader">
        <span className="dot-flashing" />
      </div>
    );
  };

  async function handleHeatMapAddition (active = true, args = searchArgs) {
    setLoadingHeatMap(true);
    let response;
    if (resetSearch) {
      args = { geoJson: {}, filters: undefined, date: {} };
    }

    try {
      response = await dispatch(resolveLensSearch({
        ...args,
        type: 'heatmap',
        searchCollections,
        heatmapActive: active
      }));
    } catch (e) {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
      dispatch(_setSearchActionIsError('resolveLensSearch'));
      return;
    }

    setLoadingHeatMap(false);
    return response;
  }

  /**
   * Event handler for the Heatmap button, loads heatmap based on search results
   * @param {boolean} status is heatmap active or not?
   * @param {object} [args={}] search arguments
   */
  const handleHeatMap = (status, args) => {
    const map = window.MAP_GLOBAL;

    // Clear existing heatmap layers 1st...
    if (mapRef.current && heatmapRef.current) {
      mapRef.current.removeLayer(heatmapRef.current);
    }
    // If heatmap is enabled let's load them points!
    if (status) {
      heatmapRef.current = L.heatLayer([], { radius: 25, minOpacity: 0.4 }).addTo(map);
      const request = handleHeatMapAddition(status, args);
      const loadHeatPoints = async () => {
        const returnedPoints = await request;
        if (Array.isArray(returnedPoints)) {
          heatmapRef.current.setLatLngs(returnedPoints);
        }
      };
      loadHeatPoints();
    } else {
      // otherwise reset it
      heatmapRef.current = null;
    }
  };

  // Clear out any previously stored Create Task state
  useEffect(() => {
    resetTaskState(initDefaultTaskValues);
  }, []);

  // Watch for user clicking "Reset Search"
  useEffect(() => {
    if (resetSearch && heatMapOption) {
      // Reset Heatmap Search if enabled
      handleHeatMap(true);
      setSearchArgs({ geoJson: {}, filters: undefined, date: {} });
    }
  }, [resetSearch, heatMapOption]);

  // Sets point or not for task context on new search
  useEffect(() => {
    if (searchArgs?.geoJson?.features) {
      setIsPoint(isPointGeometryType(searchArgs));
    } else {
      setIsPoint(IS_POINT_DEFAULT);
    }
  }, [searchArgs]);

  function handleHeatMapOptionChange () {
    setHeatMapOption(!heatMapOption);
    handleHeatMap(!heatMapOption);
  }

  // Toggle Cart Open/Closed via showCart query param
  function handleShowCart () {
    updateQuerySearchParams({ showCart: !showCart });
    setShowCart(!showCart);
  }

  // Wait to render Lens (and hit /search) until we have searchCollections
  if (!searchCollectionsFetched) {
    return (
      <Layout className="fullscreen" footer={false}>
        <LoadingSpinner isLoading={true} position="vertical-center" size="large" color="gold" />
      </Layout>
    );
  } else {
    return (
      <Layout className="fullscreen" footer={false}>
        <Helmet bodyAttributes={{ class: 'page-home' }}>
          <title>Search</title>
        </Helmet>
        <div data-search-details={onDetailsPath}>
          <Lens
            map="mapbox"
            availableLayers={activeLayers}
            availableServices={mapServices}
            projection="epsg3857"
            hideNativeLayers={true}
            className={`${className.string} ${searchOption}-search`}
            placeholder='Search Location, STAC ID, or Collect ID'
            defaultCenter={DEFAULT_CENTER}
            defaultZoom={3}
            resolveOnSearch={handleResolveLensSearch}
            resolveOnAutocomplete={resolveGeocodeSearch}
            SidebarComponents={SearchSidebar}
            useMapEffect={handleUseMapEffect}
            showFilters={searchOption === 'default' ? !onDetailsPath : false}
            availableFilters={searchFilters}
            hasFilterCancel={false}
            utc={true}
            draw={{
              shapeOptions: {
                style: {
                  color: '#0d7eff',
                  fill: false,
                  weight: 2
                }
              }
            }}
            searchActions={[
              {
                label: 'Upload AOI',
                icon: 'FaUpload',
                onClick: handleOnUploadAoi
              }
            ]}
            hideDatetime={searchOption !== 'default'}
            allowStartAfterEndDate={false}
            disableFutureDates={true}
          >
            {AutocompleteLoader(isAutoCompleteLoading)}
            <ModalContextProvider>
              <SearchModalUpload name={modalName} modal={modal} />
            </ModalContextProvider>
          </Lens>
        </div>
        <div
          className={`cart-button ${hasItems ? 'cart-button-has-items' : ''}`}
        >
          <Button onClick={handleShowCart}>
            <FaShoppingCart />
            {hasItems && <span className="item-count">{itemCount}</span>}
          </Button>
        </div>
        <div className="heat-map">
          <Button
            onClick={handleHeatMapOptionChange}
            data-tip
            data-for="heatmapTip"
            data-type="dark"
          >
            {heatMapOption && loadingHeatMap && (
              <span className="loader">
                <FaSpinner className="icon-spin" />
              </span>
            )}
            {!loadingHeatMap && heatMapOption && (
              <span>
                <FaCheck />
              </span>
            )}
            <img src={heatMapLogo} />
          </Button>
          <ReactTooltip id="heatmapTip" place="left">
            Toggle display for heatmap of catalog imagery.
          </ReactTooltip>
        </div>
        <FloatingCart showCart={showCart} handleCloseCart={handleShowCart} />
      </Layout>
    );
  }
};

export default SearchPage;
