/**
 * Enumerator and properties for Analytic Product Types
 */
export const AnalyticProducts = {
  Types: [
    {
      value: 'ACD',
      label: 'Amplitude Change Detection',
      orderUpliftPropertyKey: 'analyticImageryUpliftACD',
      repeatOnly: true,
      orgEnabledFlagId: 'amplitudeChangeDetectionEnabled',
      requiredProductTypes: ['GEO']
    },
    {
      value: 'VS',
      label: 'Vessel Detection',
      orderUpliftPropertyKey: 'analyticImageryUpliftVS',
      repeatOnly: false,
      globalEnabledFlagId: 'vesselDetectionEnabled',
      orgEnabledFlagId: 'vesselDetectionEnabled',
      requiredProductTypes: ['GEO']
    },
    {
      value: 'VC',
      label: 'Vessel Classification',
      orderUpliftPropertyKey: 'analyticImageryUpliftVC',
      repeatOnly: false,
      globalEnabledFlagId: 'vesselClassificationEnabled',
      orgEnabledFlagId: 'vesselClassificationEnabled',
      requiredProductTypes: ['SICD']
    }
  ],

  asArray: () =>
    AnalyticProducts.Types.map(t => t.value),

  /**
   * Check if a product type is an analytic type
   *
   * @param {string} productType
   * @returns {boolean}
   */
  isAnalytic: (productType) => AnalyticProducts.asArray().includes(productType),

  /**
   * If any product types are an analytic type, return true
   *
   * @param {string[]} productTypes
   * @returns {boolean}
   */
  hasAnalytics: (productTypes) =>
    productTypes.some((productType) => AnalyticProducts.isAnalytic(productType)),

  /**
   * Get friendly label for an analytic product type
   *
   * @param {string} productType
   * @returns {string}
   */
  getLabel: (productType) =>
    AnalyticProducts.Types.find(t => t.value === productType).label,

  /**
   * Get the key of the order for identifying the uplift data for the analytic product type
   *
   * @param {string} productType
   * @returns {string}
   */
  getOrderUpliftPropertyKey: (productType) =>
    AnalyticProducts.Types.find(t => t.value === productType).orderUpliftPropertyKey
};
