import clone from 'clone';

const DEFAULT_NOTICE = undefined;

const defaultState = {
  notice: DEFAULT_NOTICE,
  showCustomNotice: false,
  basemap: 'street'
};

const ui = (state = defaultState, action) => {
  const workingState = clone(state);
  const workingData = clone(action.data);
  switch (action.type) {
    case 'UPDATE_NOTICE':
      return { ...workingState, ...workingData };
    case 'SHOW_CUSTOM_NOTICE':
      return {
        ...workingState,
        showCustomNotice: workingData
      };
    case 'SET_BASEMAP':
      return {
        ...workingState,
        basemap: workingData
      };
    default:
      return workingState;
  }
};

export default ui;
