import { useMappedState } from 'redux-react-hook';

import Organization from '../models/organization';
import Task from '../models/task';

export default function useReseller ({ includeSpawned = true } = {}) {
  const mapState = ({ reseller }) => reseller;

  const { organization, tasks = [], ...rest } = useMappedState(mapState);

  // Only return valid tasks with a tasking request ID
  let filteredTasks = tasks.filter(
    (task) => task?.properties?.taskingrequestId
  );

  // Filter out tasks spawned from a repeat request if desired
  if (!includeSpawned) {
    filteredTasks = filteredTasks.filter(
      (task) => !task?.properties?.repeatrequestId
    );
  }

  return {
    ...rest,
    organization: new Organization(organization),
    tasks: filteredTasks.map((task) => {
      const instance = new Task();
      instance.ingestTaskBody(task);
      return instance;
    })
  };
}
