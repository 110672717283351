import React from 'react';
import { useDispatch } from 'redux-react-hook';
import { Helmet } from 'react-helmet';

import { logError } from 'lib/logger';
import { routePathByName, navigateTo } from 'lib/routes';
import { submitPremiumRequest, triggerNotice } from 'state/actions';

import Layout from 'components/Layout';
import Container from 'components/Container';
import FormPremiumSignup from 'components/FormPremiumSignup';

const UserPremiumPage = () => {
  const dispatch = useDispatch();

  function handleFormSubmit (e, fields) {
    const userFields = {
      title: { ...fields.position },
      country: { ...fields.country },
      city: { ...fields.city },
      state: { ...fields.state }
    };

    dispatch(submitPremiumRequest(userFields))
      .then(() => {
        navigateTo(routePathByName('adminUsers'));
      })
      .catch((error) => {
        logError(error.message, userFields);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Oops, something went wrong. Try again!'
          })
        );
      });
  }

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-user page-user-premium' }}>
        <title>Sign Up for Premium Access</title>
      </Helmet>
      <Container className="content">
        <h1>Want to Task and Order?</h1>
        <p>
          Sign up for full access to task Capella satellites and order data.
        </p>
        <FormPremiumSignup onSubmit={handleFormSubmit} />
      </Container>
    </Layout>
  );
};

export default UserPremiumPage;
