import React from 'react';
import PropTypes from 'prop-types';
import { useContracts, useFetchContracts, useUser } from 'hooks';
import TemplateContractDetails from 'commonLib/src/templates/TemplateContractDetails';
import { ORG_MANAGER_ROLE } from 'data/roles';
import {
  ROUTE_ORG,
  ROUTE_ORG_TASKS,
  ROUTE_ORG_TASKS_DETAILS
} from 'data/route-names';
import { routePathByName } from 'lib/routes';

const OrganizationTasksContractDetailsPage = ({
  contractId,
  taskId
}) => {
  const { contracts: [contract = {}] } = useContracts({ byId: contractId });
  const { isLoading } = useFetchContracts();
  const { user: { organization = {} } } = useUser();

  const organizationState = {
    isLoading
  };

  const tasksPath = routePathByName(ROUTE_ORG_TASKS);
  const organizationPath = routePathByName(ROUTE_ORG);
  // default breadcrumbs for contract details view
  const breadcrumbs = [
    {
      label: 'Organization',
      to: organizationPath
    },
    {
      label: 'Tasks',
      to: tasksPath
    }
  ];

  // if we have a task id, update the breadcrumbs to orders and order details paths
  if (taskId) {
    const taskDetailsPath = routePathByName(ROUTE_ORG_TASKS_DETAILS, {
      wildcard: taskId
    });

    breadcrumbs.push({
      label: 'Task Details',
      to: taskDetailsPath
    });
  }

  return (
    <TemplateContractDetails
      accessType={ORG_MANAGER_ROLE}
      breadcrumbs={breadcrumbs}
      contract={contract}
      organization={organization}
      state={organizationState}
    />
  );
};

OrganizationTasksContractDetailsPage.propTypes = {
  contractId: PropTypes.string,
  taskId: PropTypes.string
};

export default OrganizationTasksContractDetailsPage;
