import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { Helmet } from 'react-helmet';
import { MdInfoOutline, MdWarningAmber } from 'react-icons/md';

import Layout from 'components/Layout';
import Breadcrumbs from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import CostBreakdown from 'components/CostBreakdown';
import OrderConfigTable from 'components/OrderConfigTable';
import OrderStatus from 'components/OrderStatus';
import ItemCard from 'components/ItemCard';

import { formatDateTime } from 'fogg/src/lib/datetime';
import { Notice } from 'fogg/ui';
import { routePathByName } from 'lib/routes';
import { fillEmptyOrder } from 'lib/data';
import { calculateItemCosts } from 'lib/cart';
import { groupItemsByCollect } from 'commonLib/src/lib/items';
import { downloadOrderZip } from 'state/actions';
import { useOrder, useUser } from 'hooks';
import { TaskCollectModes } from 'data/task-collect-modes';
import { TaskCollectionTiers } from 'data/task-collection-tiers';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';
import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from 'commonLib/src/data/contracts';

const ordersPath = routePathByName('orders');

const DEFAULT_BREADCRUMBS = [
  {
    label: 'Orders',
    to: ordersPath
  }
];

const EXCLUDED_ASSET_TYPES = ['thumbnail'];

/**
 * Template used for displaying individual Order Details page
 * @param {object} props
 * @param {object} [props.order={}]
 * @param {object} [props.assets={}]
 * @param {array} [props.breadcrumbs=[]]
 * @param {object} [props.state={}]
 */
const TemplateOrder = ({
  order = {},
  assets = {},
  breadcrumbs = DEFAULT_BREADCRUMBS,
  state = {}
}) => {
  const dispatch = useDispatch();

  const containerType = [];
  const { id: orderId } = order;
  const { items: assetItems } = assets;
  const { isLoading = false } = state;
  const [downloadsObj, setDownloadObj] = useState({});

  const newOrders = useOrder(orderId);

  const { user = {} } = useUser();
  const { organization = {} } = user;
  let { hideOrderPricing, contracts } = organization;
  const hasMultipleContracts = contracts?.length > 1;

  const contract = contracts?.find((contract) => contract.id === order.contractId);
  if (contract?.type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION) {
    hideOrderPricing = true;
  }

  useEffect(() => {
    // Only poll for download zip if order is completed
    if (status !== 'completed') return;
    const interval = setInterval(() => {
      if (newOrders) {
        Object.entries(newOrders.items).forEach((entry) => {
          const [key, value] = entry;
          dispatchApi(newOrders.id, value.collection, value.id, key);
        });
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [dispatch, orderId, newOrders, order]);

  const dispatchApi = (id, collection, granule, key) => {
    if (Object.prototype.hasOwnProperty.call(downloadsObj, key)) {
      if (
        downloadsObj[key].filter((msg) => msg.message)[0].message === 'finished'
      ) {
        return;
      }
    }

    dispatch(downloadOrderZip(id, collection, granule)).then((data) => {
      const itemD = {
        id: newOrders.id,
        collection: collection,
        granule: granule,
        message: data.message,
        url: data.url
      };
      const newDownloadObj = Object.assign({}, downloadsObj, {
        [key]: [itemD]
      });
      setDownloadObj(newDownloadObj);
    });
  };

  // If user hides Notice, store that in localStorage so it doesn't return on refresh
  const [hideInfoNotice, setHideInfoNotice] = useState(false);

  function hideNotice () {
    setHideInfoNotice(true);
    window.localStorage.setItem('hideInfoNotice', true);
  }
  // Keep local state and localStorage in sync
  useEffect(() => {
    if (window.localStorage.getItem('hideInfoNotice')) {
      setHideInfoNotice(JSON.parse(window.localStorage.getItem('hideInfoNotice')));
    }
  }, []);

  let activeOrder = order;

  if (isLoading) {
    containerType.push('is-loading');
    activeOrder = fillEmptyOrder();
  }

  const { items, costBreakdown = {}, status } = activeOrder;
  const { lineItems = [] } = costBreakdown || {};
  const activeItems = assetItems || items;

  // Check if order had analytics included, which has some unique things to handle
  const [orderHasAnalytics, setOrderHasAnalytics] = useState(false);
  const productTypes = items?.map(({ feature = {} }) => feature.productType);

  useEffect(() => {
    if (!productTypes?.length) return;
    setOrderHasAnalytics(AnalyticProducts.hasAnalytics(productTypes));
  }, [productTypes]);

  const assetSettings = {
    allowDownload: true,
    excludeTypes: EXCLUDED_ASSET_TYPES
  };

  const groupedItems = groupItemsByCollect(items);
  const groupedAssetItems = groupItemsByCollect(assetItems);

  let orderItems;

  if (groupedItems && Object.keys(groupedItems).length) {
    // Transform grouped Items to a format CostBreakdown can easily use
    orderItems = Object.keys(groupedItems).map((itemId) => {
      if (!itemId || itemId === 'undefined') return;
      const stacItems = groupedItems[itemId];
      const assetItems = groupedAssetItems ? groupedAssetItems[itemId] : [];

      const items = lineItems.filter(i => i.collectId === itemId);

      const { totalCost, analyticCost, imageryCost } = calculateItemCosts(items);
      // Some metadata comes from the order items, some from asset items
      const { collectId, properties, endTime, feature = {}, freshnessOption, task = {} } = stacItems[0] || {};
      const { collectionTier } = task || {};

      // Whenever possible we want the thumbnail from GEO item
      const geoAssetItem = assetItems.find(i => i.id?.includes('_GEO_'));
      const { thumbnail } = geoAssetItem || assetItems[0] || {};

      const collectMode = (properties && properties['sar:instrument_mode'])
        ? TaskCollectModes.properties[properties['sar:instrument_mode']]?.label
        : feature.collectionDate
          ? TaskCollectModes.properties[feature.type]?.label : undefined;

      const collectDate = endTime || feature.collectionDate;

      return {
        itemId: collectId,
        collection: feature.collectionId,
        collectDate: collectDate ? formatDateTime(collectDate) : undefined,
        imagingMode: collectMode,
        thumbnail: thumbnail,
        cost: !hideOrderPricing && totalCost,
        freshness: freshnessOption,
        taskingTier: collectionTier ? TaskCollectionTiers.properties[collectionTier]?.label : undefined,
        children: assetItems.map((item, index) => {
          const productType = item.productType || item.feature?.productType;

          let itemCost;
          // Only display imagery cost once, on the 1st line item
          if (index === 0) itemCost = imageryCost;
          if (AnalyticProducts.isAnalytic(productType)) {
            itemCost = analyticCost;
          }
          item.cost = !hideOrderPricing ? itemCost || '--' : undefined;

          return (
            <ItemCard
              key={`Cart-Item-${index}`}
              className={productType}
              item={item}
              isChild={true}
              actionSettings={{
                isRemovable: false
              }}
              assetSettings={assetSettings}
              archives={downloadsObj}
              order={order}
            />
          );
        })
      };
    });
  }

  const hasItems = Array.isArray(activeItems) && activeItems.length > 0 && orderItems;

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-orders page-orders-order' }}>
        <title>Order {orderId || 'loading...'}</title>
      </Helmet>
      <Container className="content" type={containerType}>
        <Breadcrumbs items={breadcrumbs} />

        <PageHeader title="Order Details" subtitle={`ID: ${orderId}`} />

        <div className="order-details">
          <div className="order-details-card">
            <div className="order-details-card-status">
              <OrderStatus order={activeOrder} />
            </div>
          </div>

          <OrderConfigTable order={activeOrder} hasMultipleContracts={hasMultipleContracts}/>
        </div>

        <div className="order-items">
          {(orderHasAnalytics && status === 'failed') && (
            <Notice type="error large-icon" weight="bold">
              <MdWarningAmber />
              <span>Your Analytic failed to generate, you will not be charged. Please try again or contact support.</span>
            </Notice>
          )}
          {!hideInfoNotice && (
            <Notice type="white large-icon" onClose={hideNotice}>
              <MdInfoOutline />
              <span>
                Prices displayed reflect the original cost of the task or archive order. You will only be charged once.<br/>
                <b>There is no additional cost to download your data or utilize push to s3.</b>
              </span>
            </Notice>
          )}
          {hasItems && (
            <>
              <h3>Items</h3>
              <CostBreakdown
                items={orderItems}
                showHeader={false}
                showCost={!hideOrderPricing}
              />
            </>
          )}

          {!hasItems && <p>No items exist for this order.</p>}
        </div>
      </Container>
    </Layout>
  );
};

TemplateOrder.propTypes = {
  order: PropTypes.object,
  assets: PropTypes.object,
  breadcrumbs: PropTypes.array,
  state: PropTypes.object
};

export default TemplateOrder;
