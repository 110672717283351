class RequestError extends Error {
  constructor ({ message, response = {} } = {}) {
    super(message);

    const { data = {} } = response;
    const { error } = data;

    this.response = response;
    this.error = error;
  }

  setMessage (message) {
    this.message = message;
    return this.message;
  }
}

export default RequestError;
