import { setPropertyIfExists } from '../../lib/util';
import { constructRequestActionManager } from '../../lib/actions';
import { routePathByName } from '../../lib/routes';
import {
  ROUTE_API_ORGS,
  ROUTE_API_ORGS_PAGED,
  ROUTE_API_PUBLIC_COLLECTIONS,
  ROUTE_API_USERS,
  ROUTE_API_USERS_PAGED
} from '../../data/route-names';
import { ACCESS_ROLES, ADMIN_ROLE } from '../../data/roles';
import { CONTRACT_TYPE_RESELLER } from '../../data/contracts';
import { ADMIN_SCOPE } from '../../data/scopes';
import { DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS, UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS } from '../../data/errors';

/**
 * setAdminActionState
 * @description Updates the admin store's status
 */

export function setAdminActionState (status) {
  return {
    type: 'UPDATE_ADMIN_ACTION_STATE',
    data: status
  };
}

const createDispatchRequestAction =
  constructRequestActionManager(setAdminActionState);

/**
 * fetchAllUsers
 * @description Fetches all user profiles
 */

export const fetchAllUsers = () => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchAllUsers',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_USERS),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateAdminUsers(data));

    return data;
  };
};

/**
 * fetchAllUsersPaged
 * @param {object} props = { page: 1, limit: 500 }
 * @param {number} props.page = 1
 * @param {number} [props.limit = 500]
 * @description Fetches all user profiles from /paged endpoint
 * NOTE: Search & Filtering is done client-side, so the UI loops through
 * the totalPages & results to populate the store with all results
 * @returns { results = [], currentPage = 1, totalPages = 1 }
 */

export const fetchAllUsersPaged = ({ page = 1, limit = 500 }) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchAllUsersPaged',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_USERS_PAGED, {
          params: {
            page,
            limit
          }
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const { data } = request || {};
    return data;
  };
};

/**
 * fetchUserById
 * @description Fetches a user profile by their ID
 * @param {string} userId
 */

export const fetchUserById = (userId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchUserById',
      scope: ADMIN_SCOPE,
      request: {
        url: `${routePathByName('apiUsers')}/${userId}`,
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateAdminUsers(data));

    return data;
  };
};

/**
 * postNewUser
 * @description Creates a new user given the data object
 * @param {object} data
 */

export const postNewUser = (data = {}) => {
  return async (dispatch, getState) => {
    const userData = {
      familyName: data.familyName,
      givenName: data.givenName,
      email: data.email,
      address: {
        street: data.street,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country
      },
      organizationId: data.organizationId,
      apiEnvironmentRole: data.apiEnvironmentRole
    };

    ACCESS_ROLES.filter((role) => {
      return role.manageAccess.includes(ADMIN_ROLE) && role.updateDisabled !== true;
    }).forEach((role) => {
      if (typeof data[role.apiKey] === 'boolean') {
        userData[role.apiKey] = data[role.apiKey];
      }
    });

    const dispatchAction = createDispatchRequestAction({
      name: 'postNewUser',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiUsers'),
        method: 'post',
        data: userData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(addAdminUser(requestData));

    return requestData;
  };
};

/**
 * putUpdateUser
 * @description Updates a user's profile with the given data
 * @param {string} userId The user's ID
 * @param {object} data Updated user data
 */

export const putUpdateUser = (userId, data) => {
  return async (dispatch, getState) => {
    // we only want to pass params that are meant to be updated

    const userData = {
      ...setPropertyIfExists('familyName', data.familyName),
      ...setPropertyIfExists('givenName', data.givenName),
      ...setPropertyIfExists('organization', data.organization),
      ...setPropertyIfExists('organizationId', data.organizationId),
      ...setPropertyIfExists('noaaWhitelisted', data.noaaWhitelisted),
      ...setPropertyIfExists('apiEnvironmentRole', data.apiEnvironmentRole),
      ...setPropertyIfExists('disabled', data.disabled),
      ...setPropertyIfExists('forceDisabled', data.forceDisabled),
      ...setPropertyIfExists('forceTaskerRoleRemoval', data.forceTaskerRoleRemoval),
      ...setPropertyIfExists(
        'sendSupplementaryEmailNotifications',
        data.sendSupplementaryEmailNotifications
      ),
      ...setPropertyIfExists(
        'sendAllTransactionEmailNotifications',
        data.sendAllTransactionEmailNotifications
      )
    };

    ACCESS_ROLES.forEach((role) => {
      if (typeof data[role.apiKey] === 'boolean') {
        userData[role.apiKey] = data[role.apiKey];
      }
    });

    const address = {
      ...setPropertyIfExists('street', data.street),
      ...setPropertyIfExists('city', data.city),
      ...setPropertyIfExists('state', data.state),
      ...setPropertyIfExists('zip', data.zip)
    };

    // Only add the address prop if we have anything to change

    if (Object.keys(address) > 0) {
      userData.address = address;
    }

    const dispatchAction = createDispatchRequestAction({
      name: 'putUpdateUser',
      scope: ADMIN_SCOPE,
      request: {
        url: `${routePathByName('apiUsers')}/${userId}`,
        method: 'put',
        data: userData
      }
    });

    let response;
    try {
      response = await dispatchAction(dispatch, getState);
    } catch (err) {
      // Expected errors, catch and return message
      if (err.message === DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS || err.message === UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS) {
        return {
          updated: false,
          message: err.message
        };
      }
      // Unexpected error, throw it up to the parent
      throw err;
    }

    const { data: requestData } = response || {};

    const updatedUserData = {
      id: userId,
      ...requestData
    };

    dispatch(updateAdminUsers(updatedUserData));

    return updatedUserData;
  };
};

export const deleteUser = (userId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'deleteUser',
      scope: ADMIN_SCOPE,
      request: {
        url: `${routePathByName('apiUsers')}/${userId}`,
        method: 'delete'
      }
    });

    await dispatchAction(dispatch, getState);

    dispatch(deleteAdminUser(userId));

    return userId;
  };
};

/**
 * reissuePassword
 * @description
 */

export const reissuePassword = (userId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'reissuePassword',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiUsersResendPassword', {
          wildcard: [userId]
        }),
        method: 'post'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    return requestData;
  };
};

/*****************
 * ORGANIZATIONS *
 *****************/

/**
 * fetchOrganizations
 * @description Fetches a list of available organizations
 */

export const fetchOrganizations = () => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrganizations',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_ORGS),
        method: 'fetch'
      }
    });

    const response = await dispatchAction(dispatch, getState);

    const { data } = response || {};

    dispatch(updateAdminOrganizations(data));

    return data;
  };
};

/**
 * fetchOrganizationsPaged
 * @description Fetches a list of available organizations in paged format
 * @param {object} props = { page: 1, limit: 500 }
 * @param {number} props.page = 1
 * @param {number} [props.limit = 500]
 * @description Fetches all organizations from /paged endpoint
 * NOTE: Search & Filtering is done client-side, so the UI will loop through
 * the totalPages & results to populate the org store with all results
 * @returns { results = [], currentPage = 1, totalPages = 1 }
 */

export const fetchOrganizationsPaged = ({ page = 1, limit = 500 }) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrganizationsPaged',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_ORGS_PAGED, {
          params: {
            page,
            limit
          }
        }),
        method: 'fetch'
      }
    });

    const response = await dispatchAction(dispatch, getState);

    const { data = {} } = response || {};

    return data;
  };
};

/**
 * fetchOrganizationById
 * @description Fetches a organization profile by their ID
 * @param {string} organizationId
 * @param {object} [requestOptions={}] - optional request options
 * @param {boolean} [requestOptions.eagerLedgerIncludes=false] - if true, returns additional eager loaded orders and opportunity discounts with each contract
 */

export const fetchOrganizationById = (
  organizationId,
  { eagerLedgerIncludes = false } = {}
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrganizationById',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsOrganization', {
          wildcard: [organizationId],
          params: {
            eagerLedgerIncludes
          }
        }),
        method: 'fetch'
      }
    });

    const response = await dispatchAction(dispatch, getState);

    const { data } = response || {};

    dispatch(
      updateAdminOrganization({
        id: organizationId,
        ...data
      })
    );

    return data;
  };
};

/**
 * fetchOrganizationContracts
 * @description Fetches an organization's ledger contracts
 * @param {string} organizationId
 */
export const fetchOrganizationContracts = (organizationId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrganizationContracts',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsOrganizationContracts', {
          wildcard: [organizationId]
        }),
        method: 'fetch'
      }
    });

    const response = await dispatchAction(dispatch, getState);

    const { data } = response || {};

    dispatch(updateAdminOrganizationLedgerContracts(organizationId, data));

    return data;
  };
};

/**
 * createNewOrganization
 * @description Creates a new organization given the data object
 * @param {object} data
 */

export const createNewOrganization = (data) => {
  return async (dispatch, getState) => {
    const type = Array.isArray(data.type) ? data.type[0] : data.type;
    const organizationData = {
      name: data.organizationName,
      address: {
        street: data.street,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country
      },
      type: type,
      resellerId: data.resellerId && data.resellerId[0],
      subResellerId: Array.isArray(data.subResellerId)
        ? data.subResellerId[0]
        : data.subResellerId,
      fullZoomAccess: type === CONTRACT_TYPE_RESELLER // Default Full Zoom Access for reseller orgs
    };

    const dispatchAction = createDispatchRequestAction({
      name: 'createNewOrganization',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizations'),
        method: 'post',
        data: organizationData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(addAdminOrganization(requestData));

    return requestData;
  };
};

/**
 * adminUpdateOrganization
 * @description Updates an organization given the data object
 * @param {object} data
 */

export const adminUpdateOrganization = (organizationId, data) => {
  return async (dispatch, getState) => {
    const organizationData = {
      name: data.organizationName,
      contractType: data.contractType,
      type: Array.isArray(data.type) ? data.type[0] : data.type,
      address: {
        id: data.addressId,
        street: data.street,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country
      },
      availableLicenses: data.availableLicenses,
      s3PushEnabled: data.s3PushEnabled,
      salesforceAccountId: data.salesforceAccountId,
      hideOrderPricing: data.hideOrderPricing,
      hideTransactionActivity: data.hideTransactionActivity,
      fullZoomAccess: data.fullZoomAccess,
      amplitudeChangeDetectionEnabled: data.amplitudeChangeDetectionEnabled,
      vesselClassificationEnabled: data.vesselClassificationEnabled,
      disabled: data.disabled
    };

    if (data.roles) {
      organizationData.roles = Array.isArray(data.roles)
        ? data.roles
        : [data.roles];
    }

    const dispatchAction = createDispatchRequestAction({
      name: 'adminUpdateOrganization',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsOrganization', {
          wildcard: [organizationId]
        }),
        method: 'put',
        data: organizationData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(updateAdminOrganization(requestData));

    // This is here for when the UI needs to re-render any updated data without refreshing the page
    dispatch(fetchOrganizationById(organizationId));

    return requestData;
  };
};

/**
 * fetchOrganizationS3Buckets
 * @param {string} organizationId
 */

export const fetchOrganizationS3Buckets = (organizationId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrganizationS3Buckets',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrgS3BucketPostGet', {
          wildcard: [organizationId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(
      adminUpdateOrganizationS3Bucket({
        organizationId,
        bucketId: requestData.id,
        s3buckets: requestData
      })
    );

    return requestData;
  };
};

/**
 * adminCreateNewOrganizationS3Bucket
 * @description Creates a new organization S3 Bucket
 * @param {object} data
 */

export const adminCreateNewOrganizationS3Bucket = (organizationId, data) => {
  return async (dispatch, getState) => {
    const s3Data = {
      bucketName: data.bucketName,
      prefix: data.prefix,
      isGovcloud: data.isGovcloud,
      isActive: data.isActive
    };
    const dispatchAction = createDispatchRequestAction({
      name: 'adminCreateNewOrganizationS3Bucket',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrgS3BucketPostGet', {
          wildcard: [organizationId]
        }),
        method: 'post',
        data: s3Data
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(addAdminOrganizationS3Bucket(requestData));

    return requestData;
  };
};

/**
 * adminUpdateOrganizationS3Bucket
 * @description Updates an organization's S3 Bucket
 * @param {object} data
 */

export const adminUpdateOrganizationS3Bucket = (
  organizationId,
  bucketId,
  data,
  fetchOrgById = true
) => {
  return async (dispatch, getState) => {
    const s3BucketConfig = {
      bucketName: data.bucketName,
      prefix: data.prefix,
      isActive: data.isActive,
      isGovcloud: data.isGovcloud
    };
    const organizationBucketData = s3BucketConfig;
    const dispatchAction = createDispatchRequestAction({
      name: 'adminUpdateOrganizationS3Bucket',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrgS3BucketPutDel', {
          wildcard: [organizationId, bucketId]
        }),
        method: 'put',
        data: organizationBucketData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(updateAdminOrganizationS3Bucket(requestData));

    if (fetchOrgById) {
      dispatch(fetchOrganizationById(organizationId));
    }

    return requestData;
  };
};

/**
 * adminDeleteOrganizationS3BucketConfig
 * @description Delete an organization's S3 Bucket Config
 * @param {object} data
 */

export const adminDeleteOrganizationS3BucketConfig = (
  organizationId,
  bucketConfigId
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'adminUpdateOrganizationS3Bucket',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrgS3BucketPutDel', {
          wildcard: [organizationId, bucketConfigId]
        }),
        method: 'delete'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};
    return requestData;
  };
};

/**
 * fetchOrganizationCollections
 * @param {string} organizationId
 */

export const fetchOrganizationCollections = (organizationId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchOrganizationCollections',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsCollections', {
          wildcard: [organizationId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(
      updateAdminOrganizationCollectionPermissions({
        organizationId,
        collections: requestData
      })
    );

    return requestData;
  };
};

/**
 * addOrganizationCollectionPermissions
 * @param {string} organizationId
 * @param {array} collections
 */

export const addOrganizationCollectionPermissions = (
  organizationId,
  collections
) => {
  const data = {
    permissions: collections.map((collectionId) => {
      return {
        collectionId,
        organizationId
      };
    })
  };

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'addOrganizationCollectionPermissions',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiCollectionPermissions'),
        method: 'post',
        data
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(
      addAdminOrganizationCollectionPermissions({
        organizationId,
        collections
      })
    );

    dispatch(fetchOrganizationCollections(organizationId));

    return requestData;
  };
};

/**
 * removeOrganizationCollectionPermission
 * @param {string} organizationId
 * @param {string} collection
 */

export const removeOrganizationCollectionPermission = (
  organizationId,
  collection
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'removeOrganizationCollectionPermission',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiCollectionPermissions'),
        method: 'delete',
        data: {
          collectionId: collection,
          organizationId
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(
      removeAdminOrganizationCollectionPermissions({
        organizationId,
        collections: [collection]
      })
    );

    dispatch(fetchOrganizationCollections(organizationId));

    return requestData;
  };
};

/**
 * fetchPublicCollections
 * @returns {(dispatch: Function, getState: Function) => Promise<object[]>} promises an array of collections
 */
export const fetchPublicCollections = () => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: fetchPublicCollections.name,
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_PUBLIC_COLLECTIONS),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(
      fetchAdminPublicCollections({
        publicCollections: requestData
      })
    );

    return requestData;
  };
};

/*************************
 * ORGANIZATION CONTRACTS *
 **************************/

/**
 * addOrganizationContract
 * @description
 * @param {string} organizationId The organization ID
 * @param {object} data New contract data
 */

export const addOrganizationContract = (organizationId, data) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'addOrganizationContract',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsContracts', {
          wildcard: organizationId
        }),
        method: 'post',
        data
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(createUpdateOrganizationContractAction(organizationId, responseData));

    return responseData;
  };
};

/**
 * updateOrganizationContract
 * @description
 * @param {string} organizationId The organization ID
 * @param {string} contractId The contract ID
 * @param {object} data Updated contract data
 */

export const updateOrganizationContract = (
  organizationId,
  contractId,
  data
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateOrganizationContract',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsContracts', {
          wildcard: [organizationId, contractId]
        }),
        method: 'put',
        data
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(createUpdateOrganizationContractAction(organizationId, responseData));

    return responseData;
  };
};

/**
 * addOrganizationContractPeriod
 * @description
 * @param {string} organizationId The organization ID
 * @param {string} contractId The parent contract's ID
 * @param {object} data Contract period details
 */

export const addOrganizationContractPeriod = (
  organizationId,
  contractId,
  data
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'addOrganizationContractPeriod',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsContractPeriods', {
          wildcard: [organizationId, contractId]
        }),
        method: 'post',
        data
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(addAdminOrganizationContractPeriod(organizationId, responseData));

    return responseData;
  };
};

/**
 * updateOrganizationContractPeriod
 * @description
 * @param {string} organizationId The user's ID
 * @param {string} contractPeriodId The user's ID
 * @param {object} data Contract period details
 */

export const updateOrganizationContractPeriod = (
  organizationId,
  contractPeriodId,
  data
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateOrganizationContractPeriod',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsContractPeriodsById', {
          wildcard: [organizationId, contractPeriodId]
        }),
        method: 'put',
        data
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(
      updateAdminOrganizationContractPeriod(organizationId, responseData)
    );

    return responseData;
  };
};

/**
 * postOrganizationAction -- /action CHMO API endpoint
 * @description
 * @param {object} object -- job options like backfill, window, spacing, etc.
 */

export const postOrganizationAction = (object) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'postOrganizationAction',
      scope: ADMIN_SCOPE,
      request: {
        url: `${routePathByName('chmoApiAction')}`,
        method: 'post',
        data: object
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};
    return requestData;
  };
};

/**
 * shareContractWithOrganization
 * @description
 * @param {string} ownerId The ID for the organization that owns the contract
 * @param {string} contractId The contract's ID
 * @param {string} orgId The ID of the organization to share with
 */

export const shareContractWithOrganization = (ownerId, contractId, orgId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'shareContractWithOrganization',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsContractDatashare', {
          wildcard: [ownerId, contractId]
        }),
        method: 'post',
        data: { orgId }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(
      addAdminContractDatashareOrganization(ownerId, contractId, responseData)
    );

    return responseData;
  };
};

/**
 * unshareContractWithOrganization
 * @description
 * @param {string} ownerId The ID for the organization that owns the contract
 * @param {string} contractId The contract's ID
 * @param {string} orgId The ID of the organization to unshare
 */

export const unshareContractWithOrganization = (
  ownerId,
  contractId,
  orgId,
  shareId
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'unshareContractWithOrganization',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsContractDatashare', {
          wildcard: [ownerId, contractId, shareId]
        }),
        method: 'delete',
        data: { orgId }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(
      removeAdminContractDatashareOrganization(ownerId, contractId, orgId)
    );

    return responseData;
  };
};

/**
 * fetchLicenses
 * @description Fetches a list of defined licenses
 */

export const fetchLicenses = () => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchLicenses',
      scope: ADMIN_SCOPE,
      request: {
        url: routePathByName('apiLicenses'),
        method: 'fetch'
      }
    });

    const response = await dispatchAction(dispatch, getState);

    const { data } = response || {};

    dispatch(updateLicenses(data));

    return data;
  };
};

/******************
 * STATE UPDATERS *
 ******************/

/**
 * updateAdminOrganizations
 * @description Set the admin organizations
 */

export const updateAdminOrganizations = (data) => {
  return {
    type: 'UPDATE_ADMIN_ORGANIZATIONS',
    data
  };
};

/**
 * updateAdminOrganization
 * @description Update an admin organization
 */

export const updateAdminOrganization = (data) => {
  return {
    type: 'UPDATE_ADMIN_ORGANIZATION',
    data
  };
};

/**
 * addAdminOrganization
 * @description Set the admin organizations
 */

export const addAdminOrganization = (data) => {
  return {
    type: 'ADD_ADMIN_ORGANIZATION',
    data
  };
};

/**
 * updateAdminOrganizationLedgerContracts
 * @description Update an admin organization's contracts
 */
export const updateAdminOrganizationLedgerContracts = (
  organizationId,
  contracts
) => {
  return {
    type: 'UPDATE_ADMIN_ORGANIZATION_LEDGER_CONTRACTS',
    data: {
      organizationId,
      contracts
    }
  };
};

/**
 * addAdminOrganizationContract
 * @description Add a new contract to an organization
 */

export const addAdminOrganizationContract = (organizationId, contract) => {
  return {
    type: 'ADD_ADMIN_ORGANIZATION_CONTRACT',
    data: {
      organizationId,
      contract
    }
  };
};

/**
 * createUpdateOrganizationContractAction
 * @description Update an admin organization's contract
 */

const createUpdateOrganizationContractAction = (organizationId, contract) => {
  return {
    type: 'UPDATE_ADMIN_ORGANIZATION_CONTRACT',
    data: {
      organizationId,
      contract
    }
  };
};

/**
 * addAdminOrganizationContractPeriod
 * @description Add an admin organization contract period
 */

export const addAdminOrganizationContractPeriod = (
  organizationId,
  contractPeriod
) => {
  return {
    type: 'ADD_ADMIN_ORGANIZATION_CONTRACT_PERIOD',
    data: {
      organizationId,
      contractPeriod
    }
  };
};

/**
 * updateAdminOrganizationContractPeriod
 * @description Update an admin organization's contract period
 */

export const updateAdminOrganizationContractPeriod = (
  organizationId,
  contractPeriod
) => {
  return {
    type: 'UPDATE_ADMIN_ORGANIZATION_CONTRACT_PERIOD',
    data: {
      organizationId,
      contractPeriod
    }
  };
};

/**
 * addAdminOrganizationCollectionPermissions
 * @description Add permissions to multiple collections
 */

export const addAdminOrganizationCollectionPermissions = (data) => {
  return {
    type: 'ADD_ADMIN_ORGANIZATION_COLLECTION_PERMISSIONS',
    data
  };
};

/**
 * removeAdminOrganizationCollectionPermissions
 * @description Remove permission from multiple collections
 */

export const removeAdminOrganizationCollectionPermissions = (data) => {
  return {
    type: 'REMOVE_ADMIN_ORGANIZATION_COLLECTION_PERMISSIONS',
    data
  };
};

/**
 * updateAdminOrganizationCollectionPermissions
 * @description Update permissions to collections
 */

export const updateAdminOrganizationCollectionPermissions = (data) => {
  return {
    type: 'UPDATE_ADMIN_ORGANIZATION_COLLECTION_PERMISSIONS',
    data
  };
};

/**
 * fetchAdminPublicCollections
 * @description Fetch public collections for admin
 */
export const fetchAdminPublicCollections = (data) => {
  return {
    type: 'FETCH_ADMIN_PUBLIC_COLLECTIONS',
    data
  };
};

/**
 * updateAdminOrganizationS3Bucket
 * @description Update an admin organization S3 bucket
 */

export const updateAdminOrganizationS3Bucket = (data) => {
  return {
    type: 'UPDATE_ADMIN_ORGANIZATION_S3_BUCKET',
    data
  };
};

/**
 * addAdminOrganizationS3Bucket
 * @description Set the admin organizations S3 bucket
 */

export const addAdminOrganizationS3Bucket = (data) => {
  return {
    type: 'ADD_ADMIN_ORGANIZATION_S3_BUCKET',
    data
  };
};

/**
 * updateAdminUsers
 * @description Set the admin users
 */

export const updateAdminUsers = (data) => {
  return {
    type: 'UPDATE_ADMIN_USERS',
    data
  };
};

/**
 * addAdminUser
 * @description Add/create a new user
 */

export const addAdminUser = (data) => {
  return {
    type: 'ADD_ADMIN_USER',
    data
  };
};

/**
 * deleteAdminUser
 * @description Delete a user
 */

export const deleteAdminUser = (data) => {
  return {
    type: 'DELETE_ADMIN_USER',
    data
  };
};

/**
 * addAdminContractDatashareOrganization
 * @description Add an organization to dataSharedWith for a contract
 */

export const addAdminContractDatashareOrganization = (
  ownerId,
  contractId,
  shareData
) => {
  return {
    type: 'ADD_ADMIN_CONTRACT_DATASHARE_ORGANIZATION',
    data: {
      ownerId,
      contractId,
      shareData
    }
  };
};

/**
 * removeAdminContractDatashareOrganization
 * @description Remove an organization from dataSharedWith for a contract
 */

export const removeAdminContractDatashareOrganization = (
  ownerId,
  contractId,
  orgId
) => {
  return {
    type: 'REMOVE_ADMIN_CONTRACT_DATASHARE_ORGANIZATION',
    data: {
      ownerId,
      contractId,
      orgId
    }
  };
};

/**
 * updateLicenses
 * @description Update available licenses
 */

export const updateLicenses = (data) => {
  return {
    type: 'UPDATE_LICENSES',
    data
  };
};
