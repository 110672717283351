import { useMappedState } from 'redux-react-hook';
import { NEW_SUB_RESELLER_TASK } from 'data/action-types';
import { isEmptyObject } from 'lib/util';

import Task from 'models/task';
import { TASKING_REQUEST_ID } from 'data/property-names';

export default function useSubResellerTask (taskId) {
  const mapState = ({ subreseller }) => subreseller;

  const { tasks, new: newTask } = useMappedState(mapState);
  let task;

  if (taskId === NEW_SUB_RESELLER_TASK) {
    task = { ...newTask };
  } else {
    task = tasks.find(({ properties = {} }) => {
      return `${properties[TASKING_REQUEST_ID]}` === `${taskId}`;
    });
  }

  if (isEmptyObject(task)) {
    return {};
  }

  return new Task().ingestTaskBody(task);
}
