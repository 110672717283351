import React from 'react';
import PropTypes from 'prop-types';

const PageWrapper = ({ children }) => {
  return <>{children}</>;
};

PageWrapper.propTypes = {
  path: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default PageWrapper;
