import React from 'react';
import { Helmet } from 'react-helmet';
import { routePathByName } from 'lib/routes';
import { IconByName } from '../../../components';
import { adminRouteLinks, mapToLinkListItem } from '../../data';
import * as routeNames from '../../data/route-names';
import Layout from 'components/Layout';
import { PageHeader, Container, LinkBlockList } from 'commonLib';

const AdminIndexPage = () => {
  const links = adminRouteLinks().map((link) =>
    mapToLinkListItem(
      link,
      (routeName) => routePathByName(routeNames[routeName]),
      IconByName
    )
  );

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-admin page-admin-index' }}>
        <title>Admin</title>
      </Helmet>
      <Container className="content">
        <PageHeader title="Admin" icon={<IconByName name="FaCog" />} />
        <LinkBlockList links={links} />
      </Container>
    </Layout>
  );
};

export default AdminIndexPage;
