import React from 'react';
import { useDispatch } from 'redux-react-hook';
import { Helmet } from 'react-helmet';
import { MdPersonAdd } from 'react-icons/md';

import {
  putUpdateOrganization,
  triggerNotice
} from 'state/actions';
import { fieldsObjectToValues } from 'lib/util';
import { redirectTo, routePathByName } from 'lib/routes';
import { logError } from 'lib/logger';
import { useOrg, useOrgFetchOrganization } from 'hooks';

import Layout from 'components/Layout';
import Breadcrumbs from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import FormOrganizationEditOrganization from 'components/FormOrganizationEditOrganization';

const PAGE_TITLE = 'Edit Organization';

const OrganizationEditPage = () => {
  const dispatch = useDispatch();

  const { organization = {}, isLoading: fetchOrganizationIsLoading } =
    useOrgFetchOrganization();

  const { actions: orgActions = {} } = useOrg();
  const { putUpdateOrganization: updateAction = {} } = orgActions;
  const { isLoading: updateIsLoading } = updateAction;

  const isLoading = updateIsLoading || fetchOrganizationIsLoading;

  /**
   * handleSubmitForm
   * @description Makes a request to create an org on form submit
   */

  async function handleSubmitForm (e, fields) {
    const organizationData = fieldsObjectToValues(fields);

    try {
      await dispatch(putUpdateOrganization(organizationData));
    } catch (e) {
      logError(e.message, organizationData);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try again!'
        })
      );
      return;
    }

    redirectTo(routePathByName('organizationDetails'));

    dispatch(
      triggerNotice({
        type: 'success',
        weight: 'bold',
        align: 'center',
        text: 'Organization was successfully updated!'
      })
    );
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: 'page-organization page-organization-edit'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Organization',
              to: routePathByName('organization')
            },
            {
              label: 'Details',
              to: routePathByName('organizationDetails')
            }
          ]}
        />
        <PageHeader
          title={PAGE_TITLE}
          icon={<MdPersonAdd className="icon-md" />}
        />
        <FormOrganizationEditOrganization
          organization={organization}
          onSubmit={handleSubmitForm}
          disabled={isLoading}
        />
      </Container>
    </Layout>
  );
};

export default OrganizationEditPage;
