import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import {
  useLocation,
  useResellerTasks,
  useResellerFetchTasks,
  useUser,
  useFetchCollectionTypes
} from 'hooks';
import { routePathByName } from 'lib/routes';
import {
  taskFilterParamsToObject,
  prependDateTimeString,
  getFilterOptions,
  RESELLER_TASKS_TABLE_COLUMNS,
  parseTableColumnsByFlag
} from 'lib/tasks';
import { RESELLER_ROLE } from 'data/roles';
import {
  ROUTE_RESELLER,
  ROUTE_RESELLER_TASKS,
  ROUTE_RESELLER_REPEAT_REQUESTS,
  ROUTE_RESELLER_TASK_DETAILS,
  ROUTE_RESELLER_REPEAT_REQUEST_DETAILS,
  ROUTE_SEARCH
} from 'data/route-names';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';
import { updateResellerTasksColumns } from 'state/actions';

import TemplateTasks from 'templates/TemplateTasks';

const ResellerTasksPage = () => {
  let resellerId;
  const dispatch = useDispatch();

  const { user = {} } = useUser();
  const { tasks = [], columns, totalPages } = useResellerTasks({
    includeSpawned: false,
    includeContractDetails: true
  });

  const { organization = {} } = user;
  const {
    permittedCollectionTiers: { taskingRequest: permittedTiers = [] }
  } = organization;

  const { queryParams } = useLocation();

  const {
    page = 1,
    limit = DEFAULT_PAGING_LIMIT,
    filters = {},
    sort,
    order
  } = queryParams || {};

  // Convert filters & sort in query params to format tasks table can use
  // memoize the obj to avoid unnecessary re-renders
  const filtersParamObject = useMemo(() => {
    return taskFilterParamsToObject(filters);
  }, [filters]);

  const sortObject = useMemo(() => {
    return { sort, order };
  }, [sort, order]);

  let isLoadingUser = true;
  if (organization.id) {
    resellerId = organization.id;
    isLoadingUser = false;
  }

  // use forceNew to make sure we always have a fresh list
  const { isLoading: isLoadingTasks, dispatchTasksCsvByResellerId } =
    useResellerFetchTasks({
      forceNew: true,
      resellerId,
      page,
      limit,
      filters: filtersParamObject,
      sortObject
    });

  const { isLoading: isLoadingCollectionTypes, collectionTypes } = useFetchCollectionTypes();

  /* `isLoading !== false` is a fix for the case when
    useResellerFetchTasks returns `{tasks: []}`
    This happens when the ResellerTasksPage is initializing and
    renders before the UPDATE_USER_ACTION_STATE reducer
    has added actions to tasks entry in the Redux store
  */

  const isLoading = isLoadingTasks || isLoadingUser || isLoadingCollectionTypes;

  const breadcrumbs = [
    {
      label: 'Reseller',
      to: routePathByName(ROUTE_RESELLER)
    }
  ];

  const noTasksActions = [
    {
      to: routePathByName(ROUTE_SEARCH),
      label: 'Go to Search'
    }
  ];

  const routeNames = {
    tasks: ROUTE_RESELLER_TASKS,
    repeatRequests: ROUTE_RESELLER_REPEAT_REQUESTS,
    taskDetails: ROUTE_RESELLER_TASK_DETAILS,
    repeatDetails: ROUTE_RESELLER_REPEAT_REQUEST_DETAILS
  };

  const flags = useFlags();

  // Reset to default tasks table columns order & active state
  const resetColumns = () => {
    dispatch(updateResellerTasksColumns(parseTableColumnsByFlag({ columns: RESELLER_TASKS_TABLE_COLUMNS, flags, organizationId: resellerId })));
  };

  useEffect(() => {
    if (columns && flags.collectionTypeTaskingFormEnabled) {
      dispatch(updateResellerTasksColumns(parseTableColumnsByFlag({ columns, flags, organizationId: resellerId })));
    }
  }, [flags]);

  return (
    <TemplateTasks
      id="tabpanel_0"
      isLoading={isLoading}
      tasks={tasks}
      collectionTypes={collectionTypes}
      paging={{ currentPage: Number(page), totalPages, limit: Number(limit) }}
      filters={filtersParamObject}
      filterOptions={getFilterOptions({ permittedTiers, flags, organizationId: resellerId, collectionTypes })}
      accessType={RESELLER_ROLE}
      emptyActions={noTasksActions}
      activeTab="single"
      breadcrumbs={breadcrumbs}
      routeNames={routeNames}
      tableColumns={columns}
      resetColumns={resetColumns}
      handleColumnsChange={(cols) => dispatch(updateResellerTasksColumns(cols))}
      exportHandler={dispatchTasksCsvByResellerId}
      exportFileName={prependDateTimeString(
        'capella-reseller-task-requests-download.csv'
      )}
    />
  );
};

export default ResellerTasksPage;
