import React from 'react';
import PropTypes from 'prop-types';
import { useLens } from 'fogg/hooks';

import { FaTimes, FaChevronLeft } from 'react-icons/fa';

import { useLocation, useSearch } from 'hooks';
import { AVAILABLE_SEARCH_PARAMS } from 'lib/search';
import { navigateTo, routePathByName } from 'lib/routes';
import { pushSearchHistory } from 'state/actions';
import { useDispatch } from 'redux-react-hook';

import Discover from 'components/Discover';

const TAB_ACTION_CLEAR_SEARCH = 'clear-search';
const TAB_ACTION_BACK_TO_SEARCH = 'back-to-search';

const LensDiscover = ({ tabAction = TAB_ACTION_CLEAR_SEARCH, searchType }) => {
  const { map = {}, geoSearch } = useLens();
  const { enableDrawTool } = map;
  const { isActiveSearch, clearSearch } = geoSearch;
  const dispatch = useDispatch();
  const { actions = {}, last: lastSearch } = useSearch();
  const { clearQuerySearchParams } = useLocation();

  const { resolveLensSearch = {} } = actions;
  const { isLoading } = resolveLensSearch;

  let discoverTabs;

  if ((isActiveSearch || isLoading) && tabAction === TAB_ACTION_CLEAR_SEARCH) {
    const ResetLabel = () => (
      <>
        <FaTimes className="icon-times" /> Reset Search
      </>
    );
    discoverTabs = [
      {
        id: TAB_ACTION_CLEAR_SEARCH,
        Label: ResetLabel,
        onClick: (e) => {
          clearSearch();
          clearQuerySearchParams(AVAILABLE_SEARCH_PARAMS);
          dispatch(pushSearchHistory({ args: {} }));
          navigateTo(routePathByName('search'), { resetSearch: true });
        }
      }
    ];
  } else if (tabAction === TAB_ACTION_BACK_TO_SEARCH) {
    const BackLabel = () => (
      <>
        <FaChevronLeft /> Back to Search
      </>
    );
    discoverTabs = [
      {
        id: TAB_ACTION_BACK_TO_SEARCH,
        Label: BackLabel,
        onClick: () => {
          navigateTo(
            `${routePathByName('search')}${lastSearch ? lastSearch.search : ''}`
          );
        }
      }
    ];
  }

  /**
   * handleOnMarkerClick
   */

  function handleOnMarkerClick () {
    enableDrawTool({
      name: 'marker'
    });
  }

  /**
   * handleOnRectangleClick
   */

  function handleOnRectangleClick () {
    enableDrawTool({
      name: 'rectangle'
    });
  }

  /**
   * handleOnPolygonClick
   */

  function handleOnPolygonClick () {
    enableDrawTool({
      name: 'polygon'
    });
  }

  return (
    <Discover
      tabs={discoverTabs}
      onMarkerClick={handleOnMarkerClick}
      onRectangleClick={handleOnRectangleClick}
      onPolygonClick={handleOnPolygonClick}
      searchType={searchType}
    />
  );
};

LensDiscover.propTypes = {
  tabAction: PropTypes.string,
  searchType: PropTypes.string
};

export default LensDiscover;
