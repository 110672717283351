import { useMappedState } from 'redux-react-hook';

import RepeatRequest from 'models/repeatRequest';

export default function useOrgRepeatRequests ({
  includeContractDetails = false
} = {}) {
  const mapState = ({ org, user }) => ({ org, user });

  const {
    org: { repeatRequests = [], users = [], totalPages, repeatColumns = [] },
    user: {
      user: { organization: { contracts = [] } = {} }
    }
  } = useMappedState(mapState);

  let filteredRepeatRequests = repeatRequests;

  if (includeContractDetails) {
    filteredRepeatRequests = RepeatRequest.mapContractsToTasks(
      filteredRepeatRequests,
      contracts
    );
  }

  return {
    includeContractDetails,
    repeatRequests: RepeatRequest.mapUsersToRepeatRequest(
      filteredRepeatRequests,
      users
    ),
    totalPages,
    repeatColumns
  };
}
