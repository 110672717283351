import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormRow, FormInput } from 'fogg/ui';

import { getAvailableCountries } from 'lib/fields';
import { useUser } from 'hooks';

const countries = getAvailableCountries();

const FormPremiumSignup = ({ onSubmit }) => {
  const { user = {} } = useUser();
  const { address = {} } = user;
  const { country } = address;
  const defaultCountry = countries.filter((c) => c.label === country);

  function handleSubmit (e, fields) {
    if (
      Array.isArray(fields.country.value) &&
      fields.country.value.length > 0
    ) {
      fields.country.value = fields.country.value[0];
    }

    if (typeof onSubmit === 'function') {
      onSubmit(e, fields);
    }
  }

  const validationRules = {
    position: {
      required: true
    },
    country: {
      required: true
    },
    city: {
      required: true
    },
    state: {
      required: true
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} rules={validationRules}>
        <FormRow>
          <FormInput
            id="position"
            label="Organization Position / Title"
            required={true}
          />
        </FormRow>

        <FormRow>
          <FormInput
            id="country"
            label="Country"
            type="select"
            options={countries}
            required={true}
            defaultValue={defaultCountry[0] && defaultCountry[0].value}
          />
        </FormRow>

        <FormRow>
          <FormInput id="city" label="City" required={true} />
          <FormInput id="state" label="State / Province" required={true} />
        </FormRow>

        <FormRow className="form-row-actions">
          <Button>Sign Up for Premium Access</Button>
        </FormRow>
      </Form>
    </>
  );
};

FormPremiumSignup.propTypes = {
  onSubmit: PropTypes.func
};

export default FormPremiumSignup;
