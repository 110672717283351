import React from 'react';

import Layout from 'components/Layout';
import { LoadingSpinner } from 'commonLib';

const RegisterPage = () => {
  /**
   * For now we redirect this page to https://capellaspace.com/community (see gatsby-browser.js)
   */
  return (
    <Layout className="fullscreen" footer={false}>
      <LoadingSpinner isLoading={true} position="vertical-center" size="large" color="gold" />
    </Layout>
  );
};

export default RegisterPage;
