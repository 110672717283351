import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, FormRow, FormInput } from 'fogg/ui';
import { FaTrashAlt } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import { useAdminFetchOrganizations } from '../hooks';

const ContractShareCard = ({
  disabled,
  dataSharedWith = [],
  handleAddOrganization,
  handleRemoveOrganization
}) => {
  const { organizations, loading } = useAdminFetchOrganizations();

  const [selectedOrganization, setSelectedOrganization] = useState();

  const handleChange = option => setSelectedOrganization(option && option.value);
  const handleAddClick = async () => {
    if (typeof handleAddOrganization === 'function') {
      await handleAddOrganization(selectedOrganization);
      setSelectedOrganization(undefined);
    }
  };

  const handleRemoveClick = event => {
    if (typeof handleRemoveOrganization === 'function') {
      const { dataset: { orgid, shareid } } = event.currentTarget;
      handleRemoveOrganization(orgid, shareid);
    }
  };

  const [organizationsOptions, sharedOrganizations] = useMemo(() => {
    const sharedOrgDefs = [];
    const sharedOrgIds = [];
    dataSharedWith.forEach(share => {
      const orgDef = organizations.find(organization => organization.id === share.orgId);
      if (orgDef) {
        sharedOrgIds.push(share.orgId);
        sharedOrgDefs.push({
          ...orgDef,
          shareId: share.id
        });
      }
    });

    const filteredOrgs = organizations.filter(organization => !sharedOrgIds.includes(organization.id));
    const orgOpts = filteredOrgs.map(organization => ({ value: organization.id, label: organization.name }));

    return [orgOpts.sort((a, b) => a.label.localeCompare(b.label)), sharedOrgDefs];
  }, [organizations, dataSharedWith]);

  return (
    <div className="contract-share-card">
      <FormRow className="contract-share-card-title">
        <h2>Contract Data Share</h2>
        <span className="general-tool-tip" data-tip data-for="contractShareTip">
          ?
        </span>
        <ReactTooltip
          id="contractShareTip"
          place="top"
          effect="solid"
          type="dark"
          multiline={true}
          className="customTip"
        >
          <div>
            A Contract Data Share grants other organizations access to data originally purchased by this contract
          </div>
        </ReactTooltip>
      </FormRow>
      <FormRow className="contract-share-card-select">
        <FormInput
          id="add-organization"
          label="Organization"
          key={selectedOrganization}
          type="select"
          options={organizationsOptions}
          clearable={true}
          disabled={loading || disabled}
          onChange={handleChange}
          defaultValue={selectedOrganization}
        />
        <Button disabled={!selectedOrganization || loading || disabled} onClick={handleAddClick}>
          Add Organization
        </Button>
      </FormRow>
      <FormRow className="contract-share-card-list">
        {sharedOrganizations.map(({ id, name, shareId }) => (
          <div key={id} className="contract-share-card-list-item">
            <span>{name}</span>
            <Button
              disabled={loading || disabled}
              type={['button-text danger']}
              onClick={handleRemoveClick}
              data-orgid={id}
              data-shareid={shareId}
            >
              <FaTrashAlt />
            </Button>
          </div>
        ))}
      </FormRow>
    </div>
  );
};

ContractShareCard.propTypes = {
  disabled: PropTypes.bool,
  dataSharedWith: PropTypes.arrayOf(PropTypes.object),
  handleAddOrganization: PropTypes.func,
  handleRemoveOrganization: PropTypes.func
};

export default ContractShareCard;
