class Collection {
  constructor (data) {
    Object.assign(this, data);

    this.stacVersion = this.stac_version;
    this.stacExtensions = this.stac_extensions;
    this._isPublic = true;
  }

  /**
   * getter for extended isPublic property
   *
   * @returns {boolean}
   */
  get isPublic () {
    return this._isPublic;
  }

  /**
   * setter for extended isPublic property
   *
   * @param {boolean} value
   * @returns {void}
   */
  set isPublic (value) {
    this._isPublic = value;
  }
}

export default Collection;
