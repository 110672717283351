import React from 'react';
import PropTypes from 'prop-types';
import { device } from 'fogg/lib';

import {
  useFetchRepeatRequest,
  useFetchRepeatRequestChildTasks,
  useRepeatRequest,
  useRepeatRequests,
  useRepeatRequestChildTasks,
  useUser
} from 'hooks';
import { navigateTo, routePathByName } from 'lib/routes';
import { repeatRequestIdIsValid } from 'lib/data';
import { orgIsArchiveSubOnly } from 'commonLib';

import TemplateRepeatRequest from 'templates/TemplateRepeatRequest';

const { isDomAvailable } = device;

const tasksPath = routePathByName('tasks');

const RepeatRequestPage = ({ repeatRequestId }) => {
  const { actions: repeatRequestActions = {} } = useRepeatRequests();
  const {
    updateRepeatRequestStatusById: updateRepeatRequestStatusByIdAction = {}
  } = repeatRequestActions;
  const { isLoading: isUpdateRepeatRequestStatusByIdLoading } =
    updateRepeatRequestStatusByIdAction;

  const repeatRequest = useRepeatRequest(repeatRequestId) || {
    id: repeatRequestId
  };
  const { repeatRequestChildTasks } = useRepeatRequestChildTasks();

  const { isLoading: repeatRequestIsLoading } = useFetchRepeatRequest({
    repeatRequestId,
    forceNew: true
  });
  const { isLoading: isChildTasksLoading } = useFetchRepeatRequestChildTasks({
    repeatRequestId,
    forceNew: true
  });

  const { user: { organization = {} } = {}, isLoading: userIsLoading } = useUser();
  const orgIsArchSubOnly = orgIsArchiveSubOnly(organization);

  // If we don't have a valid ID, navigate back to our tasks list page
  if (!repeatRequestIdIsValid(repeatRequestId)) {
    if (isDomAvailable()) navigateTo(tasksPath);
    return null;
  }

  const repeatRequestState = {
    isLoading: repeatRequestIsLoading || isUpdateRepeatRequestStatusByIdLoading || userIsLoading,
    isChildTasksLoading
  };

  return (
    <TemplateRepeatRequest
      repeatRequest={repeatRequest}
      state={repeatRequestState}
      orgIsArchSubOnly={orgIsArchSubOnly}
      repeatRequestChildTasks={repeatRequestChildTasks}
    />
  );
};

RepeatRequestPage.propTypes = {
  repeatRequestId: PropTypes.string
};

export default RepeatRequestPage;
