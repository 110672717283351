import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import Reducers from './reducers';

import { loadState, syncStateToStorage } from 'lib/storage';

const isDevelopment =
  process.env.NODE_ENV === 'development' && typeof window !== 'undefined';

// via https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux

const middlewares = [thunk];

if (isDevelopment) {
  const logger = createLogger({
    collapsed: true
  });

  middlewares.push(logger);
}

const composeEnhancers =
  typeof window !== 'undefined' &&
  isDevelopment &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const createStore = () => {
  const persistedState = loadState();
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = reduxCreateStore(Reducers, persistedState, enhancer);
  store.subscribe(() => syncStateToStorage(store));

  return store;
};

export default createStore;
