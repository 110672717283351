import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'fogg/ui';
import { RepeatRequestEndTypes } from 'data/repeat-request-end-types';
import { TaskCollectModes } from 'data/task-collect-modes';
import { formatDateTime, formatDuration } from 'lib/datetime';
import {
  DEFAULT_LOOK_DIRECTION,
  DEFAULT_ASC_DSC,
  DEFAULT_PRODUCT_CATEGORY
} from 'lib/tasks';
import { TaskCollectionTiers } from '../../common-web-lib/src/data/task-collection-tiers';

const RepeatTaskConfigTable = ({
  className,
  task = {},
  showExtraFields = true,
  showRepeatCycle = false,
  collectionTypesEnabled = false
}) => {
  const {
    collectMode,
    collectionType,
    preApproval,
    orbitalPlanes,
    ascDsc,
    lookDirection,
    productCategory,
    collectionTier,
    archiveHoldback,
    collectConstraints = {},
    repeatRequestEndType,
    repetitionProperties: {
      repeatStart,
      repeatEnd,
      repetitionInterval,
      repetitionCount,
      maintainSceneFraming,
      lookAngleTolerance
    } = {}
  } = task;

  const { polarization, targetHeight, localTime } = collectConstraints;

  function removeTaskDisplayData (name) {
    const dataIndex = data.findIndex(item => item?.property?.props?.children === name);
    if (dataIndex !== -1) {
      data.splice(dataIndex, 1);
    }
  }

  const columns = [
    {
      columnId: 'property'
    },
    {
      columnId: 'value'
    }
  ];

  const data = [
    {
      property: <p>{`${collectionTypesEnabled ? 'Collect' : 'Imaging'} Mode`}</p>,
      value: <p className="text-capitalize">
        {
          collectMode
            ? TaskCollectModes.properties[collectMode].label
            : 'Loading'
        }
      </p>
    },
    {
      property: <p>Collection Type</p>,
      value: <p>{collectionType}</p>
    },
    {
      property: <p>Pre-Approval</p>,
      value: <p>{preApproval ? 'Yes' : 'No'}</p>
    },
    {
      property: <p>Repeat Task Start (UTC)</p>,
      value: <p>{formatDateTime(repeatStart)}</p>
    },
    {
      property: (
        <p>
          Repeat Task End{' '}
          {repeatRequestEndType === RepeatRequestEndTypes.DATE ? '(UTC)' : ''}
        </p>
      ),
      value: (
        <p>
          {repeatRequestEndType === RepeatRequestEndTypes.DATE &&
            formatDateTime(repeatEnd)}
          {repeatRequestEndType === RepeatRequestEndTypes.COUNT &&
            `After ${repetitionCount} collects`}
          {repeatRequestEndType === RepeatRequestEndTypes.INDEFINITE &&
            RepeatRequestEndTypes.properties[RepeatRequestEndTypes.INDEFINITE]
              .label}
        </p>
      )
    },
    {
      property: <p>Repeat Task Cycle</p>,
      // repeatTaskDuration is measured in days, formatDuration function needs seconds as an arg
      value: <p>{formatDuration(repetitionInterval * 86400)}</p>
    },
    {
      property: <p>Maintain Scene Framing</p>,
      value: <p>{maintainSceneFraming ? 'Yes' : 'No'}</p>
    },
    {
      property: <p>Collection Time</p>,
      value: <p style={{ textTransform: 'capitalize' }}>
        {localTime}
      </p>
    },
    {
      property: <p>{`${collectionTypesEnabled ? 'Collection' : 'Tasking'} Tier`}</p>,
      value: <p>{TaskCollectionTiers.properties[collectionTier]?.label || '-'}</p>
    },
    {
      property: <p>Orbital Plane</p>,
      value: <p>{orbitalPlanes?.join(', ') || '-'}</p>
    },
    {
      property: <p>Orbit State</p>,
      value: <p>{ascDsc === DEFAULT_ASC_DSC ? '-' : ascDsc}</p>
    },
    {
      property: <p>Observation Direction</p>,
      value: (
        <p>{lookDirection === DEFAULT_LOOK_DIRECTION ? '-' : lookDirection}</p>
      )
    },
    {
      property: <p>Polarization</p>,
      value: <p>{polarization || '-'}</p>
    }
  ];

  if (maintainSceneFraming) {
    data.push({
      property: <p>Look Angle Tolerance</p>,
      value: <p>{lookAngleTolerance}</p>
    });
  }

  if (!showRepeatCycle) {
    removeTaskDisplayData('Repeat Task Cycle');
    removeTaskDisplayData(`${collectionTypesEnabled ? 'Collection' : 'Tasking'} Tier`);
  }

  if (!collectionTypesEnabled) {
    removeTaskDisplayData('Collection Type');
  }

  if (showExtraFields) {
    data.push({
      property: <p>Target Height</p>,
      value: <p>{Number.isInteger(targetHeight) ? targetHeight : '-'}</p>
    });

    data.push({
      property: <p>Archive Holdback</p>,
      value: <p>{archiveHoldback || '-'}</p>
    });

    if (!collectionTypesEnabled) {
      data.push({
        property: <p>Product Category</p>,
        value: (
          <p>
            {productCategory === DEFAULT_PRODUCT_CATEGORY ? '-' : productCategory}
          </p>
        )
      });
    }

    // If user has supplied custom collect constraints via sliders display them
    if (productCategory === 'custom' && collectConstraints) {
      const {
        azrMin,
        azrMax,
        grrMin,
        grrMax,
        offNadirMin,
        offNadirMax,
        imageLength,
        imageWidth,
        numLooks
      } = collectConstraints;
      if (offNadirMin && offNadirMax) {
        data.push({
          property: <p>Look Angle</p>,
          value: (
            <p>
              {offNadirMin}° - {offNadirMax}°
            </p>
          )
        });
      }
      if (grrMin && grrMax) {
        data.push({
          property: <p>Ground Range Resolution</p>,
          value: (
            <p>
              {grrMin}m - {grrMax}m
            </p>
          )
        });
      }
      if (azrMin && azrMax) {
        data.push({
          property: <p>Azimuth Resolution</p>,
          value: (
            <p>
              {azrMin}m - {azrMax}m
            </p>
          )
        });
      }
      if (imageLength) {
        data.push({
          property: <p>Image Length</p>,
          value: <p>{imageLength / 1000}km</p>
        });
      }
      if (imageWidth) {
        data.push({
          property: <p>Image Width</p>,
          value: <p>{imageWidth / 1000}km</p>
        });
      }
      if (numLooks) {
        data.push({
          property: <p>Number of Looks</p>,
          value: <p>{numLooks}</p>
        });
      }
    }
  }

  const hasClassName = className !== undefined ? className : '';
  const padDurationRow =
    formatDuration(repetitionInterval).indexOf('Month') !== -1
      ? ' task-config-padded'
      : '';

  return (
    <Table
      displayHeader={false}
      fixHeightToContent={true}
      className={hasClassName + padDurationRow}
      columns={columns}
      data={data}
      rowHeight={50}
      defaultHeight={250}
    />
  );
};

RepeatTaskConfigTable.propTypes = {
  task: PropTypes.object.isRequired,
  className: PropTypes.string,
  showExtraFields: PropTypes.bool,
  showRepeatCycle: PropTypes.bool,
  collectionTypesEnabled: PropTypes.bool
};

export default RepeatTaskConfigTable;
