import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useOrg } from 'hooks';

import { logError } from 'lib/logger';
import { triggerNotice, fetchAllOrgOrders } from 'state/actions';

export default function useOrgFetchOrders ({ orgId, forceNew = false } = {}) {
  const { actions = {}, orders } = useOrg();
  const { fetchAllOrgOrders: fetchAllOrgOrdersAction = {} } = actions;
  const { firstLoad } = fetchAllOrgOrdersAction;
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstLoad && !forceNew) return;
    if (!orgId) return;
    dispatch(fetchAllOrgOrders(orgId)).catch((error) => {
      logError(error.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
    });
  }, [dispatch, firstLoad, forceNew, orgId]);

  return {
    ...actions.fetchAllOrgOrders,
    orders
  };
}
