import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'redux-react-hook';

import {
  TASK_ACCESS_REQUEST_STATUS_COMPLETED,
  TASK_ACCESS_REQUEST_STATUS_ERROR,
  ACCESS_SEQUENCE_MESSAGE_DEFAULT,
  ACCESS_SEQUENCE_MESSAGE_NOT_POINT,
  ACCESS_SEQUENCE_MESSAGE_CREATING,
  ACCESS_SEQUENCE_MESSAGE_CHECKING_STATUS,
  ACCESS_SEQUENCE_MESSAGE_FETCHING_ACCESSES,
  ACCESS_SEQUENCE_MESSAGE_SUCCESS_PART_1,
  ACCESS_SEQUENCE_MESSAGE_SUCCESS_PART_2,
  ACCESS_SEQUENCE_MESSAGE_ERROR,
  ACCESS_SEQUENCE_STATUS_READY,
  ACCESS_SEQUENCE_STATUS_LOADING,
  ACCESS_SEQUENCE_STATUS_ERROR,
  ACCESS_SEQUENCE_NOT_FOUND_ERROR,
  ACCESS_SEQUENCE_WINDOW_CLOSE_ERROR,
  ACCESS_SEQUENCE_MESSAGE_FETCHING_FOOTPRINTS
} from 'data/access-requests';
import { logError } from 'lib/logger';
import { sortByDateKey } from 'lib/util';
import { useUser, useCancellablePromise } from 'hooks';
import {
  triggerError,
  createAccessRequest,
  getAccessRequestById,
  getAccessesByRequestId,
  fetchAccessTilesById
} from 'state/actions';
import { getNumberOfDays } from 'commonLib';
import { AVAILABLE_COLLECT_TIMES } from 'lib/tasks';

const ACCESS_REQUEST_STATUS_POLL_INTERVAL = 2000;
const DEFAULT_ACCESSES = undefined;

/**
 * Hook that fetches and returns satellite access times based on tasking params
 * @param {object} geoJson
 * @param {number} windowOpen
 * @param {number} windowClose
 * @param {string} localTime
 * @param {number} accessRequestOffNadirMin
 * @param {number} accessRequestOffNadirMax
 * @param {string} accessRequestAscending
 * @param {string} accessRequestLookDirection
 * @param {array} accessRequestOrbitalPlanes
 * @param {number} imageLength
 * @param {number} imageWidth
 * @param {string} taskRequestType
 * @param {number} refreshCounter
 */

export default function useAccessRequests ({
  geoJson,
  windowOpen,
  windowClose,
  localTime,
  accessRequestOffNadirMin,
  accessRequestOffNadirMax,
  accessRequestAscending,
  accessRequestLookDirection,
  accessRequestOrbitalPlanes,
  imageLength,
  imageWidth,
  taskRequestType,
  refreshCounter
} = {}) {
  const dispatch = useDispatch();
  const [status, updateStatus] = useState(ACCESS_SEQUENCE_STATUS_READY);

  // Different AbortController for the various steps
  const initialController = useRef(new AbortController());
  const pollController = useRef(new AbortController());
  const fetchController = useRef(new AbortController());

  const { user = {} } = useUser();
  const [message, setMessage] = useState(ACCESS_SEQUENCE_MESSAGE_DEFAULT);
  const { cancellablePromise } = useCancellablePromise();
  const [accessRequest, setAccessRequest] = useState();
  const { properties = {} } = accessRequest || {};
  const { accessrequestId, processingStatus } = properties;

  const [accesses, setAccesses] = useState(DEFAULT_ACCESSES);

  const [accessRequestData, setAccessRequestData] = useState();
  const [accessStatus, setAccessStatus] = useState();

  const geometryTypes =
    geoJson && geoJson.features?.map(({ geometry } = {}) => geometry.type);
  const isPoint = geometryTypes?.includes('Point');

  // Restrict windowClose value to 30 days from windowOpen
  const daysFromWindowOpen = getNumberOfDays(windowOpen, windowClose);
  let windowCloseValue = new Date(windowClose);
  if (daysFromWindowOpen > 30) {
    // Add 30 days to window open
    windowCloseValue = new Date(windowOpen).setDate(new Date(windowOpen).getDate() + 30);
  }

  // accessrequests only supports windowClose within 90 days of today
  const daysFromToday = getNumberOfDays(new Date(), windowClose);
  const invalidWindowClose = daysFromToday > 90 || daysFromToday < -90;
  // Set up our accesses to return them as a ready-to-use array that's
  // also sorted by window open date

  let activeAccesses = [];

  if (accesses) {
    activeAccesses = Object.keys(accesses).map((accessId) => {
      return { ...accesses[accessId] };
    });
    activeAccesses = sortByDateKey(activeAccesses, 'windowOpen');
  }

  // At the start of every page load, we want to try to create a new
  // Access Request that we'll use to provide details about when
  // the request might be fulfilled

  const newAccessRequestKey = `${JSON.stringify(geoJson)}${taskRequestType}${refreshCounter}`;

  useEffect(() => {
    // Currently, Capella only supports Point type payloads for Access Requests
    if (!isPoint) {
      updateStatus(ACCESS_SEQUENCE_STATUS_ERROR);
      setMessage(ACCESS_SEQUENCE_MESSAGE_NOT_POINT);
      return;
    }
    // Prevent fetching access requests that aren't within 90 days of today
    if (invalidWindowClose) {
      updateStatus(ACCESS_SEQUENCE_STATUS_ERROR);
      setMessage(ACCESS_SEQUENCE_WINDOW_CLOSE_ERROR);
      return;
    }

    updateStatus(ACCESS_SEQUENCE_STATUS_LOADING);
    setMessage(ACCESS_SEQUENCE_MESSAGE_CREATING);

    let requestBody = { ...geoJson };

    if (requestBody.features) {
      requestBody = {
        ...requestBody.features[0]
      };
    }

    requestBody.properties = {
      ...requestBody.properties,
      windowOpen: windowOpen && new Date(windowOpen).toISOString(),
      windowClose: windowClose && new Date(windowCloseValue).toISOString(),
      accessConstraints: {
        offNadirMin: accessRequestOffNadirMin,
        offNadirMax: accessRequestOffNadirMax,
        imageLength,
        imageWidth,
        localTime: AVAILABLE_COLLECT_TIMES.apiMap[localTime]
      }
    };

    if (accessRequestAscending) {
      requestBody.properties.accessConstraints.ascDsc = accessRequestAscending;
    } else {
      delete requestBody.properties.accessConstraints.ascDsc;
    }
    if (accessRequestLookDirection) {
      requestBody.properties.accessConstraints.lookDirection =
        accessRequestLookDirection;
    } else {
      delete requestBody.properties.accessConstraints.lookDirection;
    }
    if (accessRequestOrbitalPlanes?.length) {
      requestBody.properties.accessConstraints.orbitalPlanes =
        accessRequestOrbitalPlanes;
    } else {
      delete requestBody.properties.accessConstraints.orbitalPlanes;
    }

    async function request () {
      try {
        const data = await cancellablePromise(dispatch(createAccessRequest({
          geoJson: requestBody,
          user
        })));
        setAccessRequest(data);
      } catch (e) {
        // Unless Request cancelled bc user navigated away or switched tab
        if (!e.isCancelled) {
          updateStatus(ACCESS_SEQUENCE_STATUS_ERROR);
          setMessage(ACCESS_SEQUENCE_MESSAGE_ERROR);
          logError(e.message);
          dispatch(triggerError());
        }
      }
    }

    request();
    setAccesses(DEFAULT_ACCESSES);
  }, [newAccessRequestKey]); // eslint-disable-line

  // Once we have a valid Access Request ID, poll for the status until Completed

  async function getAccessRequestStatus (controller) {
    let status;
    let response;
    try {
      response = await cancellablePromise(dispatch(getAccessRequestById({
        id: accessrequestId
      }, controller)));
      setAccessRequestData(response);
      status = response?.properties?.accessibilityStatus;
      setAccessStatus(status);
    } catch (e) {
      // Unless Request cancelled bc user navigated away or switched tab
      if (!e.isCancelled && e.message !== 'canceled') {
        updateStatus(ACCESS_SEQUENCE_STATUS_ERROR);
        setMessage(ACCESS_SEQUENCE_MESSAGE_ERROR);
        logError(e.message);
        dispatch(triggerError());
      }
    }
    if (status === 'inaccessible' || status === 'rejected') {
      updateStatus(ACCESS_SEQUENCE_STATUS_ERROR);
      setMessage(ACCESS_SEQUENCE_NOT_FOUND_ERROR);
      logError(response.properties.accessibilityMessage);
    } else if (status === TASK_ACCESS_REQUEST_STATUS_ERROR) {
      updateStatus(ACCESS_SEQUENCE_STATUS_ERROR);
      setMessage(ACCESS_SEQUENCE_MESSAGE_ERROR);
      logError(response.properties.accessibilityMessage);
      dispatch(triggerError());
    } else {
      setAccessRequest(response);
    }
  }

  // Inital access request status check...
  useEffect(() => {
    if (!accessrequestId) return;
    // Reset abort controller for each new request
    initialController.current = new AbortController();

    setMessage(ACCESS_SEQUENCE_MESSAGE_CHECKING_STATUS);
    getAccessRequestStatus(initialController.current);
    // Cleanup to abort previous async requests
    return () => {
      if (initialController.current) {
        initialController.current.abort();
      }
    };
  }, [accessrequestId]);

  // After initial status check above, keep polling at an interval until COMPLETE
  useEffect(() => {
    const status = accessRequestData?.properties?.processingStatus;
    const isError = status === TASK_ACCESS_REQUEST_STATUS_ERROR;
    const isComplete = status === TASK_ACCESS_REQUEST_STATUS_COMPLETED;

    if (!accessRequestData || isComplete || isError) return;
    // Reset abort controller for each new request
    pollController.current = new AbortController();

    // Clear timeout on unmount
    const requestTimeout = setTimeout(() =>
      getAccessRequestStatus(pollController.current), ACCESS_REQUEST_STATUS_POLL_INTERVAL);

    return () => {
      clearTimeout(requestTimeout);
      // Cleanup to abort previous async requests
      if (pollController.current) {
        pollController.current.abort();
      }
    };
  }, [accessRequestData, accessStatus]);

  // Once our Access Request gets to a completed state, we want to get
  // all the Accesses that the request would generate so that we can visualize
  // those on the UI

  useEffect(() => {
    if (!accessrequestId) return;
    if (processingStatus !== TASK_ACCESS_REQUEST_STATUS_COMPLETED) return;

    setMessage(ACCESS_SEQUENCE_MESSAGE_FETCHING_ACCESSES);
    let accesses;
    async function fetchAccesses (controller) {
      // 1. Get Accesses
      try {
        const response = await cancellablePromise(dispatch(getAccessesByRequestId({
          id: accessrequestId
        }, controller)));
        const { data } = response || {};
        accesses = data;
      } catch (e) {
        // Unless Request cancelled bc user navigated away or switched tab
        if (!e.isCancelled && e.message !== 'canceled') {
          updateStatus(ACCESS_SEQUENCE_STATUS_ERROR);
          setMessage(ACCESS_SEQUENCE_MESSAGE_ERROR);
          logError(e.message);
        }
      }
    }

    async function fetchAccessTiles (controller) {
      try {
        setMessage(ACCESS_SEQUENCE_MESSAGE_FETCHING_FOOTPRINTS);
        // 2. Get tiles associated with each access
        const tiles = await cancellablePromise(dispatch(
          fetchAccessTilesById(accessrequestId, controller)
        ));
        if (tiles?.length) {
          // Add geometry from tiles to previously fetched accesses array
          accesses = accesses.map(access => {
            const targetTile = tiles.find(tile => tile?.properties?.tileId === access.tileId);
            if (targetTile) {
              access.properties = targetTile.properties;
              access.geometry = targetTile.geometry;
            }
            return access;
          });

          // 3. Create a store of access times grouped by accessId
          const accessesData = accesses.reduce((accumulator, currentValue) => {
            const { accessId } = currentValue;
            accumulator[accessId] = currentValue;
            return accumulator;
          }, {});
          // Display Max of 30 days
          const daysToDisplay = daysFromWindowOpen > 30 ? 30 : daysFromWindowOpen;
          const messaging = ACCESS_SEQUENCE_MESSAGE_SUCCESS_PART_1 + daysToDisplay + ACCESS_SEQUENCE_MESSAGE_SUCCESS_PART_2;
          setAccesses(accessesData);
          updateStatus(ACCESS_SEQUENCE_STATUS_READY);
          setMessage(messaging);
        }
      } catch (e) {
        // Unless Request cancelled bc user navigated away or switched tab
        if (!e.isCancelled && e.message !== 'canceled') {
          logError(e.message);
        }
      }
    }
    // Make sure we can cancel out of the above chained requests
    async function fetchAccessesAndTiles () {
      // Reset abort controller for each new request
      fetchController.current = new AbortController();
      await cancellablePromise(fetchAccesses(fetchController.current));
      await cancellablePromise(fetchAccessTiles(fetchController.current));
    }
    fetchAccessesAndTiles();

    // Cleanup to abort previous async requests
    return () => {
      if (fetchController.current) {
        fetchController.current.abort();
      }
    };
  }, [accessrequestId, processingStatus]);

  return {
    accesses: activeAccesses,
    message,
    status,
    geometryTypes
  };
}
