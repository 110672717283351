import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useSubReseller, useReseller, useUser } from 'hooks';

import { errorMessageByCode } from 'lib/errors';
import { logError } from 'lib/logger';
import { subResellerFetchOrganizationById, triggerNotice, fetchParentOrganizationById } from 'state/actions';

const errorBase = 'Failed to fetch subreseller organization';

export default function useSubResellerFetchOrganization ({ id } = {}) {
  const dispatch = useDispatch();

  const { user = {} } = useUser();
  const { actions = {}, organization } = useSubReseller();
  const { actions: resellerActions = {} } = useReseller();

  const { ledgerBillingEnabled = false } = organization;

  if (typeof id !== 'string') {
    throw new Error(`${errorBase}: Invalid ID ${id}`);
  }

  const hasAdminPermissions = user.isAdmin();

  const { resellerId, subResellerId, type } = organization;

  let parentOrgId;
  if (subResellerId && type !== 'reseller') {
    parentOrgId = subResellerId;
  } else if (resellerId) {
    parentOrgId = resellerId;
  }

  const [parentOrg, setParentOrg] = useState();

  /**
   * fetch the parent reseller org if present
   */

  function fetchParentOrganization (organizationId) {
    dispatch(fetchParentOrganizationById(organizationId)).then(res => {
      setParentOrg(res);
    }).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  useEffect(() => {
    if (!parentOrgId || !hasAdminPermissions) return;
    fetchParentOrganization(parentOrgId);
  }, [parentOrgId, hasAdminPermissions]);

  /**
   * fetchOrganization
   */

  function fetchOrganization (organizationId) {
    dispatch(subResellerFetchOrganizationById(organizationId, {
      eagerLedgerIncludes: ledgerBillingEnabled
    })).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchOrganization = useCallback(() => {
    fetchOrganization(id);
  }, [id]);

  useEffect(() => {
    memoizedFetchOrganization();
  }, [memoizedFetchOrganization]);

  return {
    subResellerFetchOrganizationById: memoizedFetchOrganization,
    ...actions.subResellerFetchOrganizationById,
    organization,
    parentOrganization: parentOrg,
    isLoadingParent: resellerActions.fetchParentOrganizationById?.isLoading
  };
}
