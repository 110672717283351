import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useOrg } from '../hooks';
import { logError } from '../lib/logger';
import { fetchOrgS3BucketById, triggerNotice } from '../state/actions';

export default function useOrgFetchS3Bucket ({ orgId } = {}) {
  const { s3bucket = [], actions = {} } = useOrg();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!orgId) return;

    dispatch(fetchOrgS3BucketById(orgId)).catch((error) => {
      logError(error.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
    });
  }, [dispatch, orgId]);

  return {
    ...actions.fetchOrgS3BucketById,
    s3bucket
  };
}
