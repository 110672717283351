import React from 'react';

import {
  useResellerFetchUsers,
  useUser,
  useReseller
} from 'hooks';
import {
  ROUTE_RESELLER,
  ROUTE_RESELLER_USERS_CREATE,
  ROUTE_RESELLER_USERS_DETAILS
} from 'data/route-names';
import { routePathByName } from 'lib/routes';

import { TemplateUsers, RESELLER_COLUMNS } from 'commonLib';

const breadcrumbs = [
  {
    label: 'Reseller',
    to: routePathByName(ROUTE_RESELLER)
  }
];

const ResellerUsersPage = () => {
  let isLoadingUser;

  const { user = {} } = useUser();
  const { users: resellerUsers = [] } = useReseller();

  if (!user.organizationId) {
    isLoadingUser = true;
  }

  // Determine if we're in a loading state between the various requests
  const { isLoading: isLoadingResellerUsers } = useResellerFetchUsers({
    resellerId: user.organizationId
  });

  const isLoading = isLoadingUser || isLoadingResellerUsers;

  const usersState = {
    isLoading
  };

  const templateOptions = {
    detailsRouteName: ROUTE_RESELLER_USERS_DETAILS,
    createRouteName: ROUTE_RESELLER_USERS_CREATE
  };

  return (
    <TemplateUsers
      users={resellerUsers}
      breadcrumbs={breadcrumbs}
      state={usersState}
      options={templateOptions}
      tableColumns={RESELLER_COLUMNS}
    />
  );
};

export default ResellerUsersPage;
