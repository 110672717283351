import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { FaSpinner } from 'react-icons/fa';
import { BsCart4 } from 'react-icons/bs';
import { Button, Notice } from 'fogg/ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { useCart, useOrders, useSearch, useUser } from 'hooks';
import { formatDateTime } from 'commonLib/src/lib/datetime';
import {
  addCartItem,
  removeCartItem,
  postReviewOrder,
  setContract,
  triggerNotice,
  addAdditionalCartItem,
  searchCatalog
} from 'state/actions';
import { routePathByName, navigateTo } from 'lib/routes';
import { logError } from 'lib/logger';
import { isEmptyObject } from 'lib/util';
import { TaskCollectModes } from 'data/task-collect-modes';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';
import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from 'commonLib';
import Order from 'models/order';

import ItemCard from 'components/ItemCard';
import CostBreakdown from 'components/CostBreakdown';
import ContractsSelect from 'components/ContractsSelect';

/**
 * Shared Cart template, used in FloatingCart and /cart/view page
 * @param {object} props
 * @param {function} [props.hideFloatingCart]
 * @param {object} [props.cartObject={}]
 * @param {boolean} [props.floatingCart=false]
 */
const TemplateCart = ({
  hideFloatingCart,
  cartObject = {},
  floatingCart = false
}) => {
  const dispatch = useDispatch();
  const {
    items,
    contract: cartContract = {},
    groupedItems,
    addOnItems = []
  } = useCart();
  const { user: { organization = {} } = {}, isLoading: userIsLoading } = useUser();
  const flags = useFlags();

  let contracts = organization.contracts?.map(
    ({ id, label, type, isDefault }) => ({
      value: id,
      label,
      type,
      isDefault
    })
  );

  const checkSelectedContractArchSub = () => !!(
    organization.contracts?.find(({ id }) => id === cartContract.contractId)?.type ===
    CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION
  );

  const [selectedContractIsArchSub, setSelectedContractIsArchSub] = useState(checkSelectedContractArchSub);

  useEffect(() => {
    setSelectedContractIsArchSub(checkSelectedContractArchSub);
  }, [organization.contracts]);

  if (addOnItems.length && addOnItems.find((item) => item.productType === 'VS')) {
    contracts = contracts.filter(
      ({ type }) => type !== CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION
    );
  }

  useEffect(() => {
    const defaultContract = organization.contract;
    const cartContractId = cartContract.contractId;
    const orgContract = organization.contracts?.find(({ id }) => id === cartContractId);

    if ((!cartContractId || !orgContract) && defaultContract) {
      dispatch(
        setContract({
          contractId: defaultContract.id,
          contractLabel: defaultContract.label
        })
      );
    }
  }, [organization.contract]);

  const { lineItems, resetOrdersVerified, order } = cartObject;

  const { last: lastSearch } = useSearch();
  const { actions: orderActions = {} } = useOrders();

  const [proceedError, setProceedError] = useState(false);

  const { postReviewOrder: postReviewOrderAction = {} } = orderActions;
  const { isLoading: postReviewOrderIsLoading } = postReviewOrderAction;
  const isLoading = postReviewOrderIsLoading || userIsLoading;
  const [catalogSearchLoading, setCatalogSearchLoading] = useState(false);

  const previouslySearched = lastSearch && lastSearch.search;
  const lastSearchPath =
    previouslySearched && `${routePathByName('search')}${lastSearch.search}`;

  const analyticProductTypeMapping = new Map(
    AnalyticProducts.Types.map((obj) => [obj.value, obj.requiredProductTypes])
  );

  /**
   * event handlers
   */

  async function handleReviewOrder () {
    let newOrder;

    if (!cartContract.contractId) {
      setProceedError(true);
      return;
    }
    setProceedError(false);

    if (isEmptyObject(order) || !order.id) {
      newOrder = new Order({
        orderId: 'NEW_ORDER',
        contractId: cartContract.contractId,
        contractLabel: cartContract.contractLabel
      });
      newOrder.addItems(items);
    } else {
      newOrder = order;
      // Keeping item consistency between cart and order
      newOrder.items = items;
      newOrder.contractId = cartContract.contractId;
      newOrder.contractLabel = cartContract.contractLabel;
    }

    // Include items added via "Add Product" button in cart
    // These will need to use the new /orders/review format as shown below
    if (addOnItems.length) {
      newOrder.addOnItems = addOnItems;
    }
    try {
      await dispatch(postReviewOrder(newOrder));
    } catch (error) {
      logError(error.message, newOrder);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Please try again!'
        })
      );
      return;
    }

    navigateTo(routePathByName('cartReview'));
  }

  function handleRemoveItem (item) {
    dispatch(removeCartItem(item));
    // Removed something from cart, re-run pre-flight contract check
    resetOrdersVerified();
  }

  function handleContractChange (value) {
    setProceedError(false);

    const currentContract = contracts.find(
      (contract) => contract.value === value
    );

    setSelectedContractIsArchSub(
      currentContract?.type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION
    );

    dispatch(
      setContract({
        contractId: value,
        contractLabel: currentContract?.label
      })
    );
  }

  // These are "add on" items that user added directly from cart for a given collect
  async function handleAddAdditionalItem (productType = '', collectId = '') {
    dispatch(
      addAdditionalCartItem({
        productType: productType,
        collectId
      })
    );

    // If analytic item is added, fetch then add to cart & order any required associated product type
    if (AnalyticProducts.isAnalytic(productType) && groupedItems[collectId]?.length) {
      /** @type {Array<string>} */
      const requiredProductTypes = analyticProductTypeMapping.get(productType);
      const includedProductTypes = groupedItems[collectId].map(i => i.productType);
      const missingProductTypes = requiredProductTypes.filter(p => !includedProductTypes.includes(p));
      if (missingProductTypes.length > 0) {
        setCatalogSearchLoading(true);
        // Asynchronously search catalog for the collect's associated base type for the analytic
        const { features = [] } = await dispatch(
          searchCatalog({
            query: {
              'capella:collect_id': { eq: collectId },
              'sar:product_type': { in: missingProductTypes }
            }
          })
        );

        if (features.length && features[0].feature) {
          const derivedFromImage = features[0].feature;
          // Add derived from Image item to cart
          dispatch(addCartItem(derivedFromImage));
        }
        setCatalogSearchLoading(false);
      }
    }
  }

  const hasItems = items?.length && groupedItems && Object.keys(groupedItems).length > 0;
  let formattedItems;
  // Transform grouped Items to a format CostBreakdown can easily use
  if (hasItems) {
    formattedItems = Object.keys(groupedItems).map((itemId) => {
      const currentItems = groupedItems[itemId];
      let collectMode;
      if (currentItems[0].properties) {
        collectMode = currentItems[0].properties['sar:instrument_mode'];
      }
      // We have to get the freshness from /review lineItems
      let freshness;
      if (lineItems) {
        const matchingLineItem = lineItems.find((i) => i.collectId === itemId);
        if (matchingLineItem) freshness = matchingLineItem.freshnessOption;
      }

      // Don't show the "Add Product" button if already added
      const addOnItemsProducts = addOnItems.filter((i) => i.collectId === itemId).map((j) => j.productType);
      const additionableProducts = ['VS', 'VC'].filter(p => !addOnItemsProducts.includes(p));
      // ... or if Vessel (VS, VC) was already added
      const enabledAnalyticsOptions = AnalyticProducts.Types
        .filter(o => o.orgEnabledFlagId === undefined || organization[o.orgEnabledFlagId])
        .filter(o => o.globalEnabledFlagId === undefined || flags[o.globalEnabledFlagId])
        .filter(o => selectedContractIsArchSub ? o.value !== 'VS' : true)
        .map(o => o.value);

      const showAddButton = enabledAnalyticsOptions
        .filter(p => additionableProducts.includes(p)).length > 0;

      return {
        itemId,
        collection: currentItems[0]?.feature?.collection,
        collectDate: formatDateTime(currentItems[0].endTime),
        imagingMode: TaskCollectModes.properties[collectMode]?.label,
        thumbnail: currentItems[0].thumbnail,
        freshness: freshness,
        showAddProductButton: showAddButton,
        children: groupedItems[itemId].map((item, index) => {
          return (
            <ItemCard
              key={`Cart-Item-${itemId}-${index}`}
              item={item}
              isChild={true}
              className={item.productType}
              actionSettings={{
                isRemovable: true,
                isDisabled:
                  AnalyticProducts.hasAnalytics(addOnItems.map(j => j.productType)) &&
                    (addOnItems.filter((i) => i.collectId === itemId).flatMap(
                      p => analyticProductTypeMapping.get(p.productType)
                    ).includes(item.productType)),
                removeItem: handleRemoveItem
              }}
            />
          );
        })
      };
    });
  }

  if (hasItems) {
    return (
      <div className={floatingCart ? 'floating-cart-body' : 'cart-body'}>
        <div className="cart-details">
          <div className="cart-items">
            <CostBreakdown
              items={formattedItems}
              showCost={false}
              isLoading={isLoading || catalogSearchLoading}
              showHeader={!floatingCart}
              showLabels={!floatingCart}
              handleAddProduct={handleAddAdditionalItem}
              addOnItems={addOnItems}
              showActionButtons={true}
            />
          </div>
        </div>

        <div className="cart-actions">
          <ContractsSelect
            wrapperClassName="contracts-cart"
            contracts={contracts}
            onChange={handleContractChange}
            disable={contracts.length < 2 || isLoading}
            isValid={!proceedError}
            selectedValue={cartContract.contractId}
            formRowClassName="push-bottom-one"
          />
          <Button
            className="cart-actions-review"
            onClick={handleReviewOrder}
            full={floatingCart}
            disabled={isLoading}
          >
            {!isLoading ? (
              'Review Order'
            ) : (
              <span>
                <FaSpinner className="icon-spin" id="loading" /> Preparing
                Order...
              </span>
            )}
          </Button>
          {lastSearchPath && !floatingCart && (
            <Button
              type="text"
              onClick={hideFloatingCart}
              to={lastSearchPath}
              full={floatingCart}
            >
              Back to Search
            </Button>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={floatingCart ? 'floating-cart-body' : 'cart-body'}>
        <Notice type="info small" className="empty-cart">
          <h3>
            <BsCart4 />
          </h3>
          Your cart is empty!
        </Notice>
        {!floatingCart && (
          <p>
            <Button
              onClick={hideFloatingCart}
              full={floatingCart}
              to={lastSearchPath || routePathByName('search')}
            >
              Back to Search
            </Button>
          </p>
        )}
      </div>
    );
  }
};

TemplateCart.propTypes = {
  hideFloatingCart: PropTypes.func,
  cartObject: PropTypes.object,
  floatingCart: PropTypes.bool
};

export default TemplateCart;
