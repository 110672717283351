import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormRow, FormInput, InputButton, Notice } from 'fogg/ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { fieldByName, getAvailableCountries } from '../lib/fields';
import { TASK_ENVIRONMENTS } from '../lib/tasks';
import {
  ADMIN_ROLE,
  ORG_MANAGER_ROLE,
  USER_ROLE,
  ACCESS_ROLES,
  SUB_RESELLER_ROLE
} from '../data/roles';

import OptionsPanel from '../components/OptionsPanel';

const countries = getAvailableCountries();

const SELECT_SINGLE_VALUE_TYPES = [
  'country',
  'organizationId',
  'apiEnvironmentRole'
];

const SINGLE_CHECKBOX_OPTIONS = ACCESS_ROLES.map(({ apiKey }) => apiKey);

const FormUserCreate = ({
  organizations,
  accessType = USER_ROLE,
  disabled = false,
  onSubmit
}) => {
  const accessFull = accessType === ADMIN_ROLE;
  const [selectedRoles, updatedSelectedRoles] = useState({});
  const flags = useFlags();

  const isOrgManagerAccess = accessType === ORG_MANAGER_ROLE;
  const isSubResellerAccess = accessType === SUB_RESELLER_ROLE;

  // Determine which roles we should be showing in the UI based on
  // if the current user has the rights to manage that access and
  // if it's currently selected

  const roles = ACCESS_ROLES.filter((role) => {
    // We first want to make sure that the user actually has access
    // to add this role to a user
    const { manageAccess = [], globalEnabledFlag = {} } = role;
    let enabled = true;
    if (globalEnabledFlag) {
      enabled = flags[globalEnabledFlag.id] === globalEnabledFlag.value;
    }

    return enabled && manageAccess.includes(accessType);
  })
    .filter((role) => {
      // We also want to make sure that this location isn't excluded
      // from a particular role
      const { excludedLocations = [] } = role;
      return !excludedLocations.includes('user-create');
    })
    .map((role) => {
      const { id, label, apiKey: name, updateDisabled = false } = role;
      return {
        id,
        label,
        name,
        updateDisabled
      };
    });

  // We want to provide a UI notification in the event someone is trying
  // to create an org admin so we need to check if they are selected

  const warningRoles = ACCESS_ROLES.filter((role) => {
    const { warnOnCreate, apiKey } = role;
    return warnOnCreate && selectedRoles[apiKey];
  }).map(({ label }) => label);

  const taskEnvironmentOptions = TASK_ENVIRONMENTS.map((env = {}) => {
    return {
      ...env,
      value: env.id
    };
  });

  const defaultTaskEnvironment =
    taskEnvironmentOptions.find(({ isDefault } = {}) => isDefault) || {};

  const validationRules = {
    givenName: {
      ...fieldByName('givenName'),
      required: true
    },
    familyName: {
      ...fieldByName('familyName'),
      required: true
    },
    email: {
      ...fieldByName('email'),
      required: true
    },
    confirmEmail: {
      ...fieldByName('confirmEmail'),
      required: true,
      dependencies: [
        {
          field: 'email',
          exactMatch: true
        }
      ]
    },
    country: {
      required: true
    }
  };

  /**
   * handleSubmit
   */

  function handleSubmit (e, fields = {}) {
    const formFields = {};

    // Clean out undefined keys that get produced from visual form components
    // like OptionsPanel > ChildToggle

    Object.keys(fields).map((key) => {
      if (key === 'undefined' || key === '') return;
      formFields[key] = fields[key];
    });

    // Normalize array based select fields to their selected value

    SELECT_SINGLE_VALUE_TYPES.forEach((type) => {
      const value = formFields[type] && formFields[type].value;
      if (Array.isArray(value) && value.length > 0) {
        formFields[type].value = value[0];
      }
    });

    SINGLE_CHECKBOX_OPTIONS.forEach((type) => {
      if (!formFields[type] || !Array.isArray(formFields[type].value)) return;
      formFields[type].value = formFields[type].value.includes('true');
    });

    if (typeof onSubmit === 'function') {
      onSubmit(e, formFields);
    }
  }

  /**
   * handleOnRoleChange
   */

  function handleOnRoleChange ({ currentTarget = {} }) {
    const { name, checked } = currentTarget;
    updatedSelectedRoles((prev) => {
      return {
        ...prev,
        [name]: checked
      };
    });
  }

  return (
    <Form
      className="form-admin-create-user"
      onSubmit={handleSubmit}
      rules={validationRules}
    >
      <FormRow>
        <FormInput
          id="givenName"
          label="First Name *"
          required={true}
          disabled={disabled}
        />
        <FormInput
          id="familyName"
          label="Last Name *"
          required={true}
          disabled={disabled}
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="email"
          label="Email *"
          type="email"
          required={true}
          disabled={disabled}
        />
        <FormInput
          id="confirmEmail"
          label="Confirm Email *"
          type="email"
          required={true}
          disabled={disabled}
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="organizationId"
          label="Organization"
          type="select"
          options={organizations}
          disabled={disabled || isOrgManagerAccess}
          defaultValue={isOrgManagerAccess && organizations[0].value}
        />
      </FormRow>

      <FormRow>
        <FormInput
          id="country"
          label="Country *"
          type="select"
          options={countries}
          required={true}
          disabled={disabled}
        />
      </FormRow>

      <FormRow>
        <OptionsPanel label="Add Full Address" id="address">
          <FormRow>
            <FormInput id="street" label="Street Address" disabled={disabled} />
          </FormRow>

          <FormRow>
            <FormInput id="city" label="City" disabled={disabled} />
            <FormInput
              id="state"
              label="State / Province"
              disabled={disabled}
            />
            <FormInput
              id="postalCode"
              label="Postal Code"
              disabled={disabled}
            />
          </FormRow>
        </OptionsPanel>
      </FormRow>

      {roles.length > 0 && !isSubResellerAccess && (
        <FormRow>
          <OptionsPanel label="Add Additional Roles" id="roles">
            {roles.map((role = {}, i) => {
              const { id, label, name, updateDisabled } = role;
              return (
                <FormRow key={`Role-${i}`}>
                  <InputButton
                    id={`roles-${id}`}
                    name={name}
                    label={label}
                    value="true"
                    // During user creation we want to force-select roles with updateDisabled
                    // Other roles can be controlled (selected) by the user
                    controlChecked={updateDisabled}
                    isChecked={updateDisabled}
                    type="checkbox"
                    disabled={disabled || updateDisabled}
                    onChange={handleOnRoleChange}
                  />
                </FormRow>
              );
            })}
          </OptionsPanel>
        </FormRow>
      )}

      {accessFull && (
        <FormRow>
          <OptionsPanel label="Environment Configuration" id="environment">
            <FormRow>
              <FormInput
                id="apiEnvironmentRole"
                label="Tasking API"
                type="select"
                options={taskEnvironmentOptions}
                defaultValue={defaultTaskEnvironment.value}
                clearable={false}
                required={true}
              />
            </FormRow>
          </OptionsPanel>
        </FormRow>
      )}

      {warningRoles.length > 0 && (
        <FormRow>
          <Notice type="warning">
            <p className="caption">
              Note: You have currently selected to give this user a privileged
              role(s): {warningRoles.join(', ')}. Doing so will give them
              privileges to modify other users. If you&apos;re unsure of the
              effects of these privileges, please confirm before making the user
              that role. The roles of a user can also be modified after
              creation.
            </p>
          </Notice>
        </FormRow>
      )}

      <FormRow className="form-row-actions">
        <Button full={true} disabled={disabled}>
          Add New User
        </Button>
      </FormRow>

      <FormRow>
        <p className="caption">* Field is required</p>
      </FormRow>
    </Form>
  );
};

FormUserCreate.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  accessType: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default FormUserCreate;
