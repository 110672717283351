import { routePathByName } from 'lib/routes';

export const ORGANIZATION_ROUTES = [
  {
    id: 'organizationProfile',
    to: routePathByName('organizationProfile'),
    label: 'Profile'
  },
  {
    id: 'organizationUsers',
    to: routePathByName('organizationUsers'),
    label: 'Users'
  }
];

export const ORGANIZATION_HIDDEN_PREFERENCE_FIELDS = [
  {
    id: 'hideOrderPricing',
    label: 'Hide Transaction Pricing'
  },
  {
    id: 'hideTransactionActivity',
    label: 'Hide Transaction Activity'
  },
  {
    id: 'fullZoomAccess',
    label: 'Allow Full Zoom Access'
  }
];

export const DEFAULT_INGEST_WINDOW = 12;
export const DEFAULT_BACKFILL = 12;
export const DEFAULT_ANALYTIC_WINDOW = 3;
export const DEFAULT_SPACING = 1;

export const DEFAULT_ANALYTICS_COLLECTIONS = [
  {
    label: 'Sentinel-1 Analytics',
    value: 's1-change'
  },
  {
    label: 'Sentinel-1 L2',
    value: 'sentinel-s1-l2'
  },
  {
    label: 'Capella SAR Analytics',
    value: 'capella-change'
  },
  {
    label: 'Capella SAR Bi-Temporal Analytics',
    value: 'capella-bitemp-change'
  }
];

export const DEFAULT_ORGS_TABLE_COLS = [
  {
    Header: 'Name',
    columnId: 'name',
    widthRatio: 3
  },
  {
    Header: 'Members',
    columnId: 'users',
    sortType: 'basic',
    widthRatio: 1,
    align: 'center'
  },
  {
    Header: 'Organization Type',
    columnId: 'type',
    widthRatio: 2
  },
  {
    Header: 'Date Created',
    columnId: 'createdAt',
    widthRatio: 1,
    align: 'left'
  },
  {
    columnId: 'disabled',
    canFilter: true,
    includeColumn: false
  },
  {
    columnId: 'actions',
    Header: false,
    align: 'right',
    type: 'action',
    canSort: false,
    canFilter: false,
    widthRatio: 1
  }
];
