import React from 'react';
import PropTypes from 'prop-types';
import { FaCircle } from 'react-icons/fa';
import { ItemList } from 'fogg/ui';
import { datetime } from 'fogg/lib';

import Contract from '../models/contract';
import { contractLabel } from '../lib/contracts';
import { fillEmptyContracts } from '../lib/data';
import { routePathByName } from '../lib/routes';
import { ROUTE_ORG_CONTRACT_DETAILS } from '../data/route-names';
import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from '../data/contracts';

const { formatDateTime } = datetime;

/**
 *
 * @param {object} props
 * @param {array} [props.contracts=[]] provide contract objects in an array
 * @param {string} [props.detailsRouteName=ROUTE_ORG_CONTRACT_DETAILS] route path to contract details
 * @param {array} [props.detailsRouteOptions=[]] array of dynamic path options for contract details to inject before contract id (i.e. orgId)
 * @param {string} [emptyListMessage="Looks like there are no contracts!"] message to show when zero contracts
 * @param {bool} [shouldHidePricing=false] determines whether pricing details should be displayed
 * @param {bool} [isLoading=false] impose loading state
 * @returns {ContractsItemList}
 */
const ContractsItemList = ({
  contracts = [],
  detailsRouteName = ROUTE_ORG_CONTRACT_DETAILS,
  detailsRouteOptions = [],
  emptyListMessage = 'Looks like there are no contracts!',
  shouldHidePricing = false,
  isLoading = false
}) => {
  let loadedContracts = contracts;

  // fill empty contracts if loading
  if (isLoading) {
    loadedContracts = fillEmptyContracts();
  }

  const contractsToItemList = loadedContracts.map((contract) => {
    if (isLoading) {
      return {
        label: (
          <span
            className="contract-label loading"
            data-testid="loading-element"
          >
            {contractLabel(contract)}
          </span>
        )
      };
    }

    /** @type {Contract} */
    let typedContract = contract;
    if (typeof typedContract.isExpired !== 'function') {
      typedContract = new Contract(contract);
    }

    const isArchiveSub = typedContract.type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;

    const sublabels = [
      typedContract.isExpired() ? (
        <span className="contract-inactive-label" data-testid="inactive-label">
          <FaCircle size={10} /> Inactive
        </span>
      ) : (
        <span className="contract-active-label" data-testid="active-label">
          <FaCircle size={10} /> Active
        </span>
      ),
      <span className="contract-id" key={typedContract.id}>
        ID: <b>{typedContract.id}</b>
      </span>
    ];

    // consumption contract type details
    if (
      typedContract.contractPeriods &&
      typedContract.contractPeriods.length > 0
    ) {
      const activeContractPeriod = typedContract.contractPeriods.find(period => period.isActive) || typedContract.contractPeriods[0];

      if (!shouldHidePricing) {
        if (isArchiveSub) {
          const { subscriptionQuota, subscriptionUsage, availableSubscriptionUnits } = activeContractPeriod;

          sublabels.push(
            ...[
              <span
                className="contract-subscription-quota"
                key={subscriptionQuota}
              >
                Subscription Total: <b>{subscriptionQuota}</b>
              </span>,
              <span
                className="contract-subscription-usage"
                key={subscriptionUsage}
              >
                Subscription Usage: <b>{subscriptionUsage}</b>
              </span>,
              <span
                className="contract-subscription-available"
                key={availableSubscriptionUnits}
              >
                Subscription Balance: <b>{availableSubscriptionUnits}</b>
              </span>
            ]
          );
        } else {
          const { valueCommitment, availableCommitment, reserve } = activeContractPeriod;

          sublabels.push(
            ...[
              <span className="contract-value" key={valueCommitment}>
                Value Commitment: <b>{valueCommitment}</b>
              </span>,
              <span
                className="contract-available-funds"
                key={availableCommitment}
              >
                Available Commitment: <b>{availableCommitment}</b>
              </span>,
              <span className="contract-reserve" key={reserve}>
                Reserve: <b>{reserve}</b>
              </span>
            ]
          );
        }
      }
    } else {
      sublabels.push(<span className="contract-quoted">Quoted Order</span>);
    }

    return {
      label: (
        <>
          <span className="contract-label">{contractLabel(typedContract)}</span>
          <span className="contract-duration">
            {' '}
            - {formatDateTime(typedContract.startDate)} -{' '}
            {formatDateTime(typedContract.endDate)}
          </span>
        </>
      ),
      to: routePathByName(detailsRouteName, {
        wildcard: [...detailsRouteOptions, typedContract.id]
      }),
      sublabels
    };
  });

  return (
    <>
      <div className="item-list-wrapper">
        <ItemList items={contractsToItemList} />
        {!isLoading && !contracts.length ? <p>{emptyListMessage}</p> : null}
      </div>
    </>
  );
};

ContractsItemList.propTypes = {
  contracts: PropTypes.arrayOf(PropTypes.object).isRequired,
  detailsRouteName: PropTypes.string,
  detailsRouteOptions: PropTypes.array,
  emptyListMessage: PropTypes.string,
  shouldHidePricing: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default ContractsItemList;
