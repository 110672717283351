// shared routes
import routes from 'commonLib/src/data/routes';
import { ROUTE_API_EXPORT_TASKS_CSV, ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV } from './route-names';

// console specific routes go below here

// Note: All front-end paths must end with a /

const consoleRoutes = [];

consoleRoutes.push({
  name: ROUTE_API_EXPORT_TASKS_CSV,
  path: '/tasks/search/download'
});

consoleRoutes.push({
  name: ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV,
  path: '/repeat-requests/search/download'
});

export default routes.concat(consoleRoutes);
