import Request from 'models/request';
import Cookies from 'js-cookie';
const API_ENDPOINT = process.env.API_ENDPOINT;

/**
 * Request
 * @description Creates an Request class to manage and fetch request data
 */

const API_ERROR_BASE = 'Failed to create ApiRequest';
const API_ERROR_MISSING_ENDPOINT = 'Missing API_ENDPOINT';

export default class ApiRequest extends Request {
  constructor (baseUrl, user = {}, pageSession = {}) {
    // Temporary distinguisher between absolute and relative paths.
    // In place while we're using test endpoints
    let requestURL;

    if (baseUrl.indexOf('http://') === 0 || baseUrl.indexOf('https://') === 0) {
      requestURL = baseUrl;
    } else {
      if (typeof API_ENDPOINT === 'undefined') {
        throw new Error(`${API_ERROR_BASE}: ${API_ERROR_MISSING_ENDPOINT}`);
      }
      requestURL = `${API_ENDPOINT}${baseUrl}`;
    }

    super(requestURL);
    // Use JWT from Cookies, unless it expired then grab the latest from user store
    const token = Cookies.get('token');
    this.updateHeaders({
      ...(token && { Authorization: `Bearer ${token}` })
    });
  }
}
