import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { MdPersonAdd } from 'react-icons/md';

import { fieldsObjectToValues } from 'lib/util';

import Layout from 'components/Layout';
import Container from '../components/Container';
import Breadcrumbs from '../components/Breadcrumbs';
import PageHeader from '../components/PageHeader';
import FormUserCreate from '../components/FormUserCreate';

const TemplateUserCreate = ({
  organizations,
  breadcrumbs = [],
  accessType,
  onCreate,
  state = {}
}) => {
  const { isLoading = false } = state;

  const organizationOptions = organizations.map(({ id, name }) => {
    return {
      label: name,
      value: id
    };
  });

  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;

  function handleOnCreateUser (e, fields) {
    const userData = fieldsObjectToValues(fields);
    if (typeof onCreate === 'function') {
      onCreate(userData);
    }
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: 'template-user-create'
        }}
      >
        <title>Create New User</title>
      </Helmet>

      <Container className={`content ${isLoading ? 'content-is-loading' : ''}`}>
        {hasBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}

        <PageHeader
          title="Create New User"
          icon={<MdPersonAdd className="icon-md" />}
        />

        <FormUserCreate
          organizations={organizationOptions}
          accessType={accessType}
          disabled={isLoading}
          onSubmit={handleOnCreateUser}
        />
      </Container>
    </Layout>
  );
};

TemplateUserCreate.propTypes = {
  organizations: PropTypes.array,
  breadcrumbs: PropTypes.array,
  accessType: PropTypes.string,
  onCreate: PropTypes.func,
  state: PropTypes.object
};

export default TemplateUserCreate;
