import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import { routePathByName } from 'lib/routes';

import OrganizationIndexPage from 'pages-client/organization/organization';
import OrganizationProfilePage from 'pages-client/organization/profile';
import OrganizationUserPage from 'pages-client/organization/users/user';
import OrganizationUsersPage from 'pages-client/organization/users/users';
import OrganizationUsersCreatePage from 'pages-client/organization/users/create';
import OrganizationTask from 'pages-client/organization/tasks/task';
import OrganizationTasks from 'pages-client/organization/tasks/tasks';
import OrganizationOrder from 'pages-client/organization/orders/order';
import OrganizationOrders from 'pages-client/organization/orders/orders';
import OrganizationDetails from 'pages-client/organization/details';
import OrganizationEditPage from 'pages-client/organization/edit';
import OrganizationContractsPage from '../pages-client/organization/contracts/contracts';
import OrganizationContractDetailsPage from '../pages-client/organization/contracts/contract';
import OrganizationOrdersContractDetailsPage from '../pages-client/organization/orders/contract';
import OrganizationDetailsContractPage from '../pages-client/organization/contract';
import OrganizationTasksContractDetailsPage from '../pages-client/organization/tasks/contract';
import OrganizationsBucketConfigAddPage from '../pages-client/organization/bucket-config/add';
import OrganizationsBucketConfigEditPage from '../pages-client/organization/bucket-config/edit';
import OrganizationRepeatRequestPage from '../pages-client/organization/repeatRequests/repeatRequest';
import OrganizationRepeatRequestsPage from '../pages-client/organization/repeatRequests/repeatRequests';

import {
  ROUTE_ORG_CONTRACTS,
  ROUTE_ORG_CONTRACT_DETAILS,
  ROUTE_ORDER_CONTRACT_DETAILS,
  ROUTE_ORG_DETAILS_CONTRACT,
  ROUTE_ORG_TASKS_CONTRACT_DETAILS,
  ROUTE_ORG_BUCKET_CONFIG_ADD,
  ROUTE_ORG_BUCKET_CONFIG_EDIT,
  ROUTE_ORG_TASKS,
  ROUTE_ORG_TASKS_DETAILS,
  ROUTE_ORG_REPEAT_REQUESTS,
  ROUTE_ORG_REPEAT_REQUESTS_DETAILS
} from 'data/route-names';

const organizationRoute = routePathByName('organization');
const organizationProfileRoute = routePathByName('organizationProfile');
const organizationUsersRoute = routePathByName('organizationUsers');
const organizationUsersCreateRoute = routePathByName('organizationUsersCreate');
const organizationUsersDetailsRoute = routePathByName(
  'organizationUsersDetails',
  {
    wildcard: ':userId'
  }
);
const organizationTasksRoute = routePathByName(ROUTE_ORG_TASKS);
const organizationTasksDetailsRoute = routePathByName(
  ROUTE_ORG_TASKS_DETAILS,
  {
    wildcard: ':taskId'
  }
);

const organizationRepeatRequestsRoute = routePathByName(ROUTE_ORG_REPEAT_REQUESTS);
const organizationRepeatRequestsDetailsRoute = routePathByName(
  ROUTE_ORG_REPEAT_REQUESTS_DETAILS,
  {
    wildcard: ':repeatRequestId'
  }
);

const organizationOrdersRoute = routePathByName('organizationOrders');
const organizationOrdersDetailsRoute = routePathByName(
  'organizationOrdersDetails',
  {
    wildcard: ':orderId'
  }
);
const organizationOrderContractDetailsRoute = routePathByName(
  ROUTE_ORDER_CONTRACT_DETAILS,
  {
    wildcard: [':orderId', ':contractId']
  }
);

// this route is in case the order id gets removed
const organizationMissingOrderContractDetailsRoute = routePathByName(
  ROUTE_ORDER_CONTRACT_DETAILS,
  {
    wildcard: [':contractId']
  }
);
const organizationDetailsRoute = routePathByName('organizationDetails');
const organizationEditRoute = routePathByName('organizationEdit');
const organizationContractsRoute = routePathByName(ROUTE_ORG_CONTRACTS);
const organizationContractDetailsRoute = routePathByName(
  ROUTE_ORG_CONTRACT_DETAILS,
  {
    wildcard: ':contractId'
  }
);
const organizationDetailsContractRoute = routePathByName(
  ROUTE_ORG_DETAILS_CONTRACT,
  {
    wildcard: ':contractId'
  }
);

const organizationTasksContractDetails = routePathByName(
  ROUTE_ORG_TASKS_CONTRACT_DETAILS,
  {
    wildcard: [':taskId', ':contractId']
  }
);
// this route is in case the taskId gets removed
const organizationMissingTaskContractDetails = routePathByName(
  ROUTE_ORG_TASKS_CONTRACT_DETAILS,
  {
    wildcard: [':contractId']
  }
);
const organizationBucketConfigAdd = routePathByName(
  ROUTE_ORG_BUCKET_CONFIG_ADD
);
const organizationBucketConfigEdit = routePathByName(
  ROUTE_ORG_BUCKET_CONFIG_EDIT,
  {
    wildcard: [':bucketConfigId']
  }
);

const OrganizationIndex = () => {
  return (
    <>
      <Router basepath="/">
        <OrganizationIndexPage path={organizationRoute} default />
        <OrganizationProfilePage path={organizationProfileRoute} />
        <OrganizationUsersPage path={organizationUsersRoute} />
        <OrganizationUsersCreatePage path={organizationUsersCreateRoute} />
        <OrganizationUserPage path={organizationUsersDetailsRoute} />

        <OrganizationTasks path={organizationTasksRoute} />

        <OrganizationTask path={organizationTasksDetailsRoute} />
        <OrganizationRepeatRequestsPage path={organizationRepeatRequestsRoute} />
        <OrganizationRepeatRequestPage path={organizationRepeatRequestsDetailsRoute} />
        <OrganizationOrders path={organizationOrdersRoute} />
        <OrganizationOrder path={organizationOrdersDetailsRoute} />
        <OrganizationDetails path={organizationDetailsRoute} />
        <OrganizationEditPage path={organizationEditRoute} />
        <OrganizationContractsPage path={organizationContractsRoute} />
        <OrganizationContractDetailsPage
          path={organizationContractDetailsRoute}
        />
        <OrganizationOrdersContractDetailsPage
          path={organizationOrderContractDetailsRoute}
        />
        <OrganizationOrdersContractDetailsPage
          path={organizationMissingOrderContractDetailsRoute}
        />
        <OrganizationDetailsContractPage
          path={organizationDetailsContractRoute}
        />
        <OrganizationTasksContractDetailsPage
          path={organizationTasksContractDetails}
        />
        <OrganizationTasksContractDetailsPage
          path={organizationMissingTaskContractDetails}
        />
        <OrganizationsBucketConfigAddPage path={organizationBucketConfigAdd} />
        <OrganizationsBucketConfigEditPage
          path={organizationBucketConfigEdit}
        />
      </Router>
    </>
  );
};

OrganizationIndex.propTypes = {
  location: PropTypes.object
};

export default OrganizationIndex;
