import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBriefcase } from 'react-icons/fa';

import Contract from '../../../../models/contract';

import {
  routePathByName,
  redirectTo,
  logError,
  useAdminFetchOrganization,
  useAdminFetchLicenses,
  updateOrganizationContract,
  shareContractWithOrganization,
  unshareContractWithOrganization,
  triggerSuccess,
  triggerError,
  Breadcrumbs,
  PageHeader,
  Container,
  FormContractEdit,
  ContractShareCard,
  NavigatorSidebar
} from 'commonLib';

import Layout from 'components/Layout';

const PAGE_TITLE = 'Edit Contract';

const AdminOrganizationsContractEditPage = ({ organizationId, contractId }) => {
  const dispatch = useDispatch();

  const { licenses = {}, isLoading: fetchLicensesIsLoading } =
    useAdminFetchLicenses();
  const { organization = {}, isLoading: fetchOrgIsLoading } =
    useAdminFetchOrganization({
      id: organizationId
    });

  const contractDetailsPath = routePathByName(
    'adminOrganizationContractDetails',
    {
      wildcard: [organizationId, contractId]
    }
  );

  let contract =
    organization?.contracts?.find(
      (orgContract) => orgContract.id === contractId
    ) || {};
  if (typeof contract.isExpired !== 'function') {
    contract = new Contract(contract);
  }

  // Loading state after hitting submit button
  const [submitLoading, setSubmitLoading] = useState(false);

  function handleOnSubmit ({ data }) {
    setSubmitLoading(true);
    dispatch(updateOrganizationContract(organizationId, contractId, data))
      .then(() => {
        setSubmitLoading(false);
        redirectTo(contractDetailsPath);
        dispatch(triggerSuccess('Contract was successfully updated!'));
      })
      .catch((error) => {
        setSubmitLoading(false);
        logError(error.message, data);
        dispatch(triggerError());
      });
  }

  function handleAddOrganization (orgId) {
    setSubmitLoading(true);
    return dispatch(
      shareContractWithOrganization(organizationId, contract.id, orgId)
    )
      .then(() => {
        setSubmitLoading(false);
      })
      .catch((error) => {
        setSubmitLoading(false);
        logError(error.message, orgId);
      });
  }

  function handleRemoveOrganization (orgId, shareId) {
    setSubmitLoading(true);
    dispatch(
      unshareContractWithOrganization(
        organizationId,
        contract.id,
        orgId,
        shareId
      )
    )
      .then(() => {
        setSubmitLoading(false);
      })
      .catch((error) => {
        setSubmitLoading(false);
        logError(error.message, orgId);
      });
  }

  // Admins can "cancel an edit" by navigating back to the org details page
  function handleOnCancel () {
    redirectTo(contractDetailsPath);
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: 'page-admin page-admin-contract page-admin-contract-edit'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Admin',
              to: routePathByName('admin')
            },
            {
              label: 'Organizations',
              to: routePathByName('adminOrganizations')
            },
            {
              label: 'Organization',
              to: routePathByName('adminOrganizationsDetails', {
                wildcard: organizationId
              })
            },
            {
              label: 'Contract',
              to: contractDetailsPath
            }
          ]}
        />
        <PageHeader
          title={PAGE_TITLE}
          subtitle={`ID: ${contractId}`}
          icon={<FaBriefcase />}
        />
        <div className="edit-contract-content-wrapper">
          <FormContractEdit
            contract={contract}
            licenses={licenses}
            onSubmit={handleOnSubmit}
            disabled={
              fetchOrgIsLoading || fetchLicensesIsLoading || submitLoading
            }
            onCancel={handleOnCancel}
            organization={organization}
          />
          <NavigatorSidebar>
            <ContractShareCard
              disabled={fetchOrgIsLoading || submitLoading}
              dataSharedWith={contract.dataSharedWith}
              handleAddOrganization={handleAddOrganization}
              handleRemoveOrganization={handleRemoveOrganization}
            />
          </NavigatorSidebar>
        </div>
      </Container>
    </Layout>
  );
};

AdminOrganizationsContractEditPage.propTypes = {
  organizationId: PropTypes.string,
  contractId: PropTypes.string
};

export default AdminOrganizationsContractEditPage;
