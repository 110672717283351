import center from '@turf/center';
import { getGeom } from '@turf/invariant';
import L from 'leaflet';

import { buildTileEndpoint } from './search';

export const geoJsonWorld = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-180, -90],
            [-180, 90],
            [180, 90],
            [180, -90],
            [-180, -90]
          ]
        ]
      }
    }
  ]
};

/**
 * geometryToGeoJson
 * @description
 */

export function geometryToGeoJson (geometry) {
  if (!geometry || !geometry.type) {
    throw new Error(
      'Failed to convert geometry to GeoJson: Invalid geometry type'
    );
  }
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: geometry
      }
    ]
  };
}

/**
 * getGeoJsonCenter
 * @description
 */

export function getGeoJsonCenter (geoJson) {
  if (!geoJson || !geoJson.type) {
    throw new Error('Failed to get geoJson center: Invalid geoJson type');
  }
  return center(geoJson);
}

/**
 * latLngFromGeoJson
 * @description Grabs Lat and Lng sets from GeoJSON
 */

export function latLngFromGeoJson (geoJson) {
  const coordinates = coordinatesFromGeoJson(geoJson);
  return coordinates.map((set) => {
    return {
      lng: set[0],
      lat: set[1]
    };
  });
}

/**
 * coordinatesFromGeoJson
 * @description Grabs the coordinate sets from the GeoJSON
 */

export function coordinatesFromGeoJson (geoJson) {
  let { features } = geoJson;

  // If we don't have any top level features but has a geometry,
  // grab that instead and wrap it in an array for normalization

  if (!features && geoJson.geometry) {
    features = [geoJson.geometry];
  }

  return features.map((feature = {}) => {
    const { coordinates = [] } = getGeom(feature);
    return coordinates;
  });
}

/**
 * tileLayerPropertiesFromFeature
 */

export function tileLayerPropertiesFromFeature (feature) {
  if (!feature || !feature.id || !feature.assets) return;

  const { id, collection, assets = {}, properties = {}, bbox } = feature;
  const { preview = {} } = assets;
  const { tiler } = preview;
  const { datetime } = properties;
  const date = new Date(datetime);
  const year = date && date.getFullYear();

  // If tiler URL is not present, we can't show the layer.
  if (!tiler) return;

  const tileEndpoint = buildTileEndpoint({
    url: tiler
  });

  const bounds =
    bbox && bbox.length === 4
      ? L.latLngBounds([bbox[1], bbox[0]], [bbox[3], bbox[2]])
      : undefined;

  return {
    url: tileEndpoint,
    options: {
      name: `Capella ${collection} ${id}`,
      projections: 'epsg3857',
      attribution: `&copy; ${year} Capella Space`,
      maxZoom: 18,
      nativeZoom: 18,
      minZoom: 3,
      tileSize: 256,
      bounds: bounds
    }
  };
}

/**
 * limitGeoJsonFeatures
 */

export function limitGeoJsonFeatures (geoJson, limit) {
  if (typeof limit !== 'number') {
    throw new Error(`Invalid limit type: ${typeof limit}`);
  }
  return {
    ...geoJson,
    features: geoJson.features.slice(0, limit)
  };
}

/**
 * isValidGeoJson
 */

export function isValidGeoJson (geoJson) {
  let isValid = true;

  try {
    L.geoJSON(geoJson);
  } catch (e) {
    isValid = false;
  }

  return isValid;
}

/**
 * sanitizeGeoJson
 */

export function sanitizeGeoJson (geoJson) {
  return L.geoJSON(geoJson).toGeoJSON();
}

// Workaround for triggering leaflet draw controls from a custom button click/event
export const triggerLeafletDraw = (shape, e) => {
  e = document.createEvent('Event');
  e.initEvent('click', true, true);
  var cb = document.getElementsByClassName('leaflet-draw-draw-' + shape);
  return !cb[0].dispatchEvent(e);
};
