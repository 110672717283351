import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  Form,
  Button,
  FormRow,
  FormInput,
  InputButton
} from 'fogg/ui';
import { FaTrashAlt } from 'react-icons/fa';
import { S3BucketConfigTestConnectivity } from 'commonLib';

/**
 * Form displayed to edit S3 Bucket config
 * @param  {object} props
 * @param  {string} props.orgId
 * @param  {string} [props.mode='add']
 * @param  {object} [props.bucketConfig={}]
 * @param  {function} props.onSubmit
 * @param  {function} props.onCancel
 * @param  {function} props.onDelete
 */
const FormBucketConfigAddEdit = ({ orgId = '', mode = 'add', bucketConfig = {}, onSubmit, onCancel, onDelete }) => {
  const isEmptyBucketConfig = Object.keys(bucketConfig).length === 0;
  const [state, setState] = useState(isEmptyBucketConfig ? {
    bucketName: '',
    prefix: '',
    isGovcloud: false,
    isActive: true
  } : bucketConfig);
  const [testAccessSuccess, setTestAccessSuccess] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const handleChange = (event) => {
    if (event.target.name === 'isGovcloud') {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }

    if (event.target.name !== 'isActive') {
      setTestAccessSuccess(false);
    }
  };

  function handleSubmit (e, fields = {}) {
    const formFields = {};
    const data = {};

    Object.keys(fields).forEach((key) => {
      if (key === 'undefined' || key === '') return;
      if (typeof fields[key].value === 'undefined') return;

      const value = fields[key].value;

      formFields[key] = {
        ...fields[key],
        value
      };

      data[key] = value;
    });

    // convert boolean - TODO: what's the righ way of doing this
    data.isGovcloud = data.isGovcloud.length > 0;
    data.isActive = data.isActive.length > 0;

    if (typeof onSubmit === 'function') {
      onSubmit({
        e,
        fields: formFields,
        data
      });
    }
  }

  function handleCancel (e) {
    if (e) e.preventDefault();
    if (typeof onCancel === 'function') {
      onCancel();
    }
  }

  function showDeleteConfirmSection () {
    setShowDeletePrompt(true);
  }

  function hideDeleteConfirmSection () {
    setShowDeletePrompt(false);
  }

  function handleDelete (e) {
    if (e) e.preventDefault();
    if (typeof onDelete === 'function') {
      onDelete();
    }
  }

  return (
    <Form
      className="form-admin-bucket-config-add-edit"
      onSubmit={handleSubmit}
    >
      <ReactTooltip
        id="bucketNameTip"
        place="top"
        effect="solid"
        type="dark"
        multiline={true}
        className="customTip"
      >
        <div>
          The name of the Amazon S3 bucket to deliver Capella imagery.
        </div>
        <div>Rules for naming buckets:</div>
        <ul>
          <li>Name must be between 3 and 63 characters long</li>
          <li>
            Name can consist only of lowercase letters, numbers, dots (.),
            and hyphens (-)
          </li>
          <li>Name must begin and end with a letter or number</li>
          <li>Name must not be formatted as an IP address</li>
          <li>Name must not start with the prefix xn--</li>
        </ul>
      </ReactTooltip>
      <FormRow>
        <FormInput
          id="bucketName"
          label="Bucket Name *"
          required={true}
          value={state.bucketName}
          onChange={handleChange}
        />
        <span
          className="general-tool-tip"
          data-tip
          data-for="bucketNameTip"
        >
          ?
        </span>
      </FormRow>

      <ReactTooltip
        id="prefixTip"
        place="top"
        effect="solid"
        type="dark"
        multiline={true}
        className="customTip"
      >
          The prefix of the S3 bucket. A prefix value is similar to a
          directory name that enables you to organize your data by grouping
          similar objects in a bucket.
      </ReactTooltip>
      <FormRow>
        <FormInput
          id="prefix"
          label="Prefix"
          value={state.prefix}
          onChange={handleChange}
        />
        <span className="general-tool-tip" data-tip data-for="prefixTip">
          ?
        </span>
      </FormRow>

      <ReactTooltip
        id="govcloudTip"
        place="top"
        effect="solid"
        type="dark"
        multiline={true}
        className="customTip"
      >
        Enable this if the bucket is hosted in AWS GovCloud (US) ?
      </ReactTooltip>
      <FormRow>
        <InputButton
          id="isGovcloud"
          label="GovCloud"
          type="checkbox"
          isChecked={state.isGovcloud}
          onChange={handleChange}
        />
        <span className="general-tool-tip" data-tip data-for="govcloudTip">
          ?
        </span>
      </FormRow>

      <ReactTooltip
        id="activeTip"
        place="top"
        effect="solid"
        type="dark"
        multiline={true}
        className="customTip"
      >
        Destinations (Bucket Name / Prefix) of <b>active</b> bucket configurations automatically receive assets from tasking requests upon delivery.
      </ReactTooltip>
      <FormRow>
        <InputButton
          id="isActive"
          label="Active"
          type="checkbox"
          isChecked={state.isActive}
          onChange={handleChange}
        />
        <span className="general-tool-tip" data-tip data-for="activeTip">
          ?
        </span>
      </FormRow>

      <FormRow>
        <p className="caption">* Field is required</p>
      </FormRow>

      <FormRow>
        <S3BucketConfigTestConnectivity
          orgId={orgId}
          s3BucketName={state.bucketName}
          s3Prefix={state.prefix}
          isGovcloud={state.isGovcloud}
          setTestAccessSuccess={setTestAccessSuccess}
        />
      </FormRow>
      {!testAccessSuccess && (<span className="helper-text">Successful Access Test required before Saving</span>)}
      <FormRow className="form-row-actions">
        <Button full={true} disabled={!testAccessSuccess}>
          { mode === 'add' ? 'Add Bucket Config' : 'Save Changes' }
        </Button>
        <Button type="text" onClick={handleCancel}>
          Cancel
        </Button>
      </FormRow>
      { mode === 'edit' &&
        <FormRow>
          <Button full={true} className="form-admin-bucket-config-delete-toggle" type={['icon-before button-text danger']} disabled={showDeletePrompt} onClick={showDeleteConfirmSection}><FaTrashAlt />Delete bucket config</Button>
        </FormRow>
      }
      { showDeletePrompt && (
        <>
          <div>Please confirm you want to delete this bucket config.</div>
          <FormRow className="form-row-actions">
            <Button full={true} className="form-admin-bucket-config-delete" type={['icon-before danger']} onClick={handleDelete}>Delete</Button>
            <Button full={true} type="secondary" onClick={hideDeleteConfirmSection}>Cancel</Button>
          </FormRow>
        </>
      )
      }
    </Form>
  );
};

FormBucketConfigAddEdit.propTypes = {
  orgId: PropTypes.string,
  mode: PropTypes.string,
  bucketConfig: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func
};

export default FormBucketConfigAddEdit;
