import React from 'react';
import PropTypes from 'prop-types';
import wrapWithProvider from './wrap-with-provider';
import { Helmet } from 'react-helmet';
import PageWrapper from 'components/PageWrapper';
import { returnConsoleRUMSnippet } from 'data/analytics';
import { TaskContextProvider } from 'hooks/useTaskContext';
import { ROUTE_USER_REGISTER, ROUTE_USER_REGISTER_EXTERNAL } from 'commonLib/src/data/route-names';
import { redirectTo, routePathByName } from 'lib/routes';

// Override specific routes via Gatsby's onRouteUpdate
export const onRouteUpdate = ({ location }) => {
  // Redirect /user/register/ to capella's community page
  if (location?.pathname === routePathByName(ROUTE_USER_REGISTER)) {
    redirectTo(routePathByName(ROUTE_USER_REGISTER_EXTERNAL));
  }
};

export const wrapPageElement = ({ element, props = {} }) => {
  const { location = {} } = props;
  const { hostname } = location;
  const RUM_SNIPPET = returnConsoleRUMSnippet(hostname);
  return (
    <PageWrapper {...props}>
      <Helmet>{RUM_SNIPPET}</Helmet>
      <TaskContextProvider>
        {element}
      </TaskContextProvider>
    </PageWrapper>
  );
};

wrapPageElement.propTypes = {
  element: PropTypes.object,
  props: PropTypes.object
};

export const wrapRootElement = wrapWithProvider;
