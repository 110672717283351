import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ children, className, type }) => {
  let containerClassName = 'container';

  if (typeof type === 'string') {
    type = [type];
  }

  if (Array.isArray(type)) {
    type.forEach((containerType) => {
      containerClassName = `${containerClassName} container-${containerType}`;
    });
  }

  if (typeof className === 'string') {
    containerClassName = `${containerClassName} ${className}`;
  }

  return <div className={containerClassName}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

export default Container;
