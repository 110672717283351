import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormRow, FormInput, InputButton } from 'fogg/ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { percentageToFloat } from '../lib/currency';
import {
  CONTRACT_TYPES,
  CONTRACT_TYPE_INDEFINITE,
  CONTRACT_TYPE_STANDARD,
  CONTRACT_TYPE_TRIAL,
  CONTRACT_TYPE_RESELLER,
  CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION,
  CONTRACT_RESELLER_TIERS,
  CONTRACT_EXCLUSIVITY_UPLIFTS,
  CONTRACT_FORM_DEFAULTS_BASE
} from '../data/contracts';
import { useLocation, useFormInputDefaults } from '../hooks';

const DATETIME_FIELDS = ['endDate', 'startDate'];
const SELECT_SINGLE_VALUE_TYPES = [
  'type',
  'license',
  'resellerTier',
  'exclusivityHolds.tasking_request',
  'exclusivityHolds.data_order'
];
const PERCENTAGE_FIELDS = [
  'customUplifts',
  'customUplifts.data_order',
  'customUplifts.tasking_request',
  'discretionaryDiscount',
  'discretionaryDiscount.data_order',
  'discretionaryDiscount.tasking_request',
  'entityUplift',
  'volumeDiscount',
  'pricingOverrides.analyticImageryUplifts.VC'
];

const BOOL_VALUE_TYPES = ['enforceAvailableFundsCap'];
const fieldValueHandler = (field) => {
  if (typeof field.checked !== 'undefined') {
    return field.checked;
  }
  return field.value;
};

const FormContractEdit = ({
  contract = {},
  licenses,
  disabled = false,
  onSubmit,
  onCancel,
  showSalesforce = true,
  organization = {}
}) => {
  const {
    label,
    type,
    license,
    resellerTier,
    fundsInt,
    customUpliftsFormatted,
    discretionaryDiscountFormatted,
    entityUpliftFormatted,
    volumeDiscountFormatted,
    startDateInt,
    endDateInt,
    salesforceContractId,
    salesforceOpportunityId,
    enforceAvailableFundsCap,
    exclusivityHoldsFormatted,
    isInvoiceable,
    isAnalyticsVcSubscription,
    analyticsVcPricingOverrideFormatted
  } = contract;

  const [contractType, updateContractType] = useState(type);
  const [licenseType, setLicenseType] = useState(license);

  const [contractAnalyticsSubscription, setContractAnalyticsSubscription] = useState(isAnalyticsVcSubscription);

  const { refs: formInputRefs, assignRef, assignDefaultsToRef } = useFormInputDefaults({
    defaultFormInputValues: CONTRACT_FORM_DEFAULTS_BASE
  });

  useEffect(() => {
    updateContractType(type);
  }, [type]);
  useEffect(() => {
    if (licenses && licenses.all.length > 0) {
      // bit hacky but we do this to ensure that the select will always render with a valid defaultValue and not fail to render properly because of missing options
      setLicenseType(license);
    }
  }, [license, licenses]);

  useEffect(() => {
    setContractAnalyticsSubscription(isAnalyticsVcSubscription);
  }, [isAnalyticsVcSubscription]);

  const { pathname } = useLocation();
  const isAdminRoute = pathname.includes('/admin/organizations/');

  const { type: orgType, vesselClassificationEnabled = false } = organization;
  const isIndefinite = contractType === CONTRACT_TYPE_INDEFINITE;
  const isStandard = contractType === CONTRACT_TYPE_STANDARD;
  const isReseller = contractType === CONTRACT_TYPE_RESELLER;
  const isArchiveSub = contractType === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;

  // Set up contract types in a select field format
  let contractTypes = CONTRACT_TYPES.map((type) => {
    const { label, name, defaults } = type;
    return {
      label,
      value: name,
      defaults
    };
  });

  // Hide "Trial" contract type if org type is NOT "standard" OR if we aren't in admin form
  if ((orgType && orgType !== 'standard') || !isAdminRoute) {
    contractTypes = contractTypes.filter(
      (type) => type.value !== CONTRACT_TYPE_TRIAL
    );
  }

  const resellerTiers = CONTRACT_RESELLER_TIERS.map((tier) => {
    const { label, name } = tier;
    return {
      label,
      value: name
    };
  });

  const exclusivityUplifts = CONTRACT_EXCLUSIVITY_UPLIFTS.map((uplift) => {
    const { label, name } = uplift;
    return {
      label,
      value: name
    };
  });

  const validationRules = {};

  const flags = useFlags();
  const displayAnalyticsVcBillingType =
    vesselClassificationEnabled && flags.vesselClassificationEnabled && flags.customAnalyticUplifts;

  /**
   * handleSubmit
   */

  function handleSubmit (e, fields = {}) {
    const formFields = {};
    const data = {};

    Object.keys(fields).forEach((key) => {
      if (key === 'undefined' || key === '') return;
      if (typeof fields[key].value === 'undefined') return;

      const isSelectSingleValue = SELECT_SINGLE_VALUE_TYPES.includes(key);
      let value = fields[key].value;

      // get value from ref if available
      if (formInputRefs.current[key]) {
        value = fieldValueHandler(formInputRefs.current[key]);
      }

      // Transform any datetime based fields to ISO

      if (DATETIME_FIELDS.includes(key) && typeof value === 'string') {
        value = new Date(parseInt(fields[key].value)).toISOString();
      }

      // Select inputs need to be reduced down to a single value

      if (isSelectSingleValue && Array.isArray(value) && value.length > 0) {
        value = value[0];
      }

      // Clean up % based inputs to make them more flexible and pass the
      // intended value

      if (PERCENTAGE_FIELDS.includes(key)) {
        // The converter expects a % symbol, but if the user
        // doesn't include that, we don't want it to error
        if (value.slice(-1) !== '%') {
          value = `${value}%`;
        }
        value = percentageToFloat(value);
      }

      BOOL_VALUE_TYPES.forEach((type) => {
        const field = fields[type];
        if (!field || !Array.isArray(field.value)) return;
        fields[type].value = field.value[0] === 'true';
      });

      // Check to see if we have a nested field, in which case we want the
      // value to be an object
      const keySplit = key.split('.');
      if (keySplit[1]) {
        parseNestedFieldToObject(keySplit, 0, value, formFields, data);
        // Delete the original field to avoid sending it with the data
        delete formFields[key];
      } else {
        formFields[key] = {
          ...fields[key],
          value
        };
        data[key] = value;
      }
    });

    // Handle VC Subscription vs Uplift.
    // This is presented in the form through connected input controls and needs special handling
    if (displayAnalyticsVcBillingType && formFields.analyticsVcBillingType?.value[0] === 'analyticsVcSubscription') {
      const { pricingOverrides: formPricingOverrides = { analyticImageryUplifts: { value: {} } } } = formFields;
      const { analyticImageryUplifts: formAnalyticImageryUplifts = { value: {} } } = formPricingOverrides;
      formAnalyticImageryUplifts.value.VC = null;

      const { pricingOverrides: dataPricingOverrides = { analyticImageryUplifts: { VC: {} } } } = data;
      const { analyticImageryUplifts: dataAnalyticImageryUplifts = { VC: {} } } = dataPricingOverrides;
      dataAnalyticImageryUplifts.VC = null;
    }

    delete formFields.analyticsVcBillingType;
    delete data.analyticsVcBillingType;

    if (typeof onSubmit === 'function') {
      onSubmit({
        e,
        fields: formFields,
        data
      });
    }
  }

  /**
   * Recursively parse a key, splitted into the keySplit array, and update the form with and data
   * with an object representation having those keys as as properties and `value` as the final value.
   */
  function parseNestedFieldToObject (keySplit, pos, value, formFields, data) {
    if (!formFields[keySplit[pos]]) {
      formFields[keySplit[pos]] = {};
      formFields[keySplit[pos]].value = {};
    }

    if (!data[keySplit[pos]]) {
      data[keySplit[pos]] = {};
    }

    if (keySplit[pos + 2]) {
      parseNestedFieldToObject(keySplit, pos + 1, value, formFields[keySplit[pos]], data[keySplit[pos]]);
    } else {
      formFields[keySplit[pos]].value[keySplit[pos + 1]] = value;
      data[keySplit[pos]][keySplit[pos + 1]] = value;
    }
  }

  /**
   * handleOnTypeChange
   */

  function handleOnTypeChange ({ value } = {}) {
    updateContractType(value);
    handleSetContractDefaults(value);
  }

  function handleLicenseTypeChange (option) {
    setLicenseType(option && option.value);
  }

  function handleCancel (e) {
    if (e) e.preventDefault();
    if (typeof onCancel === 'function') {
      onCancel();
    }
  }

  /**
   * sets contract defaults on contract type change
   */
  function handleSetContractDefaults (contractType) {
    const { defaults } =
      contractTypes.find(({ value }) => value === contractType) || {};
    assignDefaultsToRef(defaults);
  }

  function handleRadioGroupChange (type, input) {
    const { target } = input;
    if (type === 'analyticsVcBillingType') {
      setContractAnalyticsSubscription(target.value === 'analyticsVcSubscription');
    }
  }

  // FIXME - No seriously fix me at some point, this is terrible. Fogg overrides the standard defaultValue passed to a (non-multi) Select
  // by filtering the options to find the full object since react-select wants the full object and not just the value. Unfortunately it does
  // not take into account grouped options and therefore will break completely if it can't find the object in the root options array.
  const licenseOptions = useMemo(
    () => licenses && [...licenses.groupedOptions, ...licenses.all],
    [licenses]
  );

  return (
    <Form
      className="form-admin-contract-edit"
      rules={validationRules}
      onSubmit={handleSubmit}
    >
      <FormRow>
        <FormInput
          id="label"
          label="Name *"
          required={true}
          defaultValue={label}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <FormInput
          key={contractType}
          id="type"
          label="Type *"
          type="select"
          options={contractTypes}
          required={true}
          defaultValue={contractType}
          clearable={false}
          disabled={disabled}
          onChange={handleOnTypeChange}
        />
      </FormRow>

      {isAdminRoute && (
        <FormRow>
          <FormInput
            className="license-input"
            key={licenseType}
            id="license"
            label="License *"
            type="select"
            required={true}
            options={licenseOptions}
            defaultValue={licenseType}
            clearable={true}
            disabled={disabled}
            onChange={handleLicenseTypeChange}
          />
        </FormRow>
      )}

      {!isIndefinite && !isArchiveSub && (
        <FormRow>
          <FormInput
            id="funds"
            label="Funds *"
            className="input-funds currency-input"
            type="number"
            required={true}
            defaultValue={fundsInt}
            disabled={disabled}
          />
          <div className="input-enforce-funds-cap">
            <label className="form-label" htmlFor="enforceAvailableFundsCap">
              Enforce Funds Cap
            </label>
            <InputButton
              id="enforceAvailableFundsCap"
              label="Capped"
              type="checkbox"
              value="true"
              isChecked={enforceAvailableFundsCap}
              disabled={disabled}
            />
          </div>
        </FormRow>
      )}

      {isReseller && (
        <FormRow>
          <FormInput
            key={resellerTier}
            id="resellerTier"
            label="Reseller Tier *"
            type="select"
            options={resellerTiers}
            required={true}
            defaultValue={resellerTier}
            clearable={false}
            disabled={disabled}
          />
        </FormRow>
      )}

      <FormRow>
        <FormInput
          id="startDate"
          label={`Start Date (UTC)${!isStandard ? ' *' : ''}`}
          type="datetime"
          value={startDateInt}
          closeOnSelectDate={true}
          utc={true}
          disabled={disabled}
          required={startDateInt && !isStandard}
        />
        <FormInput
          id="endDate"
          label={`End Date (UTC)${!isStandard ? ' *' : ''}`}
          type="datetime"
          closeOnSelectDate={true}
          value={endDateInt}
          utc={true}
          disabled={disabled}
          required={endDateInt && !isStandard}
        />
      </FormRow>

      {!isArchiveSub && (
        <>
          <FormRow>
            <InputButton
              id="isInvoiceable"
              label="Invoice Contract"
              type="checkbox"
              value="true"
              isChecked={isInvoiceable}
              disabled={disabled}
              ref={assignRef}
            />
          </FormRow>

          <h3 className="form-row-header">
            <strong>Custom Uplifts</strong>
          </h3>

          <FormRow>
            {Object.keys(customUpliftsFormatted).map((key) => {
              const { id, value, label } = customUpliftsFormatted[key];
              return (
                <FormInput
                  key={key}
                  id={`customUplifts.${id}`}
                  label={`${label} % *`}
                  required={true}
                  defaultValue={value}
                  disabled={disabled}
                />
              );
            })}
          </FormRow>

          <h3 className="form-row-header">
            <strong>Discretionary Discount</strong>
          </h3>

          <FormRow>
            {Object.keys(discretionaryDiscountFormatted).map((key) => {
              const { id, value, label } = discretionaryDiscountFormatted[key];
              return (
                <FormInput
                  key={key}
                  id={`discretionaryDiscount.${id}`}
                  label={`${label} % *`}
                  required={true}
                  defaultValue={value}
                  disabled={disabled}
                />
              );
            })}
          </FormRow>

          <h3 className="form-row-header">
            <strong>Other Discounts &amp; Uplifts</strong>
          </h3>

          <FormRow>
            <FormInput
              id="entityUplift"
              label="Entity Uplift % *"
              required={true}
              defaultValue={entityUpliftFormatted}
              disabled={disabled}
            />
            <FormInput
              id="volumeDiscount"
              label="Volume Discount % *"
              required={true}
              defaultValue={volumeDiscountFormatted}
              disabled={disabled}
            />
          </FormRow>

          <h3 className="form-row-header">
            <strong>Exclusivity Uplift</strong>
          </h3>

          {Object.keys(exclusivityHoldsFormatted).map((key) => {
            const { id, value, label } = exclusivityHoldsFormatted[key];
            return (
              <FormRow key={`${key}${value}`}>
                <FormInput
                  key={`${key}${value}`}
                  type="select"
                  id={`exclusivityHolds.${id}`}
                  label={label}
                  options={exclusivityUplifts}
                  defaultValue={value}
                  clearable={false}
                  disabled={disabled}
                />
              </FormRow>
            );
          })}

          {displayAnalyticsVcBillingType && (
            <>
              <h3 className="form-row-header">
                <strong>Analytics Charges</strong>
              </h3>

              <div className="analytics-vc-mode">
                <label>Vessel Classification</label>
                <div className="analytics-vc-mode-inputs">
                  <InputButton
                    id="analyticsVcSubscription"
                    name="analyticsVcBillingType"
                    label="Subscription"
                    type="radio"
                    value="analyticsVcSubscription"
                    isChecked={isAnalyticsVcSubscription}
                    disabled={disabled}
                    onChange={(value) => handleRadioGroupChange('analyticsVcBillingType', value)}
                  />
                  <InputButton
                    id="analyticsVcUplift"
                    name="analyticsVcBillingType"
                    label="Uplift"
                    type="radio"
                    value="analyticsVcUplift"
                    isChecked={!isAnalyticsVcSubscription}
                    disabled={disabled}
                    onChange={(value) => handleRadioGroupChange('analyticsVcBillingType', value)}
                  />
                  {!contractAnalyticsSubscription && (
                    <FormInput
                      id="pricingOverrides.analyticImageryUplifts.VC"
                      required={true}
                      disabled={disabled}
                      defaultValue={analyticsVcPricingOverrideFormatted}
                    />
                  )}
                </div>
              </div>

            </>
          )}
        </>
      )}

      {showSalesforce && (
        <>
          <h3 className="form-row-header">
            <strong>Salesforce</strong>
          </h3>

          <FormRow>
            <FormInput
              id="salesforceContractId"
              label="Contract ID"
              defaultValue={salesforceContractId}
              disabled={disabled}
            />
            <FormInput
              id="salesforceOpportunityId"
              label="Opportunity ID"
              defaultValue={salesforceOpportunityId}
              disabled={disabled}
            />
          </FormRow>
        </>
      )}

      <FormRow className="form-row-actions">
        <Button full={true} disabled={disabled}>
          Save Contract
        </Button>
        <Button
          full={true}
          type="text"
          disabled={disabled}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </FormRow>

      <FormRow>
        <p className="caption">* Field is required</p>
      </FormRow>
    </Form>
  );
};

FormContractEdit.propTypes = {
  contract: PropTypes.object,
  licenses: PropTypes.object,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showSalesforce: PropTypes.bool,
  organization: PropTypes.object
};

export default FormContractEdit;
