import { Circle, GeometryUtil, Rectangle } from 'leaflet';
import turfCircle from '@turf/circle';
import { constructRequestActionManager } from 'commonLib/src/lib/actions';
import { routePathByName } from 'lib/routes';
import { shapeAoiPayload } from 'lib/aoi';
import { AOI_SHAPE_RANGE, GEO_SUBTYPES } from 'data/aois';
import { ROUTE_API_AOIS, ROUTE_API_AOI, ROUTE_API_AOIS_SEARCH } from 'commonLib/src/data/route-names';
import { triggerNotice, clearNotice } from 'state/actions';

const { min: minShapeSize } = AOI_SHAPE_RANGE;

/**
 * Update the status of the AOIs store
 * @param  {string} status - status of the AOIs store
 */
export function setAOIsActionState (status) {
  return {
    type: 'UPDATE_AOIS_ACTION_STATE',
    data: status
  };
}

const createDispatchRequestAction =
  constructRequestActionManager(setAOIsActionState);

function aoiSearchPayloadFromSearchTerm (searchTerm) {
  if (!searchTerm || searchTerm === '') {
    return {};
  }

  return {
    query: {
      name: {
        contains: searchTerm
      }
    }
  };
}

/**
 * Fetch paged list of aois (scoped to requesting user) & update aois store
 * @returns {object} response data
 */
export const fetchAOIs = (searchQuery = '') => {
  return async (dispatch, getState) => {
    const searchPayload = aoiSearchPayloadFromSearchTerm(searchQuery);
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchAOIs',
      scope: 'aois',
      request: {
        url: routePathByName(ROUTE_API_AOIS_SEARCH),
        method: 'post',
        data: searchPayload
      }

    });

    const request = await dispatchAction(dispatch, getState);
    const { data = [] } = request;

    dispatch(updateAOIs(data));

    return data;
  };
};

/**
 * GET / FETCH an AOI by it's ID
 * @param {string} aoiId AOI ID to fetch
 * @returns {object} response data
 */

export const fetchAOI = (aoiId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchAOI',
      scope: 'aois',
      request: {
        url: routePathByName(ROUTE_API_AOI, {
          wildcard: aoiId
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request;

    const { aois: aoisState = {} } = getState();
    const { aois = [] } = aoisState;

    const updatedAois = [data, ...aois];
    dispatch(updateAOIs(updatedAois));

    return data;
  };
};

/**
 * POST new AOI creating a new AOI record
 * @param {object} payload body payload to pass to create AOI endpoint
 * @returns {object} response data
 */

export const postAOI = (payload) => {
  const aoiPayload = shapeAoiPayload(payload);

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'postAOI',
      scope: 'aois',
      request: {
        url: routePathByName(ROUTE_API_AOIS),
        method: 'post',
        data: aoiPayload
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request;

    dispatch(addAOI(data));

    return data;
  };
};

/**
 * PATCH updates to an existing AOI
 * @param {object} payload body payload to pass to create AOI endpoint
 * @returns {object} response data
 */

export const patchAOI = (payload, aoiId) => {
  const aoiPayload = shapeAoiPayload(payload);

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'patchAOI',
      scope: 'aois',
      request: {
        url: routePathByName(ROUTE_API_AOI, {
          wildcard: aoiId
        }),
        method: 'patch',
        data: aoiPayload
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request;

    dispatch(updateAOI(data));

    return data;
  };
};

/**
 * DELETE removes an existing AOI
 * @param {string} aoiId ID of AOI to delete
 * @returns {object} response data
 */

export const deleteAOI = (aoiId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'deleteAOI',
      scope: 'aois',
      request: {
        url: routePathByName(ROUTE_API_AOI, {
          wildcard: aoiId
        }),
        method: 'delete'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request;

    dispatch(deleteAOIState(aoiId));

    return data;
  };
};

// Validate layer Area, display alert, etc.
const _validateLayer = (layer, leafletElement, dispatch) => {
  let json;
  // Convert circle to polygon circle
  if (layer instanceof Circle) {
    // Minimum circle size "length" of 5km
    if (layer._mRadius < minShapeSize.meters) {
      const newLayer = layer;
      newLayer._mRadius = minShapeSize.meters;
      leafletElement.removeLayer(layer);
      leafletElement.addLayer(newLayer);
    }
    dispatch(clearNotice());
    const center = [layer._latlng.lng, layer._latlng.lat];
    const options = { steps: 64, units: 'meters' };
    const polygonCircle = turfCircle(center, layer.getRadius(), options);
    json = {
      ...polygonCircle,
      properties: {
        ...polygonCircle.properties,
        radius: layer._mRadius,
        centroid: { coordinates: center },
        subType: GEO_SUBTYPES.CIRCLE
      }
    };
  } else {
    const area = GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
    // Polygons ideally have an area at least equivalent to 5km² rectangle (ie. Spotlight)
    if (area < Math.pow(minShapeSize.meters, 2)) {
      leafletElement.removeLayer(layer);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: `Shape area less than ${minShapeSize.friendlyLabel}, please draw a larger shape for the best results.`
        })
      );
      return undefined;
    } else {
      // Area >= 5kmx5km so clear old notices if present
      dispatch(clearNotice());
      json = layer.toGeoJSON();
      // preserves editable rectangle
      if (layer instanceof Rectangle) {
        json = {
          ...json,
          properties: {
            ...json.properties,
            subType: GEO_SUBTYPES.RECTANGLE
          }
        };
      }
    }
  }
  return json;
};

/**
 * Share leaflet draw & edit handler for aois create & edit
 * Dispatches alerts if shape geometry is under a certain area
 * @param {object} leafletDrawElement Leaflet Draw element that is used to watch & control draw geometry stuff
 * @returns {object|undefined} aoiGeometry (GeoJSON)
 */
export const handleAoiDraw = (leafletDrawElement, layer) => {
  let aoiGeometry;
  return async (dispatch, getState) => {
    // No existing layer (Create AOI Page)
    if (Object.keys(leafletDrawElement?._layers || {}).length) {
      leafletDrawElement.eachLayer(async (layer) => {
        aoiGeometry = _validateLayer(layer, leafletDrawElement, dispatch);
      });
    } else {
      // Existing layer on map (Edit AOI Page)
      aoiGeometry = _validateLayer(layer, leafletDrawElement, dispatch);
    }
    return aoiGeometry;
  };
};

export const handleAoiEdit =
  (leafletEditableElement, layer) => async (dispatch, getState) => {
    return _validateLayer(layer, leafletEditableElement, dispatch);
  };

/******************
 * STATE UPDATERS *
 ******************/

/**
 * updateAOIS
 * @param  {object} data - AOIs array to keep in aois store
 */
export const updateAOIs = (data) => ({
  type: 'UPDATE_AOIS',
  data
});

/**
 * updateAOI
 * @param  {object} data - AOI to update
 */
export const updateAOI = (data) => ({
  type: 'UPDATE_AOI',
  data
});

/**
 * addAOI
 * @param  {object} data - AOI to add to aois state
 */
export const addAOI = (data) => ({
  type: 'ADD_AOI',
  data
});

/**
 * deleteAOIState
 * @param  {object} id - ID of AOI to delete
 */
export const deleteAOIState = (id) => ({
  type: 'DELETE_AOI',
  id
});

export const updateAOIsSearchTerm = (searchTerm) => ({
  type: 'UPDATE_AOIS_SEARCH_TERM',
  searchTerm
});
