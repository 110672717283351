import React from 'react';
import Item from '../models/item';

const DEFAULT_ORDER_COUNT = 5;
const DEFAULT_TASK_COUNT = 5;
const DEFAULT_TASKS_COLUMNS = 6;
const DEFAULT_ITEM_COUNT = 3;
const DEFAULT_USER_COUNT = 5;
const DEFAULT_ORGANIZATION_COUNT = 5;
const DEFAULT_COLLECTION_COUNT = 5;
const DEFAULT_CONTRACT_COUNT = 5;

const FILLER_DATE = Date.now();

/**
 * fillEmptyUser
 * @description Returns an empty user
 */

export function fillEmptyUser () {
  return {
    id: 'Loading',
    familyName: 'Loading',
    givenName: 'Loading',
    email: 'Loading',
    roles: ['Loading'],
    organization: {
      name: 'Loading'
    },
    plan: 'Loading'
  };
}

/**
 * fillEmptyUsers
 * @description Given the count, returns an array of empty users
 */

export function fillEmptyUsers (count = DEFAULT_USER_COUNT) {
  return Array.apply(null, Array(count)).map(fillEmptyUser);
}

/**
 * fillEmptyOrganization
 * @description Returns an empty organization
 */

export function fillEmptyOrganization () {
  return {
    name: 'Loading',
    type: 'Loading',
    address: {
      street: 'Loading',
      city: 'Loading',
      state: 'Loading',
      zip: 'Loading'
    },
    users: 'Loading',
    contractType: 'Loading'
  };
}

/**
 * fillEmptyOrganizations
 * @description Given the count, returns an array of empty organizations
 */

export function fillEmptyOrganizations (count = DEFAULT_ORGANIZATION_COUNT) {
  return Array.apply(null, Array(count)).map(fillEmptyOrganization);
}

/**
 * fillEmptyItem
 * @description Returns an empty item
 */

export function fillEmptyItem () {
  return new Item({
    id: 'Loading',
    properties: {
      collection: 'Loading',
      start_datetime: FILLER_DATE,
      end_datetime: FILLER_DATE,
      'sar:constellation': 'Loading',
      instruments: 'Loading'
    },
    assets: {
      HH: {
        title: 'Data File'
      },
      metadata: {
        title: 'Extended Metadata'
      },
      preview: {
        title: 'Preview Image'
      },
      digest: {
        title: 'Digest File'
      },
      STAC: {
        title: 'STAC File'
      }
    }
  });
}

/**
 * fillEmptyItems
 * @description Given the count, returns an array of empty items
 */

export function fillEmptyItems (count = DEFAULT_ITEM_COUNT) {
  return Array.apply(null, Array(count)).map(fillEmptyItem);
}

/**
 * fillEmptyTask
 * @description Given the count, returns an empty task
 */

export function fillEmptyTask () {
  return {
    id: 'Loading',
    name: 'Loading',
    contractId: 'Loading',
    description: 'Loading',
    status: 'Loading',
    collectMode: 'Loading',
    windowClose: FILLER_DATE,
    windowOpen: FILLER_DATE,
    productCategory: 'Loading',
    taskRequestType: 'Loading'
  };
}

/**
 * fillEmptyTasks
 * @description Given the count, returns an array of empty tasks
 */

export function fillEmptyTasks (count = DEFAULT_TASK_COUNT, columns = DEFAULT_TASKS_COLUMNS) {
  return Array.apply(null, Array(count)).map((val, index) => (
    <tr key={index}>
      {Array.apply(null, Array(columns)).map((val, i) => (
        <td key={index + i} className="content-is-loading" ><span>Loading</span></td>
      )
      )}
    </tr>
  ));
}

/**
 * fillEmptyOrder
 * @description Given the count, returns an empty order
 */

export function fillEmptyOrder () {
  return {
    id: 'Loading',
    userId: 'Loading',
    userName: 'Loading',
    organizationName: 'Loading',
    contractLabel: 'Loading',
    orderId: 'Loading',
    orderDate: FILLER_DATE,
    expirationDate: FILLER_DATE,
    status: 'Loading',
    items: []
  };
}

/**
 * fillEmptyOrders
 * @description Given the count, returns an array of empty orders
 */

export function fillEmptyOrders (count = DEFAULT_ORDER_COUNT) {
  return Array.apply(null, Array(count)).map(fillEmptyOrder);
}

/**
 * orderIdIsValid
 * @description Checks to see if the order ID is a valid value type for the route
 */

export function orderIdIsValid (id) {
  if (id === 'order' || id === 'orders') return false;
  if (typeof id === 'string') return true;
  if (typeof id === 'number') return true;
  return false;
}
/**
 * taskIdIsValid
 * @description Checks to see if the task ID is a valid value type for the route
 */

export function taskIdIsValid (id) {
  if (id === 'task' || id === 'tasks') return false;
  if (typeof id === 'string') return true;
  if (typeof id === 'number') return true;
  return false;
}

/**
 * Checks to see if the repeat request ID is a valid value type for the route, extends from taskIdIsValid
 * @returns {Boolean} - is valid type or not
 */

export function repeatRequestIdIsValid (id) {
  if (id === 'repeatRequest' || id === 'repeatRequests') return false;
  return taskIdIsValid(id);
}

/**
 * fillEmptyUser
 * @description Returns an empty user
 */

export function fillEmptyCollection () {
  return {
    id: 'loading',
    title: 'Loading',
    description: 'Loading',
    keywords: ['Loading', 'Loading'],
    summaries: {
      loading1: ['Loading'],
      loading2: ['Loading']
    },
    extent: {
      loading1: ['Loading', 'Loading', 'Loading', 'Loading'],
      loading2: ['Loading', 'Loading', 'Loading', 'Loading']
    },
    stacVersion: 'Loading',
    stacExtensions: ['Loading']
  };
}

/**
 * fillEmptyCollections
 * @description Given the count, returns an array of empty collections
 */

export function fillEmptyCollections (count = DEFAULT_COLLECTION_COUNT) {
  return Array.apply(null, Array(count)).map(fillEmptyCollection);
}

export function fillEmptyContract () {
  return {
    id: 'Loading',
    name: 'Loading',
    startDate: 'Loading',
    endDate: 'Loading',
    contractType: 'Loading'
  };
}

export function fillEmptyContracts (count = DEFAULT_CONTRACT_COUNT) {
  return Array.apply(null, Array(count)).map(fillEmptyContract);
}
