import { useSubResellerAllUsers } from 'hooks';

export default function useSubResellerUser ({ id }) {
  if (typeof id === 'undefined') {
    throw new Error('Failed to get subreseller user: Invalid ID');
  }

  const { users = [] } = useSubResellerAllUsers();
  const user = users.find(({ id: userId }) => userId === id);

  return {
    user
  };
}
