import React from 'react';

import TemplateOrders from 'templates/TemplateOrders';

import { useFetchOrders, useOrders } from 'hooks';
import { routePathByName } from 'lib/routes';

export default function OrdersPage () {
  // setting forceNew makes sure we always get updated orders when we visit the page
  const { isLoading } = useFetchOrders({ forceNew: true });
  const { orders = [] } = useOrders();

  const ordersState = {
    isLoading
  };

  const noOrdersActions = [
    {
      to: routePathByName('tasks'),
      label: 'Go to Tasks'
    },
    {
      to: routePathByName('search'),
      label: 'Go to Search'
    }
  ];

  const columns = [
    'orderId',
    'contractLabel',
    'orderDate',
    'status',
    'actions'
  ];

  return (
    <TemplateOrders
      state={ordersState}
      orders={orders}
      tableColumns={columns}
      emptyActions={noOrdersActions}
    />
  );
}
