import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useUser, useOrders } from 'hooks';

import { logError } from 'lib/logger';
import { fetchAllOrders, triggerNotice } from 'state/actions';

export default function useSubResellerFetchOrders ({ forceNew = false } = {}) {
  const dispatch = useDispatch();

  const { orders, actions = {} } = useOrders();
  const { user = {} } = useUser();
  const { organizationId } = user;

  useEffect(() => {
    if (typeof organizationId === 'string') {
      dispatch(fetchAllOrders(organizationId, 'resellerId')).catch((error) => {
        logError(error.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      });
    }
  }, [organizationId, forceNew, dispatch]);

  return {
    ...actions.fetchAllOrders,
    orders
  };
}
