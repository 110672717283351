import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FaUser } from 'react-icons/fa';
import {
  DataTable,
  TableSearchInput,
  TableActions,
  TableSearchFilters
} from 'fogg/ui';
import { useTableData } from 'fogg/hooks';

import {
  sortByKey,
  fillEmptyUsers,
  usersToTableData,
  routePathByName
} from '../lib';

import NavigatorLayout from '../components/NavigatorLayout';
import NavigatorSidebar from '../components/NavigatorSidebar';
import NavigatorBody from '../components/NavigatorBody';
import Breadcrumbs from '../components/Breadcrumbs';
import PageHeader from '../components/PageHeader';
import Container from '../components/Container';

const DEFAULT_COLUMNS = [
  {
    Header: 'Last Name',
    columnId: 'lastName',
    widthRatio: 2
  },
  {
    Header: 'First Name',
    columnId: 'firstName',
    widthRatio: 2
  },
  {
    Header: 'Email',
    columnId: 'email',
    widthRatio: 2
  },
  {
    columnId: 'role',
    includeColumn: false
  },
  {
    columnId: 'status',
    includeColumn: false
  },
  {
    columnId: 'actions',
    Header: false,
    align: 'right',
    type: 'action',
    canSort: false,
    canFilter: false,
    widthRatio: 1
  }
];
const FILTER_MENU_OPTIONS = [
  {
    columnId: 'status',
    type: 'radio',
    Header: 'Status'
  }
];

const TemplateUsers = ({
  state = {},
  options = {},
  users,
  tableColumns = DEFAULT_COLUMNS,
  breadcrumbs = [],
  emptyActions = []
}) => {
  const { detailsRouteName, createRouteName, itemName = 'User' } = options;

  const { isLoading = false } = state;

  let activeUsers = users && [...users];

  // If we're loading, fill the data with empty users to add a loading state

  if (isLoading) {
    activeUsers = fillEmptyUsers();
  }

  activeUsers = sortByKey(activeUsers, 'familyName');

  const usersData = usersToTableData(activeUsers, {
    editPathName: detailsRouteName
  });

  const { columns, data, sort, filter, clearFilters, filters } = useTableData({
    columns: tableColumns,
    data: usersData
  });

  const searchFilters = filters.find(
    ({ filterType } = {}) => filterType === 'search'
  );
  const { filterValue } = searchFilters || {};

  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;

  /**
   * handleOnSort
   */

  function handleOnSort (cell) {
    sort(cell);
  }

  /**
   * handleOnFilter
   */

  function handleOnFilter (filterData, cell) {
    filter(filterData, cell);
  }

  /**
   * handleOnSearchChange
   */

  function handleOnSearchChange ({ currentTarget = {} } = {}) {
    const { value } = currentTarget;

    const filterData = {
      value
    };

    handleOnFilter(filterData);
  }

  /**
   * handleOnFiltersChange
   */

  function handleOnFiltersChange ({ columnId, selectedOptions } = {}) {
    const checkedOptions = selectedOptions.filter(
      ({ isChecked } = {}) => !!isChecked
    );
    const values = checkedOptions.map(({ value } = {}) => value);

    const filterData = {
      value: values
    };

    const cell = {
      filterType: 'checklist',
      columnId
    };

    handleOnFilter(filterData, cell);
  }

  return (
    <NavigatorLayout className="layout-navigator fixed-footer">
      <Helmet bodyAttributes={{ class: 'template-users' }}>
        <title>Users</title>
      </Helmet>

      <Container type="full">
        <NavigatorSidebar>
          {hasBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}

          <PageHeader title="Users" icon={<FaUser className="icon-fa" />} />

          <TableSearchInput
            value={filterValue}
            onChange={handleOnSearchChange}
            onClear={clearFilters}
          />

          <TableSearchFilters
            isLoading={isLoading}
            options={FILTER_MENU_OPTIONS}
            defaultTableData={usersData}
            onChange={handleOnFiltersChange}
          />
        </NavigatorSidebar>

        <NavigatorBody>
          <TableActions
            actions={[
              {
                to: routePathByName(createRouteName),
                label: `Create a New ${itemName}`,
                buttonType: ['text', 'icon-before'],
                icon: 'FaPlusCircle'
              }
            ]}
          />

          <div className="data-table-wrapper with-side-layout">
            <DataTable
              label="Users"
              onSort={handleOnSort}
              columns={columns}
              data={data}
              isLoading={isLoading}
              emptyActions={emptyActions}
            />
          </div>
        </NavigatorBody>
      </Container>
    </NavigatorLayout>
  );
};

TemplateUsers.propTypes = {
  state: PropTypes.object,
  options: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableColumns: PropTypes.array,
  breadcrumbs: PropTypes.array,
  emptyActions: PropTypes.array
};

export default TemplateUsers;
