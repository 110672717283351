import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useItems } from 'hooks';

import { logError } from 'lib/logger';
import { errorMessageByCode } from 'lib/errors';
import { fetchStacRecordById, triggerNotice } from 'state/actions';

export default function useFetchStac ({ itemId, collectionId }) {
  const dispatch = useDispatch();

  const { actions = {}, stacs } = useItems();

  /**
   * _fetchStacRecordById
   */

  function _fetchStacRecordById () {
    if (!collectionId || !itemId) return;
    dispatch(
      fetchStacRecordById({
        collectionId,
        itemId
      })
    ).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchStac = useCallback(() => {
    _fetchStacRecordById();
  }, []); // eslint-disable-line

  useEffect(() => {
    memoizedFetchStac();
  }, [memoizedFetchStac]);

  return {
    fetchStacRecordById: memoizedFetchStac,
    ...actions.fetchStacRecordById,
    stacs
  };
}
