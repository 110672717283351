// shared lib/currency
export * from 'commonLib/src/lib/currency';

// console specific lib/currency can go below here
/**
 * formats Infinity number to a string format
 * @param {number} [number=Infinity] - number to format
 * @param {string} [output='N/A'] - format to return if number is an Infinity value
 * @returns {string|number} if the argument number is an Infinity value, return the argument output, otherwise return the number
 */
export function formatInfinityToString ({
  number = Infinity,
  output = 'N/A'
}) {
  let outNumber = number;

  if (number === Infinity || number === -Infinity) {
    outNumber = 'N/A';
  }

  return outNumber;
}
