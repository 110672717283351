import clone from 'clone';

import { exportClassData, updateClassData, sortByDateKey } from 'lib/util';
import {
  DEFAULT_LOOK_DIRECTION,
  DEFAULT_ASC_DSC,
  DEFAULT_LOCAL_TIME,
  DEFAULT_OFF_NADIR_MIN,
  DEFAULT_OFF_NADIR_MAX,
  DEFAULT_COLLECT_MODE,
  DEFAULT_IMAGE_LENGTH,
  DEFAULT_IMAGE_WIDTH,
  DEFAULT_GRR_MIN,
  DEFAULT_GRR_MAX,
  DEFAULT_AZR_MIN,
  DEFAULT_AZR_MAX,
  DEFAULT_NESZ_MAX,
  DEFAULT_NUM_LOOKS,
  DEFAULT_COLLECTION_TIER,
  DEFAULT_RANGE_MODE,
  DEFAULT_ELEVATION_MIN,
  DEFAULT_ELEVATION_MAX,
  DEFAULT_SQUINT,
  DEFAULT_RADAR_PARAMETERS_BANDWIDTH,
  DEFAULT_RADAR_PARAMETERS_CENTER_FREQUENCY,
  DEFAULT_RADAR_PARAMETERS_PRF,
  DEFAULT_SRR_MIN,
  DEFAULT_SRR_MAX
} from 'lib/tasks';
import { TASKING_REQUEST_ID, REPEAT_REQUEST_ID } from 'data/property-names';
import { TaskRequestTypes } from 'data/task-request-types';

const defaultTask = {
  contractId: '',
  type: 'Feature',
  geometry: null,
  properties: {
    submissionTime: null,
    [TASKING_REQUEST_ID]: null,
    taskingrequestName: null,
    taskingrequestDescription: null,
    orgId: null,
    userId: null,
    repeatrequestId: null,
    sandbox: false,
    windowOpen: null,
    windowDuration: null,
    windowClose: null,
    collectionTier: DEFAULT_COLLECTION_TIER,
    analytics: null,
    statusHistory: [],
    collectConstraints: {
      lookDirection: DEFAULT_LOOK_DIRECTION,
      targetHeight: null,
      orbitalPlanes: [],
      ascDsc: DEFAULT_ASC_DSC,
      localTime: DEFAULT_LOCAL_TIME,
      offNadirMin: DEFAULT_OFF_NADIR_MIN,
      offNadirMax: DEFAULT_OFF_NADIR_MAX,
      collectMode: DEFAULT_COLLECT_MODE,
      imageWidth: DEFAULT_IMAGE_WIDTH,
      imageLength: DEFAULT_IMAGE_LENGTH,
      grrMax: DEFAULT_GRR_MAX,
      grrMin: DEFAULT_GRR_MIN,
      azrMax: DEFAULT_AZR_MAX,
      azrMin: DEFAULT_AZR_MIN,
      neszMax: DEFAULT_NESZ_MAX,
      numLooks: DEFAULT_NUM_LOOKS,
      polarization: null,
      mode: DEFAULT_RANGE_MODE,
      elevationMin: DEFAULT_ELEVATION_MIN,
      elevationMax: DEFAULT_ELEVATION_MAX,
      squint: DEFAULT_SQUINT,
      radarParametersBandwidth: DEFAULT_RADAR_PARAMETERS_BANDWIDTH,
      radarParametersCenterFrequency: DEFAULT_RADAR_PARAMETERS_CENTER_FREQUENCY,
      radarParametersPrf: DEFAULT_RADAR_PARAMETERS_PRF,
      srrMin: DEFAULT_SRR_MIN,
      srrMax: DEFAULT_SRR_MAX
    },
    preApproval: false,
    order: undefined,
    transactionStatus: undefined,
    taskRequestType: TaskRequestTypes.STANDARD,
    costBreakdown: undefined,
    conflictId: undefined,
    customAttribute1: undefined,
    customAttribute2: undefined,
    userName: '',
    organizationName: ''
  }
};

/**
 * BaseTask instance
 */
class BaseTask {
  /**
   * static method mapping user object to task object
   * @param {array} tasks - array of tasks
   * @param {array} users - array of users
   * @param {instance} [TaskInstance=BaseTask] - instance of task, using this class instance as default
   * @returns {array} - array of objects as defined instances
   */
  static mapUsersToTasks (tasks, users, TaskInstance = BaseTask) {
    return tasks.map((task) => {
      const taskWithUser = {
        ...task,
        user: users.find(({ id } = {}) => id === task.properties?.userId)
      };

      const instance = new TaskInstance();

      if (typeof instance.ingestTaskBody === 'function') {
        instance.ingestTaskBody(taskWithUser);
        return instance;
      }

      return instance;
    });
  }

  static mapContractsToTasks (tasks, contracts, contractRouteName = null) {
    return tasks.map((task) => {
      const {
        properties: {
          taskingrequestId = '',
          contractId: taskContractId = null
        }
      } = task;
      const contract = contracts.find(({ id }) => id === taskContractId);
      return {
        ...task,
        contractId: [contract?.id, contract?.label, contractRouteName, taskingrequestId]
      };
    });
  }

  /**
   * constructor
   * @param {object} data - task data
   */
  constructor (data = {}) {
    Object.assign(this, data);
    this._data = clone(defaultTask);
    this._customProps = {};
  }

  ingestTaskBody (data) {
    updateClassData(this._data, {
      ...data
    });

    this.updateInstanceProperties();

    return this;
  }

  updateInstanceProperties () {
    this.contractId = this._data.contractId;
    this.geometry = this._data.geometry && { ...this._data.geometry };
    this.name = this._data.properties.taskingrequestName;
    this.description = this._data.properties.taskingrequestDescription;
    this.taskingrequestType = this._data.properties.taskingrequestType;
    this.id = this._data.properties[TASKING_REQUEST_ID];
    this.windowOpen = this._data.properties.windowOpen;
    this.windowOpenInt = new Date(this.windowOpen).getTime();
    this.windowClose = this._data.properties.windowClose;
    this.windowCloseInt = new Date(this.windowClose).getTime();
    this.collectionType = this._data.properties.collectionType;
    this.collectionTier = this._data.properties.collectionTier;
    this.collectMode = this._data.properties.collectConstraints.collectMode;
    this.preApproval = this._data.properties.preApproval;
    this.user = this._data.user;
    this.order = this._data.properties.order;
    this.transactionStatus = this._data.properties.transactionStatus;
    this.orbitalPlanes = this._data.properties.collectConstraints.orbitalPlanes;
    this.ascDsc = this._data.properties.collectConstraints.ascDsc;
    this.lookDirection = this._data.properties.collectConstraints.lookDirection;
    this.targetHeight = this._data.properties.collectConstraints.targetHeight;
    this.productCategory = this._data.properties.productCategory;
    this.archiveHoldback = this._data.properties.archiveHoldback;
    this.collectConstraints = this._data.properties.collectConstraints;
    this.taskRequestType = this._data.properties.taskRequestType;
    this.repeatRequestId = this._data.properties[REPEAT_REQUEST_ID];
    this.analytics = this._data.properties.analytics || this._data.properties.processingConfig?.productTypes || undefined;
    this.conflictId = this._data.properties.conflictId;
    this.customAttribute2 = this._data.properties.customAttribute2;
    this.customAttribute1 = this._data.properties.customAttribute1;
    this.submissionTime = this._data.properties.submissionTime;
    // Latest Status is the latest status TIME
    this.latestStatus = this._data.properties.statusHistory?.length
      ? this._data.properties.statusHistory[0].time
      : undefined;
    this.properties = this._data.properties;
    this.userName = this._data.properties.userName || this.userName;
    this.organizationName = this._data.properties.organizationName || this.organizationName;
  }

  update (data = {}) {
    const task = updateClassData(this._data, data);
    this.updateInstanceProperties();
    return task;
  }

  data () {
    return exportClassData(this);
  }

  /**
   * set custom properties to the object without colliding with base props
   *
   * @param {string} property property key identifier
   * @param {any} value
   * @returns {{ [k keyof property]: any }}
   */
  setCustomProperty (property, value) {
    this._customProps[property] = value;
    return value;
  }

  /**
   * get custom properties by key value identifier
   *
   * @param {string} property key value identifier
   * @returns {{ [k keyof property]: any }}
   */
  getCustomProperty (property) {
    return this._customProps[property];
  }

  get lastStatus () {
    const history = this._data.properties.statusHistory;

    if (!Array.isArray(history)) return undefined;

    const sorted = sortByDateKey(history, 'time');
    const length = sorted.length;

    return sorted[length - 1];
  }

  get status () {
    return this.lastStatus && this.lastStatus.code;
  }

  get statusMessage () {
    return this.lastStatus && this.lastStatus.message;
  }

  get isSandbox () {
    return !!(this._data.properties && this._data.properties.sandbox);
  }

  get taskRequestTypeLabel () {
    if (this.taskRequestType === TaskRequestTypes.REPEAT) {
      return TaskRequestTypes.properties[TaskRequestTypes.REPEAT].label;
    }

    return TaskRequestTypes.properties[TaskRequestTypes.STANDARD].label;
  }

  get userId () {
    return this._data.properties.userId;
  }

  get organizationId () {
    return this._data.properties.orgId;
  }
}

export default BaseTask;
