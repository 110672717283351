import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';

import { routePathByName } from 'lib/routes';
import { ROUTE_SUB_RESELLER, ROUTE_SUB_RESELLER_ORGS, ROUTE_SUB_RESELLER_ORG_CONTRACT_DETAILS } from 'data/route-names';

import {
  useSubResellerFetchOrganization,
  useFetchOrganizationCollections
} from 'hooks';

import {
  addOrganizationCollectionPermissions,
  removeOrganizationCollectionPermission
} from 'state/actions';

import { TemplateOrganizationDetails } from 'commonLib';
import { SUB_RESELLER_ROLE } from 'data/roles';

const SubResellerOrganizationsDetails = ({ organizationId }) => {
  const dispatch = useDispatch();

  const {
    organization = {},
    isLoading,
    parentOrganization = {},
    isLoadingParent
  } = useSubResellerFetchOrganization({
    id: organizationId
  });

  useFetchOrganizationCollections({
    organizationId
  });

  if (parentOrganization.name) {
    organization.resellerOrg = parentOrganization;
  }

  const organizationState = {
    isLoading: isLoading || isLoadingParent
  };

  function handleUpdateActiveCollections ({ updates }) {
    updates.forEach(({ id, isChecked } = {}) => {
      if (isChecked) {
        dispatch(addOrganizationCollectionPermissions(organizationId, [id]));
      } else {
        dispatch(removeOrganizationCollectionPermission(organizationId, id));
      }
    });
  }

  const breadcrumbs = [
    {
      label: 'Sub-Reseller',
      to: routePathByName(ROUTE_SUB_RESELLER)
    },
    {
      label: 'Organizations',
      to: routePathByName(ROUTE_SUB_RESELLER_ORGS)
    }
  ];

  return (
    <TemplateOrganizationDetails
      organization={organization}
      accessType={SUB_RESELLER_ROLE}
      breadcrumbs={breadcrumbs}
      state={organizationState}
      onUpdateCollections={handleUpdateActiveCollections}
      contractDetailsRouteName={ROUTE_SUB_RESELLER_ORG_CONTRACT_DETAILS}
      contractDetailsRouteOptions={[organizationId]}
    />
  );
};

SubResellerOrganizationsDetails.propTypes = {
  organizationId: PropTypes.string
};

export default SubResellerOrganizationsDetails;
