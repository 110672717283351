import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import Organization from 'models/organization';
import { useOrg } from 'hooks';
import { logError } from 'lib/logger';
import { errorMessageByCode } from 'lib/errors';
import { triggerNotice, fetchOrganization } from 'state/actions';

export default function useOrgFetchOrganization () {
  const dispatch = useDispatch();

  const { actions = {}, organization } = useOrg();

  /**
   * fetchOrg
   */

  function fetchOrg () {
    dispatch(fetchOrganization()).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchOrg = useCallback(() => {
    fetchOrg();
  }, []); // eslint-disable-line

  useEffect(() => {
    memoizedFetchOrg();
  }, [memoizedFetchOrg]);

  return {
    fetchOrganization: memoizedFetchOrg,
    ...actions.fetchOrganization,
    organization: organization && new Organization(organization)
  };
}
