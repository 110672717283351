import Item from 'commonLib/src/models/item';

const defaultState = {
  items: [],
  addOnItems: [],
  contract: '',
  showContractSelector: false
};

const cart = (state = defaultState, { type, data = {} } = {}) => {
  const item = data ? new Item(data) : new Item();
  const { items, addOnItems = [] } = state;
  let updatedItems, updatedAddOnItems;
  switch (type) {
    case 'ADD_TO_CART': {
      const itemExists = items.findIndex((i) => i.id === item.id) !== -1;
      updatedItems = itemExists ? items : [...items, item];

      return {
        ...state,
        items: updatedItems
      };
    }
    case 'REMOVE_FROM_CART': {
      return {
        ...state,
        items: items.filter((i) => i.id !== item.id)
      };
    }
    case 'UPDATE_CART': {
      const matchingItem = items.findIndex(i => i.id === item.id);
      items[matchingItem] = item;

      return {
        ...state,
        items: items
      };
    }
    case 'ADD_ADDITIONAL_CART_ITEM': {
      const itemExists = addOnItems.findIndex((i) =>
        i.collectId === data.collectId && i.productType === data.productType
      ) !== -1;
      updatedAddOnItems = itemExists ? addOnItems : [...addOnItems, data];

      return {
        ...state,
        addOnItems: updatedAddOnItems
      };
    }
    case 'REMOVE_ADDITIONAL_CART_ITEM': {
      const filteredItems = addOnItems.filter(i =>
        i.collectId !== data.collectId || i.productType !== data.productType
      );

      return {
        ...state,
        addOnItems: filteredItems
      };
    }
    case 'UPDATE_CART_SHOW_CONTRACTS': {
      return {
        ...state,
        showContractSelector: data
      };
    }
    case 'DESTROY_CART': {
      return {
        ...state,
        items: [],
        addOnItems: []
      };
    }
    case 'SET_CONTRACT': {
      return {
        ...state,
        contract: data
      };
    }
    default: {
      return state;
    }
  }
};

export default cart;
