import React from 'react';
import { Helmet } from 'react-helmet';
import { FaBoxes } from 'react-icons/fa';
import { DataTable, TableSearchInput } from 'fogg/ui';
import { useTableData } from 'fogg/hooks';

import {
  useItems,
  useFetchCollections,
  routePathByName,
  collectionsToTableData,
  fillEmptyCollections,
  NavigatorLayout,
  NavigatorSidebar,
  NavigatorBody,
  Breadcrumbs,
  PageHeader,
  Container
} from 'commonLib';

const DEFAULT_COLUMNS = [
  {
    Header: 'Title',
    columnId: 'title',
    widthRatio: 2,
    defaultSorted: 'asc'
  },
  {
    Header: 'ID',
    columnId: 'id',
    widthRatio: 2
  },
  {
    columnId: 'actions',
    Header: false,
    align: 'right',
    type: 'action',
    canSort: false,
    canFilter: false,
    widthRatio: 1
  }
];

const AdminCollectionsPage = () => {
  const { isLoading } = useFetchCollections();
  const { collections } = useItems();

  let activeCollections = collections;

  if (isLoading) {
    activeCollections = fillEmptyCollections();
  }

  const collectionsData = collectionsToTableData(activeCollections, {
    editPathName: 'adminCollectionsDetails'
  });

  const { columns, data, sort, filter, clearFilters, filters } = useTableData({
    columns: DEFAULT_COLUMNS,
    data: collectionsData
  });

  function handleOnSort (cell) {
    sort(cell);
  }

  function handleOnFilter (filterData, cell) {
    filter(filterData, cell);
  }

  const searchFilters =
    filters.find(({ filterType } = {}) => filterType === 'search') || {};
  const { filterValue } = searchFilters;

  /**
   * handleOnSearchChange
   */

  function handleOnSearchChange ({ currentTarget = {} } = {}) {
    const { value } = currentTarget;

    const filterData = {
      value
    };

    handleOnFilter(filterData);
  }

  return (
    <NavigatorLayout>
      <Helmet bodyAttributes={{ class: 'page-admin page-admin-collections' }}>
        <title>Collections</title>
      </Helmet>

      <Container type="full">
        <NavigatorSidebar>
          <Breadcrumbs
            items={[
              {
                label: 'Admin',
                to: routePathByName('admin')
              }
            ]}
          />
          <PageHeader
            title="Collections"
            icon={<FaBoxes className="icon-fa" />}
          />

          <TableSearchInput
            value={filterValue}
            onChange={handleOnSearchChange}
            onClear={clearFilters}
          />
        </NavigatorSidebar>

        <NavigatorBody>
          <DataTable
            label="Collections"
            onSort={handleOnSort}
            columns={columns}
            data={data}
            isLoading={isLoading}
          />
        </NavigatorBody>
      </Container>
    </NavigatorLayout>
  );
};

export default AdminCollectionsPage;
