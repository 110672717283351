import React from 'react';
import { location } from 'fogg/lib';
import { Badge, WonderLink } from 'fogg/ui';
import { FiAlertTriangle } from 'react-icons/fi';

import { routePathByName } from 'lib/routes';
import { formatDate, dateTimeFormats } from 'lib/datetime';
import { ROUTE_TASKS_DETAILS } from 'data/route-names';
import { TaskCollectModes } from 'data/task-collect-modes';
import { TaskCollectionTiers } from 'data/task-collection-tiers';

import {
  tierFilterOptions,
  COMMON_TASKS_TABLE_FILTER_OPTIONS,
  COMMON_REPEAT_REQUESTS_FILTER_OPTIONS
} from 'commonLib/src/lib/tasks';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';

const { queryParamsToObject } = location;

export * from 'commonLib/src/lib/tasks';

// Anything console-specific below...

export const TASKS_TABLE_FILTER_OPTIONS = {
  ...COMMON_TASKS_TABLE_FILTER_OPTIONS,
  analytics: {
    label: 'Analytics',
    type: 'multi',
    options: AnalyticProducts.Types,
    id: 'analytics'
  }
};

export const REPEAT_REQUESTS_FILTER_OPTIONS = {
  ...COMMON_REPEAT_REQUESTS_FILTER_OPTIONS,
  analytics: {
    label: 'Analytics',
    type: 'multi',
    options: AnalyticProducts.Types,
    id: 'analytics'
  }
};

// Use this only as a fallback when necessary. Source of truth should be the /collectiontypes endpoint
const COLLECTION_TYPES_NAMES_VERBOSE = {
  spotlight: 'Spotlight',
  spotlight_wide: 'Spotlight Wide',
  spotlight_ultra: 'Spotlight Ultra',
  spotlight_legacy: 'Spotlight - Legacy',
  sliding_spotlight_legacy: 'Sliding Spotlight - Legacy',
  stripmap_20: 'Stripmap - 20',
  stripmap_50: 'Stripmap - 50',
  stripmap_100: 'Stripmap - 100'
};

/**
 * Get conditional filter options based on predetermined permission.
 *
 * @param {object} props
 * @param {string[]} props.permittedTiers array of permitted collection tier values, used to filter from existing collectionTiers.
 *        See data/task-collection-tiers.js for all tiers
 * @param {boolean} [props.isRepeat=false] filters are for repeat requests or not
 */
export function getFilterOptions ({
  permittedTiers,
  isRepeat = false,
  flags,
  organizationId,
  collectionTypes
}) {
  let filterOptions = TASKS_TABLE_FILTER_OPTIONS;

  const collectionTierFilterOptions = {
    ...TASKS_TABLE_FILTER_OPTIONS.collectionTier,
    options: tierFilterOptions.filter(({ value }) => permittedTiers.includes(value))
  };

  if (isRepeat) {
    filterOptions = REPEAT_REQUESTS_FILTER_OPTIONS;
  }

  if (flags && flags.collectionTypeTaskingFormEnabled) {
    if (flags.collectionTypeTaskingFormEnabled?.organizations?.includes(organizationId)) {
      delete filterOptions.productCategory;
      filterOptions.collectionType.options = collectionTypes?.map(({ name, nameVerbose }) => ({ value: name, label: nameVerbose })) || [];
    } else {
      delete filterOptions.collectionType;
      filterOptions.collectMode.label = 'Imaging Mode';
      collectionTierFilterOptions.label = 'Tasking Tier';
    }
  }

  return {
    ...filterOptions,
    collectionTier: collectionTierFilterOptions
  };
}

// Array of just the analytics PCM Codes to easily check for validity
export const VALID_ANALYTICS_CODES = AnalyticProducts.Types.map(
  (obj) => obj.value
);

// return label based on abbreviation sent by API / PCM
export const getAnalyticLabel = (value) => {
  if (
    !value ||
    typeof value !== 'string' ||
    !VALID_ANALYTICS_CODES.includes(value)
  ) { return; }
  const matchingObject = AnalyticProducts.Types.find(
    (option) => option.value === value
  );
  return matchingObject.label;
};

/**
 * taskFilterParamsToObject
 * @description Convert query params to task table friendly format
 * @param {string} userId ID of the user
 * @param {number} page pagination page
 * @param {number} limit pagination limit per page
 * @returns {object}
 */
export function taskFilterParamsToObject (filters) {
  const filtersParamObject = queryParamsToObject(filters) || {};

  Object.keys(filtersParamObject).forEach((key, i) => {
    filtersParamObject[key] = filtersParamObject[key].split(',') || [];
  });
  return filtersParamObject;
}

/**
 * tasksToSortableTable
 * This is the new helper to convert tasks array into table rows for <SortableTable />
 * @param {array} tasks = []
 * @param {array} columns = []
 * @param {string} [taskPathname = ROUTE_TASKS_DETAILS]
 * @returns {Node|Node[]} JSX table row <tr> elements to render within SortableTable
*/
export function tasksToSortableTable (tasks = [], collectionTypes = [], columns = [], taskPathname = ROUTE_TASKS_DETAILS) {
  if (!Array.isArray(tasks) || !tasks?.length) {
    return (
      <tr>
        <td className="text-center empty-tasks-table" colSpan={columns.length}>
          <FiAlertTriangle /> No Tasks to Display.
        </td>
      </tr>
    );
  }

  // If there are tasks to display, map through them and convert to table rows
  return tasks.map((task = {}, i) => {
    // Display new row for each task
    const {
      name,
      id,
      windowClose,
      windowOpen,
      status,
      collectMode,
      collectionTier,
      collectionType,
      contractId,
      collectConstraints = {},
      properties = {},
      analytics = []
    } = task;
    const { imageLength, imageWidth } = collectConstraints;
    const {
      archiveHoldback,
      repetitionProperties = {},
      taskingrequestType,
      repeatrequestType,
      userName,
      organizationName
    } = properties;

    // Different task details paths for single, repeat, org mgr, reseller, etc.
    const detailsPath = routePathByName(taskPathname, {
      wildcard: [id]
    });

    /**
     * Render a given table column based on it's ID & desired format
     * @param {string} col ID of the column
     * @returns JSX <td> within the table cell
     */
    const renderColumn = (col) => {
      switch (col) {
        case 'name':
          return (
            <span>
              <WonderLink className="table-details-link" to={detailsPath}>
                {name || `Task ${id}`}
              </WonderLink><br/>
              <small className="text-muted">ID: {id}</small>
            </span>
          );
        case 'user': return (userName);
        case 'organization': return (organizationName);
        case 'unifiedStartDate':
          return formatDate(windowOpen, '--');
        case 'unifiedEndDate':
          return formatDate(windowClose, '--');
        case 'submissionTime':
        case 'latestStatus':
          return formatDate(task[col], '--');
        case 'status':
          return (
            <Badge label={status} className={`badge-${status}`}/>
          );
        case 'collectMode':
          return (
            TaskCollectModes.properties[collectMode].label
          );
        case 'collectionTier':
          return (
            TaskCollectionTiers.properties[collectionTier]?.label
          );
        case 'collectionType':
          return (
            collectionTypes?.find(({ name }) => name === collectionType)?.nameVerbose || COLLECTION_TYPES_NAMES_VERBOSE[collectionType] || collectionType
          );
        case 'contractId':
          return (
            Array.isArray(contractId) ? contractId[1] : contractId
          );
        case 'imageLength':
          return (imageLength);
        case 'imageWidth':
          return (imageWidth);
        case 'archiveHoldback':
          return (archiveHoldback);
        case 'repeatCycle':
          if (repetitionProperties.repetitionInterval) {
            return `${repetitionProperties.repetitionInterval} Day(s)`;
          } return;
        case 'productCategory':
          return (
            <span className="text-capitalize">
              {taskingrequestType || repeatrequestType}
            </span>
          );
        case 'analytics': {
          let separator = '';
          return analytics?.map?.((analytic) => {
            if (VALID_ANALYTICS_CODES.includes(analytic)) {
              const output = separator + analytic;
              separator = ', ';
              return output;
            }
            return '';
          });
        }
        default:
          return task[col];
      }
    };

    return (
      <tr key={id + i}>
        {columns.map(col => {
          return (
            <td key={`${id}-${col}`}>
              {renderColumn(col)}
            </td>
          );
        })}
      </tr>
    );
  });
}

export function prependDateTimeString (input) {
  const prefix = dateTimeFormats(new Date(), '0000', {
    dateFormat: 'YYYYMMDD',
    timeFormat: 'hhmmss'
  });

  return `${prefix.date}${prefix.time}-${input}`;
}

// TODO - Remove this (and all uses of it) when we get rid of the per-org collectionType flags
export function parseTableColumnsByFlag ({ columns, flags, organizationId }) {
  const useNewColumns = flags.collectionTypeTaskingFormEnabled?.organizations?.includes(organizationId);

  const collectModeIndex = columns.findIndex(({ columnId }) => columnId === 'collectMode');
  const collectionTierIndex = columns.findIndex(({ columnId }) => columnId === 'collectionTier');
  const collectionTypeIndex = columns.findIndex(({ columnId }) => columnId === 'collectionType');
  const productCategoryIndex = columns.findIndex(({ columnId }) => columnId === 'productCategory');

  columns[collectModeIndex].label = `${useNewColumns ? 'Collect' : 'Imaging'} Mode`;
  columns[collectionTierIndex].label = `${useNewColumns ? 'Collection' : 'Tasking'} Tier`;

  if (useNewColumns && collectionTypeIndex === -1) {
    columns.splice(4, 0, {
      label: 'Collection Type',
      columnId: 'collectionType',
      isActive: true
    });
  } else if (!useNewColumns && productCategoryIndex === -1) {
    columns.push({
      label: 'Product Category',
      columnId: 'productCategory',
      isActive: false
    });
  }

  return columns.filter(({ columnId }) => columnId !== (useNewColumns ? 'productCategory' : 'collectionType'));
}

/**
 * getAvailableAnalyticsCollectTypes
 * @description get collect types supported by Analytic
 */
export function getAvailableAnalyticsCollectTypes (productType, collectionTypes) {
  // Note: this function is kept around to place potential
  // future constrained analytics in here

  // all collect types whitelisted for VS, VC, and ACD
  return collectionTypes;
}
