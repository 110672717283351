import React from 'react';
import PropTypes from 'prop-types';

/**
 * An accessible, simple Tab component that can be used for separating views
 * @param {object} props
 * @param {object} props.tabList=[]
 */
const Tabs = ({ tabList = [] }) => {
  if (!Array.isArray(tabList) || !tabList.length > 0) return null;
  return (
    <ul className="tabs" role="tablist">
      {tabList.map((tab = {}, i) => {
        const { action, isActive, label } = tab;
        return (
          <li key={i} role="presentation" className={`${isActive ? 'active' : ''}`}>
            <button
              onClick={!isActive && action ? action : undefined}
              className={`tab ${isActive ? 'tab-active' : ''}`}
              role="tab"
              aria-selected="true"
              aria-controls={`tabpanel_${i}`}
              tabIndex={isActive ? 0 : -1}
            >
              {label}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

Tabs.propTypes = {
  tabList: PropTypes.array.isRequired
};

export default Tabs;
