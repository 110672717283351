import { constructRequestActionManager } from '../../lib/actions';
import { routePathByName } from 'lib/routes';
import { mapTaskTableField, taskFiltersToAPI } from '../../lib/tasks';
import {
  SET_RESELLER_ORG,
  UPDATE_RESELLER_ACTION_STATE,
  UPDATE_RESELLER_ORG,
  UPDATE_RESELLER_TASK,
  UPDATE_RESELLER_TASKS,
  UPDATE_RESELLER_USER,
  UPDATE_RESELLER_REPEAT_REQUESTS,
  UPDATE_RESELLER_TASKS_PAGED,
  UPDATE_RESELLER_REPEAT_REQUESTS_PAGED,
  UPDATE_RESELLER_TASKS_COLUMNS,
  UPDATE_RESELLER_REPEAT_REQUESTS_COLUMNS,
  UPDATE_RESELLER_ORG_USERS
} from '../../data/action-types';
import { ACCESS_ROLES, RESELLER_ROLE, ADMIN_ROLE } from '../../data/roles';
import { TASKING_REQUEST_ID } from '../../data/property-names';
import {
  ROUTE_API_ORGS_ORG,
  ROUTE_API_RESELLER_ORG,
  ROUTE_API_TASK,
  ROUTE_API_TASK_COLLECTS,
  ROUTE_API_TASK_TILES,
  ROUTE_API_USERS,
  ROUTE_TASKS,
  ROUTE_API_TASKS_SEARCH,
  ROUTE_API_REPEAT_REQUESTS_SEARCH,
  ROUTE_API_EXPORT_TASKS_CSV,
  ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV,
  ROUTE_API_RESELLER_ORG_USERS
} from '../../data/route-names';
import { RESELLER_SCOPE } from '../../data/scopes';
import {
  PUT_UPDATE_USER_VALID_FIELDS,
  PUT_UPDATE_USER_VALID_ADDRESS_FIELDS
} from '../../data/user';
import { DEFAULT_PAGING_LIMIT } from '../../data/tasks';
import { updateAdminUsers } from '../../state/actions';
import { setPropertyIfExists } from "../../lib";
import {DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS, UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS} from "../../data";

// Changes here most likely should be made identically to actions/subreseller.js
// as they share the same root properties

// The roles that a reseller account is able to manage

const RESELLER_MANAGED_ACCESS_ROLES = ACCESS_ROLES.filter(
  ({ manageAccess }) => {
    return manageAccess ? manageAccess.includes(RESELLER_ROLE) : false;
  }
);

/**
 * setResellerActionState
 * @description Updates the reseller store's status
 */

export function setResellerActionState (status) {
  return {
    type: UPDATE_RESELLER_ACTION_STATE,
    data: status
  };
}

const createDispatchRequestAction = constructRequestActionManager(
  setResellerActionState
);

/**
 * resellerFetchOrganization
 * @description Fetches a reseller organization
 * @param {string} organizationId
 * @param {object} [requestOptions={}] - optional request options
 * @param {boolean} [requestOptions.eagerLedgerIncludes=false] - if true, returns additional eager loaded orders and opportunity discounts with each contract
 */

export const resellerFetchOrganizationById = (organizationId, {
  eagerLedgerIncludes = false
} = {}) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'resellerFetchOrganizationById',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_RESELLER_ORG, {
          wildcard: [organizationId],
          params: {
            eagerLedgerIncludes
          }
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(_setResellerOrganization(data));

    return data;
  };
};

/**
 * fetchParentOrganizationById
 * @description Fetches an organization's parent reseller org (if present)
 * @param {string} organizationId
 */

export const fetchParentOrganizationById = (organizationId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchParentOrganizationById',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_RESELLER_ORG, {
          wildcard: [organizationId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    return data;
  };
};

/**
 * fetchUsersByResellerId
 * @description fetches users from reseller org and managed orgs
 * @param {string} resellerId
 */
export const fetchUsersByResellerId = (resellerId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchUsersByResellerId',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_RESELLER_ORG_USERS, {
          wildcard: [resellerId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateResellerOrgUsers(data));

    return data;
  };
};

/**
 * fetchTasksByResellerId
 * @description Fetches the tasks for a given reseller
 * @param {string} resellerId ID of the organization
 */
export const fetchTasksByResellerId = (resellerId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksByResellerId',
      scope: RESELLER_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_TASKS).slice(
          0,
          -1
        )}?resellerId=${resellerId}`,
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const { data } = request || {};

    dispatch(updateResellerTasks(data));

    return data;
  };
};

/**
 * @description Filter based task search for resellers
 * @param {string} resellerId reseller ID to search tasks by
 * @param {object} paging pagination page & limit
 * @param {object} [filters={}] e.g. { filterName: ['value'] }
 * @param {object} [sortObj = {}] e.g. { sort: 'status', order: 'asc' }
 * @param {AbortController} [controller = new AbortController()] AbortController signal to cancel stale requests
 */

export const searchTasksByResellerId = (
  resellerId,
  { page = 1, limit = DEFAULT_PAGING_LIMIT },
  filters = {},
  sortObj = {},
  controller
) => {
  // Convert UI filters format & naming to API format
  const filterPayload = taskFiltersToAPI(filters, 'single') || {};
  const { sort, order } = sortObj;

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'searchTasksByResellerId',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASKS_SEARCH, {
          params: {
            page,
            limit,
            sort: mapTaskTableField(sort),
            order: order
          }
        }),
        data: {
          filter: {
            ...filterPayload,
            resellerId
          }
        },
        method: 'post',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request || {};

    const { results, currentPage, totalPages } = data;

    dispatch(updateResellerTasksPaged(data));

    return { results, currentPage, totalPages };
  };
};

/**
 * Fetch action for a csv representation of a reseller's tasking request data
 *
 * @param {string} resellerId
 * @param {object} [searchFilters={}] an object of search filters in key value shape per filter, e.g. { status: '', collectMods: [], ... }
 * @param {{ sort: string, order: 'asc'|'desc' }} [sortObj={}] sort and order object to pass as parameters to the download endpoint
 */
export const fetchTasksCsvByResellerId = (resellerId, searchFilters = {}, sortObj = {}) => {
  return async (dispatch, getState) => {
    const { sort, order } = sortObj;
    // Convert UI filters format & naming to API format
    const filterPayload = taskFiltersToAPI(searchFilters, 'single') || {};

    const dispatchAction = createDispatchRequestAction({
      name: fetchTasksCsvByResellerId.name,
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_EXPORT_TASKS_CSV, {
          params: { sort: mapTaskTableField(sort), order }
        }),
        data: {
          filter: {
            ...filterPayload,
            resellerId
          }
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });
    const request = await dispatchAction(dispatch, getState);
    const { data } = request;
    return data;
  };
};

/**
 * @description Filter based task search for resellers
 * @param {string} resellerId reseller ID to search tasks by
 * @param {object} paging pagination page & limit
 * @param {object} [filters={}] e.g. { filterName: ['value'] }
 * @param {object} [sortObj = {}] e.g. { sort: 'status', order: 'asc' }
 * @param {AbortController} [controller = new AbortController()] AbortController signal to cancel stale requests
 */

export const searchRepeatRequestsByResellerId = (
  resellerId,
  { page = 1, limit = DEFAULT_PAGING_LIMIT },
  filters = {},
  sortObj = {},
  controller
) => {
  // Convert UI filters format & naming to API format
  const filterPayload = taskFiltersToAPI(filters, 'repeat') || {};
  const { sort, order } = sortObj;

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'searchRepeatRequestsByResellerId',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_REPEAT_REQUESTS_SEARCH, {
          params: {
            page,
            limit,
            resellerId,
            sort: mapTaskTableField(sort, 'repeat'),
            order: order
          }
        }),
        data: {
          filter: {
            ...filterPayload,
            resellerId
          }
        },
        method: 'post',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request || {};

    const { results, currentPage, totalPages } = data;

    dispatch(updateResellerRepeatRequestsPaged(data));

    return { results, currentPage, totalPages };
  };
};

/**
 * Fetch action for a csv representation of a reseller's repeat request data
 *
 * @param {string} resellerId
 * @param {object} [searchFilters={}] an object of search filters in key value shape per filter, e.g. { status: '', collectMods: [], ... }
 * @param {{ sort: string, order: 'asc'|'desc' }} [sortObj={}] sort and order object to pass as parameters to the download endpoint
 */
export const fetchRepeatRequestsCsvByResellerId = (resellerId, searchFilters = {}, sortObj = {}) => {
  return async (dispatch, getState) => {
    const { sort, order } = sortObj;
    // Convert UI filters format & naming to API format
    const filterPayload = taskFiltersToAPI(searchFilters, 'repeat') || {};
    const dispatchAction = createDispatchRequestAction({
      name: fetchRepeatRequestsCsvByResellerId.name,
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV, {
          params: { sort: mapTaskTableField(sort), order }
        }),
        data: {
          filter: {
            ...filterPayload,
            resellerId
          }
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });
    const request = await dispatchAction(dispatch, getState);
    const { data } = request;
    return data;
  };
};

/**
 * fetchResellerTaskByTaskId
 * @description Fetch a reseller's task by it's ID
 * @param {string} taskId ID of the task
 */

export const fetchResellerTaskByTaskId = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchResellerTaskByTaskId',
      scope: RESELLER_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_API_TASK)}/${taskId}`,
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateResellerTasks([data]));

    return data;
  };
};

/**
 * fetchResellerTasksCollectsById
 * @description Gets a given reseller task's collects
 * @param {string} taskId ID of the task
 */

export const fetchResellerTasksCollectsById = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchResellerTasksCollectsById',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASK_COLLECTS, {
          wildcard: [taskId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(
      updateResellerTask({
        properties: {
          [TASKING_REQUEST_ID]: taskId,
          collects: data
        }
      })
    );

    return data;
  };
};

/**
 * Fetches the repeat requests by resellerId
 * @param {string} resellerId ID of the reseller
 */
export const fetchRepeatRequestsByResellerId = (resellerId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchRepeatRequestsByResellerId',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName('apiRepeatRequest', {
          params: {
            resellerId,
            skip: 0,
            limit: 1000
          }
        }),
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateResellerRepeatRequests(data));

    return data;
  };
};

/**
 * resellerPutUpdateUser
 * @description Updates a reseller user's profile with the given data
 * @param {string} userId The user's ID
 * @param {object} data Updated user data
 */

export const resellerPutUpdateUser = (userId, data) => {
  return async (dispatch, getState) => {
    // we only want to pass params that are meant to be updated

    const userData = {
      ...setPropertyIfExists('disabled', data.disabled),
      ...setPropertyIfExists('forceDisabled', data.forceDisabled),
      ...setPropertyIfExists('forceTaskerRoleRemoval', data.forceTaskerRoleRemoval)
    };
    const address = {};

    PUT_UPDATE_USER_VALID_FIELDS.forEach((field) => {
      if (typeof data[field] !== 'undefined') {
        userData[field] = data[field];
      }
    });

    RESELLER_MANAGED_ACCESS_ROLES.forEach((role) => {
      if (typeof data[role.apiKey] === 'boolean' && role.updateDisabled !== true) {
        userData[role.apiKey] = data[role.apiKey];
      }
    });

    PUT_UPDATE_USER_VALID_ADDRESS_FIELDS.forEach((field) => {
      if (data[field]) {
        address[field] = data[field];
      }
    });



    // Only add the address prop if we have anything to change

    if (Object.keys(address) > 0) {
      userData.address = address;
    }

    const dispatchAction = createDispatchRequestAction({
      name: 'resellerPutUpdateUser',
      scope: RESELLER_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_API_USERS)}/${userId}`,
        method: 'put',
        data: userData
      }
    });

    let response;
    try {
      response = await dispatchAction(dispatch, getState);
    } catch (err) {
      // Expected errors, catch and return message
      if (err.message === DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS || err.message === UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS) {
        return {
          updated: false,
          message: err.message
        };
      }
      // Unexpected error, throw it up to the parent
      throw err;
    }

    const { data: requestData } = response || {};

    const updatedUserData = {
      id: userId,
      ...requestData
    };

    dispatch(_updateResellerUser(updatedUserData));

    // Used to update user list to properly re-render pages on update
    dispatch(updateAdminUsers(requestData));

    return updatedUserData;
  };
};

/**
 * resellerUpdateOrganization
 * @description Updates an organization given the data object
 * @param {object} data
 */

export const resellerUpdateOrganization = (organizationId, data) => {
  return async (dispatch, getState) => {
    const organizationData = {
      name: data.organizationName,
      contractType: data.type,
      address: {
        id: data.addressId,
        street: data.street,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country
      },
      salesforceAccountId: data.salesforceAccountId
    };

    const dispatchAction = createDispatchRequestAction({
      name: 'resellerUpdateOrganization',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_ORGS_ORG, {
          wildcard: [organizationId]
        }),
        method: 'put',
        data: organizationData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(_updateResellerOrganization(requestData));

    return requestData;
  };
};

/**
 * fetchResellerTasksTilesById
 * @description Gets a given reseller task's tiles
 * @param {string} taskId ID of the task
 */

export const fetchResellerTasksTilesById = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchResellerTasksTilesById',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASK_TILES, {
          wildcard: [taskId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const { data } = request || {};

    dispatch(
      updateResellerTask({
        properties: {
          [TASKING_REQUEST_ID]: taskId,
          tiles: data
        }
      })
    );

    return data;
  };
};

/**
 * resellerPostNewUser
 * @description Creates a new user given the data object
 * @param {object} data
 */

export const resellerPostNewUser = (data = {}) => {
  return async (dispatch, getState) => {
    const userData = {
      familyName: data.familyName,
      givenName: data.givenName,
      email: data.email,
      address: {
        street: data.street,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country
      },
      organizationId: data.organizationId,
      apiEnvironmentRole: data.apiEnvironmentRole
    };

    ACCESS_ROLES.filter((role) => {
      return role.manageAccess.includes(ADMIN_ROLE);
    }).forEach((role) => {
      if (typeof data[role.apiKey] === 'boolean') {
        userData[role.apiKey] = data[role.apiKey];
      }
    });

    const dispatchAction = createDispatchRequestAction({
      name: 'resellerPostNewUser',
      scope: 'reseller',
      request: {
        url: routePathByName(ROUTE_API_USERS),
        method: 'post',
        data: userData
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const { data: requestData } = request || {};

    dispatch(addResellerUser(requestData));

    return requestData;
  };
};

/******************
 * STATE UPDATERS *
 ******************/

/**
 * updateResellerTask
 * @description Update an individual reseller task
 */

export const updateResellerTask = (data) => {
  return {
    type: UPDATE_RESELLER_TASK,
    data
  };
};

/**
 * updateResellerTasks
 * @description Set the reseller tasks
 */

export const updateResellerTasks = (data) => {
  return {
    type: UPDATE_RESELLER_TASKS,
    data
  };
};

export const updateResellerRepeatRequests = (data) => {
  return {
    type: UPDATE_RESELLER_REPEAT_REQUESTS,
    data
  };
};

export const updateResellerTasksPaged = (data) => {
  return {
    type: UPDATE_RESELLER_TASKS_PAGED,
    data
  };
};

export const updateResellerRepeatRequestsPaged = (data) => {
  return {
    type: UPDATE_RESELLER_REPEAT_REQUESTS_PAGED,
    data
  };
};

/**
 * updateResellerOrgUsers
 * @description Stores the users of reseller and managed orgs
 */
export const updateResellerOrgUsers = (data) => {
  return {
    type: UPDATE_RESELLER_ORG_USERS,
    data
  };
};

/**
 * updateResellerTasksColumns
 * @description Set the current active task table columns & column order
 */
export const updateResellerTasksColumns = (data) => {
  return {
    type: UPDATE_RESELLER_TASKS_COLUMNS,
    data
  };
};

/**
 * updateResellerRepeatRequestsColumns
 * @description Set the current active repeat requests table columns & column order
 */
export const updateResellerRepeatRequestsColumns = (data) => {
  return {
    type: UPDATE_RESELLER_REPEAT_REQUESTS_COLUMNS,
    data
  };
};

/**
 * _setResellerOrganization
 */

export const _setResellerOrganization = (data) => {
  return {
    type: SET_RESELLER_ORG,
    data
  };
};

/**
 * _updateResellerOrganization
 */

export const _updateResellerOrganization = (data) => {
  return {
    type: UPDATE_RESELLER_ORG,
    data
  };
};

/**
 * _updateResellerUser
 */

export const _updateResellerUser = (data) => {
  return {
    type: UPDATE_RESELLER_USER,
    data
  };
};

/**
 * updateResellerOrganizationContract
 * @description
 * @param {string} organizationId The organization ID
 * @param {string} contractId The contract ID
 * @param {object} data Updated contract data
 */

export const updateResellerOrganizationContract = (
  organizationId,
  contractId,
  data
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateResellerOrganizationContract',
      scope: RESELLER_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsContracts', {
          wildcard: [organizationId, contractId]
        }),
        method: 'put',
        data
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(createUpdateOrganizationContractAction(responseData));

    return responseData;
  };
};

/**
 * createUpdateOrganizationContractAction
 * @description Update a Reseller organization's contract
 */

const createUpdateOrganizationContractAction = (contract) => {
  return {
    type: 'UPDATE_RESELLER_ORGANIZATION_CONTRACT',
    data: {
      contract
    }
  };
};

/**
 * addResellerUser
 * @description Add/create a new user
 */

export const addResellerUser = (data) => {
  return {
    type: 'ADD_RESELLER_USER',
    data
  };
};
