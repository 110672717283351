import { exportClassData, updateClassData, destroyClassData } from 'lib/util';
import { formatDateTime } from 'lib/datetime';

// GeoJSON format with name, etc. in properties
const defaultAOI = {
  type: 'Feature',
  geometry: null,
  id: null,
  properties: {
    name: null,
    description: null,
    active: null,
    excludeTaskedByOwnOrganization: null,
    excludeOlderThanEnabled: null,
    excludeOlderThanSetting: null,
    excludeOlderThanCustomDate: null,
    organizationId: null,
    userId: null,
    updatedAt: null,
    createdAt: null
  }
};

class AOI {
  constructor (data = {}) {
    Object.assign(this, defaultAOI);
    this.update(data);
  }

  data () {
    return exportClassData(this);
  }

  update (data) {
    return updateClassData(this, {
      ...data
    });
  }

  destroy () {
    return destroyClassData(this);
  }

  get createdAtFormatted () {
    return formatDateTime(this.createdAt);
  }

  get updatedAtFormatted () {
    return formatDateTime(this.updatedAt);
  }
}

export default AOI;
