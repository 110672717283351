import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FaImage, FaTrash } from 'react-icons/fa';
import { Badge, Button } from 'fogg/ui';
import { TaskCollectItemLabels } from 'data/tasks';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';
import { Icon } from '@trussworks/react-uswds';
import CostBreakdownAddOn from './CostBreakdownAddOn';
import AddProductDropdown from './AddProductDropdown';

/**
 * Basically the same as an ItemCard item, but broken out so it
 * can be customized for the Cart and potentially elsewhere
 * @param {object} props
 * @param {object} props.item
 * @param {string} props.itemIndex
 * @param {boolean} [props.showLabels]
 * @param {boolean} [props.showCost]
 * @param {boolean} [props.showOwnership]
 * @param {array} [props.labels]
 * @param {func} [props.handleAddProduct]
 * @param {func} [props.handleRemoveProduct]
 * @param {array} [props.addOnItems]
 */
const CostBreakdownItem = ({
  item,
  itemIndex,
  showLabels = true,
  showCost = true,
  showOwnership = false,
  labels,
  handleAddProduct,
  handleRemoveProduct,
  addOnItems,
  showActionButtons
}) => {
  const [activeDropdown, setActiveDropdown] = useState(undefined);
  if (!item) return;
  const {
    itemId,
    canceledDate,
    collection,
    collectDate,
    imagingMode,
    taskingTier,
    cost,
    thumbnail,
    thumbnailIcon,
    freshness,
    children,
    estimatedCost,
    showAddProductButton,
    previouslyOrdered,
    isArchiveBuyingAllowed
  } = item;

  const itemLabels = {
    ...TaskCollectItemLabels,
    ...labels
  };

  const isLoadingChildren = !itemId || itemId === 'undefined';

  /**
   * Calls parent handleRemoveAdditionalItem, and resets activeDropdown
   * @param {string} productType=''
   * @param {string} itemId=''
   */
  const removeProductHandler = (productType, itemId) => {
    handleRemoveProduct(productType, itemId);
    setActiveDropdown(undefined);
  };

  /**
   * handles the display of the thumbnail element based on defined task.thumbnail or task.thumbnailIcon
   *
   * @returns {HTMLElement|import('react').Component}
   */
  const displayThumbnail = () => {
    if (!thumbnail) {
      if (thumbnailIcon) {
        return thumbnailIcon;
      }

      return <FaImage />;
    }

    return <img src={thumbnail} alt={itemId} />;
  };

  return (
    <div
      className={`cost-breakdown-item ${
        isLoadingChildren ? 'content-is-loading' : ''
      }`}
      key={itemIndex}
    >
      {isArchiveBuyingAllowed === false &&
      <div className="cost-breakdown-not-purchasable-overlay">
        <div className="cost-breakdown-not-purchasable-note">
          <Icon.DoNotDisturb size={5} className="cost-breakdown-not-purchasable-note-icon" />
          <span className="cost-breakdown-not-purchasable-note-message">Your user profile requires the <i>Archive Buyer</i> role to order non $0 items. Please contact your organization manager or account representative.</span>
        </div>
      </div>
      }
      <div className="cost-breakdown-parent" key={itemIndex}>
        <span className="thumbnail">{displayThumbnail()}</span>
        <div
          className="cost-breakdown-parent-details"
          data-testid={`cost-breakdown-parent-details-${itemIndex}`}
        >
          {itemId && itemId !== 'undefined' && (
            <h3>
              {showLabels && `${itemLabels.collectId}: `}
              <b>{itemId}</b>
            </h3>
          )}
          {/* Collection is Fallback title for non-Capella items that don't have a collectId */}
          {(!itemId || itemId === 'undefined') && (
            <h3>
              {showLabels && `${itemLabels.collection}: `}
              <b>{collection}</b>
            </h3>
          )}
          <p className="parent-metadata">
            {collectDate && (
              <span>
                {showLabels && `${itemLabels.collectDate}: `}
                <b>{collectDate}</b>
              </span>
            )}
            {canceledDate && (
              <span>
                {showLabels && `${itemLabels.canceledDate}: `}
                <b>{canceledDate}</b>
              </span>
            )}
            {imagingMode && (
              <span>
                {showLabels && `${itemLabels.imagingMode}: `}
                <b>{imagingMode}</b>
              </span>
            )}
            {taskingTier && (
              <span>
                {showLabels && `${itemLabels.taskingTier}: `}
                <b>{taskingTier}</b>
              </span>
            )}
          </p>
          {freshness && (
            <>
              <p className="freshness">
                <span data-tip data-for={`freshness-${itemIndex}`}>
                  <Badge
                    label={freshness}
                    type={freshness === 'FRESH' ? 'info' : 'default'}
                  />
                </span>
              </p>
              <ReactTooltip
                id={`freshness-${itemIndex}`}
                place="top"
                effect="solid"
                type="dark"
                multiline={true}
                className="customTip"
              >
                <p>
                  {freshness && freshness === 'FRESH' ? (
                    <span>
                      Collected <b>within the last 30 days</b>.<br /> Standard
                      rate applied.
                    </span>
                  ) : (
                    <span>
                      Collected <b>more than 30 days</b> ago.
                      <br /> Discounted rate applied.
                    </span>
                  )}
                </p>
              </ReactTooltip>
            </>
          )}
        </div>
        {!showCost && showOwnership && (
          <h3 className="row-ownership">
            {previouslyOrdered && (
              <div className="row-owned">Owned</div>
            )}
            {!previouslyOrdered && (
              <div className="row-new">New</div>
            )}
          </h3>
        )}
        {showCost && (
          <h3
            className="row-cost"
            data-testid={`cost-breakdown-parent-cost-${itemIndex}`}
          >
            {cost}
            {estimatedCost && (
              <div className="row-inner-cost"> Estimated {estimatedCost}</div>
            )}
          </h3>
        )}
      </div>
      {children && (
        <div
          className="cost-breakdown-children"
          data-testid={'cost-breakdown-children'}
        >
          {children}
          {!isLoadingChildren &&
            addOnItems.length > 0 &&
            addOnItems.map((i) => {
              if (i.collectId && i.collectId === itemId) {
                const productDetails =
                  AnalyticProducts.Types.find(
                    (p) => p.value === i.productType
                  ) || '--';
                return (
                  <CostBreakdownAddOn
                    badge={i.productType}
                    title={productDetails.label}
                    key={i.collectId + i.productType}
                    cost={i.cost}
                    actionButtons={
                      showActionButtons ? (
                        <Button
                          type="text"
                          onClick={() =>
                            removeProductHandler(i.productType, itemId)
                          }
                        >
                          <FaTrash />
                        </Button>
                      ) : null
                    }
                  />
                );
              } else return null;
            })}
          {showAddProductButton && !isLoadingChildren && (
            <AddProductDropdown
              key={`${itemId}-${itemIndex}`}
              item={{ itemIndex, itemId }}
              activeDropdown={activeDropdown}
              setActiveDropdown={setActiveDropdown}
              addOnItems={addOnItems}
              handleAddProduct={handleAddProduct}
            />
          )}
        </div>
      )}
    </div>
  );
};

CostBreakdownItem.defaultProps = {
  showLabels: true,
  showCost: true,
  showOwnership: false,
  labels: TaskCollectItemLabels
};

CostBreakdownItem.propTypes = {
  item: PropTypes.object.isRequired,
  itemIndex: PropTypes.number.isRequired,
  showLabels: PropTypes.bool,
  showCost: PropTypes.bool,
  showOwnership: PropTypes.bool,
  labels: PropTypes.object,
  addOnItems: PropTypes.array,
  handleAddProduct: PropTypes.func,
  handleRemoveProduct: PropTypes.func,
  showActionButtons: PropTypes.bool
};

export default CostBreakdownItem;
