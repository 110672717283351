class TaskTile {
  constructor (data) {
    this._data = data;
    Object.assign(this, data);
    this.id = this.properties.tileId;
  }

  get geoJson () {
    return this._data;
  }
}

export default TaskTile;
