import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Notice,
  FormRow
} from 'fogg/ui';

import { TaskRequestTypes } from 'data/task-request-types';
import { RepeatRequestEndTypes } from 'data/repeat-request-end-types';
import { RepeatCycleTypes } from 'data/repeat-cycle-types';
import { TaskCollectModes } from 'data/task-collect-modes';
import { fieldByName } from 'lib/fields';
import { taskDefaults, isPointGeometryType } from 'lib/tasks';

import { useTaskContext, useFetchCollectionTypes } from 'hooks';

import { LoadingSpinner } from 'commonLib';
import FormCreateTaskFields from './FormCreateTaskFields';
import FormCreateTaskOptions from './FormCreateTaskOptions';

const FormCreateTaskModular = ({
  isSandbox,
  onSubmit,
  onCancel,
  geoJson = {},
  isRepeatTask,
  primaryTaskFields
}) => {
  const { taskState, updateTaskState } = useTaskContext();

  const { isLoading, collectionTypes } = useFetchCollectionTypes();

  // Set default task values on initial page load
  useEffect(() => {
    if (!collectionTypes || !collectionTypes.length || taskState.collectionType) return;

    const { name, accessParameters: newAccessParameters, collectParameters: newCollectParameters } = getDefaultCollectionType();
    updateTaskState({
      collectionType: name,
      shouldRefreshAccessTimes: true,
      collectConstraints: {
        imageWidth: newCollectParameters.imageWidth.default,
        offNadirMin: newAccessParameters.offNadirMin.default,
        offNadirMax: newAccessParameters.offNadirMax.default,
        collectMode: newCollectParameters.collectMode.default,
        polarization: newCollectParameters.polarization.default
      }
    });
  }, [collectionTypes]);

  if (isLoading || !collectionTypes.length) {
    return <LoadingSpinner isLoading={true} position="center" size="xlarge" color="gold" style={{ position: 'relative', top: '35vh' }} />;
  }

  const isPointTask = isPointGeometryType({ geoJson });

  const defaultCollectionType = getDefaultCollectionType();
  const { accessParameters: defaultAccessParameters, collectParameters: defaultCollectParameters } = defaultCollectionType;

  const {
    repetitionProperties: {
      repeatStart,
      repeatEnd,
      lookAngleTolerance,
      repetitionInterval = 2,
      repetitionCount = null,
      maintainSceneFraming = false,
      repeatCycle = taskDefaults.repeat.DEFAULT_REPEAT_CYCLE_VALUE,
      repetitionEndType = taskDefaults.repeat.DEFAULT_REPEAT_TASK_END_TYPE_VALUE
    } = {},
    collectionTier = isRepeatTask ? taskDefaults.repeat.DEFAULT_REPEAT_TASK_COLLECTION_TIER : taskDefaults.DEFAULT_COLLECTION_TIER,
    collectionType = defaultCollectionType.name,
    windowCloseInt,
    windowOpenInt,
    analytics,
    customAttribute1,
    customAttribute2
  } = taskState;

  const { accessParameters: selectedAccessParameters, collectParameters: selectedCollectParameters } = collectionTypes.find(({ name }) => name === collectionType);
  const collectMode = selectedCollectParameters.collectMode.default;
  const isNotSpotlightMode = collectMode !== TaskCollectModes.SLIDING_SPOTLIGHT && collectMode !== TaskCollectModes.SPOTLIGHT;

  const activeAnalytic = analytics && analytics.length ? analytics[0] : undefined;
  const acdIsSelected = activeAnalytic === 'ACD';
  // Reset any ACD-specific defaults that are invalid on Standard Task
  if (acdIsSelected && !isRepeatTask) {
    updateTaskState({
      analytics: undefined,
      repetitionProperties: {
        lookAngleTolerance: undefined,
        maintainSceneFraming: false,
        repeatCycle: undefined
      }
    });
  }

  function getDefaultCollectionType () {
    return isPointTask
      ? collectionTypes.find(({ name }) => name === 'spotlight') || collectionTypes.find(({ name }) => name === 'spotlight_legacy') || collectionTypes[0]
      : collectionTypes.find(({ name }) => name === 'stripmap_100');
  }

  /**
   * Event handlers
   */
  /**
   * standard task submit handler
   */
  function handleSubmit (e, fields) {
    const {
      collectConstraints: {
        imageWidth = defaultCollectParameters.imageWidth.default,
        offNadirMin = defaultAccessParameters.offNadirMin.default,
        offNadirMax = defaultAccessParameters.offNadirMax.default,
        collectMode = defaultCollectParameters.collectMode.default,
        polarization = defaultCollectParameters.polarization.default,
        targetHeight = null,
        localTime = taskDefaults.DEFAULT_LOCAL_TIME
      } = {},
      ascDsc = '',
      lookDirection = '',
      orbitalPlanes,
      preApproval = false,
      archiveHoldback = null
    } = taskState;

    const payload = {
      ...fields,
      mode: { value: 'void' },
      windowDuration: { value: (isRepeatTask ? (repeatEnd - repeatStart) : (windowCloseInt - windowOpenInt)) / 1000 },
      taskRequestType: { value: isRepeatTask ? TaskRequestTypes.REPEAT : TaskRequestTypes.STANDARD }
    };

    if (isRepeatTask) {
      payload.repeatStart.value = new Date(repeatStart).toISOString();
      payload.repeatEnd =
        repetitionEndType === RepeatRequestEndTypes.DATE
          ? {
            value: new Date(repeatEnd).toISOString()
          }
          : undefined;
      payload.repetitionEndType = { value: repetitionEndType };
      payload.repetitionCount = { value: parseInt(repetitionCount, 10) };
      payload.maintainSceneFraming = { value: maintainSceneFraming };
      payload.lookAngleTolerance = { value: lookAngleTolerance };
      payload.repeatCycle = { value: repeatCycle };

      if (repeatCycle === RepeatCycleTypes.OTHER) {
        payload.repetitionInterval = { value: repetitionInterval };
      } else if (repeatCycle === RepeatCycleTypes.DAILY) {
        payload.repetitionInterval = { value: 1 };
      } else if (repeatCycle === RepeatCycleTypes.WEEKLY) {
        payload.repetitionInterval = { value: 7 };
      } else if (repeatCycle === RepeatCycleTypes.BIWEEKLY) {
        payload.repetitionInterval = { value: 14 };
      } else if (repeatCycle === RepeatCycleTypes.MONTHLY) {
        payload.repetitionInterval = { value: 30 };
      }
    } else {
      payload.windowClose.value = new Date(windowCloseInt).toISOString();
      payload.windowOpen.value = new Date(windowOpenInt).toISOString();
    }

    payload.collectionType.value = collectionType;
    payload.collectionTier.value = collectionTier;
    payload.ascDsc.value = ascDsc;
    payload.lookDirection.value = lookDirection;
    payload.polarization.value = polarization;
    payload.archiveHoldback.value = archiveHoldback;
    payload.preApproval.value = preApproval;
    payload.collectMode = { value: collectMode };
    payload.localTime = { value: localTime };
    payload.analytics = { value: analytics };
    payload.offNadirMin = { value: offNadirMin };
    payload.offNadirMax = { value: offNadirMax };

    if (fields.orbitalPlanes) {
      payload.orbitalPlanes.value = orbitalPlanes;
    }
    if (targetHeight !== null) {
      payload.targetHeight = { value: parseInt(targetHeight, 10) };
    }
    if (isNotSpotlightMode) {
      payload.imageWidth = { value: imageWidth };
    }

    if (typeof onSubmit === 'function') {
      onSubmit(e, payload);
    }
  }

  /**
   * validation rules for handleSubmit
   */
  const validationRules = {
    windowOpen: {
      ...fieldByName('windowOpen'),
      required: !isRepeatTask,
      isValid: (date) => {
        if (isRepeatTask) return true;
        return date > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    windowClose: {
      ...fieldByName('windowClose'),
      isValid: () => {
        if (isRepeatTask) return true;
        if (windowCloseInt < windowOpenInt) return false;
        return windowCloseInt > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    repeatStart: {
      ...fieldByName('repeatStart'),
      required: isRepeatTask,
      isValid: (date) => {
        if (!isRepeatTask) return true;
        return date > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    repeatEnd: {
      ...fieldByName('repeatEnd'),
      isValid: (date) => {
        if (!isRepeatTask) return true;
        if (repeatEnd && repeatEnd < repeatStart) {
          return false;
        }
        return date > Date.now() + taskDefaults.WINDOW_OPEN_BUFFER;
      }
    },
    repetitionCount: {
      ...fieldByName('repetitionCount'),
      isValid: (count) => {
        if (!isRepeatTask) return true;
        return !(repetitionEndType === RepeatRequestEndTypes.COUNT && (repetitionCount < 2 || repetitionCount > 60));
      }
    },
    repetitionInterval: {
      ...fieldByName('repetitionInterval'),
      isValid: (count) => {
        if (!isRepeatTask) return true;
        return !(repeatCycle === RepeatCycleTypes.OTHER && (repetitionInterval < 2 || repetitionInterval > 90));
      }
    },
    customAttribute1: {
      ...fieldByName('customAttribute1'),
      isValid: () => {
        return customAttribute1?.length > 1;
      }
    },
    customAttribute2: {
      ...fieldByName('customAttribute2'),
      isValid: () => {
        return customAttribute2?.length > 1;
      }
    }
  };

  return (
    <Form
      className="create-task-form"
      onSubmit={handleSubmit}
      rules={validationRules}
    >
      {primaryTaskFields}

      <FormCreateTaskFields
        isPointTask={isPointTask}
        isRepeatTask={isRepeatTask}
        acdIsSelected={acdIsSelected}
        activeAnalytic={activeAnalytic}
        defaultCollectionType={defaultCollectionType}
      />

      <FormCreateTaskOptions
        isRepeatTask={isRepeatTask}
        acdIsSelected={acdIsSelected}
        isNotSpotlightMode={isNotSpotlightMode}
        selectedAccessParameters={selectedAccessParameters}
        selectedCollectParameters={selectedCollectParameters}
      />

      {isSandbox && (
        <Notice type="warning" weight="bold">
          Your account is currently configured for simulated tasking only.
        </Notice>
      )}

      <FormRow className="form-row-actions">
        <Button>Create Task</Button>

        <Button type="text" onClick={onCancel}>
          Cancel Task Request
        </Button>
      </FormRow>
    </Form>
  );
};

FormCreateTaskModular.propTypes = {
  isSandbox: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  geoJson: PropTypes.object,
  isRepeatTask: PropTypes.bool,
  primaryTaskFields: PropTypes.node
};

export default FormCreateTaskModular;
