import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';

import {
  useAdminFetchOrganization,
  useAdminFetchLicenses,
  useFetchCollections,
  useFetchOrganizationCollections,
  useAdmin,
  routePathByName,
  redirectTo,
  logError,
  addOrganizationCollectionPermissions,
  removeOrganizationCollectionPermission,
  adminUpdateOrganization,
  triggerNotice,
  TemplateOrganizationDetails,
  ADMIN_ROLE
} from 'commonLib';
import { ROUTE_ADMIN_ORGS_CONTRACT_DETAILS } from '../../../../data/route-names';

const AdminOrganizationPage = ({ organizationId }) => {
  const dispatch = useDispatch();

  const { actions = {} } = useAdmin();
  const {
    addOrganizationCollectionPermissions:
      addOrganizationCollectionPermissionsAction = {},
    removeOrganizationCollectionPermission:
      removeOrganizationCollectionPermissionAction = {},
    fetchOrganizationCollections: fetchOrganizationCollectionsAction = {},
    adminUpdateOrganization: updateAction = {}
  } = actions;
  const { isLoading: addOrganizationCollectionPermissionsIsLoading } =
    addOrganizationCollectionPermissionsAction;
  const { isLoading: removeOrganizationCollectionPermissionsIsLoading } =
    removeOrganizationCollectionPermissionAction;
  const { isLoading: fetchOrganizationCollectionsIsLoading } =
    fetchOrganizationCollectionsAction;
  const { isLoading: updateIsLoading } = updateAction;

  const {
    organization = {},
    parentOrganization = {},
    isLoading,
    isLoadingParent
  } = useAdminFetchOrganization({
    id: organizationId
  });

  const { licenses = {}, isLoading: fetchLicensesIsLoading } = useAdminFetchLicenses();

  useFetchOrganizationCollections({
    organizationId: organizationId
  });

  if (parentOrganization.name) {
    organization.resellerOrg = parentOrganization;
  }

  const { isLoading: fetchCollectionsIsLoading, collections } =
    useFetchCollections();

  const breadcrumbs = [
    {
      label: 'Admin',
      to: routePathByName('admin')
    },
    {
      label: 'Organizations',
      to: routePathByName('adminOrganizations')
    }
  ];

  const organizationState = {
    isLoading:
      isLoading ||
      fetchCollectionsIsLoading ||
      addOrganizationCollectionPermissionsIsLoading ||
      removeOrganizationCollectionPermissionsIsLoading ||
      fetchOrganizationCollectionsIsLoading ||
      updateIsLoading ||
      isLoadingParent ||
      fetchLicensesIsLoading
  };

  function handleUpdateActiveCollections ({ updates }) {
    updates.forEach(({ id, isChecked } = {}) => {
      if (isChecked) {
        dispatch(addOrganizationCollectionPermissions(organizationId, [id]));
      } else {
        dispatch(removeOrganizationCollectionPermission(organizationId, id));
      }
    });
  }

  async function handleUpdateVerification (e, { id }) {
    const organizationData = {
      [id]: !organization[id]
    };

    await dispatchAdminUpdateOrganization(organizationData, false);
  }

  async function handleUpdateHiddenFields (update) {
    const { id, isChecked } = update;
    const organizationData = {
      [id]: isChecked
    };

    await dispatchAdminUpdateOrganization(organizationData, true);
  }

  async function dispatchAdminUpdateOrganization (organizationData, redirectToAdminOrgDetails) {
    try {
      await dispatch(adminUpdateOrganization(organizationId, organizationData));
    } catch (e) {
      logError(e.message, organizationData);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try again!'
        })
      );
      return;
    }

    if (redirectToAdminOrgDetails) {
      redirectTo(
        routePathByName('adminOrganizationsDetails', {
          wildcard: [organizationId]
        })
      );
    }

    dispatch(
      triggerNotice({
        type: 'success',
        weight: 'bold',
        align: 'center',
        text: 'Organization was successfully updated!'
      })
    );
  }

  return (
    <TemplateOrganizationDetails
      organization={organization}
      collections={collections}
      licenses={licenses}
      accessType={ADMIN_ROLE}
      breadcrumbs={breadcrumbs}
      state={organizationState}
      onUpdateCollections={handleUpdateActiveCollections}
      onUpdateHiddenFields={handleUpdateHiddenFields}
      onUpdateVerification={handleUpdateVerification}
      contractDetailsRouteName={ROUTE_ADMIN_ORGS_CONTRACT_DETAILS}
      contractDetailsRouteOptions={[organizationId]}
    />
  );
};

AdminOrganizationPage.propTypes = {
  organizationId: PropTypes.string
};

export default AdminOrganizationPage;
