import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import { routePathByName } from 'lib/routes';

import Layout from 'components/Layout';

const IndexPage = () => {
  useEffect(() => {
    navigate(routePathByName('search'));
  }, []);
  return <Layout />;
};

export default IndexPage;
