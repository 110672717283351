import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import { routePathByName } from 'lib/routes';
import { ROUTE_REPEAT_REQUEST_CHILD_TASK, ROUTE_TASKS_RELATED_DETAILS, ROUTE_TASK_EDIT, ROUTE_REPEAT_REQUEST_EDIT } from 'data/route-names';

import TasksPage from 'pages-client/tasks/tasks';
import TaskPage from 'pages-client/tasks/task';
import TasksCreatePage from 'pages-client/tasks/create';
import TasksReviewPage from 'pages-client/tasks/review';
import TaskEditPage from 'pages-client/tasks/edit';

import RepeatRequestsPage from 'pages-client/repeatRequests/repeatRequests';
import RepeatRequestPage from 'pages-client/repeatRequests/repeatRequest';
import RepeatRequestChildTaskPage from 'pages-client/repeatRequests/repeatRequestChildTask';
import RepeatRequestEditPage from '../pages-client/repeatRequests/edit';

const TasksIndex = () => {
  return (
    <Router basepath="/">
      <TasksPage path={routePathByName('tasks')} />

      <TasksCreatePage path={routePathByName('tasksCreate')} />
      <TasksReviewPage path={routePathByName('tasksReview')} />
      <TaskPage
        path={routePathByName('tasksDetails', {
          wildcard: [':taskId']
        })}
      />
      <TaskPage
        path={routePathByName(ROUTE_TASKS_RELATED_DETAILS, {
          wildcard: [':conflictId', ':taskId']
        })}
      />
      <TaskEditPage
        path={routePathByName(ROUTE_TASK_EDIT, {
          wildcard: [':taskId']
        })}
      />

      <RepeatRequestsPage path={routePathByName('repeatRequests')} />
      <RepeatRequestPage
        path={routePathByName('repeatRequestDetails', {
          wildcard: [':repeatRequestId']
        })}
      />
      <RepeatRequestChildTaskPage
        path={routePathByName(ROUTE_REPEAT_REQUEST_CHILD_TASK, {
          wildcard: [':repeatRequestId', ':repeatRequestChildTaskId']
        })}
      />
      <RepeatRequestEditPage
        path={routePathByName(ROUTE_REPEAT_REQUEST_EDIT, {
          wildcard: [':repeatRequestId']
        })}
      />
    </Router>
  );
};

TasksIndex.propTypes = {
  location: PropTypes.object
};

export default TasksIndex;
