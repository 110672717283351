import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { device } from 'fogg/lib';

import { useLocation } from 'hooks';
import { triggerNotice } from 'state/actions';
import { redirectTo } from 'lib/routes';
import { logError } from 'lib/logger';

const { isDomAvailable } = device;

export default function usePageReqs ({ condition, error = {} }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { text, to, message } = error;

  useEffect(() => {
    if (condition) return;
    logError(message);
    dispatch(
      triggerNotice({
        type: 'error',
        weight: 'bold',
        align: 'center',
        text: text
      })
    );
  }, [dispatch, text, condition, message]);

  // If we're on a different page, kick to the redirect

  if (isDomAvailable() && !condition && to !== pathname) {
    redirectTo(to);
  }

  return {
    reqsMet: condition
  };
}
