import React from 'react';
import PropTypes from 'prop-types';
import { useResellerFetchOrganization } from 'hooks';
import TemplateContractDetails from 'commonLib/src/templates/TemplateContractDetails';
import { RESELLER_ROLE } from 'data/roles';
import {
  ROUTE_RESELLER,
  ROUTE_RESELLER_DETAILS
} from 'data/route-names';
import { routePathByName } from 'lib/routes';

import Contract from 'commonLib/src/models/contract';

const ResellerContractDetails = ({
  contractId,
  organizationId
}) => {
  const { organization = {}, isLoading } = useResellerFetchOrganization({
    id: organizationId
  });

  let contract =
    organization.contracts?.find((contract) => contract.id === contractId) ||
    {};
  if (typeof contract.isExpired !== 'function') {
    contract = new Contract(contract);
  }

  const organizationState = {
    isLoading
  };

  const resellerDetailsPath = routePathByName(ROUTE_RESELLER_DETAILS, {
    wildcard: [organizationId]
  });
  const resellerPath = routePathByName(ROUTE_RESELLER);
  // default breadcrumbs for contract details view
  const breadcrumbs = [
    {
      label: 'Reseller',
      to: resellerPath
    },
    {
      label: 'Reseller Details',
      to: resellerDetailsPath
    }
  ];

  return (
    <TemplateContractDetails
      accessType={RESELLER_ROLE}
      breadcrumbs={breadcrumbs}
      contract={contract}
      organization={organization}
      state={organizationState}
      detailView={true}
    />
  );
};

ResellerContractDetails.propTypes = {
  contractId: PropTypes.string,
  organizationId: PropTypes.string
};

export default ResellerContractDetails;
