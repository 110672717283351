import {
  exportClassData,
  updateClassData,
  destroyClassData
} from '../lib/util';
import {
  USER_ROLE,
  ADMIN_ROLE,
  ORG_MANAGER_ROLE,
  RESELLER_ROLE,
  SUB_RESELLER_ROLE
} from '../data/roles';

export const defaultData = {
  id: undefined,
  givenName: undefined,
  familyName: undefined,
  email: undefined,
  address: {
    street: undefined,
    city: undefined,
    state: undefined,
    zip: undefined
  },
  organization: {
    parentOrganization: undefined
  },
  plan: undefined,
  roles: [],
  token: undefined,
  refreshToken: undefined,
  refreshTokenExpiration: undefined,
  apiEnvironmentRole: undefined,
  disabled: false,
  sendSupplementaryEmailNotifications: false,
  sendAllTransactionEmailNotifications: false
};

class User {
  constructor (data = {}) {
    updateClassData(this, { ...defaultData, ...data });
  }

  isLoggedIn () {
    return !!(this.id && this.roles.includes(USER_ROLE));
  }

  isAdmin () {
    return this.roles.includes(ADMIN_ROLE);
  }

  isOrgManager () {
    return this.roles.includes(ORG_MANAGER_ROLE);
  }

  isReseller () {
    return this.roles.includes(RESELLER_ROLE);
  }

  isSubreseller () {
    return this.roles.includes(SUB_RESELLER_ROLE);
  }

  data () {
    return exportClassData(this);
  }

  update (data) {
    const { organization = {} } = data;

    return updateClassData(this, {
      ...data,
      // Ensure nested objects in user.organization get updated
      organization: {
        ...data.organization,
        parentOrganization: organization.parentOrganization
      }
    });
  }

  destroy () {
    return destroyClassData(this);
  }
}

export default User;
