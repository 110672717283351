import { location, device } from 'fogg/lib';

import { normalizePathname } from '../lib/routes';
import { hashToObject } from '../lib/util';
import { clearQuerySearchParams, updateQuerySearchParams } from '../lib/location';

const { queryParamsToObject } = location;
const { isDomAvailable } = device;

export default function useLocation ({ state } = {}) {
  if (!isDomAvailable()) return {};

  const { history, location } = window;
  let { pathname, search, hash } = location;
  const { state: locationState } = history;

  // prevPath doesn't currently take into account the previous query params that
  // were in the search. The issue is this can't be handled in all cases as
  // we may have situations where the prevPath exists, like /search, but the
  // properties don't exist. we'll need to make changes in FOGG to address
  // additionally storing that value

  const activeState = state || locationState || {};
  const { prevPath, prevSearch } = activeState;

  const queryParams = queryParamsToObject(search);
  const uriFragment = hashToObject(hash);

  pathname = normalizePathname(pathname);

  return {
    history,
    pathname,
    prevPath,
    prevSearch,
    activeState,
    queryParams,
    updateQuerySearchParams: (params, { replace = false }) => {
      let updatedParams = { ...params };
      if (!replace) {
        updatedParams = {
          ...queryParams,
          ...updatedParams
        };
      }
      updateQuerySearchParams(updatedParams);
    },
    clearQuerySearchParams: (params) => {
      clearQuerySearchParams(queryParams, params);
    },

    uriFragment
  };
}
