/* eslint-disable prefer-promise-reject-errors */
import { useRef, useEffect } from 'react';

function makeCancellable (promise) {
  let isCancelled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) => (isCancelled ? reject({ isCancelled }) : resolve(val)))
      .catch((error) => (isCancelled ? reject({ isCancelled }) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel () {
      isCancelled = true;
    }
  };
}

/**
 * A cancellable promise hook will take care of the clean up and cancellation
*  when the component is un-mounted
**/

export default function useCancellablePromise (cancellable = makeCancellable) {
  const emptyPromise = Promise.resolve(true);

  // test if the input argument is a cancellable promise generator
  if (!cancellable(emptyPromise).cancel) {
    console.error('promise wrapper argument must provide a cancel() function');
  }

  const promises = useRef();

  useEffect(() => {
    promises.current = promises.current || [];
    return function cancel () {
      promises.current.forEach((p) => p.cancel());
      promises.current = [];
    };
  }, []);

  function cancellablePromise (p) {
    const cPromise = cancellable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }

  return { cancellablePromise };
}
