import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaClipboard } from 'react-icons/fa';
import copy from 'copy-text-to-clipboard';

const COPY_SUCCESS_TIMEOUT = 2000;

/**
 * WithCopy
 * @param {object} props = { children }
 * @usage Children must be a string in order to render clipboard and copy
 */

const WithCopy = ({ children }) => {
  const [isCopied, setIsCopied] = useState(false);

  const isString = typeof children === 'string';

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), COPY_SUCCESS_TIMEOUT);
    }
  }, [isCopied]);

  function handleOnCopy () {
    if (isCopied) return;
    if (!isString) return;
    copy(children);
    setIsCopied(true);
  }

  return (
    <span className="with-copy" data-is-copied={isCopied}>
      <span className="with-copy-value">{children}</span>
      {isString && (
        <span className="with-copy-copy" onClick={handleOnCopy}>
          <FaClipboard />
          {isCopied && <span className="with-copy-copy-tooltip">Copied</span>}
        </span>
      )}
    </span>
  );
};

WithCopy.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default WithCopy;
