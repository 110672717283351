import React from 'react';
import PropTypes from 'prop-types';

const LoadingSpinner = ({
  isLoading = true, // Show by default currently to handle various requests made non-async
  position = 'layers-title', // 'center', 'vertical-centered', or 'layers-title'
  type = 'default', // 'dot-flashing' or 'default'
  size = 'small',
  color = 'white',
  style = {}
}) => {
  if (isLoading) {
    return (
      <div
        id="loadingSpinner"
        className={`loading-spinner-container ${position}`}
        style={style}
      >
        {type === 'default' && (
          <div className={`loading-spinner ${size} ${color}`} />
        )}
        {type === 'dot-flashing' && (
          <div className={`dot-flashing ${size} ${color}`} />
        )}
      </div>
    );
  } else {
    return null;
  }
};
LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
  position: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object
};

export default LoadingSpinner;
