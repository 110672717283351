import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import ErrorLayout from 'components/ErrorLayout';

const ServerErrorPage = ({ caughtError }) => {
  return (
    <ErrorLayout caughtError={caughtError}>
      <Helmet bodyAttributes={{ class: 'page-error page-error-500' }}>
        <title>500: Error</title>
      </Helmet>
      <h1>Whoops! Something went wrong!</h1>
      <p className="text-center">
        Please try again on the page you were on. If it&apos;s still not
        working, please let someone from the Capella team know!
      </p>
    </ErrorLayout>
  );
};

ServerErrorPage.propTypes = {
  caughtError: PropTypes.object
};

export default ServerErrorPage;
