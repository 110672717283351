import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';

import { removeAdditionalCartItem } from 'state/actions';
import CostBreakdownItem from './CostBreakdownItem';

/**
 * Reusable component to display item cost breakdowns on cart, order details, etc.
 *
 * @param {object} props
 * @param {array} [props.items=[]]
 * @param {string} [props.className='']
 * @param {boolean} [props.showCost=true]
 * @param {boolean} [props.showOwnership=false]
 * @param {boolean} [props.showHeader=true]
 * @param {boolean} [props.showLabels=true]
 * @param {boolean} [props.isLoading=false]
 * @param {func} [props.handleAddProduct={}]
 * @param {array} [props.addOnItems=[]]
 * @param {boolean} [props.showActionButtons=true]
 * @param {object} [labels]
 */
const CostBreakdown = ({
  items = [],
  addOnItems = [],
  className = '',
  showCost = true,
  showOwnership = false,
  showHeader = true,
  showLabels = true,
  isLoading = false,
  handleAddProduct,
  showActionButtons = true,
  labels
}) => {
  let containerClassName = 'cost-breakdown';
  if (typeof className === 'string') {
    containerClassName = `${containerClassName} ${className}`;
  }
  if (!showHeader) containerClassName += ' no-header';
  if (!showCost && !showOwnership) containerClassName += ' no-cost';
  if (isLoading) containerClassName += ' content-is-loading';

  const dispatch = useDispatch();
  if (!items.length) return null;

  function addProductHandler (productType, itemId) {
    handleAddProduct(productType, itemId);
  }

  function handleRemoveAdditionalItem (productType = '', collectId = '') {
    dispatch(removeAdditionalCartItem({
      productType: productType,
      collectId
    }));
  }

  return (
    <section className={containerClassName}>
      {showHeader && (
        <div className="cost-breakdown-header">
          <h5 className="column">Items</h5>
          {showCost && <h5 className="column text-right">Cost</h5>}
        </div>
      )}
      <div className="cost-breakdown-body">
        {items.map((item, i) => {
          return (
            <CostBreakdownItem
              item={item}
              itemIndex={i}
              key={`CostBreakdownItem-${i}`}
              showCost={showCost}
              showOwnership={showOwnership}
              showLabels={showLabels}
              labels={labels}
              handleAddProduct={addProductHandler}
              handleRemoveProduct={handleRemoveAdditionalItem}
              isLoading={isLoading}
              addOnItems={addOnItems}
              showActionButtons={showActionButtons}
            />
          );
        })}
      </div>
    </section>
  );
};

CostBreakdown.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  showCost: PropTypes.bool,
  showOwnership: PropTypes.bool,
  showHeader: PropTypes.bool,
  showLabels: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleAddProduct: PropTypes.func,
  addOnItems: PropTypes.array,
  labels: PropTypes.object,
  showActionButtons: PropTypes.bool
};

export default CostBreakdown;
