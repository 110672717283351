import React from 'react';
import PropTypes from 'prop-types';
import { StatusIndicator } from 'fogg/ui';
import { STATUS_LIST, ERROR_LIST } from '../data/orders';
/**
 * Order Details status indicator, used in TemplateOrder
 * @param {object} props
 * @param {object} [props.order={}]
 */
const OrderStatus = ({ order = {} }) => {
  const { status } = order;

  return (
    <div className="order-status">
      <StatusIndicator
        activeId={status}
        statusList={STATUS_LIST}
        errorList={ERROR_LIST}
      />
    </div>
  );
};

OrderStatus.propTypes = {
  order: PropTypes.object
};

export default OrderStatus;
