import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { Helmet } from 'react-helmet';
import { MdPersonAdd } from 'react-icons/md';

import { subResellerUpdateOrganization, triggerNotice } from 'state/actions';
import { fieldsObjectToValues } from 'lib/util';
import { redirectTo, routePathByName } from 'lib/routes';
import { logError } from 'lib/logger';
import { useSubResellerFetchOrganization } from 'hooks';

import Layout from 'components/Layout';
import Breadcrumbs from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import FormEditOrganization from 'components/FormEditOrganization';

const PAGE_TITLE = 'Edit Organization';

const SubResellerOrganizationsDetailsEdit = ({ organizationId }) => {
  const dispatch = useDispatch();
  let isLoading = false;

  if (!organizationId) {
    isLoading = true;
  }

  const { organization = {} } = useSubResellerFetchOrganization({
    id: organizationId
  });

  /**
   * handleSubmitForm
   * @description Makes a request to create an org on form submit
   */

  async function handleSubmitForm (e, fields) {
    const organizationData = fieldsObjectToValues(fields);
    try {
      await dispatch(
        subResellerUpdateOrganization(organizationId, organizationData)
      );
    } catch (e) {
      logError(e.message, organizationData);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Oops, something went wrong. Try again!'
        })
      );
      return;
    }

    redirectTo(
      routePathByName('subresellerOrganizationsDetails', {
        wildcard: [organizationId]
      })
    );

    dispatch(
      triggerNotice({
        type: 'success',
        weight: 'bold',
        align: 'center',
        text: 'Organization was successfully updated!'
      })
    );
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class:
            'page-subreseller page-subreseller-organizations page-subreseller-organizations-create'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Sub-Reseller',
              to: routePathByName('subreseller')
            },
            {
              label: 'Organizations',
              to: routePathByName('subresellerOrganizations')
            }
          ]}
        />
        <PageHeader
          title={PAGE_TITLE}
          icon={<MdPersonAdd className="icon-md" />}
        />
        <FormEditOrganization
          organization={organization}
          onSubmit={handleSubmitForm}
          disabled={isLoading}
        />
      </Container>
    </Layout>
  );
};

SubResellerOrganizationsDetailsEdit.propTypes = {
  organizationId: PropTypes.string
};

export default SubResellerOrganizationsDetailsEdit;
