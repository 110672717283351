import { useMappedState } from 'redux-react-hook';

const mapToSelectValues = licenses => licenses && licenses.map(({ id, name, isShareable }) => ({ name, isShareable, value: id, label: `${name} (${id})` }));

export default function useAdminLicenses () {
  const mapState = ({ admin }) => admin;
  const { licenses } = useMappedState(mapState);

  const usg = mapToSelectValues(licenses.USG) || [];
  const canada = mapToSelectValues(licenses.CANADA) || [];
  const capella = mapToSelectValues(licenses.CAPELLA) || [];

  return {
    usg,
    canada,
    capella,
    all: [
      ...capella,
      ...usg,
      ...canada
    ],
    groupedOptions: [
      {
        label: 'Capella',
        options: capella
      },
      {
        label: 'USG',
        options: usg
      },
      {
        label: 'Canada',
        options: canada
      }
    ]
  };
}
