import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useOrg } from 'hooks';

import { logError } from 'lib/logger';
import { errorMessageByCode } from 'lib/errors';
import { triggerNotice, fetchAllOrgUsers } from 'state/actions';

export default function useOrgFetchUsers ({ orgId } = {}) {
  const dispatch = useDispatch();
  const { users = [], actions = {} } = useOrg();

  /**
   * fetchUsers
   */

  function fetchUsersById (id) {
    dispatch(fetchAllOrgUsers(id)).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchUsersById = useCallback(() => {
    fetchUsersById(orgId);
  }, [orgId]); // eslint-disable-line

  useEffect(() => {
    memoizedFetchUsersById();
  }, [memoizedFetchUsersById]);

  return {
    fetchUsersById: memoizedFetchUsersById,
    ...actions.fetchAllOrgUsers,
    users
  };
}
