import React from 'react';
import PropTypes from 'prop-types';

const ReadOnlyField = ({ label, value, className }) => {
  // If className passed, append it
  let wrapperClass = 'form-input form-input-read-only';
  if (className) wrapperClass += ` ${className}`;

  return (
    <div className={wrapperClass}>
      <label className="form-label">{label}</label>
      <span>{value}</span>
    </div>
  );
};

ReadOnlyField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default ReadOnlyField;
