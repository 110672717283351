import {
  ROUTE_API_SUB_RESELLER_ORG,
  ROUTE_API_RESELLER_ORG_USERS,
  ROUTE_SUB_RESELLER,
  ROUTE_SUB_RESELLER_DETAILS,
  ROUTE_SUB_RESELLER_CONTRACT_DETAILS,
  ROUTE_SUB_RESELLER_ORGS,
  ROUTE_SUB_RESELLER_ORG_CREATE,
  ROUTE_SUB_RESELLER_ORG_DETAILS,
  ROUTE_SUB_RESELLER_ORG_DETAILS_EDIT,
  ROUTE_SUB_RESELLER_TASK_DETAILS,
  ROUTE_SUB_RESELLER_TASKS,
  ROUTE_SUB_RESELLER_USERS,
  ROUTE_SUB_RESELLER_USERS_CREATE,
  ROUTE_SUB_RESELLER_USER_DETAILS,
  ROUTE_SUB_RESELLER_ORG_CONTRACT_DETAILS,
  ROUTE_SUB_RESELLER_REPEAT_REQUESTS,
  ROUTE_SUB_RESELLER_REPEAT_REQUEST_DETAILS
} from './route-names';
import { SUB_RESELLER_ROLE } from './roles';

const routes = [];

/**
 * UI
 */

routes.push({
  name: ROUTE_SUB_RESELLER,
  path: '/subreseller/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_USERS,
  path: '/subreseller/users/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_USERS_CREATE,
  path: '/subreseller/users/create/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_USER_DETAILS,
  path: '/subreseller/users/details/*/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_ORGS,
  path: '/subreseller/organizations/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_ORG_CREATE,
  path: '/subreseller/organizations/create/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_ORG_DETAILS,
  path: '/subreseller/organizations/details/*/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_ORG_DETAILS_EDIT,
  path: '/subreseller/organizations/details/*/edit/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_ORG_CONTRACT_DETAILS,
  path: '/subreseller/organizations/*/contract/details/*/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_TASKS,
  path: '/subreseller/tasks/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_TASK_DETAILS,
  path: '/subreseller/tasks/details/*/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_REPEAT_REQUESTS,
  path: '/subreseller/tasks/repeat-requests/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_REPEAT_REQUEST_DETAILS,
  path: '/subreseller/tasks/repeat-requests/details/*/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: 'subresellerOrganizationsContractEdit',
  path: '/subreseller/organizations/*/contract/*/edit/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: 'subresellerOrganizationsContractPeriodAdd',
  path: '/subreseller/organizations/*/contract/*/contract-period/add/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: 'subresellerOrganizationsContractPeriodEdit',
  path: '/subreseller/organizations/*/contract-period/*/edit/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: 'subresellerOrganizationsDetailsEdit',
  path: '/subreseller/organizations/details/*/edit/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_DETAILS,
  path: '/subreseller/details/*/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: ROUTE_SUB_RESELLER_CONTRACT_DETAILS,
  path: '/subreseller/*/contract/details/*/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: 'subresellerDetails',
  path: '/subreseller/details/*/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: 'subresellerOrders',
  path: '/subreseller/orders/',
  access: [SUB_RESELLER_ROLE]
});

routes.push({
  name: 'subresellerOrdersDetails',
  path: '/subreseller/orders/details/*/',
  access: [SUB_RESELLER_ROLE]
});
/**
 * API
 */

// Subreseller requests reach out to the reseller APIs as technically
// a subreseller is a reseller

routes.push({
  name: ROUTE_API_SUB_RESELLER_ORG,
  path: '/resellers/*/'
});

routes.push({
  name: ROUTE_API_RESELLER_ORG_USERS,
  path: '/resellers/*/users'
});

export default routes;
