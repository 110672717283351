import React from 'react';
import CostBreakdownAddOn from '../components/CostBreakdownAddOn';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';

/**
 * Calculate & return cost of imagery & analytic line items based on total cost
 * @param {array} lineItems=[]
 */
export const calculateItemCosts = (lineItems = []) => {
  const analyticCosts = {};

  let total = 0;
  let imageryNum = 0;
  let runningTotal = 0;
  let isUpliftIncluded = false;

  // Map through line items and calculate the imagery & analytic totals
  lineItems.forEach((item = {}) => {
    const { order = {}, previouslyOrdered } = item;
    const { netListPrice = '$0.00' } = order;

    const listPriceNum = parseStringCost(netListPrice);

    total += listPriceNum;
    runningTotal = total;
    // For analytic orders it gets a little complicated....
    AnalyticProducts.asArray().forEach((analyticType) => {
      const analyticImageryUpliftKey =
        AnalyticProducts.getOrderUpliftPropertyKey(analyticType);

      if (order[analyticImageryUpliftKey]?.amount) {
        isUpliftIncluded = true;

        // previousOrdered === true & analytic is included, then analytic cost === netListPrice
        if (previouslyOrdered === true) {
          analyticCosts[analyticType] = netListPrice;
          imageryNum += 0;
          runningTotal = total - listPriceNum;
        } else {
          analyticCosts[analyticType] = order[analyticImageryUpliftKey].amount;
        }
      }
    });

    if (!isUpliftIncluded) {
      // No , VC, or ACD, so imagery total = listPrice total
      imageryNum += listPriceNum;
    }
  });

  const analyticsCost = Object.values(analyticCosts).reduce((t, value) => t + parseStringCost(value), 0);
  if (isUpliftIncluded) {
    // Calculate imagery cost when analytics are included
    if (runningTotal === analyticsCost) {
      imageryNum = 0;
    } else {
      imageryNum = total - analyticsCost;
    }
  }

  const imageryCost = imageryNum.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const totalCost = total.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return { analyticCosts, imageryCost, totalCost };
};

export const parseStringCost = function (cost) {
  return Number(cost.replace(/[^0-9.-]+/g, ''));
};

/**
 * Helper to render cost breakdown line items for the Cart Review page
 * @param {array} items=[]
 * @param {array} lineItems=[]
 * @param {boolean} [showCost=true]
 */
export const formatCostChildren = (items = [], lineItems, showCost = true) => {
  if (!items.length) return;

  const { analyticCosts, imageryCost } = calculateItemCosts(lineItems);

  return items.map((item, index) => {
    let itemCost = '--';
    // Only display imagery cost once, on the 1st line item
    if (index === 0) itemCost = imageryCost;
    if (AnalyticProducts.isAnalytic(item.productType)) itemCost = analyticCosts[item.productType];

    return (
      <CostBreakdownAddOn
        badge={item.productType}
        title={item.id}
        key={item.collectId + item.productType}
        cost={showCost && itemCost}
      />
    );
  });
};
