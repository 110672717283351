import React from 'react';
import PropTypes from 'prop-types';
import { device } from 'fogg/lib';

import {
  useFetchRepeatRequest,
  useFetchRepeatRequestChildTasks,
  useRepeatRequest,
  useRepeatRequests,
  useRepeatRequestChildTasks
} from 'hooks';
import { navigateTo, routePathByName } from 'lib/routes';
import { repeatRequestIdIsValid } from 'lib/data';
import { ROUTE_RESELLER, ROUTE_RESELLER_REPEAT_REQUESTS } from 'data/route-names';

import TemplateRepeatRequest from 'templates/TemplateRepeatRequest';

const { isDomAvailable } = device;

const tasksPath = routePathByName('tasks');

const ResellerRepeatRequestPage = ({ repeatRequestId }) => {
  const { actions: repeatRequestActions = {} } = useRepeatRequests();
  const {
    updateRepeatRequestStatusById: updateRepeatRequestStatusByIdAction = {}
  } = repeatRequestActions;
  const { isLoading: isUpdateRepeatRequestStatusByIdLoading } =
    updateRepeatRequestStatusByIdAction;

  const repeatRequest = useRepeatRequest(repeatRequestId) || {
    id: repeatRequestId
  };
  const { repeatRequestChildTasks } = useRepeatRequestChildTasks();

  const { isLoading } = useFetchRepeatRequest({
    repeatRequestId,
    forceNew: true
  });
  const { isLoading: isChildTasksLoading } = useFetchRepeatRequestChildTasks({
    repeatRequestId,
    forceNew: true
  });

  // If we don't have a valid ID, navigate back to our tasks list page
  if (!repeatRequestIdIsValid(repeatRequestId)) {
    if (isDomAvailable()) navigateTo(tasksPath);
    return null;
  }

  const repeatRequestState = {
    isLoading: isLoading || isUpdateRepeatRequestStatusByIdLoading,
    isChildTasksLoading
  };

  const breadcrumbs = [
    {
      label: 'Reseller',
      to: routePathByName(ROUTE_RESELLER)
    },
    {
      label: 'Repeat Requests',
      to: routePathByName(ROUTE_RESELLER_REPEAT_REQUESTS)
    }
  ];

  return (
    <TemplateRepeatRequest
      breadcrumbs={breadcrumbs}
      repeatRequest={repeatRequest}
      state={repeatRequestState}
      repeatRequestChildTasks={repeatRequestChildTasks}
    />
  );
};

ResellerRepeatRequestPage.propTypes = {
  repeatRequestId: PropTypes.string
};

export default ResellerRepeatRequestPage;
