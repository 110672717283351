import React from 'react';
import PropTypes from 'prop-types';

import TemplateOrder from 'templates/TemplateOrder';

import { useFetchOrder, useFetchOrderAssets, useFetchContracts, useContracts } from 'hooks';
import { routePathByName } from 'lib/routes';

const organizationPath = routePathByName('organization');
const ordersPath = routePathByName('organizationOrders');

const OrganizationOrderPage = ({ orderId }) => {
  const defaultOrder = {
    id: orderId
  };

  const {
    isLoading: fetchOrderIsLoading,
    order = defaultOrder
  } = useFetchOrder(orderId, true);

  const {
    isLoading: fetchAssetsIsLoading,
    items
  } = useFetchOrderAssets(orderId);

  const {
    isLoading: isContractsLoading,
    ledgerBillingEnabled = false
  } = useFetchContracts();
  const { contracts } = useContracts();

  const assets = {
    items
  };

  const isLoading =
    fetchOrderIsLoading || fetchAssetsIsLoading || isContractsLoading;

  const breadcrumbs = [
    {
      label: 'Organization',
      to: organizationPath
    },
    {
      label: 'Orders',
      to: ordersPath
    }
  ];

  const orderState = {
    isLoading
  };

  // loop in contract
  let contractOrder = order;
  if (ledgerBillingEnabled && order.contractId) {
    const contract = contracts.find((contract) => contract.id === order.contractId);
    contractOrder = {
      ...order,
      contract
    };
  }

  return (
    <TemplateOrder
      order={contractOrder}
      assets={assets}
      breadcrumbs={breadcrumbs}
      state={orderState}
    />
  );
};

OrganizationOrderPage.propTypes = {
  orderId: PropTypes.string
};

export default OrganizationOrderPage;
