import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { useSearchParam } from 'react-use';
import { Button, Form, FormInput, FormRow } from 'fogg/ui';

import { fetchToken, triggerNotice, clearNotice } from 'state/actions';
import { fieldByName } from 'lib/fields';
import { routePathByName, navigateTo } from 'lib/routes';
import { logError } from 'lib/logger';
import { useUser } from 'hooks';

import Layout from 'components/Layout';
import Container from 'components/Container';
import LinkList from 'components/LinkList';
import LogoWithWordmark from 'components/LogoWithWordmark';
import { LoadingSpinner } from 'commonLib';

const RESET_PASSWORD_CHALLENGE = 'NEW_PASSWORD_REQUIRED';

const UserLoginPage = ({ location }) => {
  const dispatch = useDispatch();
  const sessionExpired = useSearchParam('sessionExpired');
  const { actions = {} } = useUser();
  const { fetchToken: fetchTokenAction = {}, fetchUser: fetchUserAction = {} } =
    actions;
  const { isLoading: fetchTokenActionIsLoading } = fetchTokenAction;
  const { isLoading: fetchUserActionIsLoading } = fetchUserAction;

  const isLoading = fetchTokenActionIsLoading || fetchUserActionIsLoading;

  // Notify user if logged out due to session expiration
  useEffect(() => {
    if (sessionExpired) {
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Your session has ended, please log back in.',
          persist: true // don't clear after 10 seconds
        })
      );
    }
  }, [sessionExpired]);

  async function handleLoginSubmit (e, fields) {
    let request;
    let errorText = 'Oops, something went wrong. Try again';

    dispatch(clearNotice());

    try {
      request = await dispatch(
        fetchToken(fields.email.value, fields.password.value)
      );
    } catch (e) {
      const { response = {}, message } = e;

      logError(message, { email: fields.email });

      const { status: errorStatus } = response;

      if (errorStatus === 401) {
        errorText =
          'Something is wrong with your credentials. Please check and try again.';
      }

      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorText
        })
      );
    }

    const { challengeName } = request || {};

    if (typeof challengeName === 'string') {
      if (challengeName === RESET_PASSWORD_CHALLENGE) {
        const to = routePathByName('userNewPassword', {
          params: {
            challenge: JSON.stringify(request)
          }
        });
        navigateTo(to);
      } else {
        logError(`Failed to log in; Unknown challenge: ${challengeName}`);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: errorText
          })
        );
      }
    }
  }

  const passwordField = fieldByName('password');
  const validationRules = {
    email: {
      ...fieldByName('email'),
      required: true
    },
    password: {
      ...passwordField,
      required: true
    }
  };

  const isLoginDisabled = isLoading;
  const containerType = ['centered'];

  if (isLoading) {
    containerType.push('is-loading');
  }

  return (
    <Layout location={location} navigation={false}>
      <Helmet bodyAttributes={{ class: 'page-user page-user-login' }}>
        <title>Log In</title>
      </Helmet>
      {isLoading
      ? <LoadingSpinner position="vertical-center" size="large" color="gold" />
      : <Container className="content" type={containerType}>
          <h1 className="visually-hidden">Log In</h1>

          <div className="login-logo">
            <LogoWithWordmark />
          </div>

          <Form onSubmit={handleLoginSubmit} rules={validationRules}>
            <FormRow>
              <FormInput
                id="email"
                label="Email"
                type="email"
                required={true}
                disabled={isLoginDisabled}
              />
            </FormRow>

            <FormRow>
              <FormInput
                id="password"
                label="Password"
                type="password"
                required={true}
                disabled={isLoginDisabled}
                validationMessage={passwordField.validationMessage}
              />
            </FormRow>

            <FormRow>
              <Button id="loginButton" disabled={isLoginDisabled}>
                Log In
              </Button>
            </FormRow>

            <FormRow className="login-links">
              <LinkList
                className="text-center"
                links={[
                  {
                    label: 'Forgot Password',
                    to: routePathByName('userPasswordReset')
                  }
                ]}
              />
            </FormRow>
          </Form>
        </Container>
      }
    </Layout>
  );
};

UserLoginPage.propTypes = {
  location: PropTypes.object
};

export default UserLoginPage;
