import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';

import { routePathByName } from 'lib/routes';

import {
  useResellerFetchOrganization,
  useFetchOrganizationCollections
} from 'hooks';

import {
  addOrganizationCollectionPermissions,
  removeOrganizationCollectionPermission
} from 'state/actions';

import { TemplateOrganizationDetails } from 'commonLib';
import { ROUTE_RESELLER, ROUTE_RESELLER_ORGS, ROUTE_RESELLER_ORG_CONTRACT_DETAILS } from 'data/route-names';
import { RESELLER_ROLE } from 'data/roles';

const ResellerOrganizationsDetails = ({ organizationId }) => {
  const dispatch = useDispatch();
  const {
    organization = {},
    isLoading,
    parentOrganization = {},
    isLoadingParent
  } = useResellerFetchOrganization({
    id: organizationId
  });

  useFetchOrganizationCollections({
    organizationId: organizationId
  });

  if (parentOrganization.name) {
    organization.resellerOrg = parentOrganization;
  }

  const organizationState = {
    isLoading: isLoading || isLoadingParent
  };

  function handleUpdateActiveCollections ({ updates }) {
    updates.forEach(({ id, isChecked } = {}) => {
      if (isChecked) {
        dispatch(addOrganizationCollectionPermissions(organizationId, [id]));
      } else {
        dispatch(removeOrganizationCollectionPermission(organizationId, id));
      }
    });
  }

  const breadcrumbs = [
    {
      label: 'Reseller',
      to: routePathByName(ROUTE_RESELLER)
    },
    {
      label: 'Organizations',
      to: routePathByName(ROUTE_RESELLER_ORGS)
    }
  ];

  return (
    <TemplateOrganizationDetails
      organization={organization}
      accessType={RESELLER_ROLE}
      breadcrumbs={breadcrumbs}
      state={organizationState}
      onUpdateCollections={handleUpdateActiveCollections}
      contractDetailsRouteName={ROUTE_RESELLER_ORG_CONTRACT_DETAILS}
      contractDetailsRouteOptions={[organizationId]}
    />
  );
};

ResellerOrganizationsDetails.propTypes = {
  organizationId: PropTypes.string
};

export default ResellerOrganizationsDetails;
