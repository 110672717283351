export const RESELLER_COLUMNS = [
  {
    Header: 'Last Name',
    columnId: 'lastName',
    widthRatio: 2
  },
  {
    Header: 'First Name',
    columnId: 'firstName',
    widthRatio: 2
  },
  {
    Header: 'Email',
    columnId: 'email',
    widthRatio: 2
  },
  {
    Header: 'Organization',
    columnId: 'organization',
    widthRatio: 2
  },
  {
    columnId: 'role',
    includeColumn: false
  },
  {
    columnId: 'actions',
    Header: false,
    align: 'right',
    type: 'action',
    canSort: false,
    canFilter: false,
    widthRatio: 1
  }
];
