import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import {
  useLocation,
  useSubResellerFetchRepeatRequests,
  useSubResellerRepeatRequests,
  useUser,
  useFetchCollectionTypes
} from 'hooks';
import { routePathByName } from 'lib/routes';
import {
  taskFilterParamsToObject,
  prependDateTimeString,
  RESELLER_REPEAT_REQUESTS_TABLE_COLUMNS,
  getFilterOptions,
  parseTableColumnsByFlag
} from 'lib/tasks';

import TemplateTasks from 'templates/TemplateTasks';

import { SUB_RESELLER_ROLE } from 'data/roles';
import {
  ROUTE_SUB_RESELLER,
  ROUTE_SUB_RESELLER_TASKS,
  ROUTE_SUB_RESELLER_TASK_DETAILS,
  ROUTE_SUB_RESELLER_REPEAT_REQUESTS,
  ROUTE_SUB_RESELLER_REPEAT_REQUEST_DETAILS
} from 'data/route-names';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';
import { updateSubResellerRepeatRequestsColumns } from 'state/actions';

const SubResellerRepeatRequestsPage = () => {
  let subResellerId, isLoadingUser;
  const dispatch = useDispatch();

  const { queryParams = {} } = useLocation();
  const { user = {} } = useUser();
  const { repeatRequests, totalPages = 1, repeatColumns = [] } =
    useSubResellerRepeatRequests({ includeContractDetails: true }) || {};
  const { organization = {} } = user;
  const {
    permittedCollectionTiers: { repeatRequest: permittedTiers = [] }
  } = organization;

  if (organization.id) {
    subResellerId = organization.id;
    isLoadingUser = false;
  } else {
    isLoadingUser = true;
  }

  const {
    page = 1,
    limit = DEFAULT_PAGING_LIMIT,
    filters = {},
    sort,
    order
  } = queryParams || {};

  // Convert filters & sort in query params to format tasks table can use
  // memoize the obj to avoid unnecessary re-renders
  const filtersParamObject = useMemo(() => {
    return taskFilterParamsToObject(filters);
  }, [filters]);

  const sortObject = useMemo(() => {
    return { sort, order };
  }, [sort, order]);

  // setting forceNew makes sure we always get updated tasks when we visit the page

  const { isLoading, dispatchRepeatRequestsCsvBySubResellerId } =
    useSubResellerFetchRepeatRequests({
      subResellerId,
      forceNew: true,
      page,
      limit,
      filters: filtersParamObject,
      sortObject
    });

  const { isLoading: isLoadingCollectionTypes, collectionTypes } = useFetchCollectionTypes();

  const breadcrumbs = [
    {
      label: 'Sub-Reseller',
      to: routePathByName(ROUTE_SUB_RESELLER)
    }
  ];

  const routeNames = {
    tasks: ROUTE_SUB_RESELLER_TASKS,
    repeatRequests: ROUTE_SUB_RESELLER_REPEAT_REQUESTS,
    taskDetails: ROUTE_SUB_RESELLER_TASK_DETAILS,
    repeatDetails: ROUTE_SUB_RESELLER_REPEAT_REQUEST_DETAILS
  };

  const flags = useFlags();

  // Reset to default tasks table columns order & active state
  const resetColumns = () => {
    dispatch(updateSubResellerRepeatRequestsColumns(parseTableColumnsByFlag({ columns: RESELLER_REPEAT_REQUESTS_TABLE_COLUMNS, flags, organizationId: subResellerId })));
  };

  useEffect(() => {
    if (repeatColumns && flags.collectionTypeTaskingFormEnabled) {
      dispatch(updateSubResellerRepeatRequestsColumns(parseTableColumnsByFlag({ columns: repeatColumns, flags, organizationId: subResellerId })));
    }
  }, [flags]);

  return (
    <TemplateTasks
      id="tabpanel_1"
      isLoading={isLoading || isLoadingUser || isLoadingCollectionTypes}
      tasks={repeatRequests}
      collectionTypes={collectionTypes}
      paging={{ currentPage: Number(page), totalPages, limit: Number(limit) }}
      filters={filtersParamObject}
      filterOptions={getFilterOptions({ permittedTiers, isRepeat: true, flags, organizationId: subResellerId, collectionTypes })}
      routeNames={routeNames}
      accessType={SUB_RESELLER_ROLE}
      breadcrumbs={breadcrumbs}
      tableColumns={repeatColumns}
      resetColumns={resetColumns}
      handleColumnsChange={(cols) =>
        dispatch(updateSubResellerRepeatRequestsColumns(cols))
      }
      activeTab="repeat"
      exportHandler={dispatchRepeatRequestsCsvBySubResellerId}
      exportFileName={prependDateTimeString(
        'capella-sub-reseller-repeat-requests-download.csv'
      )}
    />
  );
};

export default SubResellerRepeatRequestsPage;
