import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, FormInput, ChildToggle, Notice } from 'fogg/ui';
import {
  DEFAULT_INGEST_WINDOW,
  DEFAULT_BACKFILL,
  DEFAULT_ANALYTIC_WINDOW,
  DEFAULT_SPACING,
  DEFAULT_ANALYTICS_COLLECTIONS
} from '../data/organization';
import { fieldByName } from '../lib/fields';
import { defaultMonthOptions } from '../data/fields';
import { FaExclamationTriangle } from 'react-icons/fa';

// Portion of the FormAdminEditOrganization form for activating "Analytics" (ie. Change Monitoring)
const FormAnalyticsPanel = ({
  isAnalyticsEnabled,
  isContinuousEnabled,
  handleAnalyticsCheckbox,
  handleContinuousCheckbox,
  analyticsOptions,
  handleUpdateAnalyticsOptions,
  disabled
}) => {
  const analyticWindowOptions = [
    { value: 3, label: '3 months' },
    { value: 4, label: '4 months' },
    { value: 6, label: '6 months' },
    { value: 12, label: '12 months' }
  ];

  // Handle form value changes (ie. controlled form)
  function handleFormChange (e, fields) {
    const { name } = fields;
    if (!e) {
      // If user clears form
      handleUpdateAnalyticsOptions(name, 0);
    } else {
      const { value } = e;
      handleUpdateAnalyticsOptions(name, value);
    }
  }

  const analyticWindowField = fieldByName('analyticWindow');
  const { ingestWindow, backfill, analyticWindow, spacing } = analyticsOptions;

  return (
    <ChildToggle
      className={`analytics-panel ${disabled ? 'disabled' : ''}`}
      label="GCM Module"
      id="analyticsPanel"
      isChecked={isAnalyticsEnabled}
      onChange={handleAnalyticsCheckbox}
    >
      <Notice type="warning small">
        <FaExclamationTriangle /> One-time Initialization Options
      </Notice>
      <FormRow>
        <FormInput
          id="ingestWindow"
          label="Data Ingest Window"
          type="select"
          options={defaultMonthOptions}
          required={true}
          defaultValue={DEFAULT_INGEST_WINDOW}
          value={ingestWindow?.value}
          onChange={handleFormChange}
        />
      </FormRow>

      <ChildToggle
        className="analytics-continuous-panel no-padding"
        label="Continuous Analytics"
        id="analyticsContinuousPanel"
        isChecked={isContinuousEnabled}
        onChange={handleContinuousCheckbox}
      >
        <FormRow>
          <FormInput
            id="backfill"
            label="Analytic Backfill"
            type="select"
            options={defaultMonthOptions}
            required={true}
            defaultValue={DEFAULT_BACKFILL}
            value={backfill?.value}
            clearable={false}
            onChange={handleFormChange}
          />
          <FormInput
            id="analyticWindow"
            label="Window"
            type="select"
            options={analyticWindowOptions}
            required={true}
            defaultValue={DEFAULT_ANALYTIC_WINDOW}
            value={analyticWindow?.value}
            clearable={false}
            onChange={handleFormChange}
            validationMessage={analyticWindowField.validationMessage}
          />
          <FormInput
            id="spacing"
            label="Spacing"
            type="select"
            options={defaultMonthOptions}
            required={true}
            clearable={false}
            defaultValue={DEFAULT_SPACING}
            value={spacing?.value}
            onChange={handleFormChange}
          />
        </FormRow>
      </ChildToggle>
      <Notice type="info" weight="bold">
        <label className="form-label">
          Collections Enabled to Support Analytics:
        </label>
        <ul className="small-list">
          {DEFAULT_ANALYTICS_COLLECTIONS.map(({ label }, key) => (
            <li key={key}>{label}</li>
          ))}
        </ul>
      </Notice>
    </ChildToggle>
  );
};

FormAnalyticsPanel.propTypes = {
  isAnalyticsEnabled: PropTypes.bool,
  isContinuousEnabled: PropTypes.bool,
  handleAnalyticsCheckbox: PropTypes.func,
  handleContinuousCheckbox: PropTypes.func,
  analyticsOptions: PropTypes.object,
  handleUpdateAnalyticsOptions: PropTypes.func,
  disabled: PropTypes.bool
};

export default FormAnalyticsPanel;
