import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { datetime, location } from 'fogg/lib';
import { WonderLink, DataTable } from 'fogg/ui';
import { useTableData } from 'fogg/hooks';

import { routePathByName } from 'lib/routes';
import { geometryToGeoJson } from 'lib/map';

import {
  TASK_STATUS_RECEIVED,
  TASK_STATUS_REVIEW,
  TASK_STATUS_SUBMITTED,
  TASK_COLLECT_STATUSES,
  TASK_COLLECT_STATUS_UNKNOWN,
  TASK_COLLECT_STATUS_TASKED,
  TASK_COLLECT_STATUS_COLLECTED,
  TASK_COLLECT_STATUS_PROCESSING,
  TASK_COLLECT_STATUS_QA,
  TASK_COLLECT_STATUS_DELIVERED,
  TASK_COLLECT_STATUS_ANOMALY,
  TASK_COLLECT_STATUS_COLLECTION_ANOMALY,
  TASK_COLLECT_STATUS_PROCESSING_ANOMALY,
  TASK_COLLECT_STATUS_PREDICTED
} from 'data/tasks';

import WithCopy from 'components/WithCopy';

const { addParamsToUrl } = location;
const { formatDateTime } = datetime;

const KEY_STATUSES = [
  TASK_COLLECT_STATUS_TASKED,
  TASK_COLLECT_STATUS_COLLECTED,
  TASK_COLLECT_STATUS_PROCESSING,
  TASK_COLLECT_STATUS_QA,
  TASK_COLLECT_STATUS_DELIVERED,
  TASK_COLLECT_STATUS_ANOMALY,
  TASK_COLLECT_STATUS_COLLECTION_ANOMALY,
  TASK_COLLECT_STATUS_PROCESSING_ANOMALY,
  TASK_COLLECT_STATUS_PREDICTED
];

const REVIEW_STATUSES = [
  TASK_STATUS_RECEIVED,
  TASK_STATUS_REVIEW,
  TASK_STATUS_SUBMITTED
];

const CellCollectId = (value) => <WithCopy>{value}</WithCopy>;

const CollectStatusIndicator = (value) => {
  return (
    <p data-collect-status={value}>
      <span className="visually-hidden">{value}</span>
    </p>
  );
};

const DEFAULT_COLUMNS = [
  {
    Header: false,
    columnId: 'statusIndicator',
    widthRatio: 0.5,
    cellTransformer: CollectStatusIndicator
  },
  {
    Header: 'Collect ID',
    columnId: 'collectId',
    widthRatio: 2,
    cellTransformer: CellCollectId
  },
  {
    Header: 'Collect Time',
    columnId: 'collectTime',
    align: 'center',
    widthRatio: 3,
    cellTransformer: (value) => formatDateTime(value),
    filterTransformer: (value) => formatDateTime(value)
  },
  {
    Header: 'Status',
    columnId: 'collectStatus',
    align: 'center',
    widthRatio: 2,
    cellTransformer: (value, { data } = {}) => {
      if (value === 'Delivered') {
        const { collectId, collectGeometry } = data;
        const url = addParamsToUrl(routePathByName('search'), {
          properties: `capella:collect_id=${collectId}`,
          geoJson: JSON.stringify(collectGeometry),
          zoom: 'auto'
        });
        return (
          <p>
            <WonderLink to={url}>{value}</WonderLink>
          </p>
        );
      }

      return value;
    }
  }
];

const TaskCollectsTable = ({
  task = {},
  collects = [],
  isLoading,
  onChangeActiveCollect,
  activeTileId
}) => {
  const { status: taskStatus } = task;
  const { geometry: taskGeometry } = task;
  const taskInReview = REVIEW_STATUSES.includes(taskStatus);

  const [activeRow, setActiveRow] = useState();

  useEffect(() => {
    // If we dont have an active tile ID, clean all cells of active state
    // to avoid any stale state

    if (!activeTileId) cleanActiveRowState();

    // If we have our active Id, apply active state to it

    applyActiveRowStateByTileId(activeTileId);

    // When the component unmounts, run cleanup
    return () => cleanActiveRowState();
  }, [activeTileId]);

  /**
   * applyActiveRowStateByTileId
   * @description Given the tile ID, add an active data attribute
   */

  function applyActiveRowStateByTileId (tileId) {
    if (!Array.isArray(collects)) return;

    // First find the active collect based on tile ID

    const activeCollect = collects.find(
      ({ tileId: collectTileId }) => collectTileId === tileId
    );

    // If we can't find an active collect, we have nothing to work from

    if (!activeCollect) return;

    const { collectId } = activeCollect;

    // Using the collect Id, find all cells that match that collect and apply
    // an active data attribute that we can use to highlight the row

    document
      .querySelectorAll(`[data-cell-collectid="${collectId}"]`)
      .forEach((cell) => {
        cell.setAttribute('data-cell-active', true);
      });
  }

  /**
   * cleanActiveRowState
   * @description Find all collect table cells and removes active status data attr
   */

  function cleanActiveRowState () {
    document.querySelectorAll('[data-cell-collectid]').forEach((cell) => {
      cell.removeAttribute('data-cell-active');
    });
  }

  const collectData =
    Array.isArray(collects) &&
    collects.map((collect = {}) => {
      const { collectId, windowOpen, lastStatus } = collect;
      let collectStatus = 'Unknown';

      if (lastStatus && lastStatus.code) {
        collectStatus = TASK_COLLECT_STATUSES.find(
          ({ id }) => id === lastStatus.code
        );

        if (!collectStatus) {
          collectStatus = TASK_COLLECT_STATUSES.find(
            ({ id }) => id === TASK_COLLECT_STATUS_UNKNOWN
          );
        }

        collectStatus = collectStatus && collectStatus.label;
      }

      return {
        collectId,
        collectTime: windowOpen,
        collectStatus,
        collectGeometry: geometryToGeoJson(taskGeometry),
        statusIndicator: collectStatus
      };
    });

  const { columns, data } = useTableData({
    columns: DEFAULT_COLUMNS,
    data: collectData
  });

  const hasMultipleCollects = data && data.length > 1;

  // If our active row changes, we want to trigger an updates
  // for anything that depends on that active row

  useEffect(() => {
    const hasActiveRow = typeof activeRow === 'number';
    if (typeof onChangeActiveCollect === 'function') {
      onChangeActiveCollect(hasActiveRow && collectData[activeRow]);
    }
  }, [activeRow]);

  /**
   * handleOnCellMouseEnter
   */

  function handleOnCellMouseEnter (cell, { target }) {
    const cellId = target && target.dataset.cellId;
    if (cellId !== cell.cellId) return;
    setActiveRow(cell.rowIndex);
  }

  /**
   * handleOnCellMouseEnter
   */

  function handleOnCellMouseLeave () {
    setActiveRow();
  }

  return (
    <div className="task-collects-table">
      <ul className="task-collect-keys">
        {TASK_COLLECT_STATUSES.filter(({ id }) =>
          KEY_STATUSES.includes(id)
        ).map(({ label, id }) => {
          return (
            <li key={id} className={`task-collect-key task-collect-key-${id}`}>
              {label}
            </li>
          );
        })}
      </ul>

      {useCallback(
        <DataTable
          label="Collects"
          columns={columns}
          data={data}
          isLoading={isLoading}
          className={hasMultipleCollects ? 'multiple-collects' : ''}
          rowHeight={60}
          defaultHeight={110}
          rowAttributes={['collectId', 'collectStatus']}
          onCellMouseEnter={handleOnCellMouseEnter}
          onCellMouseLeave={handleOnCellMouseLeave}
        >
          {taskInReview && (
            <p className="text-center">Collects are being planned...</p>
          )}
          {!taskInReview && (!data || data.length === 0) && (
            <p className="text-center">No collects available.</p>
          )}
        </DataTable>,
        [
          JSON.stringify(columns),
          JSON.stringify(collectData), // data is based on static DEFAULT_COLUMNS and dynamic collectData
          isLoading,
          taskInReview
        ]
      )}
    </div>
  );
};

TaskCollectsTable.propTypes = {
  task: PropTypes.object,
  collects: PropTypes.array,
  isLoading: PropTypes.bool,
  onChangeActiveCollect: PropTypes.func,
  activeTileId: PropTypes.string
};

export default TaskCollectsTable;
