import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBriefcase } from 'react-icons/fa';

import {
  routePathByName,
  redirectTo,
  logError,
  useAdminFetchOrganization,
  useAdminFetchLicenses,
  addOrganizationContract,
  triggerSuccess,
  triggerError,
  Breadcrumbs,
  PageHeader,
  Container,
  FormContractAdd
} from 'commonLib';

import Layout from 'components/Layout';

const PAGE_TITLE = 'Add Contract';

const AdminOrganizationsContractAddPage = ({ organizationId }) => {
  const dispatch = useDispatch();

  const { licenses = {}, isLoading: fetchLicensesIsLoading } =
    useAdminFetchLicenses();
  const { organization = {}, isLoading: fetchOrgIsLoading } =
    useAdminFetchOrganization({
      id: organizationId
    });

  const organizationDetailsPath = routePathByName('adminOrganizationsDetails', {
    wildcard: organizationId
  });

  // Loading state after hitting submit button
  const [submitLoading, setSubmitLoading] = useState(false);

  function handleOnSubmit ({ data }) {
    setSubmitLoading(true);
    dispatch(addOrganizationContract(organizationId, data))
      .then(() => {
        setSubmitLoading(false);
        redirectTo(organizationDetailsPath);
        dispatch(triggerSuccess('Contract was successfully added!'));
      })
      .catch((error) => {
        setSubmitLoading(false);
        logError(error.message, data);
        dispatch(triggerError());
      });
  }

  // Admins can "cancel an edit" by navigating back to the org details page
  function handleOnCancel () {
    redirectTo(organizationDetailsPath);
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: 'page-admin page-admin-contract page-admin-contract-add'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Admin',
              to: routePathByName('admin')
            },
            {
              label: 'Organizations',
              to: routePathByName('adminOrganizations')
            },
            {
              label: 'Organization',
              to: organizationDetailsPath
            }
          ]}
        />
        <PageHeader title={PAGE_TITLE} icon={<FaBriefcase />} />
        <div className="edit-contract-content-wrapper">
          <FormContractAdd
            licenses={licenses}
            onSubmit={handleOnSubmit}
            disabled={
              fetchOrgIsLoading || fetchLicensesIsLoading || submitLoading
            }
            onCancel={handleOnCancel}
            organization={organization}
          />
        </div>
      </Container>
    </Layout>
  );
};

AdminOrganizationsContractAddPage.propTypes = {
  organizationId: PropTypes.string
};

export default AdminOrganizationsContractAddPage;
