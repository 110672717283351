import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FaPen, FaPlusCircle } from 'react-icons/fa';
import { WonderLink } from 'fogg/ui';

import { ROUTE_ORG, ROUTE_ORG_CONTRACTS } from '../data/route-names';
import { routePathByName } from '../lib/routes';
import Breadcrumbs from '../components/Breadcrumbs';
import Container from 'components/Container';
import Layout from 'components/Layout';
import PageHeader from '../components/PageHeader';
import ContractCard from '../components/ContractCard';
import ContractPeriodCards from '../components/ContractPeriodCards';
import {
  CONTRACT_TYPE_RESELLER,
  CONTRACT_TYPE_INDEFINITE,
  CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION
} from '../data/contracts';
import { ADMIN_ROLE, RESELLER_ROLE, SUB_RESELLER_ROLE } from '../data/roles';
import Contract from '../models/contract';

const organizationPath = routePathByName(ROUTE_ORG);
const contractsPath = routePathByName(ROUTE_ORG_CONTRACTS);

const DEFAULT_BREADCRUMBS = [
  {
    label: 'Organization',
    to: organizationPath
  },
  {
    label: 'Contracts',
    to: contractsPath
  }
];

const helmetSettings = {
  bodyAttributes: {
    class: 'template-organization template-organization-contract-details'
  }
};

const TemplateContractDetails = ({
  breadcrumbs = DEFAULT_BREADCRUMBS,
  contract = {},
  organization = {},
  state = {},
  licenses = [],
  detailView = false,
  accessType
}) => {
  let typedContract = contract;
  if (typeof typedContract.isExpired !== 'function') {
    typedContract = new Contract(contract);
  }
  const { type, label, contractPeriods = [] } = typedContract;

  const { isLoading = false } = state;

  const isReseller = type === CONTRACT_TYPE_RESELLER;
  const isIndefinite = type === CONTRACT_TYPE_INDEFINITE;
  const isArchiveSub = type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;

  const accessFull = accessType === ADMIN_ROLE;
  const accessReseller = accessType === RESELLER_ROLE;
  const accessSubreseller = accessType === SUB_RESELLER_ROLE;

  const shouldHidePricing = organization.hideOrderPricing && !accessFull;

  const contractManagementActions = [];
  const contractPeriodActions = [];

  if (accessFull && !detailView) {
    contractManagementActions.push({
      label: 'Edit',
      icon: <FaPen />,
      to: routePathByName('adminOrganizationsContractEdit', {
        wildcard: [organization.id, contract.id]
      })
    });

    contractPeriodActions.push({
      label: 'Add Contract Period',
      icon: <FaPlusCircle />,
      to: routePathByName('adminOrganizationsContractPeriodAdd', {
        wildcard: [organization.id, contract.id]
      })
    });
  }

  if (accessReseller && !detailView) {
    contractManagementActions.push({
      label: 'Edit',
      icon: <FaPen />,
      to: routePathByName('resellerOrganizationsContractEdit', {
        wildcard: [organization.id, contract.id]
      })
    });

    contractPeriodActions.push({
      label: 'Add Contract Period',
      icon: <FaPlusCircle />,
      to: routePathByName('resellerOrganizationsContractPeriodAdd', {
        wildcard: [organization.id, contract.id]
      })
    });
  }

  if (accessSubreseller && !detailView) {
    contractManagementActions.push({
      label: 'Edit',
      icon: <FaPen />,
      to: routePathByName('subresellerOrganizationsContractEdit', {
        wildcard: [organization.id, contract.id]
      })
    });

    contractPeriodActions.push({
      label: 'Add Contract Period',
      icon: <FaPlusCircle />,
      to: routePathByName('subresellerOrganizationsContractPeriodAdd', {
        wildcard: [organization.id, contract.id]
      })
    });
  }

  const canEdit =
    (accessFull || accessReseller || accessSubreseller) && !detailView;

  return (
    <Layout>
      <Helmet {...helmetSettings}>
        <title>Organization Contract Details</title>
      </Helmet>
      <Container className={`content ${isLoading ? 'content-is-loading' : ''}`}>
        <Breadcrumbs items={breadcrumbs} />
        <PageHeader title={label} subtitle={organization.name || ''} />

        <div className="profile">
          <div className="profile-content">
            <ContractCard
              contract={typedContract}
              licenses={licenses}
              isReseller={isReseller}
              accessFull={accessFull}
              shouldHidePricing={shouldHidePricing}
              isIndefinite={isIndefinite}
              isArchiveSub={isArchiveSub}
              organization={organization}
              canEdit={canEdit}
              contractManagementActions={contractManagementActions}
            />
          </div>
        </div>
        <div className="profile">
          <div className="profile-content">
            <div className="profile-content-header">
              {!isIndefinite ? (
                <h2>Contract Periods</h2>
              ) : (
                <h2>Indefinite contracts do not support Contract Periods</h2>
              )}
              {!isIndefinite && (
                <ul className="profile-content-header-actions">
                  {contractPeriodActions.map((action) => {
                    const { to, label, icon } = action;
                    return (
                      <li key={to}>
                        <WonderLink to={to}>
                          {icon} {label}
                        </WonderLink>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>

            {!isIndefinite && (
              <div className="profile-section profile-section-cards">
                <ContractPeriodCards
                  isLoading={isLoading}
                  contractPeriods={contractPeriods}
                  organizationId={organization.id}
                  canEdit={canEdit}
                  isArchiveSub={isArchiveSub}
                  isReseller={accessReseller}
                  isSubreseller={accessSubreseller}
                  hideOrderPricing={shouldHidePricing}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

TemplateContractDetails.propTypes = {
  breadcrumbs: PropTypes.array,
  contract: PropTypes.object,
  licenses: PropTypes.array,
  detailView: PropTypes.bool,
  organization: PropTypes.object,
  state: PropTypes.object,
  accessType: PropTypes.string
};

export default TemplateContractDetails;
