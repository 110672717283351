import React from 'react';
import { routePathByName } from 'lib/routes';
import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from '../data/contracts';
import { formatDate } from './datetime';

/**
 * organizationsToTableData
 */

export function organizationsToTableData (
  organizations = [],
  { editPathName } = {}
) {
  if (!Array.isArray(organizations) || organizations.length === 0) return [];

  return organizations.map((organization) => {
    const {
      name,
      users,
      id,
      type,
      createdAt,
      disabled
    } = organization;

    const usersCount =
      users === 'Loading' ? users : Array.isArray(users) && users.length;

    return {
      name,
      users: usersCount,
      type: type,
      createdAt: createdAt,
      actions: [
        {
          to: routePathByName(editPathName, {
            wildcard: [id]
          }),
          label: 'Edit',
          icon: 'FaPen',
          buttonType: ['text', 'icon-before']
        }
      ],
      disabled: disabled ? 'Disabled' : 'Active'
    };
  });
}

export const orgIsArchiveSubOnly = ({ contracts = [] }) =>
  contracts.filter(
    (contract) => contract.type !== CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION
  ).length === 0;

/**
 * organizationsTableDataTransformers
 */
export const organizationsTableDataTransformers = {
  type: {
    cellTransformer: (value) => (
      <span className="text-capitalize">{value}</span>
    )
  },
  createdAt: {
    cellTransformer: (value) => formatDate(value),
    filterTransformer: (value) => formatDate(value)
  }
};
