import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormInput, FormRow } from 'fogg/ui';
import { regexByFieldName } from 'lib/input';
import { fieldByName } from 'lib/fields';

import LinkList from 'components/LinkList';

const FormChangePassword = ({ links, onSubmit }) => {
  const passwordField = fieldByName('password');
  const passwordFieldConfirmation = fieldByName('passwordConfirmation');

  const validationRules = {
    password: {
      ...passwordField,
      required: true,
      regex: regexByFieldName('password')
    },
    passwordConfirmation: {
      ...passwordFieldConfirmation,
      required: true,
      regex: regexByFieldName('password'),
      dependencies: [
        {
          field: 'password',
          exactMatch: true
        }
      ]
    }
  };

  /**
   * handleSubmit
   */

  function handleSubmit (e, fields = {}) {
    if (typeof onSubmit === 'function') {
      onSubmit(fields);
    }
  }

  return (
    <Form onSubmit={handleSubmit} rules={validationRules}>
      <FormRow>
        <FormInput
          id="password"
          label="New Password"
          type="password"
          required={true}
          validationMessage={passwordField.validationMessage}
        />
      </FormRow>
      <FormRow>
        <FormInput
          id="passwordConfirmation"
          label="Confirm New Password"
          type="password"
          required={true}
          validationMessage={passwordFieldConfirmation.validationMessage}
        />
      </FormRow>

      <FormRow>
        <Button full={true}>Change Password</Button>
      </FormRow>

      {Array.isArray(links) && links.length > 0 && (
        <FormRow className="login-links">
          <LinkList className="text-center" links={links} />
        </FormRow>
      )}
    </Form>
  );
};

FormChangePassword.propTypes = {
  links: PropTypes.array,
  onSubmit: PropTypes.func
};

export default FormChangePassword;
