import { AwsRum } from 'aws-rum-web';

export function returnConsoleRUMSnippet(hostname) {
  try {
    const config = {
      sessionSampleRate: 1,
      guestRoleArn: process.env.RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.RUM_IDENTITY_POOL_ID,
      endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
      telemetries: ["errors", "performance", "http"],
      allowCookies: true,
      enableXRay: false
    };

    const APPLICATION_ID = process.env.RUM_APPLICATION_ID;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-west-2';

    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
};
