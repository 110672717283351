import { location } from 'fogg/lib';

const { addParamsToUrl } = location;

/**
 * buildTileEndpoint
 * @description
 */

export function buildTileEndpoint ({ url }) {
  const jwt = document.cookie.split(';').reduce((cookies, cookie) => {
    const [name, value] = cookie.split('=').map((c) => c.trim());
    cookies[name] = value;
    return cookies;
    // eslint-disable-next-line dot-notation
  }, {})['token'];

  const tileUri = url.replace('*', '{z}/{x}/{y}.png');
  const params = {
    jwt: jwt,
    nodata: '0'
  };
  return addParamsToUrl(tileUri, params);
}
