import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';
import { MdRefresh } from 'react-icons/md';
import { BsQuestion } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';
import csvDownload from 'json-to-csv-export';

import { ToggleButtonGroup } from 'fogg/ui';

const AccessRequestsWrapper = ({
  onAccessRequestsRefresh,
  shouldRefreshAccessTimes = false,
  children,
  message,
  isPoint,
  csvAccesses,
  enableTimeSelect,
  selectedTime,
  timeSelectOptions,
  onChange
}) => {
  return (
    <div className="access-times">
      <div className="access-times-wrapper">
        <div className="access-requests-status">
          {typeof onAccessRequestsRefresh === 'function' && (
            <span className="refresh-block">
              <button
                type="button"
                className="button refresh-button"
                onClick={onAccessRequestsRefresh}
                disabled={shouldRefreshAccessTimes === false}
              >
                <MdRefresh /> Refresh Access Times
              </button>
            </span>
          )}
          {message && isPoint && (
            <span className="status-message">{message}</span>
          )}
        </div>
        <div className="access-times-header">
          <div className="access-times-header-group">
            <span className="header-text">Potential Satellite Access Times</span>
            <Tooltip
              className="access-requests-tip"
              id="access-requests-tip"
              icon={<BsQuestion />}
              tooltipText={<p>Changes to the following fields may impact access times: <b>Window
                Open, Tasking Tier, Collection Time, Window Close, Imaging Mode, Look Angle, Observation Direction,
                Orbital Plane, Orbit State</b>. Clicking refresh will generate updated
                access information based on these fields.</p>}
            />
          </div>
          <div className="access-times-header-group">
            <div className={`access-times-header-toggle-group${!enableTimeSelect ? ' disabled-visually' : ''}`}>
              <ToggleButtonGroup
                className={`access-request-time-toggle-button${!enableTimeSelect ? ' disabled' : ''}`}
                activeValue={selectedTime}
                options={timeSelectOptions}
                onChange={onChange}
              />
              <Tooltip
                className={`access-requests-tip${!enableTimeSelect ? ' disabled' : ''}`}
                id="access-requests-time-toggle-tip"
                icon={<BsQuestion />}
                tooltipText={<p>The datetime of the access opportunity will be displayed in Coordinated Universal time if the &quot;UTC&quot; option is selected and in the local time of the target location if the &quot;LOCAL&quot; option is selected.</p>}
              />
            </div>
            {csvAccesses && Object.keys(csvAccesses).length ? (
              <a className="header-link" onClick={() => csvDownload(csvAccesses, 'Access_Requests.csv')}>
                <FaDownload /> .CSV
              </a>
            ) : null}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

AccessRequestsWrapper.propTypes = {
  onAccessRequestsRefresh: PropTypes.func,
  shouldRefreshAccessTimes: PropTypes.bool,
  children: PropTypes.any,
  message: PropTypes.string,
  isPoint: PropTypes.bool,
  csvAccesses: PropTypes.array,
  enableTimeSelect: PropTypes.bool,
  selectedTime: PropTypes.string,
  timeSelectOptions: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};

export default AccessRequestsWrapper;
