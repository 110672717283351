import React, { useEffect, useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { Helmet } from 'react-helmet';
import { MdLibraryAdd } from 'react-icons/md';
import { Button } from 'fogg/ui';

import { useOrder, usePageReqs, useCart, useUser, useSearch } from 'hooks';
import {
  postOrderRequest,
  clearNewOrder,
  triggerNotice,
  destroyCart
} from 'state/actions';
import { routePathByName, promiseToNavigateTo } from 'lib/routes';
import { logError } from 'lib/logger';
import { isEmptyObject } from 'lib/util';
import { errorMessageByCode } from 'lib/errors';
import { formatCostChildren } from 'lib/cart';
import { formatDateTime } from 'commonLib/src/lib/datetime';
import { TaskCollectModes } from 'data/task-collect-modes';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { userHasArchiveBuyerAccess } from '../../../common-web-lib/src/lib/role-util';

import Layout from 'components/Layout';
import PageHeader from 'components/PageHeader';
import Container from 'components/Container';
import OrderSummaryTable from 'components/OrderSummaryTable';
import CostBreakdown from 'components/CostBreakdown';
import { calculateItemCosts } from '../../lib/cart';
import { filterOrderableItems } from '../../lib/review';
import { AnalyticProducts } from 'commonLib/src/data/analytic-product-types';
import { CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION } from 'commonLib/src/data/contracts';

/**
 * 2nd page in the shopping cart process (new archive order)
 */
const CartReviewPage = () => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const order = useOrder('NEW_ORDER') || {};
  const {
    orderDetails = {},
    authorized,
    authorizationDenialReason = {}
  } = order;

  const { lineItems = [] } = orderDetails;
  const {
    contract: { contractId, contractLabel } = {},
    groupedItems,
    addOnItems
  } = useCart();

  const { user = {} } = useUser();
  const flags = useFlags();

  const { organization = {}, roles = {} } = user;
  let { hideOrderPricing, contracts } = organization;
  const isMultiContract = contracts?.length > 1;

  // Archive Subscription logic
  const contract = contracts?.find(
    (orgContract) => orgContract.id === contractId
  );
  const isArchiveSubscriptionOrder =
    contract?.type === CONTRACT_TYPE_ARCHIVE_SUBSCRIPTION;
  if (isArchiveSubscriptionOrder) {
    hideOrderPricing = true;
  }
  const { availableSubscriptionUnits = 0 } = contract;

  const { last: lastSearch } = useSearch();

  // Include the addOnItems with the traditional cart items
  const allItemsGrouped = groupedItems;
  Object.keys(allItemsGrouped).map((itemId) => {
    addOnItems.filter((a) => a.collectId === itemId).forEach((matchingAddOnItem) => {
      if (AnalyticProducts.isAnalytic(matchingAddOnItem.productType)) {
        matchingAddOnItem.id = AnalyticProducts.getLabel(matchingAddOnItem.productType);
        allItemsGrouped[itemId].push(matchingAddOnItem);
      }
    });
  });

  const archiveBuyingAllowed = userHasArchiveBuyerAccess(roles, flags);

  // Transform grouped Items to a format CostBreakdown can easily use

  const groupedOrderItemsByCollect = Object.keys(groupedItems).map((itemId) => {
    const currentItems = groupedItems[itemId];
    const items = lineItems.filter((i) => i.collectId === itemId);

    let collectMode;
    if (currentItems[0].properties) {
      collectMode =
        TaskCollectModes.properties[
          currentItems[0].properties['sar:instrument_mode']
        ]?.label;
    }

    const { freshnessOption, previouslyOrdered, collectId, chargeable } = items[0] || {};
    const { totalCost } = calculateItemCosts(items);

    const chargedAnalytics = items.flatMap((item) => item.chargedAnalytics);
    const isArchiveBuyingAllowed = (!chargeable && chargedAnalytics.length === 0) || archiveBuyingAllowed;

    return {
      itemId: itemId,
      collection: currentItems[0]?.feature?.collection,
      collectDate: formatDateTime(currentItems[0].endTime),
      imagingMode: collectMode,
      thumbnail: currentItems[0].thumbnail,
      freshness: freshnessOption,
      cost: !hideOrderPricing && totalCost,
      showAddProductButton: false,
      children: formatCostChildren(currentItems, items, !hideOrderPricing),
      collectId,
      previouslyOrdered,
      isArchiveBuyingAllowed
    };
  });

  const { reqsMet } = usePageReqs({
    condition: !isEmptyObject(order),
    error: {
      text: 'Please create an order to review',
      to: routePathByName('search'),
      message: 'No order to review'
    }
  });

  function triggerError (
    errorMessage = 'Uh oh, something went wrong. Please try again!'
  ) {
    dispatch(
      triggerNotice({
        type: 'error',
        weight: 'bold',
        align: 'center',
        text: errorMessage
      })
    );
  }

  useEffect(() => {
    if (authorized) return;

    const { code: errorCode, message: apiErrorMessage = '' } =
      authorizationDenialReason;

    if (errorCode === 'AUTHORIZATION_ARCHIVE_BUYER_ACCESS_DENIED') {
      // This case is handled by the check and filtering of chargeable items
      return;
    }
    const errorMessage = apiErrorMessage || errorMessageByCode(errorCode);

    triggerError(errorMessage);
  }, [authorized, authorizationDenialReason.code]);

  if (!reqsMet) return null;

  const { orderIsAuthorized, orderItems: orderableItems, addOnItems: orderableAddOnItems, orderSummary } =
      filterOrderableItems(order, groupedOrderItemsByCollect, addOnItems);

  /**
   * handleCreateClick
   * @description
   */
  async function handleCreateClick () {
    let orderRequest;
    let orderId;
    setIsSubmitted(true);

    order.items = orderableItems;
    order.addOnItems = orderableAddOnItems;

    try {
      orderRequest = await dispatch(postOrderRequest({ order }));
      orderId = orderRequest.orderId;
      if (!orderId) {
        setIsSubmitted(false);
        throw new Error('Unable to find order ID for request');
      }
    } catch (e) {
      setIsSubmitted(false);
      logError(e.message, order);
      triggerError(e.message);
      return;
    }

    const orderRoute = routePathByName('ordersDetails', {
      wildcard: [orderId]
    });

    try {
      await promiseToNavigateTo(orderRoute);
    } catch (e) {
      setIsSubmitted(false);
      logError(e.message, {
        route: orderRoute
      });
      triggerError();
      return;
    }

    dispatch(clearNewOrder());
    dispatch(destroyCart());
  }

  // Construct the "Back To Cart" url path
  let backToCartPath = `${routePathByName('search')}?showCart=true`;
  if (lastSearch) {
    backToCartPath = `${routePathByName('search')}${
      lastSearch.search
    }&showCart=true`;
  }

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-orders page-orders-review' }}>
        <title>Orders Review</title>
      </Helmet>

      <div className="orders-review">
        <Container className="content">
          <PageHeader
            title="Review New Order"
            icon={<MdLibraryAdd className="icon-md" />}
          />
          <div className="orders-review-details">
            <div className="orders-review-details-items">
              <CostBreakdown
                items={groupedOrderItemsByCollect}
                showCost={!hideOrderPricing}
                showOwnership={isArchiveSubscriptionOrder}
                showActionButtons={false}
              />
            </div>
            <div className="orders-review-details-billing">
              <h2>Order Details</h2>
              <OrderSummaryTable
                orderSummary={orderSummary}
                orderArchSubParams={{
                  isArchiveSubscriptionOrder,
                  availableSubscriptionUnits
                }}
                hidePricing={ hideOrderPricing }
              />

              {!!isMultiContract && contractLabel && (
                <div className="order-review-details-contracts">
                  Charges will be applied to <b>{contractLabel}</b>
                </div>
              )}

              <p className="orders-review-details-billing-actions">
                <Button
                  className="orders-review-submit-order"
                  full={true}
                  onClick={handleCreateClick}
                  disabled={!orderIsAuthorized || isSubmitted}
                >
                  {isSubmitted ? 'Submitting....' : 'Submit New Order'}
                </Button>
                <Button full={true} type="text" to={backToCartPath}>
                  Back to Cart
                </Button>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default CartReviewPage;
