import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, FormInput, Button, Notice } from 'fogg/ui';
import { Helmet } from 'react-helmet';
import { Button as ModalButton, Modal, ModalFooter, Icon } from '@trussworks/react-uswds';

import Layout from 'components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import PageHeader from '../components/PageHeader';
import Container from 'components/Container';
import UserProfile from '../components/UserProfile';
import Verifications from '../components/Verifications';
import ProfileSidebar from '../components/ProfileSidebar';
import ProfileSidebarList from '../components/ProfileSidebarList';
import ProfileSidebarSection from '../components/ProfileSidebarSection';

import { fillEmptyUser } from '../lib/data';
import { createFullName } from '../lib/user';
import { TASK_ENVIRONMENTS } from '../lib/tasks';
import { renderOrNot } from '../lib/util';
import { ADMIN_ROLE, ORG_MANAGER_ROLE } from '../data/roles';

const TemplateUser = ({
  user = {},
  organizations = [],
  accessType,
  breadcrumbs = [],
  verifications = [],
  isLoading,
  onUpdateUser,
  onUpdateVerification,
  onReissuePassword,
  handleConfirmation,
  handleRejectConfirmation,
  forceDisable = false,
  forceTaskerRoleRemoval = false
}) => {
  const modalRef = useRef();
  const accessFull = accessType === ADMIN_ROLE;
  const { givenName, familyName, apiEnvironmentRole } = user;

  let activeUser = user;

  if (isLoading) {
    activeUser = fillEmptyUser();
  }

  const { disabled } = activeUser;

  const fullName = createFullName(givenName, familyName);

  const hasBreadcrumbs = Array.isArray(breadcrumbs) && breadcrumbs.length > 0;
  const hasVerifications =
    Array.isArray(verifications) && verifications.length > 0;

  const taskEnvironmentOptions = TASK_ENVIRONMENTS.map((env = {}) => {
    return {
      ...env,
      value: env.id
    };
  });

  /**
   * handleOnTaskingApiChange
   */

  function handleOnTaskingApiChange (data) {
    const { value } = data || {};

    if (value === apiEnvironmentRole) return;

    onUpdateUser({
      apiEnvironmentRole: value
    });
  }

  const UserManagement = () => {
    return (
      <>
        <p>
          <Button full={true} onClick={onReissuePassword} disabled={isLoading}>
            Reissue Password
          </Button>
        </p>
        <p className="caption">
          Reissue is only available for users who have not yet changed their
          password for the first time.
        </p>
      </>
    );
  };

  const UserEnvironment = () => {
    return (
      <Form>
        <FormInput
          id="apiEnvironmentRole"
          label="Tasking API"
          type="select"
          options={taskEnvironmentOptions}
          required={true}
          value={taskEnvironmentOptions.find(
            ({ value }) => value === apiEnvironmentRole
          )}
          clearable={false}
          onChange={handleOnTaskingApiChange}
        />
      </Form>
    );
  };

  const displayUserId = [ADMIN_ROLE, ORG_MANAGER_ROLE].includes(accessType);

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'template-user' }}>
        <title>{fullName}</title>
      </Helmet>
      <Container className={`content ${isLoading ? 'content-is-loading' : ''}`}>
        {hasBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}

        <PageHeader
          title={fullName}
          subtitle={displayUserId ? `ID: ${activeUser.id}` : undefined}
        />

        {disabled && (
          <Notice type="warning" weight="bold" align="center">
            User is disabled.
          </Notice>
        )}

        <div className="profile">
          <div className="profile-content">
            <div className="profile-section">
              <UserProfile
                user={activeUser}
                organizations={organizations}
                updateUser={onUpdateUser}
                accessType={accessType}
              />
            </div>
          </div>

          <ProfileSidebar>
            <ProfileSidebarSection
              header="Status"
              className="profile-sidebar-list"
            >
              <Verifications
                verifications={[
                  {
                    id: 'disabled',
                    label: 'Active',
                    isVerified: !disabled,
                    actionLabels: {
                      add: 'Activate User',
                      remove: 'Disable User'
                    }
                  }
                ]}
                onChange={onUpdateVerification}
              />
            </ProfileSidebarSection>

            {hasVerifications && (
              <ProfileSidebarSection
                header="Verification"
                className="profile-sidebar-list"
              >
                <Verifications
                  verifications={verifications}
                  onChange={onUpdateVerification}
                />
              </ProfileSidebarSection>
            )}

            <ProfileSidebarSection header="User">
              <ProfileSidebarList
                className="profile-sidebar-management"
                items={[
                  {
                    Item: UserManagement
                  }
                ]}
              />
            </ProfileSidebarSection>

            {accessFull && (
              <ProfileSidebarSection header="Environment">
                <ProfileSidebarList
                  className="profile-sidebar-management"
                  items={[
                    {
                      Item: UserEnvironment
                    }
                  ]}
                />
              </ProfileSidebarSection>
            )}
          </ProfileSidebar>
        </div>
        {renderOrNot(
          forceDisable || forceTaskerRoleRemoval,
          <Modal
            id="update-user-modal"
            aria-labelledby="update-user-modal-heading"
            modalRef={modalRef}
            isInitiallyOpen={true}
            forceAction={true}
          >
            <h3 id="update-user-modal-heading" className="update-user-moda-heading flat-all trim-all">
              <Icon.Warning size={3} /> User has active repeat tasks!
            </h3>
            <div className="usa-prose">
              <p id="update-user-modal-description-1">
                {forceDisable && <span>This user has one or more active repeat tasks. Disabling the user will cancel all active repeat tasks for this user.</span>}
                {forceTaskerRoleRemoval && <span>This user has one or more active repeat tasks. Removing the tasker role will cancel all active repeat tasks for this user.</span>}
              </p>
              <p id="update-user-modal-description-2">
                Do you wish to continue?
              </p>
            </div>
            <ModalFooter>
              <ModalButton
                size="big"
                className="primary"
                onClick={handleConfirmation}
              >
                Yes
              </ModalButton>
              <ModalButton
                unstyled
                size="big"
                className="padding-105 text-center"
                onClick={handleRejectConfirmation}
              >
                No, go back
              </ModalButton>
            </ModalFooter>
          </Modal>
        )}
      </Container>
    </Layout>
  );
};

TemplateUser.propTypes = {
  user: PropTypes.object,
  organizations: PropTypes.array,
  accessType: PropTypes.string,
  breadcrumbs: PropTypes.array,
  verifications: PropTypes.array,
  isLoading: PropTypes.bool,
  onUpdateUser: PropTypes.func,
  onReissuePassword: PropTypes.func,
  onUpdateVerification: PropTypes.func,
  showConfirmModal: PropTypes.bool,
  handleConfirmation: PropTypes.func,
  handleRejectConfirmation: PropTypes.func
};

export default TemplateUser;
