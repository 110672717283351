import { useMappedState } from 'redux-react-hook';

import Task from 'models/task';
import RepeatRequest from 'models/repeatRequest';
import { TaskRequestTypes } from 'data/task-request-types';

export default function useConflictedTask (taskId) {
  const mapState = ({ tasks }) => tasks;

  const { conflictedTask } = useMappedState(mapState);

  if (!conflictedTask) {
    return {};
  }

  let TaskModel = Task;

  if (conflictedTask.properties.taskRequestType === TaskRequestTypes.REPEAT) {
    TaskModel = RepeatRequest;
  }

  const outputTask = new TaskModel().ingestTaskBody(conflictedTask);

  return outputTask;
}
