import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { setToken, setUserActionState } from 'state/actions';

export default function useCognitoToken (uriFragment = {}) {
  const dispatch = useDispatch();
  const { access_token: token } = uriFragment;

  useEffect(() => {
    // clear out any user errors
    dispatch(
      setUserActionState({
        setToken: {
          isError: false
        }
      })
    );
    dispatch(setToken(token));
  }, [token]); // eslint-disable-line
}
