/**
 * This is logic for "Smart Submission".
 * If a user does not have the archive-buyer role:
 *   - Only allow $0 items to be ordered
 *   - Grey out / disable non-free items with an overlay
 *   - Update summary to show only 'free' items
 *   - Grey out order summary if order has no free items
 *   - Disable submission if order has no free items
 */
export const filterOrderableItems = (order, groupedOrderItemsByCollect, addOnItems) => {
  const {
    orderDetails = {},
    authorized,
    authorizationDenialReason = {}
  } = order;

  let orderIsAuthorized = authorized;

  const { summary: originalSummary = {} } = orderDetails;
  if (orderIsAuthorized || authorizationDenialReason.code !==
      'AUTHORIZATION_ARCHIVE_BUYER_ACCESS_DENIED') {
    return {
      orderIsAuthorized,
      orderItems: order.items,
      addOnItems,
      orderSummary: originalSummary
    };
  }

  // filter free items
  const orderableCollectIds = groupedOrderItemsByCollect
    .filter((orderItem) => orderItem.isArchiveBuyingAllowed)
    .map((freeItem) => freeItem.collectId);

  const freeOrderableItems = order.items.filter((item) => {
    return orderableCollectIds.includes(item.collectId);
  });

  // Filter free add on-items
  let freeOrderableAddOnItems;
  if (Array.isArray(order.addOnItems) && order.addOnItems.length > 0) {
    freeOrderableAddOnItems = addOnItems.filter((addOn) =>
      orderableCollectIds.includes(addOn.collectId)
    );
  }

  let orderSummary = originalSummary;
  if (freeOrderableItems.length > 0) {
    // if only free items, order is authorized, need to adjust order summary to accomodate
    orderIsAuthorized = true;

    let filteredAvailableSubscriptionUnitsAfterOrder;
    if (originalSummary.availableSubscriptionUnitsAfterOrder) {
      // This needs to be adjusted to account for the subtracted amount on the order review response
      filteredAvailableSubscriptionUnitsAfterOrder = originalSummary.availableSubscriptionUnitsAfterOrder +
          (groupedOrderItemsByCollect.length - freeOrderableItems.length);
    }
    orderSummary = {
      total: '$0.00',
      subtotal: '$0.00',
      availableSubscriptionUnitsAfterOrder: filteredAvailableSubscriptionUnitsAfterOrder
    };
  }

  return {
    orderIsAuthorized,
    orderItems: freeOrderableItems,
    addOnItems: freeOrderableAddOnItems,
    orderSummary
  };
};
