import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAdmin } from 'hooks';

import { logError } from 'lib/logger';
import { fetchUserById, triggerNotice } from 'state/actions';

export default function useAdminFetchUser ({ id, forceNew = false } = {}) {
  const { users = [], actions = {} } = useAdmin();
  const dispatch = useDispatch();

  const user = users.find((user) => user.id === id);

  useEffect(() => {
    dispatch(fetchUserById(id)).catch((error) => {
      logError(error.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
    });
  }, [dispatch, forceNew, id]);

  return {
    ...actions.fetchUserById,
    user
  };
}
