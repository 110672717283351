import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useAois, useFetchAois, useLocation } from 'hooks';
import { updateAOIsSearchTerm, triggerNotice } from '../../state/actions';
import TemplateAois from '../../templates/TemplateAois';
import { useDispatch } from 'redux-react-hook';
import { logError } from 'lib/logger';

export default function AOIsPage () {
  const searchRef = useRef();
  const { isLoading = true, triggerFetchAOIs } = useFetchAois({ forceNew: false });
  const { aois = [], aoiFeatures = undefined, searchTerm = '' } = useAois();
  const dispatch = useDispatch();

  const {
    queryParams = {}
  } = useLocation();

  const { search } = queryParams || {};

  function handleSearch (newSearch) {
    dispatch(updateAOIsSearchTerm(newSearch));
  }

  function clearSearch () {
    dispatch(updateAOIsSearchTerm(''));
  }

  function handleAddLayerError (err) {
    logError(err.message);
    dispatch(
      triggerNotice({
        type: 'error',
        weight: 'bold',
        align: 'center',
        text: "We weren't able to display your AOI on the map! Please try again"
      })
    );
  }

  useEffect(() => {
    const searchFor = searchTerm || search;

    if (searchFor) {
      searchRef.current.value = searchFor;
      triggerFetchAOIs(searchFor);
    } else {
      triggerFetchAOIs('');
    }
  }, [searchTerm, search]);

  return (
    <>
      <Helmet bodyAttributes={{ class: 'aois' }}>
        <title>Areas of Interest (AOIs)</title>
      </Helmet>
      <TemplateAois
        isLoading={isLoading}
        aois={aois}
        aoiFeatures={aoiFeatures}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        searchValue={search}
        searchRef={searchRef}
        handleAddLayerError={handleAddLayerError}
      />
    </>
  );
}
