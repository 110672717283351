import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormRow, FormInput } from 'fogg/ui';

import { percentageToFloat } from '../lib/currency';

const DATETIME_FIELDS = ['endDate', 'startDate'];
const PERCENTAGE_FIELDS = ['resellerDiscount'];

const FormContractPeriodEdit = ({
  contractPeriod = {},
  disabled = false,
  isArchiveSub = false,
  onSubmit,
  onCancel
}) => {
  const validationRules = {};

  const {
    valueCommitmentInt,
    resellerDiscountFormatted,
    startDateInt,
    endDateInt,
    subscriptionQuota
  } = contractPeriod;

  /**
   * handleSubmit
   */

  function handleSubmit (e, fields = {}) {
    const formFields = {};
    const data = {};

    Object.keys(fields).forEach((key) => {
      if (key === 'undefined' || key === '') return;
      if (typeof fields[key].value === 'undefined') return;

      let value = fields[key].value;

      // Transform any datetime based fields to ISO

      if (DATETIME_FIELDS.includes(key) && typeof value === 'string') {
        value = new Date(parseInt(fields[key].value)).toISOString();
      }

      // Clean up % based inputs to make them more flexible and pass the
      // intended value

      if (PERCENTAGE_FIELDS.includes(key)) {
        // The converter expects a % symbol, but if the user
        // doesn't include that, we don't want it to error
        if (value.slice(-1) !== '%') {
          value = `${value}%`;
        }
        value = percentageToFloat(value);
      }

      formFields[key] = {
        ...fields[key],
        value
      };

      data[key] = value;
    });

    if (typeof onSubmit === 'function') {
      onSubmit({
        e,
        fields: formFields,
        data
      });
    }
  }

  function handleCancel (e) {
    if (e) e.preventDefault();
    if (typeof onCancel === 'function') {
      onCancel();
    }
  }

  return (
    <Form
      className="form-admin-contract-period-edit"
      rules={validationRules}
      onSubmit={handleSubmit}
    >
      <FormRow>
        {isArchiveSub ? (
          <FormInput
            id="subscriptionQuota"
            label="Subscription Total *"
            type="number"
            required={true}
            disabled={disabled}
            defaultValue={subscriptionQuota}
          />
        ) : (
          <>
            <FormInput
              id="valueCommitment"
              label="Value Commitment *"
              type="number"
              required={true}
              disabled={disabled}
              defaultValue={valueCommitmentInt}
            />
            <FormInput
              id="resellerDiscount"
              label="Reseller Discount *"
              required={true}
              disabled={disabled}
              key={resellerDiscountFormatted}
              defaultValue={resellerDiscountFormatted}
            />
          </>
        )}
      </FormRow>
      <FormRow>
        <FormInput
          id="startDate"
          label="Start Date (UTC)"
          type="datetime"
          utc={true}
          disabled={disabled}
          value={startDateInt}
          closeOnSelectDate={true}
        />
        <FormInput
          id="endDate"
          label="End Date (UTC)"
          type="datetime"
          utc={true}
          disabled={disabled}
          value={endDateInt}
          closeOnSelectDate={true}
        />
      </FormRow>

      <FormRow className="form-row-actions">
        <Button full={true} disabled={disabled}>
          Save Changes
        </Button>
        <Button full={true} disabled={disabled} onClick={handleCancel}>
          Cancel
        </Button>
      </FormRow>

      <FormRow>
        <p className="caption">* Field is required</p>
      </FormRow>
    </Form>
  );
};

FormContractPeriodEdit.propTypes = {
  contractPeriod: PropTypes.object,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  isArchiveSub: PropTypes.bool,
  accessType: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default FormContractPeriodEdit;
