import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'redux-react-hook';
import { TaskAction, Button } from 'fogg/ui';
import { device } from 'fogg/lib';
import { FaChevronDown, FaChevronUp, FaSync } from 'react-icons/fa';
import { FcCancel } from 'react-icons/fc';
import {
  TASK_STATUS_COMPLETE,
  TASK_TRANSACTION_STATUS_COMPLETED,
  TASK_TRANSACTION_STATUS_PENDING_COST_CALCULATION,
  TASK_TRANSACTION_STATUS_PENDING_USER_APPROVAL,
  TASK_TRANSACTION_STATUS_REJECTED,
  TASK_TRANSACTION_STATUS_SERVICE_REJECTED,
  TASK_TRANSACTION_STATUS_CUSTOMER_CANCELLED
} from 'data/tasks';
import { logError } from 'lib/logger';
import { navigateTo, routePathByName } from 'lib/routes';
import { formatDateTime } from 'commonLib/src/lib/datetime';
import {
  updateTaskStatusById,
  createOrderByTaskId,
  triggerError,
  searchCatalog
} from 'state/actions';
import { returnStatusByCode } from 'lib/tasks';
import { formatCurrencyToNumber, formatNumberToCurrency } from 'lib/currency';
import { TaskCollectionTiers } from 'data/task-collection-tiers';
import { TaskCollectModes } from '../data/task-collect-modes';
import CostBreakdown from './CostBreakdown';
import ItemCard from './ItemCard';

/**
 * Task Transaction status
 * The transaction status is different from the top level status, which refers to the
 * CAPI status. Transaction on the other hand defines where the task is at in relation
 * to the financial transaction and ordering of the task
 * Available statuses:
 * - COMPLETED: no transaction modifications possible. Perhaps the "download order"?
 * - PENDING_COST_CALCULATION: we're waiting for CAPI to estimate size
 * - PENDING_USER_APPROVAL: only status where you should show "approve and reject"
 * - IN_PROGRESS: this is where you would show cancellation options
 * - FAILED: The task failed at some point after it was in-progress and determined feasible.
 * - DENIED: CAPI could not accommodate the request. We have refunded any reserved funds.
 * - REJECTED: 'customer-rejected'
 * - SERVICE_REJECTED: 'service-rejected', Capella's systems rejected the task.
 * - CUSTOMER_CANCELED: 'customer-cancelled'
 */

const TRANSACTION_STATUSES_CAN_CANCEL = [
  TASK_TRANSACTION_STATUS_PENDING_USER_APPROVAL
];

const TRANSACTION_STATUSES_CAN_APPROVE = [
  TASK_TRANSACTION_STATUS_PENDING_USER_APPROVAL
];

const FinalizedStatuses = [
  TASK_TRANSACTION_STATUS_COMPLETED
];

const TaskCostAction = ({
  task = {},
  contractLabel = '',
  showContractOnly = false
}) => {
  const dispatch = useDispatch();
  const { isDomAvailable } = device;

  const {
    status,
    order = {},
    transactionStatus,
    id,
    collectMode,
    _data: { properties = {} } = {},
    costBreakdown = {},
    analytics = [],
    isSandbox
  } = task;

  const { summary = {} } = order;
  const { total } = summary;
  const { chargedFunds, publishedCollects, scheduledCollects = [] } = costBreakdown;
  const { collects = [], statusHistory = [] } = properties;
  const [{ time: lastStatusUpdateTime } = {}] = statusHistory;
  const [{ items: [{ windowOpen: scheduledCollectTime } = {}] = [] } = {}] =
    scheduledCollects;
  const taskStatusComplete = status === TASK_STATUS_COMPLETE;

  const transactionUnknownStatus = !transactionStatus;
  const transactionCompleted =
    transactionStatus === TASK_TRANSACTION_STATUS_COMPLETED;
  const transactionPendingCalculation =
    transactionStatus === TASK_TRANSACTION_STATUS_PENDING_COST_CALCULATION;
  const transactionIsRejected =
    transactionStatus === TASK_TRANSACTION_STATUS_REJECTED;
  const transactionIsServiceRejected =
    transactionStatus === TASK_TRANSACTION_STATUS_SERVICE_REJECTED;
  const transactionCanCancel =
    TRANSACTION_STATUSES_CAN_CANCEL.includes(transactionStatus);
  const transactionCanApprove =
    TRANSACTION_STATUSES_CAN_APPROVE.includes(transactionStatus);
  const taskTransactionIsFinalized = FinalizedStatuses.includes(transactionStatus);
  const taskTransactionIsCanceled = transactionStatus === TASK_TRANSACTION_STATUS_CUSTOMER_CANCELLED;

  const hasChargedFunds = !!formatCurrencyToNumber(chargedFunds);
  const isCanceledAndCharged = taskTransactionIsCanceled && hasChargedFunds;

  const displayCost = typeof total !== 'undefined' || isCanceledAndCharged;
  const canOrder = taskStatusComplete && transactionCompleted;

  const [priceExpanded, setPriceExpanded] = useState(false);
  const hasCollects = collects && collects.length;

  // To get the thumbnail we need to hit /catalog/search unfortunately
  const [hasFetched, setHasFetched] = useState(false);
  const [collectThumbnail, setCollectThumbnail] = useState(undefined);

  useEffect(() => {
    if (!hasCollects || hasFetched) return;
    async function fetchThumbnail () {
      try {
        const response = await dispatch(searchCatalog({
          query: {
            'capella:collect_id': {
              eq: collects[0].collectId
            }
          }
        }, false));
        setHasFetched(true);
        // Get thumbnail from first GEO result
        const { features = {}, numberOfResults } = response;
        if (numberOfResults > 0) {
          features.map(({ feature = {}, thumb }) => {
            if (feature.properties['sar:product_type'] === 'GEO') {
              setCollectThumbnail(thumb);
            }
          });
        }
      } catch {
        setHasFetched(true);
      }
    }
    fetchThumbnail();
  }, [hasCollects, hasFetched]);

  // Sift through collect lineItems to generate Cost Breakdown "children"
  function returnCostChildren (lineItems = []) {
    let children;
    lineItems.forEach(({ order = {} }) => {
      const vesselDetection = order.analyticImageryUpliftVS;
      const vesselClassification = order.analyticImageryUpliftVC;
      const acd = order.analyticImageryUpliftACD;
      const totalCost = formatCurrencyToNumber(order.netListPrice);
      // Deconstruct total by subtracting VS/VC/ACD cost from total
      if (vesselDetection) {
        const vesselDetectionCost = formatCurrencyToNumber(vesselDetection.amount);
        children = [
          { title: 'SAR Imagery', cost: formatNumberToCurrency(totalCost - vesselDetectionCost) },
          { title: 'Vessel Detection Analytic', cost: vesselDetection.amount }
        ];
      } else if (vesselClassification) {
        const vesselClassificationCost = formatCurrencyToNumber(vesselClassification.amount);
        children = [
          { title: 'SAR Imagery', cost: formatNumberToCurrency(totalCost - vesselClassificationCost) },
          { title: 'Vessel Classification Analytic', cost: vesselClassification.amount }
        ];
      } else if (acd) {
        const acdCost = formatCurrencyToNumber(acd.amount);
        children = [
          { title: 'SAR Imagery', cost: formatNumberToCurrency(totalCost - acdCost) },
          { title: 'Amplitude Change Detection Analytic', cost: acd.amount }
        ];
      } else if (!analytics.includes('VS') && !analytics.includes('VC') && !analytics.includes('ACD')) {
        // No analytic, so SAR Imagery = Total Cost and the only child cost to show
        // Don't display for older analytic tasks to avoid incorrectly only showing SAR Imagery
        children = [{ title: 'SAR Imagery', cost: order.netListPrice }];
      }
    });
    // Return <ItemCard> components for each child item
    if (!children) return undefined;
    return children.map(({ title, cost }, i) => {
      return <ItemCard
        item={{ title, cost }}
        key={`title-${i}`}
        isChild={true}
        actionSettings={{
          isRemovable: false
        }}
      />;
    });
  }

  // If no total is available for a collect, show "TBD" until task is completed
  const missingCostLabel = transactionCompleted ? '--' : 'TBD';

  // Map through collects and return in format for <CostBreakdown>
  const collectItems = collects.map(({ collectStatusHistory, collectId }) => {
    const collected = returnStatusByCode(collectStatusHistory, 'collected');
    const taskingTier = effectiveTaskingTier(task);
    const matchingCollect = publishedCollects?.find(pc => pc?.collectIds?.includes(collectId));
    const cost = matchingCollect?.total || missingCostLabel;

    const item = {
      itemId: collectId,
      collectDate: collected?.time ? formatDateTime(collected.time) : 'TBD',
      imagingMode: TaskCollectModes.properties[collectMode]?.label,
      taskingTier,
      cost,
      children: returnCostChildren(matchingCollect?.lineItems)
    };
    if (collectThumbnail) {
      item.thumbnail = collectThumbnail;
    }
    return item;
  });

  const cancelItemAsCollectItem = {
    itemId: 'Canceled by customer',
    canceledDate: lastStatusUpdateTime
      ? formatDateTime(lastStatusUpdateTime)
      : undefined,
    collectDate: scheduledCollectTime
      ? formatDateTime(scheduledCollectTime)
      : undefined,
    cost: chargedFunds,
    estimatedCost: total,
    thumbnailIcon: <FcCancel />
  };

  /**
   * get the items applicable for cost breakdown
   * @returns {cancelItemAsCollectItem|collectItems|[]}
   */
  function getCostBreakdownItems () {
    if (isCanceledAndCharged) {
      return [cancelItemAsCollectItem];
    }

    return collectItems;
  }

  function getCollectItemLabels () {
    if (isCanceledAndCharged) {
      return {
        collectId: 'Item',
        canceledDate: 'Canceled Date',
        collectDate: 'Scheduled Date'
      };
    }

    return {};
  }

  /**
   * handleSubmit
   */

  function handleSubmit () {
    dispatch(
      updateTaskStatusById(id, {
        status: 'approved'
      })
    ).catch((error) => {
      logError(error.message, {
        taskId: id
      });
      dispatch(triggerError());
    });
  }

  /**
   * handleCancel
   */

  function handleCancel () {
    dispatch(
      updateTaskStatusById(id, {
        status: 'rejected'
      })
    ).catch((error) => {
      logError(error.message, {
        taskId: id
      });
      dispatch(triggerError());
    });
  }

  /**
   * handleOnOrderData
   */

  function handleOnOrderData () {
    dispatch(
      createOrderByTaskId(id, {
        status: 'rejected'
      })
    )
      .then(({ orderId } = {}) => {
        const orderRoute = routePathByName('ordersDetails', {
          wildcard: [orderId]
        });
        navigateTo(orderRoute);
      })
      .catch((error) => {
        logError(error.message, {
          taskId: id
        });
        dispatch(triggerError());
      });
  }

  function effectiveTaskingTier (task) {
    const { repeatRequestId, repetitionProperties = {}, collectionTier } = (task || {});

    let intervalModifier = '';
    let effectiveTier = collectionTier;
    if (repeatRequestId && !!repetitionProperties?.collectionTier) {
      effectiveTier = repetitionProperties.collectionTier;
      if (effectiveTier === TaskCollectionTiers.TIER_ROUTINE) {
        intervalModifier = repetitionProperties.repetitionInterval < 7 ? ' < 7 days' : ' >= 7 days';
      }
    }

    return TaskCollectionTiers.properties[effectiveTier]
      ? `${TaskCollectionTiers.properties[effectiveTier].label}${intervalModifier}`
      : '--';
  }

  let actionPositive;
  let actionNegative;

  if (transactionCanApprove) {
    actionPositive = {
      onSubmit: handleSubmit,
      label: 'Submit Request'
    };
  } else if (canOrder) {
    let buttonText = 'Order Task Data';
    // Don't show $0 cost if org has pricing hidden
    if (displayCost) {
      buttonText += ' $(0)';
    }
    actionPositive = {
      onSubmit: handleOnOrderData,
      label: buttonText,
      disclaimer: 'You will not be charged'
    };
  }

  if (transactionCanCancel) {
    actionNegative = {
      onSubmit: handleCancel,
      label: 'Cancel Request'
    };
  }

  const hasActionsAvailable = !!(actionPositive || actionNegative);

  if (transactionUnknownStatus) {
    return null;
  }

  function refreshPage () {
    if (!isDomAvailable()) return;
    window.location.reload(false);
  }

  // Hide the Cost Breakdown if PROD Sandbox Task & display "Estimated" total price
  const isProdSandbox = isSandbox && process.env.ENV === 'prod';

  return (
    <div
      className="task-cost-action with-footer"
      data-has-actions-available={hasActionsAvailable}
    >
      <>
        {task && status && !showContractOnly && (
          <>
            <TaskAction positive={actionPositive} negative={actionNegative}>
              <div>
                {transactionPendingCalculation && (
                  <p className="task-cost-action-status pending-calculation">
                    <span className="task-action-heading">Pending Calculation</span>
                    <small>This will take a few minutes & require the page to reload. Check back later or refresh below.</small>
                    <br/>
                    <Button onClick={refreshPage} type="text"><FaSync /> Reload Page</Button>
                  </p>
                )}
                <>
                  {displayCost && (
                    <>
                      <p className="task-cost-action-status">
                        {taskTransactionIsFinalized || isCanceledAndCharged ? (
                          <span>Total:</span>
                        ) : (
                          <span>Estimated Total:</span>
                        )}
                        <span>
                          {(taskTransactionIsFinalized && !isProdSandbox) ||
                          isCanceledAndCharged
                            ? chargedFunds
                            : total}
                        </span>
                      </p>

                      {(!!hasCollects || isCanceledAndCharged) && !isProdSandbox && (
                        <Button
                          data-testid="task-cost-full-code-breakdown-button"
                          type="text"
                          onClick={() => setPriceExpanded(!priceExpanded)}
                        >
                          Full Cost Breakdown{' '}
                          {priceExpanded ? <FaChevronUp /> : <FaChevronDown />}
                        </Button>
                      )}
                      {!taskTransactionIsFinalized && !transactionIsRejected && (
                        <p className="task-cost-action-note">
                          * Resulting charges may be lower than estimated
                        </p>
                      )}
                    </>
                  )}
                  {!displayCost &&
                    !transactionIsRejected &&
                    !transactionPendingCalculation &&
                    !transactionIsServiceRejected && (
                    <>
                      <p className="task-cost-action-status">
                        <span>Contact Reseller for pricing.</span>
                      </p>
                      {!!hasCollects && !isProdSandbox && (
                      // Show cost breakdown without pricing if "Contact Reseller for Pricing"
                        <Button
                          data-testid="task-cost-full-code-breakdown-button"
                          type="text"
                          onClick={() => setPriceExpanded(!priceExpanded)}
                        >
                            Full Task Breakdown{' '}
                          {priceExpanded ? <FaChevronUp /> : <FaChevronDown />}
                        </Button>
                      )}
                    </>
                  )}

                  {transactionIsRejected && (
                    <p className="task-cost-action-note">
                      *Task was canceled by user. No charges were made.
                    </p>
                  )}
                </>
              </div>
            </TaskAction>
            {!isProdSandbox && (
              <div
                className={`task-action-breakdown ${
                  priceExpanded ? 'expanded' : ''
                }`}
              >
                <CostBreakdown
                  items={getCostBreakdownItems()}
                  showCost={displayCost}
                  costBreakdown={costBreakdown}
                  total={total}
                  labels={getCollectItemLabels()}
                />
              </div>
            )}
          </>
        )}
        {!!contractLabel && (
          <div className="task-cost-action-footer">
            <div className="task-action-contract">
              <b>Contract:</b> {contractLabel}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

TaskCostAction.propTypes = {
  task: PropTypes.object,
  contractLabel: PropTypes.string,
  showContractOnly: PropTypes.bool
};

export default TaskCostAction;
