import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useTokenCookie, useUser } from 'commonLib/src/hooks';
import { logOut } from 'state/actions';
import {
  routePathByName,
  chainPlatformRoutes
} from 'lib/routes';
import { ROUTE_SHARED_LOGIN, ROUTE_SHARED_LOGOUT } from 'data/route-names';
import { logError } from 'lib/logger';

/**
 * Hook that redirects or navigates user to logout, login, and other paths based on
 * their session and other related properties
 * @param {object} props
 * @param {string} [props.next=''] pathname to redirect to after login (ie. ?next=/search/)
 * @param {bool} [props.sessionExpired=false] flag for knowing if user's session has expired
 * @param {bool} [props.manualLogout=true] if true, force a logout to avoid some redirect logic
 */
export default function useLogOut ({ next, sessionExpired = false, manualLogout = false }) {
  const dispatch = useDispatch();
  const { refresh } = useTokenCookie();
  const { user = {} } = useUser();

  const isBrowser = typeof window !== 'undefined';
  // Construct logout urls based on various url params
  const sharedLogoutUrl = routePathByName(ROUTE_SHARED_LOGOUT);
  // Redirect to logout if session is expired
  if (sessionExpired && next && isBrowser) {
    const expiredUrl = chainPlatformRoutes(
      [sharedLogoutUrl, next],
      'Console',
      sessionExpired
    );
    window.location.replace(expiredUrl);
  }

  const shouldLogOut = !!user.id || sessionExpired || manualLogout;
  useEffect(() => {
    async function logUserOut () {
      // If we still have a token in state, log the user out
      try {
        await dispatch(logOut());
        if (!next && isBrowser) {
          window.location.replace(sharedLogoutUrl);
        }
      } catch (e) {
        logError(e.message, e);
      }
    }
    if (shouldLogOut) {
      logUserOut();
    }
  }, [dispatch, shouldLogOut, next]);

  // No auth token, no user state, return to platform
  if (!user.id) {
    if (next && !manualLogout && isBrowser) {
      // Send user back to platform login
      const loginUrl = chainPlatformRoutes(
        [routePathByName(ROUTE_SHARED_LOGIN), next],
        'Console',
        sessionExpired
      );
      window.location.replace(loginUrl);
    }
    if (!next && !refresh && isBrowser) {
      window.location.replace(sharedLogoutUrl);
    }
  }
}
