import { useMappedState } from 'redux-react-hook';

import Task from 'models/task';

export default function useSubResellerTasks ({
  includeSpawned = true,
  includeContractDetails = false
}) {
  const mapState = ({ subreseller, user }) => ({ subreseller, user });

  const {
    subreseller: { tasks = [], totalPages, columns },
    user: {
      user: { organization: { contracts = [] } = {} }
    }
  } = useMappedState(mapState);

  // Only return valid tasks with a tasking request ID
  let filteredTasks = tasks.filter(
    (task) => task?.properties?.taskingrequestId
  );

  // Filter out tasks spawned from a repeat request if desired
  if (!includeSpawned) {
    filteredTasks = filteredTasks.filter(
      (task) => !task?.properties?.repeatrequestId
    );
  }

  // map in contract details if they are included and enabled
  if (includeContractDetails) {
    filteredTasks = Task.mapContractsToTasks(filteredTasks, contracts);
  }

  return {
    tasks: filteredTasks.map((task) => {
      const instance = new Task();
      instance.ingestTaskBody(task);
      return instance;
    }),
    columns,
    totalPages
  };
}
