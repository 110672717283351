/**
 * Hook for dynamic a/b decision for displaying a template or component
 *
 * @param {object} param0
 * @param {React.Component|HTMLElement} param0.a
 * @param {React.Component|HTMLElement} param0.b
 * @param {string} [param0.defaultChoice='a']
 * @param {boolean} [param0.conditionMet=false]
 */
function useABComponent ({
  a,
  b,
  defaultChoice = 'a',
  conditionMet = false
}) {
  const componentSwitcher = {
    a,
    b
  };
  const oppositeChoice = defaultChoice === 'a' ? 'b' : 'a';
  const DefaultComponent = componentSwitcher[defaultChoice];
  const ConditionalComponent = componentSwitcher[oppositeChoice];

  if (conditionMet) {
    return ConditionalComponent;
  }

  return DefaultComponent;
}

export default useABComponent;
