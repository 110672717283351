import { constructRequestActionManager } from 'commonLib/src/lib/actions';
import { routePathByName } from 'lib/routes';
import { mapTaskTableField, taskFiltersToAPI } from 'lib/tasks';
import {
  SET_SUB_RESELLER_ORG,
  UPDATE_SUB_RESELLER_ACTION_STATE,
  UPDATE_SUB_RESELLER_ORG,
  UPDATE_SUB_RESELLER_TASK,
  UPDATE_SUB_RESELLER_TASKS,
  UPDATE_SUB_RESELLER_USER,
  UPDATE_SUB_RESELLER_REPEAT_REQUESTS,
  UPDATE_SUB_RESELLER_TASKS_PAGED,
  UPDATE_SUB_RESELLER_REPEAT_REQUESTS_PAGED,
  UPDATE_SUB_RESELLER_TASKS_COLUMNS,
  UPDATE_SUB_RESELLER_REPEAT_REQUESTS_COLUMNS,
  UPDATE_SUB_RESELLER_ORG_USERS,
  UPDATE_SUB_RESELLER_ORGANIZATION_CONTRACT
} from 'data/action-types';
import { CONTENT_TYPE_JSON } from 'data/content-types';
import { TASKING_REQUEST_ID } from 'data/property-names';
import { ACCESS_ROLES, SUB_RESELLER_ROLE } from 'data/roles';
import {
  ROUTE_API_ORGS_ORG,
  ROUTE_API_SUB_RESELLER_ORG,
  ROUTE_API_TASK,
  ROUTE_API_TASK_COLLECTS,
  ROUTE_API_TASK_TILES,
  ROUTE_API_USERS,
  ROUTE_TASKS,
  ROUTE_API_TASKS_SEARCH,
  ROUTE_API_REPEAT_REQUESTS_SEARCH,
  ROUTE_API_EXPORT_TASKS_CSV,
  ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV,
  ROUTE_API_RESELLER_ORG_USERS
} from 'data/route-names';

import { SUB_RESELLER_SCOPE } from 'data/scopes';
import {
  PUT_UPDATE_USER_VALID_FIELDS,
  PUT_UPDATE_USER_VALID_ADDRESS_FIELDS
} from 'data/user';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';

import { updateAdminUsers } from 'state/actions';
import {
  DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS,
  setPropertyIfExists,
  UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS
} from '../../../common-web-lib';

// Changes here most likely should be made identically to actions/reseller.js
// as they share the same root properties

// The roles that a subreseller account is able to manage

const SUB_RESELLER_MANAGED_ACCESS_ROLES = ACCESS_ROLES.filter(
  ({ manageAccess }) => {
    return manageAccess ? manageAccess.includes(SUB_RESELLER_ROLE) : false;
  }
);

/**
 * setSubResellerActionState
 * @description Updates the subreseller store's status
 */

export function setSubResellerActionState (status) {
  return {
    type: UPDATE_SUB_RESELLER_ACTION_STATE,
    data: status
  };
}

const createDispatchRequestAction = constructRequestActionManager(
  setSubResellerActionState
);

/**
 * subResellerFetchOrganization
 * @description Fetches a subreseller organization
 * @param {string} organizationId
 * @param {object} [requestOptions={}] - optional request options
 * @param {boolean} [requestOptions.eagerLedgerIncludes=false] - if true, returns additional eager loaded orders and opportunity discounts with each contract
 */
export const subResellerFetchOrganizationById = (organizationId, {
  eagerLedgerIncludes = false
} = {}) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'subResellerFetchOrganizationById',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_SUB_RESELLER_ORG, {
          wildcard: [organizationId],
          params: {
            eagerLedgerIncludes
          }
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(_setSubResellerOrganization(data));

    return data;
  };
};

/**
 * fetchUsersByResellerId
 * @description fetches users from reseller org and managed orgs
 * @param {string} resellerId
 */
export const fetchUsersBySubResellerId = (subResellerId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchUsersBySubResellerId',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_RESELLER_ORG_USERS, {
          wildcard: [subResellerId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateSubResellerOrgUsers(data));

    return data;
  };
};

/**
 * fetchTasksBySubResellerId
 * @description Fetches the tasks for a given subreseller
 * @param {string} subResellerId ID of the organization
 */
export const fetchTasksBySubResellerId = (subResellerId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksBySubResellerId',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_TASKS).slice(
          0,
          -1
        )}?resellerId=${subResellerId}`,
        method: 'fetch',
        headers: {
          'Content-Type': CONTENT_TYPE_JSON
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);

    const { data } = request || {};

    dispatch(updateSubResellerTasks(data));

    return data;
  };
};

/**
 * @description Filter based task search for subresellers
 * @param {string} subResellerId subreseller ID to search by
 * @param {object} paging pagination page & limit
 * @param {object} [filters={}] e.g. { filterName: ['value'] }
 * @param {object} [sortObj = {}] e.g. { sort: 'status', order: 'asc' }
 * @param {AbortController} [controller = new AbortController()] AbortController signal to cancel stale requests
 */

export const searchTasksBySubResellerId = (
  subResellerId,
  { page = 1, limit = DEFAULT_PAGING_LIMIT },
  filters = {},
  sortObj = {},
  controller
) => {
  // Convert UI filters format & naming to API format
  const filterPayload = taskFiltersToAPI(filters, 'single') || {};
  const { sort, order } = sortObj;

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'searchTasksBySubResellerId',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASKS_SEARCH, {
          params: {
            page,
            limit,
            sort: mapTaskTableField(sort),
            order: order
          }
        }),
        data: {
          filter: {
            ...filterPayload,
            resellerId: subResellerId
          }
        },
        method: 'post',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request || {};

    const { results, currentPage, totalPages } = data;

    dispatch(updateSubResellerTasksPaged(data));

    return { results, currentPage, totalPages };
  };
};

/**
 * Fetch action for a csv representation of a sub-reseller's tasking request data
 *
 * @param {string} subResellerId
 * @param {object} [searchFilters={}] an object of search filters in key value shape per filter, e.g. { status: '', collectMods: [], ... }
 * @param {{ sort: string, order: 'asc'|'desc' }} [sortObj={}] sort and order object to pass as parameters to the download endpoint
 */
export const fetchTasksCsvBySubResellerId = (subResellerId, searchFilters = {}, sortObj = {}) => {
  return async (dispatch, getState) => {
    const { sort, order } = sortObj;
    // Convert UI filters format & naming to API format
    const filterPayload = taskFiltersToAPI(searchFilters, 'single') || {};

    const dispatchAction = createDispatchRequestAction({
      name: fetchTasksCsvBySubResellerId.name,
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_EXPORT_TASKS_CSV, {
          params: { sort: mapTaskTableField(sort), order }
        }),
        data: {
          filter: {
            ...filterPayload,
            resellerId: subResellerId
          }
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });
    const request = await dispatchAction(dispatch, getState);
    const { data } = request;
    return data;
  };
};

/**
 * @description Filter based task search for subresellers
 * @param {string} subResellerId subreseller ID to search by
 * @param {object} paging pagination page & limit
 * @param {object} [filters={}] e.g. { filterName: ['value'] }
 * @param {object} [sortObj = {}] e.g. { sort: 'status', order: 'asc' }
 * @param {AbortController} [controller = new AbortController()] AbortController signal to cancel stale requests
 */

export const searchRepeatRequestsBySubResellerId = (
  subResellerId,
  { page = 1, limit = DEFAULT_PAGING_LIMIT },
  filters = {},
  sortObj = {},
  controller
) => {
  // Convert UI filters format & naming to API format
  const filterPayload = taskFiltersToAPI(filters, 'repeat') || {};
  const { sort, order } = sortObj;

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'searchRepeatRequestsBySubResellerId',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_REPEAT_REQUESTS_SEARCH, {
          params: {
            page,
            limit,
            sort: mapTaskTableField(sort, 'repeat'),
            order: order
          }
        }),
        data: {
          filter: {
            ...filterPayload,
            resellerId: subResellerId
          }
        },
        method: 'post',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request || {};

    const { results, currentPage, totalPages } = data;

    dispatch(updateSubResellerRepeatRequestsPaged(data));

    return { results, currentPage, totalPages };
  };
};

/**
 * Fetch action for a csv representation of a sub-reseller's repeat request data
 *
 * @param {string} subResellerId
 * @param {object} [searchFilters={}] an object of search filters in key value shape per filter, e.g. { status: '', collectMods: [], ... }
 * @param {{ sort: string, order: 'asc'|'desc' }} [sortObj={}] sort and order object to pass as parameters to the download endpoint
 */
export const fetchRepeatRequestsCsvBySubResellerId = (subResellerId, searchFilters = {}, sortObj = {}) => {
  return async (dispatch, getState) => {
    const { sort, order } = sortObj;
    // Convert UI filters format & naming to API format
    const filterPayload = taskFiltersToAPI(searchFilters, 'repeat') || {};
    const dispatchAction = createDispatchRequestAction({
      name: fetchRepeatRequestsCsvBySubResellerId.name,
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV, {
          params: { sort: mapTaskTableField(sort), order }
        }),
        data: {
          filter: {
            ...filterPayload,
            resellerId: subResellerId
          }
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });
    const request = await dispatchAction(dispatch, getState);
    const { data } = request;
    return data;
  };
};

/**
 * fetchSubResellerTaskByTaskId
 * @description Fetch a sub-reseller's task by it's ID
 * @param {string} taskId ID of the task
 */

export const fetchSubResellerTaskByTaskId = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchSubResellerTaskByTaskId',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_API_TASK)}/${taskId}`,
        method: 'fetch',
        headers: {
          'Content-Type': CONTENT_TYPE_JSON
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateSubResellerTasks([data]));

    return data;
  };
};

/**
 * fetchSubResellerTasksCollectsById
 * @description Gets a given subreseller task's collects
 * @param {string} taskId ID of the task
 */

export const fetchSubResellerTasksCollectsById = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchSubResellerTasksCollectsById',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASK_COLLECTS, {
          wildcard: [taskId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(
      updateSubResellerTask({
        properties: {
          [TASKING_REQUEST_ID]: taskId,
          collects: data
        }
      })
    );

    return data;
  };
};

/**
 * Fetches the repeat requests by subResellerId
 * @param {string} subResellerId ID of the sub reseller
 */
export const fetchRepeatRequestsBySubResellerId = (subResellerId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchRepeatRequestsBySubResellerId',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName('apiRepeatRequest', {
          params: {
            resellerId: subResellerId,
            skip: 0,
            limit: 1000
          }
        }),
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateSubResellerRepeatRequests(data));

    return data;
  };
};

/**
 * subResellerPutUpdateUser
 * @description Updates a subreseller user's profile with the given data
 * @param {string} userId The user's ID
 * @param {object} data Updated user data
 */

export const subResellerPutUpdateUser = (userId, data) => {
  return async (dispatch, getState) => {
    // we only want to pass params that are meant to be updated

    const userData = {
      ...setPropertyIfExists('disabled', data.disabled),
      ...setPropertyIfExists('forceDisabled', data.forceDisabled)
    };
    const address = {};

    PUT_UPDATE_USER_VALID_FIELDS.forEach((field) => {
      if (typeof data[field] !== 'undefined') {
        userData[field] = data[field];
      }
    });

    SUB_RESELLER_MANAGED_ACCESS_ROLES.forEach((role) => {
      if (typeof data[role.apiKey] === 'boolean' && role.updateDisabled !== true) {
        userData[role.apiKey] = data[role.apiKey];
      }
    });

    PUT_UPDATE_USER_VALID_ADDRESS_FIELDS.forEach((field) => {
      if (data[field]) {
        address[field] = data[field];
      }
    });

    // Only add the address prop if we have anything to change

    if (Object.keys(address) > 0) {
      userData.address = address;
    }

    const dispatchAction = createDispatchRequestAction({
      name: 'subResellerPutUpdateUser',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_API_USERS)}/${userId}`,
        method: 'put',
        data: userData
      }
    });

    let response;
    try {
      response = await dispatchAction(dispatch, getState);
    } catch (err) {
      // Expected errors, catch and return message
      if (err.message === DISABLE_DENIED_ACTIVE_REPEAT_REQUESTS || err.message === UPDATE_DENIED_ACTIVE_REPEAT_REQUESTS) {
        return {
          updated: false,
          message: err.message
        };
      }
      // Unexpected error, throw it up to the parent
      throw err;
    }

    const { data: responseData } = response || {};

    const { subreseller } = getState();

    const updatedUserData = {
      id: userId,
      ...responseData
    };

    dispatch(_updateSubResellerUser(updatedUserData));
    dispatch(subResellerFetchOrganizationById(subreseller.organization.id));

    // Used to update user list to properly re-render pages on update
    dispatch(updateAdminUsers(responseData));

    return updatedUserData;
  };
};

/**
 * subResellerUpdateOrganization
 * @description Updates a subreseller organization given the data object
 * @param {object} data
 */

export const subResellerUpdateOrganization = (organizationId, data) => {
  return async (dispatch, getState) => {
    const organizationData = {
      name: data.organizationName,
      contractType: data.type,
      address: {
        id: data.addressId,
        street: data.street,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country
      },
      salesforceAccountId: data.salesforceAccountId
    };

    const dispatchAction = createDispatchRequestAction({
      name: 'subResellerUpdateOrganization',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_ORGS_ORG, {
          wildcard: [organizationId]
        }),
        method: 'put',
        data: organizationData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: requestData } = request || {};

    dispatch(_updateSubResellerOrganization(requestData));

    return requestData;
  };
};

/**
 * fetchSubResellerTasksTilesById
 * @description Gets a given subreseller task's tiles
 * @param {string} taskId ID of the task
 */

export const fetchSubResellerTasksTilesById = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchSubResellerTasksTilesById',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_TASK_TILES, {
          wildcard: [taskId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(
      updateSubResellerTask({
        properties: {
          [TASKING_REQUEST_ID]: taskId,
          tiles: data
        }
      })
    );

    return data;
  };
};

/**
 * updateSubresellerOrganizationContract
 * @description
 * @param {string} organizationId The organization ID
 * @param {string} contractId The contract ID
 * @param {object} data Updated contract data
 */

export const updateSubresellerOrganizationContract = (
  organizationId,
  contractId,
  data
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateSubresellerOrganizationContract',
      scope: SUB_RESELLER_SCOPE,
      request: {
        url: routePathByName('apiOrganizationsContracts', {
          wildcard: [organizationId, contractId]
        }),
        method: 'put',
        data
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(createUpdateOrganizationContractAction(responseData));

    return responseData;
  };
};

/******************
 * STATE UPDATERS *
 ******************/

/**
 * updateSubResellerTask
 * @description Update an individual subreseller task
 */

export const updateSubResellerTask = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_TASK,
    data
  };
};

/**
 * updateSubResellerTasks
 * @description Set the subreseller tasks
 */

export const updateSubResellerTasks = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_TASKS,
    data
  };
};

/**
 * updateSubResellerTasks
 * @description Set the subreseller tasks
 */

export const updateSubResellerRepeatRequests = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_REPEAT_REQUESTS,
    data
  };
};

/**
 * updateSubResellerTasksPaged
 * @description Set the subreseller tasks in paged format
 */

export const updateSubResellerTasksPaged = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_TASKS_PAGED,
    data
  };
};

/**
 * updateSubResellerTasksPaged
 * @description Set the subreseller tasks in paged format
 */

export const updateSubResellerRepeatRequestsPaged = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_REPEAT_REQUESTS_PAGED,
    data
  };
};

/**
 * updateSubResellerOrgUsers
 * @description Stores the users of reseller and managed orgs
 */
export const updateSubResellerOrgUsers = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_ORG_USERS,
    data
  };
};

/**
 * updateSubResellerTasksColumns
 * @description Set the current active task table columns & column order
 */
export const updateSubResellerTasksColumns = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_TASKS_COLUMNS,
    data
  };
};

/**
 * updateSubResellerRepeatRequestsColumns
 * @description Set the current active repeat requests table columns & column order
 */
export const updateSubResellerRepeatRequestsColumns = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_REPEAT_REQUESTS_COLUMNS,
    data
  };
};

/**
 * _setSubResellerOrganization
 */

export const _setSubResellerOrganization = (data) => {
  return {
    type: SET_SUB_RESELLER_ORG,
    data
  };
};

/**
 * _updateSubresellerOrganization
 */

export const _updateSubResellerOrganization = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_ORG,
    data
  };
};

/**
 * _updateSubresellerUser
 */

export const _updateSubResellerUser = (data) => {
  return {
    type: UPDATE_SUB_RESELLER_USER,
    data
  };
};

/**
 * createUpdateOrganizationContractAction
 * @description Update a Reseller organization's contract
 */

const createUpdateOrganizationContractAction = (contract) => {
  return {
    type: UPDATE_SUB_RESELLER_ORGANIZATION_CONTRACT,
    data: {
      contract
    }
  };
};
