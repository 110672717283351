import { useSubReseller, useSubResellerUsers } from 'hooks';

/**
 * useSubResellerAllUsers
 * @description Returns all users belonging to a Reseller
 * @note This includes all users from child organizations
 */

export default function useSubResellerAllUsers () {
  const { users: resellerUsers = [] } = useSubResellerUsers();

  const { organization = {} } = useSubReseller();
  const { childOrganizations = [] } = organization;

  const childUsers = childOrganizations.flatMap(({ users }) => users);

  return {
    users: [...resellerUsers, ...childUsers]
  };
}
