import { exportClassData, updateClassData, destroyClassData } from 'lib/util';
import { dateIsExpired } from 'lib/datetime';

class Contract {
  constructor (data = {}) {
    this.accountId = data.accountId;
    this.chargedCancellationFunds = data.chargedCancellationFunds;
    this.contractNumber = data.contractNumber;
    this.contractType = data.contractType;
    this.endDate = data.endDate;
    this.id = data.id;
    this.name = data.name;
    this.opptyName = data.opptyName;
    this.partnerAccount = data.partnerAccount;
    this.pricebook2Id = data.pricebook2Id;
    this.startDate = data.startDate;
    this.update(data);
  }

  data () {
    return exportClassData(this);
  }

  update (data) {
    return updateClassData(this, {
      ...data
    });
  }

  destroy () {
    return destroyClassData(this);
  }

  isExpired () {
    return dateIsExpired(this.endDate);
  }
}

export default Contract;
