import { useState, useEffect } from 'react';
import { useMappedState } from 'redux-react-hook';
import { defaultLayers } from 'data/map-services';

/**
 * Hook for easily accessing the current map layers, specifically basemap layers
 * so we can persist basemap changes through the various maps
 * @param {object} layers
 */
export default function useMapLayers () {
  const mapState = ({ ui }) => ui;

  const { basemap } = useMappedState(mapState);
  const [currentLayers, setCurrentLayers] = useState(defaultLayers);

  // Watch for basemap selection changes
  useEffect(() => {
    if (!basemap) return;

    const overlayLayers = currentLayers.overlay;
    const baseLayers = currentLayers.base;
    baseLayers.map(layer => {
      // if basemap selection matches, set defaultIsActive to true
      layer.defaultIsActive = layer.serviceName === basemap;
      return layer;
    });

    setCurrentLayers({
      overlay: overlayLayers,
      base: baseLayers
    });
  }, [basemap]);

  return {
    basemap,
    layers: currentLayers
  };
}
