import { combineReducers } from 'redux';

import { USER_DESTROY_SESSION } from 'data/action-types';

// Shared
import admin from 'commonLib/src/state/reducers/admin';
import items from 'commonLib/src/state/reducers/items';
import org from 'commonLib/src/state/reducers/org';
import reseller from 'commonLib/src/state/reducers/reseller';
import user from 'commonLib/src/state/reducers/user';
// App-specific
import aois from './aois';
import app from './app';
import cart from './cart';
import contracts from './contracts';
import orders from './orders';
import search from './search';
import subreseller from './subreseller';
import tasks from './tasks';
import ui from './ui';
import repeatRequests from './repeatRequests';
import repeatRequestChildTasks from './repeatRequestChildTasks';
import collectionTypes from './collectionTypes';

const appReducer = combineReducers({
  aois,
  app,
  admin,
  contracts,
  cart,
  items,
  orders,
  org,
  reseller,
  search,
  subreseller,
  tasks,
  ui,
  user,
  repeatRequests,
  repeatRequestChildTasks,
  collectionTypes
});

export default (state, action) => {
  // If we're destroying the user's session, kill everything and let the
  // state revert back to it's default state
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992

  if (action.type === USER_DESTROY_SESSION) {
    state = undefined;
  }

  return appReducer(state, action);
};
