/**
 * type enumerators for task requests
 */
export const TaskRequestTypes = {
  STANDARD: 'standard',
  REPEAT: 'repeat',
  OOSM: 'oosm',
  properties: {
    standard: {
      label: 'Single Task'
    },
    repeat: {
      label: 'Repeat Task'
    },
    oosm: {
      label: 'OOSM'
    }
  }
};
