import React from 'react';

import {
  useAdminOrganizations,
  useAdminFetchOrganizations,
  useAdminFetchLicenses,
  TemplateOrganizations,
  routePathByName
} from 'commonLib';
import { ROUTE_ADMIN_ORGS_DETAILS, ROUTE_ADMIN_ORGS_CREATE } from '../../../data';

const AdminOrganizationsPage = () => {
  const { isLoading: fetchOrgIsLoading } = useAdminFetchOrganizations();
  const { isLoading: licensesIsLoading } = useAdminFetchLicenses();
  const organizations = useAdminOrganizations();
  const isLoading = fetchOrgIsLoading || licensesIsLoading;

  const organizationsState = {
    isLoading
  };

  const templateOptions = {
    detailsRouteName: ROUTE_ADMIN_ORGS_DETAILS,
    createRouteName: ROUTE_ADMIN_ORGS_CREATE,
    showSearchFilters: true
  };

  const breadcrumbs = [
    {
      label: 'Admin',
      to: routePathByName('admin')
    }
  ];

  return (
    <TemplateOrganizations
      organizations={organizations}
      breadcrumbs={breadcrumbs}
      state={organizationsState}
      options={templateOptions}
    />
  );
};

export default AdminOrganizationsPage;
