import React, { useEffect, useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useLens } from 'fogg/hooks';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import PropTypes from 'prop-types';

import { redirectTo, orgIsArchiveSubOnly } from 'commonLib';
import { userHasTaskingAccess } from 'commonLib/src/lib/role-util';

import { fillEmptyItem } from 'lib/data';
import { addParamsToUrl } from 'lib/util';
import { routePathByName } from 'lib/routes';
import { getLookAngleRange } from 'lib/tasks';
import { returnDerivedCartItems } from 'lib/search';
import { formatDate, defaultInternationalFormat } from 'lib/datetime';
import { tileLayerPropertiesFromFeature, getGeoJsonCenter } from 'lib/map';
import { addAndConfigCartItems, fetchTaskByCollectId, triggerError, clearNotice } from 'state/actions';
import { useCart, useFetchItem, useItemProperties, useTaskCreate, useTaskContext, useUser, useFetchCollectionTypes } from 'hooks';

import { Button, Panel, Badge } from 'fogg/ui';
import {
  MdRepeat,
  MdAddShoppingCart,
  MdCheck,
  MdZoomIn
} from 'react-icons/md';
import { Accordion } from '@trussworks/react-uswds';
import { FaSpinner, FaExternalLinkAlt } from 'react-icons/fa';

import CopyButton from './CopyButton';
import HelpfulButton from './HelpfulButton';

let productAoiFeatureGroup, productTilesFeatureGroup;

const OFF_NADIR_PAD = 5;
const AZIMUTH_ANGLE_PAD = 10;
const GRAZING_ANGLE_PAD = 5;

const collectProperties = [
  'platform',
  'sar:instrument_mode',
  'sar:polarizations',
  'datetime',
  'locale:datetime',
  'locale:timezone',
  'view:incidence_angle',
  'view:look_angle',
  'capella:squint_angle',
  'capella:orbital_plane',
  'sat:orbit_state',
  'sar:observation_direction',
  'synthetic:collect_time'
];
const productProperties = [
  'sar:product_type',
  'start_datetime',
  'end_datetime',
  'sar:pixel_spacing_range',
  'sar:pixel_spacing_azimuth',
  'sar:looks_range',
  'sar:looks_azimuth',
  'sar:resolution_range',
  'capella:resolution_ground_range',
  'sar:resolution_azimuth',
  'capella:image_length',
  'capella:image_width',
  'capella:image_formation_algorithm',
  'created',
  'updated',
  'capella:look_angle_delta'
];
const titleArray = ['Collect', 'Product'];
const generateAccordionItems = items => {
  // We do this convoluted mapping to preserve the property order defined in the arrays
  const filteredProperties = [collectProperties, productProperties].map(propertyGroup => propertyGroup.reduce((acc, propertyKey) => {
    const property = items.find(({ key }) => key === propertyKey);
    if (property) acc.push(property);
    return acc;
  }, []));

  items
    .filter(({ key }) => key.includes('link:derived_from'))
    .sort(({ key: key1 = '' } = {}, { key: key2 = '' } = {}) => key1.at(-1) - key2.at(-1))
    .forEach(item => filteredProperties[1].push(item));

  return filteredProperties.map((properties, i) => ({
    title: `${titleArray[i]} Properties`,
    id: `properties-${titleArray[i]}`,
    className: 'item-properties-section',
    expanded: true,
    content: (
      <ul>
        {properties.map(({ property, value, type }, i) => {
          return (
            <li key={i}>
              <b>{property}</b>
              {type === 'url' ? (
                <a href={value[1]} target="_blank" rel="noreferrer" className="sidebar-properties-link">
                  {value[0]} <FaExternalLinkAlt />
                </a>
              ) : (
                <span className={type === 'meters' ? 'item-properties-meters' : ''}>{value}</span>
              )}
            </li>
          );
        })}
      </ul>
    ),
    headingLevel: 'h4'
  }));
};

const SidebarDetails = ({ collectionId, itemId }) => {
  const dispatch = useDispatch();
  const { item, isLoading: itemIsLoading } = useFetchItem({
    collectionId,
    itemId,
    setActive: true
  });

  const { items: cartItems } = useCart();

  const { map = {} } = useLens();
  const {
    addTileLayerToMap,
    clearTileLayers,
    addShapeToMap,
    clearLayers,
    createFeatureGroup,
    zoomToBounds
  } = map;

  const flags = useFlags();
  const { isLoading: userIsLoading, user: { organization = {}, roles = {} } = {} } = useUser();
  const orgIsArchSubOnly = orgIsArchiveSubOnly(organization);
  const isTaskingAllowed = userHasTaskingAccess(roles, flags);

  const isLoading = itemIsLoading || userIsLoading;

  // We only want to create our feature groups once, so if this is the first render, most likely
  // we don't have feature groups yet, so create them if they're undefined
  if (!productAoiFeatureGroup) {
    productAoiFeatureGroup = createFeatureGroup('product-aoi-feature-group');
  }

  if (!productTilesFeatureGroup) {
    productTilesFeatureGroup = createFeatureGroup(
      'product-tiles-feature-group'
    );
  }

  // Does this item exist in the cart?
  const isInCart = !!(
    item && cartItems.findIndex((i) => i.id === item.id) !== -1
  );

  let activeItem = item;

  if (isLoading) {
    activeItem = fillEmptyItem();
  }

  const { itemProperties = [] } = useItemProperties({ item: activeItem });

  const activeGeoJson = activeItem &&
    activeItem.feature && {
    type: 'FeatureCollection',
    features: [activeItem.feature]
  };

  const { path: createTaskPath } = useTaskCreate({
    geoJson: activeGeoJson
  });
  const { updateTaskState } = useTaskContext() || {};

  // When someone navigates to a product details page, this component will render. We
  // use the useEffect hook to add our tileLayer preview and the AOI boundary to the map.
  // We also use the return value of useEffect which basically acts as an unmount to
  // clear those layers to clean up our map state
  useEffect(() => {
    if (isLoading || !activeItem) return;

    const tileLayer = tileLayerPropertiesFromFeature(activeItem);

    addTileLayerToMap({
      ...tileLayer,
      featureGroup: productTilesFeatureGroup,
      options: {
        ...tileLayer.options,
        zIndex: 5
      }
    });

    if (activeGeoJson) {
      addShapeToMap({
        geoJson: activeGeoJson,
        featureGroup: productAoiFeatureGroup,
        clearOtherLayers: true,
        panToShape: false,
        shapeOptions: {
          fill: false,
          weight: 2
        }
      });
    }
    return () => {
      clearTileLayers({
        featureGroup: productTilesFeatureGroup
      });

      clearLayers({
        featureGroup: productAoiFeatureGroup
      });
    };
  }, [isLoading, activeItem]);

  const [isRetaskLoading, setIsRetaskLoading] = useState(false);

  /**
   * handleAddToCart
   */
  function handleAddToCart () {
    const { feature = {} } = activeItem || {};
    const itemsToAdd = returnDerivedCartItems(feature);
    dispatch(addAndConfigCartItems(itemsToAdd));
  }

  /**
   * zooms to the active feature on the map
   */
  function handleZoomInClick () {
    if (productAoiFeatureGroup) {
      // fit map bounds to feature bounding box
      const layerBounds = productAoiFeatureGroup.getBounds();
      zoomToBounds({
        layerBounds
      });
    }
  }

  function findCollectionType (typeName) {
    return collectionTypes.find(({ name }) => name === typeName);
  }
  // retask
  const { isLoading: collectionTypesIsLoading, collectionTypes } = useFetchCollectionTypes();
  const collectionTypesEnabled = flags.collectionTypeTaskingFormEnabled?.organizations?.includes(organization.id) || false;
  async function prepRetaskFormDataAndRedirect () {
    setIsRetaskLoading(true);
    dispatch(clearNotice());
    const collectId = item.properties['capella:collect_id'];
    const originalLookAngle = item.properties['view:look_angle'];

    try {
      const tr = await dispatch(fetchTaskByCollectId(collectId));
      const { properties: { collectionType, collectConstraints } } = tr;

      // In order to increase the chance of a new tasking request from a collected image being accepted, we will need to expand out the Look Angle tolerate by 10 degrees (5 in either direction from the original collect's look tolerance). Therefore,
      // the Product Category will need to be set to Custom and the look angle range extended.
      const retaskCollectConstraints = { ...collectConstraints };
      const newTaskState = {
        geometry: tr.geometry,
        isRetask: true,
        origTaskingId: collectId,
        fromArchive: true
      };
      if (collectionTypesEnabled) {
        let collectionTypeDef = findCollectionType(collectionType);

        // Note: stripmap_[20|50|100] are available to both legacy and new spotlight pricing orgs
        // so below conditional is only relevant for spotlight
        if (!collectionTypeDef) {
          const isLegacySpotlightPricingOrg = organization.collectionTypePricingDisabled;

          let equivalentCollectionType;
          // map legacy spotlight collection types to new
          if (!isLegacySpotlightPricingOrg) {
            if (collectionType === 'sliding_spotlight_legacy') {
              equivalentCollectionType = 'spotlight_wide';
            } else {
              equivalentCollectionType = 'spotlight';
            }
          // map new to legacy spotlight collection types
          } else {
            if (collectionType === 'spotlight_wide') {
              equivalentCollectionType = 'sliding_spotlight_legacy';
            } else {
              equivalentCollectionType = 'spotlight_legacy';
            }
          }

          collectionTypeDef = findCollectionType(equivalentCollectionType);
        }

        const { name: typeDefName, accessParameters: { offNadirMax, offNadirMin, azimuthAngleMax, azimuthAngleMin, grazingAngleMax, grazingAngleMin } } = collectionTypeDef;

        retaskCollectConstraints.offNadirMax = Math.min(collectConstraints.offNadirMax + OFF_NADIR_PAD, offNadirMax.maximum);
        retaskCollectConstraints.offNadirMin = Math.max(collectConstraints.offNadirMin - OFF_NADIR_PAD, offNadirMin.minimum);

        retaskCollectConstraints.azimuthAngleMax = Math.min(collectConstraints.azimuthAngleMax + AZIMUTH_ANGLE_PAD, azimuthAngleMax.maximum);
        retaskCollectConstraints.azimuthAngleMin = Math.max(collectConstraints.azimuthAngleMin - AZIMUTH_ANGLE_PAD, azimuthAngleMin.minimum);

        retaskCollectConstraints.grazingAngleMax = Math.min(collectConstraints.grazingAngleMax + GRAZING_ANGLE_PAD, grazingAngleMax.maximum);
        retaskCollectConstraints.grazingAngleMin = Math.max(collectConstraints.grazingAngleMin - GRAZING_ANGLE_PAD, grazingAngleMin.minimum);

        newTaskState.preApproval = false;
        newTaskState.collectionType = typeDefName;
      } else {
        const { maxValue: maxLookAngle, minValue: minLookAngle } = getLookAngleRange(collectConstraints.collectMode, 'custom');
        retaskCollectConstraints.offNadirMax = Math.min(originalLookAngle + 5, maxLookAngle);
        retaskCollectConstraints.offNadirMin = Math.max(originalLookAngle - 5, minLookAngle);

        newTaskState.productCategory = 'custom';
      }
      newTaskState.collectConstraints = retaskCollectConstraints;

      updateTaskState(newTaskState);

      // NOTE: instead of using product geometry, override to initially tasked geometry from tasking request
      activeGeoJson.features[0].geometry = tr.geometry;
      const retaskFromArchiveLink = addParamsToUrl(routePathByName('tasksCreate'), {
        geoJson: JSON.stringify(activeGeoJson)
      });

      redirectTo(retaskFromArchiveLink);
    } catch (error) {
      const message = error?.response?.data?.error?.message;

      let detail = message;
      try {
        detail = JSON.parse(message).detail;
      } catch {}
      dispatch(triggerError(detail));
    } finally {
      setIsRetaskLoading(false);
    }
  }

  const centroid = activeItem && activeItem.geometry && getGeoJsonCenter(activeItem.geometry);
  const centroidString = centroid?.geometry?.coordinates.map(coord => coord.toFixed(5)).join(', ');

  return (
    <>
      <Panel className={`details ${isLoading ? 'details-is-loading' : ''}`}>
        <div className="details-content">
          <div className="details-content-header">
            <Badge label={collectionId} type='default' />
            {!isLoading && productAoiFeatureGroup && (
              <HelpfulButton
                id={`${itemId}/${collectionId}`}
                className="feature-zoom-btn"
                onClick={handleZoomInClick}
                tooltipId={`zoomHelp${itemId}-${collectionId}`}
                tooltipText="Zoom map to image boundary"
              >
                <MdZoomIn size={24} />
              </HelpfulButton>
            )}
          </div>
          <h1 className="details-title">
            <strong className="details-title-item" title={itemId}>
              {itemId}
            </strong>
            <CopyButton copyText={itemId} helperText="Copy STAC ID" />
          </h1>
          {activeItem && activeItem.id && (
            <>
              <div className="details-subheading">
                <p className="details-date">
                  {formatDate(activeItem.startTime, null, defaultInternationalFormat)} UTC -{' '}
                  {formatDate(activeItem.endTime, null, defaultInternationalFormat)} UTC
                </p>
                <div className="primary-info-section">
                  {centroidString && (
                    <div>
                      <strong>Image Centroid (Lng, Lat):</strong> <span>{centroidString}</span>
                      <CopyButton copyText={centroidString} helperText="Copy Centroid Coordinates" />
                    </div>
                  )}
                  {item?.collectId && (
                    <div>
                      <strong>Collect ID:</strong> <span>{item.collectId}</span>
                      <CopyButton copyText={item.collectId} helperText="Copy Collect ID" />
                    </div>
                  )}
                </div>
              </div>

              {!isLoading && (
                <>
                  <h2>Properties</h2>
                  <Accordion
                    className="item-properties-body"
                    multiselectable={true}
                    items={generateAccordionItems(itemProperties)}
                  />
                </>
              )}
            </>
          )}
        </div>
      </Panel>
      <Panel className="panel-clean details details-actions">
        <Button
          className="details-actions-action-add-to-cart"
          type="icon-before"
          full={true}
          disabled={isLoading || isInCart || isRetaskLoading}
          onClick={handleAddToCart}
        >
          {isInCart ? (
            <>
              <MdCheck /> Added to Cart
            </>
          ) : (
            <>
              <MdAddShoppingCart /> Add To Cart
            </>
          )}
        </Button>
        {createTaskPath && !orgIsArchSubOnly && isTaskingAllowed && (
          <Button
            className="details-actions-action-tasking-request"
            onClick={prepRetaskFormDataAndRedirect}
            full={true}
            type="icon-before"
            disabled={isLoading || isRetaskLoading || (collectionTypesEnabled && collectionTypesIsLoading)}
          >
            {
              isRetaskLoading
                ? <span><FaSpinner className="icon-spin" id="loading" /></span>
                : <span><MdRepeat /> Retask</span>
            }

          </Button>
        )}
      </Panel>
    </>
  );
};

SidebarDetails.propTypes = {
  collectionId: PropTypes.string,
  itemId: PropTypes.string,
  toggleLayer: PropTypes.func,
  layers: PropTypes.object,
  location: PropTypes.object
};

export default SidebarDetails;
