export const MAP_COLOR_BLUE = '#2196F3';
export const MAP_COLOR_ACCENTBLUE = '#00A3FF';
export const MAP_COLOR_RED = '#F44336';
export const MAP_COLOR_YELLOW = '#FFFF23';
export const MAP_COLOR_GREEN = '#05ED1C';
export const MAP_COLOR_PURPLE = '#8000FF';
export const MAP_COLOR_ORANGE = '#ED9105';
export const MAP_COLOR_TEAL = '#00E5B2';
export const MAP_COLOR_MAGENTA = '#E159C8';
export const MAP_COLOR_LIGHTBLUE = '#00B4D9';
