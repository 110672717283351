import React from 'react';
import PropTypes from 'prop-types';
import { device } from 'fogg/lib';
import {
  ROUTE_TASKS,
  ROUTE_TASKS_DETAILS
} from 'commonLib/src/data/route-names';
import { orgIsArchiveSubOnly } from 'commonLib';
import {
  useFetchTask,
  useTask,
  useTasks,
  useFetchTaskCollects,
  useFetchTaskTiles,
  useUser,
  useConflictedTask
} from 'hooks';
import { navigateTo, routePathByName } from 'lib/routes';
import { taskIdIsValid } from 'lib/data';
import { taskStatusById } from 'lib/tasks';
import TemplateTask from 'templates/TemplateTask';

const { isDomAvailable } = device;

const tasksPath = routePathByName('tasks');

const TaskPage = ({ taskId, conflictId }) => {
  const { actions: taskActions = {} } = useTasks();
  const conflictedTask = useConflictedTask();

  const {
    updateTaskStatusById: updateTaskStatusByIdAction = {},
    fetchTaskByTaskId: fetchTaskActions = {}
  } = taskActions;
  const { isLoading: isUpdateTaskStatusByIdLoading } =
    updateTaskStatusByIdAction;
  const { isLoading: isFetchTaskLoading } = fetchTaskActions;

  const task = useTask(taskId) || {
    id: taskId
  };

  const { user: { organization = {} } = {}, isLoading: userIsLoading } = useUser();

  const {
    isLoading,
    fetchConflictingTasksActions: { isLoading: isConflictingTasksLoading = false } = {},
    conflictingTasks = []
  } = useFetchTask(taskId, true, {}, conflictId);
  const { isLoading: taskCollectsIsLoading } = useFetchTaskCollects({ taskId });
  const { isLoading: taskTilesIsLoading } = useFetchTaskTiles({ taskId });

  // If we don't have a valid ID, navigate back to our tasks list page

  if (!taskIdIsValid(taskId)) {
    if (isDomAvailable()) navigateTo(tasksPath);
    return null;
  }

  const taskState = {
    isLoading:
      isLoading ||
      isFetchTaskLoading ||
      isUpdateTaskStatusByIdLoading ||
      taskCollectsIsLoading ||
      taskTilesIsLoading ||
      userIsLoading,
    isConflictingTasksLoading
  };

  const taskContract = organization.contracts?.find(({ id }) => id === task.contractId);
  const isMultiContract = organization.contracts?.length > 1;
  const orgIsArchSubOnly = orgIsArchiveSubOnly(organization);

  const breadcrumbs = [
    {
      label: 'Tasks',
      to: routePathByName(ROUTE_TASKS)
    }
  ];

  if (conflictId) {
    const conflictedTaskStatus = taskStatusById(conflictedTask.status, { label: 'Conflicted', id: 'conflicted' });
    breadcrumbs.push({
      label: `${conflictedTaskStatus.label} Task`,
      to: routePathByName(ROUTE_TASKS_DETAILS, {
        wildcard: [conflictId]
      })
    });
  }

  return (
    <TemplateTask
      task={task}
      conflictingTasks={conflictingTasks}
      contract={taskContract}
      displayMultiContract={isMultiContract}
      orgIsArchSubOnly={orgIsArchSubOnly}
      state={taskState}
      breadcrumbs={breadcrumbs}
      conflictId={conflictId}
    />
  );
};

TaskPage.propTypes = {
  taskId: PropTypes.string,
  conflictId: PropTypes.string
};

export default TaskPage;
