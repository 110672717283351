import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormRow,
  Form,
  Button,
  FormInput,
  InputButtonList,
  InputButton
} from 'fogg/ui';
import ReactTooltip from 'react-tooltip';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { fieldByName } from '../lib/fields';
import { sortByKey } from '../lib/util';
import { routePathByName } from '../lib/routes';
import { ROUTE_ADMIN_ORGS_DETAILS } from '../data/route-names';
import {
  ACCESS_ROLES,
  USER_ROLE,
  ADMIN_ROLE,
  ORG_MANAGER_ROLE
} from '../data/roles';

import ReadOnlyField from '../components/FormReadOnlyField';
import OptionsPanel from '../components/OptionsPanel';
import {isEnabled, userHasTaskingAccess} from '../lib/role-util';

const SELECT_SINGLE_VALUE_TYPES = [
  'organizationId',
  'sendAllTransactionEmailNotifications, sendSupplementaryEmailNotifications'
];

const UserProfile = ({
  user = {},
  organizations = [],
  updateUser,
  accessType = USER_ROLE
}) => {
  const accessFull = accessType === ADMIN_ROLE;
  const hasOrgManagerAccess = !!(
    Array.isArray(user.roles) &&
    user.roles.length !== 0 &&
    user.roles.includes(ORG_MANAGER_ROLE)
  );

  const flags = useFlags();
  const hasTaskingAccess = userHasTaskingAccess(user.roles, flags);

  const [formState, updateFormState] = useState({
    key: generateFormKey(),
    isEditable: false
  });
  const [
    supplementaryEmailNotifications,
    updateSupplementaryEmailNotifications
  ] = useState(user?.sendSupplementaryEmailNotifications || false);
  const [
    allTransactionEmailNotifications,
    updateAllTransactionEmailNotifications
  ] = useState(user?.sendAllTransactionEmailNotifications || false);

  const shouldDisplayEmailPreferences = hasOrgManagerAccess || hasTaskingAccess;

  const { isEditable } = formState;

  const { organization: org = {}, roles = [] } = user;
  const {
    name: organizationName,
    id: organizationId,
    parentOrganization = {}
  } = org || {};

  let organizationOptions = organizations.map(({ id, name }) => {
    return {
      label: name,
      value: id
    };
  });

  organizationOptions = sortByKey(organizationOptions, 'label');

  const defaultOrganizationSelection = organizationOptions.find(
    ({ value }) => organizationId === value
  );
  const defaultOrganizationKey =
    defaultOrganizationSelection && defaultOrganizationSelection.value;

  const [selectedOrganization, updateSelectedOrganization] = useState(
    defaultOrganizationSelection
  );

  useEffect(() => {
    updateSelectedOrganization(defaultOrganizationSelection);
  }, [defaultOrganizationKey]);

  // The selected roles are determined by the user's access. We also need to check if
  // the roles change, and if they do, update the available options

  const [selectedRoles, updateSelectedRoles] = useState(roles || []);
  const userRolesKey = Array.isArray(roles) && roles.join('/');

  useEffect(() => {
    updateSelectedRoles(roles || []);
  }, [userRolesKey]);

  useEffect(() => {
    const { sendSupplementaryEmailNotifications = false } = user;
    updateSupplementaryEmailNotifications(sendSupplementaryEmailNotifications);
  }, [user.sendSupplementaryEmailNotifications]);

  useEffect(() => {
    const { sendAllTransactionEmailNotifications = false } = user;
    updateAllTransactionEmailNotifications(
      sendAllTransactionEmailNotifications
    );
  }, [user.sendAllTransactionEmailNotifications]);

  // Determine which roles we should be showing in the UI based on
  // if the current user has the rights to manage that access and
  // if it's currently selected

  const visibleRoleOptions = ACCESS_ROLES.filter((role) => {
    const { manageAccess = [], globalEnabledFlag = {} } = role;
    let enabled = true;
    if (globalEnabledFlag) {
      enabled = flags[globalEnabledFlag.id] === globalEnabledFlag.value;
    }

    return enabled && manageAccess.includes(accessType);
  }).map((role) => {
    const { id, label, updateDisabled = false } = role;
    return {
      label,
      value: id,
      isChecked: selectedRoles.includes(id),
      disabled: updateDisabled
    };
  });

  const validationRules = {
    givenName: {
      ...fieldByName('givenName'),
      required: true
    },
    familyName: {
      ...fieldByName('familyName'),
      required: true
    }
  };
  /**
   * handleSubmit
   */
  function handleSubmit (e, fields = {}) {
    // Normalize array based select fields to their selected value
    const data = {};

    // Remove "user" role since it isn't editible in checkboxes/UI
    const userAccessRoles = ACCESS_ROLES.filter((role) => isEnabled(role, flags) && role.id !== USER_ROLE);
    const checkedRoles = selectedRoles.filter((role) => role !== USER_ROLE);
    // Pass true/false to API based on roles checkboxes
    userAccessRoles.forEach((role) => {
      if (checkedRoles.length) {
        fields[role.apiKey] = {
          value: selectedRoles.includes(role.id)
        };
      } else {
        fields[role.apiKey] = {
          // If no checked values, every role is "false"
          value: false
        };
      }
    });

    SELECT_SINGLE_VALUE_TYPES.forEach((type) => {
      const value = fields[type] && fields[type].value;

      if (!fields[type]) return;

      if (Array.isArray(value) && value.length > 0) {
        fields[type].value = value[0];
      } else {
        fields[type].value = null;
      }
    });

    Object.keys(fields).forEach((key) => {
      data[key] = fields[key].value;
    });

    // The FormInput select isn't passing the value into the submit handler for
    // some reason so this makes sure the org Id is set

    if (selectedOrganization) {
      data.organizationId = selectedOrganization.value;
    }
    if (typeof updateUser === 'function') {
      // Pass values for Email Notification checkboxes from local state (controlled components)
      data.sendSupplementaryEmailNotifications =
        supplementaryEmailNotifications;
      data.sendAllTransactionEmailNotifications =
        allTransactionEmailNotifications;

      updateUser(data);
    }

    makeFormReadOnly();
  }

  /**
   * handleCancel
   */

  function handleCancel () {
    updateFormState((prev) => {
      return {
        ...prev,
        isEditable: false,
        key: generateFormKey()
      };
    });
    updateSelectedRoles(roles);
    updateSelectedOrganization(defaultOrganizationSelection);
  }

  /**
   * handleOnRolesChange
   */

  function handleOnRolesChange (e, selectedOptions) {
    let roles = selectedOptions.filter(({ isChecked } = {}) => isChecked);

    roles = roles.map(({ value } = {}) => value);

    updateSelectedRoles(roles);
  }

  /**
   * handleSupplementaryEmailNotifications
   */

  function handleSupplementaryEmailNotifications ({ currentTarget = {} }) {
    const { checked } = currentTarget;
    updateSupplementaryEmailNotifications(checked);
  }

  /**
   * handleAllTransactionEmails
   */

  function handleAllTransactionEmails ({ currentTarget = {} }) {
    const { checked } = currentTarget;
    updateAllTransactionEmailNotifications(checked);
  }

  /**
   * makeFormEditable
   */

  function makeFormEditable (e) {
    if (e) e.preventDefault();
    updateFormState((prev) => {
      return {
        ...prev,
        isEditable: true
      };
    });
  }

  /**
   * makeFormReadOnly
   */

  function makeFormReadOnly (e) {
    if (e) e.preventDefault();
    updateFormState((prev) => {
      return {
        ...prev,
        isEditable: false
      };
    });
  }

  /**
   * handleOnOrganizationChange
   */

  function handleOnOrganizationChange (selection) {
    updateSelectedOrganization(selection);
  }

  return (
    <div className="user-profile">
      <Form key={formState.key} onSubmit={handleSubmit} rules={validationRules}>
        <ul>
          <li>
            <FormRow>
              <ReadOnlyField label="Email" value={user.email} />
            </FormRow>
          </li>
          <li>
            <FormRow>
              {!accessFull && (
                <ReadOnlyField
                  label="Organization"
                  value={organizationName || '-'}
                />
              )}
              {accessFull && (
                <FormInput
                  id="organizationId"
                  label="Organization"
                  type="select"
                  options={organizationOptions}
                  disabled={!isEditable}
                  value={selectedOrganization}
                  onChange={handleOnOrganizationChange}
                />
              )}
            </FormRow>
          </li>
          {parentOrganization?.name && (
            <li>
              <FormRow>
                <ReadOnlyField label="Reseller Organization" value={
                  user.isAdmin()
                    ? <a
                      href={routePathByName(ROUTE_ADMIN_ORGS_DETAILS, {
                        wildcard: [parentOrganization.id]
                      })}
                      data-testid='reseller-org-link'
                    >
                      {parentOrganization.name}
                    </a>
                    : parentOrganization.name
                } />
              </FormRow>
            </li>
          )}
          <li>
            <FormRow>
              <FormInput
                id="givenName"
                label="First Name"
                defaultValue={user.givenName}
                disabled={!isEditable}
              />
            </FormRow>
          </li>
          <li>
            <FormRow>
              <FormInput
                id="familyName"
                label="Last Name"
                defaultValue={user.familyName}
                disabled={!isEditable}
              />
            </FormRow>
          </li>
          {visibleRoleOptions.length >= 1 && (
            <>
              <li>
                <OptionsPanel label="Roles" id="roles">
                  <FormRow>
                    <InputButtonList
                      name="roles"
                      type="checkbox"
                      options={visibleRoleOptions}
                      onChange={handleOnRolesChange}
                      controlChecked={true}
                      disabled={!isEditable}
                    />
                  </FormRow>
                </OptionsPanel>
              </li>
            </>
          )}

          {shouldDisplayEmailPreferences && (
            <li>
              <label className="form-label" htmlFor="sendEmailNotifications">
              Email Preferences{' '}
              </label>
              {hasTaskingAccess && (
                <span>
                  <ReactTooltip
                    id="supplementaryEmailNotifications"
                    place="top"
                    effect="solid"
                    type="dark"
                    multiline={true}
                    className="customTip"
                  >
              If checked, in addition to being notified by email of final task
              status you will receive emails for all task status updates for
              your account activity.
                  </ReactTooltip>
                  <FormRow>
                    <InputButton
                      id="sendSupplementaryEmailNotifications"
                      label="Receive additional emails for user activity"
                      type="checkbox"
                      controlChecked={true}
                      isChecked={supplementaryEmailNotifications || false}
                      disabled={!isEditable}
                      onChange={handleSupplementaryEmailNotifications}
                    />
                    <span
                      className="general-tool-tip"
                      data-tip
                      data-for="supplementaryEmailNotifications"
                    >
                ?
                    </span>
                  </FormRow>
                </span>)}
              {hasOrgManagerAccess && (
                <span>
                  <ReactTooltip
                    id="allTransactionEmailNotifications"
                    place="top"
                    effect="solid"
                    type="dark"
                    multiline={true}
                    className="customTip"
                  >
                  If checked, you will receive emails for all task status
                  updates for all account activity across the organization.
                  </ReactTooltip>
                  <FormRow>
                    <InputButton
                      id="sendAllTransactionEmailNotifications"
                      label="Receive emails for all organization activity"
                      type="checkbox"
                      isChecked={allTransactionEmailNotifications}
                      controlChecked={true}
                      disabled={!isEditable}
                      onChange={handleAllTransactionEmails}
                    />
                    <span
                      className="general-tool-tip"
                      data-tip
                      data-for="allTransactionEmailNotifications"
                    >
                    ?
                    </span>
                  </FormRow>
                </span>
              )}
            </li>
          )}
        </ul>

        <FormRow className="form-row-actions">
          {!isEditable && (
            <Button className="action-edit" onClick={makeFormEditable}>
              Edit
            </Button>
          )}
          {isEditable && (
            <>
              <Button className="action-save">Save</Button>
              <Button className="action-cancel" onClick={handleCancel}>
                Cancel
              </Button>
            </>
          )}
        </FormRow>
      </Form>
    </div>
  );
};

UserProfile.propTypes = {
  user: PropTypes.shape({
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    sendSupplementaryEmailNotifications: PropTypes.bool,
    sendAllTransactionEmailNotifications: PropTypes.bool,
    organization: PropTypes.shape({
      name: PropTypes.string
    }),
    plan: PropTypes.string,
    roles: PropTypes.array
  }),
  organizations: PropTypes.array,
  updateUser: PropTypes.func,
  accessType: PropTypes.string
};

export default UserProfile;

function generateFormKey () {
  return `form-${Date.now()}`;
}
