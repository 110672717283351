import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ItemList } from 'fogg/ui';
import { location } from 'fogg/lib';
import {
  FaMapMarkerAlt,
  FaSquareFull,
  FaChevronUp,
  FaChevronDown
} from 'react-icons/fa';
import rocketMarker from '../assets/images/rocket.svg';

import defenseIntelligence from '../assets/images/industry-icons/defense-intelligence-solid.svg';
import disasterResponse from '../assets/images/industry-icons/disaster-response-solid.svg';
import energy from '../assets/images/industry-icons/energy-solid.svg';
import financeAndCommodities from '../assets/images/industry-icons/finance-and-commodities-solid.svg';
import insuranceRiskManagement from '../assets/images/industry-icons/insurance-risk-management-solid.svg';
import maritimeDomainAwareness from '../assets/images/industry-icons/maritime-domain-awareness-solid.svg';
import publicInfrastructureMonitoring from '../assets/images/industry-icons/public-infrastructure-monitoring-solid.svg';

import { routePathByName } from 'lib/routes';

import IconPolygon from 'components/IconPolygon';

const { addParamsToUrl } = location;

const subList = [
  {
    label: 'Defense & Intelligence',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=0eef5343-1773-4e2d-8322-6998458998bb',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[46.216, 51.481]}}]}',
      zoom: 13,
      forceSearch: true
    }),
    id: 'defenseIntelligence',
    subIcon: defenseIntelligence
  },
  {
    label: 'Energy & Natural Resources',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=ff716a2f-0477-427c-a1f8-f14c7f54d3e8',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[-6.253, 37.443]}}]}',
      zoom: 13,
      forceSearch: true
    }),
    id: 'energyNaturalResources',
    subIcon: energy
  },
  {
    label: 'Public Infrastructure Monitoring',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=11c5541c-5334-41f4-b36e-5141f03961fe',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[3.280, 6.470]}}]}',
      zoom: 13,
      forceSearch: true
    }),
    id: 'publicInfrastructureMonitoring',
    subIcon: publicInfrastructureMonitoring
  },
  {
    label: 'Disaster Response',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=dbc11a56-3d23-46ac-ab40-003876c9bb57',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[-61.185, 13.335]}}]}',
      zoom: 13,
      forceSearch: true
    }),
    id: 'humanitarianAssistanceDisasterRelief',
    subIcon: disasterResponse
  },
  {
    label: 'Insurance & Risk Management',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=a987a2e2-ec83-4197-9f84-af94cb7cb999',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[150.818, -33.596]}}]}',
      zoom: 13,
      forceSearch: true
    }),
    id: 'insuranceRiskManagement',
    subIcon: insuranceRiskManagement
  },
  {
    label: 'Maritime Domain Awareness',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=4750a736-34fb-4432-b16e-551dfe9d3f38',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[112.701, -7.2]}}]}',
      zoom: 13,
      forceSearch: true
    }),
    id: 'maritimeDomainAwareness',
    subIcon: maritimeDomainAwareness
  },
  {
    label: 'Finance & Commodities',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=5cc241c0-127e-437a-8e04-ffd1662a6b83',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[121.668, 31.330]}}]}',
      zoom: 13,
      forceSearch: true
    }),
    id: 'financeCommodities',
    subIcon: financeAndCommodities
  }
];

const SEARCH_EXPLORE_ITEMS = [
  {
    label: 'Spot Data Sample',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=5b62510c-0bc8-4b3a-be66-e58b89e526f2',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[-119.150715,35.063244]}}]}',
      zoom: 13,
      forceSearch: true
    }),
    id: 'spotDataSample'
  },
  {
    label: 'Site Data Sample',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=82c284c1-7444-4452-bed0-10a59146c763',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[-96.87086,32.680431]}}]}',
      zoom: 13,
      forceSearch: true
    }),
    id: 'siteDataSample'
  },
  {
    label: 'Strip Data Sample',
    to: addParamsToUrl(routePathByName('search'), {
      properties: 'collect_id=fb89d836-5a8e-403e-8fee-f4a4aa4b3b6c',
      geoJson:
        '{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Point","coordinates":[-115.54829,32.990569]}}]}',
      zoom: 12,
      forceSearch: true
    }),
    id: 'stripDataSample'
  },
  {
    label: 'Market Samples',
    id: 'marketSamples',
    hasChildren: true,
    children: subList
  }
];

const ExploreLabel = () => {
  return <>Explore</>;
};

const ExploreContent = () => {
  return <ItemList items={SEARCH_EXPLORE_ITEMS} subListIcon={rocketMarker} />;
};

const DEFAULT_TABS = [
  {
    id: 'explore',
    Label: ExploreLabel,
    Content: ExploreContent
  }
];

const Discover = ({
  tabs = DEFAULT_TABS,
  onMarkerClick,
  onRectangleClick,
  onPolygonClick,
  searchType
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  function handleOnTabClick () {
    if (isCollapsed) {
      setIsCollapsed(false);
    }
  }

  const hasTabs = Array.isArray(tabs) && tabs.length > 0;
  const hasContentTabs = !!(
    hasTabs && tabs.find(({ Content } = {}) => !!Content)
  );

  return (
    <div
      className="discover"
      data-is-collapsed={isCollapsed}
      data-has-content-tabs={hasContentTabs}
    >
      <div className="discover-panel">
        <div className="discover-header">
          {hasTabs && (
            <ul className="discover-navigation">
              {tabs.map(({ id, Label, onClick } = {}) => {
                function handleOnClick (e) {
                  handleOnTabClick(e);
                  if (typeof onClick === 'function') {
                    onClick();
                  }
                }
                return (
                  <li key={id}>
                    <button data-active={true} onClick={handleOnClick}>
                      <Label />
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
          {searchType === 'default' && (
            <ul className="discover-actions">
              <li>
                <button
                  className="discover-action-marker"
                  onClick={onMarkerClick}
                >
                  <span className="visually-hidden">Draw a marker</span>
                  <FaMapMarkerAlt className="icon-marker" />
                </button>
              </li>
              <li>
                <button
                  className="discover-action-rectangle"
                  onClick={onRectangleClick}
                >
                  <span className="visually-hidden">Draw a rectangle</span>
                  <FaSquareFull className="icon-square" />
                </button>
              </li>
              <li>
                <button
                  className="discover-action-polygon"
                  onClick={onPolygonClick}
                >
                  <span className="visually-hidden">Draw a polygon</span>
                  <IconPolygon className="icon-polygon" />
                </button>
              </li>
            </ul>
          )}
        </div>
        {hasContentTabs && (
          <div className="discover-content">
            {tabs.map(({ id, Content } = {}) =>
              Content ? <Content key={id} /> : null
            )}
          </div>
        )}
      </div>
      <div className="discover-after">
        <ul>
          {hasContentTabs && (
            <li className="discover-after-collapse">
              {!isCollapsed && (
                <button onClick={() => setIsCollapsed(true)}>
                  <span className="visually-hidden">Collapse Menu</span>
                  <FaChevronUp className="icon-chevron-up" />
                </button>
              )}
              {isCollapsed && (
                <button onClick={() => setIsCollapsed(false)}>
                  <span className="visually-hidden">Show Menu</span>
                  <FaChevronDown className="icon-chevron-down" />
                </button>
              )}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

Discover.propTypes = {
  tabs: PropTypes.array,
  onMarkerClick: PropTypes.func,
  onRectangleClick: PropTypes.func,
  onPolygonClick: PropTypes.func,
  searchType: PropTypes.string
};

export default Discover;
