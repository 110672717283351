import { constructRequestActionManager } from 'commonLib/src/lib/actions';
import { routePathByName } from 'lib/routes';
import { COLLECTION_TYPES_SCOPE } from 'data/scopes';

/**
 * Updates the collection types store's status
 */
export function setCollectionTypesActionState (status) {
  return {
    type: 'UPDATE_COLLECTION_TYPES_ACTION_STATE',
    data: status
  };
}

const createDispatchRequestAction = constructRequestActionManager(
  setCollectionTypesActionState
);

/**
 * fetchCollectionTypes
 * @description Fetches all permitted collection types for a user's organization
 */

export const fetchCollectionTypes = () => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchCollectionTypes',
      scope: COLLECTION_TYPES_SCOPE,
      request: {
        url: routePathByName('apiCollectionTypes'),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: { results } = {} } = request || {};

    dispatch(updateCollectionTypes(results));

    return results;
  };
};

/**
 * updateCollectionTypes
 * @description Set the collection types results
 */

export const updateCollectionTypes = (data) => {
  return {
    type: 'UPDATE_COLLECTION_TYPES',
    data
  };
};
