import clone from 'clone';
import deepmerge from 'deepmerge';
import {
  ADD_REPEAT_REQUEST,
  CLEAR_NEW_REPEAT_REQUEST,
  NEW_REPEAT_REQUEST,
  UPDATE_REPEAT_REQUEST,
  UPDATE_REPEAT_REQUESTS,
  UPDATE_REPEAT_REQUESTS_PAGED,
  UPDATE_REPEAT_REQUESTS_ACTION_STATE,
  UPDATE_REPEAT_REQUESTS_COLUMNS
} from 'data/action-types';
import { REPEAT_REQUEST_ID } from 'data/property-names';
import {
  updateRepeatRequestData,
  updateRepeatRequestsData
} from 'lib/repeatRequests';
import { REPEAT_REQUESTS_TABLE_COLUMNS } from 'lib/tasks';

const defaultState = {
  repeatRequests: [],
  repeatRequestChildTasks: [],
  actions: {},
  new: {},
  currentPage: 1,
  totalPages: 1,
  columns: REPEAT_REQUESTS_TABLE_COLUMNS
};

const repeatRequests = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);
  let repeatRequestId;

  if (workingData && workingData.properties) {
    repeatRequestId = workingData.properties[REPEAT_REQUEST_ID];
  }

  const {
    repeatRequests: workingStateRepeatRequests = [],
    new: workingStateNewRepeatRequest
  } = workingState;

  switch (type) {
    case UPDATE_REPEAT_REQUEST:
      if (typeof workingData !== 'object' || Array.isArray(workingData)) {
        throw new Error(`Invalid data passed to ${UPDATE_REPEAT_REQUEST}`);
      }

      if (repeatRequestId === NEW_REPEAT_REQUEST) {
        return {
          ...workingState,
          new: updateRepeatRequestData(
            workingStateNewRepeatRequest,
            workingData
          )
        };
      } else {
        return {
          ...workingState,
          repeatRequests: updateRepeatRequestsData(workingStateRepeatRequests, [
            workingData
          ])
        };
      }
    // FIXME: Find a way to consolidate with UPDATE_REPEAT_REQUESTS_PAGED
    case UPDATE_REPEAT_REQUESTS:
      if (!Array.isArray(workingData)) {
        throw new Error(`Invalid data passed to ${UPDATE_REPEAT_REQUESTS}`);
      }
      return {
        ...workingState,
        repeatRequests: updateRepeatRequestsData(
          workingStateRepeatRequests,
          workingData
        )
      };
    case UPDATE_REPEAT_REQUESTS_PAGED:
      if (!Array.isArray(workingData.results)) {
        throw new Error(`Invalid data passed to ${UPDATE_REPEAT_REQUESTS_PAGED}`);
      }
      return {
        ...workingState,
        repeatRequests: workingData.results,
        currentPage: workingData.currentPage,
        totalPages: workingData.totalPages
      };
    case UPDATE_REPEAT_REQUESTS_COLUMNS:
      return {
        ...workingState,
        columns: workingData
      };
    case ADD_REPEAT_REQUEST:
      if (repeatRequestId === NEW_REPEAT_REQUEST) {
        return {
          ...workingState,
          new: workingData
        };
      } else {
        return {
          ...workingState,
          repeatRequests: [...workingState.repeatRequests, workingData]
        };
      }
    case UPDATE_REPEAT_REQUESTS_ACTION_STATE:
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    case CLEAR_NEW_REPEAT_REQUEST:
      return {
        ...workingState,
        new: {}
      };
    default:
      return workingState;
  }
};

export default repeatRequests;
