import { useEffect, useState } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useOrder, useOrders } from 'hooks';

import { logError } from 'lib/logger';
import { fetchOrderById, triggerNotice, fetchTaskByTaskId } from 'state/actions';

export default function useFetchOrder (orderId, forceNew = false) {
  const dispatch = useDispatch();

  const { actions = {} } = useOrders();
  const order = useOrder(orderId);
  const { id } = order || {};

  const [orderCost, setOrderCost] = useState(null);
  const [orderItems, setOrderItems] = useState(null);

  async function fetchTask (id) {
    if (!id) return;
    const task = await dispatch(fetchTaskByTaskId(id));
    return task;
  }

  useEffect(() => {
    if (!orderId || (id && !forceNew)) return;
    dispatch(fetchOrderById(orderId)).then(({ costBreakdown, items }) => {
      setOrderCost(costBreakdown);
      // If items have a taskingRequestId, fetch that task
      if (items?.length) {
        items.map((item, i) => {
          if (item.feature?.taskingRequestId) {
            fetchTask(item.feature.taskingRequestId).then(({ properties }) => {
              // Include that task object with the Item
              items[i].task = properties;
            });
          }
        });
      }
      setOrderItems(items);
    }).catch((error) => {
      logError(error.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: 'Uh oh, something went wrong. Try refreshing the page.'
        })
      );
    });
  }, [orderId, id, forceNew]); // eslint-disable-line

  // Cost Breakdown not returned from /orders, so manually including it when available
  // Also need to pass in the latest items with any associated tasks fetched above
  const orderWithLatestMetadata = {
    ...order,
    costBreakdown: orderCost,
    items: orderItems
  };

  return {
    ...actions.fetchOrderById,
    order: orderWithLatestMetadata
  };
}
