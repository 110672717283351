import { useDispatch } from 'redux-react-hook';

import { useLocation } from 'hooks';
import { NEW_ENTRY_PREFIX } from 'hooks/useCreateNewEntry';
import { isEmptyObject } from 'lib/util';

const VALID_CREATE_PATHS = ['view', 'create', 'review', 'details'];

export default function useClearNewEntries ({ entries = [] }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const clearedIds = [];

  if (typeof pathname !== 'string') return {};

  entries.forEach(
    ({ entryHook, entryType = '', entryPathBase, entryClearAction }) => {
      const newEntryId = `${NEW_ENTRY_PREFIX}${entryType.toUpperCase()}`;
      const newEntry = entryHook(newEntryId) || {};
      const pathChunk = pathname
        .replace(`/${entryPathBase}/`, '')
        .split('/')[0];
      if (!isEmptyObject(newEntry) && !VALID_CREATE_PATHS.includes(pathChunk)) {
        dispatch(entryClearAction());
        clearedIds.push(newEntryId);
      }
    }
  );

  return {
    clearedIds
  };
}
