import { useEffect, useRef } from 'react';
import { useDispatch } from 'redux-react-hook';

import {
  useFetchUser,
  useTask,
  useActiveItem,
  useClearNewEntries,
  useLocation,
  useUi
} from 'hooks';
import { useTokenCookie } from 'commonLib/src/hooks';
import {
  clearNewTask,
  clearActiveItem,
  clearNotice
} from 'state/actions';

export default function useBootstrap ({ location, forceNew }) {
  const dispatch = useDispatch();
  const { token } = useTokenCookie();
  const { notice } = useUi();
  const { pathname: noticePathname } = notice || {};

  const {
    pathname,
    history = {},
    queryParams = {},
    prevPath
  } = useLocation(location);
  const { next } = queryParams || {};

  const { state: historyState = {} } = history;
  const { isRedirect } = historyState || {};

  // if we go from ex: /account/ > /account/profile/, it is a redirect but it is in the parent tree and not related to the noice
  const isRedirectFromParent = !!(isRedirect && pathname.includes(prevPath));

  // Attempt to fetch the user profile

  useFetchUser({ next, token, prevPath, forceNew });

  // Clear any instances of NEW_ something to prevent stale create paths
  // Note: entryHook needs to be defined within the function scope to allow
  // the hooks to work properly

  useClearNewEntries({
    entries: [
      {
        entryHook: useTask,
        entryClearAction: clearNewTask,
        entryPathBase: 'tasks',
        entryType: 'task'
      },
      {
        entryHook: useActiveItem,
        entryClearAction: clearActiveItem,
        entryPathBase: 'search',
        entryType: 'item'
      }
    ]
  });

  // If the notice was triggered on a previous page that was NOT a redirect
  // clear it from the UI

  const clearTimerRef = useRef();

  const isDifferentPath = noticePathname !== pathname;
  const isClearableRedirect = !isRedirect || isRedirectFromParent;

  let clearTimeoutTime = 10000;

  if (isDifferentPath && isClearableRedirect) {
    clearTimeoutTime = 0;
  }

  useEffect(() => {
    // If we've already cleared the notice and we see
    // a timeout still set up, clear the timeout

    if (!notice && clearTimerRef.current) {
      clearTimeout(clearTimerRef.current);
    }

    // If we don't have a notice there's nothing to clear.
    // If we have a timer, we dont want to set another
    // Also if persist = true don't clear notice

    if (!notice || clearTimerRef.current || notice?.persist) {
      return;
    }

    clearTimerRef.current = setTimeout(() => {
      dispatch(clearNotice());
    }, clearTimeoutTime);
  }, [notice]);

  return { token };
}
