import clone from 'clone';
import deepmerge from 'deepmerge';
import {
  UPDATE_REPEAT_REQUEST_CHILD_TASKS,
  UPDATE_REPEAT_REQUEST_CHILD_TASKS_ACTION_STATE
} from 'data/action-types';

const defaultState = {
  tasks: [],
  actions: {},
  new: {}
};

const tasks = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);

  switch (type) {
    case UPDATE_REPEAT_REQUEST_CHILD_TASKS_ACTION_STATE:
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    case UPDATE_REPEAT_REQUEST_CHILD_TASKS:
      return {
        ...workingState,
        repeatRequestChildTasks: workingData
      };
    default:
      return workingState;
  }
};

export default tasks;
