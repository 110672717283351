import BaseTask from 'models/baseTask';
import { geometryToGeoJson } from 'lib/map';
import { formatCurrencyToNumber } from 'lib/currency';
import { REPEAT_REQUEST_ID } from 'data/property-names';
import { TaskRequestTypes } from 'data/task-request-types';
import { RepeatRequestEndTypes } from 'data/repeat-request-end-types';

/**
 * class object for repeat request, extends BaseTask, and includes it's own set of repetition properties
 */
class RepeatRequest extends BaseTask {
  static mapUsersToRepeatRequest (repeatRequests, users) {
    return BaseTask.mapUsersToTasks(repeatRequests, users, RepeatRequest);
  }

  constructor (data = {}) {
    super(data);
    this._data = {
      ...this._data,
      repetitionProperties: {}
    };
    this._repeatRequestEndType = RepeatRequestEndTypes.INDEFINITE;
  }

  updateInstanceProperties () {
    super.updateInstanceProperties();
    this.id = this._data.properties[REPEAT_REQUEST_ID];
    this.name = this._data.properties.repeatrequestName;
    this.costPerImageEstimate = this._data.properties.costPerImageEstimate;
    this.description = this._data.properties.repeatrequestDescription;
    this.repeatrequestName = this._data.properties.repeatrequestName;
    this.repeatrequestDescription =
      this._data.properties.repeatrequestDescription;
    this.repeatrequestType = this._data.properties.repeatrequestType;
    this.repetitionProperties = this._data.properties.repetitionProperties;
    this.taskRequestType = TaskRequestTypes.REPEAT;
    this.childTasks = this._data.childTasks || [];
    this.windowOpen = this.repetitionProperties.repeatStart;
    this.windowClose = this.repetitionProperties.repeatEnd;
  }

  geoJson () {
    return this.geometry && geometryToGeoJson(this.geometry);
  }

  /**
   * gets repetition end type based on repeatEnd or repetitionCount values
   * @returns {string} - type of end, "count", "date", or "indefinite"
   */
  get repeatRequestEndType () {
    const { repeatEnd, repetitionCount } = this.repetitionProperties;
    this._repeatRequestEndType = RepeatRequestEndTypes.INDEFINITE;

    if (repetitionCount) {
      this._repeatRequestEndType = RepeatRequestEndTypes.COUNT;
    } else if (repeatEnd) {
      this._repeatRequestEndType = RepeatRequestEndTypes.DATE;
    }

    return this._repeatRequestEndType;
  }

  /**
   * calculates duration from repeatStart to repeatEnd properties
   * @param {boolean} [asMonths=false] - if true, changes the output to number of months
   * @returns {number} - number in milliseconds or months, or Infinity if there is no end
   */
  repeatDuration = function (asMonths = false) {
    const { repeatEnd, repeatStart } = this.repetitionProperties;

    const repeatEndDate = repeatEnd ? new Date(repeatEnd).getTime() : Infinity;
    const repeatStartDate = new Date(repeatStart).getTime();
    const repeatDurationInMS = repeatEndDate - repeatStartDate;

    if (asMonths) {
      return (
        repeatDurationInMS / (1000 * 60 * 60 * 24 * 30.4167) // total milliseconds in one month
      );
    }

    return repeatDurationInMS;
  };

  /**
   * gets the cost per image estimate (string) and returns it as a Number
   */
  get estimatedCostPerTask () {
    return formatCurrencyToNumber(this.costPerImageEstimate);
  }

  /**
   * calculated number of tasks to run per month
   * @returns {number}
   */
  get repetitionIntervalInMonths () {
    const { repetitionInterval } = this.repetitionProperties;

    // repetitionInterval is measured in days
    return Math.ceil(30.4167 / repetitionInterval);
  }

  /**
   * gets the estimated monthly cost if the repeat tasking request has no defined end
   * @returns {number|null} - returns null if the repeat request has a defined end type
   */
  get estimatedCostPerMonth () {
    if (this.repeatRequestEndType === RepeatRequestEndTypes.INDEFINITE) {
      return this.estimatedCostPerTask * this.repetitionIntervalInMonths;
    }

    return null;
  }

  /**
   * gets the total number of tasks to run
   * @returns {number} - returns Infinity type if task has no defined end
   */
  get totalTasksToRun () {
    if (this.repeatRequestEndType === RepeatRequestEndTypes.COUNT) {
      const { repetitionCount } = this.repetitionProperties;
      return repetitionCount;
    }

    if (this.repeatRequestEndType === RepeatRequestEndTypes.DATE) {
      const repeatDurationMonths = this.repeatDuration(true);
      return Math.floor(
        this.repetitionIntervalInMonths * repeatDurationMonths
      );
    }

    return Infinity;
  }

  /**
   * gets calculated total cost if task has a defined end type
   * @returns {number|null} - returns null if the repeat request has no defined end type
   */
  get estimatedFiniteTotalCost () {
    const totalTasks = this.totalTasksToRun;
    if (totalTasks !== Infinity) {
      return this.estimatedCostPerTask * totalTasks;
    }

    return null;
  }
}

export default RepeatRequest;
