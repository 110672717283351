import {
  ADD_TASK,
  CLEAR_NEW_TASK,
  UPDATE_TASK,
  UPDATE_TASKS,
  UPDATE_TASKS_ACTION_STATE
} from 'commonLib/src/data/action-types';
import { TASKING_REQUEST_ID } from 'commonLib/src/data/property-names';
import { ROUTE_API_TASK } from 'commonLib/src/data/route-names';
import { TASKS_SCOPE } from 'commonLib/src/data/scopes';
import { routePathByName } from 'lib/routes';
import { constructRequestActionManager } from 'commonLib/src/lib/actions';

/**
 * setTasksActionState
 * @description Updates the tasks store's status
 */

export function setTasksActionState (status) {
  return {
    type: UPDATE_TASKS_ACTION_STATE,
    data: status
  };
}

const createDispatchRequestAction =
  constructRequestActionManager(setTasksActionState);

/**
 * fetchTasksByUserId
 * @description Fetches the tasks for a given user
 * @param {string} userId ID of the user
 */

export const fetchTasksByUserId = (userId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksByUserId',
      scope: TASKS_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_API_TASK)}?customerId=${userId}`,
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateTasks(data));

    return data;
  };
};

/**
 * fetchTaskByTaskId
 * @description Fetch a task by it's ID
 * @param {string} taskId ID of the task
 */

export const fetchTaskByTaskId = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTaskByTaskId',
      scope: TASKS_SCOPE,
      request: {
        url: `${routePathByName('apiTask')}/${taskId}`,
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateTasks([data]));

    return data;
  };
};

/**
 * postTaskRequest
 * @description Creates a new task given the provided data
 * @param {object} data The object data of the task
 */

const TASK_POST_PROPERTIES = [
  'taskingrequestName',
  'taskingrequestDescription',
  'orgId',
  'userId',
  'windowOpen',
  'windowClose',
  'collectionTier',
  'collectConstraints',
  'preApproval',
  'productCategory'
];

const TASK_POST_COLLECT_CONSTRAINTS = [
  'collectMode',
  'orbitalPlanes',
  'ascDsc',
  'lookDirection',
  'targetHeight',
  'azrMin',
  'azrMax',
  'grrMin',
  'grrMax',
  'offNadirMin',
  'offNadirMax',
  'imageWidth',
  'imageLength',
  'numLooks',
  'polarization'
];

export const postTaskRequest = (data = {}) => {
  return async (dispatch, getState) => {
    const { type, geometry, properties } = data;

    const taskData = {
      type,
      geometry,
      properties: {}
    };

    // Limit the properties to only the ones we want to actively send

    TASK_POST_PROPERTIES.forEach((property) => {
      if (!properties[property]) return;
      if (property === 'collectConstraints') {
        taskData.properties[property] = {};
      } else {
        taskData.properties[property] = properties[property];
      }
    });

    if (!taskData.properties.collectConstraints) {
      taskData.properties.collectConstraints = {};
    }

    if (properties.collectConstraints) {
      TASK_POST_COLLECT_CONSTRAINTS.forEach((property) => {
        if (!properties.collectConstraints[property]) return;
        taskData.properties.collectConstraints[property] =
          properties.collectConstraints[property];
      });
    }

    const dispatchAction = createDispatchRequestAction({
      name: 'postTaskRequest',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName('apiTask'),
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        data: taskData
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data: responseData } = request || {};

    dispatch(addTask(responseData));

    return responseData;
  };
};

/**
 * updateTaskStatusById
 * @description Updates the status of a task given the ID
 * @param {string} taskId ID of the task
 * @param {object} data Data to update on task status
 */

export const updateTaskStatusById = (taskId, { status } = {}) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateTaskStatusById',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName('apiTasksStatus', {
          wildcard: [taskId]
        }),
        method: 'patch',
        data: {
          status
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(fetchTaskByTaskId(taskId));

    return data;
  };
};

/**
 * fetchTasksCollectsById
 * @description Gets a given task's collects
 * @param {string} taskId ID of the task
 */

export const fetchTasksCollectsById = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksCollectsById',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName('apiTaskCollects', {
          wildcard: [taskId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(
      updateTask({
        properties: {
          [TASKING_REQUEST_ID]: taskId,
          collects: data
        }
      })
    );

    return data;
  };
};

/**
 * fetchTasksTilesById
 * @description Gets a given task's tiles
 * @param {string} taskId ID of the task
 */

export const fetchTasksTilesById = (taskId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchTasksTilesById',
      scope: TASKS_SCOPE,
      request: {
        url: routePathByName('apiTaskTiles', {
          wildcard: [taskId]
        }),
        method: 'fetch'
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(
      updateTask({
        properties: {
          [TASKING_REQUEST_ID]: taskId,
          tiles: data
        }
      })
    );

    return data;
  };
};

/******************
 * STATE UPDATERS *
 ******************/

/**
 * storeNewTask
 * @description
 */

export const storeNewTask = (data) => {
  return addTask(data);
};

/**
 * clearNewTask
 * @description
 */

export const clearNewTask = () => {
  return {
    type: CLEAR_NEW_TASK,
    data: {}
  };
};

/**
 * updateTasks
 * @description Set the task results
 */

export const updateTasks = (data) => {
  return {
    type: UPDATE_TASKS,
    data
  };
};

/**
 * updateTask
 * @description Update an individual task
 */

export const updateTask = (data) => {
  return {
    type: UPDATE_TASK,
    data
  };
};

/**
 * addTask
 * @description Add a task to the store
 */

export const addTask = (data) => {
  return {
    type: ADD_TASK,
    data
  };
};
