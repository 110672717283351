import { device } from 'fogg/lib';

import appPackage from '../../../package';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';
import { compareVersionNumbers } from '../lib/util';
import {
  TASKS_TABLE_COLUMNS,
  REPEAT_REQUESTS_TABLE_COLUMNS,
  ORG_TASKS_TABLE_COLUMNS,
  ORG_REPEAT_REQUESTS_TABLE_COLUMNS,
  RESELLER_TASKS_TABLE_COLUMNS,
  RESELLER_REPEAT_REQUESTS_TABLE_COLUMNS
} from '../lib/tasks';

const { isDomAvailable } = device;

export const COOKIE_PATH = '/';
export const COOKIE_EXPIRATION = 3600; // 1 hour in seconds
export const REFRESH_EXPIRATION = 3600 * 24; // 24 hours

export const SHORTENED_COOKIE_EXPIRATION = 300; // 5 mins
export const SHORTENED_REFRESH_EXPIRATION = 3600; // 1 hour

// Cookie domain used for shared session
export let COOKIE_DOMAIN = '.capellaspace.com';

// Make the Cookie environment specific by using hostname
if (typeof window !== 'undefined' && window.location) {
  const host = window.location.hostname;
  let subdomain = host;
  // Strip out app names so cookie can be shared on specific environments
  // Only do this on capellaspace.com urls, feature branches should use exact hostname
  if (subdomain?.length && subdomain.includes('.') && subdomain.includes('.capellaspace.com')) {
    subdomain = '.' + subdomain.split('.').slice(1).join('.');
  }
  COOKIE_DOMAIN = subdomain;
}

const STATE_KEY = 'CAP_DASH_STATE';
let storage;

if (isDomAvailable() && typeof localStorage !== 'undefined') {
  storage = localStorage;
}

const whitelistedStateKeys = [
  {
    key: 'app'
  },
  {
    key: 'cart'
  },
  {
    key: 'orders',
    defaultState: {
      orders: [],
      actions: {},
      new: {}
    },
    whitelistedKeys: ['new']
  },
  {
    key: 'tasks',
    defaultState: {
      tasks: [],
      actions: {},
      new: {},
      currentPage: 1,
      totalPages: 1,
      limit: DEFAULT_PAGING_LIMIT,
      filters: {},
      columns: TASKS_TABLE_COLUMNS
    },
    whitelistedKeys: ['new', 'columns']
  },
  {
    key: 'repeatRequests',
    defaultState: {
      repeatRequests: [],
      repeatRequestChildTasks: [],
      actions: {},
      new: {},
      currentPage: 1,
      totalPages: 1,
      columns: REPEAT_REQUESTS_TABLE_COLUMNS
    },
    whitelistedKeys: ['new', 'columns']
  },
  {
    key: 'user',
    defaultState: {
      user: {},
      pageSession: {
        id: ''
      },
      actions: {}
    },
    whitelistedKeys: ['user', 'pageSession']
  },
  {
    key: 'search',
    defaultState: {
      history: [],
      actions: {}
    },
    whitelistedKeys: ['history']
  },
  {
    key: 'org',
    defaultState: {
      users: [],
      organization: {},
      actions: {},
      orders: [],
      tasks: [],
      totalPages: 1,
      repeatRequests: [],
      columns: ORG_TASKS_TABLE_COLUMNS,
      repeatColumns: ORG_REPEAT_REQUESTS_TABLE_COLUMNS
    },
    whitelistedKeys: ['columns', 'repeatColumns']
  },
  {
    key: 'subreseller',
    defaultState: {
      organization: {},
      actions: {},
      tasks: [],
      repeatRequests: [],
      columns: RESELLER_TASKS_TABLE_COLUMNS,
      repeatColumns: RESELLER_REPEAT_REQUESTS_TABLE_COLUMNS
    },
    whitelistedKeys: ['columns', 'repeatColumns']
  },
  {
    key: 'reseller',
    defaultState: {
      organization: {},
      actions: {},
      tasks: [],
      repeatRequests: [],
      columns: RESELLER_TASKS_TABLE_COLUMNS,
      repeatColumns: RESELLER_REPEAT_REQUESTS_TABLE_COLUMNS
    },
    whitelistedKeys: ['columns', 'repeatColumns']
  }
];

export function loadState () {
  const defaultState = {};

  if (!storage) return defaultState;

  const state = getStoredState();

  const { app = {} } = state || {};
  const { version } = app;

  const hasVersion = typeof version !== 'undefined';
  const isStaleVersion =
    hasVersion && compareVersionNumbers(version, appPackage.version) === -1;

  // If we have a stale version of the cache, let's kill it to make sure the user
  // doesn't unintentially run into any issues with missing or incorrect data

  if (!hasVersion || isStaleVersion) {
    clearState();
    return defaultState;
  }

  return state;
}

export function saveState (state) {
  try {
    const serializedState = JSON.stringify(state);
    storage.setItem(STATE_KEY, serializedState);
  } catch (error) {
    throw new Error(`Issue saving state to storage: ${error}`);
  }
}

export function clearState () {
  try {
    const serializedState = JSON.stringify({});
    storage.setItem(STATE_KEY, serializedState);
  } catch (error) {
    throw new Error(`Issue clearing state from storage: ${error}`);
  }
}

export function syncStateToStorage (store) {
  const currentState = store.getState();
  const filteredState = {};

  whitelistedStateKeys.forEach((item) => {
    const { key, defaultState, whitelistedKeys } = item;
    const keyState = {};
    if (whitelistedKeys && defaultState) {
      whitelistedKeys.forEach((prop) => {
        keyState[prop] = currentState[key][prop];
      });
      filteredState[key] = {
        ...defaultState,
        ...keyState
      };
    } else {
      filteredState[key] = currentState[key];
    }
  });

  saveState(filteredState);
}

export function getStoredState (key = STATE_KEY) {
  if (!isDomAvailable()) return {};
  let state;
  try {
    state = storage.getItem(key);
    if (state !== null) {
      state = JSON.parse(state);
    }
  } catch (error) {
    throw new Error(`Issue loading state from storage: ${error}`);
  }
  return state;
}

export function hasStoredState () {
  const { user: userState = {}, cart: cartState = {} } = getStoredState() || {};
  const { cart = {} } = cartState;
  const { user = {} } = userState;

  const isUser = user.id || user.token;
  const hasCart = Array.isArray(cart.items) && cart.items.length > 0;

  return isUser || hasCart;
}
