import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useAdmin, useAdminOrganization, useUser, useReseller } from '../hooks';

import { errorMessageByCode } from '../lib/errors';
import { logError } from '../lib/logger';
import { fetchOrganizationById, triggerNotice, fetchParentOrganizationById } from '../state/actions';

const errorBase = 'Failed to fetch organization';

export default function useAdminFetchOrganization ({ id } = {}) {
  const dispatch = useDispatch();

  const { user = {} } = useUser();
  const { actions = {} } = useAdmin();
  const { actions: resellerActions = {} } = useReseller();
  const organization = useAdminOrganization({ id });

  if (typeof id !== 'string') {
    throw new Error(`${errorBase}: Invalid ID ${id}`);
  }

  const hasAdminPermissions = user.isAdmin();

  const { resellerId, subResellerId, type } = organization;

  let parentOrgId;
  if (subResellerId && type !== 'reseller') {
    parentOrgId = subResellerId;
  } else if (resellerId) {
    parentOrgId = resellerId;
  }

  const [parentOrg, setParentOrg] = useState();

  /**
   * fetchOrganization
   */

  function fetchOrganization (organizationId) {
    dispatch(fetchOrganizationById(organizationId, {
      eagerLedgerIncludes: true
    })).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  /**
   * fetch the parent reseller org if present
   */

  function fetchParentOrganization (organizationId) {
    dispatch(fetchParentOrganizationById(organizationId)).then(res => {
      setParentOrg(res);
    }).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  useEffect(() => {
    if (!parentOrgId || !hasAdminPermissions) return;
    fetchParentOrganization(parentOrgId);
  }, [parentOrgId, hasAdminPermissions]);

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchOrganization = useCallback(() => {
    fetchOrganization(id);
  }, [id]);

  useEffect(() => {
    memoizedFetchOrganization();
  }, [memoizedFetchOrganization]);

  return {
    fetchOrganization: memoizedFetchOrganization,
    ...actions.fetchOrganizationById,
    organization,
    parentOrganization: parentOrg,
    isLoadingParent: resellerActions.fetchParentOrganizationById?.isLoading
  };
}
