import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useUser, useTokenCookie } from '../hooks';
import { checkTokenExpiration } from '../state/actions';
import { isPublicPath, navigateTo, canRedirectToAfterAuth, routePathByName } from '../lib/routes';

/**
 * Watch for user events, or page loads and compare
 * refreshTokenExpiration with current time
 */
export default function useTokenExpiration () {
  const { expiration } = useTokenCookie();
  const { pathname } = useLocation();
  const { user = {} } = useUser();

  const handleOnUserAction = event => {
    checkTokenExpiration(expiration, pathname);
  };

  const handleOnActive = event => {
    checkTokenExpiration(expiration, pathname);
  };

  const handleOnIdle = event => {
    checkTokenExpiration(expiration, pathname);
  };

  // Watch for mouse and idle events
  useIdleTimer({
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnUserAction,
    debounce: 1000
  });

  // Check refresh token expiration when it or user changes
  useEffect(() => {
    if (!expiration || !typeof expiration === 'number') {
      // Stale state maybe, if no expiration logout to clear cookies
      if (user.id && !isPublicPath(pathname) && !canRedirectToAfterAuth(pathname)) {
        navigateTo(routePathByName('userLogout'));
        return;
      }
    }
    checkTokenExpiration(expiration, pathname);
  }, [expiration, user, pathname]);
}
