import axios from 'axios';

import RequestError from 'models/request-error';
import { addParamsToUrl } from 'lib/util';
import { logRequestEvent } from 'lib/logger';

/**
 * Request
 * @description Creates an Request class to manage and fetch request data
 */

export default class Request {
  constructor (baseUrl) {
    this.url = baseUrl;
    this.data = null;
    this.options = {};
  }

  /**
   * setParams
   * @description Adds query parameters to the request URI
   * @param {object} params
   */

  setParams (params) {
    if (typeof params !== 'object') return this.url;
    this.url = addParamsToUrl(this.url, params);
    return this.url;
  }

  /**
   * setData
   * @description Adds a data object to the request instance
   * @param {object} data
   */

  setData (data) {
    if (typeof data !== 'object') return this.data;
    this.data = Object.assign({}, data);
    return this.data;
  }

  /**
   * setOptions
   * @description Adds a options object to the request instance
   * @param {object} options
   */

  setOptions (options) {
    if (typeof options !== 'object') return this.options;
    this.options = Object.assign({}, options);
    return this.options;
  }

  /**
   * updateHeaders
   * @description Updates the headers object on the options
   * @param {object} options
   */

  updateHeaders (headers) {
    this.setOptions({
      headers: {
        ...this.options.headers,
        ...headers
      }
    });
    return this.options.headers;
  }

  /**
   * validate
   * @description Checks that we have a valid request config before making it
   */
  validate () {
    if (!this.url) {
      return Promise.reject(new Error(Object.assign({}, this), 'MISSING_URL'));
    }
    return Promise.resolve(true);
  }

  /**
   * fetch
   * @description Makes the request given the current state of the class URL
   */

  fetch () {
    return this.validate().then(() => {
      return axios
        .get(this.url, this.options)
        .then((response) => {
          logRequestEvent('GET', this, response.status);
          return response;
        })
        .catch((error) => {
          logRequestEvent('GET', this, `Error: ${error.message}`);
          throw new RequestError(error);
        });
    });
  }

  /**
   * post
   * @description Makes the request given the current state of the class URL and data
   */

  post () {
    const BASE_URL = process.env.LOG_EVENT_ENDPOINT;
    const loggerURL = `${BASE_URL}/logs/event`;
    const postToLogger = this.url === loggerURL;

    return this.validate().then(() => {
      return axios
        .post(this.url, this.data, this.options)
        .then((response) => {
          if (!postToLogger) logRequestEvent('POST', this, response.status);
          return response;
        })
        .catch((error) => {
          if (!postToLogger) {
            logRequestEvent('POST', this, `Error: ${error.message}`);
          }
          throw new RequestError(error);
        });
    });
  }

  /**
   * put
   * @description Makes the request given the current state of the class URL and data
   */

  put () {
    return this.validate().then(() => {
      return axios.put(this.url, this.data, this.options).catch((error) => {
        throw new RequestError(error);
      });
    });
  }

  /**
   * patch
   * @description Makes the request given the current state of the class URL and data
   */

  patch () {
    return this.validate().then(() => {
      return axios.patch(this.url, this.data, this.options).catch((error) => {
        throw new RequestError(error);
      });
    });
  }

  /**
   * delete
   * @description Makes the request given the current state of the class URL and data
   */

  delete () {
    return this.validate().then(() => {
      return axios
        .delete(this.url, {
          ...this.options,
          data: this.data
        })
        .catch((error) => {
          throw new RequestError(error);
        });
    });
  }
}
