import clone from 'clone';
import deepmerge from 'deepmerge';

import { isEmptyObject } from 'lib/util';
import Order from 'models/order';

const defaultState = {
  orders: [],
  actions: {},
  new: {}
};

const orders = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);

  switch (type) {
    case 'UPDATE_ORDERS': {
      return {
        ...workingState,
        orders: [...workingData]
      };
    }
    case 'ADD_ORDER': {
      if (workingData.id === 'NEW_ORDER') {
        return {
          ...workingState,
          new: workingData
        };
      } else {
        return {
          ...workingState,
          orders: [...workingState.orders, workingData]
        };
      }
    }
    case 'UPDATE_ORDER_ITEMS': {
      const { orders, new: newOrder } = workingState;
      const { orderId, items } = workingData;

      if (orderId === 'NEW_ORDER' && !isEmptyObject(newOrder)) {
        return {
          ...workingState,
          new: new Order(newOrder).update({ items })
        };
      } else {
        const updatedOrderIndex = orders.findIndex(
          (order) => order.id === orderId
        );
        let updatedOrder;

        if (updatedOrderIndex !== -1) {
          updatedOrder = new Order(orders[updatedOrderIndex]).update({ items });
          orders[updatedOrderIndex] = updatedOrder.data();
        }

        const updatedState = orders;

        return {
          ...workingState,
          orders: updatedState
        };
      }
    }
    case 'UPDATE_ORDERS_ACTION_STATE': {
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    }
    case 'CLEAR_NEW_ORDER': {
      return {
        ...workingState,
        new: {}
      };
    }
    default: {
      return workingState;
    }
  }
};

export default orders;
