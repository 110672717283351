import {
  UPDATE_REPEAT_REQUESTS,
  UPDATE_REPEAT_REQUESTS_ACTION_STATE,
  UPDATE_REPEAT_REQUESTS_PAGED,
  UPDATE_REPEAT_REQUESTS_COLUMNS
} from 'data/action-types';
import {
  ROUTE_API_REPEAT_REQUEST,
  ROUTE_API_REPEAT_REQUEST_STATUS,
  ROUTE_API_REPEAT_REQUESTS_SEARCH,
  ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV
} from 'data/route-names';
import { REPEAT_REQUESTS_SCOPE } from 'data/scopes';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';
import { routePathByName } from 'lib/routes';
import { constructRequestActionManager } from 'commonLib/src/lib/actions';

import { taskFiltersToAPI, mapTaskTableField } from 'lib/tasks';

/**
 * Updates the repeat requests store's status
 */
export function setRepeatRequestsActionState (status) {
  return {
    type: UPDATE_REPEAT_REQUESTS_ACTION_STATE,
    data: status
  };
}

const createDispatchRequestAction = constructRequestActionManager(
  setRepeatRequestsActionState
);

/**
 * searchRepeatRequestsByUserId
 * @description Filter & Sort Repeat Requests
 * @param {string} userId ID of the user
 * @param {object} paging pagination page & limit
 * @param {object} [filters={}] e.g. { filterName: ['value'] }
 * @param {object} [sortObj = {}] e.g. { sort: 'status', order: 'asc' }
 * @param {AbortController} [controller = new AbortController()] AbortController signal to cancel stale requests
 */

export const searchRepeatRequestsByUserId = (
  userId,
  { page = 1, limit = DEFAULT_PAGING_LIMIT },
  filters = {},
  sortObj = {},
  controller
) => {
  // Convert UI filters format & naming to API format
  const filterPayload = taskFiltersToAPI(filters, 'repeat', userId) || {};
  const { sort, order } = sortObj;

  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'searchRepeatRequestsByUserId',
      scope: REPEAT_REQUESTS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_REPEAT_REQUESTS_SEARCH, {
          params: {
            page,
            limit,
            sort: mapTaskTableField(sort, 'repeat'),
            order: order
          }
        }),
        data: {
          filter: filterPayload
        },
        method: 'post',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data = {} } = request || {};

    const { results, currentPage, totalPages } = data;

    dispatch(updateRepeatRequestsPaged(data));

    return { results, currentPage, totalPages };
  };
};

/**
 * Fetch action for a csv representation of a user's repeat request data
 *
 * @param {string} userId
 * @param {object} [searchFilters={}] an object of search filters in key value shape per filter, e.g. { status: '', collectMods: [], ... }
 * @param {{ sort: string, order: 'asc'|'desc' }} [sortObj={}] sort and order object to pass as parameters to the download endpoint
 */
export const fetchRepeatRequestsCsvByUserId = (userId, searchFilters = {}, sortObj = {}) => {
  return async (dispatch, getState) => {
    const { sort, order } = sortObj;
    // Convert UI filters format & naming to API format
    const filterPayload = taskFiltersToAPI(searchFilters, 'repeat', userId) || {};
    const dispatchAction = createDispatchRequestAction({
      name: fetchRepeatRequestsCsvByUserId.name,
      scope: REPEAT_REQUESTS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_EXPORT_REPEAT_REQUESTS_CSV, {
          params: { sort: mapTaskTableField(sort), order }
        }),
        data: {
          filter: filterPayload
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });
    const request = await dispatchAction(dispatch, getState);
    const { data } = request;
    return data;
  };
};

/**
 * Fetch a repeat request by it's ID
 * @param {string} repeatRequestId ID of the repeat request
 */
export const fetchRepeatRequestByRepeatRequestId = (repeatRequestId) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'fetchRepeatRequestByRepeatRequestId',
      scope: REPEAT_REQUESTS_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_API_REPEAT_REQUEST)}/${repeatRequestId}`,
        method: 'fetch',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(updateRepeatRequests([data]));

    return data;
  };
};

/**
 * Updates the status of a repeat request given the ID
 * @param {string} repeatRequestId ID of the repeatRequest
 * @param {object} data Data to update on repeat request status
 */

export const updateRepeatRequestStatusById = (
  repeatRequestId,
  { status } = {}
) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateRepeatRequestStatusById',
      scope: REPEAT_REQUESTS_SCOPE,
      request: {
        url: routePathByName(ROUTE_API_REPEAT_REQUEST_STATUS, {
          wildcard: [repeatRequestId]
        }),
        method: 'patch',
        data: {
          code: status
        }
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    dispatch(fetchRepeatRequestByRepeatRequestId(repeatRequestId));

    return data;
  };
};

/**
 * updateRepeatRequestById
 * @description Updates a repeat request's properties given the ID
 * @param {string} repeatRequestId ID of the repeat
 * @param {object} data Data to update on repeat
 */

export const updateRepeatRequestById = (repeatRequestId, dataObj = {}) => {
  return async (dispatch, getState) => {
    const dispatchAction = createDispatchRequestAction({
      name: 'updateRepeatRequestById',
      scope: REPEAT_REQUESTS_SCOPE,
      request: {
        url: `${routePathByName(ROUTE_API_REPEAT_REQUEST)}/${repeatRequestId}`,
        method: 'patch',
        data: dataObj
      }
    });

    const request = await dispatchAction(dispatch, getState);
    const { data } = request || {};

    return data;
  };
};

/**
 * Set the repeat requests results
 */
export const updateRepeatRequests = (data) => {
  return {
    type: UPDATE_REPEAT_REQUESTS,
    data
  };
};

/**
 * updateRepeatRequestsPaged
 * @description Set the task results in paged format
 */
export const updateRepeatRequestsPaged = (data) => {
  return {
    type: UPDATE_REPEAT_REQUESTS_PAGED,
    data
  };
};

/**
 * updateRepeatRequestsColumns
 * @description Set the columns & column order in the repeat requests table
 */
export const updateRepeatRequestsColumns = (data) => {
  return {
    type: UPDATE_REPEAT_REQUESTS_COLUMNS,
    data
  };
};
