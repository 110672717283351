import { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';

import { useItems } from 'hooks';
import { logError } from 'lib/logger';
import { errorMessageByCode } from 'lib/errors';
import { triggerNotice, fetchAllCollections } from 'state/actions';

export default function useFetchCollections () {
  const dispatch = useDispatch();

  const { actions = {}, collections } = useItems();

  /**
   * _fetchAllCollections
   */

  function _fetchAllCollections () {
    dispatch(fetchAllCollections()).catch((e) => {
      logError(e.message);
      dispatch(
        triggerNotice({
          type: 'error',
          weight: 'bold',
          align: 'center',
          text: errorMessageByCode()
        })
      );
    });
  }

  // Create a memoized version so we can use it as a dependency
  // for useEffect

  const memoizedFetchAllCollections = useCallback(() => {
    _fetchAllCollections();
  }, []); // eslint-disable-line

  useEffect(() => {
    memoizedFetchAllCollections();
  }, [memoizedFetchAllCollections]);

  return {
    fetchAllCollections: memoizedFetchAllCollections,
    ...actions.fetchAllCollections,
    collections
  };
}
