import clone from 'clone';
import deepmerge from 'deepmerge';
import {
  ADD_TASK,
  CLEAR_NEW_TASK,
  NEW_TASK,
  UPDATE_TASK,
  UPDATE_TASKS,
  UPDATE_TASKS_PAGE,
  UPDATE_TASKS_LIMIT,
  UPDATE_TASKS_PAGED,
  UPDATE_TASKS_SORT,
  UPDATE_TASKS_FILTERS,
  UPDATE_TASKS_COLUMNS,
  UPDATE_SEARCH_TASKS,
  UPDATE_TASKS_ACTION_STATE,
  UPDATE_CONFLICTED_TASK
} from 'data/action-types';
import { DEFAULT_PAGING_LIMIT } from 'data/tasks';
import { TASKING_REQUEST_ID } from 'data/property-names';
import { updateTaskData, updateTasksData, TASKS_TABLE_COLUMNS } from 'lib/tasks';

const defaultState = {
  tasks: [],
  searchTasks: [],
  actions: {},
  new: {},
  currentPage: 1,
  totalPages: 1,
  limit: DEFAULT_PAGING_LIMIT,
  columns: TASKS_TABLE_COLUMNS,
  conflictedTask: null
};

const tasks = (state = defaultState, { type, data } = {}) => {
  const workingState = clone(state);
  const workingData = clone(data);
  let taskingrequestId;

  if (workingData && workingData.properties) {
    taskingrequestId = workingData.properties[TASKING_REQUEST_ID];
  }

  const { tasks: workingStateTasks = [], new: workingStateNewTask } =
    workingState;

  switch (type) {
    case UPDATE_TASK:
      if (typeof workingData !== 'object' || Array.isArray(workingData)) {
        throw new Error(`Invalid data passed to ${UPDATE_TASK}`);
      }

      if (taskingrequestId === NEW_TASK) {
        return {
          ...workingState,
          new: updateTaskData(workingStateNewTask, workingData)
        };
      } else {
        return {
          ...workingState,
          tasks: updateTasksData(workingStateTasks, [workingData]),
          searchTasks: []
        };
      }
    // FIXME: Replace UPDATE_TASKS with _PAGED version post-feature flag
    case UPDATE_TASKS:
      if (!Array.isArray(workingData)) {
        throw new Error(`Invalid data passed to ${UPDATE_TASKS}`);
      }
      return {
        ...workingState,
        tasks: updateTasksData(workingStateTasks, workingData),
        searchTasks: []
      };
    case UPDATE_TASKS_PAGED:
      if (!Array.isArray(workingData.results)) {
        throw new Error(`Invalid data passed to ${UPDATE_TASKS_PAGED}`);
      }
      return {
        ...workingState,
        tasks: workingData.results,
        currentPage: workingData.currentPage,
        totalPages: workingData.totalPages
      };
    case UPDATE_TASKS_LIMIT:
      return {
        ...workingState,
        limit: workingData
      };
    case UPDATE_TASKS_PAGE:
      return {
        ...workingState,
        page: workingData
      };
    case UPDATE_TASKS_FILTERS:
      return {
        ...workingState,
        filters: workingData
      };
    case UPDATE_TASKS_COLUMNS:
      return {
        ...workingState,
        columns: workingData
      };
    case UPDATE_TASKS_SORT:
      return {
        ...workingState,
        sort: workingData
      };
    case ADD_TASK:
      if (taskingrequestId === NEW_TASK) {
        return {
          ...workingState,
          new: workingData,
          searchTasks: []
        };
      } else {
        return {
          ...workingState,
          tasks: [...workingState.tasks, workingData],
          searchTasks: []
        };
      }
    case UPDATE_SEARCH_TASKS:
      if (!Array.isArray(workingData)) {
        throw new Error(`Invalid data passed to ${UPDATE_SEARCH_TASKS}`);
      }
      return {
        ...workingState,
        searchTasks: workingData
      };
    case UPDATE_TASKS_ACTION_STATE:
      return {
        ...workingState,
        actions: deepmerge(workingState.actions, workingData)
      };
    case CLEAR_NEW_TASK:
      return {
        ...workingState,
        new: {},
        searchTasks: []
      };
    case UPDATE_CONFLICTED_TASK:
      return {
        ...workingState,
        conflictedTask: workingData
      };
    default:
      return workingState;
  }
};

export default tasks;
