import React from 'react';
import { useDispatch } from 'redux-react-hook';

import { postNewUser, triggerNotice, triggerErrorByCode } from 'state/actions';
import { redirectTo, routePathByName } from 'lib/routes';
import { logError } from 'lib/logger';
import { useUser } from 'hooks';
import { ORG_MANAGER_ROLE } from 'data/roles';

import { TemplateUserCreate } from 'commonLib';

const OrganizationUsersCreatePage = () => {
  const dispatch = useDispatch();

  const { user = {}, actions = {} } = useUser();
  const { organization } = user;
  const { fetchUser = {} } = actions;
  const { isLoading } = fetchUser;

  const breadcrumbs = [
    {
      label: 'Organization Management',
      to: routePathByName('organization')
    },
    {
      label: 'Users',
      to: routePathByName('organizationUsers')
    }
  ];

  const createState = {
    isLoading
  };

  /**
   * handleSubmitForm
   */

  function handleSubmitForm (data) {
    dispatch(postNewUser(data))
      .then(() => {
        redirectTo(routePathByName('organizationUsers'));
        dispatch(
          triggerNotice({
            type: 'success',
            weight: 'bold',
            align: 'center',
            text: 'User was successfully created!'
          })
        );
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        const { error = {} } = data;

        logError(e.message, data);

        dispatch(triggerErrorByCode(error.code));
      });
  }

  return (
    <TemplateUserCreate
      organizations={[organization]}
      accessType={ORG_MANAGER_ROLE}
      breadcrumbs={breadcrumbs}
      onCreate={handleSubmitForm}
      state={createState}
    />
  );
};
export default OrganizationUsersCreatePage;
