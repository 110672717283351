import React from 'react';
import PropTypes from 'prop-types';
import { FaSatellite, FaDownload } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { dateTimeFormats } from 'lib/datetime';
import { geometryToGeoJson } from 'lib/map';

/**
 * Individual satellite Access Request Items within the horizontal scroller
 * @param {object} access
 * @param {function} onClick
 * @param {function} hoverAccess
 */

export default function AccessRequestItem ({
  access = {},
  onClick,
  hoverAccess,
  displayUtc
}) {
  const {
    accessId,
    windowOpen,
    windowOpenLocal,
    geometry
  } = access;
  const { date, time } = dateTimeFormats(
    displayUtc ? windowOpen : windowOpenLocal,
    undefined,
    {
      timeFormat: 'HH:mm'
    }
  );

  // Returns an href string to pass the geoJson download link
  const downloadGeometry = (geometry) => {
    const href = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(geometryToGeoJson(geometry))
      )}`;
    return href;
  };

  if (!access) return null;
  return (
    <div
      className="access-request-item"
      onClick={() => onClick()}
      role="button"
      tabIndex={0}
      onMouseEnter={() => hoverAccess({ id: accessId, geometry }, 'enter')}
      onMouseLeave={() => hoverAccess({ id: accessId, geometry }, 'leave')}
      key={accessId}
      data-tip
      data-for={'additionalAccessRequestsTip' + accessId}
    >
      <span className="access-request-icon">
        <FaSatellite />
      </span>
      <span className="access-request-time">
        <span>{date}</span>
        <span>{time}</span>
      </span>
      {access.accessProperties && (
        <ReactTooltip
          id={'additionalAccessRequestsTip' + accessId}
          place="left"
          effect="solid"
          type="dark"
          multiline={true}
          className="customTip"
          delayHide={100}
          delayUpdate={100}
        >
          <div className="access-request-tip">
            <div className="tip-content">
              <div className="tip-content-row">
                Orbit State: <b>{access.accessProperties.ascdsc}</b>
              </div>
              <div className="tip-content-row">
                Orbital Plane:{' '}
                <b>{Array.isArray(access.orbitalPlane)
                  ? access.orbitalPlane.join(', ')
                  : access.orbitalPlane}</b>
              </div>
              <div className="tip-content-row">
                Observation Direction:{' '}
                <b>{access.accessProperties.lookDirection}</b>
              </div>
              <div className="tip-content-row">
                Look Angle:{' '}
                <b>{access.accessProperties.offNadirMin.toFixed(1)}</b>
              </div>
              <div className="tip-content-row button-row">
                <a href={downloadGeometry(geometry)}
                  download={`AccessTime_${date}_${time.replace(':', '')}.geoJson`}>
                  <FaDownload /> <b>Geometry</b> (.GeoJSON)
                </a>
              </div>
            </div>
          </div>
        </ReactTooltip>
      )}
    </div>
  );
}

AccessRequestItem.propTypes = {
  access: PropTypes.object,
  onClick: PropTypes.func,
  hoverAccess: PropTypes.func,
  displayUtc: PropTypes.bool
};
