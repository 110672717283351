import React from 'react';
import PropTypes from 'prop-types';
import { device } from 'fogg/lib';

import {
  useTask,
  useFetchTask,
  useFetchRepeatRequestChildTasks,
  useRepeatRequestChildTasks
} from 'hooks';
import { navigateTo, routePathByName } from 'lib/routes';
import { repeatRequestIdIsValid, taskIdIsValid } from 'lib/data';
import {
  ROUTE_TASKS,
  ROUTE_TASKS_DETAILS,
  ROUTE_REPEAT_REQUEST_DETAILS
} from 'data/route-names';

import TemplateRepeatRequestChildTask from 'templates/TemplateRepeatRequestChildTask';

const { isDomAvailable } = device;

/**
 * page for a repeat request's child task
 * @param {string} repeatRequestId
 * @param {string} repeatRequestChildTaskId
 */
const RepeatRequestChildTaskPage = ({ repeatRequestId, repeatRequestChildTaskId }) => {
  // navigation paths
  const tasksPath = routePathByName(ROUTE_TASKS);
  const parentRepeatRequestPath = routePathByName(ROUTE_REPEAT_REQUEST_DETAILS, {
    wildcard: [repeatRequestId]
  });
  const taskDetailsPath = routePathByName(ROUTE_TASKS_DETAILS, {
    wildcard: [repeatRequestChildTaskId]
  });

  // child tasks spawned from repeat request
  const { repeatRequestChildTasks } = useRepeatRequestChildTasks();
  const { isLoading: isChildTasksLoading } = useFetchRepeatRequestChildTasks({
    repeatRequestId,
    forceNew: true
  });
  const isValidRepeatRequestChildTaskId = taskIdIsValid(repeatRequestChildTaskId);
  const isValidRepeatRequestId = repeatRequestIdIsValid(repeatRequestId);

  // fetch the task from tasking-api
  const fetchedTask = useTask(repeatRequestChildTaskId);
  const { isLoading: isFetchTaskLoading, isError: isFetchTaskError } = useFetchTask(repeatRequestChildTaskId, true, {
    quietNotice: true,
    retryOnFail: true,
    retriesAllowed: Infinity
  });
  const isValidFetchedTaskId = taskIdIsValid(fetchedTask?.id);

  /**
   * If we dont have a valid repeat request ID,
   * navigate back to the tasks path
   */
  if (!isValidRepeatRequestId) {
    if (isDomAvailable()) navigateTo(tasksPath);
    return null;
  }

  /**
   * If we have a valid repeat request ID but not a valid child task ID,
   * navigate back to the parent repeat request path
   */
  if (isValidRepeatRequestId && !isValidRepeatRequestChildTaskId) {
    if (isDomAvailable()) navigateTo(parentRepeatRequestPath);
    return null;
  }

  /**
   * If we have a valid fetchedTask, then we can redirect to the main task details template
   */
  if (!isFetchTaskLoading && !isFetchTaskError && isValidFetchedTaskId) {
    if (isDomAvailable()) navigateTo(taskDetailsPath);
    return null;
  }

  const taskState = {
    isLoading:
      isChildTasksLoading
  };

  const repeatRequestChildTask = repeatRequestChildTasks?.find(({ id }) => id === repeatRequestChildTaskId) || {
    id: repeatRequestChildTaskId
  };

  return <TemplateRepeatRequestChildTask task={repeatRequestChildTask} state={taskState} />;
};

RepeatRequestChildTaskPage.propTypes = {
  repeatRequestId: PropTypes.string,
  repeatRequestChildTaskId: PropTypes.string
};

export default RepeatRequestChildTaskPage;
