import React from 'react';
import PropTypes from 'prop-types';

import { useResellerFetchOrganization } from 'hooks';
import { routePathByName } from 'lib/routes';
import { RESELLER_ROLE } from 'data/roles';
import { ROUTE_RESELLER_CONTRACT_DETAILS } from 'data/route-names';

import { TemplateOrganizationDetails } from 'commonLib';

const ResellerDetailsPage = ({ organizationId }) => {
  const {
    isLoading,
    organization = {},
    parentOrganization = {},
    isLoadingParent
  } = useResellerFetchOrganization({
    id: organizationId
  });

  if (parentOrganization.name) {
    organization.resellerOrg = parentOrganization;
  }

  const breadcrumbs = [
    {
      label: 'Reseller',
      to: routePathByName('reseller')
    }
  ];

  const organizationState = {
    isLoading: isLoading || isLoadingParent
  };

  return (
    <TemplateOrganizationDetails
      organization={organization}
      accessType={RESELLER_ROLE}
      breadcrumbs={breadcrumbs}
      state={organizationState}
      detailView={true}
      contractDetailsRouteName={ROUTE_RESELLER_CONTRACT_DETAILS}
      contractDetailsRouteOptions={[organizationId]}
    />
  );
};

ResellerDetailsPage.propTypes = {
  organizationId: PropTypes.string.isRequired
};

export default ResellerDetailsPage;
