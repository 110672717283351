import { useRef } from 'react';

/**
 * Hook for assigning component refs, and setting default values dynamically
 *
 * @param {object} [param0={}]
 * @param {object} [param0.defaultFormInputValues={}] a dictionary of form data defaults
 */
function useFormInputDefaults ({
  defaultFormInputValues = {}
} = {}) {
  const refs = useRef({});

  /**
   * Assigns ref to a component using the key or id attribute
   *
   * @example
   * const FormComponent = () => {
   *   const { assignRef } = useFormInputDefaults();
   *   return(
   *     <InputComponent ref={assignRef} key="inputComponent1" />
   *   );
   * };
   *
   * @param {HTMLElement|React.Component} refEl
   * @returns {void}
   */
  function assignRef (refEl) {
    if (refEl) {
      refs.current[refEl.key || refEl.id] = refEl;
    }
  }

  /**
   * Sets default values to all assigned ref components
   *
   * @example
   * const { assignDefaultsToRef } = useFormInputDefaults();
   * function setDefaults(selection) {
   *   if (selection === 'example1') {
   *     assignDefaultsToRef({ key: 'value' });
   *   } else {
   *     // resets back to defaultFormInputValues
   *     assignDefaultsToRef();
   *   }
   * }
   *
   * @param {object} [defaults]
   * @returns {void}
   */
  function assignDefaultsToRef (defaults) {
    defaults = defaults || defaultFormInputValues;
    Object.entries(defaults).forEach(([refKey, defaultValues]) => {
      if (refKey in refs.current) {
        Object.entries(defaultValues).forEach(([prop, value]) => {
          refs.current[refKey][prop] = value;
        });
      }
    });
  }

  return {
    refs,
    assignRef,
    assignDefaultsToRef
  };
}

export default useFormInputDefaults;
