import { useMappedState } from 'redux-react-hook';

export default function useOrder (orderId) {
  const mapState = ({ orders }) => orders;

  const { orders, new: newOrder } = useMappedState(mapState);

  if (orderId === 'NEW_ORDER') {
    return newOrder;
  } else {
    return orders.find((order) => `${order.id}` === orderId);
  }
}
