import { useMappedState } from 'redux-react-hook';
import Organization from 'models/organization';

export default function useAdminOrganization ({ id } = {}) {
  const mapState = ({ admin }) => admin;
  const { organizations } = useMappedState(mapState);

  const organization = organizations.find(({ id: orgId } = {}) => orgId === id);

  return new Organization(organization);
}
