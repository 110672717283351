
export const NEW_ENTRY_PREFIX = 'NEW_';

// TODO: Figure out why we have this hook and if it's still needed?
export default function useCreateNewEntry ({
  entryHook,
  entryType
} = {}) {
  let newEntryId;

  if (typeof entryType === 'string') {
    newEntryId = `${NEW_ENTRY_PREFIX}${entryType.toUpperCase()}`;
  }

  const newEntry = entryHook(newEntryId);

  return {
    newEntry,
    newEntryId
  };
}
