import React from 'react';
import PropTypes from 'prop-types';
import { Notice, Table } from 'fogg/ui';

const COLUMNS = [
  {
    columnId: 'label',
    widthRatio: 2
  },
  {
    columnId: 'value',
    align: 'right'
  }
];

const OrderSummaryTable = ({
  orderSummary = {},
  orderArchSubParams: {
    isArchiveSubscriptionOrder = false,
    availableSubscriptionUnits
  } = {},
  hidePricing = false
}) => {
  const summaryItems = [];
  const { total, subtotal, availableSubscriptionUnitsAfterOrder } = orderSummary;

  if (!hidePricing) {
    if (isArchiveSubscriptionOrder) {
      summaryItems.push({
        label: 'Current image balance',
        value: availableSubscriptionUnits.toString()
      });

      summaryItems.push({
        label: 'Total new (unowned) images in order',
        value: (availableSubscriptionUnits - availableSubscriptionUnitsAfterOrder).toString()
      });

      summaryItems.push({
        label: <strong>Remaining image balance</strong>,
        value: (
          <strong>{availableSubscriptionUnitsAfterOrder}</strong>
        )
      });
    } else {
      summaryItems.push({
        label: 'Subtotal',
        value: subtotal
      });

      summaryItems.push({
        label: <strong>Discounted Total</strong>,
        value: <strong>{total}</strong>
      });
    }
  }

  return (
    <>
      {!hidePricing ? (
        <Table
          displayHeader={false}
          fixHeightToContent={true}
          columns={COLUMNS}
          data={summaryItems}
        />
      ) : (
        <span className="order-review-details-hide-price">
          <Notice type="white small">Contact Reseller for pricing.</Notice>
        </span>
      )}
    </>
  );
};

OrderSummaryTable.propTypes = {
  orderSummary: PropTypes.object,
  orderArchSubParams: PropTypes.object,
  hidePricing: PropTypes.bool
};

export default OrderSummaryTable;
