import { useEffect } from 'react';

import { buildSearchParams } from 'lib/search';
import { useLocation } from 'hooks';

export default function useSearchSync (data) {
  const { results } = data;
  const { updateQuerySearchParams } = useLocation();

  const isActiveSearch = Array.isArray(results);

  const params = buildSearchParams(data);

  // If our search is active and the params have changed, make sure
  // we're keeping things up to date with the active search

  useEffect(() => {
    if (!isActiveSearch) return;
    updateQuerySearchParams(params, {
      replace: true
    });
  }, [isActiveSearch, params, updateQuerySearchParams]);

  return {
    params
  };
}
