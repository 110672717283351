import { useState } from 'react';
import { useMappedState } from 'redux-react-hook';

export default function useAoi (aoiId) {
  const mapState = ({ aois }) => aois;
  const [aoiFeatureState, setAoiFeatureState] = useState();

  /**
   * helper function to update state in a piecemeal way to preserve unchanged properties
   *
   * @param {object} feature geoJson feature
   */
  function updateAoiFeatureState (feature) {
    setAoiFeatureState((prevState) => ({
      ...prevState,
      ...feature,
      properties: {
        ...prevState?.properties,
        ...feature?.properties,
        radius: feature?.properties?.radius || null
      }
    }));
  }

  const aoisState = useMappedState(mapState);

  const { aois = [] } = aoisState || {};

  const aoi = aois.find(({ id }) => id === aoiId);
  let aoiFeature;

  if (aoi) {
    aoiFeature = {
      type: 'FeatureCollection',
      features: [aoi]
    };
  }
  return {
    ...aoisState,
    aoi,
    aoiFeature,
    aoiFeatureState,
    updateAoiFeatureState
  };
}
