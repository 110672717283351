import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Layout from 'components/Layout';
import Container from 'components/Container';
import { useLocation, useCognitoToken } from 'hooks';

const UserAuthorizePage = ({ location }) => {
  const { uriFragment } = useLocation({ location });

  useCognitoToken(uriFragment);

  return (
    <Layout location={location} forceNew={true} navigation={false}>
      <Helmet bodyAttributes={{ class: 'page-user page-user-authorize' }}>
        <title>Authorize</title>
      </Helmet>
      <Container className="content" type="centered">
        <h1>Logging In</h1>
      </Container>
    </Layout>
  );
};

UserAuthorizePage.propTypes = {
  location: PropTypes.object
};

export default UserAuthorizePage;
