import { useMappedState } from 'redux-react-hook';
import Contract from 'commonLib/src/models/salesforceContract';

function friendlyContractId (contractId = '') {
  if (contractId.length > 7) {
    return `ID: ${contractId}`;
  }

  return contractId;
}
/**
 * hook for contracts store
 * @param {object} [options={}] - optional object for config options
 * @param {boolean} [options.activeOnly=false] - return existent contracts only
 * @param {boolean} [options.asSelectOptions=false] - return contracts data formatted for a fogg/ui/FormInput select component
 * @param {boolean} [options.byId] - returns an array of one found by contract id
 * @returns {Array}
 */
export default function useContracts ({
  activeOnly = false,
  asSelectOptions = false,
  byId
} = {}) {
  const mapState = ({ contracts }) => contracts;
  const contractsState = useMappedState(mapState);
  const { contracts = [] } = contractsState;

  const typedContracts = contracts
    .map((contract) => new Contract(contract))
    .filter((contract) => {
      if (activeOnly && !contract.isExpired()) {
        return contract;
      }

      return contract;
    })
    .sort((contractA, contractB) => Number(contractB.endDate) - Number(contractA.endDate))
  ;

  if (byId) {
    return {
      ...contractsState,
      contracts: [typedContracts.find((contract) => contract.id === byId)]
    };
  }

  if (asSelectOptions === true) {
    return {
      ...contractsState,
      contracts: typedContracts.map(({ id, name }) => ({
        value: id,
        label: name || friendlyContractId(id)
      }))
    };
  }

  return {
    ...contractsState,
    contracts: typedContracts
  };
}
