import React from 'react';
import PropTypes from 'prop-types';
import { useTokenExpiration } from 'commonLib';

const PageWrapper = ({ children }) => {
  // Watch for refresh token to expire
  useTokenExpiration();

  return (<>{children}</>);
};

PageWrapper.propTypes = {
  children: PropTypes.node
};

export default PageWrapper;
