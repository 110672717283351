import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

/**
 * Arrows for the Access Requests horizontal scroller
 * @param {string} direction
 * @param {boolean} isLoading
 */

function Arrow ({ direction = 'left', isLoading }) {
  const {
    isFirstItemVisible,
    isLastItemVisible,
    scrollPrev,
    scrollNext,
    visibleElements,
    initComplete
  } = useContext(VisibilityContext);

  const [disabledLeft, setDisabledLeft] = useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  // Right Arrow control for ScrollMenu - based on <Arrow />
  const [disabledRight, setDisabledRight] = useState(
    !visibleElements.length && isLastItemVisible && !isLoading
  );

  useEffect(() => {
    if (visibleElements.length && !isLoading) {
      setDisabledLeft(isFirstItemVisible);
      setDisabledRight(isLastItemVisible);
    }
  }, [isLastItemVisible, isFirstItemVisible, visibleElements, isLoading]);

  if (direction === 'left') {
    return (
      <button
        disabled={disabledLeft}
        onClick={() => scrollPrev()}
        className={`horizontal-scroll-button left ${disabledLeft ? ' disabled' : ''}`}
      >
        <FaChevronLeft />
      </button>
    );
  }

  if (direction === 'right') {
    return (
      <button
        disabled={disabledRight}
        onClick={() => scrollNext()}
        className={`horizontal-scroll-button right ${disabledRight ? ' disabled' : ''}`}
      >
        <FaChevronRight />
      </button>
    );
  }
}

Arrow.propTypes = {
  isLoading: PropTypes.bool,
  direction: PropTypes.string
};

export default Arrow;
