import { device } from 'fogg/lib';

import { normalizePathname } from 'lib/routes';
import { errorMessageByCode } from 'lib/errors';

const { isDomAvailable } = device;

export const updateNotice = (data) => {
  return {
    type: 'UPDATE_NOTICE',
    data: { notice: data }
  };
};

export const setShowCustomNotice = (data) => {
  return {
    type: 'SHOW_CUSTOM_NOTICE',
    data
  };
};

export const triggerNotice = ({
  type,
  weight,
  align,
  text,
  closable = true,
  persist = false
}) => {
  let pathname;

  if (isDomAvailable()) {
    const { location = {} } = window;
    pathname = normalizePathname(location.pathname);
  }

  return (dispatch) => {
    function handleClose () {
      dispatch(clearNotice());
    }

    dispatch(
      updateNotice({
        type,
        weight,
        align,
        text,
        pathname,
        onClose: closable ? handleClose : null,
        persist
      })
    );

    if (isDomAvailable()) {
      window.scroll(0, 0);
    }
  };
};

export const triggerSuccess = (message = '', options = {}) => {
  return triggerNotice({
    type: 'success',
    align: 'center',
    text: message,
    ...options
  });
};

export const triggerError = (message = errorMessageByCode(), options = {}) => {
  return triggerNotice({
    type: 'error',
    weight: 'bold',
    align: 'center',
    text: message,
    ...options
  });
};

export const triggerErrorByCode = (code) => {
  const message = errorMessageByCode(code);
  return triggerError(message);
};

export const clearNotice = () => {
  return {
    type: 'UPDATE_NOTICE',
    data: {
      notice: null
    }
  };
};

export const setBasemap = (data) => {
  return {
    type: 'SET_BASEMAP',
    data
  };
};
