// Admin Pages
export { default as AdminIndexPage } from './src/pages-client/admin/admin';
export { default as AdminUsersPage } from './src/pages-client/admin/users/users';
export { default as AdminUsersCreatePage } from './src/pages-client/admin/users/create';
export { default as AdminUserPage } from './src/pages-client/admin/users/user';
export { default as AdminOrganizationsPage } from './src/pages-client/admin/organizations/organizations';
export { default as AdminOrganizationsCreatePage } from './src/pages-client/admin/organizations/create';
export { default as AdminOrganizationPage } from './src/pages-client/admin/organizations/organization/organization';
export { default as AdminOrganizationEditPage } from './src/pages-client/admin/organizations/organization/edit';
export { default as AdminOrganizationsBucketConfigAddPage } from './src/pages-client/admin/organizations/bucket-config/add';
export { default as AdminOrganizationsBucketConfigEditPage } from './src/pages-client/admin/organizations/bucket-config/edit';
export { default as AdminOrganizationsContractAddPage } from './src/pages-client/admin/organizations/contract/add';
export { default as AdminOrganizationsContractEditPage } from './src/pages-client/admin/organizations/contract/edit';
export { default as AdminOrganizationsContractPeriodAddPage } from './src/pages-client/admin/organizations/contract-period/add';
export { default as AdminOrganizationsContractPeriodEditPage } from './src/pages-client/admin/organizations/contract-period/edit';
export { default as AdminOrganizationsContractDetailsPage } from './src/pages-client/admin/organizations/organization/contract';
export { default as AdminCollectionsPage } from './src/pages-client/admin/collections/collections';
export { default as AdminCollectionPage } from './src/pages-client/admin/collections/collection';
