import { AVAILABLE_ORBITAL_PLANES as orbitalPlanes } from 'commonLib/src/data/tasks';

export const TILE_ENDPOINT = process.env.TILER_URL;

export const DAY_NIGHT_TIME_RANGES = {
  day: {
    gte: '06:00:00',
    lt: '18:00:00'
  },
  night: [
    {
      gte: '00:00:00',
      lt: '06:00:00'
    },
    {
      gte: '18:00:00',
      lte: '23:59:59'
    }
  ]
};

export const searchFilters = [
  {
    label: 'Collection',
    id: 'collections',
    name: 'collections',
    type: 'checklist',
    // This list get's populated with the user org's allowed collections fetched in pages/search.js
    list: [],
    shouldToggleItems: true,
    showAllValuesListItem: false
  },
  {
    label: 'Type',
    id: 'product_type',
    name: 'properties/sar:product_type',
    type: 'checklist',
    list: ['SLC', 'GEO', 'GEC', 'SICD', 'CPHD', 'SIDD']
  },
  {
    label: 'Instrument Mode',
    id: 'instrument_mode',
    name: 'properties/sar:instrument_mode',
    type: 'radiolist',
    list: ['sliding_spotlight', 'spotlight', 'stripmap']
  },
  {
    label: 'Collection Time',
    subLabel: 'Day: 6AM - 6PM, Night: 6PM - 6AM',
    id: 'collection_time',
    name: 'properties/locale:time',
    type: 'radiolist',
    list: ['day', 'night']
  },
  {
    label: 'Orbital Plane',
    id: 'orbital_plane',
    name: 'properties/capella:orbital_plane',
    type: 'checklist',
    list: orbitalPlanes.map(({ value }) => value.toString()),
    displayList: Object.fromEntries(
      orbitalPlanes.map((item) => [item.value, item.label])
    )
  },
  {
    label: 'Orbit State',
    id: 'orbit_state',
    name: 'properties/sat:orbit_state',
    type: 'radiolist',
    list: ['ascending', 'descending']
  },
  {
    label: 'Observation Direction',
    id: 'observation_direction',
    name: 'properties/sar:observation_direction',
    type: 'radiolist',
    list: ['left', 'right']
  },
  {
    label: 'Polarization',
    id: 'polarization',
    name: 'properties/sar:polarizations',
    type: 'radiolist',
    list: ['HH', 'VV']
  },
  {
    label: 'Incidence Angle',
    id: 'incidence_angle',
    name: 'properties/view:incidence_angle',
    type: 'range',
    range: {
      min: 5,
      max: 75
    }
  },
  {
    label: 'Squint Angle',
    id: 'squint_angle',
    name: 'properties/capella:squint_angle',
    type: 'range',
    range: {
      min: 0,
      max: 45
    }
  },
  {
    label: 'Slant Range Resolution',
    id: 'resolution_range',
    name: 'properties/sar:resolution_range',
    type: 'range',
    range: {
      min: 0.3,
      max: 10
    }
  },
  {
    label: 'Azimuth Resolution',
    id: 'resolution_azimuth',
    name: 'properties/sar:resolution_azimuth',
    type: 'range',
    range: {
      min: 0.5,
      max: 11.5
    }
  },
  {
    label: 'Collect ID',
    id: 'collect_id',
    name: 'properties/capella:collect_id',
    type: 'hidden'
  }
];

export const ALL_VALUES_ITEM = 'All Values';
