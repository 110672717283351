import { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';

import {
  useContracts,
  useUser
} from 'hooks';
import { logError } from 'lib/logger';
import { fetchContracts, triggerNotice } from 'state/actions';

/**
 * Fetches salesforce ledger contracts from the api
 * @param  {object} [options={}] - optional config options
 * @param  {bool} [forceNew=false] - used to force a new fetch or not
 */
export default function useFetchContracts ({ forceNew = false, includeManagedOrgs = false } = {}) {
  const dispatch = useDispatch();
  const { contracts, actions = {} } = useContracts() || {};
  const { fetchContracts: fetchContractsAction = {} } = actions;
  const { firstLoad } = fetchContractsAction;

  // useUser organization to determine contracts ability
  const {
    user: {
      organization: {
        ledgerBillingEnabled = false,
        type = 'standard'
      } = {}
    } = {}
  } = useUser();

  useEffect(() => {
    if (!ledgerBillingEnabled) return;
    if (firstLoad && !forceNew) return;

    dispatch(fetchContracts({
      includeManagedOrgs: includeManagedOrgs && type === 'reseller'
    }))
      .catch((error) => {
        logError(error.message);
        dispatch(
          triggerNotice({
            type: 'error',
            weight: 'bold',
            align: 'center',
            text: 'Uh oh, something went wrong. Try refreshing the page.'
          })
        );
      });
  }, [dispatch, forceNew, firstLoad]);

  return {
    ...actions.fetchContracts,
    ledgerBillingEnabled,
    contracts
  };
}
