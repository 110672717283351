import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';

import { normalizePathname, routePathByName, redirectTo } from 'lib/routes';
import { useLocation } from 'hooks';

import CartViewPage from 'pages-client/cart/view';
import CartReviewPage from 'pages-client/cart/review';

import Layout from 'components/Layout';
import Container from 'components/Container';

const CartIndex = ({ location }) => {
  const { pathname } = useLocation(location);

  const isCartPath = normalizePathname(pathname) === routePathByName('cart');

  useEffect(() => {
    // TODO: why are we doing this?
    if (isCartPath) {
      redirectTo(routePathByName('cartView'));
    }
  }, [isCartPath]);

  if (isCartPath) {
    // Return a layout component to avoid a page flicker of no content
    return (
      <Layout>
        <Container className="content" />
      </Layout>
    );
  }

  return (
    <>
      <Router basepath="/">
        <CartViewPage path={routePathByName('cartView')} />
        <CartReviewPage path={routePathByName('cartReview')} />
      </Router>
    </>
  );
};

CartIndex.propTypes = {
  location: PropTypes.object
};

export default CartIndex;
