import { routePathByName } from 'lib/routes';
import { ROUTE_ORDERS_DETAILS } from 'data/route-names';

export const DEFAULT_ORDERS_TABLE_COLUMNS = [
  'orderId',
  'orderDate',
  'status',
  'actions'
];

export const RESELLER_ORDERS_TABLE_COLUMNS = [
  ...DEFAULT_ORDERS_TABLE_COLUMNS,
  'contractLabel',
  'userName',
  'organizationName'
];

/**
 * ordersToTableData
 */

export function ordersToTableData (orders = [], options = {}) {
  if (!Array.isArray(orders) || orders.length === 0) return [];

  const {
    routeName = ROUTE_ORDERS_DETAILS,
    columns = DEFAULT_ORDERS_TABLE_COLUMNS
  } = options;

  orders = orders.map((order = {}) => {
    const {
      orderDate,
      orderId,
      orderStatus,
      status,
      id,
      user = {},
      contractLabel: orderContractLabel = '',
      userName = '',
      organizationName = ''
    } = order;

    // JAM-2705 - Bug band-aid for when a user id on an order doesn't exist in the org users to prevent the name displaying as "undefined undefined"
    const { givenName = '-', familyName = '' } = user;

    return {
      orderId: orderId || id,
      user: `${givenName} ${familyName}`,
      userName,
      organizationName,
      contractLabel: orderContractLabel,
      orderDate,
      status: orderStatus || status,
      actions: [
        {
          to: routePathByName(routeName, {
            wildcard: [orderId || id]
          }),
          label: '   ',
          icon: 'FaChevronRight',
          buttonType: ['text', 'icon-after']
        }
      ]
    };
  });

  orders = orders.map((order = {}) => {
    const keys = Object.keys(order);
    const activeKeys = keys.filter((key) => columns.includes(key));
    const filteredOrder = {};

    activeKeys.forEach((key) => {
      filteredOrder[key] = order[key];
    });

    return filteredOrder;
  });

  return orders;
}
