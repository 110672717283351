import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'redux-react-hook';
import { FaBitbucket } from 'react-icons/fa';

import {
  routePathByName,
  redirectTo,
  logError,
  adminCreateNewOrganizationS3Bucket,
  triggerSuccess,
  triggerError,
  Breadcrumbs,
  PageHeader,
  Container,
  FormBucketConfigAddEdit
} from 'commonLib';

import Layout from 'components/Layout';

const PAGE_TITLE = 'Add Bucket Config';

const AdminOrganizationsBucketConfigAddPage = ({ organizationId }) => {
  const dispatch = useDispatch();

  const organizationEditPath = routePathByName('adminOrganizationsEdit', {
    wildcard: organizationId
  });

  const organizationDetailsPath = routePathByName('adminOrganizationsDetails', {
    wildcard: organizationId
  });

  function handleOnSubmit ({ data }) {
    dispatch(adminCreateNewOrganizationS3Bucket(organizationId, data))
      .then(() => {
        redirectTo(organizationEditPath);
        dispatch(triggerSuccess(`Successfully added bucket config ${data.bucketName}/${data.prefix}!`));
      })
      .catch((error) => {
        logError(error.message, data);
        dispatch(triggerError(`Not able to add bucket config ${data.bucketName}/${data.prefix}!`));
      });
  }

  function handleOnCancel () {
    redirectTo(organizationEditPath);
  }

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: 'page-admin page-admin-bucket-config page-admin-bucket-config-add'
        }}
      >
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <Container className="content">
        <Breadcrumbs
          items={[
            {
              label: 'Admin',
              to: routePathByName('admin')
            },
            {
              label: 'Organizations',
              to: routePathByName('adminOrganizations')
            },
            {
              label: 'Organization',
              to: organizationDetailsPath
            }
          ]}
        />
        <PageHeader title={PAGE_TITLE} icon={<FaBitbucket />} />
        <FormBucketConfigAddEdit
          orgId={organizationId}
          mode='add'
          onCancel={handleOnCancel}
          onSubmit={handleOnSubmit}
        />
      </Container>
    </Layout>
  );
};

AdminOrganizationsBucketConfigAddPage.propTypes = {
  organizationId: PropTypes.string
};

export default AdminOrganizationsBucketConfigAddPage;
