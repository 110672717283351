/**
 * type enumerators for repeat request end
 */
export const RepeatRequestEndTypes = {
  INDEFINITE: 'indefinite',
  DATE: 'date',
  COUNT: 'count',
  properties: {
    indefinite: {
      label: 'Until canceled'
    },
    date: {
      label: 'Date and time'
    },
    count: {
      label: 'Number of collects'
    }
  }
};
